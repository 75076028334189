import { COLORS, List, Text, FontWeight } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

const Callout = styled(Text.Callout)`
  color: ${COLORS.panther};
`;

const StyledListItem = styled(List.Item)<{ highlight?: boolean }>`
  background: ${({ highlight }) => (highlight ? COLORS.tealBackground : COLORS.cloud)};
  display: flex;
  justify-content: space-between;
`;

export const SummaryListItem: React.FC<{ title: string; value: string; highlight?: boolean }> = ({
  title,
  value,
  highlight,
}) => (
  <StyledListItem highlight={highlight}>
    <Callout weight={FontWeight.Medium}>{title}</Callout>
    <Callout weight={highlight ? FontWeight.Medium : FontWeight.Regular}>{value}</Callout>
  </StyledListItem>
);
