import cn from 'classnames';
import * as React from 'react';

const DEFAULT_TAG = 'div';

type Size = 0 | 1 | 2 | 3 | 4 | 5 | 'auto';

export const Spacing: React.FC<{
  tag?: 'div' | 'span';
  mt?: Size;
  mb?: Size;
  ml?: Size;
  mr?: Size;
  pt?: Size;
  pb?: Size;
  pl?: Size;
  pr?: Size;
}> = ({ tag = DEFAULT_TAG, children, mt, mb, ml, mr, pt, pb, pl, pr }) => {
  const Tag = tag;
  return (
    <Tag
      className={cn(
        mt && `mt-${mt}`,
        mb && `mb-${mb}`,
        ml && `ml-${ml}`,
        mr && `mr-${mr}`,
        pt && `pt-${pt}`,
        pb && `pb-${pb}`,
        pl && `pl-${pl}`,
        pr && `pr-${pr}`,
      )}
    >
      {children}
    </Tag>
  );
};
