import styled from '@emotion/styled';
import React from 'react';

import { Spinner } from '@portal/components/helpers';
import { CONTAINER_SIDE_PADDING } from '@portal/components/shared/layout';
import { useAccountDetailQuery } from '@portal/schema';

import { View } from './view';

const Container = styled.div`
  padding: 0 ${16 - CONTAINER_SIDE_PADDING}px;
`;

export const Details: React.FC = () => {
  const { data, loading } = useAccountDetailQuery();

  if (loading) {
    return <Spinner />;
  }

  if (!data || !data.account) {
    return null;
  }

  const { account, upcomingStorageOnboarding } = data;

  return (
    <Container>
      <View account={account} onboarding={upcomingStorageOnboarding} />
    </Container>
  );
};
