import * as React from 'react';

import { Nav } from '@shared/components/bootstrap';

export enum Mode {
  All,
  Selected,
}

export const Filters: React.FC<{
  mode: Mode;
  all?: number;
  selected?: number;
  onChange(mode: Mode): void;
}> = ({ mode, all, selected, onChange }) => (
  <Nav format={Nav.Format.Tabs}>
    <Nav.Item>
      <Nav.Link
        href="#"
        active={mode === Mode.All}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onChange(Mode.All);
        }}
      >
        All ({all !== undefined ? all : '-'})
      </Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link
        href="#"
        active={mode === Mode.Selected}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onChange(Mode.Selected);
        }}
      >
        Selected ({selected !== undefined ? selected : '-'})
      </Nav.Link>
    </Nav.Item>
  </Nav>
);
