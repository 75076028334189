import * as React from 'react';

import styled from '@emotion/styled';
import { Button, Radio, SpinLoader, Box } from '@clutter/clean';

import { AddressWithDetailsFragment, AddressWithDetailsFragmentDoc, useBuildAddressMutation } from '@portal/schema';

import { useState } from 'react';
import { serialize } from '@portal/components/addresses/form/mutation';

import { Modal as AddressModal } from '@portal/components/addresses/modal';
import { IAddressWithDetails } from '@portal/types';

const AddAddressButton = styled(Button)`
  margin-top: 24px;
`;

export const Selector: React.FC<{
  selection?: AddressWithDetailsFragment;
  addresses?: AddressWithDetailsFragment[];
  onSelect(address: AddressWithDetailsFragment): void;
}> = ({ selection, addresses, onSelect }) => {
  const [showNewAddressModal, setShowNewAddressModal] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [saveAddress, { loading }] = useBuildAddressMutation({
    update(cache, { data }) {
      const newAddress = data?.buildAddress?.address;
      if (!newAddress) return;
      cache.modify({
        fields: {
          addresses(existing = []) {
            const newAddressFragment = cache.writeFragment({
              data: newAddress,
              fragment: AddressWithDetailsFragmentDoc,
              fragmentName: 'addressWithDetails',
            });
            return [...existing, newAddressFragment];
          },
        },
      });
    },
    onCompleted: (data) => {
      if (data.buildAddress?.address) {
        selectAddress(data.buildAddress.address.id);
        setShowNewAddressModal(false);
      }
    },
  });

  const selectAddress = (addressID: string) => {
    const selectedAddress = addresses?.find(({ id }) => id === addressID);
    if (selectedAddress) onSelect(selectedAddress);
  };

  const onAddAddress = async (address: IAddressWithDetails) => {
    const response = await saveAddress({ variables: { input: serialize(address) } });
    if (response.data?.buildAddress?.error) {
      setError(response.data.buildAddress.error);
    } else if (response.data?.buildAddress?.address) {
      onSelect(response.data.buildAddress.address);
    }
  };

  return (
    <>
      {!addresses && (
        <Box margin="0 24px">
          <SpinLoader />
        </Box>
      )}
      {addresses && (
        <Radio.Selector
          name="address"
          options={addresses.map((option) => ({ value: option.id, label: option.formatted }))}
          value={selection?.id}
          onChange={selectAddress}
        />
      )}

      <AddAddressButton type="button" kind="secondary" size="medium" onClick={() => setShowNewAddressModal(true)}>
        Add Address
      </AddAddressButton>

      <AddressModal
        isOpen={showNewAddressModal}
        onClose={() => setShowNewAddressModal(false)}
        error={error}
        loading={loading}
        onAddAddress={onAddAddress}
      />
    </>
  );
};
