import React from 'react';
import styled from '@emotion/styled';

import { Button, UnstyledButton, Text } from '@clutter/clean';
import { StepName } from '@portal/components/orders/estimation/virtual_walkthrough/data';
import { OrderTypeEnum } from '@portal/schema';
import BackArrow from '@portal/images/back_arrow.svg';

import { TrackedClick } from '@portal/components/wt/tracked_click';
import { BaseStepType } from './data';

const Container = styled.div<{ estimator?: boolean }>`
  margin-top: 40px;
  padding-bottom: ${({ estimator }) => (estimator ? '230px' : '80px')};

  // Override global Account Portal styles
  label {
    margin-bottom: 0;
  }

  // Disable text & image highlighting on click
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const Header = styled.div`
  margin-bottom: 32px;
`;

const Headline = styled(Text.Title)`
  text-align: center;
  margin-bottom: 8px;
`;

const Subheading = styled(Text.Body)`
  text-align: center;
`;

const Next = styled(Button)`
  max-width: 600px;
`;

const Footer = styled.div<{ hide?: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  padding: 16px 24px;
  box-shadow: 0px 2px 20px rgba(194, 194, 194, 0.25);
  background-color: white;
  z-index: 1;
`;

const FooterContent = styled.div<{ canPrev?: boolean; neverFullWidthNext?: boolean }>`
  justify-content: ${({ canPrev, neverFullWidthNext }) =>
    canPrev ? 'space-between' : neverFullWidthNext ? 'flex-end' : 'center'};
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
`;

export const StepContainer: React.FC<BaseStepType> = ({
  canPrev,
  canNext,
  prev,
  next,
  nextLabel,
  hideNav,
  headline,
  subheading,
  children,
  loading,
  orderID,
  orderType,
  neverFullWidthNext,
  stepName,
}) => (
  <Container estimator={stepName === StepName.ItemInventory && orderType === OrderTypeEnum.Move}>
    {(headline || subheading) && (
      <Header>
        {headline && <Headline size="large">{headline}</Headline>}
        {subheading && <Subheading>{subheading}</Subheading>}
      </Header>
    )}
    {children}
    <Footer hide={hideNav}>
      <FooterContent canPrev={canPrev} neverFullWidthNext={neverFullWidthNext}>
        {canPrev && (
          <UnstyledButton onClick={prev}>
            <img src={BackArrow} />
          </UnstyledButton>
        )}
        <TrackedClick params={nextButtonTrackingParams(orderID, nextLabel ?? 'Next')}>
          <Next
            onClick={next}
            disabled={!canNext || loading}
            fullWidth={!neverFullWidthNext && !canPrev}
            loading={loading}
          >
            {nextLabel ?? 'Next'}
          </Next>
        </TrackedClick>
      </FooterContent>
    </Footer>
  </Container>
);

export function nextButtonTrackingParams(orderID: string, buttonLabel: string) {
  return {
    pageName: 'portal:virtual_walkthrough_review',
    container: 'appointment_details',
    action: 'click',
    objectType: 'button',
    objectName: 'next_button',
    label: buttonLabel,
    value: 'next_step',
    order_id: orderID,
  };
}
