import { AriaAttributes, SVGAriaEnhancements } from '@shared/components/helpers/svg_aria_enhancements';
import React, { FC } from 'react';

export const Calendar: FC<AriaAttributes> = ({ title, description: desc }) => {
  const Aria = SVGAriaEnhancements(title, desc);

  return (
    <svg
      width="40"
      height="38"
      viewBox="0 0 40 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={Aria.ariaLabelledby}
    >
      <Aria.Title />
      <Aria.Desc />
      <g clipPath="url(#clip0_22_1539)">
        <path
          d="M34.6831 0.679202L0.688293 6.55032L1.55589 11.3723L35.5507 5.50117L34.6831 0.679202Z"
          fill="#F79C94"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.6574 5.64865L39.3102 31.5045L7.99989 36.9144L3.34702 11.0586"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.398 10.5485L5.97757 12.8663L6.29284 14.6185L19.7133 12.3007L19.398 10.5485Z"
          fill="#00A499"
          stroke="#00A499"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.5204 12.175L6.68408 16.8098L9.83762 34.3366L36.674 29.7018L33.5204 12.175Z"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.47574 21.1892L34.3125 16.554"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.26654 25.5721L35.1033 20.937"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.05276 29.955L35.8895 25.3198"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3125 33.5631L11.1585 16.036"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.7861 32.7928L15.6321 15.2613"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.2596 32.018L20.1056 14.491"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.7332 31.2477L24.5792 13.7162"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.2068 30.473L29.0527 12.946"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.23897 10.9054L3.3746 6.0856"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.26426 37.0676L2.45047 11.2162"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.37233 37.2252L1.55853 11.3694"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.55853 11.3694L34.6574 5.64865"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.6538 5.65423L1.55551 11.3705L1.71355 12.2488L34.8119 6.53256L34.6538 5.65423Z"
          fill="#00404D"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.7033 20.3198C31.4803 19.0991 29.7332 18.9685 28.1424 19.0631C24.7125 19.2613 21.2045 20.4775 18.8505 23.2478C17.9401 24.3198 19.4298 25.9099 20.3447 24.8379C20.9654 24.1081 21.6734 23.518 22.4459 23.0315C21.6413 23.7568 20.924 24.5901 20.3263 25.5225C19.8941 26.1982 19.5631 26.946 19.6367 27.7838C19.7194 28.7207 20.3631 29.4775 21.1447 29.8649C22.5516 30.5541 24.1976 30.0991 25.6275 29.7252C28.478 28.982 31.3608 27.6622 33.0711 25.0045C34.0505 23.4775 33.9999 21.6216 32.7033 20.3198ZM31.5999 23.2252C31.2551 24.2072 30.3033 25.0766 29.5125 25.6397C28.6114 26.2793 27.6183 26.7433 26.593 27.0991C25.5447 27.4595 24.4229 27.8018 23.3286 27.9415C22.9194 27.991 22.4229 28.036 22.0413 27.8379C21.4803 27.5451 21.8895 27.0631 22.1516 26.6577C23.5907 24.4189 25.8895 22.9415 28.3677 22.4144C28.97 22.2883 29.1815 21.7793 29.1125 21.2793C29.3286 21.2883 29.5493 21.2973 29.7608 21.3198C30.6114 21.4279 32.0091 22.0496 31.5999 23.2252Z"
          fill="#F79C94"
        />
        <path
          d="M3.34702 11.0586L7.94472 36.6081L6.16081 36.9144L3.34702 11.0586Z"
          fill="#00404D"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_1539">
          <rect width="40" height="37.8378" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
