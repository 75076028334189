import * as React from 'react';
import { useHistory } from 'react-router';

import styled from '@emotion/styled';
import { COLORS, Text, TextButton, FontWeight } from '@clutter/clean';
import { WhatToStoreBox } from '@portal/components/shared/icons';
import { orderURL } from '@portal/config/routes';
import { Spacer } from '@shared/components/helpers/spacer';

const Container = styled.div`
  border-radius: 4px;
  border: 1px solid ${COLORS.tiger};
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  align-items: flex-start;
  background: ${COLORS.grayBackground};
`;

const Icon = styled.div`
  margin-right: 24px;
`;

export const CuftDiscrepancyAlert: React.FC<{ orderID: string }> = ({ orderID }) => {
  const history = useHistory();

  return (
    <Container>
      <Icon>
        <WhatToStoreBox />
      </Icon>
      <div>
        <Text.Callout weight={FontWeight.Medium}>
          There is a large discrepancy between your currently selected plan size and the inventory you have entered to
          be stored.
        </Text.Callout>
        <Spacer height="12px" />
        <Text.Callout>
          Please review your inventory to confirm that it is as accurate as possible. We use this to determine how many
          movers to send and how much time we schedule for your appointment.
        </Text.Callout>
        <Spacer height="12px" />
        <Text.Callout>
          If your inventory is complete and accurate, then you can also change your plan size to avoid any plan changes
          on the day of your appointment.
        </Text.Callout>
        <Spacer height="12px" />
        <TextButton onClick={() => history.push(orderURL(orderID, 'estimation_flow'))}>Review Inventory</TextButton>
      </div>
    </Container>
  );
};
