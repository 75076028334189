import * as React from 'react';

import { Brick } from '@portal/components/helpers/brick';
import { Files } from '@portal/components/helpers/files';
import { Grid } from '@portal/components/helpers/grid';
import { AttachmentFragment } from '@portal/schema';

interface IAttachmentsProps {
  accept?: string;
  attachments: AttachmentFragment[];
  onAttach?(signedID: string): void;
  onDetach?(attachmentID: string): void;
  onUploading?(uploading: boolean): void;
}

export const Attachments = ({ accept, attachments, onAttach, onDetach, onUploading }: IAttachmentsProps) => (
  <>
    <Grid>
      {attachments.map(({ id, imgixURL, filename }) => (
        <Grid.Entry key={id}>
          <Brick imgixURL={imgixURL}>
            {onDetach && (
              <Brick.Status>
                <button className="btn btn-link" onClick={() => onDetach(id)}>
                  Remove
                </button>
              </Brick.Status>
            )}
            <Brick.Details>{filename}</Brick.Details>
          </Brick>
        </Grid.Entry>
      ))}
    </Grid>
    {accept && onAttach && <Files accept={accept} onSave={onAttach} onUploading={onUploading} />}
  </>
);
