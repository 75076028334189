import { Button, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { SmallOffice } from '@portal/components/shared/icons/small_office';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

const ImageContainer = styled.div`
  padding-bottom: 24px;
`;

const Callout = styled(Text.Callout)`
  text-align: center;
  padding-bottom: 24px;
`;

const ButtonLink = styled.a`
  display: block;
  width: 100%;
  max-width: 400px;
  &:hover {
    text-decoration: none;
  }
`;

export const Reonboard: React.FC<{ reonboardingCheckoutLink: string }> = ({ reonboardingCheckoutLink }) => (
  <Container>
    <ImageContainer>
      <SmallOffice />
    </ImageContainer>
    <Callout>We’ll help you book hassle-free and affordable storage in your area.</Callout>
    <ButtonLink href={reonboardingCheckoutLink}>
      <Button kind="secondary" fullWidth>
        Get Started
      </Button>
    </ButtonLink>
  </Container>
);
