import { OrderSubtypeEnum, OrderTypeEnum } from '@portal/schema';
import { DateTime, DateTimeFormatOptions, Duration } from 'luxon';

const FROM_ISO_OPTIONS = { setZone: true };

export function datetime(isoString: string) {
  return DateTime.fromISO(isoString, FROM_ISO_OPTIONS);
}

export function formatDate(date: DateTime) {
  return date.toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' });
}

export function formatTime(time: DateTime) {
  const format: DateTimeFormatOptions = { hour: 'numeric' };
  if (time.minute !== 0) {
    format.minute = 'numeric';
  }
  return time.toLocaleString(format);
}

export function formattedArrivalTimeWindow(arrivalDetails: { scheduled: string; window: string }) {
  const startTime = datetime(arrivalDetails.scheduled);
  const endTime = startTime.plus(Duration.fromISO(arrivalDetails.window));
  return `${formatTime(startTime)} - ${formatTime(endTime)}`;
}

export function hasMatchingArrivalTimeWindows(arrivalDetails: Array<{ scheduled: string; window: string }>) {
  const windows = arrivalDetails.map((details) => formattedArrivalTimeWindow(details));
  return new Set(windows).size <= 1;
}

export function dateDetailsMap(
  orderDetails: Array<{ scheduled: string; window: string; type: OrderTypeEnum; subtype: OrderSubtypeEnum }>,
) {
  const map: Map<string, { formattedArrivalWindow: string; serviceDescription: string }> = new Map();
  [...orderDetails]
    .sort((a, b) => datetime(a.scheduled).toJSDate().getTime() - datetime(b.scheduled).toJSDate().getTime())
    .forEach((detail) => {
      const date = formatDate(datetime(detail.scheduled));
      const formattedArrivalWindow = formattedArrivalTimeWindow(detail);
      let serviceDescription = '';
      if (detail.type === OrderTypeEnum.Move) {
        if (detail.subtype === OrderSubtypeEnum.Packing) {
          serviceDescription = 'Packing';
        } else if (detail.subtype === OrderSubtypeEnum.Unpacking) {
          serviceDescription = 'Unpacking';
        } else if (detail.subtype === OrderSubtypeEnum.None) {
          serviceDescription = 'Move';
        }
      } else if (detail.type === OrderTypeEnum.Pickup) {
        serviceDescription = 'Pickup';
      } else if (detail.type === OrderTypeEnum.Return) {
        serviceDescription = 'Delivery';
      }

      const existingMapEntry = map.get(date);
      if (existingMapEntry) {
        const existingServiceDescription = existingMapEntry.serviceDescription;
        const newServiceDescription = serviceDescription.includes(existingServiceDescription)
          ? serviceDescription
          : `${existingServiceDescription} & ${serviceDescription}`;
        map.set(date, {
          formattedArrivalWindow: formattedArrivalWindow,
          serviceDescription: newServiceDescription,
        });
      } else {
        map.set(date, {
          formattedArrivalWindow: formattedArrivalWindow,
          serviceDescription: serviceDescription,
        });
      }
    });
  return map;
}
