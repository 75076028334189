import { AriaAttributes, SVGAriaEnhancements } from '@shared/components/helpers/svg_aria_enhancements';
import React, { FC } from 'react';

export const LightBulb: FC<AriaAttributes> = ({ title, description: desc }) => {
  const Aria = SVGAriaEnhancements(title, desc);

  return (
    <svg
      width="40"
      height="53"
      viewBox="0 0 40 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={Aria.ariaLabelledby}
    >
      <Aria.Title />
      <Aria.Desc />
      <g clipPath="url(#clip0_22_1531)">
        <path
          d="M20 0.296204L24.3369 7.74334L32.1831 4.28934L31.3556 12.8918L39.7066 14.7521L34.0317 21.2157L39.7066 27.6794L31.3556 29.5397L32.1831 38.1421L24.3369 34.6881L20 42.1352L15.6631 34.6881L7.82277 38.1421L8.65023 29.5397L0.293427 27.6794L5.96831 21.2157L0.293427 14.7521L8.65023 12.8918L7.82277 4.28934L15.6631 7.74334L20 0.296204Z"
          fill="#FFEABF"
          stroke="#FFEABF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.6479 48.4567C25.1491 47.9389 25.4293 47.2434 25.4284 46.5194V41.7264C25.4284 41.0979 25.1811 40.4952 24.7408 40.0507C24.3006 39.6063 23.7035 39.3566 23.081 39.3566H16.9073C16.2847 39.3566 15.6876 39.6063 15.2474 40.0507C14.8072 40.4952 14.5598 41.0979 14.5598 41.7264V46.5075C14.5609 47.2398 14.8476 47.9422 15.358 48.4626L16.6901 49.8016H23.3098L24.6479 48.4567Z"
          fill="#A1E6E0"
          stroke="#A1E6E0"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6901 49.8016L18.081 51.2057C18.3353 51.4656 18.6383 51.672 18.9722 51.8128C19.3062 51.9536 19.6645 52.0262 20.0264 52.0262C20.3883 52.0262 20.7466 51.9536 21.0806 51.8128C21.4145 51.672 21.7175 51.4656 21.9718 51.2057L23.3098 49.8253L16.6901 49.8016Z"
          fill="#00A499"
          stroke="#00A499"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.358 48.4627L18.081 51.2057C18.3353 51.4656 18.6383 51.672 18.9722 51.8128C19.3062 51.9537 19.6645 52.0262 20.0264 52.0262C20.3883 52.0262 20.7466 51.9537 21.0806 51.8128C21.4145 51.672 21.7175 51.4656 21.9718 51.2057L24.6479 48.4567L14.5598 46.5076"
          stroke="#00404D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M25.399 45.844L14.5598 43.7408" stroke="#00404D" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M25.399 43.0773L14.5598 40.98" stroke="#00404D" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M24.5951 40.1683L17.2946 38.8057" stroke="#00404D" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M16.9073 38.8057C16.2847 38.8057 15.6876 38.556 15.2474 38.1116C14.8072 37.6671 14.5599 37.0644 14.5599 36.4359V35.7308C14.5511 34.8361 14.3417 33.9549 13.9473 33.1535C13.5529 32.3521 12.9839 31.6514 12.2829 31.1038C10.7155 29.8379 9.47817 28.2047 8.67837 26.3462C7.87858 24.4877 7.54054 22.4601 7.69366 20.4396C8.11032 14.9061 13.1103 9.65697 18.5739 9.05267C21.2235 8.75427 23.8974 9.3313 26.1946 10.6972C28.4918 12.063 30.2883 14.1441 31.3145 16.6281C32.3408 19.1121 32.5415 21.8651 31.8865 24.4741C31.2316 27.083 29.7562 29.4072 27.6819 31.0979C26.9801 31.6491 26.4103 32.3529 26.0151 33.1571C25.6199 33.9613 25.4093 34.845 25.3991 35.7427V36.4359C25.3991 37.0644 25.1517 37.6671 24.7115 38.1116C24.2713 38.556 23.6742 38.8057 23.0516 38.8057H22.6643L19.953 36.0389L17.189 38.8057H16.9073Z"
          stroke="#00404D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.8251 37.6978C16.5138 37.6978 16.2153 37.573 15.9952 37.3507C15.7751 37.1285 15.6514 36.8271 15.6514 36.5129V35.7309C15.6454 34.6662 15.4005 33.6167 14.9349 32.6614C14.4694 31.706 13.7954 30.8697 12.9636 30.2151C11.241 28.8149 9.96651 26.9322 9.29997 24.8033C8.63342 22.6744 8.60457 20.3942 9.21702 18.2489C9.82947 16.1035 11.0559 14.1885 12.7425 12.7443C14.4291 11.3001 16.5007 10.3909 18.6972 10.131C19.1242 10.0876 19.5533 10.0678 19.9824 10.0717C22.299 10.0821 24.5558 10.8157 26.4431 12.1719C28.3304 13.5282 29.7559 15.4407 30.5241 17.6471C31.2922 19.8536 31.3655 22.2459 30.7337 24.496C30.1019 26.7461 28.7961 28.7438 26.9953 30.2151C26.1645 30.8696 25.4908 31.705 25.0244 32.6591C24.558 33.6131 24.3109 34.6611 24.3017 35.7249V36.4951C24.3017 36.8094 24.178 37.1108 23.9579 37.333C23.7378 37.5552 23.4392 37.68 23.1279 37.68L16.8251 37.6978Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.3838 21.666L19.9237 36.0389L15.6221 21.666H24.3838Z"
          stroke="#F79C94"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.203 21.3934C31.1968 18.3916 30.0125 15.5146 27.9094 13.3926C25.8063 11.2705 22.9558 10.0764 19.9824 10.0717C19.553 10.0717 19.124 10.0954 18.6972 10.1428C16.5738 10.3941 14.5657 11.2526 12.9093 12.6172C11.2529 13.9819 10.0171 15.7958 9.34753 17.8456C8.67792 19.8954 8.60232 22.0956 9.12962 24.1873C9.65693 26.2789 10.7652 28.175 12.3239 29.6522C11.1165 28.242 10.3128 26.5261 9.9994 24.6891C9.68602 22.8521 9.8748 20.9636 10.5454 19.2269C11.2161 17.4902 12.3431 15.971 13.8053 14.8327C15.2676 13.6945 17.0096 12.9804 18.8439 12.7673C19.2337 12.7245 19.6255 12.7028 20.0176 12.7021C21.9541 12.7139 23.847 13.2845 25.4728 14.3467C27.0986 15.4089 28.3896 16.9184 29.1934 18.6971C29.9972 20.4758 30.2804 22.4495 30.0094 24.3853C29.7385 26.3211 28.9248 28.1384 27.6643 29.6226C28.7814 28.5691 29.672 27.2949 30.2808 25.8793C30.8896 24.4636 31.2035 22.9367 31.203 21.3934Z"
          fill="white"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_1531">
          <rect width="40" height="52.3077" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
