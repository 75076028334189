import * as React from 'react';
import { Query } from '@apollo/client/react/components';

import { IInventoryConfig, IOrderInventory } from '@shared/types';
import { ConfigWithSetup } from './config_with_setup';

import * as QUERY from './config.gql';

const DEFAULT_INVENTORY_CONFIG: IInventoryConfig = {
  estimationCategories: [],
  roomCategories: [],
};

export const ConfigWithQuery: React.FC<{
  inventory: IOrderInventory;
  onSelect?(inventory: IOrderInventory): void;
}> = ({ inventory, onSelect, children }) => (
  <Query<IInventoryConfig>
    query={QUERY}
    children={({ data }) => (
      <ConfigWithSetup
        {...DEFAULT_INVENTORY_CONFIG}
        {...data}
        onSelect={onSelect}
        inventory={inventory}
        children={children}
      />
    )}
  />
);
