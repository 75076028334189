import {
  Maybe,
  OrderServiceTypeEnum,
  OrderSubtypeEnum,
  OrderTypeEnum,
  UpcomingStorageOnboardingFragment,
} from '@portal/schema';
import { displayableText } from '@shared/utils/displayable_text';
import { DISPLAYED_SERVICE_TYPE_MAP } from '@shared/utils/order';

const UPDATABLE_SERVICE_TYPES = [OrderServiceTypeEnum.FullService, OrderServiceTypeEnum.CurbsidePickup];

export const onboardingUpdatable = (onboarding?: Maybe<UpcomingStorageOnboardingFragment>) =>
  !onboarding?.started && !!onboarding?.serviceType && UPDATABLE_SERVICE_TYPES.includes(onboarding.serviceType);

export const orderDisplayName = (
  order: {
    type?: Maybe<OrderTypeEnum>;
    serviceType?: Maybe<OrderServiceTypeEnum>;
    subtype?: Maybe<OrderSubtypeEnum>;
  },
  addAppointmentSuffix = true,
) => {
  let name = '';

  if (order.type === OrderTypeEnum.Move) {
    name = 'Moving';
  } else if (order.serviceType === OrderServiceTypeEnum.DropOff && order.type === OrderTypeEnum.Return) {
    name = 'Item Return';
  } else if (order.type === OrderTypeEnum.RetailDelivery && order.subtype) {
    name = displayableText(order.subtype);
  } else if (order.serviceType === OrderServiceTypeEnum.FullService && order.type === OrderTypeEnum.Pickup) {
    name = 'Pickup & Packing';
  } else if (order.type === OrderTypeEnum.Return && order.serviceType === OrderServiceTypeEnum.Facility) {
    name = 'Warehouse Pickup';
  } else if (order.type === OrderTypeEnum.Disposal) {
    name = 'Disposal';
  } else if (order.serviceType) {
    name = DISPLAYED_SERVICE_TYPE_MAP[order.serviceType];
  }

  if (addAppointmentSuffix && order.serviceType !== OrderServiceTypeEnum.Shipment) {
    name += `${name ? ' ' : ''}Appointment`;
  }

  return name;
};
