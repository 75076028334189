import React from 'react';
import { RouteComponentProps, Switch } from 'react-router-dom';

import { invoiceURL } from '@portal/config/routes';
import { RouteWithTitle } from '@portal/components/shared/route_with_title';
import { Spinner } from '@portal/components/helpers';
import { Switch as SwitchCard } from '@portal/components/invoices/switch_payment_method/switch';
import { useInvoiceDetailQuery } from '@portal/schema';
import { View } from '@portal/components/invoices/detail/view';

export const Detail: React.FC<RouteComponentProps<{ invoiceID: string }>> = ({
  match: {
    params: { invoiceID },
  },
}) => {
  const { data, loading } = useInvoiceDetailQuery({ variables: { invoiceID } });

  if (loading) {
    return <Spinner />;
  }

  if (!data || !data.invoice) {
    return <div>Sorry, that invoice does not exist.</div>;
  }

  const { invoice } = data;

  return (
    <Switch>
      <RouteWithTitle
        exact
        path={invoiceURL(':invoiceID', 'switch_card')}
        render={(props) => <SwitchCard invoice={invoice} {...props} />}
        title="Switch Payment Method"
      />
      <RouteWithTitle
        path={invoiceURL(':invoiceID')}
        render={() => <View invoice={invoice} />}
        title="Invoice Details"
      />
    </Switch>
  );
};
