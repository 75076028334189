import { BREAKPOINTS, COLORS, Column, FontWeight, Grid, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Button } from '@portal/components/shared/customer_protected/button';
import { BLOCK_ACTION } from '@portal/components/shared/customer_protected/util';
import {
  useAcceptPaymentPlanOfferMutation,
  PaymentPlanDetail__OfferFragment as PaymentPlanOffer,
  PaymentPlan__Offer__Type as OfferType,
  Status,
} from '@portal/schema';

import { BalanceSummary } from './balance_summary';
import { OfferSummary } from './offer_summary';
import { PaymentMethodSelector } from './payment_method_selector';
import { PaymentSummary } from './payment_summary';
import { Terms } from './terms';

const SummaryContainer = styled.div`
  @media (min-width: ${BREAKPOINTS.MD}) {
    padding-right: 48px;
  }
  padding-bottom: 40px;
`;

const Title = styled(Text.Title)`
  color: ${COLORS.tealPrimary};
  margin-bottom: 24px;
`;

const Subtitle = styled(Text.Title)`
  margin-bottom: 32px;
`;

const ErrorMessage = styled(Text.Callout)`
  color: ${COLORS.toucan};
`;

export const Approved: React.FC<{ offer: PaymentPlanOffer }> = ({ offer }) => {
  const [selectedSourceID, setSelectedSourceID] = useState<string>();
  const [agreed, setAgreed] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [acceptOffer, { loading: accepting }] = useAcceptPaymentPlanOfferMutation();

  const onAccept = async () => {
    setError(undefined);
    const response = await acceptOffer({ variables: { offerID: offer.id, sourceID: selectedSourceID! } });
    if (response?.data?.accept) {
      if (response.data.accept.status === Status.Unprocessable) {
        setError('Your payment was unable to be processed.');
      }
    }
  };

  return (
    <>
      <Title size="large">Welcome, {offer.account.customer.name}.</Title>
      <Subtitle size="medium">
        {offer.type === OfferType.AutopayOptIn
          ? 'Opt into autopay and get a one-time discount!'
          : 'We’ve created a custom payment plan just for you.'}
      </Subtitle>

      <Grid wrap={true}>
        <Column widths={[12, 12, 7]}>
          <SummaryContainer>
            <BalanceSummary offer={offer} />
            <OfferSummary offer={offer} />
          </SummaryContainer>
        </Column>
        <Column widths={[12, 12, 5]}>
          <PaymentSummary offer={offer} />
          <PaymentMethodSelector
            showError={!!error && !BLOCK_ACTION}
            selectedSourceID={selectedSourceID}
            setSelectedSourceID={setSelectedSourceID}
          />
          <Terms offer={offer} setAgreed={setAgreed} />

          {error && <ErrorMessage weight={FontWeight.Medium}>{error}</ErrorMessage>}
          <Button
            size="medium"
            kind="primary"
            fullWidth
            onClick={onAccept}
            loading={accepting}
            disabled={!selectedSourceID || !agreed || accepting}
            onActionBlocked={(errorMessage) => setError(errorMessage)}
          >
            {offer.type === OfferType.AutopayOptIn ? 'Opt Into Autopay' : 'Accept Payment Plan'}
          </Button>
        </Column>
      </Grid>
    </>
  );
};
