import { Text } from '@clutter/clean';
import React from 'react';

import { OrderSubtypeEnum, OrderTypeEnum } from '@portal/schema';
import { Spacer } from '@shared/components/helpers/spacer';
import { DateTime, Duration } from 'luxon';
import { datetime, formatDate, formatTime, hasMatchingArrivalTimeWindows } from '@portal/utils/scheduling';
import { AppointmentDetailsOrder } from './full_panel';

export const OrderAppointmentDetailsHeader: React.FC<{ sourceOrder: AppointmentDetailsOrder }> = ({ sourceOrder }) => (
  <>
    <OrderTypeText order={sourceOrder} />
    <Spacer height="16px" />
    <OrderSLA order={sourceOrder} />
  </>
);

// SLA Component Builders

const SingleDayOrderSLA: React.FC<{
  date: DateTime;
  formattedArrivalWindow: string;
}> = ({ date, formattedArrivalWindow }) => (
  <>
    <Text.Title size="large">{formatDate(date)}</Text.Title>
    <Text.Body>We'll arrive between {formattedArrivalWindow}</Text.Body>
  </>
);

const MultiDayOrderSLA: React.FC<{
  initialDate: DateTime;
  finalDate: DateTime;
  constantSLAWindow?: string;
}> = ({ initialDate, finalDate, constantSLAWindow }) => {
  const windowEnd = constantSLAWindow && initialDate.plus(Duration.fromISO(constantSLAWindow));

  return (
    <>
      <Text.Title size="large">
        {formatDate(initialDate)} - {formatDate(finalDate)}
      </Text.Title>
      {constantSLAWindow && (
        <Text.Body>
          We'll arrive between {formatTime(initialDate)} {windowEnd && `- ${formatTime(windowEnd)} each day`}
        </Text.Body>
      )}
    </>
  );
};

const OrderSLA: React.FC<{ order: AppointmentDetailsOrder }> = ({ order }) => {
  const nonTravelSourceAndSuccessors = order.nonTravelSourceAndSuccessors.filter(
    (o) => o.subtype !== OrderSubtypeEnum.Travel,
  );
  const initialWindowStartTime = datetime(nonTravelSourceAndSuccessors[0].scheduled);
  const multiDayOrder = order.multiDay;

  if (multiDayOrder) {
    const constantSLAWindow = hasMatchingArrivalTimeWindows(nonTravelSourceAndSuccessors);
    return (
      <MultiDayOrderSLA
        initialDate={initialWindowStartTime}
        finalDate={datetime(nonTravelSourceAndSuccessors[nonTravelSourceAndSuccessors.length - 1].scheduled)}
        constantSLAWindow={constantSLAWindow ? order.window : undefined}
      />
    );
  }

  return <SingleDayOrderSLA date={initialWindowStartTime} formattedArrivalWindow={order.formattedArrivalWindow} />;
};

const OrderTypeText: React.FC<{ order: AppointmentDetailsOrder }> = ({ order }) => {
  if (order.type === OrderTypeEnum.Move) return <Text.SmallCaps>Move Details</Text.SmallCaps>;
  if (order.type === OrderTypeEnum.RetailDelivery) return <Text.SmallCaps>Delivery Details</Text.SmallCaps>;
  else return <Text.SmallCaps>Move-In Details</Text.SmallCaps>;
};
