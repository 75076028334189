import React from 'react';

import { COLORS } from '@clutter/clean';

export const Plus: React.FC<
  { fillColor?: string; strokeColor?: string; iconColor?: string } & React.HTMLAttributes<SVGElement>
> = ({ fillColor = COLORS.tealPrimary, strokeColor = COLORS.tealPrimary, iconColor = COLORS.cloud }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14" fill={fillColor} stroke={strokeColor} strokeWidth={2} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9985 21.0014C13.9985 21.5537 14.4463 22.0008 14.9985 22C15.5508 21.9992 15.9985 21.5509 15.9985 20.9986V15.9986H20.9986C21.5509 15.9986 21.9992 15.5509 22 14.9986C22.0008 14.4464 21.5537 13.9986 21.0014 13.9986H15.9985V8.99861C15.9985 8.44633 15.5508 7.99923 14.9985 8C14.4463 8.00077 13.9985 8.44911 13.9985 9.00139V13.9986H9.00139C8.44911 13.9986 8.00077 14.4464 8 14.9986C7.99923 15.5509 8.44633 15.9986 8.99861 15.9986H13.9985V21.0014Z"
      fill={iconColor}
    />
  </svg>
);
