import React from 'react';

const DEFAULT_WIDTH = 36;
const DEFAULT_HEIGHT = 32;

export const ThumbsDown: React.FC<{ overrideColor?: string; scale?: number }> = ({ overrideColor, scale = 1.0 }) => {
  const width = DEFAULT_WIDTH * scale;
  const height = DEFAULT_HEIGHT * scale;

  return (
    <svg width={width} height={height} viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.2258 19.564C26.2007 19.2844 23.9269 19.1167 23.0323 20.6823C22.1377 22.2478 20.423 24.8758 19.6774 25.9941L19.1183 30.7468C17.5341 31.2127 14.3656 31.25 14.3656 27.6715C14.3656 24.093 15.2975 20.7754 15.7635 19.564H3.74196C3.74196 19.564 1.5054 19.564 1.5054 17.3274C1.5054 15.6229 2.64202 15.0551 3.18282 14.8813M10.1721 14.8113H3.46239C3.46239 14.8113 3.35163 14.8271 3.18282 14.8813M3.18282 14.8813C3.18282 14.8813 0.66669 14.8113 0.66669 12.5747C0.66669 10.3382 3.18282 10.0586 3.18282 10.0586H4.58067M10.1721 10.0586H4.58067M4.58067 10.0586C4.58067 10.0586 2.90325 9.7204 2.90325 7.76341C2.90325 5.80642 4.86024 5.58548 4.86024 5.58548H10.1721"
        stroke={overrideColor || '#00404D'}
      />
      <path
        d="M6.81723 5.58551C6.81723 5.58551 4.58067 5.02637 4.58067 3.06938C4.58067 1.11239 6.81723 0.553252 6.81723 0.553252H18.5592C19.957 0.553252 23.6473 1.11239 27.2258 3.34895"
        stroke={overrideColor || '#00404D'}
      />
      <rect
        x="35.3335"
        y="20.9032"
        width="8.94624"
        height="20.129"
        transform="rotate(-180 35.3335 20.9032)"
        fill={overrideColor || '#00A499'}
      />
    </svg>
  );
};
