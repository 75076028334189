import React from 'react';
import { Status } from '@portal/schema';

import { VirtualWalkthrough, VirtualWalkthroughForm } from '@portal/components/orders/moving/virtual_walkthrough_form';
import { BaseStepType } from './data';
import { StepContainer } from './step_container';

export const Photos: React.FC<BaseStepType> = (props) => {
  const { next, orderID, setError } = props;

  return (
    <>
      <VirtualWalkthrough orderID={orderID}>
        {({ loading, submit }) => (
          <StepContainer
            {...props}
            loading={loading}
            next={async () => {
              const response = await submit();
              if (response.status === Status.Ok) {
                next();
              } else {
                setError(response.message);
              }
            }}
          >
            <VirtualWalkthroughForm />
          </StepContainer>
        )}
      </VirtualWalkthrough>
    </>
  );
};
