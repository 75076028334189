import { Button, COLORS, Radio } from '@clutter/clean';
import styled from '@emotion/styled';
import React, { useState } from 'react';

import { displayableSource, Spinner } from '@portal/components/helpers';
import { SourceFragment, useSourcesQuery } from '@portal/schema';

import { BuildSourceDialog } from './build_source_dialog';

const TextLink = styled.p`
  text-decoration: underline;
  color: ${COLORS.tealJungle};
  cursor: pointer;
`;

const StyledRadio = styled(Radio.Selector)`
  margin: 12px 0;
`;

const formatForRadio = (source: SourceFragment) => ({ value: source.id, label: displayableSource(source) });

export const Selector: React.FC<{
  useButtonStyle?: boolean;
  disabled?: boolean;
  showDefaultCheckbox?: boolean;
  canAddPrepaid?: boolean;
  selectedSourceID?: string;
  omittedID?: string;
  setSelectedSourceID(sourceID: string): void;
  setSelectedSource?(source?: SourceFragment): void;
}> = ({
  useButtonStyle = false,
  showDefaultCheckbox = true,
  disabled = false,
  canAddPrepaid = false,
  omittedID,
  selectedSourceID,
  setSelectedSourceID,
  setSelectedSource,
}) => {
  const [buildingSource, setBuildingSource] = useState<boolean>(false);

  const { data, loading, refetch } = useSourcesQuery();

  const savedSources = data && data.sources.filter((source) => source.id !== omittedID);

  const onSetSelectedSource = (sourceID?: string) => {
    if (setSelectedSource) {
      setSelectedSource(savedSources?.find((source) => source.id === sourceID));
    }
  };

  const onSourceChange = (sourceID: string) => {
    if (!loading && !disabled) {
      setSelectedSourceID(sourceID);
      onSetSelectedSource(sourceID);
    }
  };

  const onSaveSource = (sourceID?: string) => {
    refetch();
    setBuildingSource(false);
    if (sourceID) {
      setSelectedSourceID(sourceID);
      onSetSelectedSource(sourceID);
    }
  };

  return (
    <>
      {loading && <Spinner />}
      {savedSources && (
        <StyledRadio
          name="source"
          value={selectedSourceID}
          options={savedSources.map((source) => formatForRadio(source))}
          onChange={onSourceChange}
        />
      )}
      {useButtonStyle ? (
        <Button disabled={disabled} kind="secondary" fullWidth onClick={() => setBuildingSource(true)}>
          Add a New Card
        </Button>
      ) : (
        <TextLink onClick={() => setBuildingSource(true)}>Add a new card</TextLink>
      )}
      {buildingSource && (
        <BuildSourceDialog
          showDefaultCheckbox={showDefaultCheckbox}
          canAddPrepaid={canAddPrepaid}
          onClose={() => setBuildingSource(false)}
          onSave={onSaveSource}
        />
      )}
    </>
  );
};
