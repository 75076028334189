import React from 'react';

import { useOrderFinancingIntentQuery } from '@portal/schema';
import { useParams } from 'react-router';
import { FAQ } from './summary/faq';
import { Steps } from './summary/steps';
import { Switch } from './summary/switch';

export const Summary: React.FC = () => {
  const { orderID } = useParams<{ orderID: string }>();
  const { data } = useOrderFinancingIntentQuery({ variables: { orderID } });
  const order = data?.order;
  if (!order) return null;

  return (
    <>
      <Steps order={order} />
      <Switch order={order} />
      <FAQ />
    </>
  );
};
