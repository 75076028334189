import * as React from 'react';

import { referralURL } from '@portal/config/routes';

const DIALOG_HEIGHT = 320;
const DIALOG_WIDTH = 480;

export const Action: React.FC<{
  source: string;
}> = ({ source, children }) => {
  const onClick = () => {
    const URL = referralURL(source, 'twitter');
    open(URL, 'Share on Twitter', `height=${DIALOG_HEIGHT},width=${DIALOG_WIDTH}`);
  };

  return (
    <div onClick={onClick} title="Twitter">
      {children}
    </div>
  );
};
