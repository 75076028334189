/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import styled from '@emotion/styled';
import { COLORS, Text, Tooltip } from '@clutter/clean';

import { itemURL } from '@portal/config/routes';
import { Link } from 'react-router-dom';

import { InventoryItemFragment } from '@portal/schema';
import { InventoryFragment, Content, ItemCard as Card } from '@portal/components/helpers/inventory/item_card';

const ITEM_GROUP_TYPENAME = 'ItemGroup';
const ITEM_TYPENAME = 'Item';

const ShippableContainer = styled.div`
  background: ${COLORS.cloud};
  border-radius: 4px;
  color: ${COLORS.hippo};
  left: 50%;
  margin-bottom: -16px;
  padding: 4px;
  position: absolute;
  text-align: center;
  top: 8px;
  transform: translateX(-50%);
  z-index: 1;
`;

export const ItemCard: React.FC<{
  item: InventoryFragment;
  selected: boolean;
  onSelect(items: InventoryFragment[]): void;
  onDeselect(itemIDs: string[]): void;
  disableShippingTag?: boolean;
  disablePrimaryOrders?: boolean;
}> = ({ item, selected, onSelect, onDeselect, disableShippingTag, disablePrimaryOrders }) => {
  const select = () => {
    if (item.__typename === ITEM_TYPENAME) onSelect([item]);
    if (item.__typename === ITEM_GROUP_TYPENAME) onSelect(item.items);
  };

  const deselect = () => {
    if (item.__typename === ITEM_TYPENAME) onDeselect([item.id]);
    if (item.__typename === ITEM_GROUP_TYPENAME) onDeselect(item.items.map(({ id }) => id));
  };

  const shippingEligible = (item as InventoryItemFragment).shippingEligible && !disableShippingTag;
  const disabledMessage =
    !shippingEligible && disablePrimaryOrders ? (
      <>
        <p>Only shipping and disposals/donations are allowed while another Smart Storage appointment is scheduled.</p>
        <p>Go to the Appointments tab if you would like to update or cancel your existing appointment.</p>
      </>
    ) : undefined;

  const itemCard = (
    <Card.Container data-test-id={`item-${item.uuid}`}>
      <Link
        to={itemURL(item.uuid)}
        onClick={(e) => {
          // This link is technically valid, but wouldn't have the same filters.
          e.preventDefault();

          if (!disabledMessage) {
            if (selected) deselect();
            else select();
          }
        }}
      >
        {shippingEligible && (
          <ShippableContainer>
            <Text.Caption>Shipping Eligible</Text.Caption>
          </ShippableContainer>
        )}
        <Content item={item} selected={selected} disabled={!!disabledMessage} />
      </Link>
    </Card.Container>
  );

  if (disabledMessage) {
    return (
      <Tooltip arrow text={disabledMessage}>
        {itemCard}
      </Tooltip>
    );
  } else {
    return itemCard;
  }
};
