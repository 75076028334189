import React from 'react';

import { Box, COLORS, FontWeight, Text } from '@clutter/clean';
import styled from '@emotion/styled';

import { OrderSupplyKitsQuery } from '@portal/schema';
import { Pluralize } from '@shared/components/helpers';

import { Items as SupplyKitItems } from './items';

type OrderSupplyKits = OrderSupplyKitsQuery['order'];

const Header = styled(Text.Title)`
  font-weight: ${FontWeight.Regular};
  margin-bottom: 24px;
`;

const Caption = styled(Text.Callout)`
  margin-top: 16px;
  color: ${COLORS.storm};
`;

export const SupplyKit: React.FC<{ order: OrderSupplyKits }> = ({ order }) => (
  <Box.Flex flexDirection="column" alignItems="center">
    <Header size="medium">
      You're receiving <Pluralize count={order.retailDeliveryItems[0].quantity} singular="kit" plural="kits" />!
    </Header>
    <SupplyKitItems items={order.retailDeliveryItems[0].summedComponentSummaries} />
    <Caption>We'll grab any leftover supplies on the day of your appointment.</Caption>
  </Box.Flex>
);
