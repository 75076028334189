import * as React from 'react';

import { Spacing, Text } from '@shared/components/bootstrap';

import { useInventoryForSave } from './use_inventory_for_save';

export const FullMoveOutSummary: React.FC = () => {
  const inventory = useInventoryForSave();

  return (
    <Spacing pt={1} pb={3}>
      <Text tag="p" style="info">
        <strong>Are you moving out or storing extra things?</strong>
      </Text>
      <Text tag="p">
        <strong>{inventory.isFullMoveOut ? 'Moving Out' : 'Storage'}</strong>
      </Text>
      <hr />
    </Spacing>
  );
};
