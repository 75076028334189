import styled from '@emotion/styled';
import { COLORS, Column, FontWeight, Grid, Text } from '@clutter/clean';
import React from 'react';

import { currency } from '@shared/utils/currency';

export enum Highlight {
  Primary = 'Primary',
  Danger = 'Danger',
}

const Container = styled.div`
  background-color: ${COLORS.grayBackground};
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 16px;
`;

const Block = styled.div`
  padding: 16px;
  :not(:last-child) {
    border-bottom: 1.5px solid ${COLORS.grayBorder};
  }
`;

const Line = styled(Grid)`
  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const highlightColor = (highlight: Highlight) => {
  switch (highlight) {
    case Highlight.Primary:
      return COLORS.tealPrimary;
    case Highlight.Danger:
      return COLORS.toucan;
  }
};

const Callout = styled(Text.Callout)<{ highlight?: Highlight }>`
  color: ${({ highlight }) => (highlight ? highlightColor(highlight) : COLORS.panther)};
`;

const Caption = styled(Text.Caption)<{ highlight?: Highlight }>`
  color: ${({ highlight }) => (highlight ? highlightColor(highlight) : COLORS.panther)};
`;

const PriceColumn = styled(Column)`
  text-align: right;
`;

const LineItem: React.FC<{
  name: string;
  highlight?: Highlight;
  subItem?: boolean;
}> = ({ name, highlight, subItem = false }) => {
  const TextDisplay = subItem ? Caption : Callout;

  return (
    <Column widths={[8, 8, 8]}>
      <TextDisplay highlight={highlight} weight={FontWeight.Medium}>
        {name}
      </TextDisplay>
    </Column>
  );
};

const LinePrice: React.FC<{
  price: number;
  highlight?: Highlight;
  negative?: boolean;
  subItem?: boolean;
  useFree?: boolean;
  kind?: '';
}> = ({ price, highlight, negative = false, subItem = false, useFree = true }) => {
  const TextDisplay = subItem ? Caption : Callout;

  return (
    <PriceColumn widths={[4, 4, 4]}>
      <TextDisplay highlight={highlight} weight={FontWeight.Medium}>
        {negative && !!price && '-'}
        {price || !useFree ? currency(price) : 'Free'}
      </TextDisplay>
    </PriceColumn>
  );
};

const Combined = {
  Container: Container,
  Block: Block,
  Line: Line,
  LineItem: LineItem,
  LinePrice: LinePrice,
};

export { Combined as TotalsTable };
