import { AriaAttributes, SVGAriaEnhancements } from '@shared/components/helpers/svg_aria_enhancements';
import React, { FC } from 'react';

export const CargoVan: FC<AriaAttributes> = ({ title, description: desc }) => {
  const Aria = SVGAriaEnhancements(title, desc);

  return (
    <svg
      width="40"
      height="37"
      viewBox="0 0 40 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={Aria.ariaLabelledby}
    >
      <Aria.Title />
      <Aria.Desc />
      <g clipPath="url(#clip0_22_1548)">
        <path
          d="M38.4386 18.9426L26.8675 15.1148C26.2892 14.9234 25.759 14.622 25.2964 14.2392C24.9446 13.9474 24.6361 13.6124 24.3807 13.2297L18.8771 5.02392L17.094 2.36842C16.2024 1.03828 14.694 0.239235 13.0843 0.239235H0.240967V28.9474H39.759V20.756C39.759 19.933 39.2289 19.201 38.4386 18.9426Z"
          fill="#00A499"
          stroke="#00A499"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.240967 23.6842H26.9831C30.8 23.6842 33.5566 25.8852 34.3807 29.0431"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.240967 31.8182H38.7952C39.3253 31.8182 39.759 31.3876 39.759 30.8612V27.0335C39.759 26.5072 39.3253 26.0766 38.7952 26.0766H33.494L32.9108 27.6794C32.6361 28.4402 31.9084 28.9474 31.0988 28.9474H0.240967V31.8182Z"
          fill="#00404D"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.240967 36.6029H39.759"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.7157 30.3828H0.240967"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.240967 23.2057H27.2289C30.7518 23.2057 33.7494 25.445 34.8627 28.5646"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.2289 36.6029C30.1567 36.6029 32.5301 34.2465 32.5301 31.3397C32.5301 28.4329 30.1567 26.0766 27.2289 26.0766C24.3012 26.0766 21.9277 28.4329 21.9277 31.3397C21.9277 34.2465 24.3012 36.6029 27.2289 36.6029Z"
          fill="#00404D"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.229 34.2105C28.8259 34.2105 30.1205 32.9252 30.1205 31.3397C30.1205 29.7542 28.8259 28.4689 27.229 28.4689C25.632 28.4689 24.3374 29.7542 24.3374 31.3397C24.3374 32.9252 25.632 34.2105 27.229 34.2105Z"
          fill="#A1E6E0"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.2289 32.5359C27.8943 32.5359 28.4337 32.0003 28.4337 31.3397C28.4337 30.6791 27.8943 30.1435 27.2289 30.1435C26.5635 30.1435 26.0241 30.6791 26.0241 31.3397C26.0241 32.0003 26.5635 32.5359 27.2289 32.5359Z"
          fill="#A1E6E0"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.240967 16.9856H24.3374C24.8675 16.9856 25.3012 17.4163 25.3012 17.9426V22.7129C25.3012 23.1244 25.0362 23.488 24.6458 23.6172C22.347 24.378 20.4964 26.1196 19.6096 28.3493C19.4651 28.7129 19.1133 28.9474 18.7229 28.9474C16.4048 28.9474 8.92049 28.9474 8.92049 28.9474V2.63158C8.92049 2.10526 9.35422 1.67464 9.88434 1.67464H10.3663"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.8651 28.7895C32.2891 29.5455 32.5301 30.4163 32.5301 31.3397C32.5301 31.5024 32.5205 31.6603 32.5108 31.8182"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8434 13.1579C10.8434 13.9522 11.4892 14.5933 12.2892 14.5933H25.3012V14.2392C24.9494 13.9474 24.641 13.6124 24.3856 13.2297L18.8771 5.02393H12.2892C11.4892 5.02393 10.8434 5.66507 10.8434 6.45933V13.1579Z"
          fill="#A1E6E0"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.759 22.1914C39.759 21.3684 39.2289 20.6364 38.4386 20.378L26.8675 16.5502C26.2892 16.3589 25.759 16.0574 25.2964 15.6746C24.9446 15.3828 24.6361 15.0478 24.3807 14.6651L18.8771 6.45933"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.240967 1.67464H13.0843C14.694 1.67464 16.2024 2.47368 17.094 3.80383L24.3374 14.5933H12.2892"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.9133 5.02393L24.3374 14.5933"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.2771 21.2919H39.759V25.1196H38.3132V22.2488C38.3132 21.7225 38.747 21.2919 39.2771 21.2919Z"
          fill="#00404D"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.7952 23.2057H39.759"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.8674 25.1196H37.8313V21.2918H36.8674V25.1196Z"
          fill="#FFEABF"
          stroke="#FFEABF"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.8313 25.1196H36.8675V21.2919"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.5181 10.7656V14.5933C20.3181 14.5933 20.9639 13.9522 20.9639 13.1579V12.201C20.9639 11.4067 20.3181 10.7656 19.5181 10.7656Z"
          fill="#00404D"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_1548">
          <rect width="40" height="36.8421" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
