import { DateTime } from 'luxon';
import React from 'react';

import { TotalsTable, Highlight } from '@portal/components/shared/totals_table';
import { InvoiceDetailFragment } from '@portal/schema';

import { ITransaction, TransactionKind, TransactionStatus } from './transaction';

const Transaction: React.FC<{ transaction: ITransaction }> = ({ transaction }) => {
  const refund = transaction.kind === TransactionKind.Refund;
  const lineItem = `${refund ? 'Refund' : 'Paid'} - ${transaction.date.toLocaleString(DateTime.DATE_FULL)}`;
  const highlight = refund ? Highlight.Primary : undefined;

  return (
    <TotalsTable.Block>
      <TotalsTable.Line>
        <TotalsTable.LineItem name={lineItem} subItem={true} highlight={highlight} />
        <TotalsTable.LinePrice price={transaction.amount} highlight={highlight} negative={refund} subItem={true} />
      </TotalsTable.Line>
    </TotalsTable.Block>
  );
};

const FinalTotal: React.FC<{
  total: number;
  amountOwed: number;
  amountPaid: number;
  forgiven: boolean;
}> = ({ total, amountOwed, amountPaid, forgiven }) => {
  let title;
  let amount;
  if (total < 0) {
    title = 'Total Credited';
    amount = Math.abs(total);
  } else if (amountOwed > 0 || forgiven) {
    title = 'Total Due';
    amount = amountOwed;
  } else {
    title = 'Total Paid';
    amount = amountPaid;
  }

  return (
    <TotalsTable.Block>
      <TotalsTable.Line>
        <TotalsTable.LineItem name={title} />
        <TotalsTable.LinePrice price={amount} useFree={false} />
      </TotalsTable.Line>
    </TotalsTable.Block>
  );
};

export const Totals: React.FC<{
  invoice: InvoiceDetailFragment;
  transactions: ITransaction[];
}> = ({ invoice, transactions }) => {
  const { amountDue, amountOwed, appliedCredits, discountAmount, forgiven, subtotal, tax, total } = invoice;
  const discountedTotal = subtotal - discountAmount;
  const successfulTransactions = transactions.filter((txn) => txn.status === TransactionStatus.Successful);
  const amountPaid = successfulTransactions.reduce((amount, txn) => {
    if (txn.kind === TransactionKind.Refund) {
      amount -= txn.amount;
    } else {
      amount += txn.amount;
    }
    return amount;
  }, 0);

  return (
    <TotalsTable.Container>
      {!!discountAmount && (
        <TotalsTable.Block>
          <TotalsTable.Line>
            <TotalsTable.LineItem name="Total" />
            <TotalsTable.LinePrice price={subtotal} useFree={false} />
          </TotalsTable.Line>
          <TotalsTable.Line>
            <TotalsTable.LineItem name="Discount" highlight={Highlight.Primary} subItem={true} />
            <TotalsTable.LinePrice
              price={discountAmount}
              highlight={Highlight.Primary}
              negative={true}
              subItem={true}
            />
          </TotalsTable.Line>
        </TotalsTable.Block>
      )}
      {(!!tax || !!appliedCredits) && (
        <TotalsTable.Block>
          <TotalsTable.Line>
            <TotalsTable.LineItem name="Total" subItem={true} />
            <TotalsTable.LinePrice price={discountedTotal} subItem={true} useFree={false} />
          </TotalsTable.Line>
          {!!tax && (
            <TotalsTable.Line>
              <TotalsTable.LineItem name="Tax" subItem={true} />
              <TotalsTable.LinePrice price={tax} subItem={true} />
            </TotalsTable.Line>
          )}
          {!!appliedCredits && (
            <TotalsTable.Line>
              <TotalsTable.LineItem name="Credit" highlight={Highlight.Primary} subItem={true} />
              <TotalsTable.LinePrice
                price={appliedCredits}
                highlight={Highlight.Primary}
                negative={true}
                subItem={true}
              />
            </TotalsTable.Line>
          )}
        </TotalsTable.Block>
      )}
      <TotalsTable.Block>
        <TotalsTable.Line>
          <TotalsTable.LineItem name="Total" />
          <TotalsTable.LinePrice price={amountDue} useFree={false} />
        </TotalsTable.Line>
      </TotalsTable.Block>
      {successfulTransactions.map((transaction, idx) => (
        <Transaction key={`txn-${idx}`} transaction={transaction} />
      ))}
      {forgiven && (
        <TotalsTable.Block>
          <TotalsTable.Line>
            <TotalsTable.LineItem name="Due" />
            <TotalsTable.LinePrice price={amountDue} />
          </TotalsTable.Line>
          <TotalsTable.Line>
            <TotalsTable.LineItem name="Amount forgiven" highlight={Highlight.Primary} subItem={true} />
            <TotalsTable.LinePrice price={amountDue} highlight={Highlight.Primary} negative={true} subItem={true} />
          </TotalsTable.Line>
        </TotalsTable.Block>
      )}
      <FinalTotal total={total} amountOwed={amountOwed} amountPaid={amountPaid} forgiven={forgiven} />
    </TotalsTable.Container>
  );
};
