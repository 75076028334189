import { pick } from 'lodash';
import * as React from 'react';

import { ClaimPropertySelectionFragment, Status, useBuildClaimPropertySelectionMutation } from '@portal/schema';

import { ClaimConsumer, ClaimPropertySelectionConsumer } from '@portal/contexts';

import { Draft } from '@portal/components/helpers/draft';
import { Alert } from '@shared/components/bootstrap';

import { Step } from '@portal/components/claims/step';

interface IFormRenderProps {
  loading: boolean;
  selection: ClaimPropertySelectionFragment;
  onChange(selection: ClaimPropertySelectionFragment): void;
}

type Field = 'name' | 'declaration' | 'ownership' | 'owner';

interface IFormProps {
  field: Field;
  children(props: IFormRenderProps): React.ReactNode;
}

export const ClaimPropertySelectionForm = ({ field, children }: IFormProps) => {
  const [mutate, { loading, data }] = useBuildClaimPropertySelectionMutation();
  return (
    <ClaimConsumer>
      {({ claim: { uuid } }) => (
        <ClaimPropertySelectionConsumer>
          {({ selection, onChange: onSave }) => (
            <Draft draft={selection}>
              {({ draft, onChange }) => (
                <Step
                  saving={loading}
                  valid={!!draft[field]}
                  onSave={async () => {
                    const input = { [field]: draft[field] };
                    if (field === 'ownership' && draft.owner) {
                      input.owner = pick(draft.owner, ['email', 'phone', 'name']);
                    }
                    const result = await mutate({
                      variables: {
                        uuid,
                        input,
                      },
                    });
                    if (!result || !result.data?.buildClaimPropertySelection) {
                      return Status.Unprocessable;
                    }
                    const { status } = result.data.buildClaimPropertySelection;
                    if (status === Status.Ok) {
                      onSave(draft);
                    }
                    return status;
                  }}
                >
                  {data && data.buildClaimPropertySelection?.error && (
                    <Alert style="danger">{data.buildClaimPropertySelection.error}</Alert>
                  )}
                  {children({ loading, selection: draft, onChange })}
                </Step>
              )}
            </Draft>
          )}
        </ClaimPropertySelectionConsumer>
      )}
    </ClaimConsumer>
  );
};
