import { History } from 'history';
import React from 'react';

import {
  claimIssueSelectionURL,
  claimItemSelectionURL,
  claimPropertySelectionURL,
  claimURL,
} from '@portal/config/routes';

import { ClaimItemSelectionKind, DetailedClaimFragment } from '@portal/schema';

import { HEAD as ISSUE_SELECTION_HEAD, TAIL as ISSUE_SELECTION_TAIL } from './issue_selection/wizard';
import { HEAD as ITEM_SELECTION_HEAD, TAIL as ITEM_SELECTION_TAIL } from './item_selection/wizard';
import { HEAD as PROPERTY_SELECTION_HEAD, TAIL as PROPERTY_SELECTION_TAIL } from './property_selection/wizard';

type Section =
  | 'start'
  | 'kind'
  | 'ready'
  | 'damages'
  | 'lost'
  | 'confirm'
  | 'thanks'
  | 'issue_selection'
  | 'item_selection'
  | 'property_selection';

interface IStep {
  selectionID?: string;
  section?: Section;
  headURL?: string;
  tailURL?: string;
  baseURL?: string;
}

interface IStepsProps {
  claim: DetailedClaimFragment;
  history: History;
  selectionID?: string;
  section?: Section;
  children(args: { onNext(): void; onPrev(): void; onClose(): void }): React.ReactNode;
}

export class Steps extends React.Component<IStepsProps> {
  public render() {
    const { onNext, onPrev, onClose } = this;
    return this.props.children({ onNext, onPrev, onClose });
  }

  private get steps(): IStep[] {
    const { claim } = this.props;
    if (claim.hasIssue) {
      return this.stepsForIssue;
    } else {
      return this.stepsForDamagedOrLost;
    }
  }

  private get stepsForIssue(): IStep[] {
    const { claim } = this.props;
    const steps: IStep[] = [];

    steps.push({ section: 'start', baseURL: claimURL(claim.uuid, 'start') });
    if (claim.hasIssue) {
      steps.push({
        section: 'issue_selection',
        headURL: claimIssueSelectionURL(claim.uuid, ISSUE_SELECTION_HEAD),
        tailURL: claimIssueSelectionURL(claim.uuid, ISSUE_SELECTION_TAIL),
      });
    }
    steps.push({ section: 'confirm', baseURL: claimURL(claim.uuid, 'confirm') });
    steps.push({ section: 'thanks', baseURL: claimURL(claim.uuid, 'thanks') });
    return steps;
  }

  private get stepsForDamagedOrLost(): IStep[] {
    const { claim } = this.props;
    const { itemSelections } = claim;
    const steps: IStep[] = [];

    steps.push({ section: 'start', baseURL: claimURL(claim.uuid, 'start') });
    steps.push({ section: 'kind', baseURL: claimURL(claim.uuid, 'kind') });
    steps.push({ section: 'ready', baseURL: claimURL(claim.uuid, 'ready') });

    if (claim.hasDamagedItem) {
      steps.push({ section: 'damages', baseURL: claimURL(claim.uuid, 'damages') });
      const substeps = itemSelections
        .filter(({ kind }) => kind === ClaimItemSelectionKind.Damaged)
        .map(
          ({ id: selectionID }): IStep => ({
            selectionID,
            section: 'item_selection',
            headURL: claimItemSelectionURL(claim.uuid, selectionID, ITEM_SELECTION_HEAD),
            tailURL: claimItemSelectionURL(claim.uuid, selectionID, ITEM_SELECTION_TAIL),
          }),
        );
      steps.push(...substeps);
    }

    if (claim.hasLostItem) {
      steps.push({ section: 'lost', baseURL: claimURL(claim.uuid, 'lost') });
      const substeps = itemSelections
        .filter(({ kind }) => kind === ClaimItemSelectionKind.Lost)
        .map(
          ({ id: selectionID }): IStep => ({
            selectionID,
            section: 'item_selection',
            headURL: claimItemSelectionURL(claim.uuid, selectionID, ITEM_SELECTION_HEAD),
            tailURL: claimItemSelectionURL(claim.uuid, selectionID, ITEM_SELECTION_TAIL),
          }),
        );
      steps.push(...substeps);
    }

    if (claim.hasDamagedProperty) {
      steps.push({
        section: 'property_selection',
        headURL: claimPropertySelectionURL(claim.uuid, PROPERTY_SELECTION_HEAD),
        tailURL: claimPropertySelectionURL(claim.uuid, PROPERTY_SELECTION_TAIL),
      });
    }

    steps.push({ section: 'confirm', baseURL: claimURL(claim.uuid, 'confirm') });
    steps.push({ section: 'thanks', baseURL: claimURL(claim.uuid, 'thanks') });
    return steps;
  }

  private onNext = () => {
    const { section, selectionID, history } = this.props;

    const { steps } = this;
    const currentIndex = steps.findIndex((s) => s.section === section && s.selectionID === selectionID);
    const step = steps[currentIndex + 1];

    history.push(step.baseURL ? step.baseURL : step.headURL!);
  };

  private onPrev = () => {
    const { section, selectionID, history } = this.props;

    const { steps } = this;
    const currentIndex = steps.findIndex((s) => s.section === section && s.selectionID === selectionID);
    const step = steps[currentIndex - 1];

    history.push(step.baseURL ? step.baseURL : step.tailURL!);
  };

  private onClose = () => {
    const { history } = this.props;
    history.push('/claims');
  };
}
