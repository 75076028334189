import React from 'react';

import { AccountResolution__OfferFragment } from '@portal/schema';

import { RentalList } from './rental_list';

export const Accepted: React.FC<{ offer: AccountResolution__OfferFragment }> = ({ offer }) => (
  <>
    Your storage rate will remain the same for the following units:
    <RentalList rateAdjustments={offer.metadata.eligible_rate_adjustments} eligible={true} />
  </>
);
