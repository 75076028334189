import { Button, COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Spinner } from '@portal/components/helpers';
import { Close as CloseIcon } from '@portal/components/shared/icons';
import { SuccessFlash } from '@portal/components/shared/success_flash';
import { Status, useCustomerEmailQuery, useSendReceiptMutation } from '@portal/schema';
import { Modal } from '@shared/components/bootstrap';

const SUCCESS_MESSAGE = 'Your receipt has been emailed! Please allow up to 10 minutes for the email to arrive.';

const ModalBody = styled(Modal.Body)`
  padding: 0 24px 24px 24px;
  text-align: center;
`;

const CloseContainer = styled.div`
  height: 38px;
  text-align: right;
  padding: 10px 10px 0;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const Prompt = styled(Text.Body)`
  margin: 16px 0;
`;

const ErrorMessage = styled(Text.Callout)`
  color: ${COLORS.toucan};
  margin-bottom: 8px;
`;

export const SendModal: React.FC<{
  invoiceID: string;
  displayID: string;
  onClose(): void;
  onSuccess(): void;
}> = ({ invoiceID, displayID, onClose, onSuccess }) => {
  const { data, loading } = useCustomerEmailQuery();
  const [sendReceipt, { loading: sending }] = useSendReceiptMutation({ variables: { invoiceID } });
  const [error, setError] = useState<string | undefined>(undefined);

  const customerEmail = data?.customer!.email;

  const onSend = async () => {
    setError(undefined);
    const response = await sendReceipt();
    if (response?.data?.sendReceipt?.status === Status.Unprocessable) {
      setError('There was an error sending your receipt. Please try again.');
    } else {
      onSuccess();
      onClose();
    }
  };

  return (
    <Modal onClose={onClose} centered>
      <Modal.Content>
        <CloseContainer>
          <StyledCloseIcon onClick={onClose} />
        </CloseContainer>
        <ModalBody>
          <Text.Title size="small">Email my receipt</Text.Title>
          {loading && <Spinner />}
          {customerEmail && (
            <>
              <Prompt>
                We will send an email receipt for invoice #{displayID} to {customerEmail}
              </Prompt>
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <Button kind="primary" size="small" loading={sending} disabled={sending} onClick={onSend} fullWidth>
                Send
              </Button>
            </>
          )}
        </ModalBody>
      </Modal.Content>
    </Modal>
  );
};

export const EmailReceiptButton: React.FC<{ invoiceID: string; displayID: string }> = ({ invoiceID, displayID }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSuccessFlash, setShowSucessFlash] = useState<boolean>(false);

  return (
    <>
      {showModal && (
        <SendModal
          invoiceID={invoiceID}
          displayID={displayID}
          onClose={() => setShowModal(false)}
          onSuccess={() => setShowSucessFlash(true)}
        />
      )}
      {showSuccessFlash && <SuccessFlash message={SUCCESS_MESSAGE} onFadeout={() => setShowSucessFlash(false)} />}
      <Button kind="secondary" size="small" fullWidth onClick={() => setShowModal(true)}>
        Email Receipt
      </Button>
    </>
  );
};
