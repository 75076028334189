import { Button } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Billing__InvoiceFragment } from '@portal/schema';
import { formatDate } from '@shared/utils/format_date';

import { Card } from '../card';

import { CardContainer } from '../sections';

const ButtonContainer = styled.div`
  padding-top: 16px;
  margin: 0 8px;
`;

export const InvoiceList: React.FC<{
  invoices: Billing__InvoiceFragment[];
  invoiceSelections: { [key: string]: boolean };
  disabled?: boolean;
  setInvoiceSelections(mappedInvoices: { [key: string]: boolean }): void;
}> = ({ invoices, invoiceSelections, disabled = false, setInvoiceSelections }) => {
  const allInvoicesSelected = invoices.every((invoice) => invoiceSelections[invoice.id]);

  const onSelectAll = () => {
    const updatedInvoiceSelections: { [key: string]: boolean } = {};
    invoices.forEach(({ id }) => {
      updatedInvoiceSelections[id] = true;
    });
    setInvoiceSelections(updatedInvoiceSelections);
  };

  return (
    <>
      {invoices.map((invoice) => {
        const description = invoice.date ? `Owed on ${formatDate(invoice.date)}` : undefined;
        return (
          <CardContainer key={`invoice-${invoice.id}`}>
            <Card
              name={invoice.displayName}
              amount={invoice.amountDue}
              description={description}
              disabled={disabled}
              selected={invoiceSelections[invoice.id] || false}
              setSelected={(selected) => setInvoiceSelections({ ...invoiceSelections, [invoice.id]: selected })}
              themeName="invoice"
            />
          </CardContainer>
        );
      })}
      <ButtonContainer>
        <Button
          size="medium"
          kind="secondary"
          fullWidth
          disabled={allInvoicesSelected || disabled}
          onClick={onSelectAll}
        >
          Select All Invoices
        </Button>
      </ButtonContainer>
    </>
  );
};
