import React from 'react';

import { UpcomingSubscriptionDetailsFragment } from '@portal/schema';

import { Name } from './name';

export const List: React.FC<{ subscriptions: UpcomingSubscriptionDetailsFragment[] }> = ({ subscriptions }) => (
  <>
    {subscriptions.map((subscription, index) => (
      <React.Fragment key={subscription.id}>
        {index > 0 && ' + '}
        <Name subscription={subscription} />
      </React.Fragment>
    ))}
  </>
);
