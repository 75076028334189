/* eslint-disable no-underscore-dangle */
import { COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';
import { TrackedLink } from '@portal/components/wt/tracked_link';
import { LightBulb } from '@root/images/light_bulb';
import { IconEntry, TealLink, TextEntry } from './fine_print';

const FAQ_LINK = 'https://www.clutter.com/help';

const FAQCardStyle = styled.div`
  display: flex;
  align-items: center;
  padding: 48px 24px;
  background: ${COLORS.grayBackground};
  border: ${COLORS.tealJungle} 1px solid;
  border-radius: 4px;
`;

const FAQ_COPY_PARTS: [string, string, string] = [
  'Check out the',
  'FAQ’s on our Knowledge Base',
  'to learn even more about Clutter and making your move as easy as possible.',
];

export const FAQCard = () => (
  <FAQCardStyle>
    <IconEntry>
      <LightBulb title="Frequency Asked Questions" description="Light Bulb" />
    </IconEntry>
    <TextEntry>
      <Text.Headline>Have more questions?</Text.Headline>
      <Text.Callout color={COLORS.hippo}>
        {FAQ_COPY_PARTS[0]}&nbsp;
        <TrackedLink
          params={{
            pageName: 'portal:appointment_details',
            container: 'faq',
            action: 'click',
            objectType: 'link',
            objectName: 'faq',
            label: FAQ_COPY_PARTS.join(' '),
            value: FAQ_LINK,
          }}
        >
          <TealLink href="https://www.clutter.com/help" target="_blank">
            {FAQ_COPY_PARTS[1]}
          </TealLink>
        </TrackedLink>
        &nbsp;{FAQ_COPY_PARTS[2]}
      </Text.Callout>
    </TextEntry>
  </FAQCardStyle>
);
