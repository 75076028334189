import { Text } from '@clutter/clean';
import styled from '@emotion/styled';
import * as React from 'react';

const Title = styled(Text.Title)`
  padding: 16px 0;
`;

const Details = styled(Text.Body)`
  padding-bottom: 24px;
`;

const ROOT_TITLE = 'I need help with...';
const LEAF_WITH_CONTACT_PREFERENCES_TITLE = 'How can we help?';
const LEAF_WITH_CONTACT_PREFERENCES_COPY =
  'Tell us more about your issue, and select a preferred contact method to get in touch with our Customer Support team.';
const REJECTED_OFFER_COPY = 'We’re sorry that this solution doesn’t work for you.';

const title = (isLeaf: boolean, isRoot: boolean, reasonCopy: string, showHelpContent: boolean): string => {
  if (isLeaf && !showHelpContent) {
    return LEAF_WITH_CONTACT_PREFERENCES_TITLE;
  } else if (isRoot) {
    return ROOT_TITLE;
  } else {
    return reasonCopy;
  }
};

export const Prompt: React.FC<{
  isLeaf: boolean;
  isRoot: boolean;
  reasonCopy: string;
  showHelpContent: boolean;
  offerRejected?: boolean;
}> = ({ isLeaf, isRoot, reasonCopy, showHelpContent, offerRejected }) => (
  <>
    <Title size="large">{title(isLeaf, isRoot, reasonCopy, showHelpContent)}</Title>
    {isLeaf && !showHelpContent && (
      <Details>
        {offerRejected && REJECTED_OFFER_COPY} {LEAF_WITH_CONTACT_PREFERENCES_COPY}
      </Details>
    )}
  </>
);
