import React from 'react';

import styled from '@emotion/styled';

import { Text, COLORS } from '@clutter/clean';

type Kind = 'primary' | 'secondary' | 'danger';

const styles = {
  primary: {
    color: COLORS.cloud,
    bgColor: COLORS.tealDark,
  },
  secondary: {
    color: COLORS.tealDark,
    bgColor: COLORS.cloud,
  },
  danger: {
    color: COLORS.cloud,
    bgColor: COLORS.toucan,
  },
};

const Container = styled.div<{ kind: Kind }>`
  color: ${({ kind }) => styles[kind].color};
  background-color: ${({ kind }) => styles[kind].bgColor};
  border: 1px solid ${({ kind }) => styles[kind].color};
  text-transform: uppercase;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 4px;
`;

interface IBadgeProps {
  text: string;
  kind: Kind;
  className?: string;
}

export const Badge = ({ text, ...props }: IBadgeProps) => (
  <Container {...props}>
    <Text.SmallCaps>{text}</Text.SmallCaps>
  </Container>
);
