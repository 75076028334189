import React from 'react';

const ID = 'mover-with-cart';

export const MoverWithCartFridge: React.FC<{ title?: string }> = ({ title = 'Mover With Cart' }) => (
  <svg aria-labelledby={ID} width="60" height="74" viewBox="0 0 60 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title id={ID}>{title}</title>
    <path
      d="M13.7186 23.7069L19.4472 20.7529"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7889 26.2064L22.5377 22.6465"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.4472 42.7181L24.2714 41.3548C25.1005 41.1276 26.005 41.582 26.2312 42.4909L33.8442 69.6823C34.0704 70.5155 34.9749 71.0457 35.804 70.8184L46.5829 67.713C47.2613 67.4858 48.0151 67.6373 48.6181 68.016L49.8241 68.7734"
      stroke="#F79C94"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.4271 70.8944H30.6784C29.9246 70.8944 29.397 70.2127 29.5477 69.4553L31.0553 61.6538C31.206 60.8207 31.206 59.9118 30.9799 59.0786L30.4522 57.1851"
      stroke="#F79C94"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.4724 72.9393C30.971 72.9393 32.1859 71.7185 32.1859 70.2126C32.1859 68.7066 30.971 67.4858 29.4724 67.4858C27.9737 67.4858 26.7588 68.7066 26.7588 70.2126C26.7588 71.7185 27.9737 72.9393 29.4724 72.9393Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.4724 71.1973C30.0135 71.1973 30.4523 70.7565 30.4523 70.2127C30.4523 69.6689 30.0135 69.228 29.4724 69.228C28.9312 69.228 28.4925 69.6689 28.4925 70.2127C28.4925 70.7565 28.9312 71.1973 29.4724 71.1973Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.0492 0.413015L21.5431 6.31836L39.1529 68.062L59.6591 62.1566L42.0492 0.413015Z"
      fill="white"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5792 6.27462L18.1736 7.25537L35.7835 68.999L39.1891 68.0182L21.5792 6.27462Z"
      fill="white"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.8185 44.9845L30.9067 51.8706L35.8076 69.054L59.7193 62.1679L54.8185 44.9845Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.2965 50.8984L39.196 68.0162"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.8291 51.8833L54.7236 44.9907"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.3166 47.4144L32.7889 45.6723C32.6382 45.0664 32.7136 44.3847 33.0151 43.8545C33.3166 43.3243 33.3919 42.6426 33.2412 42.0367L31.4322 35.7501C31.2814 35.1442 30.8291 34.614 30.3015 34.311C29.7739 34.008 29.3216 33.4778 29.1708 32.8719L26.6834 24.2373"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.6683 66.2741L38.1407 64.5321C37.9899 63.9261 38.0653 63.2445 38.3668 62.7143C38.6683 62.1841 38.7437 61.5024 38.593 60.8965L37.6131 57.3366C37.4623 56.7306 37.01 56.2004 36.4824 55.8975C35.9548 55.5945 35.5025 55.0643 35.3518 54.4584L34.8241 52.7163"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.397 20.0711L30.7538 19.6167"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.0754 19.8438L30.7538 21.8888"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.2362 16.4356L38.4422 15.6782"
      stroke="#FFEABF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.8392 16.0571L38.8945 17.9507"
      stroke="#FFEABF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.2613 19.844L32.8643 17.6475L33.6935 19.7682"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.1809 17.4204L35.3518 17.9506C35.1256 18.1021 35.1256 18.3293 35.2764 18.5565C35.3518 18.708 35.5025 18.7838 35.7286 18.708L36.7839 18.5565C36.9347 18.5565 37.0854 18.6323 37.2362 18.708C37.3869 18.9353 37.3115 19.1625 37.1608 19.314L36.3317 19.8442"
      stroke="#00A499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.6281 16.7388L41.2311 18.1779L41.9849 16.8145"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.2311 18.1777V18.9352"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.18089 42.5667L6.40702 40.2944H13.1909L15.1507 41.8093L14.5477 45.1419L16.4321 57.4879L8.89446 59.5329L6.33164 47.6414C6.03014 45.9751 5.95476 44.3088 6.18089 42.5667Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4.52261 70.5914L12.0603 57.9424L7.23617 55.9731L3.39195 65.8196" fill="white" />
    <path
      d="M4.52261 70.5914L12.0603 57.9424L7.23617 55.9731L3.39195 65.8196"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15.603 70.8189L11.5327 58.8516L16.3568 57.4883L17.7889 67.6377" fill="white" />
    <path
      d="M15.603 70.8189L11.5327 58.8516L16.3568 57.4883L17.7889 67.6377"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5779 71.3489L23.8191 72.1821L23.7437 73.6212H18.9196H15.9045L15.603 70.8187L17.6382 71.1975L18.995 69.6826L20.5779 71.3489Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5628 67.7891L3.69346 69.4554L5.5025 68.8495L6.78391 71.3489L9.27135 72.1064V72.788V73.6212H5.95476L1.05527 70.9702L2.5628 67.7891Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.57286 42.7939L15.2261 39.0825L16.5829 40.9003L16.5075 44.3087L14.8492 57.4878L7.23618 55.973L9.57286 42.7939Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.51758 21.7378C10.2512 22.4195 11.9849 23.1011 13.7186 23.7071C15.3769 25.0704 15.6784 29.312 16.2814 33.8565L17.1105 40.9005L6.33165 40.2946L6.63316 36.8105C3.91959 24.5402 6.03014 24.2373 8.51758 21.7378Z"
      fill="#00A499"
      stroke="#00A499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.794 29.6909C14.3216 30.827 14.9246 32.9478 15 34.7656L19.6734 39.3102L20.201 39.5374C20.5025 39.6889 20.804 39.8404 21.1055 40.0676C21.407 40.2948 21.7085 40.4463 22.01 40.5978L23.2161 41.128C23.1407 41.4309 22.8392 41.6582 22.4623 41.5824L21.3316 41.2795L21.5578 41.8854L22.2362 43.173L19.9749 43.7032C19.598 43.7032 19.1457 43.476 18.9196 43.0215L17.9397 41.0522L12.0603 36.2805L9.12059 30.3726L13.794 29.6909Z"
      fill="white"
    />
    <path
      d="M8.51757 26.2065C8.4422 27.4942 8.51757 29.0847 9.12059 30.4481L12.0603 36.356L17.9397 41.2792L18.9196 43.2485C19.1457 43.703 19.598 43.9302 19.9749 43.9302L22.2362 43.4"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.6734 39.3101L20.201 39.5373C20.5025 39.6888 20.804 39.8403 21.1055 40.0675C21.407 40.2947 21.7085 40.4462 22.01 40.5977L23.2161 41.1279C23.1407 41.4308 22.8392 41.6581 22.4623 41.5823L21.3316 41.2794"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.27135 73.6212H5.95476L1.05527 70.9702"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3417 57.7153L15.5276 52.1104"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.70853 43.6274L7.16079 45.1423C7.31154 45.5967 7.38692 46.1269 7.38692 46.6571L7.31154 50.7472"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0603 21.6621C12.4372 21.2834 12.7387 20.8289 12.8141 20.3745C12.4372 20.2987 12.0603 20.0715 11.8342 19.7686"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6432 18.481C13.8693 18.6324 14.1709 18.6324 14.397 18.6324L14.4724 20.3745H12.8141"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4724 17.7993C14.7739 17.875 15.0754 17.572 15.3015 17.3448L14.3216 15.9815V15.527C14.3216 15.224 14.2462 14.9211 14.0955 14.6938"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.66834 20.3742C8.96985 19.7683 8.96985 18.6322 8.51758 17.9505C8.1407 17.4203 7.68844 16.9659 7.46231 16.3599C7.23618 15.754 7.23618 14.9208 7.61306 14.3906"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8543 16.4358C10.7789 16.2086 10.5528 16.0571 10.3266 16.0571C10.1005 16.0571 9.87438 16.2844 9.799 16.5116C9.72362 16.7388 9.799 16.966 9.87438 17.1933C10.0251 17.4205 10.1759 17.572 10.402 17.6477C10.6281 17.7992 10.8543 17.8749 11.0804 17.9507"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.61307 12.5729C7.8392 12.5729 8.1407 12.5729 8.36684 12.5729C8.44221 12.3457 8.51759 12.1185 8.59297 11.8913C8.8191 11.967 9.19598 11.8155 9.42211 11.8913C9.49749 11.7398 9.64824 11.5126 9.64824 11.2853C9.94975 11.3611 10.2513 11.3611 10.4774 11.5126C10.5528 11.3611 10.7035 11.1338 10.7789 11.0581C11.005 11.2096 11.3065 11.3611 11.4573 11.5126C11.608 11.3611 11.8342 11.2096 11.9095 11.0581C12.0603 11.2096 12.3618 11.3611 12.5126 11.5126C12.6633 11.4368 12.8894 11.4368 13.1156 11.3611C13.1156 11.5883 13.2663 11.7398 13.2663 11.8913C13.4171 11.8913 13.7186 11.8913 13.8693 11.8913C14.0201 12.1185 14.0955 12.4215 14.0201 12.6487C14.1709 12.6487 14.397 12.7244 14.5477 12.7244C14.6231 13.1789 14.4724 13.6333 14.0955 13.9363L13.8693 14.012L10.7035 14.1635L7.61307 13.8606L7.53769 13.4061C7.68844 13.1789 7.61307 12.8002 7.61307 12.5729Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.6231 73.6211H0.376877"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0">
        <rect width="60" height="74" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
