import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApolloError } from '@apollo/client';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { faRedo, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Alert } from '@shared/components/bootstrap/alert';
import { Button } from '@shared/components/bootstrap/button';
import { Text } from '@shared/components/bootstrap/text';

const FALLBACK = `
  We couldn’t connect to the server. Please load the page again when you have better cellular service or a wireless connection.
`;

export const Retry: React.FC<{
  error: ApolloError;
  fallback?: string;
  refetch(): Promise<any>;
}> = ({ error, refetch, fallback = FALLBACK }) => {
  const [promise, setPromise] = useState<undefined | Promise<any>>(undefined);
  useEffect(() => {
    if (!promise) {
      return;
    }
    promise.then(() => setPromise(undefined));
    promise.catch(() => setPromise(undefined));
  }, [promise]);
  const reload = () => {
    setPromise(refetch());
  };

  return (
    <Alert style="danger">
      <Text alignment="center" tag="p">
        {error.graphQLErrors.length ? error.message : fallback}
      </Text>
      <hr />
      <Text alignment="center" tag="p">
        <Button onClick={reload} kind="danger" disabled={!!promise}>
          <FontAwesomeIcon icon={promise ? faSpinner : faRedo} spin={!!promise} /> Retry
        </Button>
      </Text>
    </Alert>
  );
};
