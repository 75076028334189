import React from 'react';
import { compact } from 'lodash';
import { Text } from '@clutter/clean';

import { Header } from '@portal/components/helpers/header';
import { Address, DropOff__Fulfiller } from '@portal/schema';
import { name } from '../../drop_off/utils';

type AddressViewProps = {
  fulfiller: DropOff__Fulfiller;
  address: Pick<Address, 'aptsuite' | 'street' | 'city' | 'state' | 'zip'>;
};

export const AddressView: React.FC<AddressViewProps> = ({
  fulfiller,
  address: { aptsuite, street, city, state, zip },
}) => (
  <>
    <Header tag="h4">Facility Address</Header>

    <Text.Body>
      {name(fulfiller)} - {city}
    </Text.Body>
    <Text.Body>{compact([street, aptsuite]).join(' ')}</Text.Body>
    <Text.Body>{`${city}, ${state}, ${zip}`}</Text.Body>

    <hr />
  </>
);
