import { COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import * as React from 'react';

import { Currency } from '@shared/components/helpers';

import { IShippingOption, formatService } from '../shipping/options';

const PricingRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${COLORS.grayBorder};
  padding: 12px 0;
`;

const PricingRowTotal = styled(PricingRow)`
  font-weight: bold;
`;

const Price = styled.div`
  font-weight: bold;
`;

export const ShippingPrice: React.FC<{ option: IShippingOption; includeService: boolean }> = ({
  option,
  includeService,
}) => {
  const { carrier, service, price, maxPrice } = option;
  return (
    <>
      <PricingRow>
        <div>
          {carrier} {includeService && `(${formatService(carrier, service)})`}
        </div>
        <Price>
          <Currency value={price} precision="automatic" />
        </Price>
      </PricingRow>
      <PricingRowTotal>
        <div>Total</div>
        <Price>
          <Currency value={price} precision="automatic" />
        </Price>
      </PricingRowTotal>
      {price !== maxPrice && (
        <div>
          <PricingRowTotal>
            <div>Do Not Exceed Price</div>
            <Price>
              <Currency value={maxPrice} precision="automatic" />
            </Price>
          </PricingRowTotal>
          <Text.Caption>
            Just a heads-up: Your estimated shipping cost for this order is{' '}
            {<Currency value={price} precision="automatic" />}. We've got your back! Even if things change, like
            shipping rates or item weight, you won't be charged more than{' '}
            {<Currency value={maxPrice} precision="automatic" />}.
          </Text.Caption>
        </div>
      )}
    </>
  );
};
