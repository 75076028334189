import React from 'react';

import { Billing__Invoice__Status, useBillingInvoicesQuery } from '@portal/schema';
import { Spinner } from '@portal/components/helpers';

import { Empty } from '../empty';
import { PayInvoiceForm } from './form';

export const PayInvoices: React.FC = () => {
  const { data, loading } = useBillingInvoicesQuery({
    variables: { status: Billing__Invoice__Status.Failed },
  });

  if (loading) {
    return <Spinner />;
  }

  if (!data || !data.invoices.length) {
    return <Empty />;
  }

  return <PayInvoiceForm invoices={data.invoices} />;
};
