import * as React from 'react';

import { Maybe } from '@portal/schema';
import { Linkify } from '@shared/components/helpers/linkify';
import { Breadcrumbs } from './breadcrumbs';
import { Prompt } from './prompt';

interface IContainerProps {
  breadcrumb: string;
  orderID: string;
  scheduled: string;
  error?: Maybe<string>;
  unconfirmed?: boolean;
}

export const Container: React.FC<IContainerProps> = (props) => {
  const { breadcrumb, orderID, scheduled, error, unconfirmed = false } = props;

  const errors = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (errors.current) {
      errors.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [error]);

  return (
    <>
      <Breadcrumbs active={breadcrumb} orderID={orderID} />
      <Prompt scheduled={scheduled} unconfirmed={unconfirmed} />
      <hr />

      {error && (
        <div className="alert alert-danger" ref={errors}>
          <Linkify text={error} />
        </div>
      )}

      {props.children}
    </>
  );
};
