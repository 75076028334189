import * as React from 'react';
import { Link } from 'react-router-dom';

import { Breadcrumb } from '@shared/components/bootstrap';

import { orderURL } from '@portal/config/routes';

interface IBreadcrumbsProps {
  orderID: string;
  active?: string;
}

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ orderID, active }) => (
  <Breadcrumb>
    <Breadcrumb.Item active={!active}>
      {active ? <Link to={orderURL(orderID)}>Your Appointment</Link> : <>Your Appointment</>}
    </Breadcrumb.Item>
    {active && <Breadcrumb.Item active>{active}</Breadcrumb.Item>}
  </Breadcrumb>
);
