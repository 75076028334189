import * as React from 'react';

import { AddressDetailField, IAddressDetail } from '@shared/types';

import { RadioFormGroup } from '../radio_form_group';

const HEIGHT_IN_INCHES_OF_LARGEST_TRUCK_IN_FLEET = 168; // 14 feet
const HEIGHT_IN_INCHES_OF_SMALLEST_TRUCK_IN_FLEET = 144; // 12 feet

enum MaxTruckHeightAnswer {
  Yes = 'YES',
  No = 'NO',
  Unknown = 'UNKNOWN',
}

const DETAILS_FOR_VALUE = {
  [MaxTruckHeightAnswer.Yes]: {
    [AddressDetailField.MaxTruckHeightUnknown]: false,
    [AddressDetailField.MaxTruckHeight]: HEIGHT_IN_INCHES_OF_LARGEST_TRUCK_IN_FLEET,
  },
  [MaxTruckHeightAnswer.No]: {
    [AddressDetailField.MaxTruckHeightUnknown]: false,
    [AddressDetailField.MaxTruckHeight]: HEIGHT_IN_INCHES_OF_SMALLEST_TRUCK_IN_FLEET,
  },
  [MaxTruckHeightAnswer.Unknown]: {
    [AddressDetailField.MaxTruckHeightUnknown]: true,
    [AddressDetailField.MaxTruckHeight]: null,
  },
};

const OPTIONS = [
  { label: 'Yes', value: MaxTruckHeightAnswer.Yes },
  { label: 'No', value: MaxTruckHeightAnswer.No },
  { label: "I don't know", value: MaxTruckHeightAnswer.Unknown },
];

export const MaxTruckHeight: React.FC<{
  details: IAddressDetail;
  setDetails(details: IAddressDetail): void;
}> = ({ details, setDetails }) => {
  const selection = (() => {
    if (details[AddressDetailField.MaxTruckHeight] === HEIGHT_IN_INCHES_OF_LARGEST_TRUCK_IN_FLEET) {
      return MaxTruckHeightAnswer.Yes;
    } else if (details[AddressDetailField.MaxTruckHeight] === HEIGHT_IN_INCHES_OF_SMALLEST_TRUCK_IN_FLEET) {
      return MaxTruckHeightAnswer.No;
    } else if (details[AddressDetailField.MaxTruckHeightUnknown]) {
      return MaxTruckHeightAnswer.Unknown;
    }
  })();

  return (
    <RadioFormGroup
      name="max-truck-height"
      label="Is the parking location tall enough for us to park a 14 foot tall truck?"
      options={OPTIONS}
      value={selection}
      onChange={(value: MaxTruckHeightAnswer) => {
        setDetails({
          ...details,
          ...DETAILS_FOR_VALUE[value],
        });
      }}
    />
  );
};
