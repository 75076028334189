import { OrderTypeEnum } from '@portal/schema';
import { IInventoryConfig, IOrder, IOrderInventory, OrderStatus } from '@shared/types';
import { buildOrderInventory, filterInventoryForSave } from '@shared/utils';

export const incompleteInventory = (inventory: IOrderInventory) => {
  const { isFullMoveOut, rooms, packableEstimations, unpackableEstimations, extraItems } = inventory;

  return (
    isFullMoveOut === null ||
    ((!rooms || rooms.size === 0) &&
      (!packableEstimations || packableEstimations.size === 0) &&
      (!unpackableEstimations || unpackableEstimations.size === 0) &&
      (!extraItems || extraItems.length === 0))
  );
};

export const incompleteOrder = (order: IOrder, config: IInventoryConfig) =>
  order.services.some((service) => {
    const inventory = buildOrderInventory(service, config);
    return (
      service.type === OrderTypeEnum.Pickup &&
      order.status === OrderStatus.Scheduled &&
      incompleteInventory(filterInventoryForSave(inventory))
    );
  });
