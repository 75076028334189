import { COLORS } from '@clutter/clean';
import React from 'react';

export const BackArrow: React.FC<{ fill?: string }> = ({ fill = COLORS.tealPrimary }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.78899 18.9877C9.58944 19.1786 9.27293 19.1715 9.08206 18.972L0.830718 10.3456C0.645812 10.1523 0.645812 9.84768 0.830718 9.65437L9.08206 1.02797C9.27293 0.828418 9.58944 0.821383 9.78899 1.01226L10.8202 1.99864C11.0198 2.18951 11.0268 2.50602 10.8359 2.70557L5.0194 8.78648L18.6685 8.78648C18.9446 8.78648 19.1685 9.01033 19.1685 9.28648L19.1685 10.7135C19.1685 10.9896 18.9446 11.2135 18.6685 11.2135L5.0194 11.2135L10.8359 17.2944C11.0268 17.4939 11.0198 17.8104 10.8202 18.0013L9.78899 18.9877Z"
      fill={fill}
    />
  </svg>
);
