import { Button, Checkbox, COLORS, Text, TextButton } from '@clutter/clean';
import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Modal } from '@shared/components/bootstrap';

import { PoweredByStripe, Secure } from '@portal/components/shared/icons';
import { BaseModal } from './base_modal';
import { Fields } from './fields';
import { SourceForm } from './form';

const Subtitle = styled(Text.Body)`
  color: ${COLORS.storm};
  margin-bottom: 24px;
`;

const SkipButton = styled.div`
  padding-top: 16px;
`;

const Prompt = styled(Text.Body)`
  color: ${COLORS.hippo};
  margin-bottom: 24px;
`;

const Footer = styled.div`
  background-color: ${COLORS.grayBackground};
  margin: 0 -24px -24px;
  display: flex;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
`;

const SecureSection = styled.div`
  display: flex;
  align-items: center;
`;

const SecureText = styled(Text.Caption)`
  margin-left: 12px;
`;

const DefaultCheckbox = styled(Checkbox.Selector)`
  margin-top: 12px;
`;

const DEFAULT_TITLE = 'Add a credit card';
const DEFAULT_BUTTON_TEXT = 'Save';

export const BuildSourceDialog: React.FC<{
  title?: string;
  subtitle?: string;
  prompt?: string;
  buttonText?: string;
  dismissible?: boolean;
  skippable?: boolean;
  showDefaultCheckbox?: boolean;
  canAddPrepaid?: boolean;
  onClose(): void;
  onSave(sourceID?: string, token?: IStripeToken): void;
}> = ({
  title = DEFAULT_TITLE,
  subtitle,
  prompt,
  buttonText = DEFAULT_BUTTON_TEXT,
  dismissible = true,
  skippable,
  showDefaultCheckbox = true,
  canAddPrepaid = false,
  onClose,
  onSave,
}) => {
  const [options, setOptions] = useState<{ [key: string]: boolean }>({ default: true });
  const checkboxOptions = [
    {
      key: 'default',
      label: 'Set as my default payment method',
    },
  ];

  return (
    <Modal onClose={onClose} keyboard={dismissible} backdrop={dismissible ? true : 'static'}>
      <SourceForm onSave={onSave} makeDefault={options.default} canAddPrepaid={canAddPrepaid}>
        {({ fields, error, save, saving }) => (
          <BaseModal.Content>
            <BaseModal.Header>
              {dismissible && <BaseModal.Close onClose={onClose} />}
              <BaseModal.Title size="large">{title}</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
              <Fields {...fields} />
              {showDefaultCheckbox && (
                <DefaultCheckbox
                  name="credit-card-options"
                  values={options}
                  options={checkboxOptions}
                  onChange={(_, updatedOptions) => setOptions(updatedOptions)}
                />
              )}
              {error && <BaseModal.Error>{error}</BaseModal.Error>}
            </BaseModal.Body>
            <BaseModal.Buttons>
              <Button fullWidth kind="primary" loading={saving} disabled={saving} onClick={save}>
                {buttonText}
              </Button>
              {skippable && (
                <SkipButton>
                  <TextButton size="medium" onClick={onClose}>
                    Skip for now
                  </TextButton>
                </SkipButton>
              )}
            </BaseModal.Buttons>
            {prompt && <Prompt>{prompt}</Prompt>}
            <Footer>
              <SecureSection>
                <Secure />
                <SecureText>Secure & encrypted</SecureText>
              </SecureSection>
              <a href="https://www.stripe.com" target="_blank">
                <PoweredByStripe />
              </a>
            </Footer>
          </BaseModal.Content>
        )}
      </SourceForm>
    </Modal>
  );
};
