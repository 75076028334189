import React, { useRef } from 'react';

import styled from '@emotion/styled';

import { COLORS, FontWeight, mq, Text } from '@clutter/clean';

import MagicBox from '@portal/images/magic-box.svg';
import { Action as EmailAction } from './email/action';
import { Action as FBAction } from './fb/action';
import { Action as SMSAction } from './sms/action';
import { Action as TWAction } from './tw/action';

import { Email as EmailIcon, Facebook as FBIcon, SMS as SMSIcon, Twitter as TWIcon } from '../shared/icons';

const Banner = styled.div`
  text-align: center;
  background: ${COLORS.cloud};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px 24px 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
`;

const MagicBoxImg = styled.img`
  ${mq({
    height: ['53px', '53px', '60px', '68px'],
  })}
`;

const TextSection = styled.div`
  text-align: left;
  margin-left: 18px;
  margin-right: 26px;
`;

const StyledAmount = styled.span`
  color: ${COLORS.tealBrand};
`;

const ShareBox = styled.div`
  display: flex;
  width: 213px;
  margin: 0 auto;
`;

const ShareIcon = styled.div`
  padding: 16px 12px;
  cursor: pointer;
`;

const ICON_HEIGHT = 30;
const ICON_WIDTH = 30;

export const RewardsBanner: React.FC<{ source: string }> = ({ source }) => {
  const container = useRef<HTMLDivElement | null>(null);

  return (
    <Banner ref={container}>
      <Header>
        <MagicBoxImg src={MagicBox} />
        <TextSection>
          <Text.Title size="medium">
            Get up to <StyledAmount>$100</StyledAmount>
          </Text.Title>
          <Text.Callout weight={FontWeight.Regular}>for every friend you refer</Text.Callout>
        </TextSection>
      </Header>
      <ShareBox>
        <SMSAction source={source}>
          <ShareIcon>
            {' '}
            <SMSIcon height={ICON_HEIGHT} width={ICON_WIDTH} />{' '}
          </ShareIcon>
        </SMSAction>
        <EmailAction source={source} container={container} onClick={() => {}}>
          <ShareIcon>
            {' '}
            <EmailIcon height={ICON_HEIGHT} width={ICON_WIDTH} />{' '}
          </ShareIcon>
        </EmailAction>
        <FBAction source={source}>
          <ShareIcon>
            {' '}
            <FBIcon height={ICON_HEIGHT} width={ICON_WIDTH} />{' '}
          </ShareIcon>
        </FBAction>
        <TWAction source={source}>
          <ShareIcon>
            {' '}
            <TWIcon height={ICON_HEIGHT} width={ICON_WIDTH} />{' '}
          </ShareIcon>
        </TWAction>
      </ShareBox>
    </Banner>
  );
};
