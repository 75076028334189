import { Billing__Source, Billing__OfflinePayment__Kind } from '@admin/schema';

export enum BillingSourceKind {
  BankAccount = 'BANK_ACCOUNT',
  Card = 'CARD',
}

export enum BillingSourceFundingType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Prepaid = 'PREPAID',
  Unknown = 'UNKNOWN',
}

export const BillingSourceFundingTypes = [
  BillingSourceFundingType.Credit,
  BillingSourceFundingType.Debit,
  BillingSourceFundingType.Prepaid,
  BillingSourceFundingType.Unknown,
];

export interface IBillingSource {
  __typename: 'Billing__Source';
  id: string;
  kind: BillingSourceKind;
  fundingType: BillingSourceFundingType;
  number: string;
  brand?: string;
  name?: string;
  expiration?: string;
  expired: boolean;
  default: boolean;
}

export enum BillingMethodSourceType {
  Source = 'SOURCE',
  Other = 'OTHER',
  BalanceOnly = 'BALANCE_ONLY',
}

export type BillingMethodSource = Pick<Billing__Source, 'id' | 'kind' | 'brand' | 'number' | 'expiration'> & {
  type: BillingMethodSourceType.Source;
};

export type BillingMethodOther = {
  kind?: Billing__OfflinePayment__Kind;
  transactionNumber?: string;
  type: BillingMethodSourceType.Other;
};

export type BillingMethodBalanceOnly = { type: BillingMethodSourceType.BalanceOnly; kind: undefined };

export type BillingMethod = BillingMethodSource | BillingMethodOther | BillingMethodBalanceOnly;
