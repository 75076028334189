import { DateTime } from 'luxon';
import React from 'react';
import styled from '@emotion/styled';

import { BookingDisclaimer } from '@portal/schema';

import { Text } from '@clutter/clean';

import { ReturnInventory } from '@portal/components/helpers';
import { Alert } from '@shared/components/bootstrap';
import { InventoryFragment } from '@portal/components/helpers/inventory/item_card';

import { IMPERSONATOR } from '@portal/config/impersonator';
import { Base } from '../base';
import { Section } from './section';
import { PaymentMethodSelection } from './payment_method_selection';
import { TermCommitmentBalance } from './term_commitment_balance';
import { DisposalAgentOptions } from './disposal_agent_options';

const Body = styled(Text.Body)`
  margin-top: 16px;
`;

export const Disposal: React.FC<{
  selectedItems: InventoryFragment[];
  disclaimers: BookingDisclaimer | undefined;
  loading: boolean;
  error?: string;
  scheduled: DateTime;
  chargeTermCommitment: boolean;
  paymentError?: string;
  paymentSourceID?: string;
  waiveFees: boolean;
  waiveDocuments: boolean;
  setWaiveFees(waiveFees: boolean): void;
  setWaiveDocuments(waiveDocuments: boolean): void;
  setPaymentSourceID(sourceID: string): void;
  onPrev(): void;
  onSave(): void;
}> = ({
  selectedItems,
  disclaimers,
  onSave,
  onPrev,
  loading,
  error,
  scheduled,
  chargeTermCommitment,
  paymentError,
  paymentSourceID,
  waiveFees,
  waiveDocuments,
  setWaiveFees,
  setWaiveDocuments,
  setPaymentSourceID,
}) => {
  let prompt;
  if (chargeTermCommitment) {
    prompt = 'This card will be used to charge the term commitment balance and the disposal and donation charge.';
  } else {
    prompt = 'This card will be used to charge the disposal and donation charge.';
  }

  return (
    <Base onPrev={onPrev} onNext={onSave} loading={loading} nextLabel="Confirm" scrollOnNext={false}>
      {error && <Alert style="danger">{error}</Alert>}
      <Text.Title size="large">Confirm details for your Disposal and Donation</Text.Title>

      <Body>
        All items selected below will be disposed of or donated. Please be sure you want to discard all selected items
        below before confirming.
      </Body>

      <Section title="Items Selected for Disposal and Donation">
        <ReturnInventory selections={selectedItems} />
      </Section>

      <Section title="Disposal and Donation Costs">
        {disclaimers?.orderLaborDisclaimer ? (
          <>
            <Body>{disclaimers?.orderLaborDisclaimer}</Body>
            <Body>You will be billed in full once you confirm.</Body>
          </>
        ) : (
          <Body>Free</Body>
        )}
      </Section>

      {chargeTermCommitment && <TermCommitmentBalance scheduled={scheduled} />}
      <PaymentMethodSelection
        prompt={prompt}
        selectedSourceID={paymentSourceID}
        setSelectedSourceID={setPaymentSourceID}
        error={paymentError}
      />
      {IMPERSONATOR && (
        <DisposalAgentOptions
          waiveFees={waiveFees}
          setWaiveFees={setWaiveFees}
          waiveDocuments={waiveDocuments}
          setWaiveDocuments={setWaiveDocuments}
        />
      )}
    </Base>
  );
};
