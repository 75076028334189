import React from 'react';

import { Accordion } from '@clutter/clean';
import { wt } from '@portal/initializers/wt';
import { PricingSetPriceEntryFragment, Pricing__RateGroup__Commitment as Commitment } from '@portal/schema';

import { AccordionLabel } from '../accordion_label';
import { Option } from './option';
import { Options } from '../options';
import {
  accordionMetadata,
  commitmentChanged,
  commitmentMetadata,
  commitmentValue,
  PLAN_UPDATE_PAGE_NAME,
} from '../util';

export type CommitmentOption = {
  commitmentType: Commitment;
  minimumMonths: number;
  pricingSetEntry?: PricingSetPriceEntryFragment;
};

export const DEFAULT_COMMITMENT_OPTION = {
  commitmentType: Commitment.Saver,
  minimumMonths: 8,
};

const UNPRICED_COMMITMENTS: CommitmentOption[] = [
  DEFAULT_COMMITMENT_OPTION,
  {
    commitmentType: Commitment.Flexer,
    minimumMonths: 4,
  },
  { commitmentType: Commitment.Mover, minimumMonths: 0 },
];

export const Selector: React.FC<{
  orderID: string;
  loading: boolean;
  initialCommitment?: CommitmentOption;
  selectedCommitment?: CommitmentOption;
  onCommitmentChange(option: CommitmentOption): void;
  options: CommitmentOption[] | undefined;
}> = ({
  orderID,
  loading,
  initialCommitment,
  selectedCommitment,
  onCommitmentChange,
  options = UNPRICED_COMMITMENTS,
}) => {
  const sortedOptions = options.sort((a, b) => a.minimumMonths - b.minimumMonths);

  const onToggle = (isOpen: boolean) => {
    const params = {
      ...accordionMetadata(isOpen),
      container: 'commitment_accordion',
      order_id: orderID,
    };
    wt.track(params);
  };

  const onSelect = (currentCommitment: CommitmentOption, label: string) => {
    const params = {
      container: 'commitment_accordion',
      previous_selection: commitmentMetadata(selectedCommitment),
      current_selection: commitmentMetadata(currentCommitment),
      action: 'click',
      label: label,
      pageName: PLAN_UPDATE_PAGE_NAME,
      objectType: 'card',
      objectName: 'commitment',
      order_id: orderID,
    };
    wt.track(params);
    onCommitmentChange(currentCommitment);
  };

  return (
    <Accordion.Item
      name="commitment"
      label={
        <AccordionLabel
          loading={loading}
          title="Commitment"
          value={commitmentValue(selectedCommitment)}
          changed={commitmentChanged(selectedCommitment, initialCommitment)}
        />
      }
      onToggle={onToggle}
    >
      <Options.Container>
        {sortedOptions.map((option) => (
          <Option
            key={option.minimumMonths}
            option={option}
            selected={option.minimumMonths === selectedCommitment?.minimumMonths}
            onSelect={(label) => onSelect(option, label)}
          />
        ))}
      </Options.Container>
    </Accordion.Item>
  );
};
