import { head } from 'lodash';

const ZIP_TYPE = 'postal_code';
const CITY_TYPE = 'locality';
const SUBLOCALITY_TYPE = 'sublocality';
const BACKUP_CITY_TYPE = 'administrative_area_level_3';
const STATE_TYPE = 'administrative_area_level_1';
const COUNTY_TYPE = 'administrative_area_level_2';
const COUNTRY_TYPE = 'country';

interface IGoogleMapsPlace {
  types: string[];
  latitude: number;
  longitude: number;
  street?: string;
  city?: string;
  state?: string;
  county?: string;
  country?: string;
  zip?: string;
}

const component = (pr: google.maps.places.PlaceResult, type: string) => {
  const result = pr.address_components?.find((ac) => ac.types.some((value) => value === type));
  return result ? result.long_name : undefined;
};

export const parseGooglePlaceResult = (pr: google.maps.places.PlaceResult): IGoogleMapsPlace | undefined => {
  if (!pr?.geometry) {
    return undefined;
  }
  return {
    types: pr.types ?? [],
    street: pr.formatted_address ? head(pr.formatted_address.split(',')) : undefined,
    latitude: pr.geometry.location.lat(),
    longitude: pr.geometry.location.lng(),
    city: component(pr, CITY_TYPE) || component(pr, SUBLOCALITY_TYPE) || component(pr, BACKUP_CITY_TYPE),
    state: component(pr, STATE_TYPE),
    county: component(pr, COUNTY_TYPE),
    country: component(pr, COUNTRY_TYPE),
    zip: component(pr, ZIP_TYPE),
  };
};
