import { useEffect } from 'react';

export const useKey = (key: string, type: 'keydown' | 'keyup', callback: () => void) => {
  useEffect(() => {
    const onKey = (event: KeyboardEvent) => {
      if (event.key !== key) {
        return;
      }
      callback();
    };

    document.addEventListener(type, onKey);
    return () => {
      document.removeEventListener(type, onKey);
    };
  }, [callback, key, type]);
};
