import * as React from 'react';

import { Spacing, Text } from '@shared/components/bootstrap';

import { useInventoryForSave } from './use_inventory_for_save';

import { EstimationCategoriesSummaryTable } from './estimation_categories_summary_table';

export const PackableEstimationSummary: React.FC<{ pickup?: boolean }> = ({ pickup = true }) => {
  const inventory = useInventoryForSave();

  if (inventory.packableEstimations !== undefined) {
    return (
      <Spacing pb={3}>
        <Text tag="p" style="info">
          <strong>{pickup ? 'What needs to be packed up?' : 'Do your items fall into any of these categories?'}</strong>
        </Text>
        <EstimationCategoriesSummaryTable estimations={inventory.packableEstimations} packable={true} />
        <hr />
      </Spacing>
    );
  }

  return null;
};
