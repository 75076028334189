import { IIconProps } from '@portal/components/shared/icons/props';
import React from 'react';
import { snakeCase } from 'lodash';

export const CleanCreditCard: React.FC<IIconProps> = ({ title, width = 46, height = 38 }) => (
  <svg
    aria-hidden={!title}
    aria-labelledby={title && `${snakeCase(title)}-svg`}
    width={width}
    height={height}
    viewBox="0 0 46 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title id={`${snakeCase(title)}-svg`}>{title}</title>}
    <path
      d="M33.9324 0.431803C35.538 -0.161947 37.3042 0.647712 37.8395 2.26703L45.0113 21.8608C45.6 23.4801 44.7972 25.2613 43.1916 25.8551L11.5606 37.5142C9.95494 38.1079 8.18875 37.2983 7.65354 35.679L0.481704 16.0852C-0.107029 14.4659 0.695788 12.6846 2.30142 12.0909L33.9324 0.431803Z"
      fill="#00A499"
      stroke="#00A499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4902 36.6506C9.63381 36.6506 8.88452 36.1108 8.61691 35.3551L1.44508 15.7074C1.28452 15.2216 1.28452 14.6278 1.4986 14.142C1.76621 13.6563 2.14085 13.2784 2.67607 13.1165L15.6282 8.3125L10.7042 36.6506C10.5972 36.6506 10.5437 36.6506 10.4902 36.6506Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.93805 26.4489C8.29579 26.4489 7.70706 26.017 7.49297 25.4233L5.67326 20.3494C5.40565 19.5398 5.7803 18.6222 6.58312 18.3523L13.0592 15.9773C13.2197 15.9233 13.4338 15.8693 13.5944 15.8693C14.2366 15.8693 14.8254 16.3011 15.0395 16.8949L16.8592 21.9688C17.1268 22.7784 16.7521 23.696 15.9493 23.9659L9.47326 26.3409C9.31269 26.4489 9.09861 26.4489 8.93805 26.4489Z"
      fill="#FFEABF"
    />
    <path
      d="M13.5944 16.2472C14.0761 16.2472 14.5043 16.517 14.6648 17.0028L16.4845 22.0767C16.6986 22.6705 16.3775 23.3722 15.7888 23.5881L9.3127 25.9631C9.20566 26.017 9.0451 26.017 8.93806 26.017C8.45637 26.017 8.0282 25.7472 7.86763 25.2614L6.04791 20.1875C5.83383 19.5937 6.15496 18.892 6.74369 18.6761L13.2197 16.3011C13.3268 16.3011 13.4338 16.2472 13.5944 16.2472ZM13.5944 15.4375C13.3803 15.4375 13.1662 15.4915 12.8986 15.5455L6.42256 17.9744C5.94087 18.1364 5.56622 18.5142 5.29862 19C5.08453 19.4318 5.08453 19.9716 5.2451 20.5114L7.06482 25.5852C7.33242 26.3409 8.08172 26.8807 8.88454 26.8807C9.09862 26.8807 9.3127 26.8267 9.58031 26.7727L16.0564 24.3977C16.5381 24.2358 16.9127 23.858 17.1803 23.3722C17.3944 22.8864 17.4479 22.3466 17.2338 21.8608L15.4141 16.733C15.1465 15.9773 14.3972 15.4375 13.5944 15.4375Z"
      fill="#00A499"
    />
    <path
      d="M15.7888 23.5881L9.31271 25.9631C8.72398 26.179 8.02821 25.8551 7.81412 25.2613L5.9944 20.1875C5.78032 19.5937 6.10144 18.892 6.69018 18.6761L13.1662 16.3011C13.755 16.0852 14.4507 16.4091 14.6648 17.0028L16.4845 22.0767C16.6986 22.7244 16.431 23.3721 15.7888 23.5881Z"
      fill="#FFEABF"
      stroke="#FFEABF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.95499 17.5426L10.2226 18.2443L11.5606 18.6221C11.8817 18.7301 12.2564 18.7301 12.5775 18.5682L13.862 18.0824"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.52679 20.9432L10.0085 21.1591C10.2761 21.267 10.3831 21.5909 10.2761 21.8608L10.062 22.3466"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9522 21.375L12.4705 21.1591C12.2029 21.0511 12.0958 20.7272 12.2029 20.4574L12.4169 19.9716"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4338 20.3494L15.5747 19.5938"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.90427 22.7244L9.09863 21.9688"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2226 18.2443L9.41975 19.4318C9.20567 19.7017 8.93806 19.9716 8.61694 20.0796L7.33243 20.5114"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5775 24.7756L12.3099 24.0739L10.9719 23.696C10.6508 23.5881 10.2761 23.5881 9.95498 23.75L8.67047 24.2358"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3099 24.0738L13.0592 22.8863C13.2733 22.6164 13.5409 22.3466 13.862 22.2386L15.1465 21.7528"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.8567 18.9093L9.63657 30.0201L10.9564 33.6714L41.1765 22.5606L39.8567 18.9093Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.4 8.31244C30.0254 7.2329 30.2394 6.09938 30.9352 5.23574C30.4 5.12779 29.8648 5.18176 29.3296 5.39767C27.724 5.99142 26.9211 7.77267 27.5099 9.39199C28.0986 11.0113 29.8648 11.821 31.4704 11.2272C32.0056 11.0113 32.4338 10.6874 32.7549 10.3096C31.6845 10.0937 30.7747 9.39199 30.4 8.31244Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.1662 4.31816C31.631 4.53406 31.2028 4.85793 30.8817 5.23577C31.9521 5.3977 32.862 6.15338 33.2366 7.23293C33.6113 8.31247 33.3972 9.446 32.7014 10.3096C33.2366 10.4176 33.7719 10.3636 34.3071 10.1477C35.9127 9.55395 36.7155 7.7727 36.1268 6.15338C35.5381 4.53406 33.7719 3.72441 32.1662 4.31816Z"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.2366 7.23294C32.862 6.15339 31.8986 5.45169 30.8817 5.23578C30.1859 6.04544 29.9718 7.23294 30.3465 8.31248C30.7211 9.39203 31.6845 10.0937 32.7014 10.3096C33.3972 9.44601 33.6648 8.31248 33.2366 7.23294Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.0113 21.9148C45.6 23.5341 44.7972 25.3153 43.1916 25.9091L11.5606 37.5142C9.95493 38.1079 8.18873 37.2983 7.65352 35.679L0.481689 16.0852"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.99437 20.1875C5.78029 19.5937 6.10141 18.892 6.69015 18.6761L13.1662 16.3011C13.7549 16.0852 14.4507 16.4091 14.6648 17.0028L16.4845 22.0767C16.6986 22.6704 16.3775 23.3721 15.7887 23.5881"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
