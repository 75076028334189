import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Spinner } from '@portal/components/helpers';
import { Estimation__AlgorithmName, useAppointmentHubQuery } from '@portal/schema';

import { EstimationFlowV1 } from './v1/flow';
import { VirtualWalkthroughFlow } from './virtual_walkthrough/flow';

export const EstimationFlow: React.FC<RouteComponentProps<{ orderID: string }>> = (props) => {
  const {
    match: {
      params: { orderID },
    },
  } = props;
  const { data, loading } = useAppointmentHubQuery({ variables: { orderID } });
  const order = data?.order;

  const algorithmName: Estimation__AlgorithmName | undefined = data?.order?.estimationAlgorithm?.name;

  if (loading || !order) {
    return <Spinner />;
  }

  return algorithmName === Estimation__AlgorithmName.V1 ? (
    <EstimationFlowV1 {...props} />
  ) : (
    <VirtualWalkthroughFlow {...props} />
  );
};
