import { DateTime, Interval } from 'luxon';
import React, { useEffect, useRef } from 'react';

import { Calendar } from '@shared/components/calendar/calendar';
import { DateSelector, Spinner } from '@portal/components/helpers';
import { DateAvailability } from '@portal/components/helpers/scheduled_date_picker';
import { Spacer } from '@shared/components/helpers';

import { useTrack } from '@clutter/wt';
import { EventSchema, Portal__DateSelected } from '@shared/wt';
import { Header } from './header';
import { Legend } from './calendar/legend';
import { UnavailableDate } from './unavailable_date';

export const BasicScheduledDatePicker: React.FC<{
  indexedAvailabilities: Map<string, DateAvailability>;
  date?: DateTime;
  initialDateRequest?: DateTime;
  from?: DateTime;
  till?: DateTime;
  additionalInfo?: React.ReactNode;
  showNav: boolean;
  loading: boolean;
  includeWaitlist: boolean;
  rescheduling: boolean;
  showUnavailableBanner: boolean;
  trackDateSelection: boolean;
  overrideUnavailability: boolean;
  setOverrideUnavailability(overrideUnavailability: boolean): void;
  onDate(date: DateTime): void;
  onInitialDateRequest(date: DateTime): void;
  onInterval(intervalConfig?: { interval: Interval; forced: boolean }): void;
  onPrevious?(): void;
  onNext?(): void;
}> = ({
  indexedAvailabilities,
  date,
  initialDateRequest,
  from,
  till,
  additionalInfo,
  showNav,
  loading,
  includeWaitlist,
  rescheduling,
  showUnavailableBanner,
  onDate,
  trackDateSelection,
  overrideUnavailability,
  setOverrideUnavailability,
  onInitialDateRequest,
  onInterval,
  onPrevious,
  onNext,
}) => {
  const track = useTrack();
  const initialDateRequestRef = useRef<DateTime>();
  const trackInitialDateRequest = useRef<boolean>();

  const disabled = (value: DateTime) =>
    value <= DateTime.local() ||
    !indexedAvailabilities.get(value.toISODate()) ||
    !!indexedAvailabilities.get(value.toISODate())?.blocked;
  const selected = (value: DateTime) => !!date && date.toISODate() === value.toISODate();
  const booked = (value: DateTime) =>
    !disabled(value) &&
    !(indexedAvailabilities.get(value.toISODate())?.available || indexedAvailabilities.get(value.toISODate())?.blocked);

  const availabilityLoading = date && (loading || indexedAvailabilities.size === 0);

  const handleInitialDateRequest = (initialDate: DateTime) => {
    onDate(initialDate);
    onInterval(undefined);
    onInitialDateRequest(initialDate);
  };

  useEffect(() => {
    if (!initialDateRequest) return;
    const availability = indexedAvailabilities.get(initialDateRequest.toISODate());

    if (initialDateRequest !== initialDateRequestRef.current) trackInitialDateRequest.current = true;

    if (trackDateSelection && availability && trackInitialDateRequest.current) {
      const params: Portal__DateSelected = {
        schema: EventSchema.Portal__DateSelected,
        action: 'click',
        metadata: {
          date: initialDateRequest.toISODate(),
          is_waitlisted: availability.waitlisted || false,
          is_available: availability.available || false,
          is_initial_date_requested: true,
        },
      };
      track(params);
      trackInitialDateRequest.current = false;
    }

    initialDateRequestRef.current = initialDateRequest;
  }, [indexedAvailabilities, initialDateRequest, trackDateSelection, track]);

  return (
    <>
      <Header tag="h2">Choose an appointment date</Header>
      {additionalInfo}
      <Spacer height="2rem" />
      {!rescheduling && (!date?.isValid || availabilityLoading) && <DateSelector onDate={handleInitialDateRequest} />}
      {availabilityLoading && <Spinner />}
      {showUnavailableBanner && (
        <UnavailableDate
          date={date!}
          overrideUnavailability={overrideUnavailability}
          setOverrideUnavailability={setOverrideUnavailability}
        />
      )}
      {date && from && till && (
        <>
          <Legend includeWaitlist={includeWaitlist} />
          <Calendar
            from={from}
            till={till}
            showNav={showNav}
            disabled={disabled}
            selected={selected}
            booked={booked}
            onSelect={(value) => {
              onDate(value);
              onInterval(undefined);

              if (trackDateSelection) {
                const params: Portal__DateSelected = {
                  schema: EventSchema.Portal__DateSelected,
                  action: 'click',
                  metadata: {
                    date: value.toISODate(),
                    is_waitlisted: indexedAvailabilities.get(value.toISODate())?.waitlisted || false,
                    is_available: indexedAvailabilities.get(value.toISODate())?.available || false,
                    is_initial_date_requested:
                      initialDateRequest && initialDateRequest.toISODate() === value.toISODate(),
                  },
                };
                track(params);
              }
            }}
            onPrevious={onPrevious}
            onNext={onNext}
          />
        </>
      )}
    </>
  );
};
