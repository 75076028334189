import * as React from 'react';

import { TextareaFormGroup } from '../textarea_form_group';

export const AdditionalNotes: React.FC<{
  value?: string | null;
  onChange(value: string | null): void;
}> = ({ value, onChange }) => (
  <TextareaFormGroup
    label="Anything else we should know? (door man, code, hours, etc.)"
    value={value ?? ''}
    onChange={(event) => onChange(event.target.value)}
  />
);
