import React from 'react';

import { PAYMENT_SOURCE_REQUIRED } from '@portal/config/source';
import { Moving__PackingEnum, OrderTypeEnum, PackageKindEnum } from '@portal/schema';

import { Box, mq } from '@clutter/clean';
import { ShotClock, ShotClockKind } from '@portal/components/shot_clock';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { AddStorageAlert } from './add_storage_alert';
import { FailedDepositAlert } from './failed_deposit_alert';
import { PackingSuppliesAlert } from './packing_supplies_alert';
import { AppointmentDetailsOrder } from './appointment_details/full_panel';

const ShotClockContainer = styled.div`
  display: grid;
  justify-content: center;
  margin: -24px 0 60px;

  ${mq({
    margin: ['-8px 0 40px', null, '-24px 0 60px'],
  })}
`;

export const Alert: React.FC<{
  order: AppointmentDetailsOrder;
  estimationFlowCompleted: boolean;
}> = ({ order, estimationFlowCompleted }) => {
  const packingMaterialsAdded = !!order.accountPackages.find(({ kind }) => kind === PackageKindEnum.PackingSupplies);
  const packingHelpRequested =
    !!order.movingOperation?.packing && order.movingOperation.packing !== Moving__PackingEnum.EverythingIsPacked;

  // We only want to show the VW and credit card shot clocks if the countdown is above 0
  const [shotClockExpired, setShotClockExpired] = React.useState(
    DateTime.fromISO(order.scheduled).minus({ days: 2 }) < DateTime.local(),
  );

  if (order.failedPrepayment) {
    return <FailedDepositAlert failedPrepayment={order.failedPrepayment} />;
  } else if (order.type === OrderTypeEnum.Move) {
    if (!estimationFlowCompleted && !shotClockExpired) {
      return (
        <ShotClockContainer>
          <ShotClock kind={ShotClockKind.VirtualWalkthrough} order={order} onExpire={() => setShotClockExpired(true)} />
        </ShotClockContainer>
      );
    } else if (!order.simultaneousPickup && !order.multiDay) {
      return (
        <Box margin="0 0 36px">
          <AddStorageAlert orderID={order.id} />
        </Box>
      );
    } else if (packingHelpRequested && !packingMaterialsAdded && !order.permissions.whiteGloveTestEligible) {
      return (
        <Box margin="0 0 36px">
          <PackingSuppliesAlert />
        </Box>
      );
    }
  } else if (PAYMENT_SOURCE_REQUIRED && !order.bookingPartner && !shotClockExpired) {
    return (
      <ShotClockContainer>
        <ShotClock kind={ShotClockKind.CreditCard} order={order} onExpire={() => setShotClockExpired(true)} />
      </ShotClockContainer>
    );
  } else if (!estimationFlowCompleted && !shotClockExpired) {
    return (
      <ShotClockContainer>
        <ShotClock kind={ShotClockKind.VirtualWalkthrough} order={order} onExpire={() => setShotClockExpired(true)} />
      </ShotClockContainer>
    );
  }

  return <></>;
};
