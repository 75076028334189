import { Map } from 'mapbox-gl';
import { createContext, useContext } from 'react';

export const MapContext = createContext<Map | undefined>(undefined);

export const useMap = () => {
  const map = useContext(MapContext);
  if (map === undefined) {
    throw new Error('useMap must be used within a MapProvider');
  }
  return map;
};
