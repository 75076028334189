interface IConfig {
  customer_id?: string;
  customer_post_booking_sign_in?: boolean;
}

declare const CONFIG: IConfig;

export const CUSTOMER_ID = CONFIG.customer_id;

export const CUSTOMER_POST_BOOKING_SIGN_IN = CONFIG.customer_post_booking_sign_in;
