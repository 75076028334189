import * as React from 'react';

import { ClaimPaymentOptionFragment, ClaimPaymentOptionKind, DetailedClaimFragment } from '@portal/schema';
import { Digest } from '@portal/components/claims/digest';
import { Text } from '@shared/components/bootstrap';

import { NO_ADDRESS_PAYMENT_OPTION_KINDS, PAYMENT_OPTION_DESCRIPTIONS } from './constants';

const PaymentSelection: React.FC<{ paymentOption: ClaimPaymentOptionFragment }> = ({ paymentOption }) => {
  const { email, name, kind, address } = paymentOption;

  const showDetails = !NO_ADDRESS_PAYMENT_OPTION_KINDS.includes(kind);

  return (
    <>
      <span className="claim-view__section-header">Payment Options</span>
      <Text tag="div">Payment Method: {PAYMENT_OPTION_DESCRIPTIONS[kind]}</Text>
      {showDetails && (
        <>
          {kind === ClaimPaymentOptionKind.Paypal && <Text>PayPal Email: {email}</Text>}

          <span className="claim-view__section-header">Contact Details</span>
          <Text tag="div">Name</Text>
          <Text tag="div">{name}</Text>
          <br />

          {address && (
            <div className="row">
              <div className="col-md-6">
                <Text tag="div">Mailing Address</Text>
                <Text tag="div">
                  {address.street}, {address.city}, {address.state} {address.zip}
                </Text>
              </div>
              <div className="col-md-6">
                <Text tag="div">Apt/Suite</Text>
                {address.aptsuite && <Text tag="div">#{address.aptsuite}</Text>}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const SettlementAgreement: React.FC<{
  uuid: string;
  settlementAgreement: DetailedClaimFragment['settlementAgreement'];
}> = ({ uuid, settlementAgreement }) => (
  <>
    <span className="claim-view__section-header">Settlement Agreement</span>
    <a href={settlementAgreement?.url} target="_blank">
      View Settlement Agreement
    </a>{' '}
    for Claim <Digest uuid={uuid} />.
  </>
);

export const Accepted: React.FC<{ claim: DetailedClaimFragment }> = ({ claim }) => {
  const { paymentOption, settlementAgreement } = claim;

  return (
    <div className="claim-view__accepted">
      {paymentOption && <PaymentSelection paymentOption={paymentOption} />}
      {settlementAgreement && <SettlementAgreement uuid={claim.uuid} settlementAgreement={settlementAgreement} />}
    </div>
  );
};
