import { COLORS, FontWeight, Grid, Text, TextButton } from '@clutter/clean';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React from 'react';

import { SourceFragment } from '@portal/schema';
import { CreditCard } from '@portal/components/shared/icons/credit_card';
import { Label, LabelKind } from '@portal/components/shared/label';

const CARD_CONTAINER_HEIGHT = 56;

const Card = styled.div`
  background: ${COLORS.cloud};
  border-radius: 4px;
  margin: 8px 16px;
  padding: 16px;
`;

const Container = styled(Grid)`
  height: ${CARD_CONTAINER_HEIGHT}px;
  margin: 0;
  align-items: flex-start;
  justify-content: space-between;
`;

const SourceNumber = styled(Text.Callout)`
  color: ${COLORS.panther};
`;

const Expiry = styled(Text.Callout)<{ expired: boolean }>`
  color: ${({ expired }) => (expired ? COLORS.toucan : COLORS.panther)};
`;

const Info = styled(Grid)`
  height: ${CARD_CONTAINER_HEIGHT}px;
  align-items: center;
`;

const Icon = styled.div`
  margin: 0 16px 0 8px;
`;

const Actions = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: inline-block;
    color: ${COLORS.tealPrimary};
    cursor: pointer;
  }

  li:not(:last-child) {
    border-right: 1px solid ${COLORS.grayBorder};
    padding-right: 12px;
  }

  li:not(:first-of-type) {
    padding-left: 12px;
  }
`;

interface IProps {
  source: SourceFragment;
  setDestroying(source: SourceFragment): void;
  setDefaulting(source: SourceFragment): void;
}

const SourceActions: React.FC<IProps> = ({ source, setDestroying, setDefaulting }) => (
  <Actions>
    <li>
      <TextButton onClick={() => setDestroying(source)}>REMOVE</TextButton>
    </li>
    {!source.expired && (
      <li>
        <TextButton onClick={() => setDefaulting(source)}>DEFAULT</TextButton>
      </li>
    )}
  </Actions>
);

export const SourceCard: React.FC<IProps> = ({ source, setDestroying, setDefaulting }) => {
  const expiration = source.expiration ? DateTime.fromISO(source.expiration) : undefined;

  return (
    <Card>
      <Container>
        <Info>
          <Icon>
            <CreditCard brand={source.brand} />
          </Icon>
          <div>
            <SourceNumber weight={FontWeight.Medium}>Ending in {source.number}</SourceNumber>
            {expiration && (
              <Expiry expired={source.expired}>
                {source.expired ? 'expired' : 'expires'} {expiration.toFormat('yyyy-MM')}
              </Expiry>
            )}
            {!source.default && (
              <SourceActions source={source} setDestroying={setDestroying} setDefaulting={setDefaulting} />
            )}
          </div>
        </Info>
        {source.default && <Label kind={LabelKind.Primary}>DEFAULT</Label>}
      </Container>
    </Card>
  );
};
