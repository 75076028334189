import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useState } from 'react';

import { heroURL } from '@shared/utils/hero';

import { BuildClaimItemSelectionInput, ItemFragment, ItemStatus, useItemsQuery } from '@portal/schema';

import { Brick } from '@portal/components/helpers/brick';
import { Clickable } from '@portal/components/helpers/clickable';
import { Grid } from '@portal/components/helpers/grid';
import { Labelled } from '@portal/components/helpers/labelled';
import { InputGroup } from '@shared/components/bootstrap';
import { useDebounce } from '@shared/hooks';

type IExtra = Partial<BuildClaimItemSelectionInput & { uuid?: string }>;

const Search: React.FC<{
  loading: boolean;
  query?: string;
  onSearch(query?: string): void;
}> = ({ loading, query, onSearch }) => (
  <InputGroup>
    <InputGroup.Prepend>
      <InputGroup.Text>
        {!loading && <FontAwesomeIcon icon="search" />}
        {loading && <FontAwesomeIcon icon="spinner" spin />}
      </InputGroup.Text>
    </InputGroup.Prepend>
    <input
      type="text"
      placeholder="Search for your item"
      value={query || ''}
      onChange={(event) => onSearch(event.target.value || undefined)}
      className="form-control"
    />
  </InputGroup>
);

const Extras: React.FC<{
  extras: IExtra[];
  onBuild(): void;
  onChange(extra: IExtra): void;
}> = ({ extras, onBuild, onChange }) => (
  <>
    {extras.map((extra) => (
      <Grid.Entry key={extra.id || extra.uuid}>
        <Labelled>
          <Brick icon="box" selected={!extra._destroy}>
            <Brick.Status>
              <Brick.Selector
                selected={!extra._destroy}
                onSelect={() => onChange({ ...extra, _destroy: false })}
                onDeselect={() => onChange({ ...extra, _destroy: true })}
              />
            </Brick.Status>
            <Brick.Details>
              <input
                autoFocus
                type="text"
                className="form-control"
                placeholder="Item Name"
                disabled={!!extra._destroy}
                value={extra.name || ''}
                onChange={(event) => {
                  onChange({ ...extra, name: event.target.value || undefined });
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              />
            </Brick.Details>
          </Brick>
        </Labelled>
      </Grid.Entry>
    ))}

    <Grid.Entry>
      <Clickable onClick={onBuild}>
        <Brick icon="plus">
          <Brick.Details>Add an Item</Brick.Details>
        </Brick>
      </Clickable>
    </Grid.Entry>
  </>
);

const Items: React.FC<{
  items: ItemFragment[];
  selectedIDs: Set<string>;
  forReturn: boolean;
  onSelect(itemID: string): void;
  onDeselect(itemID: string): void;
}> = ({ items, selectedIDs, forReturn, onSelect, onDeselect }) => (
  <>
    {items.map((item) => {
      const selected = selectedIDs.has(item.id);
      return (
        <Grid.Entry key={item.id}>
          <Labelled>
            <Brick imgixURL={heroURL(item)} selected={selected} selectable={!forReturn || !!item.selectableForReturn}>
              <Brick.Status>
                <Brick.Selector
                  selected={selected}
                  enabled={!forReturn || !!item.selectableForReturn}
                  onSelect={() => onSelect(item.id)}
                  onDeselect={() => onDeselect(item.id)}
                />
              </Brick.Status>
              <Brick.Details>#{item.barcode.value}</Brick.Details>
            </Brick>
          </Labelled>
        </Grid.Entry>
      );
    })}
  </>
);

const Inventory: React.FC<{
  status?: ItemStatus;
  children(props: {
    loading: boolean;
    items?: ItemFragment[];
    query?: string;
    onSearch(query?: string): void;
  }): React.ReactElement;
}> = ({ status, children }) => {
  const [query, setQuery] = useState<string | undefined>(undefined);
  const debouncedQuery = useDebounce(query);
  const { data, loading } = useItemsQuery({ variables: { query: debouncedQuery, status } });

  return children({
    loading,
    query,
    items: data ? data.items : undefined,
    onSearch: setQuery,
  });
};

const Combined = Object.assign(Inventory, {
  Grid,
  Items,
  Extras,
  Search,
});

export { Combined as Inventory };
