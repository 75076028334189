import { COLORS, FontWeight, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { useBalanceQuery } from '@portal/schema';
import { currency } from '@shared/utils/currency';

const Container = styled.div`
  background-color: ${COLORS.tealBackground};
  border-radius: 4px;
  padding: 16px;
  margin-top: 24px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const Callout = styled(Text.Callout)`
  color: ${COLORS.panther};
`;

const Caption = styled(Text.Caption)`
  color: ${COLORS.storm};
`;

export const CreditBalance: React.FC = () => {
  const { data } = useBalanceQuery();

  if (!data?.account?.creditBalance || data?.account?.creditBalance < 0) {
    return null;
  }

  const { creditBalance } = data.account;

  return (
    <Container>
      <TitleContainer>
        <Callout weight={FontWeight.Medium}>Credit Balance</Callout>
        <Callout weight={FontWeight.Medium}>{currency(creditBalance)}</Callout>
      </TitleContainer>
      <Caption>We’ll apply this to your future payments until its been fully used up.</Caption>
    </Container>
  );
};
