import { RouteWithTitle } from '@portal/components/shared/route_with_title';
import { COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { NAV_BAR_HEIGHT } from '@portal/components/shared/layout';
import { billingURL } from '@portal/config/routes';
import { useBillingBannerQuery } from '@portal/schema';

import { Banner } from './banner/index';
import { History } from './history';
import { Payment } from './payment';
import { Tabs, BillingTabs } from './tabs';
import { Upcoming } from './upcoming';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${NAV_BAR_HEIGHT}px);
  position: absolute;
  top: ${NAV_BAR_HEIGHT}px;
  left: 0;
  right: 0;
`;

const Title = styled(Text.Title)`
  margin-top: 24px;
  margin-left: 24px;
  margin-bottom: 28px;
`;

const TabbedContainer = styled.div`
  background: ${COLORS.grayBackground};
  flex: 1;
  width: 100%;
  padding-bottom: 90px;
`;

const withTabs =
  (Component: React.ComponentType, activeTab: BillingTabs): React.FC =>
  () =>
    (
      <>
        <Tabs activeTab={activeTab} />
        <TabbedContainer>
          <Component />
        </TabbedContainer>
      </>
    );

export const Billing: React.FC = () => {
  const { data } = useBillingBannerQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <Container>
      <Title size="large">Billing</Title>
      {!!data && (
        <Banner
          offerID={data.account?.approvedPaymentPlanOffer?.id}
          delinquentBalance={data.account?.delinquentBalance || 0}
          creditBalance={data.account?.creditBalance || 0}
        />
      )}
      <Switch>
        <RouteWithTitle
          exact
          path={billingURL(BillingTabs.Upcoming)}
          component={withTabs(Upcoming, BillingTabs.Upcoming)}
          title="Upcoming Invoices"
        />
        <RouteWithTitle
          exact
          path={billingURL(BillingTabs.History)}
          component={withTabs(History, BillingTabs.History)}
          title="Billing History"
        />
        <RouteWithTitle
          exact
          path={billingURL(BillingTabs.Payment)}
          component={withTabs(Payment, BillingTabs.Payment)}
          title="Payment Methods"
        />
        <Route path={billingURL()} render={() => <Redirect to={billingURL(BillingTabs.History)} />} />
      </Switch>
    </Container>
  );
};
