import styled from '@emotion/styled';

const DISABLED_STYLE = { color: '#CCD2D9', backgroundColor: '#F2F4F5', borderColor: '#CCD2D9' };
const SELECTED_STYLE = { color: '#FFFFFF', backgroundColor: '#3BB5AE', borderColor: '#0A3D3A' };
const DEFAULT_STYLE = { color: '#5D5D5D', backgroundColor: '#FFFFFF', borderColor: '#808080' };

export const TimeslotEntry = styled.button<{
  disabled?: boolean;
  selected?: boolean;
}>`
  display: block;
  width: 100%;
  margin: 8px 0;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  font-size: 18px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;

  ${(props) => {
    if (props.disabled) return DISABLED_STYLE;
    if (props.selected) return SELECTED_STYLE;
    return DEFAULT_STYLE;
  }};

  transition-duration: 150ms;
  transition-property: background-color, border-color, color;

  &,
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
`;
