export enum FlashKind {
  Info = 'INFO',
  Danger = 'DANGER',
  Success = 'SUCCESS',
  Warning = 'WARNING',
}

export interface IFlash {
  message: string | React.ReactNode;
  kind: FlashKind;
}
