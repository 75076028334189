import axios, { AxiosError } from 'axios';
import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';

import { PasswordForm } from '@portal/components/password/password_form';
import { resetPasswordURL } from '@portal/config/routes';
import { CUSTOMER_POST_BOOKING_SIGN_IN } from '@portal/config/customer';
import { Box, COLORS, Text } from '@clutter/clean';

export const PasswordEditForm: React.FC<{ token: string; onEdit(): void }> = ({ token, onEdit }) => {
  const [error, setError] = useState<React.ReactElement | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const savePassword = async (password: string) => {
    if (!password) {
      setError(<>Enter a new password</>);
      return;
    }

    setLoading(true);
    try {
      await axios.put('/password.json', {
        customer: {
          reset_password_token: token,
          password,
        },
      });
      onEdit();
    } catch (exception) {
      setLoading(false);
      setError(() => {
        const errors = (exception as AxiosError).response?.data?.errors;
        if (errors?.password) {
          return <>Password {errors.password.join(', ')}</>;
        }
        if (errors?.reset_password_token) {
          return (
            <>
              This link has expired. Please <a href={resetPasswordURL()}>request a new link here</a>.
            </>
          );
        }
        Sentry?.withScope((scope) => {
          scope.setTag('setPasswordError', 'true');
          Sentry.captureException(exception);
        });
        return <>There was an error. Please try again.</>;
      });
    }
  };

  return (
    <>
      <PasswordForm error={error} loading={loading} savePassword={savePassword} />
      {!CUSTOMER_POST_BOOKING_SIGN_IN && (
        <Box textAlign="center" margin="24px 0 0">
          <Text.Callout color={COLORS.storm}>
            By clicking Update Password, I agree to Clutter’s{' '}
            <a style={{ textDecoration: 'underline' }} href="https://www.clutter.com/legal/tos" target="_blank">
              Terms of Use.
            </a>
          </Text.Callout>
        </Box>
      )}
    </>
  );
};
