import React from 'react';
import styled from '@emotion/styled';

import { Box, Text } from '@clutter/clean';

import moverIcon from '@admin/images/illustrations/mover_tv_packing.svg';

const Image = styled.img`
  width: inherit;
`;

export const Empty: React.FC<{ caption: string | undefined }> = ({ caption }) => (
  <Box.Flex flexDirection="column" alignItems="center" padding="10px 0 0 0">
    <Box width={['200px', '300px', '300px']}>
      <Image src={moverIcon} />
    </Box>
    {caption && (
      <Box textAlign="center" padding="20px 0 0 0">
        <Text.Body>{caption}</Text.Body>
      </Box>
    )}
  </Box.Flex>
);
