import styled from '@emotion/styled';
import React from 'react';
import infoTooltip from '@portal/images/info_tooltip.svg';
import { COLORS, Tooltip } from '@clutter/clean';

export const TooltipList = styled.ul`
  span {
    color: ${COLORS.panther};
  }
  li {
    color: ${COLORS.tealPrimary};
  }
`;

export const InfoTooltip: React.FC<{
  text: React.ReactNode;
}> = ({ text }) => (
  <Tooltip arrow text={text}>
    <img src={infoTooltip} />
  </Tooltip>
);
