import { Box } from '@clutter/clean';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { InputGroup } from '@shared/components/bootstrap';
import { Step } from '@portal/components/claims/step';
import { ChoiceButton } from '@portal/components/helpers/choice_button';

import { IssueSelectionForm } from './form';

export const Declaration = () => (
  <IssueSelectionForm field="compensationRequested">
    {({ loading, selection, onChange }) => (
      <>
        <Step.Title>Are you requesting compensation for this issue?</Step.Title>
        <ChoiceButton
          text="Yes"
          selected={selection.compensationRequested === true}
          onSelect={() =>
            onChange({
              ...selection,
              compensationRequested: true,
            })
          }
        />
        <ChoiceButton
          text="No"
          selected={selection.compensationRequested === false}
          onSelect={() =>
            onChange({
              ...selection,
              compensationRequested: false,
              declaration: undefined,
            })
          }
        />
        <Box margin="24px 0 0 0">
          {selection.compensationRequested === true && (
            <>
              <Step.Subtitle>What is the estimated amount of loss? We’ll round to the nearest dollar.</Step.Subtitle>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {loading ? <FontAwesomeIcon icon="spinner" spin /> : <FontAwesomeIcon icon="dollar-sign" />}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <input
                  className="form-control"
                  type="number"
                  placeholder="Value"
                  min={0}
                  max={1000000}
                  onChange={(event) => onChange({ ...selection, declaration: Number(event.target.value) || undefined })}
                  value={selection.declaration || ''}
                />
              </InputGroup>
            </>
          )}
          {selection.compensationRequested === false && (
            <Step.Subtitle>The Trust and Safety team will investigate and follow up in 3-5 days.</Step.Subtitle>
          )}
        </Box>
      </>
    )}
  </IssueSelectionForm>
);
