import * as React from 'react';

import { ClaimItemSelectionConsumer } from '@portal/contexts/claim_item_selection';

import { Step } from '@portal/components/claims/step';

export const Submitted = () => (
  <Step>
    <ClaimItemSelectionConsumer>
      {({ page, total, selection: { name } }) => {
        const remaining = total - page;
        return (
          <>
            <Step.Title>
              <>We’re all done with your item: {name}.</>
              {remaining > 0 && (
                <>
                  {' '}
                  {page} down, {remaining} to go.{' '}
                </>
              )}
            </Step.Title>
            <Step.Subtitle>
              {remaining > 0 ? (
                <>We’ll take you through the process again for your next item.</>
              ) : (
                <>That’s all the items you selected.</>
              )}
            </Step.Subtitle>
          </>
        );
      }}
    </ClaimItemSelectionConsumer>
  </Step>
);
