import * as qs from 'qs';
import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { Spinner } from '@portal/components/helpers';
import {
  Review__Reviewable__SelfStorageRental,
  Review__ReviewableEnum,
  useBuildReviewMutation,
  useReviewableQuery,
} from '@portal/schema';
import { Message as ErrorMessage } from '@portal/components/shared/error';
import { reviewThanksURL } from '@portal/config/routes';
import { Retry } from '@shared/components/helpers';

import { Review as LogisticsLoadReview } from '@portal/components/logistics/review';
import { Review as SelfStorageReview } from '@portal/components/self_storage/review';
import { Review as TicketReview } from '@portal/components/ticket/review';

import { NPS } from './nps';

const QS_PARSE_OPTIONS = { ignoreQueryPrefix: true };

export const Edit: React.FC = () => {
  const location = useLocation();
  const params = qs.parse(location.search, QS_PARSE_OPTIONS);
  const score = parseInt(params.score);
  const rating = params.rating?.toUpperCase();
  const { reviewable_id: signedReviewableID, kind, scheduling_id: schedulingID } = params;

  const { data, error, loading, refetch } = useReviewableQuery({
    variables: { reviewableID: signedReviewableID },
    skip: !signedReviewableID,
  });
  const [buildReview, { loading: building }] = useBuildReviewMutation();

  useEffect(() => {
    const noScore = score === undefined || Number.isNaN(score);
    if (!data?.reviewable || (!rating && noScore)) {
      return;
    }

    const { reviewable } = data;

    (async () => {
      await buildReview({
        variables: {
          input: {
            reviewable: { id: reviewable.id, type: reviewable.type },
            kind,
            schedulingID,
            rating,
            score,
          },
        },
      });
    })();
  }, [data]);

  if (loading || building) {
    return <Spinner />;
  }

  if (error) {
    return <Retry error={error} refetch={refetch} />;
  }

  if (!data?.reviewable) {
    return <ErrorMessage message="That link has expired." />;
  }

  const { reviewable } = data;

  return (
    <div>
      {reviewable.type === Review__ReviewableEnum.Account && (
        <NPS
          signedReviewableID={signedReviewableID}
          reviewableID={reviewable.id}
          initialScore={score}
          kind={kind}
          schedulingID={schedulingID}
        />
      )}
      {reviewable.type === Review__ReviewableEnum.CustomerTicket && (
        <TicketReview reviewableID={reviewable.id} rating={rating} kind={kind} schedulingID={schedulingID} />
      )}
      {reviewable.type === Review__ReviewableEnum.SelfStorageRental && (
        <SelfStorageReview
          reviewableID={reviewable.id}
          rating={rating}
          facilityName={(reviewable as Review__Reviewable__SelfStorageRental).facilityName}
          kind={kind}
          schedulingID={schedulingID}
        />
      )}
      {reviewable.type === Review__ReviewableEnum.LogisticsOutboundLoad && (
        <LogisticsLoadReview reviewableID={reviewable.id} rating={rating} kind={kind} schedulingID={schedulingID} />
      )}
      {reviewable.type === Review__ReviewableEnum.Order && (
        <Redirect to={`${reviewThanksURL()}?${qs.stringify({ reviewable_id: signedReviewableID, rating })}`} />
      )}
    </div>
  );
};
