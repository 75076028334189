import * as React from 'react';
import { useState } from 'react';

import { inventoryComplete } from '@portal/utils';

import { IInventoryConfig, IOrderInventory, IOrderService, IPickupInventoryInput } from '@portal/types';

import { buildOrderInventory, buildPickupInventoryInput } from '@portal/utils';

import { ConfigWithSetup } from '@shared/components/inventory_capture';

import { InventoryCaptureForm as InventoryForm } from '@shared/components/inventory_capture/form';

import { Header } from '@portal/components/helpers/header';
import { Spacer } from '@shared/components/helpers';
import { PerItemOrderFooter } from '@portal/components/orders/details/per_item_order_footer';
import { Button } from '@portal/components/orders/steps/base';
import {
  PickupInventoryOrderFragment,
  PickupInventoryOrderServiceFragment,
  EstimationCategory,
  OrderSubtypeEnum,
} from '@portal/schema';

export const OrderPickupInventoryForm: React.FC<{
  estimationCategories: EstimationCategory[];
  order: PickupInventoryOrderFragment;
  service: PickupInventoryOrderServiceFragment;
  loading: boolean;
  onSubmit(input: IPickupInventoryInput): void;
}> = ({ order, service, loading, onSubmit, ...config }) => {
  const [inventory, setInventory] = useState<IOrderInventory>(() =>
    buildOrderInventory(service as IOrderService, config as IInventoryConfig),
  );

  const inventoryInput = buildPickupInventoryInput({ inventory });
  const { estimations, estimatedItems, rooms } = inventoryInput;
  return (
    <>
      <Header tag="h3">Storage Inventory</Header>
      <p>
        Update your inventory to include everything you plan to store. Knowing what you will store helps Clutter send
        the right number of people for the right length of time to complete your appointment.
      </p>
      <Spacer height="1rem" />
      <form
        onSubmit={(event): void => {
          event.preventDefault();
          event.stopPropagation();
          if (loading) {
            return;
          }
          onSubmit(buildPickupInventoryInput({ inventory }));
        }}
      >
        <ConfigWithSetup {...(config as IInventoryConfig)} inventory={inventory} onSelect={setInventory}>
          <InventoryForm hidePacking={false} hideMoving={order.subtype === OrderSubtypeEnum.Subsequent} />
        </ConfigWithSetup>

        <PerItemOrderFooter orderID={order.id} orderEdits={{ estimations, estimatedItems, rooms }}>
          <Button
            type="button"
            className="secondary"
            onClick={() => {
              setInventory(buildOrderInventory(service as IOrderService, config as IInventoryConfig));
            }}
          >
            Reset
          </Button>
          <Button type="submit" className="primary" disabled={loading || !inventoryComplete(inventory)}>
            Save Changes
          </Button>
        </PerItemOrderFooter>
      </form>
    </>
  );
};
