import { CSRF_TOKEN } from '@shared/config/csrf';
import { from, Observable, createHttpLink, ServerParseError } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';

const promiseToObservable = <T>(promise: Promise<T>) =>
  new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) {
          return;
        }
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err),
    );
  });

const isServerParseError = (error: ErrorResponse['networkError']): error is ServerParseError =>
  error !== undefined && (error as ServerParseError).bodyText !== undefined;

// Handle non-JSON responses from backend (such as a redirect to sign-in)
// Apollo will try and parse backend responses even if they are non-JSON
// See here: https://github.com/apollographql/apollo-feature-requests/issues/153
const invalidJsonErrorLink = onError(({ networkError }) => {
  if (isServerParseError(networkError)) {
    try {
      JSON.parse(networkError.bodyText);
    } catch (e) {
      // If not JSON, replace parsing error message with real one
      networkError.message = networkError.bodyText;
    }
  }
});

const refreshCSRFToken = async () => {
  const response = await fetch('/csrf');
  if (!response.ok) {
    return '';
  }
  const json: { token: string } = await response.json();
  return json.token;
};

const INVALID_AUTHENTICITY_TOKEN_ERROR_MESSAGE = 'ActionController::InvalidAuthenticityToken';

// Handle 422 Invalid Authenticity Token error from Rails by refreshing the CSRF token
const invalidAuthenticityTokenErrorLink = onError(({ operation, forward, graphQLErrors }) => {
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      if (error.extensions && error.extensions.code === INVALID_AUTHENTICITY_TOKEN_ERROR_MESSAGE) {
        return promiseToObservable(refreshCSRFToken()).flatMap((token) => {
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              'X-CSRF-TOKEN': token,
            },
          });
          return forward(operation);
        });
      }
    }
  }
});

const httpLink = createHttpLink({
  credentials: 'same-origin',
  uri: ({ operationName }) => `/graphql${process.env.NODE_ENV === 'development' ? '?op=' + operationName : ''}`,
  headers: {
    'X-CSRF-TOKEN': CSRF_TOKEN,
  },
});

export const DEFAULT_APOLLO_CLIENT_LINK = from([invalidJsonErrorLink, invalidAuthenticityTokenErrorLink, httpLink]);
