import React from 'react';

import { COLORS } from '@clutter/clean';

export const Email: React.FC<{ height: number; width: number }> = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    preserveAspectRatio="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill={COLORS.tealPrimary} />
    <rect x="8.80005" y="11" width="21.6" height="15.3" rx="2" fill="white" />
    <path
      d="M20 22.05L8.5 11.3828H31.6L20 22.05Z"
      stroke={COLORS.tealPrimary}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M23.2 19.075L32 27.15" stroke={COLORS.tealPrimary} strokeWidth="1.2" />
    <path d="M16.8 18.65L8 28" stroke={COLORS.tealPrimary} strokeWidth="1.2" />
  </svg>
);
