import { Shipping__EasyPostRate } from '@portal/schema';

export interface IInboundShipmentInput {
  name: string;
  length: number | undefined;
  width: number | undefined;
  height: number | undefined;
  weight: number | undefined;
  contentDescription: string;
  easyPostRate: Shipping__EasyPostRate | undefined;
  easyPostShipmentID: string;
}

export const BOX_NAMES = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
