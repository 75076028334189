import React from 'react';

import { OrderTypeEnum } from '@portal/schema';

import { OnboardingReview } from './onboarding_review';
import { MoveReview } from './move_review';
import { BaseStepType } from '../../data';

export const Review: React.FC<BaseStepType> = (props) => {
  const { orderType } = props;

  return orderType === OrderTypeEnum.Move ? <MoveReview {...props} /> : <OnboardingReview {...props} />;
};
