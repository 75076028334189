import * as React from 'react';
import { RadioFormGroup } from '../radio_form_group';

const OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const GatedCommunity: React.FC<{
  value: boolean;
  onChange(value: boolean): void;
}> = ({ value, onChange }) => (
  <RadioFormGroup
    label="Is this address in a gated community?"
    name="gated-community"
    value={value}
    onChange={onChange}
    options={OPTIONS}
  />
);
