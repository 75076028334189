import React from 'react';
import styled from '@emotion/styled';
import { Text } from '@clutter/clean';

const Container = styled.div`
  margin-top: 32px;
`;

export const Section: React.FC<{ title: string }> = ({ title, children }) => (
  <Container>
    <Text.Title size="small">{title}</Text.Title>
    {children}
  </Container>
);
