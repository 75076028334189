import { COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { sumBy } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';

import {
  AccountDetailFragment,
  Maybe,
  Pricing__RateGroup__OrderService as RateGroupService,
  ProtectionPlanFragment,
  StoragePlanFragment,
  UpcomingSubscriptionDetailsFragment,
} from '@portal/schema';

import { CostRow, CostTable } from '@portal/components/shared/cost_table';
import { storageName, protectionName } from '@portal/utils/plan';
import { getOrdinalSuffix } from '@shared/utils/get_ordinal_suffix';

import { PlanUpdateLink } from './plan_update_link';
import { Totals } from './totals';

const DEFAULT_PROTECTION_PLAN_NAME = 'Item Protection';
const MINIMAL_PROTECTION_PLAN_DESCRIPTION = 'None selected';

const Body = styled(Text.Body)`
  color: ${COLORS.storm};
  margin-bottom: 24px;
`;

const ServiceTitle = styled(Text.Title)`
  color: ${COLORS.panther};
`;

const StoragePlan = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const formattedCubicFootageText = (cubicFootage?: Maybe<number>) => {
  if (!cubicFootage || cubicFootage <= 0) {
    return undefined;
  }
  return `Covers up to ${cubicFootage} cubic feet of storage`;
};

const currency = (amount: number) =>
  amount.toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const formattedProtectionPlanName = (protectionSubscription: UpcomingSubscriptionDetailsFragment) => {
  const plan = protectionSubscription.pricing?.plan as ProtectionPlanFragment;
  if (!plan) {
    return DEFAULT_PROTECTION_PLAN_NAME;
  }
  return protectionName(plan);
};

const formattedProtectionDescription = (protectionSubscription: UpcomingSubscriptionDetailsFragment) => {
  const name = protectionSubscription.pricing?.plan.name;
  if (['Basic', 'Silver Protection', 'Gold Protection'].includes(name)) {
    return name;
  }
  const maxCovered = (protectionSubscription.pricing?.plan as ProtectionPlanFragment)?.maxCovered;
  return maxCovered ? `Covers up to ${currency(maxCovered)}` : MINIMAL_PROTECTION_PLAN_DESCRIPTION;
};

export const DoorToDoorSubscriptions: React.FC<{
  subscriptionSet: AccountDetailFragment['upcomingSubscriptionSet'];
  onboardingID?: string;
  serviceType?: Maybe<RateGroupService>;
  allowPlanUpdate?: boolean;
}> = ({ subscriptionSet, onboardingID, serviceType, allowPlanUpdate }) => {
  const { storageSubscriptions, protectionSubscription, nextPeriodEnd } = subscriptionSet;

  const totalCost = sumBy(storageSubscriptions, ({ total }) => total) + (protectionSubscription?.total || 0);
  const totalTax = sumBy(storageSubscriptions, ({ tax }) => tax);
  const totalDiscount =
    sumBy(storageSubscriptions, ({ discount }) => discount) + (protectionSubscription?.discount || 0);

  const dayOfMonth = nextPeriodEnd ? DateTime.fromISO(nextPeriodEnd, { setZone: true }).day : undefined;

  const serviceName = serviceType === RateGroupService.DropOff ? 'Drop Off' : 'Door-to-Door';

  return (
    <>
      <ServiceTitle size="large">Your {serviceName} Details</ServiceTitle>
      <StoragePlan>
        <ServiceTitle size="medium">Storage Plan</ServiceTitle>
        {allowPlanUpdate && <PlanUpdateLink container={'storage_plan'} onboardingID={onboardingID!} />}
      </StoragePlan>
      <Body>
        {dayOfMonth ? `Due on the ${dayOfMonth}${getOrdinalSuffix(dayOfMonth)} of each month` : `Due every month`}.
      </Body>
      <CostTable removeBottomBorder removeTopBorder>
        <tbody>
          {storageSubscriptions.map((storageSubscription) => (
            <CostRow
              key={storageSubscription.id}
              item={`${storageName(storageSubscription.pricing?.plan as StoragePlanFragment)} Storage`}
              description={formattedCubicFootageText(storageSubscription.cubicFootage)}
              amount={storageSubscription.total}
            />
          ))}
          {protectionSubscription ? (
            <CostRow
              item={formattedProtectionPlanName(protectionSubscription)}
              description={formattedProtectionDescription(protectionSubscription)}
              amount={protectionSubscription.total}
              useFree={false}
            />
          ) : (
            <CostRow
              item={DEFAULT_PROTECTION_PLAN_NAME}
              description={MINIMAL_PROTECTION_PLAN_DESCRIPTION}
              amount={0}
              useFree={false}
            />
          )}
        </tbody>
      </CostTable>
      <Totals discount={totalDiscount} tax={totalTax} totalCost={totalCost} />
    </>
  );
};
