import * as React from 'react';

import { wt } from '@portal/initializers/wt';
import { IEventParams } from '@portal/types/wt/event';

interface IClickProps {
  children: React.ReactElement<{ onClick: (...args: any[]) => any }>;
  params: IEventParams;
}

export const TrackedClick = (props: IClickProps) => {
  const { children, params } = props;
  const wrapOnClick = (...args: any[]) => {
    if (props.children.props.onClick) {
      props.children.props.onClick(...args);
    }
    wt.track(params);
  };
  return React.cloneElement(children, { onClick: wrapOnClick });
};
