import { COLORS, FontWeight, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Accordion } from '@clutter/clean';

const Body = styled(Text.Body)`
  color: ${COLORS.tealDark};
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

const AccordionLabel: React.FC<{ question: string }> = ({ question }) => (
  <Body weight={FontWeight.Medium}>{question}</Body>
);

const Content = styled(Text.Callout)`
  color: ${COLORS.panther};
  padding: 0 24px 24px;
`;

export const QuestionItem: React.FC<{ name: string; question: string }> = ({ name, question, children }) => (
  <Accordion.Item name={name} label={<AccordionLabel question={question} />}>
    <Content>{children}</Content>
  </Accordion.Item>
);
