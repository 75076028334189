import cn from 'classnames';
import * as React from 'react';

interface IListGroupItemProps {
  style?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  className?: string;
}

const ListGroupItem: React.FC<IListGroupItemProps & React.LiHTMLAttributes<HTMLLIElement>> = ({
  style,
  className,
  children,
  ...props
}) => (
  <li {...props} className={cn('list-group-item', style && `list-group-item-${style}`, className)}>
    {children}
  </li>
);

interface IListGroupProps {
  flush?: boolean;
  className?: string;
}

const ListGroup: React.FC<IListGroupProps & React.HTMLAttributes<HTMLUListElement>> = ({
  flush,
  className,
  children,
  ...props
}) => (
  <ul {...props} className={cn('list-group', { 'list-group-flush': flush }, className)}>
    {children}
  </ul>
);

const Combined = Object.assign(ListGroup, { Item: ListGroupItem });
export { Combined as ListGroup };
