import { Box, Button, Text } from '@clutter/clean';
import React, { useState } from 'react';
import { DateTime } from 'luxon';

import { Button as ProtectedButton } from '@portal/components/shared/customer_protected/button';
import { Status, useChargeEarlyAccountClosureFeeMutation } from '@portal/schema';
import { PaymentMethodSelection } from '@portal/components/orders/steps/confirm/payment_method_selection';

import { Balance, TermCommitmentBalance } from './balance';

export const Pay: React.FC<{
  balance: TermCommitmentBalance;
  loading: boolean;
  scheduled: DateTime;
  onBack(): void;
  onTermCommitmentPayment(): void;
}> = ({ balance, loading, scheduled, onBack, onTermCommitmentPayment }) => {
  const [paymentSourceID, setPaymentSourceID] = useState<string>();
  const [paymentError, setPaymentError] = useState<string | undefined>();
  const [chargeEarlyAccountClosureFee, { loading: charging }] = useChargeEarlyAccountClosureFeeMutation();

  const onSave = async () => {
    setPaymentError(undefined);

    if (!paymentSourceID) {
      setPaymentError('Please select a payment method');
      return;
    }

    const response = await chargeEarlyAccountClosureFee({
      variables: { sourceID: paymentSourceID!, scheduled: scheduled.toJSON() },
    });
    if (response?.data?.charge?.status === Status.Unprocessable) {
      setPaymentError(`There was an error processing your term balance payment.`);
      return;
    }
    onTermCommitmentPayment();
  };

  return (
    <>
      <Text.Title size="large">Balance Details</Text.Title>
      <Balance balance={balance} />
      <PaymentMethodSelection
        prompt={'This card will be used to charge the term commitment balance.'}
        selectedSourceID={paymentSourceID}
        setSelectedSourceID={setPaymentSourceID}
        error={paymentError}
      />
      <Box margin="12px 0">
        <Button kind="secondary" size="medium" disabled={loading || charging} onClick={onBack} fullWidth>
          Back
        </Button>
      </Box>
      <Box margin="12px 0">
        <ProtectedButton
          kind="primary"
          size="medium"
          disabled={loading || charging || !paymentSourceID}
          onClick={onSave}
          fullWidth
        >
          Pay & Reschedule
        </ProtectedButton>
      </Box>
    </>
  );
};
