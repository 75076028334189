import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { claimURL } from '@portal/config/routes';

import { ClaimItemSelectionChanger, ClaimItemSelectionProvider } from '@portal/contexts';
import { ClaimItemSelectionFragment, ClaimItemSelectionKind, DetailedClaimFragment } from '@portal/schema';

import { Wizard } from './wizard';

interface ISelectionProps {
  id: string;
  claim: DetailedClaimFragment;
  onChange(changer: (_: DetailedClaimFragment) => DetailedClaimFragment): void;
  onNext(): void;
  onPrev(): void;
}

export class Selection extends React.Component<ISelectionProps, {}> {
  public render() {
    const { onNext, onPrev, claim } = this.props;
    const { onChange } = this;
    const { entry } = this;
    if (!entry) {
      return <Redirect to={claimURL(claim.uuid)} />;
    }
    const { selection, page, total } = entry;
    const name = selection.name || (selection.item ? `#${selection.item.barcode.value}` : 'UNKNOWN');

    return (
      <ClaimItemSelectionProvider value={{ ...entry, onChange }}>
        <Wizard onNext={onNext} onPrev={onPrev} summary={`Working on: ${name}. Item ${page} of ${total} selected.`} />
      </ClaimItemSelectionProvider>
    );
  }

  private onChange = (changer: ClaimItemSelectionChanger) => {
    const { onChange, id } = this.props;
    onChange((claim) => ({
      ...claim,
      itemSelections: claim.itemSelections.map((selection) =>
        selection.id === id ? (typeof changer === 'function' ? changer(selection) : changer) : selection,
      ),
    }));
  };

  private get entry() {
    const { claim, id } = this.props;
    const totals: Record<ClaimItemSelectionKind, number> = {
      [ClaimItemSelectionKind.Damaged]: 0,
      [ClaimItemSelectionKind.Lost]: 0,
    };
    let match: ClaimItemSelectionFragment;
    let page = 0;
    for (const selection of claim.itemSelections) {
      totals[selection.kind]++;
      if (selection.id === id) {
        page = totals[selection.kind];
        match = selection;
      }
    }

    if (!page) {
      return undefined;
    }

    return {
      page: page!,
      selection: match!,
      total: totals[match!.kind],
    };
  }
}
