import * as React from 'react';
import styled from '@emotion/styled';
import { Box, COLORS, FontWeight, Text } from '@clutter/clean';

import { Phone } from '@shared/components/helpers/phone';
import { useCustomerQuery } from '@portal/schema';

const ContactContainer = styled(Box.Flex)`
  background: ${COLORS.dust};
  border-radius: 4px;
  padding: 8px 0;
  margin: 0 auto 16px;
  width: 100%;
`;

export const DefaultContact = () => {
  const { data } = useCustomerQuery();
  if (!data) return null;
  const customer = data.account.customer;

  return (
    <ContactContainer justifyContent="space-around" alignItems="center" flexDirection="column">
      <Text.Body weight={FontWeight.Medium}>{customer.name}</Text.Body>
      <Phone value={customer.phone} />
    </ContactContainer>
  );
};
