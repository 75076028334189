import styled from '@emotion/styled';
import * as React from 'react';

import { Button, Text, mq } from '@clutter/clean';

const DEFAULT_VALIDATES = 'next';
const DEFAULT_VALID = true;

const Headings = styled.div`
  margin: 32px 0;
  h1 {
    margin-bottom: 16px;
  }
  ${mq({
    textAlign: ['left', 'center'],
  })}
`;

const Content = styled.div`
  margin: 0 auto;
`;

const ButtonContainer = styled.div`
  ${mq({
    padding: ['16px 0', '16px 24px'],
  })}
  margin: 0 auto;
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  button {
    ${mq({
      width: ['initial', '150px'],
    })}
  }
`;

export const Base: React.FC<{
  headline?: string;
  subheading?: string | JSX.Element;
  validates?: 'next' | 'prev';
  valid?: boolean;
  onNext?(): void;
  onPrev?(): void;
  loading?: boolean;
}> = ({
  validates = DEFAULT_VALIDATES,
  valid = DEFAULT_VALID,
  headline,
  subheading,
  children,
  onNext,
  onPrev,
  loading,
}) => (
  <div>
    <Headings>
      {headline && <Text.Title size="large">{headline}</Text.Title>}
      {subheading && <Text.Body>{subheading}</Text.Body>}
    </Headings>
    <Content>{children}</Content>
    <ButtonContainer>
      {onPrev && (
        <Button kind="secondary" disabled={validates === 'prev' && !valid} onClick={onPrev}>
          Back
        </Button>
      )}
      {onNext && (
        <Button loading={loading} kind="primary" disabled={validates === 'next' && !valid} onClick={onNext}>
          Next
        </Button>
      )}
    </ButtonContainer>
  </div>
);
