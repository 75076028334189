import { StepProps } from '@clutter/staircase';

import {
  Estimation__CategoryThemeType,
  Estimation__CategoryType,
  Estimation__RoomCategoryType,
  Estimation__SelectedRoomType,
  HydrateEstimationFlowV1Query,
} from '@portal/schema';

import { BaseAddedStepProps, BaseValues } from '@portal/components/orders/estimation/data';

// Room category icons
import bedroom from '@portal/images/estimation/bedroom.svg';
import closet from '@portal/images/estimation/closet.svg';
import livingRoom from '@portal/images/estimation/living_room.svg';
import kitchen from '@portal/images/estimation/kitchen.svg';
import diningRoom from '@portal/images/estimation/dining_room.svg';
import office from '@portal/images/estimation/office.svg';
import garage from '@portal/images/estimation/garage.svg';
import patio from '@portal/images/estimation/patio.svg';
import otherRoom from '@portal/images/estimation/other_room.svg';

// Item category icons
import appliancesV1 from '@portal/images/estimation/v1/appliances.svg';
import chairsV1 from '@portal/images/estimation/v1/chairs.svg';
import containersV1 from '@portal/images/estimation/v1/containers.svg';
import drawersV1 from '@portal/images/estimation/v1/drawers.svg';
import mattressV1 from '@portal/images/estimation/v1/mattress.svg';
import shelvesV1 from '@portal/images/estimation/v1/shelves.svg';
import sofaV1 from '@portal/images/estimation/v1/sofa.svg';
import tablesV1 from '@portal/images/estimation/v1/tables.svg';
import tvV1 from '@portal/images/estimation/v1/tv.svg';

export enum StepName {
  Intro = 'Intro',
  Boxes = 'Boxes',
  Address = 'Address',
  Rooms = 'Rooms',
  Items = 'Items',
  VirtualWalkthrough = 'Virtual Walkthrough',
  Status = 'Status',
  Review = 'Review',
  Plan = 'Plan',
}

export const FLOW_STEPS_MAP: Record<string, { dataFields: string[] }> = {
  [StepName.Boxes]: {
    dataFields: ['boxCategoryName'],
  },
  [StepName.Address]: {
    dataFields: ['address'],
  },
  [StepName.Rooms]: {
    dataFields: ['selectedRoomCounts'],
  },
  [StepName.Items]: {
    dataFields: ['selectedItemCounts'],
  },
  [StepName.Status]: {
    dataFields: ['estimationCompleted'],
  },
  [StepName.Review]: {
    dataFields: ['estimationFit'],
  },
};

export type Values = {
  boxCategoryName?: string;
  otherItemCategoryName?: string;
  selectedRoomCounts?: RoomCounts;
  selectedItemCounts?: ItemCounts;
  rooms?: Estimation__SelectedRoomType[];
  estimationCompleted?: boolean;
  estimationFit?: estimationFitEnum;
  allItemsInListedRooms?: boolean;
  itemsRequireMoreThanOneMover?: string;
} & BaseValues;

export enum estimationFitEnum {
  GoodFit = 'Good Fit',
  OverCapacity = 'Over Capacity',
  UnderCapacity = 'Under Capacity',
}

export type AddedStepProps = {
  roomCategories?: Estimation__RoomCategoryType[];
  itemCategories?: Estimation__CategoryThemeType[];
  boxCategories?: Estimation__CategoryType[];
  otherItemCategories?: Estimation__CategoryType[];
  additionalItemCategory?: Estimation__CategoryType;
} & BaseAddedStepProps;

export type StepType = StepProps<Values, AddedStepProps>;

// Map of room category ID to number of rooms
export type RoomCounts = Record<string, number>;
export type ItemCounts = Record<string, number>;

export type ItemSelections = HydrateEstimationFlowV1Query['estimationItems'];

export const CATEGORY_TO_ROOM_DETAILS: Record<string, any> = {
  Bedroom: {
    icon: bedroom,
  },
  'Walk-In Closet': {
    icon: closet,
  },
  'Living Room': {
    icon: livingRoom,
  },
  Kitchen: {
    icon: kitchen,
  },
  'Dining Room': {
    icon: diningRoom,
  },
  Office: {
    icon: office,
  },
  Garage: {
    icon: garage,
  },
  'Outdoor Patio': {
    icon: patio,
  },
  Other: {
    icon: otherRoom,
  },
};

export const CATEGORY_TO_ITEM_DETAILS: Record<string, any> = {
  Sofas: {
    icon: sofaV1,
    tagline: 'This category includes sofas, couches and large chairs that require two people to move:',
    examples: ['Chaise', 'Loveseat', 'Ottoman', 'Sectional Sofa', 'Sofa', 'Recliner', 'Pull-out Sofa Bed'],
  },
  Tables: {
    icon: tablesV1,
    tagline: 'This category includes tables of all sizes. Here are some examples:',
    examples: [
      'Bar Table',
      'Bedside Table',
      'Changing Table',
      'Coffee Table',
      'Conference Table',
      'Desk',
      'Dining Table',
      'Drop Leaf Table',
      'Foldable Table',
      'Glass Table Top',
      'Kid’s Table',
      'Massage Table',
      'Nightstand',
      'Patio Table',
      'Side Table',
      'Standing Desk',
    ],
  },
  'Appliances/Equipment': {
    icon: appliancesV1,
    tagline: 'This category includes refrigerators, washers, or other appliances that require two people to move:',
    examples: [
      'Arcade',
      'Barbecue Grill',
      'Freezer',
      'Dryer',
      'Refrigerator',
      'Stairmaster',
      'Stove',
      'Washer',
      'Wine Fridge',
    ],
  },
  'Storage Containers': {
    icon: containersV1,
    tagline: 'Here are some examples of storage containers:',
    examples: [
      'Chest',
      'Container',
      'Cooler',
      'Jewelry Box',
      'Milk Crate',
      'Pelican Case',
      'Plastic Bin / Bucket',
      'Storage Tub',
      'Tool Box',
      'Trunk',
      'Wardrobe Box',
      'Under-bed Bin',
    ],
  },
  Chairs: {
    icon: chairsV1,
    tagline: 'This category includes chairs that can be moved by one person:',
    examples: [
      'Accent Chair',
      'Baby Seating',
      'Bar Stool',
      'Bench',
      'Dining Chair',
      'Massage Chair',
      'Office Chair',
      'Papasan Chair',
      'Patio Chair',
      'Rocking Chair',
      'Wheelchair',
      'Living Room Chair',
    ],
  },
  Shelves: {
    icon: shelvesV1,
    tagline: 'This category includes shelves of various sizes. Here are some examples:',
    examples: ['Armoire', 'Bookshelf', 'China Cabinet', 'Shelves', 'Display Case'],
  },
  Drawers: {
    icon: drawersV1,
    tagline: 'This category includes drawers of various sizes. Here are some examples:',
    examples: [
      'Credenza',
      'Dresser',
      'Entertainment Center',
      'File Cabinet',
      'Kitchen Cart',
      'Minibar',
      'Rolling Island',
      'TV Stand',
      'Vanity',
    ],
  },
  Mattress: {
    icon: mattressV1,
    tagline: 'This category includes mattresses of various sizes. Here are some examples:',
    examples: [
      'Baby Mattress',
      'Full Mattress',
      'Kid/Toddler Mattress',
      'King Mattress',
      'Twin Mattress',
      'Queen Mattress',
    ],
  },
  'TV/Monitor': {
    icon: tvV1,
    tagline: 'This category includes TV’s and monitors of various sizes. Here are some examples:',
    examples: ['Computer Monitor', 'Flat Screen TV', 'Tube TV'],
  },
};
