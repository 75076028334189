import { StepProps as StaircaseStepProps } from '@clutter/staircase';

import {
  AddressDetailUnitSizeEnum,
  Estimation__CategoryType,
  EstimationMovingQuoteFragment,
  OrderTypeEnum,
} from '@portal/schema';

export type EstimationItemCounts = Record<string, number>;
export type SelectedItemCategories = Array<{
  category: Estimation__CategoryType;
  quantity: number;
}>;

export enum BuildingTypeEnum {
  House = 'House',
  Apartment = 'Apartment',
  Dorm = 'Dorm / Campus Apartment',
  Townhouse = 'Townhouse',
  Studio = 'Studio',
  StorageFacilityOrWarehouse = 'Storage Facility / Warehouse',
  Commercial = 'Commercial',
  Other = 'Other',
}

export type AddressDetails = {
  buildingType?: BuildingTypeEnum;
  floor?: number;
  access?: string;
  stories?: number;
  unitSize?: AddressDetailUnitSizeEnum;
  squareFootage?: number;
  longCarry?: boolean;
};

export type Address = {
  id?: string;
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  aptsuite?: string;
} & AddressDetails;

export enum AccessKind {
  NOT_APPLICABLE = 'Not Applicable',
  STAIRS = 'Stairs',
  ELEVATOR = 'Elevator',
  SERVICE_ELEVATOR = 'Service Elevator',
}

export type AddressDetailField = keyof Omit<AddressDetails, 'buildingType'> | 'aptsuite';

export const ADDRESS_BUILDING_TO_FIELDS_MAP: Record<string, AddressDetailField[]> = {
  [BuildingTypeEnum.House]: ['aptsuite', 'stories', 'longCarry'],
  [BuildingTypeEnum.Townhouse]: ['aptsuite', 'stories', 'longCarry'],
  [BuildingTypeEnum.Commercial]: ['aptsuite', 'squareFootage', 'stories', 'floor', 'access', 'longCarry'],
  [BuildingTypeEnum.Other]: ['aptsuite', 'squareFootage', 'floor', 'access', 'longCarry'],
  [BuildingTypeEnum.StorageFacilityOrWarehouse]: ['unitSize', 'floor', 'access', 'longCarry'],
  [BuildingTypeEnum.Apartment]: ['aptsuite', 'floor', 'access', 'longCarry'],
  [BuildingTypeEnum.Studio]: ['aptsuite', 'floor', 'access', 'longCarry'],
  [BuildingTypeEnum.Dorm]: ['aptsuite', 'floor', 'access', 'longCarry'],
};

export const ADDRESS_FIELD_MAP: Record<AddressDetailField, any> = {
  stories: {
    label: 'Number of Stories',
    input: 'tel',
  },
  aptsuite: {
    label: 'Unit Number (Optional)',
    input: 'text',
    optional: true,
  },
  floor: {
    label: 'Floor Number',
    input: 'tel',
  },
  access: {
    label: 'Access',
    input: 'select',
    options: [
      { label: 'Not applicable', value: AccessKind.NOT_APPLICABLE },
      { label: 'Stairs', value: AccessKind.STAIRS },
      { label: 'Elevator', value: AccessKind.ELEVATOR },
      { label: 'Service Elevator', value: AccessKind.SERVICE_ELEVATOR },
    ],
  },
  longCarry: {
    label: 'Where can we park?',
    input: 'select',
    options: [
      {
        label: 'Less than a 5 minute walk',
        value: false,
      },
      {
        label: 'More than a 5 minute walk',
        value: true,
      },
    ],
  },
  unitSize: {
    label: 'Unit Size',
    input: 'select',
    options: [
      { label: 'Locker', value: AddressDetailUnitSizeEnum.SizeLocker },
      { label: "5' x 5'", value: AddressDetailUnitSizeEnum.Size_5X5 },
      { label: "5' x 10'", value: AddressDetailUnitSizeEnum.Size_5X5 },
      { label: "10' x 10'", value: AddressDetailUnitSizeEnum.Size_10X10 },
      { label: "10' x 15'", value: AddressDetailUnitSizeEnum.Size_10X15 },
      { label: "10' x 20'", value: AddressDetailUnitSizeEnum.Size_10X20 },
      { label: "10' x 25'", value: AddressDetailUnitSizeEnum.Size_10X25 },
      { label: "10' x 30'", value: AddressDetailUnitSizeEnum.Size_10X30 },
    ],
  },
  squareFootage: {
    label: 'Square Footage',
    input: 'tel',
  },
};

export type BaseValues = {
  address?: Address;
  scheduled: string;
  destinationAddress?: Address;
  selectedItemCategories?: SelectedItemCategories;
  itemsRequireMoreThanOneMover?: string;
  otherItemCategoryName?: string;
  priceMatchGuaranteeEligible?: boolean;
  movingQuote?: EstimationMovingQuoteFragment;
};

export type CreateStepTransition = (params: {
  name: string;
  actionName: string;
  position: number;
  values?: Record<string, any>;
  metadata?: Record<string, any>;
}) => void;

export type BaseAddedStepProps = {
  nextLabel?: string;
  headline?: React.ReactNode;
  subheading?: React.ReactNode;
  hideNav?: boolean;
  loading?: boolean;
  setError: (error: string) => void;
  orderID: string;
  orderType: OrderTypeEnum;
  createStepTransition: CreateStepTransition;
  currentStep: number;
  stepName: string;
  algorithmName: string;

  // Internal step props to override
  canPrev?: boolean;
  prev?: () => void;
  neverFullWidthNext?: boolean;
};

export type BaseStepType = StaircaseStepProps<BaseValues, BaseAddedStepProps>;
