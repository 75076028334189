import React, { useEffect } from 'react';
import { COLORS, Box, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { client } from '@portal/libraries/apollo';

import {
  InventoryItemFragment,
  useEasypostOutboundShippingOptionsLazyQuery,
  Shipping__EasyPostOutboundShipment,
} from '@portal/schema';
import { IAddress } from '@portal/types';
import { DetailedBannerAlert } from '@portal/components/shared/detailed_banner_alert';
import { orderStepURL } from '@portal/config/routes';
import { wt } from '@portal/initializers/wt';
import { Base } from './base';
import { IStepProps } from '../form';
import { ItemInfoCard } from './shipping/item_info_card';
import { IItemMetadata, MetadataSelector } from './shipping/metadata_selector';

const Header = styled(Text.Title)`
  margin-bottom: 32px;
`;

const ItemNumberContainer = styled(Box)`
  margin-bottom: 8px;
`;

const ItemNumberText = styled(Text.Title)`
  color: ${COLORS.hippo};
`;

const ItemNumber: React.FC<{
  currentIndex: number;
  length: number;
}> = ({ currentIndex, length }) => (
  <ItemNumberContainer>
    <ItemNumberText size="extraSmall">
      {currentIndex}/{length}
    </ItemNumberText>
  </ItemNumberContainer>
);

const MissingRates: React.FC<{
  handleNoEligibility(): void;
}> = ({ handleNoEligibility }) => {
  const heading = 'No shipping options are available!';

  wt.track({
    pageName: 'portal:new_appointment',
    container: 'outbound_shipment_item_info_screen',
    action: 'display',
    label: heading,
    objectName: 'missing_shipping_rates_banner',
    objectType: 'banner',
  });

  return (
    <DetailedBannerAlert
      handleClick={() => handleNoEligibility()}
      heading={heading}
      subheading="Unfortunately we can't retrieve a shipping rate for this item, and will be unable to ship it. Please either remove this item or choose 'Clutter Delivery' in order to receive it."
      linkText="See Clutter Delivery Options"
    />
  );
};

export const OutboundItemInfo: React.FC<
  {
    selections: InventoryItemFragment[];
    address: IAddress;
    metadata: Map<string, IItemMetadata>;
    setMetadata(metadata: Map<string, IItemMetadata>): void;
    metadataIndex: number;
    setMetadataIndex(metadata: number): void;
    outboundShippingOptions: Shipping__EasyPostOutboundShipment[];
    setOutboundShippingOptions(availableShippingOptions: Shipping__EasyPostOutboundShipment[]): void;
    onNext(): void;
    onPrev(): void;
  } & IStepProps
> = ({
  selections,
  address,
  metadata,
  setMetadata,
  metadataIndex,
  setMetadataIndex,
  outboundShippingOptions,
  setOutboundShippingOptions,
  onNext,
  onPrev,
}) => {
  const history = useHistory();
  const [getShipmentOptions, { data, loading }] = useEasypostOutboundShippingOptionsLazyQuery({
    client,
    onCompleted: () => {
      if (data) {
        setOutboundShippingOptions([...outboundShippingOptions, data.easypostOutboundShippingOptions[0]]);
        if (data.easypostOutboundShippingOptions[0].rates.length > 0) {
          if (
            metadataIndex + 1 === selections.length &&
            Array.from(metadata.values()).every((entry) => Object.values(entry).some((value) => value))
          ) {
            onNext();
          } else {
            setMetadataIndex(metadataIndex + 1);
          }
        }
      }
    },
  });

  const onNextClick = () => {
    getShipmentOptions({
      variables: {
        input: {
          addressID: address.id!,
          outboundItems: [
            {
              itemID: selections[metadataIndex].id,
              heavy: metadata.get(selections[metadataIndex].id)!.heavy || false,
            },
          ],
        },
      },
    });
  };

  const onPrevClick = () => {
    const updatedOutboundShippingOptions = outboundShippingOptions.slice(0, outboundShippingOptions.length - 1);
    setOutboundShippingOptions(updatedOutboundShippingOptions);
    if (metadataIndex === 0) {
      onPrev();
    } else {
      setMetadataIndex(metadataIndex - 1);
    }
  };

  const headerLabel = 'Does this box hold fragile or heavy items?';
  const activeItem = selections[metadataIndex];

  useEffect(() => {
    wt.track({
      pageName: 'portal:new_appointment',
      container: 'outbound_shipment_item_info_screen',
      action: 'display',
      label: headerLabel,
      objectName: 'outbound_shipment_item_info',
      objectType: 'page',
      item_id: activeItem.id,
    });
  }, [activeItem.id]);

  return (
    <Base
      onNext={onNextClick}
      onPrev={onPrevClick}
      loading={loading}
      valid={!!metadata.get(activeItem.id) && Object.values(metadata.get(activeItem.id)!).some((value) => value)}
    >
      <Header size="large">{headerLabel}</Header>
      {outboundShippingOptions[metadataIndex] && outboundShippingOptions[metadataIndex].rates.length === 0 && (
        <MissingRates handleNoEligibility={() => history.push(orderStepURL('delivery_option'))} />
      )}
      <ItemNumber currentIndex={metadataIndex + 1} length={selections.length} />
      <ItemInfoCard item={activeItem} />
      <MetadataSelector itemID={activeItem.id} metadata={metadata} setMetadata={setMetadata} />
    </Base>
  );
};
