import React from 'react';

import { ClaimIssueSelectionKind } from '@portal/schema';

import { Checklist } from '@portal/components/helpers/checklist';
import { Step } from '@portal/components/claims/step';

import { IssueSelectionForm } from './form';

export const Issue = () => (
  <IssueSelectionForm field="kind">
    {({ loading, selection, onChange }) => (
      <>
        <Step.Title>Which best describes your issue?</Step.Title>
        <Step.Subtitle>Please select one.</Step.Subtitle>
        <Checklist>
          <Checklist.Entry
            format="radio"
            disabled={loading}
            checked={selection.kind === ClaimIssueSelectionKind.Appointment}
            onChange={() => onChange({ ...selection, kind: ClaimIssueSelectionKind.Appointment })}
          >
            A problem with an appointment
          </Checklist.Entry>
          <Checklist.Entry
            format="radio"
            disabled={loading}
            checked={selection.kind === ClaimIssueSelectionKind.Billing}
            onChange={() => onChange({ ...selection, kind: ClaimIssueSelectionKind.Billing })}
          >
            A problem with a bill or payment
          </Checklist.Entry>
          <Checklist.Entry
            format="radio"
            disabled={loading}
            checked={selection.kind === ClaimIssueSelectionKind.Other}
            onChange={() => onChange({ ...selection, kind: ClaimIssueSelectionKind.Other })}
          >
            Something else
          </Checklist.Entry>
        </Checklist>
      </>
    )}
  </IssueSelectionForm>
);
