import React from 'react';

import { AccountResolution__OfferFragment } from '@portal/schema';

import { RentalList } from './rental_list';

export const Summary: React.FC<{ offer: AccountResolution__OfferFragment }> = ({ offer }) => {
  const eligibleRateAdjustments = offer.metadata.eligible_rate_adjustments;
  const ineligibleRateAdjustments = offer.metadata.ineligible_rate_adjustments;
  return (
    <>
      We’re happy to maintain your current storage rate for the following units:
      <RentalList rateAdjustments={eligibleRateAdjustments} eligible={true} />
      {!!ineligibleRateAdjustments?.length && (
        <>
          The following units don’t quality for this offer:
          <RentalList rateAdjustments={ineligibleRateAdjustments} eligible={false} />
        </>
      )}
      These rates will be maintained for the next billing cycle.
    </>
  );
};
