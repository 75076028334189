import React from 'react';

import { COLORS } from '@clutter/clean';

export const Minus: React.FC<
  {
    fillColor?: string;
    iconColor?: string;
  } & React.HTMLAttributes<SVGElement>
> = ({ fillColor = COLORS.tealPrimary, iconColor = COLORS.storm, ...props }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="15" cy="15" r="15" fill={fillColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14.9986C8.00077 14.4463 8.44911 13.9986 9.00139 13.9986H21.0014C21.5537 13.9986 22.0008 14.4463 22 14.9986C21.9992 15.5509 21.5509 15.9986 20.9986 15.9986H8.99861C8.44633 15.9986 7.99923 15.5509 8 14.9986Z"
      fill={iconColor}
    />
  </svg>
);
