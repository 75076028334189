import * as FullStory from '@fullstory/browser';

type Config = {
  env?: 'test' | 'development' | 'production';
  visitor?: {
    id: string;
    name: string;
    email: string;
  };
  impersonator?: {
    id: string;
    name: string;
  };
};

declare const CONFIG: Config | undefined;

const FULL_STORY_ORG_ID = 'CW3B2';

if (CONFIG?.env === 'production') {
  FullStory.init({ orgId: FULL_STORY_ORG_ID });

  if (CONFIG?.visitor) {
    const { id: customerID, name: customerName, email: customerEmail } = CONFIG.visitor;

    if (CONFIG.impersonator) {
      const { id: impersonatorID, name: impersonatorName } = CONFIG.impersonator;
      FullStory.identify(`${impersonatorID}::${customerID}`, {
        displayName: `${impersonatorName} on behalf of ${customerName}`,
        email: customerEmail,
        impersonation: 'true',
      });
    } else {
      FullStory.identify(customerID, { displayName: customerName, email: customerEmail });
    }
  }
}
