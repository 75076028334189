import { ActionCard, COLORS, FontWeight, Icon, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

const Container = styled.div`
  max-width: 500px;
  padding: 0 24px 24px 24px;
`;

const StyledCard = styled(ActionCard)<{ selected: boolean }>`
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

const Content = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  margin-right: 24px;
`;

const PriceContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Remove = styled(Text.Callout)`
  color: ${COLORS.tealPrimary};
  cursor: pointer;
`;

const Card: React.FC<{
  title: string;
  description: string;
  price?: string;
  selected: boolean;
  icon: React.FC;
  onSelect(): void;
  onRemove?(): void;
}> = ({ title, description, price, selected, icon: CardIcon, onSelect, onRemove }) => (
  <StyledCard
    selected={selected}
    onSelect={() => !selected && onSelect()}
    icon={selected ? Icon.RadioChecked : Icon.RadioEmpty}
  >
    <Content>
      <IconContainer>
        <CardIcon />
      </IconContainer>
      <div>
        <Text.SmallCaps>{title}</Text.SmallCaps>
        <Text.Callout>{description}</Text.Callout>
        <PriceContainer>
          <Text.Callout weight={FontWeight.Medium}>{price}</Text.Callout>
          {selected && onRemove && (
            <Remove weight={FontWeight.Medium} onClick={onRemove}>
              Remove
            </Remove>
          )}
        </PriceContainer>
      </div>
    </Content>
  </StyledCard>
);

const Combined = {
  Container: Container,
  Card: Card,
};

export { Combined as Options };
