import React from 'react';
import { Link } from 'react-router-dom';

import styled from '@emotion/styled';

import { COLORS, Text, List, UnstyledButton, FontWeight } from '@clutter/clean';
import { TrackedClick } from '@portal/components/wt/tracked_click';
import { IEventParams } from '@portal/types/wt/event';

const FullContainer = styled.div`
  color: ${COLORS.panther};
  display: flex;
  justify-content: space-between;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  text-align: right;
  min-width: 100px;
`;

const LeftTitle = styled(Text.Body)`
  font-weight: 600;
`;

const DescriptionContainer = styled(Text.Body)`
  color: ${COLORS.storm};
  margin: 4px 0px 4px 0px;
`;

const ActionContainer = styled.div`
  margin-top: 8px;
`;

const ActionLink = styled(Link)`
  color: ${COLORS.tealPrimary};
  font-weight: 500;
  &:hover {
    color: ${COLORS.tealBrand};
    text-decoration: none;
  }

  cursor: pointer;
`;

export const ActionButton = styled(UnstyledButton)`
  color: ${COLORS.tealPrimary};
  font-weight: ${FontWeight.Medium};
  &:hover {
    color: ${COLORS.tealBrand};
    text-decoration: none;
  }

  cursor: pointer;
`;

type TrackedLinkActionProps = {
  params: {
    text: string;
    url: string;
    trackingParams?: IEventParams;
  };
};

export const TrackedLinkAction: React.FC<TrackedLinkActionProps> = ({ params }) => {
  const { url, text, trackingParams } = params;

  const actionButton = (
    <ActionContainer>
      <ActionLink to={url}>{text}</ActionLink>
    </ActionContainer>
  );

  const trackedActionButton = trackingParams && <TrackedClick params={trackingParams}>{actionButton}</TrackedClick>;

  return trackedActionButton || actionButton;
};

export const LineItem: React.FC<{
  label: React.ReactNode;
  description?: React.ReactNode;
  action?: React.ReactNode;
}> = ({ label, description, children, action }) => (
  <List.Item>
    <FullContainer>
      <LeftContainer>
        <LeftTitle>{label}</LeftTitle>
        {description && <DescriptionContainer>{description}</DescriptionContainer>}
        {action}
      </LeftContainer>
      <RightContainer>{children}</RightContainer>
    </FullContainer>
  </List.Item>
);
