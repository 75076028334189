import * as React from 'react';
import { useContext } from 'react';

import { Spacing, Text } from '@shared/components/bootstrap';
import { Stepper } from '@shared/components/helpers';
import { InventoryContext } from '@shared/components/inventory_capture/inventory_context';

export const HowManyMovers: React.FC = () => {
  const { data, onSelect } = useContext(InventoryContext);

  if (!data.requestedMovers) {
    onSelect({ ...data, requestedMovers: 1 });
  }

  return (
    <>
      <Text tag="p" style="info">
        <strong>How many people to lift your largest or heaviest item? </strong>
      </Text>
      <Spacing mt={4} mb={4}>
        <div className="row">
          <div className="col-auto">
            <Stepper
              kind="info"
              value={data.requestedMovers}
              min={1}
              max={5}
              onChange={(value) => onSelect({ ...data, requestedMovers: value })}
            />
          </div>
        </div>
      </Spacing>
    </>
  );
};
