export enum SourceBrand {
  AmericanExpress = 'American Express',
  DinersClub = 'Diners Club',
  JapanCreditBureau = 'JCB',
  MasterCard = 'MasterCard',
  Visa = 'Visa',
}

export interface ISource {
  id: string;
  number: string;
  brand?: SourceBrand;
  name?: string;
  expiration?: string;
  expired: boolean;
  default: boolean;
}
