import * as React from 'react';

interface ISelectionProps<T> {
  children(props: { selected?: T; onSelect(selected: T): void; onDeselect(): void }): React.ReactNode;
}

interface ISelectionState<T> {
  selected?: T;
}

export class Selection<T> extends React.Component<ISelectionProps<T>, ISelectionState<T>> {
  public state: ISelectionState<T> = { selected: undefined };

  public render() {
    const { children } = this.props;
    const { selected } = this.state;
    return children({
      selected,
      onSelect: this.onSelect,
      onDeselect: this.onDeselect,
    });
  }

  private onSelect = (selected: T) => {
    this.setState({ selected });
  };

  private onDeselect = () => {
    this.setState({ selected: undefined });
  };
}
