import * as React from 'react';
import { DateTime, Duration } from 'luxon';

const StandardConfirmation: React.FC<{ scheduled: DateTime; window: Duration }> = ({ scheduled, window }) => (
  <p>
    {scheduled.toLocaleString(DateTime.DATE_HUGE)}
    <br />
    Arrival Window: {scheduled.toLocaleString(DateTime.TIME_SIMPLE)} to{' '}
    {scheduled.plus(window).toLocaleString(DateTime.TIME_SIMPLE)}
  </p>
);

export const Confirmation: React.FC<{ scheduled: DateTime; window: Duration }> = ({ scheduled, window }) => (
  <>
    <p>
      <strong>Confirmed Appointment Time</strong>
    </p>
    <StandardConfirmation scheduled={scheduled} window={window} />
  </>
);
