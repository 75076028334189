import React from 'react';
import styled from '@emotion/styled';

import { COLORS, Text, FontWeight, Button, mq } from '@clutter/clean';

import { Spacer } from '@shared/components/helpers';
import { WhatToStoreBox } from '@portal/components/shared/icons';
import { useBreakpointFlags } from '@portal/utils';

import { StepName, estimationFitEnum, Values } from '../data';

const SummaryCard = styled.div`
  background-color: ${COLORS.cloud};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

const SummaryCardSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  ${mq({
    justifyContent: ['flex-start', 'space-between'],
  })};
`;

const SummaryCardSubSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const SummaryCardText = styled.div`
  margin-right: 24px;
`;

const PlanName = styled.span`
  white-space: nowrap;
`;

const CapacityCallout = styled.div`
  padding-right: 24px;
  display: flex;
  flex-direction: column;
`;

const CapacityPercentage = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: -7px;
`;

const MobileButton = styled(Button)`
  margin-top: 20px;
`;

const BannerContainer = styled.div`
  background-color: ${COLORS.dust};
  border-radius: 4px;
  width: 100%;
  margin-top: 16px;
  padding: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const BannerIcon = styled.div`
  margin-right: 24px;
`;

const BannerText = styled.div`
  display: flex;
  flex-direction: column;
`;

const selectCapacityCallouts = (estimationCalculatedCuft: number, planSizeCuft: number) => {
  if (estimationCalculatedCuft > planSizeCuft) {
    const percentage =
      (estimationCalculatedCuft - planSizeCuft) / planSizeCuft >= 0.5
        ? '>50'
        : Math.round(((estimationCalculatedCuft - planSizeCuft) / planSizeCuft) * 100);

    return {
      percentage: percentage,
      percentageCaption: 'over capacity',
      cardText: `You've maxed out on a `,
      button: 'Upgrade plan',
    };
  } else {
    return {
      percentage: Math.round((estimationCalculatedCuft / planSizeCuft) * 100),
      percentageCaption: 'of plan filled',
      cardText: `Plenty of space left for a `,
      button: 'Downgrade plan',
    };
  }
};

/* eslint-disable no-lonely-if */
const selectBannerText = (complete: boolean, estimationCalculatedCuft: number, planSizeCuft: number) => {
  if (complete) {
    if (estimationCalculatedCuft > planSizeCuft) {
      return {
        header: 'Consider upgrading to the next plan size up.',
        firstParagraph:
          'Based on the details you have entered, you may need a bigger plan size. You can select a bigger plan now to avoid any changes on the day of your appointment.',
        secondParagraph:
          'Otherwise your plan will automatically be updated based on what we actually pick up. You will only pay for what you store!',
      };
    } else {
      return {
        header: 'You still have plenty of space left in your plan.',
        firstParagraph:
          'Consider downgrading your plan size or make sure you have entered in all items that you plan to store.',
        secondParagraph:
          'It is important that this information is as accurate as possible as we use this to determine how many movers to send and how much time we schedule for your appointment.',
      };
    }
  } else {
    if (estimationCalculatedCuft > planSizeCuft) {
      return {
        header: 'You may need a bigger plan size.',
        firstParagraph: 'Based on the details you have entered so far, you may need a bigger plan size.',
        secondParagraph:
          'Remember to come back and finish entering your details. We use this to determine how many movers to send and how much time we schedule for your appointment.',
      };
    } else {
      return {
        header: 'You still have plenty of space left in your plan.',
        firstParagraph:
          "Based on the details you have entered so far, you have a lot of space remaining. Don't worry, you will only pay for what you store.",
        secondParagraph:
          'Remember to come back and finish entering your details. We use this to determine how many movers to send and how much time we schedule for your appointment.',
      };
    }
  }
};

export const BadFitCard: React.FC<{
  onChange(key: keyof Values, value?: any, cb?: (values: Values) => void): void;
  goToStep(step: string | number, meta?: any, toPrevious?: boolean): void;
  complete: boolean;
  estimationCalculatedCuft: number;
  planSizeName: string;
  planSizeCuft: number;
  planSizeComparison: string;
  estimationFit: estimationFitEnum;
  stepName?: string;
}> = ({
  onChange,
  goToStep,
  complete,
  estimationCalculatedCuft,
  planSizeName,
  planSizeCuft,
  planSizeComparison,
  estimationFit,
  stepName = StepName.Plan,
}) => {
  const { isMobile } = useBreakpointFlags();

  const capacityCallouts = selectCapacityCallouts(estimationCalculatedCuft, planSizeCuft);
  const bannerText = selectBannerText(complete, estimationCalculatedCuft, planSizeCuft);

  return (
    <SummaryCard>
      <SummaryCardSection>
        <SummaryCardSubSection>
          <CapacityCallout>
            <CapacityPercentage>
              <Text.Title size="large" color={COLORS.toucan}>
                {capacityCallouts.percentage}
              </Text.Title>
              <Text.Title size="small" color={COLORS.toucan}>
                %
              </Text.Title>
            </CapacityPercentage>
            <Text.SmallCaps color={COLORS.toucan}>{capacityCallouts.percentageCaption}</Text.SmallCaps>
          </CapacityCallout>
          <SummaryCardText>
            <Text.Title size="extraSmall">
              {capacityCallouts.cardText}
              <PlanName>{planSizeName}</PlanName>
            </Text.Title>
            <Spacer height="4px" />
            <Text.Callout weight={FontWeight.Regular}>
              A <PlanName>{planSizeName}</PlanName> is about enough space to store a {planSizeComparison}.
            </Text.Callout>
          </SummaryCardText>
        </SummaryCardSubSection>
        {!isMobile && complete && (
          <Button
            kind="secondary"
            size="medium"
            onClick={() => onChange('estimationFit', estimationFit, () => goToStep(stepName))}
          >
            {capacityCallouts.button}
          </Button>
        )}
      </SummaryCardSection>
      {isMobile && complete && (
        <MobileButton
          kind="secondary"
          size="medium"
          onClick={() => onChange('estimationFit', estimationFit, () => goToStep(stepName))}
        >
          {capacityCallouts.button}
        </MobileButton>
      )}
      <SummaryCardSection>
        <BannerContainer>
          <BannerIcon>
            <WhatToStoreBox />
          </BannerIcon>
          <BannerText>
            <Text.Callout weight={FontWeight.Medium}>{bannerText?.header}</Text.Callout>
            <Spacer height="12px" />
            <Text.Callout weight={FontWeight.Regular}>{bannerText?.firstParagraph}</Text.Callout>
            <Spacer height="12px" />
            <Text.Callout weight={FontWeight.Regular}>{bannerText?.secondParagraph}</Text.Callout>
          </BannerText>
        </BannerContainer>
      </SummaryCardSection>
    </SummaryCard>
  );
};
