import { HydrateEstimationFlowQuery, HydrateVirtualWalkthroughFlowQuery } from '@portal/schema';
import { AccessKind, Address, ADDRESS_BUILDING_TO_FIELDS_MAP, ADDRESS_FIELD_MAP, BuildingTypeEnum } from './data';

const OTHER_ITEM_RANGES = ['None', '1-10', '11-20', '21-30', '31-40', '41-50', '51+'];

export const isAddressCompleted = (address?: Address) =>
  !!address?.buildingType &&
  ADDRESS_BUILDING_TO_FIELDS_MAP[address.buildingType].every(
    (field) => address[field] !== undefined || ADDRESS_FIELD_MAP[field].optional,
  );

export const sanitizeAddress = (address: HydrateEstimationFlowQuery['order']['address']): Address => {
  let access = AccessKind.NOT_APPLICABLE;

  if (address.details?.stairs) {
    access = AccessKind.STAIRS;
  } else if (address.details?.serviceElevator) {
    access = AccessKind.SERVICE_ELEVATOR;
  } else if (address.details?.elevator) {
    access = AccessKind.ELEVATOR;
  }
  return {
    id: address.id,
    street: address.street,
    city: address.city,
    state: address.state,
    zip: address.zip,
    buildingType: (address.details?.buildingType as BuildingTypeEnum) || undefined,
    aptsuite: address.aptsuite || undefined,
    floor: address.details?.floor || undefined,
    stories: address.details?.stories || undefined,
    access: access,
    longCarry: address.details?.longCarry,
    squareFootage: address.details?.squareFeet || undefined,
    unitSize: address.details?.unitSize || undefined,
  };
};

export const sanitizeSelectedItemCategories = (
  estimationItems: HydrateVirtualWalkthroughFlowQuery['virtualWalkthroughEstimationItems'],
) =>
  estimationItems
    .filter((item) => !OTHER_ITEM_RANGES.includes(item.category.name))
    .map((item) => (({ category, quantity }) => ({ category, quantity }))(item));
