import React from 'react';
import styled from '@emotion/styled';
import { Box, ExternalContent, SpinLoader, Text } from '@clutter/clean';

import { useZendeskCopyQuery, Zendesk__ArticleName } from '@portal/schema';
import { IStepProps } from '@portal/components/orders/form';

import { Base } from './base';

const Title = styled(Text.Title)`
  margin-bottom: 16px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 32px;
`;

const Container = styled(Box)`
  max-width: 48rem;
`;

const Loader = () => (
  <SpinnerContainer>
    <SpinLoader />
  </SpinnerContainer>
);

export const DisposalExpectations: React.FC<
  {
    onNext?(): void;
    onPrev?(): void;
  } & IStepProps
> = ({ onNext, onPrev }) => {
  const { data, loading } = useZendeskCopyQuery({
    variables: { articleName: Zendesk__ArticleName.DisposalExpectations },
  });

  const zendeskCopy = data?.zendeskCopy;

  return (
    <Base onNext={onNext} onPrev={onPrev}>
      <Container>
        <Title size="large">What to expect with Disposals and Donations</Title>
        {loading ? (
          <Loader />
        ) : (
          <>{zendeskCopy && <ExternalContent dangerouslySetInnerHTML={{ __html: zendeskCopy }} />}</>
        )}
      </Container>
    </Base>
  );
};
