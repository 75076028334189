import { useEffect, useState } from 'react';

export const useURLSearchParamState = (
  name: string,
): [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>] => {
  const [state, setState] = useState<string | undefined>(
    () => new URLSearchParams(window.location.search).get(name) || undefined,
  );

  useEffect(() => {
    const url = new URL(window.location as any);
    if (url.searchParams.get(name) === state) return;
    if (state) url.searchParams.set(name, state);
    else url.searchParams.delete(name);
    history.pushState({}, '', url);
  }, [state, name]);

  return [state, setState];
};
