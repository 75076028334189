import * as React from 'react';

import { AddressDetailField, IAddress, IAddressDetail, IAddressValidationErrors } from '@shared/types';
import { AdditionalNotes } from './additional_notes';
import { BuildingFloor } from './building_floor';
import { BusinessName } from './business_name';
import { Environment } from './environment';

import { Restrictions } from './restrictions';
import { GatedCommunity } from './gated_community';
import { Access } from './access';
import { LongCarry } from './long_carry';
import { UnitSize } from './unit_size';

export const Warehouse: React.FC<{
  address: IAddress;
  details: IAddressDetail;
  errors?: IAddressValidationErrors;
  setAddress(address: IAddress): void;
  setDetails(details: IAddressDetail): void;
}> = ({ address, setAddress, details, setDetails, errors }) => (
  <>
    <BusinessName
      title="Name of Storage Company"
      value={address.businessName}
      error={errors?.businessName}
      onChange={(value) => setAddress({ ...address, businessName: value })}
    />
    <UnitSize
      value={details[AddressDetailField.UnitSize]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.UnitSize]: value })}
    />
    <Environment
      value={details.environment}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.Environment]: value })}
    />
    <BuildingFloor
      details={details}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.Floor]: value })}
    />
    <Access details={details} error={errors?.access} onChange={(values) => setDetails({ ...details, ...values })} />
    <LongCarry
      value={details[AddressDetailField.LongCarry]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.LongCarry]: value })}
    />
    <AdditionalNotes
      value={details[AddressDetailField.AdditionalNotes]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.AdditionalNotes]: value })}
    />
    <Restrictions
      details={details}
      onDetail={setDetails}
      error={errors?.[AddressDetailField.BuildingRestrictionsAnswer]}
      initialBuildingRestrictionsAnswer={details[AddressDetailField.BuildingRestrictionsAnswer]}
    />
    <GatedCommunity
      value={details[AddressDetailField.Gated]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.Gated]: value })}
    />
  </>
);
