import React from 'react';
import { TotalsTable, Highlight } from '@portal/components/shared/totals_table';

export const Totals: React.FC<{ delinquentBalance: number; paymentAmount: number; remainingBalance: number }> = ({
  delinquentBalance,
  paymentAmount,
  remainingBalance,
}) => (
  <TotalsTable.Container>
    <TotalsTable.Block>
      <TotalsTable.Line>
        <TotalsTable.LineItem name="Delinquent Balance" />
        <TotalsTable.LinePrice price={delinquentBalance} />
      </TotalsTable.Line>
      <TotalsTable.Line>
        <TotalsTable.LineItem name="Today's Payment" highlight={Highlight.Primary} subItem={true} />
        <TotalsTable.LinePrice
          price={paymentAmount}
          negative={true}
          highlight={Highlight.Primary}
          subItem={true}
          useFree={false}
        />
      </TotalsTable.Line>
    </TotalsTable.Block>
    <TotalsTable.Block>
      <TotalsTable.Line>
        <TotalsTable.LineItem
          highlight={!remainingBalance ? Highlight.Primary : Highlight.Danger}
          name="Remaining Balance"
        />
        <TotalsTable.LinePrice
          negative={false}
          highlight={!remainingBalance ? Highlight.Primary : Highlight.Danger}
          price={remainingBalance}
          useFree={false}
        />
      </TotalsTable.Line>
    </TotalsTable.Block>
  </TotalsTable.Container>
);
