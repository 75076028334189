import $ from 'jquery';
import React, { useEffect, useRef } from 'react';

export enum TooltipPlacement {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
}

const DEFAULT_PLACEMENT = TooltipPlacement.Top;

const version = (): number => {
  const tooltip = $.fn.tooltip;
  const [_, tooltipVersion] = /^(\d+)\.(\d+)\.(\d+)$/.exec((tooltip as any).Constructor.VERSION) ?? [];
  return Number(tooltipVersion);
};

type TooltipProps = {
  title?: string;
  placement?: TooltipPlacement;
  children: React.ReactNode;
};

type ElementProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

export const Tooltip: React.FC<TooltipProps & ElementProps> = ({
  title,
  placement = DEFAULT_PLACEMENT,
  children,
  ...props
}) => {
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!ref.current || !$.fn.tooltip) return;
    if (!title) return;

    const tooltip = $(ref.current).tooltip();

    return () => {
      // Bootstrap changed from 'destroy' to 'dispose' between v3 and v4 for tooltips:
      // v4: https://getbootstrap.com/docs/4.3/components/tooltips/#tooltipdispose
      // v3: https://getbootstrap.com/docs/3.3/javascript/#tooltip-destroy
      if (version() > 3) tooltip.tooltip('dispose');
      else (tooltip as any).tooltip('destroy');
    };
  }, [title]);

  if (!title) return <>{children}</>;

  return (
    <span
      {...props}
      data-html
      data-toggle="tooltip"
      data-placement={placement}
      data-original-title={title}
      children={children}
      title={title}
      ref={ref}
    />
  );
};
