import { COLORS } from '@clutter/clean';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { SERIF_FONT_FAMILY } from '@portal/constants/fonts';

export type Size = 'large' | 'medium' | 'small' | 'xlarge';
export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;
type Headings = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface IHeadingProps {
  /**
   * Specifies the nesting level of the heading.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements#Accessibility_concerns
   */
  level: HeadingLevel;
  /** Visual size of the heading. */
  size?: Size;
  children: React.ReactNode;
  className?: string;
}

const headingStyles: Record<Size, SerializedStyles> = {
  xlarge: css`
    @media (max-width: 600px) {
      font-size: 56px;
      line-height: 56px;
    }
    font-size: 72px;
    line-height: 72px;
  `,
  large: css`
    @media (max-width: 600px) {
      font-size: 40px;
      line-height: 44px;
    }
    font-size: 56px;
    line-height: 60px;
  `,
  medium: css`
    @media (max-width: 600px) {
      font-size: 32px;
      line-height: 40px;
    }
    font-size: 40px;
    line-height: 48px;
  `,
  small: css`
    font-size: 24px;
    line-height: 32px;
  `,
};

const StyledHeading = styled.h1<{ size: Size }>`
  ${({ size }) => headingStyles[size]}
  font-family: ${SERIF_FONT_FAMILY};
  font-weight: 500;
`;

export const Heading = ({ level, size, className, children }: IHeadingProps) => {
  const Wrapper = StyledHeading.withComponent(`h${level}` as Headings);
  let defaultSize: Size = 'large';
  if (level === 2) {
    defaultSize = 'medium';
  } else if (level > 2) {
    defaultSize = 'small';
  }
  return (
    <Wrapper size={size || defaultSize} className={className}>
      {children}
    </Wrapper>
  );
};

export const bodyLargeStyle = `
  font-size: 18px;
  line-height: 150%;
`;

export const bodyStyle = `
  font-size: 16px;
  line-height: 150%;
`;

export const captionStyle = `
  font-size: 14px;
  line-height: 160%;
  font-weight: 600;
`;

export const smallCapsStyle = `
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export const TextLink = styled.a`
  ${bodyStyle}
  font-weight: bold;
  color: ${COLORS.tealPrimary};
  :hover {
    text-decoration: none;
    color: ${COLORS.tealDark};
  }
`;
