import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { InputGroup } from '@shared/components/bootstrap';

import { BuildClaimPropertySelectionOwnerInput, ClaimPropertySelectionOwnership } from '@portal/schema';

import { ChoiceButton as Choice } from '@portal/components/helpers/choice_button';

import { Step } from '@portal/components/claims/step';

import { ClaimPropertySelectionForm } from './form';

export const DEFAULT_CLAIM_PROPERTY_OWNER: BuildClaimPropertySelectionOwnerInput = {
  name: '',
  email: '',
  phone: '',
};

interface IFieldsProps {
  owner: BuildClaimPropertySelectionOwnerInput;
  onChange(owner: BuildClaimPropertySelectionOwnerInput): void;
}

const Fields = ({ owner, onChange }: IFieldsProps) => (
  <>
    <div className="form-group">
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <FontAwesomeIcon icon="font" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <input
          className="form-control"
          type="text"
          placeholder="Owner’s Name"
          onChange={(event) =>
            onChange({
              ...owner,
              name: event.target.value,
            })
          }
          value={owner.name}
        />
      </InputGroup>
    </div>
    <div className="form-group">
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <FontAwesomeIcon icon="phone" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <input
          className="form-control"
          type="tel"
          placeholder="Owner’s Phone Number"
          onChange={(event) =>
            onChange({
              ...owner,
              phone: event.target.value,
            })
          }
          value={owner.phone}
        />
      </InputGroup>
    </div>
    <div className="form-group">
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <FontAwesomeIcon icon="at" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <input
          className="form-control"
          type="email"
          placeholder="Owner’s Email Address"
          onChange={(event) =>
            onChange({
              ...owner,
              email: event.target.value,
            })
          }
          value={owner.email}
        />
      </InputGroup>
    </div>
  </>
);

export const Owner = () => (
  <ClaimPropertySelectionForm field="ownership">
    {({ selection, onChange }) => (
      <>
        <Step.Title>Are you the owner of the damaged property?</Step.Title>
        <p>
          <Choice
            text="Yes"
            selected={selection.ownership === ClaimPropertySelectionOwnership.Customer}
            onSelect={() =>
              onChange({
                ...selection,
                ownership: ClaimPropertySelectionOwnership.Customer,
                owner: undefined,
              })
            }
          />
          <Choice
            text="No"
            selected={selection.ownership === ClaimPropertySelectionOwnership.Landlord}
            onSelect={() =>
              onChange({
                ...selection,
                ownership: ClaimPropertySelectionOwnership.Landlord,
                owner: undefined,
              })
            }
          />
        </p>
        {selection.ownership === ClaimPropertySelectionOwnership.Landlord && (
          <>
            <Step.Subtitle>
              Please enter the name, phone number, and email address of the owner of the damaged property.
            </Step.Subtitle>
            <Fields
              owner={selection.owner || DEFAULT_CLAIM_PROPERTY_OWNER}
              onChange={(owner) => onChange({ ...selection, owner })}
            />
          </>
        )}
      </>
    )}
  </ClaimPropertySelectionForm>
);
