import { sumBy } from 'lodash';
import React from 'react';

import { COLORS, FontWeight, Text } from '@clutter/clean';
import styled from '@emotion/styled';

import {
  PaymentPlanDetail__OfferFragment as PaymentPlanOffer,
  PaymentPlan__Offer__Type as OfferType,
} from '@portal/schema';

import { Pluralize } from '@shared/components/helpers';
import { currency } from '@shared/utils/currency';
import { formatDate } from '@shared/utils/format_date';

const DetailsContainer = styled.div`
  background: ${COLORS.grayBackground};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const DetailItem = styled.div`
  margin-top: 24px;
`;

const CurrencyWrapper = styled.span`
  color: ${COLORS.tealPrimary};
`;

const DateWrapper = styled.span`
  white-space: nowrap;
  font-weight: ${FontWeight.Medium};
`;

const Body = styled(Text.Body)`
  margin-bottom: 8px;
`;

const Headline = styled(Text.Headline)`
  padding-top: 32px;
  padding-bottom: 24px;
`;

export const OfferSummary: React.FC<{ offer: PaymentPlanOffer }> = ({ offer }) => {
  const { expiry, oneTimeFee, monthlyFee, monthlyFeeTerm, storageTermEndDate, settledInvoices, type } = offer;
  const monthlyFeesExist = !!monthlyFee && !!monthlyFeeTerm;
  const previousBalance = sumBy(settledInvoices, (invoice) => invoice.amountDue);
  const newBalance = (oneTimeFee || 0) + (monthlyFee || 0) * (monthlyFeeTerm || 0);
  const totalForgiven = previousBalance - newBalance;
  return (
    <>
      <Headline weight={FontWeight.Medium}>
        Make{' '}
        {monthlyFeesExist && (
          <>
            {monthlyFeeTerm!} monthly payments of <CurrencyWrapper>{currency(monthlyFee!)}</CurrencyWrapper>
          </>
        )}
        {!!oneTimeFee && (
          <>
            {monthlyFeesExist && ' along with '}a one-time payment of{' '}
            <CurrencyWrapper>{currency(oneTimeFee)}</CurrencyWrapper>
          </>
        )}
        , and{' '}
        {type === OfferType.AutopayOptIn ? 'add a card to your account' : 'your account will be completely up to date'}!
      </Headline>
      <DetailsContainer>
        <Text.Title size="extraSmall">Offer Details</Text.Title>
        <DetailItem>
          <Body weight={FontWeight.Medium}>Payment</Body>
          <Text.Callout>
            {!!oneTimeFee && !monthlyFeesExist && (
              <>
                {type === OfferType.AutopayOptIn ? (
                  <>
                    The {currency(totalForgiven)} off discount is a one time offer that applies only to the invoices
                    listed above. The {currency(oneTimeFee)} payment will be processed when you opt into autopay below.
                    All future invoices will be charged automatically on your monthly bill date.
                  </>
                ) : (
                  <>
                    A {currency(oneTimeFee)} payment will be processed upon acceptance of this payment plan. Once
                    successfully collected, the {currency(totalForgiven)} balance will be forgiven and you will regain
                    full access to your Clutter account.
                  </>
                )}
              </>
            )}
            {monthlyFeesExist && (
              <>
                Commit to paying {currency(monthlyFee!)} for {monthlyFeeTerm} months{' '}
                {!!oneTimeFee && <>along with a one-time payment of {currency(oneTimeFee)}</>} and you will regain full
                access to your Clutter account today. Your first month's payment will be due upon acceptance of this
                payment plan. The next{' '}
                <Pluralize count={offer.monthlyFeeTerm! - 1} singular="payment" plural="payments" /> will be collected
                monthly on the same date you accept this payment plan.
                <br />
                <br />
                These monthly payment plan payments are entirely separate from any other invoices that may be incurred
                on your account including, but not limited to, your standard storage subscription.
              </>
            )}
          </Text.Callout>
        </DetailItem>
        {!!storageTermEndDate && (
          <DetailItem>
            <Body weight={FontWeight.Medium}>Storage Commitment</Body>
            <Text.Callout>
              Accepting this payment plan comes with a commitment to store with Clutter through{' '}
              {formatDate(storageTermEndDate)}. If you need to close your account earlier, invoices from all storage
              subscriptions and/or payment plan agreements will be due at the time you book your final return.
            </Text.Callout>
          </DetailItem>
        )}
        {type !== OfferType.AutopayOptIn && (
          <DetailItem>
            <Body weight={FontWeight.Medium}>Future Invoices</Body>
            <Text.Callout>
              This offer only applies to the invoices listed above. All future payments will be auto-billed on the due
              date at the full rate.{' '}
              {monthlyFeesExist && (
                <>Monthly payment plan charges are entirely separate from your standard storage subscription payment.</>
              )}
            </Text.Callout>
          </DetailItem>
        )}
        {type === OfferType.AutopayOptIn && (
          <DetailItem>
            <Body weight={FontWeight.Medium}>Autopay</Body>
            <Text.Callout>
              Autopay is the easiest way to stay up to date on your storage payments. Your monthly rent will be billed
              automatically each month on your unit's bill date.
            </Text.Callout>
          </DetailItem>
        )}
        <DetailItem>
          <Body weight={FontWeight.Medium}>Offer Expiration</Body>
          <Text.Callout>
            This is a one-time offer that expires on <DateWrapper>{formatDate(expiry)}</DateWrapper>.{' '}
            {type !== OfferType.AutopayOptIn && (
              <>Once expired, the full invoiced amount will be owed and all stored items are auction eligible.</>
            )}
          </Text.Callout>
        </DetailItem>
      </DetailsContainer>
    </>
  );
};
