import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Theme } from '@portal/components/helpers/theme';
import { supportURL } from '@portal/config/routes';

import { AccountResolution } from './account_resolution';

export const Support: React.FC = () => (
  <Theme name="default">
    <Switch>
      <Route exact path={supportURL(':support_flow')} component={AccountResolution} />
      <Route exact path={supportURL()} component={AccountResolution} />
    </Switch>
  </Theme>
);
