import {
  IEstimationCategory,
  IEstimationSelection,
  IInventoryConfig,
  IOrderInventory,
  IPickupInventoryInput,
  IRoomCategory,
  IRoomSelection,
} from '@shared/types';

export const buildOrderInventory = (inventory: IPickupInventoryInput, config: IInventoryConfig): IOrderInventory => {
  const rooms = new Map<IRoomCategory, Partial<IRoomSelection>>();
  const packableEstimations = new Map<IEstimationCategory, Partial<IEstimationSelection>>();
  const unpackableEstimations = new Map<IEstimationCategory, Partial<IEstimationSelection>>();
  const isFullMoveOut = inventory.isFullMoveOut;
  // Full move outs don't get asked if they need packing help - we default it to true. We want to reset it here so
  // switching to 'Storing extra things' will force them to answer the needs packing help question.
  const needsPackingHelp = isFullMoveOut ? undefined : inventory.needsPackingHelp;
  const requestedMovers = inventory.requestedMovers ? inventory.requestedMovers : 0;
  const extraItems = inventory.estimatedItems || [];

  for (const room of inventory.rooms) {
    const category = config.roomCategories.find(({ id }) => id === room.categoryID)!;
    rooms.set(category, room);
  }

  for (const estimation of inventory.estimations) {
    const category = config.estimationCategories.find(({ id }) => id === estimation.categoryID)!;
    const estimations = category.packable ? packableEstimations : unpackableEstimations;
    estimations.set(category, estimation);
  }

  return {
    roomsComplete: rooms.size !== 0,
    extraItemsComplete: true,
    packingCategoriesComplete: true,
    hasExtraItems: extraItems.length > 0,
    isFullMoveOut,
    needsPackingHelp,
    requestedMovers,
    rooms,
    packableEstimations,
    unpackableEstimations,
    extraItems,
  };
};
