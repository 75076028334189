import { COLORS, Text, transparentize } from '@clutter/clean';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

export enum LabelKind {
  Primary,
  Danger,
  Warning,
  Default,
}

const styles: Record<LabelKind, SerializedStyles> = {
  [LabelKind.Primary]: css`
    color: ${COLORS.tealPrimary};
    background: ${COLORS.tealBackground};
  `,
  [LabelKind.Danger]: css`
    color: ${COLORS.toucan};
    background: ${transparentize(COLORS.flower, 0.5)};
  `,
  [LabelKind.Warning]: css`
    color: ${COLORS.tiger};
    background: ${transparentize(COLORS.dust, 0.5)};
  `,
  [LabelKind.Default]: css`
    color: ${COLORS.storm};
    background: ${COLORS.grayBackground};
  `,
};

const StyledLabel = styled.label<{ kind: LabelKind }>`
  padding: 1px 8px;
  border-radius: 4px;
  margin: 0;
  text-align: center;
  ${({ kind }) => styles[kind]};
`;

export const Label: React.FC<{
  kind: LabelKind;
  className?: string;
}> = ({ kind, className, children }) => (
  <StyledLabel className={className} kind={kind}>
    <Text.SmallCaps>{children}</Text.SmallCaps>
  </StyledLabel>
);
