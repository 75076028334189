import React from 'react';
import styled from '@emotion/styled';

import { COLORS, FontWeight, Text, List } from '@clutter/clean';

import { Estimation__SelectedRoomType } from '@portal/schema';
import { LineItem } from '../../../home/line_item';
import { StepName, Values, estimationFitEnum } from '../data';

const ListContainer = styled.div`
  margin-top: 24px;
`;

const EditContainer = styled.div`
  margin-top: 8px;
  width: 100%;
`;

const TextButton = styled(Text.Callout)`
  position: relative;
  color: ${COLORS.tealPrimary};
  &:hover {
    color: ${COLORS.tealBrand};
  }
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: -8px;
    padding: 16px;
  }
`;

const EditLink: React.FC<{ onEdit(): void }> = ({ onEdit }) => (
  <EditContainer>
    <TextButton onClick={onEdit} weight={FontWeight.Medium}>
      Edit
    </TextButton>
  </EditContainer>
);

export const Summary: React.FC<{
  boxCategoryName?: string;
  otherItemCategoryName?: string;
  rooms?: Estimation__SelectedRoomType[];
  selectedItemCounts: Record<string, number>;
  onChange(key: keyof Values, value?: any, cb?: (values: Values) => void): void;
  goToStep(step: string | number, meta?: any, toPrevious?: boolean): void;
  complete: boolean;
  estimationFit: estimationFitEnum;
}> = ({
  boxCategoryName,
  otherItemCategoryName,
  rooms,
  selectedItemCounts,
  onChange,
  goToStep,
  complete,
  estimationFit,
}) => {
  const roomCount = rooms?.length || 0;
  const itemCount = Object.values(selectedItemCounts).reduce((acc, quantity) => acc + quantity, 0);

  return (
    <ListContainer>
      <List shadow>
        <LineItem
          key="box"
          label={'Boxes'}
          action={<EditLink onEdit={() => onChange('estimationFit', estimationFit, () => goToStep(StepName.Boxes))} />}
        >
          {boxCategoryName && boxCategoryName !== 'None' ? boxCategoryName : '0'} boxes
        </LineItem>
        <LineItem
          key="room"
          label={'Rooms'}
          action={<EditLink onEdit={() => onChange('estimationFit', estimationFit, () => goToStep(StepName.Rooms))} />}
        >
          {roomCount} room{roomCount !== 1 ? 's' : ''}
        </LineItem>
        <LineItem
          key="item"
          label={'Items'}
          action={<EditLink onEdit={() => onChange('estimationFit', estimationFit, () => goToStep(StepName.Items))} />}
        >
          {itemCount} item{itemCount !== 1 ? 's' : ''}
        </LineItem>
        <LineItem
          key="other"
          label={'Other Items'}
          action={<EditLink onEdit={() => onChange('estimationFit', estimationFit, () => goToStep(StepName.Items))} />}
        >
          {otherItemCategoryName && otherItemCategoryName !== 'None' ? otherItemCategoryName : '0'} other items
        </LineItem>
        <LineItem
          key="status"
          label={'All items entered?'}
          action={<EditLink onEdit={() => onChange('estimationFit', estimationFit, () => goToStep(StepName.Status))} />}
        >
          {complete ? 'Yes' : 'No'}
        </LineItem>
      </List>
    </ListContainer>
  );
};
