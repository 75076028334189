import cn from 'classnames';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Box, COLORS, Radio, Text } from '@clutter/clean';

import { Order__ContactInput } from '@portal/schema';
import { DefaultContact } from '@portal/components/account/default_contact';
import { PhoneInput } from '@portal/components/helpers/phone_input';
import { IRadioOption } from '@portal/components/shared/radio';

export enum ContactType {
  Customer = 'CUSTOMER',
  Other = 'OTHER',
}

const DEFAULT_CUSTOMER = { name: '', phone: '' };

const Explanation = styled(Text.Body)`
  margin-bottom: 21px;
  color: ${COLORS.storm};
`;

const Fields: React.FC<{
  contact: Order__ContactInput;
  phoneValid: boolean;
  onChange(contact: Order__ContactInput): void;
  onPhoneValidation(valid: boolean): void;
}> = ({ contact, onChange, onPhoneValidation, phoneValid }) => {
  const [blurred, setBlurred] = useState<boolean>(false);
  const showInvalid = !phoneValid && blurred;
  const sanitize = (value: string) => value.replace(/\D/g, '');

  return (
    <>
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          name="name"
          placeholder="Name"
          className="form-control"
          value={contact.name}
          onChange={(event) => onChange({ ...contact, name: event.target.value })}
        />
      </div>
      <div className="form-group">
        <label>Number</label>
        <PhoneInput
          className={cn('form-control', { 'is-invalid': showInvalid })}
          value={contact.phone}
          placeholder="Phone"
          onChange={(event) => onChange({ ...contact, phone: sanitize(event.target.value) })}
          onValidation={onPhoneValidation}
          onFocus={() => setBlurred(false)}
          onBlur={() => setBlurred(true)}
        />
        {showInvalid && <div className="text-danger">Please provide a valid phone number.</div>}
      </div>
    </>
  );
};

export const TypeSelector: React.FC<{
  contact?: Order__ContactInput;
  onChange(contact?: Order__ContactInput): void;
  explanation: string;
  options: Array<IRadioOption<ContactType>>;
  setValid(valid: boolean): void;
}> = ({ contact, onChange, explanation, options, setValid }) => {
  const [phoneValid, setPhoneValid] = useState<boolean>(true);
  const [contactType, setContactType] = useState<ContactType>();

  useEffect(() => {
    const isValid =
      (contactType === ContactType.Customer && !contact) ||
      (contactType === ContactType.Other && contact && phoneValid);
    setValid(!!isValid);
  }, [setValid, contactType, phoneValid, contact]);

  const onContactChange = (type: ContactType) => {
    setContactType(type);

    if (type === ContactType.Customer) {
      setPhoneValid(true);
      if (contact) {
        onChange(undefined);
      }
    }
    if (type === ContactType.Other) {
      setPhoneValid(false);
      onChange(DEFAULT_CUSTOMER);
    }
  };

  return (
    <Box margin="16px 0">
      <Explanation>{explanation}</Explanation>
      <div className="form-group">
        <Radio.Selector<ContactType>
          name="contactType"
          onChange={onContactChange}
          value={contactType}
          options={options}
        />
      </div>
      {contact && contactType === ContactType.Other ? (
        <Fields contact={contact} onChange={onChange} onPhoneValidation={setPhoneValid} phoneValid={phoneValid} />
      ) : (
        <DefaultContact />
      )}
    </Box>
  );
};
