import React from 'react';

const ID = 'floating-check';

export const FloatingCheck: React.FC<{ title?: string }> = ({ title = 'Checkmark' }) => (
  <svg width="66" height="69" viewBox="0 0 66 69" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title id={ID}>{title}</title>
    <circle opacity="0.2" cx="33" cy="28" r="28" fill="#13BFB5" />
    <circle opacity="0.2" cx="33" cy="28" r="28" fill="#13BFB5" />
    <g filter="url(#filter0_d)">
      <circle cx="33" cy="28" r="15" fill="white" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.8152 31.9519C32.251 32.4317 31.4034 32.3934 30.8851 31.851L28.5479 29.4047C28.0104 28.8422 28.0307 27.9505 28.5933 27.4131C29.1558 26.8756 30.0475 26.8959 30.5849 27.4585L32.0051 28.9448L37.1196 24.2026C37.6901 23.6736 38.5814 23.7072 39.1103 24.2777C39.6393 24.8482 39.6057 25.7395 39.0352 26.2685L33.1599 31.7162C33.0545 31.8139 32.9382 31.8924 32.8152 31.9519Z"
      fill="#17847D"
    />
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="3"
        width="66"
        height="66"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="9" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);
