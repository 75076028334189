import * as React from 'react';

import { AddressWithDetailsFragment, OrderServiceTypeEnum, OrderTypeEnum } from '@portal/schema';

import {
  CargoVanFrontHalf,
  FiveBoxesVaried,
  MoverWithBoxes,
  MoverWithCartFridge,
  StorageFacility,
} from '@portal/components/shared/icons';

import { COLORS, FontWeight, Text } from '@clutter/clean';

export interface IServiceTypeCard {
  serviceType: OrderServiceTypeEnum;
  cardBody(serviceType: OrderServiceTypeEnum, totalPrice: string): React.ReactChild;
  infoHeader: string;
  infoBody(address?: AddressWithDetailsFragment): string;
  icon: React.FC;
}

const BaseAppointmentFee = (serviceType: OrderServiceTypeEnum, totalPrice: string) => {
  if (totalPrice === '$0') {
    return <></>;
  }

  return (
    <>
      <Text.Caption weight={FontWeight.Regular} color={COLORS.panther}>
        {serviceType === OrderServiceTypeEnum.Shipment ? 'Estimated' : 'Starting at'}
      </Text.Caption>
      <Text.Body weight={FontWeight.Medium} display={'inline'}>
        {totalPrice}
      </Text.Body>
      <Text.Caption weight={FontWeight.Regular} color={COLORS.storm} display={'inline'}>
        {serviceType === OrderServiceTypeEnum.Shipment ? ' flat fee' : ''}
      </Text.Caption>
    </>
  );
};

export const SERVICE_TYPE_MAPPING: {
  [key in OrderTypeEnum]?: {
    serviceTypeOptions: IServiceTypeCard[];
    prompt: string;
  };
} = {
  [OrderTypeEnum.Return]: {
    serviceTypeOptions: [
      {
        serviceType: OrderServiceTypeEnum.FullService,
        cardBody: BaseAppointmentFee,
        infoHeader: 'Clutter Delivery',
        infoBody: () => `Clutter movers will bring items inside to the floor and room that you choose.`,
        icon: MoverWithCartFridge,
      },
      {
        serviceType: OrderServiceTypeEnum.LongDistance,
        cardBody: BaseAppointmentFee,
        infoHeader: 'Clutter Delivery',
        infoBody: () => `Clutter movers will bring items inside to the floor and room that you choose.`,
        icon: MoverWithCartFridge,
      },
      {
        serviceType: OrderServiceTypeEnum.Facility,
        cardBody: BaseAppointmentFee,
        infoHeader: 'Warehouse Pickup',
        infoBody: (address) => {
          let copy = 'You or a moving company you hire collects items from the storage facility';
          if (address) {
            copy += ` in ${address.city}, ${address.stateAbbreviation}, ${address.zip}.`;
          } else {
            copy += '.';
          }
          return copy;
        },
        icon: StorageFacility,
      },
      {
        serviceType: OrderServiceTypeEnum.Disposal,
        cardBody: BaseAppointmentFee,
        infoHeader: 'Disposal and Donation',
        infoBody: () => 'Clutter will dispose of or donate your items.',
        icon: MoverWithBoxes,
      },
      {
        serviceType: OrderServiceTypeEnum.Shipment,
        cardBody: BaseAppointmentFee,
        icon: FiveBoxesVaried,
        infoHeader: `Standard Shipping`,
        infoBody: () => `Nationwide shipping available via FedEx.`,
      },
    ],
    prompt: 'How do you want to get your items back?',
  },
  [OrderTypeEnum.Pickup]: {
    serviceTypeOptions: [
      {
        serviceType: OrderServiceTypeEnum.FullService,
        cardBody: BaseAppointmentFee,
        infoHeader: 'Clutter Pickup',
        infoBody: () => 'Clutter movers will pack and move your items into storage.',
        icon: CargoVanFrontHalf,
      },
      {
        serviceType: OrderServiceTypeEnum.Shipment,
        cardBody: BaseAppointmentFee,
        icon: FiveBoxesVaried,
        infoHeader: `Standard Shipping`,
        infoBody: () => `Ship directly to our storage facility from anywhere in the US.`,
      },
    ],
    prompt: 'How do you want to add items to storage?',
  },
  [OrderTypeEnum.PickupReturn]: {
    serviceTypeOptions: [
      {
        serviceType: OrderServiceTypeEnum.FullService,
        cardBody: BaseAppointmentFee,
        infoHeader: 'Clutter Delivery & Pickup',
        infoBody: () => 'Clutter movers will deliver your items, then pack and store new items.',
        icon: MoverWithCartFridge,
      },
      {
        serviceType: OrderServiceTypeEnum.Shipment,
        cardBody: BaseAppointmentFee,
        icon: FiveBoxesVaried,
        infoHeader: `Standard Shipping`,
        infoBody: () =>
          `Ship from anywhere in the US. Choose from major carriers, print a shipping label, and drop off at your convenience.`,
      },
    ],
    prompt: 'How do you want to add items to storage?',
  },
};
