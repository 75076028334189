import * as React from 'react';
import styled from '@emotion/styled';
import { COLORS } from '@clutter/clean';

import { Element } from '@shared/components/stripe';

const Input = styled.input`
  height: 48px;
  margin-bottom: 24px;
  ::placeholder {
    color: ${COLORS.hippo};
  }
`;

export const Fields: React.FC<{
  element: IStripeElement;
  name?: string;
  onName(name: string | undefined): void;
}> = ({ element, name, onName }) => (
  <>
    <Input
      className="form-control"
      placeholder="Name"
      type="text"
      onChange={(event) => onName(event.target.value || undefined)}
      value={name || ''}
    />
    <Element element={element} />
  </>
);
