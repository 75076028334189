import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import { COLORS, FontWeight, Text, mq } from '@clutter/clean';

import { OrderTypeEnum, useEstimationFlowStatusQuery } from '@portal/schema';
import { orderURL } from '@portal/config/routes';
import { useBreakpointFlags } from '@portal/utils';
import { Spinner } from '@portal/components/helpers';
import amazonGCIcon from '@portal/images/amazon_gc.svg';
import itemBackdropDesktop from '@portal/images/item_backdrop_desktop.svg';
import itemBackdropMobile from '@portal/images/item_backdrop_mobile.svg';

import { GoodFitCard } from './good_fit_card';
import { BadFitCard } from './bad_fit_card';

import { Summary } from './summary';
import { StepType, estimationFitEnum, Values } from '../data';
import { StepContainer } from '../../step_container';

const Header = styled.div`
  background-color: ${COLORS.tealBackground};
  background-position: top center;
  background-repeat: no-repeat;
  transform: translateY(-40px);
  width: 100vw;
  height: 210px;
  margin-left: calc(-50vw + 50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq({
    backgroundImage: [`url(${itemBackdropMobile})`, `url(${itemBackdropDesktop})`],
    backgroundSize: ['100%', 'auto 100%'],
  })};

  h1 {
    ${mq({
      transform: [`translateY(75px)`, `translateY(120px)`],
    })};
  }
`;

const Body = styled.div`
  max-width: 700px;
  margin: 0 auto 0;

  ${mq({
    transform: [`translateY(-75px)`, `translateY(-60px)`],
  })};
`;

const IncentiveContainer = styled.div`
  background-color: ${COLORS.tealBackground};
  padding: 18px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  border-radius: 4px;
  height: 86px;
  img {
    margin-bottom: 54px;
  }
`;

const SelectCard: React.FC<{
  onChange(key: keyof Values, value?: any, cb?: (values: Values) => void): void;
  goToStep(step: string | number, meta?: any, toPrevious?: boolean): void;
  complete: boolean;
  estimationCalculatedCuft: number;
  planSizeName: string;
  planSizeCuft: number;
  planSizeComparison: string;
  estimationFit: estimationFitEnum;
}> = ({
  onChange,
  goToStep,
  complete,
  estimationCalculatedCuft,
  planSizeName,
  planSizeCuft,
  planSizeComparison,
  estimationFit,
}) =>
  estimationFit === estimationFitEnum.GoodFit ? (
    <GoodFitCard planSizeName={planSizeName} />
  ) : (
    <BadFitCard
      onChange={onChange}
      goToStep={goToStep}
      complete={complete}
      estimationCalculatedCuft={estimationCalculatedCuft}
      planSizeName={planSizeName}
      planSizeCuft={planSizeCuft}
      planSizeComparison={planSizeComparison}
      estimationFit={estimationFit}
    />
  );

export const Review: React.FC<StepType> = (props) => {
  const {
    values: { boxCategoryName, otherItemCategoryName, rooms, selectedItemCounts = {} },
    orderID,
    onChange,
    goToStep,
    orderType,
    createStepTransition,
  } = props;

  const { data, loading } = useEstimationFlowStatusQuery({
    variables: {
      orderID: orderID,
    },
    fetchPolicy: 'network-only',
  });

  const history = useHistory();
  const { isMobile } = useBreakpointFlags();

  if (loading || !data || !data.estimationStatus) {
    return <Spinner />;
  }

  const { complete, estimationCalculatedCuft, planSizeName, planSizeCuft, planSizeComparison, fitsPlanSize } =
    data.estimationStatus;

  const estimationFit = (function () {
    if (fitsPlanSize) {
      return estimationFitEnum.GoodFit;
    } else if (estimationCalculatedCuft > planSizeCuft) {
      return estimationFitEnum.OverCapacity;
    } else {
      return estimationFitEnum.UnderCapacity;
    }
  })();

  return (
    <StepContainer
      {...props}
      canPrev={false}
      nextLabel="OK"
      canNext
      next={() => {
        createStepTransition({
          name: 'estimation_flow',
          actionName: 'flow_completed',
          position: 0,
          values: {
            estimationFit: estimationFit,
          },
        });
        history.push(orderURL(orderID));
      }}
    >
      <Header>
        {isMobile ? (
          <Text.Title size="large">
            Review your
            <br />
            information
          </Text.Title>
        ) : (
          <Text.Title size="large">Review your information</Text.Title>
        )}
      </Header>

      <Body>
        <SelectCard
          onChange={onChange}
          goToStep={goToStep}
          complete={complete}
          estimationCalculatedCuft={estimationCalculatedCuft}
          planSizeName={planSizeName}
          planSizeCuft={planSizeCuft}
          planSizeComparison={planSizeComparison!}
          estimationFit={estimationFit}
        />
        <Summary
          boxCategoryName={boxCategoryName}
          otherItemCategoryName={otherItemCategoryName}
          rooms={rooms}
          selectedItemCounts={selectedItemCounts}
          onChange={onChange}
          goToStep={goToStep}
          complete={complete}
          estimationFit={estimationFit}
        />
        {orderType !== OrderTypeEnum.Move && (
          <IncentiveContainer>
            <img src={amazonGCIcon} />
            <Text.Callout weight={FontWeight.Medium}>
              Thank you! You will receive your gift card after your items have been moved into storage.
            </Text.Callout>
          </IncentiveContainer>
        )}
      </Body>
    </StepContainer>
  );
};
