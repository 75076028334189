import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import { OrderDetailsQuery, OrderSubtypeEnum, OrderTypeEnum, useOrderDetailsQuery } from '@portal/schema';

import { Callback } from './callback';
import { Canceled } from './canceled';
import { Confirm } from './confirm';
import { Reasons } from './reasons/index';

export type OrderDetailsOrder = OrderDetailsQuery['order'];

export const Steps = ({
  match: {
    path,
    params: { orderID },
  },
}: RouteComponentProps<{ orderID: string }>) => {
  const { data } = useOrderDetailsQuery({
    variables: { orderID },
    fetchPolicy: 'network-only',
  });

  const buildURL = (endpoint: string) => `${path}${endpoint}`;

  if (!data?.order) {
    return <></>;
  }

  const {
    order: { subtype, type },
    order,
  } = data;
  const isOnboarding = subtype === OrderSubtypeEnum.Onboarding;
  const isMove = type === OrderTypeEnum.Move;

  return (
    <Switch>
      <Route
        exact
        path={buildURL('/')}
        render={({ history }) => {
          if (isOnboarding || isMove) {
            return <Reasons orderType={type} orderSubtype={subtype} order={order} />;
          }
          return <Confirm history={history} order={order} />;
        }}
      />
      <Route
        exact
        path={buildURL('/reason')}
        render={() => <Reasons orderType={type} orderSubtype={subtype} order={order} />}
      />
      <Route exact path={buildURL('/call_back')} render={() => <Callback order={order} />} />

      <Route exact path={buildURL('/confirm')} render={({ history }) => <Confirm history={history} order={order} />} />
      <Route
        exact
        path={buildURL('/canceled')}
        render={({ history }) => <Canceled history={history} order={order} />}
      />
    </Switch>
  );
};
