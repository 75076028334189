import React, { useContext } from 'react';

/**
 * Utility to create a hook for accessing a context that doesn't provide a
 * default value (while enforcing that a provider is present).
 *
 * Example:
 *
 * ```
 * const MyContext = React.createContext<{ hasValue: boolean } | undefined>(undefined)
 *
 * const useMyContext = createContextHook(MyContext);
 *
 * // `value` is narrowed to remove `undefined`!
 * const value = useMyContext();
 * ```
 */
export function createContextHook<T>(context: React.Context<T | undefined>, contextName = 'Context') {
  return function useNonDefaultdContext() {
    const value = useContext(context);
    if (!value) throw new Error(`${contextName} is missing a provider!`);
    return value;
  };
}
