import styled from '@emotion/styled';
import React from 'react';
import type { DateTime } from 'luxon';
import type { FC, ReactNode } from 'react';

const DISABLED_STYLE = { color: '#ebeced', backgroundColor: '#ffffff', borderColor: '#ebeced' };
const SELECTED_STYLE = { color: '#ffffff', backgroundColor: '#097e77', borderColor: '#00a499' };
const BOOKED_STYLE = { color: '#000000', backgroundColor: '#ebeced', borderColor: '#757575' };
const DEFAULT_STYLE = { color: '#000000', backgroundColor: '#e8f6f5', borderColor: '#00a499' };

const CalendarEntryButton = styled.button<{
  disabled?: boolean;
  selected?: boolean;
  booked?: boolean;
  date: DateTime;
}>`
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  display: block;
  font-size: 18px;
  font-weight: 700;
  outline: none;
  padding: 0px;
  text-align: center;
  width: 100%;

  transition-duration: 150ms;
  transition-property: background-color, border-color, color;

  &:focus {
    outline: none;
  }

  ${({ disabled, selected, booked }) => {
    if (disabled) return DISABLED_STYLE;
    if (selected) return SELECTED_STYLE;
    if (booked) return BOOKED_STYLE;
    return DEFAULT_STYLE;
  }};

  grid-column: ${({ date }) => (date.weekday + 1) % 7};
`;

const CalendarEntryDate = styled.div`
  padding: 10px 0;
`;

export const CalendarEntry: FC<{
  children?(_: { date: DateTime; disabled?: boolean; selected?: boolean; booked?: boolean }): ReactNode;
  date: DateTime;
  booked?: boolean;
  disabled?: boolean;
  selected?: boolean;
  onSelect(date: DateTime): void;
}> = ({ children, date, booked, disabled, selected, onSelect }) => (
  <CalendarEntryButton
    aria-label={`${date.year}-${date.month}-${date.day}`}
    type="button"
    date={date}
    booked={booked}
    disabled={disabled}
    selected={selected}
    onClick={() => {
      onSelect(date);
    }}
  >
    {children ? (
      children({
        date,
        disabled,
        selected,
        booked,
      })
    ) : (
      <CalendarEntryDate>{date.day}</CalendarEntryDate>
    )}
  </CalendarEntryButton>
);
