import React from 'react';
import { Text } from '@clutter/clean';
import { DateTime } from 'luxon';

import { IOrder, OrderStatus } from '@portal/types';
import { Spacer } from '@shared/components/helpers';
import { Button as CustomerProtectedButton } from '@portal/components/shared/customer_protected/button';

const FROM_ISO_OPTIONS = { setZone: true };

export const Scheduled: React.FC<{ order: IOrder }> = ({ order }) => {
  const { number, scheduled, status } = order;
  const booked = DateTime.fromISO(scheduled, FROM_ISO_OPTIONS).toLocaleString(DateTime.DATETIME_MED);

  return (
    <>
      <Spacer height="1rem" />
      <Text.Title size="large">Disposal #{number}</Text.Title>

      <Text.Body>Booked on: {booked}</Text.Body>
      {status === OrderStatus.Pending && (
        <>
          <Spacer height="1rem" />
          <Text.Body>
            You have documents that need to be signed before we can proceed with disposing your items.
          </Text.Body>
          <Spacer height="1rem" />
          {order.documentHubURL && (
            <CustomerProtectedButton
              showErrorInTooltip={true}
              kind="primary"
              onClick={() => (window.location.href = order.documentHubURL!)}
            >
              Sign Documents
            </CustomerProtectedButton>
          )}
        </>
      )}
      <hr />
    </>
  );
};
