import React from 'react';

import { RetentionOfferFragment } from '@portal/schema';

// e.g. "40% off your storage for 3 months" or "$50 off your move"
export const Discount: React.FC<{
  offer: RetentionOfferFragment;
  discountable?: string; // e.g. 'your storage', 'your move', etc.
}> = ({
  discountable,
  offer: {
    detail: {
      action: { discountPercent, discountAmount, discountDuration },
    },
  },
}) => {
  const duration = discountDuration && <>for {discountDuration}</>;

  if (discountPercent)
    return (
      <>
        {discountPercent}% off {discountable} {duration}
      </>
    );
  if (discountAmount)
    return (
      <>
        ${discountAmount} off {discountable} {duration}
      </>
    );
  return null;
};
