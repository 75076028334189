import { usePerItemPricingQuery } from '@portal/schema';
import { IPerItemPricingData } from '@portal/utils/per_item_pricing';

export const useAccountPerItemPricingData = (): { loading: boolean; data: IPerItemPricingData } => {
  const { loading, data: perItemPricingQuery } = usePerItemPricingQuery();

  return {
    loading: loading,
    data: perItemPricingQuery?.account ?? {
      perItemPricing: false,
      perItemFee: '$0',
      baseAppointmentFee: '$0',
    },
  };
};
