import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router';

import { Text, Button, useSnackbarContext } from '@clutter/clean';
import { Financing__Intent, Order, useSwitchFinancingIntentMutation } from '@portal/schema';
import { orderURL } from '@portal/config/routes';
import { Title } from './title';
import { Affirm } from './affirm';
import { Section } from './section';

const SwitchText = styled(Text.Body)`
  margin: 0 0 24px;
  text-align: center;
`;

export const Switch: React.FC<{
  order?: Pick<Order, 'id'> & {
    financingIntent?: Pick<Financing__Intent, 'id' | 'intended'> | null;
  };
}> = ({ order }) => {
  const history = useHistory();
  const { addSnack } = useSnackbarContext();
  const [execute, { loading }] = useSwitchFinancingIntentMutation();
  if (!order) return null;
  const intended = order.financingIntent?.intended;
  if (intended) return null;

  const swap = async () => {
    if (loading) return;
    await execute({ variables: { orderID: order.id, intended: true } });
    addSnack({
      key: 'financing:switch',
      content: 'Thanks! You are all set to pay with Affirm.',
    });
    history.push(orderURL(order.id));
  };

  return (
    <Section>
      <Title>
        Switch your payment method to <Affirm />
      </Title>
      <SwitchText>
        Don’t worry, you can always switch back to paying with your credit card if you change your mind.
      </SwitchText>
      <Button fullWidth kind="secondary" onClick={swap} loading={loading}>
        Switch to Affirm
      </Button>
    </Section>
  );
};
