import { Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { TextLink } from '@portal/components/shared/text';
import { itemsURL } from '@portal/config/routes';
import { RetentionOfferFragment } from '@portal/schema';
import { currency } from '@shared/utils/currency';

import { Discount } from '@portal/components/retention/discount';

const Title = styled(Text.Title)`
  margin-bottom: 32px;
`;

export const AcceptedRetentionOffer: React.FC<{ offer: RetentionOfferFragment; storageRate: number }> = ({
  offer,
  storageRate,
}) => {
  const { waiveRateAdjustment } = offer.detail;
  let description;
  if (waiveRateAdjustment) {
    description = `Your upcoming rate adjustment has been waived and your storage rate will continue to be
      ${currency(storageRate)} / month.`;
  } else {
    description = (
      <>
        The <Discount offer={offer} discountable="storage" /> discount has been applied to your account.
      </>
    );
  }

  return (
    <>
      <Title size="large">Thank You</Title>
      <Text.Body>
        {description}
        <br />
        <br />
        There is no other action required.
        <br />
        <br />
        Take me back to <TextLink href={itemsURL()}>My Storage</TextLink>.
      </Text.Body>
    </>
  );
};
