import * as React from 'react';

import { Card } from '@shared/components/bootstrap';

import { addressNewURL } from '@portal/config/routes';
import { useAddressListQuery } from '@portal/schema';

import { Table } from './list/table';

export const AddressesPanel: React.FC = () => {
  const { data, refetch } = useAddressListQuery();
  const addresses = data?.addresses;

  return (
    <Card>
      <Card.Header>Address Book</Card.Header>
      {addresses && <Table addresses={addresses} onDestroy={() => refetch()} />}
      <Card.Footer>
        <div className="clearfix">
          <div className="float-right">
            <a className="btn btn-primary" href={addressNewURL()}>
              Add Address
            </a>
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};
AddressesPanel.displayName = 'AddressesPanel';
