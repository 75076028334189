import { css } from '@emotion/react';
import * as React from 'react';
import { useContext, useState } from 'react';

import { useActiveStorageDirectUpload } from '@shared/hooks';

import { Context } from '@shared/components/files/context';

import { SpinLoader, Text, UnstyledButton, COLORS, transparentize } from '@clutter/clean';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';

export const thumbnail = css`
  width: 100px;
  height: 100px;
  border-radius: 4px;
`;

export const Upload = styled.div`
  ${thumbnail}
`;

export const ImageThumbnail = styled.img`
  ${thumbnail};
  object-fit: cover;
`;

export const VideoThumbnail = styled.video`
  ${thumbnail};
  object-fit: cover;
`;

export const ImageUploadThumbnail = styled(ImageThumbnail)`
  position: absolute;
`;

export const VideoUploadThumbnail = styled(VideoThumbnail)`
  position: absolute;
`;

export const ErrorOverlay = styled.div`
  ${thumbnail}
  background: ${transparentize(COLORS.panther, 0.8)};
  position: absolute;
`;

export const ErrorBody = styled.div`
  width: 100px;
  position: absolute;
  margin-top: 24px;
  text-align: center;
`;

export const ErrorText = styled(Text.Body)`
  font-size: 14px;
  color: ${COLORS.cloud};
`;

export const RemoveButton = styled(UnstyledButton)`
  position: absolute;
  margin-top: -8px;
  margin-left: -4px;
  color: ${COLORS.tealPrimary};
  background: ${COLORS.cloud};
  height: 28px;
  width: 28px;
  border-radius: 50%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

export const RetryButtonStyle = styled(UnstyledButton)`
  margin-top: 4px;
  color: ${COLORS.tealPrimary};
  background: ${COLORS.cloud};
  border-radius: 4px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 4px 8px;
`;

export const MediaUploader: React.FC<{
  file: File;
  uuid: string;
}> = ({ file, uuid }) => {
  const [error, setError] = useState<Error | string | undefined>(undefined);
  const { onCancel, onUpload } = useContext(Context);
  const [objectURL, setObjectURL] = useState<string | null>(null);
  const isImage = file.type.split('/')[0] === 'image';
  const isVideo = file.type.split('/')[0] === 'video';

  React.useEffect(() => {
    setObjectURL(URL.createObjectURL(file));

    return () => {
      if (objectURL) {
        URL.revokeObjectURL(objectURL);
      }
    };
  }, []);

  const { retry } = useActiveStorageDirectUpload({
    file,
    onUpload: (signedID) => {
      setError(undefined);
      onUpload(file, uuid, signedID);
    },
    onError: setError,
  });

  const onDelete = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onCancel(file, uuid);
  };

  const onRetry = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setError(undefined);
    retry();
  };

  const LoadingIndicator = () => (
    <RemoveButton>
      <SpinLoader
        css={css`
          position: relative;
        `}
      />
    </RemoveButton>
  );

  const DeleteButton = () => (
    <RemoveButton aria-label="Delete" onClick={onDelete}>
      <FontAwesomeIcon icon="times" />
    </RemoveButton>
  );

  const RetryButton = () => (
    <RetryButtonStyle onClick={onRetry}>
      Retry <FontAwesomeIcon icon="redo" />
    </RetryButtonStyle>
  );

  return (
    <Upload>
      {objectURL && isVideo && <VideoUploadThumbnail src={objectURL} />}
      {objectURL && isImage && <ImageUploadThumbnail src={objectURL} />}

      {error && <ErrorOverlay />}
      {error ? <DeleteButton /> : <LoadingIndicator />}

      {error && (
        <ErrorBody>
          <ErrorText>Upload failed.</ErrorText>
          <RetryButton />
        </ErrorBody>
      )}
    </Upload>
  );
};
