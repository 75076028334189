import { AddressDetailUnitSizeEnum } from '@portal/schema';
import * as React from 'react';
import { SelectFormGroup } from '../select_form_group';

const OPTIONS = [
  { label: '5x5', value: AddressDetailUnitSizeEnum.Size_5X5 },
  { label: '5x10', value: AddressDetailUnitSizeEnum.Size_5X10 },
  { label: '10x10', value: AddressDetailUnitSizeEnum.Size_10X10 },
  { label: '10x15', value: AddressDetailUnitSizeEnum.Size_10X15 },
  { label: '10x20', value: AddressDetailUnitSizeEnum.Size_10X20 },
  { label: '10x25', value: AddressDetailUnitSizeEnum.Size_10X25 },
  { label: '10x30', value: AddressDetailUnitSizeEnum.Size_10X30 },
  { label: 'Locker', value: AddressDetailUnitSizeEnum.SizeLocker },
];

export const UnitSize: React.FC<{
  value?: AddressDetailUnitSizeEnum | null;
  onChange(value: AddressDetailUnitSizeEnum): void;
}> = ({ value, onChange }) => <SelectFormGroup label="Unit size" value={value} onChange={onChange} options={OPTIONS} />;
