import { COLORS, Text, List } from '@clutter/clean';
import styled from '@emotion/styled';
import { sumBy } from 'lodash';
import React from 'react';

import { planUpdateURL } from '@portal/config/routes';
import {
  StoragePlanFragment,
  UpcomingSubscriptionDetailsFragment,
  UpcomingSubscriptionSetFragment,
} from '@portal/schema';
import { storageName } from '@portal/utils/plan';
import { Spacer } from '@shared/components/helpers';
import { currency } from '@shared/utils/currency';

import { LineItem, TrackedLinkAction } from './line_item';

const MINIMAL_PROTECTION_PLAN_DESCRIPTION = '-';
const MONTH_TO_MONTH_COMMITMENT = 'Month to month';

const PlanTitle = styled(Text.Title)`
  color: ${COLORS.panther};
  margin-bottom: 8px;
`;

const Description = styled(Text.Body)`
  color: ${COLORS.panther};
`;

const TotalCost = styled(Text.Callout)`
  font-weight: 600;
`;

const Container = styled.div`
  margin-bottom: 48px;
`;

const updatePlanTrackingParams = (orderID: string, label: string, objectName: string) => ({
  pageName: 'portal:appointment_details',
  container: 'storage_plan',
  action: 'click',
  objectType: 'link',
  objectName: objectName,
  label: label,
  value: planUpdateURL(),
  order_id: orderID,
});

export const StoragePlan: React.FC<{
  upcomingSubscriptionSet: UpcomingSubscriptionSetFragment;
  term?: number;
  orderID: string;
}> = ({ upcomingSubscriptionSet, term, orderID }) => {
  const { storageSubscriptions, protectionSubscription } = upcomingSubscriptionSet;

  const totalCost = sumBy(storageSubscriptions, ({ total }) => total) + (protectionSubscription?.total || 0);
  const totalDiscount = sumBy(storageSubscriptions, ({ discount }) => discount);
  const totalTax = sumBy(storageSubscriptions, ({ tax }) => tax) + (protectionSubscription?.tax || 0);

  const nonZeroProtectionSubscription = (subscription?: UpcomingSubscriptionDetailsFragment | null) =>
    subscription && subscription.total > 0;

  return (
    <Container>
      <PlanTitle size="large">Your Storage Plan</PlanTitle>
      <Description>
        You’ll only pay for what you store. If you end up storing more or less than originally planned, we’ll let you
        know.
      </Description>
      <Spacer height="32px" />
      <List borderColor={COLORS.grayBorder}>
        {storageSubscriptions.map((storageSubscription) => (
          <>
            <LineItem
              key={storageSubscription.id}
              label={`${storageName(storageSubscription.pricing?.plan as StoragePlanFragment)} storage`}
              action={
                <TrackedLinkAction
                  params={{
                    text: 'Edit',
                    url: planUpdateURL(),
                    trackingParams: updatePlanTrackingParams(orderID, 'Edit', 'plan_size'),
                  }}
                />
              }
            >
              {`${currency(storageSubscription.total)}/month`}
            </LineItem>
          </>
        ))}
        <LineItem
          label="Protection plan"
          action={
            <TrackedLinkAction
              params={{
                text: nonZeroProtectionSubscription(protectionSubscription) ? 'Edit' : 'Add coverage',
                url: planUpdateURL(),
                trackingParams: updatePlanTrackingParams(
                  orderID,
                  nonZeroProtectionSubscription(protectionSubscription) ? 'Edit' : 'Add coverage',
                  'item_protection',
                ),
              }}
            />
          }
        >
          {protectionSubscription && nonZeroProtectionSubscription(protectionSubscription)
            ? `${currency(protectionSubscription.total)}/month`
            : MINIMAL_PROTECTION_PLAN_DESCRIPTION}
        </LineItem>
        <LineItem
          label="Term commitment"
          action={
            <TrackedLinkAction
              params={{
                text: 'Edit',
                url: planUpdateURL(),
                trackingParams: updatePlanTrackingParams(orderID, 'Edit', 'commitment'),
              }}
            />
          }
        >
          {term && term > 0 ? `${term} month minimum` : MONTH_TO_MONTH_COMMITMENT}
        </LineItem>
        {!!totalTax && <LineItem label="Taxes">{currency(totalTax)}</LineItem>}
        <LineItem label="Monthly total">
          <TotalCost>{currency(totalCost - totalDiscount + totalTax)}</TotalCost>
        </LineItem>
      </List>
    </Container>
  );
};
