import cn from 'classnames';
import * as React from 'react';

const DEFAULT_START = 0.0;
const DEFAULT_TOTAL = 1.0;

const percent = (loaded: number, total: number) => (100.0 * loaded) / total;

const ProgressBar: React.FC<{
  loaded: number;
  start?: number;
  total?: number;
  bg?: 'danger' | 'info' | 'success' | 'warning';
}> = ({ loaded, start = DEFAULT_START, total = DEFAULT_TOTAL, bg }) => (
  <div
    className={cn('progress-bar', bg && `bg-${bg}`)}
    aria-valuenow={loaded}
    aria-valuemin={start}
    aria-valuemax={total}
    role="progressbar"
    style={{ width: `${percent(loaded, total)}%` }}
  />
);

const Progress: React.FC = ({ children }) => <div className="progress">{children}</div>;

const Combined = Object.assign(Progress, { Bar: ProgressBar });
export { Combined as Progress };
