import { ChannelNameWithParams, Consumer } from '@rails/actioncable';
import { useEffect, useRef, useState } from 'react';

const URL = '/cable';
const CLIENT = new Consumer(URL);

export const useActionCableSubscription = <T>(
  // Be careful not to pass a channel object that will re-initialize every render
  // as it will trigger the subscribe/unsubscribe effect even if the values are identical
  channel?: string | ChannelNameWithParams,
  callback?: (data: T) => void,
): { connected?: boolean } => {
  const [connected, setConnected] = useState<boolean>(false);
  const ref = useRef(callback);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!channel) return;

    const subscription = CLIENT.subscriptions.create(channel, {
      connected: () => setConnected(true),
      disconnected: () => setConnected(false),
      received: (data: T) => {
        if (ref.current) {
          ref.current(data);
        }
      },
    });
    return (): void => {
      subscription.unsubscribe();
    };
  }, [channel]);

  return { connected };
};
