import React, { useContext, useState } from 'react';
import { Box, COLORS, Radio, Text, SkeletonLoader } from '@clutter/clean';
import styled from '@emotion/styled';
import { range } from 'lodash';

import {
  Shipping__ShipmentItemQuoteInput,
  Status,
  useShipmentItemQuotesUpsertMutation,
  Shipping__EasyPostOutboundShipment,
  useCustomerPriceQuery,
  useOutboundShipmentMinDaysQuery,
} from '@portal/schema';
import { client } from '@portal/libraries/apollo';

import { Base } from './base';
import { IStepProps } from '../form';
import { IItemMetadata } from './shipping/metadata_selector';
import { convertToSelectorOptions, getBestOptions, IShippingOption, Shipment } from './shipping/options';
import { OrderContext } from './order_context';

const ErrorMessage = styled(Text.Callout)`
  color: ${COLORS.toucan};
  margin-bottom: 8px;
`;

const Header = styled(Text.Title)`
  margin-bottom: 16px;
`;

const Disclaimer = styled(Text.Body)`
  margin-bottom: 24px;
`;

const Container = styled(Box.Flex)`
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
`;

export const OutboundShippingOptions: React.FC<
  {
    metadata: Map<string, IItemMetadata>;
    setShipping(option: IShippingOption): void;
    outboundShippingOptions: Shipping__EasyPostOutboundShipment[];
    setOutboundShippingOptions(availableShippingOptions: Shipping__EasyPostOutboundShipment[]): void;
    onNext(): void;
    onPrev(): void;
  } & IStepProps
> = ({ metadata, setShipping, outboundShippingOptions, setOutboundShippingOptions, onNext, onPrev }) => {
  const { order: orderInput } = useContext(OrderContext);
  const [shippingOption, setShippingOption] = useState<string>();
  const [error, setError] = useState<string>();
  const [execute, { loading: saving }] = useShipmentItemQuotesUpsertMutation();
  const { data, loading } = useCustomerPriceQuery({
    client,
    variables: {
      orderInput: orderInput,
      shippingOptions: getBestOptions(outboundShippingOptions),
    },
  });
  const { data: minDaysData, loading: loadingMinDays } = useOutboundShipmentMinDaysQuery({
    client,
    variables: { itemIDs: orderInput!.itemIDs! },
    skip: !orderInput?.itemIDs,
  });

  const Loader = () => {
    const loaderOptions = range(4).map((i) => ({
      value: i,
      label: <SkeletonLoader key={i} height="24px" width="225px" />,
    }));

    return (
      <Box>
        <Header size="large">Select your shipping option</Header>
        <Container>
          <Radio.Selector name="loader" value={null} options={loaderOptions} onChange={() => null} />
        </Container>
      </Box>
    );
  };

  const sanitizeInputs = (inputs: Shipment[]) =>
    inputs.map(({ itemID, rates }) => {
      const metadataValues = metadata.get(itemID);
      const { rate, deliveryDays, deliveryDate, service, carrier } = rates.find(
        (availableRate) => availableRate.service === shippingOption,
      )!;

      return {
        itemID,
        heavy: metadataValues!.heavy || false,
        fragile: metadataValues!.fragile || false,
        rate,
        deliveryDays,
        deliveryDate,
        service,
        carrier,
      } as Shipping__ShipmentItemQuoteInput;
    });

  const onSubmit = async (input: Shipment[]) => {
    setError(undefined);
    const sanitizedInput = sanitizeInputs(input);
    const response = await execute({
      variables: {
        inputs: sanitizedInput,
      },
    });
    if (response.data?.shipmentItemQuotesUpsert?.status === Status.Ok) {
      setShipping(bestOptions.find((option) => option.service === shippingOption)!);
      onNext();
    } else {
      setError(
        response.data?.shipmentItemQuotesUpsert?.error ||
          'Something went wrong, please submit a ticket and our team will review this issue.',
      );
    }
  };

  const onPrevClick = () => {
    const updatedOutboundShippingOptions = outboundShippingOptions.slice(0, outboundShippingOptions.length - 1);
    setOutboundShippingOptions(updatedOutboundShippingOptions);
    onPrev();
  };

  if (loading || loadingMinDays || !data) return <Loader />;

  const shipmentOptions = outboundShippingOptions;
  const bestOptions = data.customerPrice;
  const selectorOptions = convertToSelectorOptions(bestOptions);
  const minDays = minDaysData?.outboundShipmentMinDays ?? 1;

  return (
    <Base
      onNext={() => onSubmit(shipmentOptions)}
      onPrev={onPrevClick}
      valid={!!shippingOption && !saving}
      nextLabel={saving ? 'Loading' : undefined}
    >
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Header size="large">Select your shipping option</Header>
      <Disclaimer>
        The current estimated time to ship your items is {minDays}-{minDays + 2} business days.
      </Disclaimer>
      <Container>
        <Radio.Selector name="option" value={shippingOption} options={selectorOptions} onChange={setShippingOption} />
      </Container>
    </Base>
  );
};
