import React from 'react';

export const WhatToStoreBox: React.FC<{ width?: string }> = ({ width = '60' }) => (
  <svg width={width} height="70.5" viewBox="0 0 80 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#what_to_store_box)">
      <path
        d="M66.7803 40.8408L78.8803 51.2208L68.7603 47.9208L66.7803 40.8408Z"
        fill="#00A499"
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.7805 40.8408L76.3205 74.9408L44.6005 93.0008L14.2605 83.1008L4.73047 49.0008L35.0705 58.9008L66.7805 40.8408Z"
        fill="#00A499"
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2803 83.1805L44.6203 93.0805L76.3403 75.0205"
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M35.0903 58.9805L44.6203 93.0805" stroke="#00404D" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M35.0902 58.9801L31.3302 78.4101L0.990234 68.5101L4.75023 49.0801L35.0902 58.9801Z"
        fill="#00A499"
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75 49.0805L35.09 58.9805L66.81 40.9205L36.46 31.0205L4.75 49.0805Z"
        fill="#00404D"
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.0902 58.9805L31.3302 78.4105L0.990234 68.5105"
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M66.8101 40.9209L68.7901 48.0009" stroke="#00404D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M36.7002 31.8701L42.7802 53.6001" stroke="#00A499" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M47.1103 61.0008C47.1661 61.2407 47.2797 61.4633 47.4412 61.6493C47.6027 61.8352 47.8073 61.9788 48.0371 62.0676C48.2668 62.1564 48.5148 62.1875 48.7594 62.1585C49.004 62.1294 49.2378 62.0409 49.4403 61.9008L57.8103 57.1408C58.3134 56.841 58.7071 56.3879 58.9339 55.8481C59.1606 55.3082 59.2084 54.7098 59.0703 54.1408C59.0159 53.9 58.9032 53.6762 58.7422 53.4891C58.5812 53.302 58.3768 53.1571 58.1469 53.0673C57.9169 52.9775 57.6685 52.9455 57.4233 52.974C57.178 53.0024 56.9436 53.0906 56.7403 53.2308L48.3603 58.0008C47.8603 58.3031 47.4695 58.7569 47.2447 59.2964C47.02 59.8358 46.9729 60.4328 47.1103 61.0008Z"
        fill="#00404D"
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.8204 23.3204C58.9513 19.146 57.8982 15.0198 55.7829 11.4186C53.6675 7.81741 50.5763 4.88834 46.8665 2.9699C43.1567 1.05146 38.9798 0.221983 34.8184 0.577325C30.6571 0.932667 26.6813 2.45833 23.3504 4.97797C20.0196 7.49762 17.4698 10.9084 15.9956 14.8161C14.5215 18.7237 14.1832 22.9688 15.02 27.0606C15.8567 31.1524 17.8342 34.9239 20.724 37.9393C23.6138 40.9546 27.2979 43.0906 31.3504 44.1004L35.7304 53.6004L40.7304 44.4004C45.6986 43.4786 50.202 40.885 53.4926 37.0504C56.7833 33.2158 58.6633 28.371 58.8204 23.3204Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4902 22C15.7797 16.4482 18.2333 11.2309 22.3248 7.46712C26.4163 3.70334 31.8199 1.69286 37.3766 1.86687C42.9332 2.04088 48.2004 4.38554 52.0484 8.39795C55.8964 12.4104 58.0187 17.771 57.9602 23.33"
        stroke="#FFEABF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.1803 27.5508C34.1715 26.3362 34.3956 25.1311 34.8403 24.0008C35.3196 23.0023 36.0249 22.1293 36.9003 21.4508C37.6565 20.8801 38.3296 20.207 38.9003 19.4508C39.2249 18.945 39.4079 18.3614 39.4303 17.7608C39.4903 16.0008 38.6703 15.0508 37.0103 15.0008C36.6645 14.9752 36.3171 15.0215 35.9901 15.1368C35.6631 15.2521 35.3635 15.4339 35.1103 15.6708C34.5895 16.2061 34.2988 16.9239 34.3003 17.6708L29.6903 17.5208C29.6812 16.6314 29.8651 15.7506 30.2294 14.9392C30.5937 14.1278 31.1296 13.405 31.8003 12.8208C33.322 11.6586 35.2226 11.1059 37.1303 11.2708C39.011 11.1905 40.8553 11.8064 42.3103 13.0008C42.9247 13.6013 43.3979 14.331 43.6954 15.137C43.9929 15.943 44.1073 16.8051 44.0303 17.6608C44.0036 18.5487 43.7708 19.4183 43.3503 20.2008C42.7612 21.1799 42.0183 22.0577 41.1503 22.8008L39.8203 24.0008C39.0236 24.688 38.5017 25.6396 38.3503 26.6808L38.2603 27.6808L34.1803 27.5508Z"
        fill="white"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.5403 33.2902C33.5411 32.9631 33.6109 32.6399 33.7454 32.3418C33.8798 32.0436 34.0757 31.7773 34.3203 31.5602C34.8503 31.1318 35.5166 30.9082 36.1976 30.9302C36.8787 30.9522 37.5292 31.2184 38.0303 31.6802C38.2525 31.9151 38.4261 32.1916 38.5413 32.4937C38.6566 32.7958 38.7111 33.1177 38.7018 33.4409C38.6925 33.7641 38.6196 34.0823 38.4872 34.3774C38.3548 34.6724 38.1656 34.9384 37.9303 35.1602C37.6728 35.3852 37.3731 35.5568 37.0486 35.665C36.7241 35.7731 36.3814 35.8157 36.0403 35.7902C35.3537 35.7942 34.693 35.5284 34.2003 35.0502C33.9741 34.8187 33.7986 34.5427 33.685 34.2397C33.5713 33.9367 33.5221 33.6133 33.5403 33.2902Z"
        fill="white"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M60.0503 34.2107L63.7703 32.7207" stroke="#F79C94" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.9802 39L9.49023 40" stroke="#F79C94" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.1703 34.7608L9.26025 33.0908" stroke="#F79C94" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="what_to_store_box">
        <rect width="78.89" height="93.58" fill="white" transform="translate(0.490234)" />
      </clipPath>
    </defs>
  </svg>
);
