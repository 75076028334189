import React, { useState } from 'react';

import { Dialog } from './dialog';

export const Action: React.FC<{
  source: string;
  container: React.RefObject<HTMLElement>;
  onClick(): void;
}> = ({ source, container, onClick, children }) => {
  const [sharing, setSharing] = useState<boolean>(false);

  return (
    <div
      title="Email"
      onClick={() => {
        onClick();
        setSharing(true);
      }}
    >
      {children}
      {sharing && <Dialog container={container} onClose={() => setSharing(false)} source={source} />}
    </div>
  );
};
