import React from 'react';

import { AccountResolution__OfferFragment } from '@portal/schema';
import { Currency } from '@shared/components/helpers';
import { formatDate } from '@shared/utils';

export const Summary: React.FC<{ offer: AccountResolution__OfferFragment }> = ({ offer }) => {
  const oldPlanPrice = Number(offer.metadata.old_plan_price) || undefined;
  return (
    <>
      We’re happy to{' '}
      {offer.metadata.action === 'reverse' ? (
        <>
          reverse the price change that occurred on {formatDate(offer.metadata.effective_date)}
          {!!offer.metadata.refund_amount && (
            <>
              {' '}
              and refund <Currency value={Number(offer.metadata.refund_amount)} precision="automatic" /> in charges
            </>
          )}
          .{' '}
          {oldPlanPrice && (
            <>
              This change will set your monthly storage rate back to{' '}
              <Currency value={oldPlanPrice} precision="automatic" />
              /mo.
            </>
          )}
        </>
      ) : (
        <>
          maintain your current storage rate{' '}
          {oldPlanPrice && (
            <>
              of <Currency value={oldPlanPrice} precision="automatic" />
              /mo{' '}
            </>
          )}
          for your next billing cycle.
        </>
      )}
    </>
  );
};
