import * as React from 'react';

import { Box, COLORS, SkeletonLoader } from '@clutter/clean';
import { CustomerItemFragment } from '@portal/schema';
import { itemURL } from '@portal/config/routes';
import { Link } from 'react-router-dom';
import { SMALL_WIDTH, LARGE_WIDTH } from '@portal/components/helpers/inventory/layout';

import { ItemCard } from '@portal/components/helpers/inventory/item_card';
import { useItemViewContext } from '../item_view_context';

const Loader = () => (
  <ItemCard.Container>
    <SkeletonLoader width="100%" height={[SMALL_WIDTH, null, null, LARGE_WIDTH]} />
    <Box padding="8px 24px" border={`1px solid ${COLORS.grayBackground}`} borderStyle="none none solid">
      <SkeletonLoader height="16px" />
    </Box>
  </ItemCard.Container>
);

const Item: React.FC<{ item: CustomerItemFragment }> = ({ item }) => {
  const { setSelectedItem, selectedItem } = useItemViewContext();
  const selected = selectedItem?.uuid === item.uuid;

  return (
    <ItemCard.Container data-test-id={`item-${item.uuid}`}>
      <Link
        to={itemURL(item.uuid)}
        onClick={(e) => {
          // This link is technically valid, but wouldn't have the same filters.
          e.preventDefault();
          setSelectedItem(item);
        }}
      >
        <ItemCard.Content item={item} selected={selected} selectedText="Viewing" unselectedText="View" />
      </Link>
    </ItemCard.Container>
  );
};

const Combined = Object.assign(Item, { Loader });

export { Combined as ItemCard };
