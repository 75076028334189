import styled from '@emotion/styled';
import { COLORS, mq, Text } from '@clutter/clean';
import React from 'react';

import { Close as CloseIcon } from '@portal/components/shared/icons';
import { Modal } from '@shared/components/bootstrap';

const Content = styled(Modal.Content)`
  padding: 24px;
  background: white;
`;

const Header = styled.div`
  padding-bottom: 16px;
`;

const Title = styled(Text.Title)`
  ${mq({ fontSize: ['32px', null, '40px'] })}
`;

const Body = styled.div`
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  text-align: center;
  button {
    margin-bottom: 16px;
  }
`;

const Error = styled(Text.Caption)`
  margin-top: 8px;
  color: ${COLORS.toucan};
  a {
    color: ${COLORS.toucan};
    text-decoration: underline;
    &:focus,
    &:hover {
      color: ${COLORS.ladyBug};
    }
  }
`;

const CloseContainer = styled.div`
  height: 38px;
  text-align: right;
  padding: 10px 10px 0;
  margin: -24px -24px 0;
  &:hover {
    cursor: pointer;
  }
`;

const Close: React.FC<{ onClose(): void }> = ({ onClose }) => (
  <CloseContainer>
    <CloseIcon onClick={onClose} />
  </CloseContainer>
);

const Combined = {
  Content: Content,
  Header: Header,
  Title: Title,
  Close: Close,
  Body: Body,
  Buttons: ButtonContainer,
  Error: Error,
};
export { Combined as BaseModal };
