import React from 'react';

export const LocationTruckTracking: React.FC<{ width?: number; height?: number }> = ({ width = 93, height = 84 }) => (
  <svg width={width} height={height} viewBox="0 0 93 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M51.29 79.5003L88.52 65.4603L4.5 47.5803L89.14 11.8203"
      stroke="#A1E6E0"
      strokeWidth="7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.2895 1.5C30.8095 1.5 18.1395 23.8 28.6095 41.4L51.2895 79.5L73.9695 41.4C84.4395 23.8 71.7595 1.5 51.2895 1.5ZM51.2895 37.22C46.1595 37.22 41.9995 33.06 41.9995 27.93C41.9995 22.8 46.1595 18.64 51.2895 18.64C56.4195 18.64 60.5795 22.8 60.5795 27.93C60.5695 33.06 56.4195 37.22 51.2895 37.22Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.1495 28.14C27.1095 23.98 28.1695 19.8 30.3395 15.99C34.6795 8.35004 42.5095 3.79004 51.2895 3.79004C60.0695 3.79004 67.8995 8.35004 72.2395 15.98C74.3695 19.74 75.4395 23.84 75.4295 27.93M39.7095 27.94C39.7095 34.32 44.8995 39.52 51.2895 39.52C57.6795 39.52 62.8695 34.33 62.8695 27.94"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.57 27.9404C60.57 22.8104 56.41 18.6504 51.28 18.6504C46.15 18.6504 42 22.8104 42 27.9404"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.9694 41.4004L51.2894 79.5004L28.6094 41.4004"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.2598 27.4103L89.1398 11.8203"
      stroke="#A1E6E0"
      strokeWidth="7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
