import React from 'react';

const ID = 'piggy-bank';

export const PiggyBank: React.FC<{ title?: string; size?: number }> = ({ title = 'Piggy Bank', size = 30 }) => (
  <svg
    aria-labelledby={ID}
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id={ID}>{title}</title>
    <g clipPath="url(#clip0)">
      <path
        d="M24.1294 11.9684L26.1647 9.30873C26.4236 8.98191 26.0706 8.53112 25.6706 8.67763L21.153 10.3343C3.82355 4.24862 -1.4941 20.6799 5.91767 24.3876V29.6393H12.2V26.8557H16.7883V29.6393H22.353V25.0863L25.7412 21.8407H28.0824C29.0118 21.8407 29.7647 21.1194 29.7647 20.2291V14.1998H25.7412L24.1294 11.9684Z"
        fill="white"
      />
      <path
        d="M25.7417 14.6223L24.13 12.3796L26.1652 9.71999C26.4241 9.39316 26.0711 8.94237 25.6711 9.08888L21.1535 10.7455C16.9182 9.25793 13.4005 9.11142 10.6123 9.79887C10.8005 9.94538 11.0005 10.0806 11.1888 10.2271C13.3652 12.3233 14.8594 14.7914 15.6947 17.6426C15.6947 17.6651 15.7064 17.6989 15.7064 17.7215C16.1417 20.8432 15.53 23.9875 14.0476 26.8049C13.9652 26.9627 13.8947 27.1092 13.8358 27.2669H16.8005V30.0618H22.3535V25.5089L25.7417 22.2632H28.0829C29.0123 22.2632 29.7652 21.5419 29.7652 20.6516V14.6223H25.7417Z"
        fill="#FAE6D3"
      />
      <path
        d="M22.5884 30.2872H16.5648V27.5036H12.4472V30.2872H5.68248V24.9453C2.55307 23.2887 1.51778 19.1978 3.28248 15.3661C5.31778 10.9596 11.2119 7.06029 21.1413 10.5201L25.5766 8.88598C25.859 8.78455 26.1531 8.86344 26.3413 9.08884C26.5178 9.31423 26.5295 9.61851 26.3531 9.85518L24.4119 12.3796L25.859 14.3856H30.0001V20.6403C30.0001 21.6546 29.1413 22.4773 28.0825 22.4773H25.8354L22.5884 25.599V30.2872ZM17.0354 29.8364H22.1178V25.4187L25.6354 22.049H28.0707C28.8707 22.049 29.5178 21.4292 29.5178 20.6628V14.8477H25.6001L23.8237 12.3796L25.9648 9.59597C26.0472 9.49455 25.9884 9.40439 25.9648 9.37058C25.9413 9.34804 25.8707 9.26915 25.7413 9.31423L21.1413 11.0047L21.059 10.9709C15.0237 8.85217 6.84719 8.77328 3.71778 15.5464C2.02366 19.209 3.02366 23.1084 6.03542 24.6072L6.16484 24.6749V29.8364H11.9766V27.0528H17.0354V29.8364Z"
        fill="#231F20"
      />
      <path
        d="M10.0943 12.9151L9.70605 12.3516C9.84723 12.2615 13.3531 10.0639 17.8708 12.2615L17.5531 12.8588C15.8237 12.0136 14.0472 11.7656 12.2708 12.115C10.9296 12.3855 10.1061 12.9039 10.0943 12.9151Z"
        fill="#231F20"
      />
      <path d="M27.8261 29.5774H0V29.9999H27.8261V29.5774Z" fill="#231F20" />
      <path
        d="M22.9996 17.1862C23.4999 17.1862 23.9055 16.7977 23.9055 16.3185C23.9055 15.8392 23.4999 15.4507 22.9996 15.4507C22.4993 15.4507 22.0938 15.8392 22.0938 16.3185C22.0938 16.7977 22.4993 17.1862 22.9996 17.1862Z"
        fill="#231F20"
      />
      <path
        d="M13.7188 10.4693C16.4933 10.4693 18.7424 8.31483 18.7424 5.65714C18.7424 2.99945 16.4933 0.844971 13.7188 0.844971C10.9444 0.844971 8.69531 2.99945 8.69531 5.65714C8.69531 8.31483 10.9444 10.4693 13.7188 10.4693Z"
        fill="#B9E2E4"
      />
      <path
        d="M13.5196 10.5089C10.6137 10.5089 8.26079 8.24367 8.26079 5.47132C8.24902 2.6877 10.6137 0.422485 13.5196 0.422485C16.4137 0.422485 18.7784 2.6877 18.7784 5.46005C18.7784 8.24367 16.4137 10.5089 13.5196 10.5089ZM13.5196 0.873274C10.8726 0.873274 8.71961 2.93563 8.71961 5.46005C8.71961 7.99574 10.8725 10.0468 13.5078 10.0468C16.1549 10.0468 18.2961 7.98447 18.2961 5.46005C18.3078 2.93563 16.1549 0.873274 13.5196 0.873274Z"
        fill="#231F20"
      />
    </g>
    <path
      d="M14.0728 7.05421V7.3243C14.0728 7.48186 13.9297 7.60565 13.7476 7.60565C13.5655 7.60565 13.4224 7.48186 13.4224 7.3243V7.05421C12.8502 6.98668 12.4079 6.72784 12.2128 6.44649C12.1868 6.41273 12.1738 6.35646 12.1738 6.31144C12.1738 6.15389 12.3169 6.0301 12.486 6.0301C12.616 6.0301 12.6941 6.08637 12.7461 6.14264C12.9932 6.35646 13.3054 6.53652 13.7216 6.53652C14.2678 6.53652 14.528 6.37897 14.528 6.06386C14.528 5.30984 12.3559 5.65871 12.3559 4.42078C12.3559 3.97062 12.6941 3.57673 13.4224 3.49795V3.23911C13.4224 3.08156 13.5655 2.95776 13.7476 2.95776C13.9297 2.95776 14.0728 3.08156 14.0728 3.23911V3.49795C14.515 3.55422 14.8791 3.72303 15.0742 3.95937C15.1133 4.00438 15.1393 4.06065 15.1393 4.12818C15.1393 4.27448 14.9962 4.39827 14.8141 4.39827C14.7361 4.39827 14.645 4.35326 14.554 4.28573C14.3719 4.12818 14.0597 4.01564 13.7216 4.01564C13.2273 4.01564 13.0453 4.21821 13.0453 4.42078C13.0453 5.09602 15.2173 4.78091 15.2173 6.06386C15.2173 6.60405 14.8271 6.97543 14.0728 7.05421Z"
      fill="black"
    />
    <defs>
      <clipPath id="clip0">
        <rect width={size} height={size} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
