import { Spinner } from '@portal/components/helpers';
import styled from '@emotion/styled';
import React from 'react';

import { CONTAINER_SIDE_PADDING } from '@portal/components/shared/layout';
import { useMovingStorageAddOnEligibilityQuery } from '@portal/schema';
import { RouteComponentProps } from 'react-router-dom';

import { PlanUpdateForm } from '../update/form';
import { UnableToAdd } from './unable_to_add';

const Container = styled.div`
  margin: 0 ${CONTAINER_SIDE_PADDING}px;
  max-width: 750px;
`;

export const AddPlan: React.FC<RouteComponentProps<{ orderID: string }>> = (props) => {
  const {
    match: {
      params: { orderID },
    },
  } = props;

  const { data, loading } = useMovingStorageAddOnEligibilityQuery({
    variables: { orderID: orderID! },
    skip: !orderID,
    fetchPolicy: 'network-only',
  });

  if (!orderID) {
    return <UnableToAdd />;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <Container>
      {data?.order?.storageAddOnEligible ? (
        <PlanUpdateForm orderID={orderID} action="add" />
      ) : (
        <UnableToAdd orderID={orderID} />
      )}
    </Container>
  );
};
