import * as React from 'react';

import { Button, Modal } from '@shared/components/bootstrap';

import { Digest } from '@portal/components/claims/digest';

import { useDestroyClaimMutation, Status, Claim } from '@portal/schema';

export const Delete: React.FC<{
  claim: Pick<Claim, 'uuid'>;
  onClose(): void;
}> = ({ claim: { uuid }, onClose }) => {
  const [execute, { loading: destroying }] = useDestroyClaimMutation({ variables: { uuid } });
  const destroy = async () => {
    const result = await execute();
    if (result?.data?.destroyClaim?.status !== Status.Ok) {
      return;
    }
    onClose();
  };

  return (
    <Modal centered onClose={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title tag="h6">
            Remove ‘<Digest uuid={uuid} />’
          </Modal.Title>
          <Modal.Close close={onClose} disabled={destroying} />
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove ‘<Digest uuid={uuid} />
          ’?
        </Modal.Body>
        <Modal.Footer>
          <Button kind="light" disabled={destroying} onClick={onClose}>
            Cancel
          </Button>
          <Button kind="danger" loading={destroying} onClick={destroy}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
