import * as React from 'react';

interface IRenderProps<T> {
  draft: T;
  onChange(draft: T): void;
}

interface IDraftProps<T> {
  draft: T;
  children(props: IRenderProps<T>): React.ReactNode;
}

interface IDraftState<T> {
  draft: T;
}

export class Draft<T> extends React.Component<IDraftProps<T>, IDraftState<T>> {
  constructor(props: IDraftProps<T>) {
    super(props);
    this.state = { draft: props.draft };
  }

  public render() {
    const { children } = this.props;
    const { draft } = this.state;
    return children({ draft, onChange: this.onChange });
  }

  private onChange = (draft: T) => {
    this.setState({ draft });
  };
}
