import { DateTime } from 'luxon';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { OrderTypeEnum, Account__CancelIntent__Reason as AccountCancelIntentReason } from '@portal/schema';
import { IOrder } from '@portal/types';

import { Header } from '@portal/components/helpers/header';
import { orderURL } from '@portal/config/routes';
import { Spacer } from '@shared/components/helpers';

const orderTypeDisplayCopy = (type: OrderTypeEnum) => {
  switch (type) {
    case OrderTypeEnum.Pickup:
      return 'Pickup';
    case OrderTypeEnum.Return:
    case OrderTypeEnum.PickupReturn:
      return 'Delivery';
  }
};

export const Scheduled: React.FC<{
  order: IOrder;
}> = ({ order }) => {
  const date = DateTime.fromISO(order.scheduled, { setZone: true });

  return (
    <>
      <Spacer height="2rem" />
      <Header tag="h2">{date.toLocaleString(DateTime.DATE_HUGE)}</Header>
      <p>
        Your {orderTypeDisplayCopy(order.type)} Appointment
        <br />
        <>Arrival Window: {order.formattedArrivalWindow}</>
      </p>
      {order.permissions.active && order.accountCancelIntent?.reason !== AccountCancelIntentReason.PayToVacate && (
        <>
          {order.permissions.reschedulable ? (
            <>
              <Link className="btn btn-primary" to={orderURL(order.id, 'reschedule')}>
                Reschedule
              </Link>
            </>
          ) : (
            <p>Order cannot be rescheduled. Please cancel if you are no longer available.</p>
          )}
        </>
      )}
      <hr />
    </>
  );
};
