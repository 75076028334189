import styled from '@emotion/styled';
import cn from 'classnames';
import * as React from 'react';

const AlignRight = styled.div`
  display: flex;
  gap: 4px;
  justify-content: flex-end;
`;

const Title: React.FC<{ className?: string }> = ({ children, className }) => (
  <div className={cn('panel-title', className)}>{children}</div>
);
const Header: React.FC<{
  style?: React.CSSProperties;
  className?: string;
  onClick?(): void;
}> = ({ style, className, onClick, children }) => (
  <div className={cn('panel-heading', className)} style={style} onClick={onClick}>
    {children}
  </div>
);

const Body: React.FC<{ className?: string }> = ({ className, children }) => (
  <div className={cn('panel-body', className)}>{children}</div>
);

const Control: React.FC = ({ children }) => <div className="panel-control">{children}</div>;

const Footer: React.FC<{ align?: 'left' | 'right' }> = ({ align = 'left', children }) => (
  <div className="panel-footer">{align === 'right' ? <AlignRight>{children}</AlignRight> : children}</div>
);

const Panel: React.FC<{
  id?: string;
  style?: React.CSSProperties;
  className?: string;
}> = ({ id, style, className, children }) => (
  <div id={id} className={cn('panel', className)} style={style}>
    {children}
  </div>
);

const Combined = Object.assign(Panel, {
  Title,
  Header,
  Body,
  Footer,
  Control,
});

export { Combined as Panel };
