import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { Manager, Selector, Uploader } from '@shared/components/files';
import { Labelled } from './labelled';

export const Files: React.FC<{
  accept: string;
  onSave(signedID: string): void;
  onUploading?(uploading: boolean): void;
}> = ({ accept, onSave, onUploading }) => (
  <Manager
    onSave={(_, signedID) => onSave(signedID)}
    onUploading={onUploading}
    children={({ uploads }) => (
      <Labelled>
        <div className="files">
          {!!uploads.length && (
            <div className="files__uploaders">
              {uploads.map(({ uuid, file }) => (
                <Uploader key={uuid} uuid={uuid} file={file} />
              ))}
            </div>
          )}
          <div className="files__selector">
            <Selector accept={accept} />
            <FontAwesomeIcon icon="cloud" />{' '}
            <>
              To upload a file, drag and drop it here or <u>choose the file</u> from your hard drive.
            </>
          </div>
        </div>
      </Labelled>
    )}
  />
);
