import React, { useState } from 'react';

import { Message as ErrorMessage } from '@portal/components/shared/error';
import { Button } from '@portal/components/shared/customer_protected/button';
import { Selector as SourcesSelector } from '@portal/components/sources/selector';
import { Status, Billing__Prepayment, useMovingPrepaymentPayMutation } from '@portal/schema';
import { currency } from '@shared/utils/currency';
import { formatDate } from '@shared/utils/format_date';

import { Card } from '../card';
import { Totals } from '../totals';
import { Container, SubmitContainer, SourceContainer, MainTitle, Heading, Divider, CardContainer } from '../sections';
import { SuccessModal } from '../success_modal';

export const PayDepositForm: React.FC<{
  failedPrepayment: Billing__Prepayment;
}> = ({ failedPrepayment: { amount, dueAt, id: orderID } }) => {
  const [selectedSourceID, setSelectedSourceID] = useState<string>();
  const [paymentSuccessful, setPaymentSuccessful] = useState<boolean>(false);
  const [payPrepayment, { loading: resolving }] = useMovingPrepaymentPayMutation();
  const [error, setError] = useState<string>();
  const description = `Owed on ${formatDate(dueAt)}`;

  const onClick = async () => {
    const response = await payPrepayment({ variables: { orderID, sourceID: selectedSourceID! } });
    if (response?.data?.movingPrepaymentPay?.status === 'OK') {
      setError('');
      setPaymentSuccessful(true);
    } else if (response.data?.movingPrepaymentPay?.status === Status.Unprocessable) {
      setError(response.data.movingPrepaymentPay.error ?? 'An error has occurred.');
    }
  };

  return (
    <>
      <Container>
        <MainTitle size="large">Paying your deposit</MainTitle>
        <CardContainer>
          <Card name="Deposit" amount={amount} description={description} disabled={true} themeName="deposit" />
        </CardContainer>
        <Divider />
        <Heading size="small">1. Confirm Payment Method</Heading>
        <SourceContainer>
          <SourcesSelector
            useButtonStyle={true}
            showDefaultCheckbox={false}
            disabled={resolving}
            selectedSourceID={selectedSourceID}
            setSelectedSourceID={setSelectedSourceID}
          />
        </SourceContainer>
        <Divider />
        <Heading size="small">2. Review and Pay</Heading>
        <Totals delinquentBalance={amount} paymentAmount={amount} remainingBalance={0} />
        <SubmitContainer>
          {error && <ErrorMessage message={error} />}
          <Button
            kind="primary"
            disabled={!amount || !selectedSourceID || resolving}
            loading={resolving}
            fullWidth
            onClick={onClick}
            onActionBlocked={(errorMessage) => setError(errorMessage)}
          >
            Pay {currency(amount)} Now
          </Button>
        </SubmitContainer>
      </Container>
      {paymentSuccessful && <SuccessModal />}
    </>
  );
};
