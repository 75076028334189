/* @jsx jsx */
import React from 'react';

import { jsx } from '@emotion/react';

import styled from '@emotion/styled';

import { COLORS, FontWeight, mq, Text } from '@clutter/clean';

const RewardsBanner = styled(Text.Display)`
  color: ${COLORS.cloud};
  margin-top: 64px;
  ${mq({ fontSize: ['40px', null, '48px'] })}
`;

const RewardsEarned = styled(Text.Display)`
  color: ${COLORS.cloud};
  font-weight: ${FontWeight.Semibold};
  line-height: 56px;
  margin-top: 16px;
  ${mq({
    fontSize: ['48px', '48px', '56px'],
  })}
`;

const RewardsCount = styled(Text.Body)`
  color: ${COLORS.cloud};
  margin-top: 2px;
`;

export const RewardsHeader: React.FC<{
  referralsCount: number;
  creditsEarned: number;
}> = ({ referralsCount, creditsEarned }) => (
  <div className="rewards__header">
    <RewardsBanner>Clutter Rewards</RewardsBanner>
    {creditsEarned > 0 && (
      <div className="rewards__header__summary">
        <RewardsEarned>${Math.trunc(creditsEarned)}</RewardsEarned>
        <RewardsCount weight={FontWeight.Medium}>
          Earned from {referralsCount} {referralsCount > 1 ? 'referrals' : 'referral'}
        </RewardsCount>
      </div>
    )}
  </div>
);
