import { IIconProps } from '@portal/components/shared/icons/props';
import React from 'react';
import { snakeCase } from 'lodash';

export const Calendar: React.FC<IIconProps> = ({ title, viewBox, width = 68, height = 65 }) => (
  <svg
    aria-hidden={!title}
    aria-labelledby={title && `${snakeCase(title)}-svg`}
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox ?? `0 0 ${width} ${height}`}
  >
    {title && <title id={`${snakeCase(title)}-svg`}>{title}</title>}
    <path
      d="M58.373 1.167L1.158 11.252l1.46 8.284L59.834 9.45l-1.46-8.283z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.33 9.704l7.83 44.416-52.696 9.294-7.83-44.417"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.648 18.12L10.06 22.103l.531 3.01 22.587-3.981-.53-3.01z"
      fill="#00A499"
      stroke="#00A499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.416 20.915L11.25 28.877l5.307 30.108 45.167-7.962-5.308-30.108zM12.582 36.4l45.167-7.962M13.913 43.93l45.167-7.963M15.236 51.458l45.167-7.962M24.088 57.657l-5.308-30.11M31.618 56.333l-5.309-30.116M39.147 55.002l-5.308-30.109M46.676 53.68l-5.308-30.117M54.205 52.348L48.897 22.24"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.134 18.734l-1.454-8.28M8.86 63.677l-4.736-44.41M7.359 63.948L2.623 19.53M2.623 19.531L58.33 9.704"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.324 9.713l-55.706 9.82.266 1.509 55.706-9.82-.266-1.509z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.04 34.907c-2.057-2.097-4.998-2.322-7.675-2.16-5.773.341-11.677 2.43-15.639 7.19-1.532 1.841.975 4.573 2.515 2.73a15.38 15.38 0 013.536-3.102 20.972 20.972 0 00-3.567 4.28c-.727 1.16-1.285 2.444-1.16 3.884.139 1.61 1.222 2.91 2.537 3.575 2.368 1.183 5.138.402 7.545-.24 4.798-1.277 9.65-3.544 12.528-8.11 1.648-2.623 1.563-5.811-.62-8.047zm-1.856 4.99c-.58 1.688-2.182 3.181-3.513 4.148-1.517 1.1-3.188 1.896-4.914 2.507-1.764.62-3.652 1.208-5.494 1.447-.689.085-1.524.163-2.167-.178-.944-.502-.255-1.33.186-2.027 2.422-3.846 6.291-6.384 10.462-7.29 1.014-.216 1.37-1.09 1.254-1.95.363.016.735.032 1.09.07 1.432.186 3.785 1.254 3.096 3.274z"
      fill="#F79C94"
    />
    <path
      d="M5.633 18.997l7.738 43.89-3.002.527-4.736-44.417z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
