import { DateTime } from 'luxon';

import {
  Billing__Charge__Status,
  Billing__ChargeFragment as ChargeFragment,
  Billing__NestedInvoiceFragment,
  Billing__OfflinePaymentFragment as OfflinePaymentFragment,
  Billing__RefundFragment as RefundFragment,
  Maybe,
} from '@portal/schema';

export enum TransactionKind {
  Charge,
  OfflinePayment,
  Refund,
}

export enum TransactionStatus {
  Successful,
  Failed,
}

export interface ITransaction {
  kind: TransactionKind;
  status: TransactionStatus;
  date: DateTime;
  amount: number;
  summary: string;
  sourceBrand?: Maybe<string>;
}

export const buildTransactions = (
  charges: ChargeFragment[],
  offlinePayments: OfflinePaymentFragment[],
  refunds: RefundFragment[],
  lumpSumInvoice?: Billing__NestedInvoiceFragment | null,
): ITransaction[] => {
  const transactions: ITransaction[] = [];
  charges.forEach((charge) => {
    transactions.push({
      kind: TransactionKind.Charge,
      status:
        charge.status === Billing__Charge__Status.Succeeded ? TransactionStatus.Successful : TransactionStatus.Failed,
      date: DateTime.fromISO(charge.date, { setZone: true }),
      amount: charge.amount,
      summary: `Ending in ${charge.source.number}`,
      sourceBrand: charge.source.brand,
    });
  });
  offlinePayments.forEach((payment) => {
    transactions.push({
      kind: TransactionKind.OfflinePayment,
      status: TransactionStatus.Successful,
      date: DateTime.fromISO(payment.date, { setZone: true }),
      summary: payment.summary || payment.kind,
      amount: payment.amount,
    });
  });
  refunds.forEach((refund) => {
    transactions.push({
      kind: TransactionKind.Refund,
      status: TransactionStatus.Successful,
      date: DateTime.fromISO(refund.date, { setZone: true }),
      summary: `Ending in ${refund.source.number}`,
      amount: refund.amount,
      sourceBrand: refund.source.brand,
    });
  });
  if (lumpSumInvoice) {
    transactions.push({
      kind: TransactionKind.Charge,
      status: TransactionStatus.Successful,
      date: DateTime.fromISO(lumpSumInvoice.paidAt!, { setZone: true }),
      amount: lumpSumInvoice.total,
      summary: `Combined Invoice #${lumpSumInvoice.id}`,
    });
  }
  return transactions.sort((a, b) => a.date.diff(b.date).milliseconds);
};
