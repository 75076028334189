import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import {
  Address,
  Financing__FailureKind,
  useFinancingCheckoutQuery,
  useSubmitFinancingCheckoutMutation,
} from '@portal/schema';
import { orderURL } from '@portal/config/routes';
import { useAffirmCheckout } from '@shared/hooks/use_affirm_checkout';
import { UUID } from '@shared/utils';
import { useActionCableSubscription } from '@shared/hooks/use_action_cable_subscription';
import { useSnackbarContext } from '@clutter/clean';
import { Failed } from './checkout/failed';
import { Succeeded } from './checkout/succeeded';
import { Selector as AddressSelector } from './checkout/address/selector';
import { Builder as AddressBuilder } from './checkout/address/builder';

enum Step {
  AddressSelectorStep,
  AddressBuilderStep,
  FailedStep,
  SucceededStep,
}

const DEFAULT_STEP = Step.AddressSelectorStep;
const DEFAULT_FAILURE_KIND = Financing__FailureKind.Retriable;

const CHANNEL = 'Financing::CheckoutChannel';

type AddressFragment = Pick<Address, 'aptsuite' | 'street' | 'city' | 'state' | 'zip' | 'country'>;

const SNACKBAR_KEY = 'FINANCING_CHECKOUT';
const SNACKBAR_PAID_CONTENT = 'There are no outstanding bills on your appointment.';

export const Checkout: React.FC = () => {
  const { addSnack } = useSnackbarContext();
  const { orderID } = useParams<{ orderID: string }>();
  const history = useHistory();
  const location = useLocation<{ step?: Step; failureKind: Financing__FailureKind }>();
  const [processing, setProcessing] = useState(false);
  const [uuid] = useState(UUID);
  const [save] = useSubmitFinancingCheckoutMutation();
  const { data, refetch } = useFinancingCheckoutQuery({ variables: { orderID } });
  const checkout = useAffirmCheckout();

  useEffect(() => {
    if (data?.order.financingContext.total === 0) {
      addSnack({
        key: SNACKBAR_KEY,
        content: SNACKBAR_PAID_CONTENT,
      });
      history.replace(orderURL(orderID));
    }
  }, [data]);

  const [channel] = useState({ channel: CHANNEL, uuid: uuid });
  useActionCableSubscription<{ status: 'failed' | 'processed' }>(channel, ({ status }) => {
    switch (status) {
      case 'failed':
        history.push(location.pathname, { step: Step.FailedStep, failureKind: Financing__FailureKind.Retriable });
        break;
      case 'processed':
        history.push(location.pathname, { step: Step.SucceededStep });
        break;
    }
    setProcessing(false);
  });

  const step = location.state?.step ?? DEFAULT_STEP;
  const failureKind = location.state?.failureKind ?? DEFAULT_FAILURE_KIND;

  if (!data) return null;

  const buildAffirmCheckout = async (address: AddressFragment) => {
    try {
      return await checkout({
        orderID,
        customer: data.customer!,
        address,
        total: data.order.financingContext.total,
        taxAmount: data.order.financingContext.taxAmount,
        items: data.order.financingContext.items.map((item) => ({
          display_name: item.displayName,
          sku: item.sku,
          unit_price: item.unitPrice,
          qty: item.quantity,
          item_image_url: item.imageURL,
          item_url: item.url,
        })),
        discounts: data.order.financingContext.discounts.reduce<Record<string, AffirmDiscount>>(
          (discounts, discount) => {
            discounts[discount.code] = {
              discount_amount: discount.amount,
              discount_display_name: discount.displayName,
            };
            return discounts;
          },
          {},
        ),
      });
    } catch (_error) {
      setProcessing(false);
    }
  };

  const submitFinancingCheckout = async (affirmID: string) => {
    try {
      return await save({
        variables: {
          input: {
            affirmID,
            orderID,
            uuid,
            total: data.order.financingContext.total,
            taxAmount: data.order.financingContext.taxAmount,
          },
        },
      });
    } catch (_error) {
      setProcessing(false);
    }
  };

  const submit = async (address: AddressFragment) => {
    if (processing) return;
    setProcessing(true);

    const affirm = await buildAffirmCheckout(address);
    if (!affirm) return;

    const response = await submitFinancingCheckout(affirm.checkout_token);
    if (!response) return;

    if (response.data?.submitFinancingCheckout?.failureKind) {
      setProcessing(false);
      history.push(location.pathname, {
        step: Step.FailedStep,
        failureKind: response.data.submitFinancingCheckout.failureKind,
      });
    }
  };

  return (
    <>
      {step === Step.AddressSelectorStep && (
        <AddressSelector
          loading={processing}
          onContinue={(address) => {
            if (address === null) {
              history.push(location.pathname, { step: Step.AddressBuilderStep });
            } else {
              submit(address);
            }
          }}
          addresses={data?.addresses}
        />
      )}

      {step === Step.AddressBuilderStep && (
        <AddressBuilder
          loading={processing}
          onContinue={(address) => {
            submit(address);
          }}
          onBack={() => {
            history.goBack();
          }}
        />
      )}

      {step === Step.FailedStep && (
        <Failed
          kind={failureKind}
          loading={processing}
          onRetry={() => {
            refetch({ orderID });
            history.push(location.pathname, { step: Step.AddressSelectorStep });
          }}
        />
      )}

      {step === Step.SucceededStep && <Succeeded />}
    </>
  );
};
