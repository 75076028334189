import React, { useEffect } from 'react';

import styled from '@emotion/styled';
import { COLORS } from '@clutter/clean';
import { TealBox } from '@portal/components/shared/icons';
import { wt } from '@portal/initializers/wt';

const Container = styled.div`
  border-radius: 4px;
  border: 1px solid ${COLORS.cloud};
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  align-items: center;
  background: ${COLORS.tealBackground};
  margin-bottom: 0.5rem;
`;

const ArtContainer = styled.div`
  margin-right: 24px;
`;

export const ShippingAvailableNotice: React.FC = () => {
  const label = 'Clutter now offers shipping on certain items. Choose "shipping eligible" items to see your options.';

  useEffect(() => {
    wt.track({
      pageName: 'portal:new_appointment',
      container: 'item_select_screen',
      action: 'display',
      label: label,
      objectName: 'shipping_available_notice',
      objectType: 'banner',
    });
  }, []);

  return (
    <Container>
      <ArtContainer>
        <TealBox />
      </ArtContainer>
      <div>{label}</div>
    </Container>
  );
};
