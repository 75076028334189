import mapbox from 'mapbox-gl';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useMap } from './context';

interface IMarker {
  draggable: boolean;
  latitude?: number | string | null;
  longitude?: number | string | null;
  customMarker?: React.ReactNode;
  onChange?: (lngLat: mapbox.LngLat) => void;
}

const DEFAULT_LATITUDE = 37.7577;
const DEFAULT_LONGITUDE = -122.4376;

export const Marker: React.FC<IMarker> = ({
  draggable,
  latitude = DEFAULT_LATITUDE,
  longitude = DEFAULT_LONGITUDE,
  customMarker,
  onChange,
}) => {
  const map = useMap();
  const [marker, setMarker] = useState<mapbox.Marker | null>(null);

  useEffect(() => {
    const element = customMarker ? document.createElement('div') : undefined;

    const initMarker = new mapbox.Marker({
      draggable,
      element,
    })
      .setLngLat([Number(longitude), Number(latitude)])
      .addTo(map);

    setMarker(initMarker);

    return () => {
      initMarker.remove();
      setMarker(null);
    };
  }, []);

  useEffect(() => {
    if (marker) {
      const onDragEnd = () => {
        const coordinates = marker.getLngLat();

        if (onChange) {
          onChange(coordinates);
        }
      };

      marker.on('dragend', onDragEnd);

      return () => {
        marker.off('dragend', onDragEnd);
      };
    }
  }, [marker, onChange]);

  return marker && customMarker ? createPortal(customMarker, marker.getElement()) : null;
};
