import { COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import * as React from 'react';

import { ItemPricingFragment } from '@portal/schema';
import { currency, WHOLE_NUMBER_OPTIONS } from '@shared/utils';

const PricingRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${COLORS.grayBorder};
  padding: 12px 0;
`;

const PricingRowTotal = styled(PricingRow)`
  font-weight: bold;
`;

const Price = styled.div`
  font-weight: bold;
`;

export const PerItemPricingCosts = ({ pricingInfo }: { pricingInfo?: ItemPricingFragment }) => {
  if (!pricingInfo) {
    return null;
  }

  const {
    appointmentFee,
    pickupPerItemFee,
    returnPerItemFee,
    pickupLargeItemFee,
    returnLargeItemFee,
    pickupEstimate,
    returnedItemsCount,
    pickupLargeEstimate,
    returnedLargeItemsCount,
    price,
    accountPackages,
  } = pricingInfo;

  const { lowestEstimatedItems, highestEstimatedItems } = pickupEstimate || {};
  const isRange = !!lowestEstimatedItems && !!highestEstimatedItems && lowestEstimatedItems < highestEstimatedItems;

  const { lowestEstimatedItems: largeLowestEstimatedItems, highestEstimatedItems: largeHighestEstimatedItems } =
    pickupLargeEstimate || {};
  const largeIsRange =
    !!largeLowestEstimatedItems &&
    !!largeHighestEstimatedItems &&
    largeLowestEstimatedItems < largeHighestEstimatedItems;

  return (
    <>
      <PricingRow>
        <div>Service Charge</div>
        <Price>{appointmentFee}</Price>
      </PricingRow>
      {accountPackages.map((accountPackage) => (
        <PricingRow key={accountPackage.id}>
          <div>{accountPackage.name} Charge</div>
          <Price>{currency(accountPackage.amount, WHOLE_NUMBER_OPTIONS)}</Price>
        </PricingRow>
      ))}
      {!!lowestEstimatedItems && !!highestEstimatedItems && pickupPerItemFee && (
        <PricingRow>
          <div>Pickup Item Rate (requiring 1 mover)</div>
          <Price>{pickupPerItemFee}/item</Price>
        </PricingRow>
      )}
      {!!lowestEstimatedItems && !!highestEstimatedItems && (
        <PricingRow>
          <div>Estimated Pickup Items (requiring 1 mover)</div>
          <Price>
            {lowestEstimatedItems} {isRange && `- ${highestEstimatedItems}`}
          </Price>
        </PricingRow>
      )}
      {!!largeLowestEstimatedItems && !!largeHighestEstimatedItems && pickupLargeItemFee && (
        <PricingRow>
          <div>Pickup Item Rate (requiring 2+ movers)</div>
          <Price>{pickupLargeItemFee}/item</Price>
        </PricingRow>
      )}
      {!!largeLowestEstimatedItems && !!largeHighestEstimatedItems && (
        <PricingRow>
          <div>Estimated Pickup Items (requiring 2+ movers)</div>
          <Price>
            {largeLowestEstimatedItems} {largeIsRange && `- ${largeHighestEstimatedItems}`}
          </Price>
        </PricingRow>
      )}
      {!!returnedItemsCount && returnPerItemFee && (
        <PricingRow>
          <div>Return Item Rate (requiring 1 mover)</div>
          <Price>{returnPerItemFee}/item</Price>
        </PricingRow>
      )}
      {!!returnedItemsCount && (
        <PricingRow>
          <div>Return Items (requiring 1 mover)</div>
          <Price>{returnedItemsCount}</Price>
        </PricingRow>
      )}
      {!!returnedLargeItemsCount && returnLargeItemFee && (
        <PricingRow>
          <div>Return Item Rate (requiring 2+ movers)</div>
          <Price>{returnLargeItemFee}/item</Price>
        </PricingRow>
      )}
      {!!returnedLargeItemsCount && (
        <PricingRow>
          <div>Return Items (requiring 2+ movers)</div>
          <Price>{returnedLargeItemsCount}</Price>
        </PricingRow>
      )}
      <PricingRowTotal>
        <div>Estimated Total</div>
        <Price>{price}</Price>
      </PricingRowTotal>
    </>
  );
};
