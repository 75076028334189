import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { Text } from '@shared/components/bootstrap';

export const Spinner: React.FC = () => (
  <Text alignment="center" style="muted" tag="div">
    <FontAwesomeIcon spin icon="spinner" />
  </Text>
);
