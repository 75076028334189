import React from 'react';

export const NoEntry: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <circle cx="8.00023" cy="8" r="7" transform="rotate(-45 8.00023 8)" stroke="white" strokeWidth="2" />
      <rect x="2.34326" y="12.2426" width="14.6667" height="2" transform="rotate(-45 2.34326 12.2426)" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
