import * as React from 'react';

interface IClickableProps {
  children: React.ReactNode;
  onClick(): void;
}

export const Clickable = ({ children, onClick }: IClickableProps) => (
  <button
    className="clickable"
    type="button"
    onClick={(event) => {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    }}
  >
    {children}
  </button>
);
