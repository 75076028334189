import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

import { Checklist, Spinner } from '@portal/components/helpers';
import { Rating, Review__Reviewable, Status, useBuildReviewMutation, useReviewReasonsQuery } from '@portal/schema';
import { Heading } from '@portal/components/shared/text';
import { Button, ControlLabel, FormGroup, Text } from '@shared/components/bootstrap';

import { Success } from './success';

import { ThumbsDown, ThumbsUp } from './thumbs';

const Title = styled(Heading)`
  margin: 32px 0;
  text-align: center;
`;

const DEFAULT_SCALE = 2;

export const ReviewForm: React.FC<{
  title: string;
  reviewable: Review__Reviewable;
  selectedRating?: Rating;
  scale?: number;
  kind?: string;
  schedulingID?: string;
  setSelectedRating(rating: Rating): void;
  onSubmission?(): void;
}> = ({
  title,
  reviewable,
  selectedRating,
  setSelectedRating,
  onSubmission,
  scale = DEFAULT_SCALE,
  kind,
  schedulingID,
}) => {
  const [selectedReasons, setSelectedReasons] = useState<{ [reason: string]: boolean }>({});
  const [comments, setComments] = useState<string>('');
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const { data, loading } = useReviewReasonsQuery({
    variables: { reviewableType: reviewable.type },
  });
  const reasons = data?.reviewReasons;

  useEffect(() => {
    if (reasons && selectedRating) {
      const refreshSelections: { [reason: string]: boolean } = {};
      if (selectedRating === Rating.Good) {
        reasons.good.forEach((reason) => (refreshSelections[reason] = false));
      } else {
        reasons.poor.forEach((reason) => (refreshSelections[reason] = false));
      }
      setSelectedReasons(refreshSelections);
    }
  }, [reasons, selectedRating]);

  const selectRating = (selected: Rating) => {
    if (selected === selectedRating) {
      return;
    }
    setSelectedRating(selected);
  };

  const [buildReview, { loading: building }] = useBuildReviewMutation();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const response = await buildReview({
      variables: {
        input: {
          reviewable,
          comments,
          rating: selectedRating!,
          reasons: JSON.stringify(selectedReasons),
          kind,
          schedulingID,
        },
      },
    });
    if (response && response.data) {
      if (response.data.buildReview?.status === Status.Unprocessable) {
        setFormError(`There was a problem submitting your review: ${response.data.buildReview.error}`);
      } else if (response.data.buildReview?.status === Status.Ok) {
        setFormError(undefined);
        if (onSubmission) {
          onSubmission();
        } else {
          setSubmitted(true);
        }
      }
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (submitted) {
    return <Success />;
  }

  return (
    <>
      <Title level={1} size="medium">
        {title}
      </Title>
      <form onSubmit={onSubmit}>
        <div className="row mb-3">
          <div className="col-6 col-md-6 text-right">
            <div onClick={() => selectRating(Rating.Good)} className="d-inline-block">
              <ThumbsUp scale={scale} selected={selectedRating === Rating.Good} />
            </div>
          </div>
          <div className="col-6 col-md-6 text-left">
            <div onClick={() => selectRating(Rating.Poor)} className="d-inline-block">
              <ThumbsDown scale={scale} selected={selectedRating === Rating.Poor} />
            </div>
          </div>
        </div>
        {!!selectedRating && (
          <>
            <Text tag="p" weight="bold">
              {selectedRating === Rating.Good ? 'What went well?' : 'What can we improve?'}
            </Text>
            <Checklist>
              {Object.keys(selectedReasons).map((reason, key) => {
                const checked = selectedReasons[reason];
                return (
                  <Checklist.Entry
                    key={key}
                    format="check"
                    checked={checked}
                    onChange={() => setSelectedReasons({ ...selectedReasons, [reason]: !checked })}
                  >
                    {reason}
                  </Checklist.Entry>
                );
              })}
            </Checklist>
            <FormGroup>
              <ControlLabel>Additional comments? (optional)</ControlLabel>
              <textarea
                style={{ resize: 'vertical' }}
                name="comments"
                value={comments}
                className="form-control"
                onChange={(e) => setComments(e.currentTarget.value)}
              />
            </FormGroup>
            {formError && (
              <Text tag="p" style="danger" weight="bold" alignment="center">
                {formError}
              </Text>
            )}
            <Button block type="submit" kind="primary" loading={building}>
              Submit
            </Button>
          </>
        )}
      </form>
    </>
  );
};
