import { ActiveStorageAttachment, CustomerTicket__ContactPreference, CustomerTicket__Priority } from '@admin/schema';
import { ICustomerInteraction, ITicketGroup, IUser, IZendeskTicketAssociation } from '@admin/types';

export enum CustomerTicketPriority {
  Extreme = 'EXTREME',
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
}

export const CUSTOMER_TICKET_PRIORITIES: Array<{ value: CustomerTicket__Priority; name: string; details: string }> = [
  {
    value: CustomerTicket__Priority.Extreme,
    name: 'P0',
    details: 'Requires immediate attention',
  },
  {
    value: CustomerTicket__Priority.High,
    name: 'P1',
    details: 'Requires attention within the hour',
  },
  {
    value: CustomerTicket__Priority.Medium,
    name: 'P2',
    details: 'Requires attention within 4 hours',
  },
  {
    value: CustomerTicket__Priority.Low,
    name: 'P3',
    details: 'Can be addressed next day',
  },
];

export enum CustomerTicketState {
  Open = 'open',
  Resolved = 'resolved',
}

export interface ICustomerTicketable {
  id: string;
  name: string;
}

export interface ICustomerTicket {
  id: string;
  customerTicketableID: string;
  customerTicketableType: string;
  assignee?: IUser;
  creator?: IUser;
  group?: ITicketGroup;
  category: string;
  subCategory?: string;
  description?: string;
  state: CustomerTicketState;
  createdAt: string;
  actionRequiredAt?: string;
  customerUpdatedAt?: string;
  priority: CustomerTicket__Priority;
  interactions: ICustomerInteraction[];
  zendeskTicketAssociations: IZendeskTicketAssociation[];
  files?: ActiveStorageAttachment[];
  contactPreference?: CustomerTicket__ContactPreference[];
}
