import * as React from 'react';

import { Checkbox } from '@clutter/clean';
import { FieldFormGroup } from './field_form_group';

type Option<T> = {
  label: string | React.ReactElement<T>;
  value: T;
  key: T;
};

type CheckboxProps<T = any> = {
  options: Array<Option<T>>;
  onChange(name: string, value: { [key: string]: boolean }): void;
  label: React.ReactNode;
  name: string;
  error?: string;
  values?: { [key: string]: boolean };
};

export const CheckboxFormGroup: React.FC<
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value' | 'name'> & CheckboxProps
> = ({ label, error, id, ...props }) => (
  <FieldFormGroup label={label} error={error} id={id}>
    <Checkbox.Selector {...props} />
  </FieldFormGroup>
);
