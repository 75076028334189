import { COLORS, Column, Grid, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { sumBy } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';

import { SelfStorage__Subscription__Billing, SelfStorage__UpcomingSubscriptionFragment } from '@portal/schema';

import { CostTable } from '@portal/components/shared/cost_table';
import { CreditCard } from '@portal/components/shared/icons/credit_card';
import { Label, LabelKind } from '@portal/components/shared/label';
import { getOrdinalSuffix } from '@shared/utils/get_ordinal_suffix';

import { RentalCostRows } from './rental_cost_rows';
import { Totals } from '../totals';

const Body = styled(Text.Body)`
  color: ${COLORS.storm};
`;

const BillingDetails = styled(Grid)`
  margin-top: 16px;

  div:last-child {
    text-align: right;
  }
`;

const DetailRow = styled(Column)`
  margin-bottom: 16px;
`;

const Container = styled.div`
  margin-top: 64px;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const Subscription: React.FC<{
  subscription: SelfStorage__UpcomingSubscriptionFragment;
}> = ({ subscription }) => {
  const { billing, billsOn, source, rentals } = subscription;
  const dayOfMonth = billsOn ? DateTime.fromISO(billsOn).day : undefined;
  const isChargedAutomatically = billing === SelfStorage__Subscription__Billing.ChargeAutomatically;
  const labelKind = isChargedAutomatically ? LabelKind.Primary : LabelKind.Danger;
  const labelText = isChargedAutomatically ? 'ON' : 'OFF';

  let totalCost = 0;
  rentals.forEach(({ subscriptionItems }) => {
    const subscriptionItemsTotal = subscriptionItems.reduce((sum, { rate }) => sum + rate, 0);
    totalCost += subscriptionItemsTotal;
  });

  const totalDiscount = sumBy(rentals, ({ discount }) => discount);

  return (
    <Container>
      <Body>
        {dayOfMonth ? `Due on the ${dayOfMonth}${getOrdinalSuffix(dayOfMonth)} of each month` : `Due every month`}.
      </Body>
      <BillingDetails wrap>
        <DetailRow widths={[5, 4, 4]}>
          <Label kind={labelKind}>AUTOPAY: {labelText}</Label>
        </DetailRow>
        <DetailRow widths={[7, 8, 8]}>
          {source && isChargedAutomatically && (
            <span>
              <CreditCard brand={source.brand} /> Ending in {source.number}
            </span>
          )}
        </DetailRow>
      </BillingDetails>
      <CostTable removeBottomBorder removeTopBorder>
        <tbody>
          {rentals.map((rental) => (
            <RentalCostRows key={rental.id} rental={rental} />
          ))}
        </tbody>
      </CostTable>
      <Totals discount={totalDiscount} totalCost={totalCost} tax={0} />
    </Container>
  );
};
