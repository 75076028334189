import { OrderServiceTypeEnum } from '@admin/schema';

export const DISPLAYED_SERVICE_TYPE_MAP: Record<OrderServiceTypeEnum, string> = {
  [OrderServiceTypeEnum.Capsule]: 'Capsule',
  [OrderServiceTypeEnum.CurbsidePickup]: 'Pickup Only',
  [OrderServiceTypeEnum.Disposal]: 'Disposal',
  [OrderServiceTypeEnum.Doorstep]: 'Doorstep',
  [OrderServiceTypeEnum.DropOff]: 'Drop-off',
  [OrderServiceTypeEnum.FullService]: 'Full Service',
  [OrderServiceTypeEnum.Shipment]: 'Shipment',
  [OrderServiceTypeEnum.ThirdPartyReturn]: 'Third-party Return',
  [OrderServiceTypeEnum.Auction]: 'Auction',
  [OrderServiceTypeEnum.Facility]: 'Facility',
  [OrderServiceTypeEnum.LongDistance]: 'Long-Distance',
};
