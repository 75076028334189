import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import * as React from 'react';

import { Labelled } from './labelled';

const ICONS: {
  [format in 'check' | 'radio']: {
    [checked in 'checked' | 'unchecked']: ['far', 'check-square' | 'square' | 'dot-circle' | 'circle'];
  };
} = {
  check: {
    checked: ['far', 'check-square'],
    unchecked: ['far', 'square'],
  },
  radio: {
    checked: ['far', 'dot-circle'],
    unchecked: ['far', 'circle'],
  },
};

const ChecklistAction: React.FC = ({ children }) => <div className="checklist__action">{children}</div>;

const ChecklistEntry: React.FC<{
  checked: boolean;
  disabled?: boolean;
  format?: 'check' | 'radio';
  style?: React.CSSProperties;
  onChange(checked: boolean): void;
}> = ({ children, disabled, checked, format, style, onChange }) => (
  <Labelled>
    <div
      className={cn(
        'checklist__entry',
        checked ? 'checklist__entry--checked' : 'checklist__entry--unchecked',
        disabled && 'checklist__entry--disabled',
      )}
      style={style}
    >
      <input
        className="custom-control-input"
        disabled={disabled}
        type="checkbox"
        checked={checked}
        onChange={(event) => onChange(event.target.checked)}
      />
      {format && (
        <div className="checklist__checkmark">
          <FontAwesomeIcon icon={disabled ? 'ban' : ICONS[format][checked ? 'checked' : 'unchecked']} />
        </div>
      )}
      <div className="checklist__content">{children}</div>
    </div>
  </Labelled>
);

const Checklist: React.FC = ({ children }) => <div className="checklist">{children}</div>;

const Combined = Object.assign(Checklist, {
  Action: ChecklistAction,
  Entry: ChecklistEntry,
});
export { Combined as Checklist };
