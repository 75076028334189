import { COLORS, List } from '@clutter/clean';
import styled from '@emotion/styled';

export const BorderlessList = styled(List)<{ verticalPadding: number }>`
  border: none;
  border-bottom: 1.5px solid ${COLORS.grayBorder};
  padding: 0;
  & > div {
    padding: ${({ verticalPadding }) => verticalPadding}px 0;
  }
`;
