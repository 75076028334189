/* eslint-disable no-underscore-dangle */
import styled from '@emotion/styled';
import React from 'react';

import { Text, mq } from '@clutter/clean';
import { orderDisplayName } from '@portal/utils/order';

import { Order } from '@portal/components/orders/index';
import { OrderStatusEnum, OrderTypeEnum } from '@portal/schema';
import { Card } from './card';

const Container = styled.div`
  text-align: center;
`;

const Title = styled(Text.Title)`
  ${mq({
    marginTop: [0, '90px'],
  })}
`;

const Cards = styled.div`
  text-align: left;
  padding: 24px 0;
  margin: 0 auto;
  ${mq({
    width: [null, '600px'],
  })}
  & > div {
    margin-bottom: 12px;
  }
`;

const title = (orders: Order[]) => {
  const pluralize = orders.length > 1;
  const s = pluralize ? 's' : '';

  let appointmentText = 'appointment';

  if (orders.length === 1 && orders[0].services.length <= 1) {
    const orderNameStr = orderDisplayName(orders[0]);
    if (orderNameStr) {
      appointmentText = orderNameStr;
    }
  }

  if (orders.length === 1 && orders[0].type === OrderTypeEnum.Disposal) {
    return `Your Disposal is ${
      orders[0].status === OrderStatusEnum.Pending ? 'Awaiting Your Signature' : 'Being Processed'
    }`;
  }

  const onWaitlist = orders.some(
    ({ status, waitlistRequests }) => status === OrderStatusEnum.Pending && waitlistRequests.length,
  );

  if (onWaitlist) {
    return `On the Waitlist for your ${appointmentText}`;
  }

  return `Your ${appointmentText}${s} ${pluralize ? 'are' : 'is'} coming up.`;
};

interface IActiveOrdersProps {
  editable: boolean;
  orders: Order[];
}
export const ActiveOrders = ({ orders, editable }: IActiveOrdersProps) => (
  <Container>
    <Title size="large">{title(orders)}</Title>
    <Cards>
      {orders.map((order) => (
        <Card key={order.id} order={order} editable={editable} />
      ))}
    </Cards>
  </Container>
);
