import React from 'react';
import { Switch } from 'react-router-dom';
import { Theme } from '@portal/components/helpers/theme';
import { RouteWithTitle } from '@portal/components/shared/route_with_title';
import { AddPlan } from '@portal/components/plan/add';
import { planAddURL, planUpdateURL } from '@portal/config/routes';
import { UpdatePlan } from './plan/update';

export const Plan: React.FC = () => (
  <Theme name="default">
    <Switch>
      <RouteWithTitle exact path={planUpdateURL()} component={UpdatePlan} title="Update Plan" />
      <RouteWithTitle exact path={planAddURL(':orderID')} component={AddPlan} title="Add Plan" />
    </Switch>
  </Theme>
);
