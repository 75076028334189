import * as React from 'react';
import { Link } from 'react-router-dom';

import { claimURL, DEFAULT_CLAIM_ACTION } from '@portal/config/routes';
import { DetailedClaimFragment } from '@portal/schema';

import { Text } from '@shared/components/bootstrap';

import { Step } from '@portal/components/claims/step';

interface IEmptyProps {
  claim: DetailedClaimFragment;
}

export const Empty = ({ claim }: IEmptyProps) => (
  <Text tag="div" alignment="center">
    <Step.Title>This claim is empty and cannot be submitted.</Step.Title>
    <Link className="btn btn-light" to={claimURL(claim.uuid, DEFAULT_CLAIM_ACTION)}>
      Restart
    </Link>
  </Text>
);
