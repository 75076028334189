import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { useTrackClaimMutation, Status } from '@portal/schema';

interface ITrackerProps {
  uuid: string;
  children(props: { track(): Promise<Status> }): React.ReactElement;
}

const TrackerWithoutRouter = ({ match, uuid, children }: ITrackerProps & RouteComponentProps) => {
  const [execute] = useTrackClaimMutation();

  return children({
    track: async () => {
      const result = await execute({ variables: { uuid, step: match.url } });
      if (!result || !result.data) {
        return Status.Unprocessable;
      }
      return Status.Ok;
    },
  });
};

export const Tracker = withRouter(TrackerWithoutRouter);
