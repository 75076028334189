import { DateTime } from 'luxon';
import styled from '@emotion/styled';
import { COLORS, FontWeight, Text } from '@clutter/clean';
import React from 'react';

import {
  PaymentPlanDetail__OfferFragment as PaymentPlanOffer,
  PaymentPlan__Offer__Type as OfferType,
} from '@portal/schema';

import { displayableSource } from '@portal/components/helpers';
import { List as InvoicesList } from '@portal/components/invoices/list';
import { Currency, Pluralize } from '@shared/components/helpers';
import { formatDate } from '@shared/utils/format_date';
import { getOrdinalSuffix } from '@shared/utils/get_ordinal_suffix';

const Title = styled(Text.Title)`
  color: ${COLORS.tealPrimary};
  margin-bottom: 24px;
`;

const SubTitle = styled(Text.Title)`
  margin: 40px 0 16px 0;
`;

export const Accepted: React.FC<{ offer: PaymentPlanOffer }> = ({ offer }) => {
  const {
    account,
    paymentMethod,
    settledInvoices,
    acceptedAt,
    oneTimeFee,
    storageTermEndDate,
    monthlyFee,
    monthlyFeeTerm,
    type,
  } = offer;
  const monthlyFeesExist = !!monthlyFee && !!monthlyFeeTerm;
  const dayOfMonth = DateTime.fromISO(acceptedAt!).day;
  return (
    <>
      <Title size="large">Congrats {account.customer.name}.</Title>
      <Text.Body>
        Your payment was processed, and your{' '}
        {type === OfferType.AutopayOptIn ? 'storage account is on autopay' : 'account is now up to date'}.
      </Text.Body>

      <SubTitle size="extraSmall">Payment Details</SubTitle>
      <Text.Body>
        <b>Amount paid:</b> <Currency value={oneTimeFee || 0} />
        <br />
        <b>Payment method:</b> {paymentMethod && displayableSource(paymentMethod)}
        <br />
        <b>Date:</b> {formatDate(acceptedAt!)}
      </Text.Body>

      <SubTitle size="extraSmall">Forgiven Invoices</SubTitle>
      <InvoicesList invoices={settledInvoices} />

      {type !== OfferType.AutopayOptIn && (
        <>
          <SubTitle size="extraSmall">Future Invoices</SubTitle>
          <Text.Callout>
            This payment plan only applies to the invoices listed above. All future payments will be auto-billed on the
            due date at the full rate.
            <br />
            <br />
            Failure to pay any future invoices or any other owed invoices will result in your items being auction
            eligible.
          </Text.Callout>
        </>
      )}
      {type === OfferType.AutopayOptIn && (
        <>
          <SubTitle size="extraSmall">Autopay Details</SubTitle>
          <Text.Callout>
            The {paymentMethod && displayableSource(paymentMethod)} will be automatically charged each month for all
            monthly storage fees associated with the units on your Clutter account.
            <br />
            <br />
            Your monthly bill will be charged automatically on your unit's bill date. If this automatic charge fails,
            the card will be automatically re-attempted until payment is successful.
            <br />
            <br />
            The default card used for autopay can always be updated by contacting our Customer Care team.
          </Text.Callout>
        </>
      )}
      {(monthlyFeesExist || !!storageTermEndDate) && (
        <>
          <SubTitle size="extraSmall">Terms</SubTitle>
          {monthlyFeesExist && (
            <>
              <Text.Body weight={FontWeight.Medium}>Monthly Payments</Text.Body>
              <br />
              <Text.Callout>
                <Currency value={monthlyFee!} /> will be due on the {dayOfMonth}
                {getOrdinalSuffix(dayOfMonth)} of every month for the next{' '}
                <Pluralize count={monthlyFeeTerm! - 1} singular="month" plural="months" />.{' '}
                {type !== OfferType.AutopayOptIn && (
                  <>Failure to pay these invoices will result in your storage items being auction eligible.</>
                )}
                <br />
                <br />
                These monthly payment plan payments are entirely separate from any other invoices that may be incurred
                on your account including, but not limited to, your standard storage subscription.
              </Text.Callout>
              <br />
            </>
          )}
          {storageTermEndDate && (
            <>
              <Text.Body weight={FontWeight.Medium}>Storage Commitment</Text.Body>
              <br />
              <Text.Callout>
                You are obligated to store with Clutter through {formatDate(storageTermEndDate)}. If you need to close
                your account before that date then the remaining invoices owed under this storage commitment will be due
                when booking your final return.
              </Text.Callout>
            </>
          )}
        </>
      )}
    </>
  );
};
