import React, { FC } from 'react';

import { Box, COLORS, Text } from '@clutter/clean';

import { VISITOR } from '@portal/config/visitor';
import { ServiceCards } from './service_cards';

export const BookNewAppointment: FC<{ reonboarding?: boolean; requestableItemsExist: boolean }> = ({
  reonboarding = true,
  requestableItemsExist,
}) => (
  <Box padding="16px 8px">
    <Text.Title color={COLORS.panther} size="large">
      Hello{VISITOR && ` ${VISITOR.name}`},
    </Text.Title>
    <Box color={COLORS.panther} margin="0 0 24px">
      <Text.Body>You have no upcoming storage appointments. How can we help you today?</Text.Body>
    </Box>
    <ServiceCards reonboarding={reonboarding} requestableItemsExist={requestableItemsExist} />
  </Box>
);
