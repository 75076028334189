import * as React from 'react';

import { DetailedClaimFragment, Status, useBuildClaimMutation } from '@portal/schema';

import { Checklist } from '@portal/components/helpers/checklist';
import { Draft } from '@portal/components/helpers/draft';
import { Alert } from '@shared/components/bootstrap';

import { ClaimConsumer } from '@portal/contexts';
import { Step } from './step';

interface IFieldsProps {
  claim: DetailedClaimFragment;
  onChange(claim: DetailedClaimFragment): void;
}

const Fields = ({ claim, onChange }: IFieldsProps) => (
  <>
    <Step.Title>What went wrong?</Step.Title>
    <Step.Subtitle>You may select more than one issue.</Step.Subtitle>
    <Checklist>
      <Checklist.Entry
        format="check"
        checked={claim.hasDamagedItem}
        onChange={(hasDamagedItem) => onChange({ ...claim, hasDamagedItem })}
      >
        Damaged Item (for example, a lamp we picked up)
      </Checklist.Entry>
      <Checklist.Entry
        format="check"
        checked={claim.hasDamagedProperty}
        onChange={(hasDamagedProperty) => onChange({ ...claim, hasDamagedProperty })}
      >
        Damaged Property (for example, a wall in your home)
      </Checklist.Entry>
      <Checklist.Entry
        format="check"
        checked={claim.hasLostItem}
        onChange={(hasLostItem) => onChange({ ...claim, hasLostItem })}
      >
        Lost Item
      </Checklist.Entry>
    </Checklist>
  </>
);

export const Kinds = () => {
  const [mutate, { loading, error, data }] = useBuildClaimMutation();

  if (error) {
    throw error;
  }

  return (
    <ClaimConsumer>
      {({ claim, onChange: onSave }) => (
        <Draft<DetailedClaimFragment> draft={claim}>
          {({ draft, onChange }) => {
            const { hasDamagedItem, hasLostItem, hasDamagedProperty, hasIssue, uuid } = draft;

            return (
              <Step
                saving={loading}
                valid={hasDamagedItem || hasLostItem || hasDamagedProperty}
                onSave={async () => {
                  const result = await mutate({
                    variables: {
                      input: { hasDamagedItem, hasLostItem, hasDamagedProperty, hasIssue, uuid },
                    },
                  });
                  onSave(draft);
                  return result.data?.buildClaim?.status || Status.Unprocessable;
                }}
              >
                {data && data.buildClaim?.error && <Alert style="danger">{data.buildClaim.error}</Alert>}
                <Fields claim={draft} onChange={onChange} />
              </Step>
            );
          }}
        </Draft>
      )}
    </ClaimConsumer>
  );
};
