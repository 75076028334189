import React from 'react';
import { ProgressBar } from '@clutter/clean';
import { StepName, Values } from './data';
import { Item, ItemContainer, ProgressContainer } from '../progress_helpers';
import { isAddressCompleted } from '../utils';

export const Progress: React.FC<{ currentStep: number; goToStep: (stepName: string) => void; values: Values }> = ({
  currentStep,
  goToStep,
  values,
}) => {
  // Hide progress bar on first and last steps
  if (!currentStep || currentStep >= 5) return null;

  let percentage;
  if (currentStep <= 2) {
    percentage = 0.33;
  } else if (currentStep <= 3) {
    percentage = 0.66;
  } else {
    percentage = 0.9;
  }

  const boxesCompleted = !!values.boxCategoryName;
  const addressCompleted = isAddressCompleted(values.address);
  const roomsCompleted = !!values.rooms?.length;

  const boxesClickable = true;
  const addressClickable = boxesCompleted;
  const itemsClickable = boxesCompleted && addressCompleted && roomsCompleted;

  return (
    <ProgressContainer>
      <ProgressBar percentage={percentage} />
      <ItemContainer>
        <Item
          highlighted={percentage === 0.33}
          onClick={() => addressClickable && goToStep(StepName.Address)}
          clickable={addressClickable}
        >
          Your Home
        </Item>
        <Item
          highlighted={percentage === 0.66}
          onClick={() => boxesClickable && goToStep(StepName.Boxes)}
          clickable={boxesClickable}
        >
          Boxes
        </Item>
        <Item
          highlighted={percentage === 0.9}
          onClick={() => itemsClickable && goToStep(StepName.Items)}
          clickable={itemsClickable}
        >
          Furniture
        </Item>
      </ItemContainer>
    </ProgressContainer>
  );
};
