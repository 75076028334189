import styled from '@emotion/styled';
import React from 'react';

import { PlanFragment } from '@portal/schema';

import { COLORS } from '@clutter/clean';

const DEFAULT_QUANTITY = 1;

const Muted = styled.span`
  color: ${COLORS.hippo};
`;

export const Name: React.FC<{ plan: PlanFragment; quantity?: number }> = ({ plan, quantity }) => {
  const dimensions = plan.__typename === 'Pricing__StoragePlan' ? plan.dimensions : undefined;
  const name = dimensions ? `${dimensions.length}’ × ${dimensions.width}’` : plan.name;

  return (
    <>
      {quantity && quantity > DEFAULT_QUANTITY && name !== 'Custom' && <Muted>{quantity} × </Muted>} {name}
    </>
  );
};
