import { DateTime, Duration } from 'luxon';

import { CallbackWindow } from '@portal/schema';

const TIME_FORMAT = 'h:mma';

export const formatCallbackWindow = ({ time, duration }: CallbackWindow) => {
  const from = DateTime.fromISO(time);
  const till = from.plus(Duration.fromISO(duration));
  return `${from.toFormat(TIME_FORMAT)} - ${till.toFormat(TIME_FORMAT)}`;
};
