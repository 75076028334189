import { COLORS, Text, TextButton } from '@clutter/clean';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Table } from '@portal/components/shared/table';
import { currency } from '@shared/utils/currency';

const selectedStyles = css`
  border: 1.5px solid ${COLORS.tealPrimary};
  background: ${COLORS.tealBackground};
`;

const unselectedStyles: Record<Theme, SerializedStyles> = {
  invoice: css`
    border: 1.5px solid ${COLORS.grayBackground};
    background: ${COLORS.grayBackground};
  `,
  deposit: css`
    border: 1.5px solid ${COLORS.sunset};
    background: ${COLORS.flower};
  `,
};

const Container = styled.div<{ selected?: boolean; themeName: Theme }>`
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;

  ${({ selected, themeName }) => (selected ? selectedStyles : unselectedStyles[themeName])}
`;

const SubContainer = styled(Table)`
  tr > td {
    border: none;
    padding: 0;
    &:last-child {
      text-align: right;
    }
  }
  &:last-of-type {
    tr > td {
      padding: 16px 0 0;
      vertical-align: bottom;
    }
  }
`;

const Callout = styled(Text.Callout)`
  color: ${COLORS.panther};
`;

const Caption = styled(Text.Caption)`
  color: ${COLORS.storm};
`;

type Theme = 'invoice' | 'deposit';

export const Card: React.FC<{
  name: string;
  description?: string;
  amount: number;
  disabled?: boolean;
  selected?: boolean;
  setSelected?(selected: boolean): void;
  themeName: Theme;
}> = ({ name, description, amount, disabled = false, selected, setSelected, themeName }) => (
  <Container selected={selected} themeName={themeName}>
    <SubContainer>
      <tbody>
        <tr>
          <td>
            <Callout>{name}</Callout>
          </td>
          <td>
            <Callout>{currency(amount)}</Callout>
          </td>
        </tr>
      </tbody>
    </SubContainer>
    <SubContainer>
      <tbody>
        <tr>
          {themeName === 'invoice' && (
            <td>
              <TextButton onClick={() => !disabled && setSelected?.(!selected)}>
                {selected ? 'Deselect' : 'Select Invoice'}
              </TextButton>
            </td>
          )}
          <td>
            <Caption>{description}</Caption>
          </td>
        </tr>
      </tbody>
    </SubContainer>
  </Container>
);
