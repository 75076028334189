/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router';

import { Text, SkeletonLoader, Box, BREAKPOINTS } from '@clutter/clean';
import { itemsURL, ItemStatusSlug } from '@portal/config/routes';
import { OrderFragment, OrderStatusEnum, OrderTypeEnum } from '@portal/schema';
import { Tabs } from '@portal/components/helpers/tabs';
import { SearchInput } from '@portal/components/helpers/search_input';

const SEARCH_PLACEHOLDER = 'Search for your bed, chair, etc.';

const Loader = () => (
  <Box margin="48px 0 32px">
    <Box margin="0 0 8px">
      <Text.Title size="small">My items</Text.Title>
    </Box>
    <SearchInput placeholder={SEARCH_PLACEHOLDER} disabled />
    <SkeletonLoader height="51px" margin="32px 0 0" />
  </Box>
);

const Filters: React.FC<{
  initialQuery?: string;
  orders?: OrderFragment[];
  onQueryChange(query: string): void;
  inventory: {
    available: number;
    requested: number;
    returned: number;
    delayed: number;
  };
}> = ({ inventory, onQueryChange, orders, initialQuery = '' }) => {
  const location = useLocation();
  const history = useHistory();
  const [query, setQuery] = useState(initialQuery);

  const handleSearchChange = (searchQuery: string, event?: React.ChangeEvent<HTMLInputElement>) => {
    event?.preventDefault();
    event?.stopPropagation();

    setQuery(searchQuery);
    const search = new URLSearchParams(location.search);
    if (searchQuery) {
      search.set('query', searchQuery);
      history.replace({ search: search.toString() });
    } else {
      search.delete('query');
      history.push({ search: search.toString() });
    }

    onQueryChange(searchQuery);
  };

  const clearSearch = () => {
    setQuery('');
    onQueryChange('');
  };

  const hasReturn = orders?.some(
    (order) =>
      order.status !== OrderStatusEnum.Canceled &&
      (order.type === OrderTypeEnum.Return || order.type === OrderTypeEnum.PickupReturn),
  );

  return (
    <Box margin="48px 0 32px">
      <Box margin="0 0 8px">
        <Text.Title size="small">My items</Text.Title>
      </Box>
      <SearchInput
        value={query}
        onChange={handleSearchChange}
        placeholder={SEARCH_PLACEHOLDER}
        onKeyDown={({ key, currentTarget }) =>
          // Force the keyboard to close on mobile when pressing 'Return'
          key === 'Enter' && window.innerWidth < BREAKPOINTS.SM_INT && currentTarget.blur()
        }
        onClear={clearSearch}
      />
      {hasReturn && (
        <Tabs>
          <Tabs.TabLink to={`${itemsURL(ItemStatusSlug.Available)}${location.search}`}>
            <Text.SmallCaps>Stored ({inventory.available})</Text.SmallCaps>
          </Tabs.TabLink>
          <Tabs.TabLink to={`${itemsURL(ItemStatusSlug.Requested)}${location.search}`}>
            <Text.SmallCaps>Requested ({inventory.requested})</Text.SmallCaps>
          </Tabs.TabLink>
          <Tabs.TabLink to={`${itemsURL(ItemStatusSlug.Returned)}${location.search}`}>
            <Text.SmallCaps>Returned ({inventory.returned})</Text.SmallCaps>
          </Tabs.TabLink>
          {inventory.delayed > 0 && (
            <Tabs.TabLink to={`${itemsURL(ItemStatusSlug.Delayed)}${location.search}`}>
              <Text.SmallCaps>Delayed ({inventory.delayed})</Text.SmallCaps>
            </Tabs.TabLink>
          )}
        </Tabs>
      )}
    </Box>
  );
};

const Combo = Object.assign(Filters, { Loader });

export { Combo as Filters };
