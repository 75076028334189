import React from 'react';

import { COLORS } from '@clutter/clean';

export const Marker: React.FC<{ fillColor?: string } & React.HTMLAttributes<SVGElement>> = ({
  fillColor = COLORS.tealJungle,
  className,
  style,
}) => (
  <svg
    width="21"
    height="32"
    viewBox="0 0 21 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M8.7576 28.261L1.99264 16.2344C1.23621 14.8896 2.22291 13.231 3.76564 13.2541L16.356 13.442C17.824 13.4639 18.7689 15.0074 18.1206 16.3248L12.2953 28.1635C11.5847 29.6074 9.54658 29.6636 8.7576 28.261Z"
      fill={fillColor}
    />
    <circle cx="10.045" cy="10.045" r="10.045" fill="#1B9089" />
    <circle cx="10.0449" cy="9.47159" r="3.73099" fill="white" />
  </svg>
);
