import React, { useState } from 'react';

import { ReviewForm } from '@portal/components/review/form';
import { Rating, Review__ReviewableEnum } from '@portal/schema';

export const Review: React.FC<{
  reviewableID: string;
  rating?: Rating;
  kind?: string;
  schedulingID?: string;
}> = ({ reviewableID, rating, kind, schedulingID }) => {
  const [selectedRating, setSelectedRating] = useState<Rating | undefined>(rating);

  return (
    <ReviewForm
      title="How would you rate your experience?"
      selectedRating={selectedRating}
      setSelectedRating={setSelectedRating}
      reviewable={{ id: reviewableID, type: Review__ReviewableEnum.CustomerTicket }}
      kind={kind}
      schedulingID={schedulingID}
    />
  );
};
Review.displayName = 'TicketReview';
