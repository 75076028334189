import styled from '@emotion/styled';

export const CalendarLabel = styled.div`
  color: #000000;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
`;
