export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AccountMutationInterface: ['Mutation'],
    AccountResolutionMutationInterface: ['Mutation'],
    AccountResolutionQueryInterface: ['Query'],
    AvailabilityMutationInterface: ['Mutation'],
    BillingMutationInterface: ['Mutation'],
    BillingQueryInterface: ['Query'],
    Billing__Invoiceable: ['Order', 'SelfStorage__AdjustedRateRental', 'UpcomingSubscription'],
    ClaimMutationInterface: ['Mutation'],
    ClaimQueryInterface: ['Query'],
    ComplianceMutationInterface: ['Mutation'],
    ComplianceQueryInterface: ['Query'],
    CustomerItem: ['Item', 'ItemGroup'],
    CustomerMutationInterface: ['Mutation'],
    DisposalQueryInterface: ['Query'],
    DropOff__Fulfiller: ['DropOff__ThirdPartySelfStorageFacility', 'SelfStorage__Facility'],
    DropOff__StorageArrangement: ['DropOff__ThirdPartySelfStorageUnit', 'SelfStorage__Hold'],
    EstimationMutationInterface: ['Mutation'],
    EstimationQueryInterface: ['Query'],
    Facility: ['Warehouse'],
    FacilityQueryInterface: ['Query'],
    FinancingMutationInterface: ['Mutation'],
    FlowMutationInterface: ['Mutation'],
    MFAMutationInterface: ['Mutation'],
    MarketingMutationInterface: ['Mutation'],
    MarketingQueryInterface: ['Query'],
    MovingMutationInterface: ['Mutation'],
    MovingQueryInterface: ['Query'],
    OnboardingMutationInterface: ['Mutation'],
    OrderMutationInterface: ['Mutation'],
    PaymentPlanMutationInterface: ['Mutation'],
    PaymentPlanQueryInterface: ['Query'],
    PricingMutationInterface: ['Mutation'],
    PricingQueryInterface: ['Query'],
    Pricing__Plan: ['Pricing__ProtectionPlan', 'Pricing__StoragePlan'],
    RetentionMutationInterface: ['Mutation'],
    ReviewQueryInterface: ['Query'],
    Review__Reviewable: [
      'Review__Reviewable__Base',
      'Review__Reviewable__Order',
      'Review__Reviewable__SelfStorageRental',
    ],
    ReviewsMutationInterface: ['Mutation'],
    SettingsMutationInterface: ['Mutation'],
    ShippingMutationInterface: ['Mutation'],
    ShippingQueryInterface: ['Query'],
    TermCommitmentMutationInterface: ['Mutation'],
    ZendeskQueryInterface: ['Query'],
  },
};
export default result;
