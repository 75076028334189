interface IConfig {
  impersonator?: {
    id: string;
    name: string;
    roles: string[];
  };
}

declare const CONFIG: IConfig;

export const IMPERSONATOR = CONFIG.impersonator;
