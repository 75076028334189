import React from 'react';

const DEFAULT_WIDTH = 36;
const DEFAULT_HEIGHT = 39;

export const ThumbsUp: React.FC<{ overrideColor?: string; scale?: number }> = ({ overrideColor, scale = 1.0 }) => {
  const width = DEFAULT_WIDTH * scale;
  const height = DEFAULT_HEIGHT * scale;

  return (
    <svg width={width} height={height} viewBox="0 0 36 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.77417 19.436C9.79926 19.7156 12.0731 19.8833 12.9677 18.3177C13.8623 16.7522 15.577 14.1242 16.3226 13.0059L16.8817 8.25323C18.4659 7.78728 21.6344 7.75 21.6344 11.3285C21.6344 14.907 20.7025 18.2246 20.2365 19.436H32.258C32.258 19.436 34.4946 19.436 34.4946 21.6726C34.4946 23.3771 33.358 23.9449 32.8172 24.1187M25.8279 24.1887H32.5376C32.5376 24.1887 32.6484 24.1729 32.8172 24.1187M32.8172 24.1187C32.8172 24.1187 35.3333 24.1887 35.3333 26.4253C35.3333 28.6618 32.8172 28.9414 32.8172 28.9414H31.4193M25.8279 28.9414H31.4193M31.4193 28.9414C31.4193 28.9414 33.0968 29.2796 33.0968 31.2366C33.0968 33.1936 31.1398 33.4145 31.1398 33.4145H25.8279"
        stroke={overrideColor || '#00404D'}
      />
      <path
        d="M29.1828 33.4145C29.1828 33.4145 31.4193 33.9736 31.4193 35.9306C31.4193 37.8876 29.1828 38.4467 29.1828 38.4467H17.4408C16.043 38.4467 12.3527 37.8876 8.77417 35.651"
        stroke={overrideColor || '#00404D'}
      />
      <path d="M15.491 5.47255L12.9141 2.44244" stroke={overrideColor || '#A1E6E0'} strokeLinecap="round" />
      <path d="M18.7949 5.01641L18.7632 1" stroke={overrideColor || '#A1E6E0'} strokeLinecap="round" />
      <path d="M21.8184 5.79816L23.9998 2.81645" stroke={overrideColor || '#A1E6E0'} strokeLinecap="round" />
      <rect x="0.666504" y="18.0967" width="8.94624" height="20.129" fill={overrideColor || '#00A499'} />
    </svg>
  );
};
