import * as React from 'react';

import { Heading } from '@portal/components/shared/text';
import { Text } from '@shared/components/bootstrap';

export const DoorstepNotice: React.FC = () => (
  <div className="mb-3">
    <Heading level={2} size="small">
      Doorstep Delivery
    </Heading>
    <Text tag="p" style="dark">
      We will deliver these items to your doorstep, or a secure location outside, without requiring a signature. Once
      items are delivered, we will email you an update that includes a photo of where items were placed.
    </Text>
  </div>
);
