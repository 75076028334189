import styled from '@emotion/styled';
import axios, { AxiosError } from 'axios';
import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';

import { Container } from '@portal/components/shared/layout';
import { bodyStyle, Heading } from '@portal/components/shared/text';

import { ResetForm } from './reset_form';
import { Success } from './reset_success';

const Title = styled(Heading)`
  text-align: center;
  margin-bottom: 24px;
  @media (max-width: 600px) {
    text-align: left;
    margin-bottom: 16px;
  }
`;

const Subtitle = styled.p`
  ${bodyStyle}
  text-align: center;
  padding-bottom: 16px;
  margin: 0;
  @media (max-width: 600px) {
    text-align: left;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 470px;
`;

const SUBTITLE =
  'We just sent an email with a link to reset your password. If you don’t see the email in your inbox ' +
  'in the next five minutes, please check your spam folder.';
const EMAIL_NOT_FOUND =
  'We couldn’t find an account with that email address. Please make sure the spelling is correct or try another.';

export const Reset: React.FC = () => {
  const [reset, setReset] = useState<boolean>(false);
  const [error, setError] = useState<React.ReactElement | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const onReset = async (email: string) => {
    setLoading(true);
    try {
      await axios.post('/password.json', { customer: { email } });
      setReset(true);
    } catch (e) {
      setLoading(false);
      if ((e as AxiosError).response?.data.errors?.email) {
        setError(<>{EMAIL_NOT_FOUND}</>);
      } else {
        setError(<>There was an error, please try again.</>);
        if (Sentry) {
          Sentry.withScope((scope) => {
            scope.setTag('resetPasswordError', 'true');
            Sentry.captureException(e);
          });
        }
      }
    }
  };

  if (reset) {
    return <Success subtitle={SUBTITLE} />;
  }

  return (
    <Container hasNavbar={false}>
      <Title level={1} size="large">
        Reset your password
      </Title>
      <Content>
        <Subtitle>
          Enter the email associated with your Clutter account below and we'll send you a link to reset your password.
        </Subtitle>
        <ResetForm loading={loading} error={error} setError={setError} onReset={onReset} />
      </Content>
    </Container>
  );
};
Reset.displayName = 'ResetPassword';
