import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Form, Input, Label } from '@clutter/clean';

import {
  IGoogleMapsPlace,
  useGooglePlacesAutocompleteInputRef,
} from '@shared/components/helpers/google_maps_places_autocomplete_input';

import { Address } from '@portal/schema';
import { Page } from './page';

const TITLE = 'Add a new billing address';

type AddressFragment = Pick<Address, 'aptsuite' | 'street' | 'city' | 'state' | 'zip' | 'country'>;

const Field = styled.div`
  margin: 24px 0;
`;

export const Builder: React.FC<{
  loading: boolean;
  onBack?(): void;
  onContinue(address: AddressFragment): void;
}> = ({ loading, onBack, onContinue }) => {
  const [aptsuite, setAptsuite] = useState<string | undefined>();
  const [place, setPlace] = useState<IGoogleMapsPlace | undefined>();

  const input = useGooglePlacesAutocompleteInputRef(setPlace);

  const onNext = () => {
    if (place && place.city && place.state && place.street && place.zip && place.country) {
      onContinue({
        aptsuite,
        street: place.street,
        city: place.city,
        state: place.state,
        zip: place.zip,
        country: place.country,
      });
    }
  };

  return (
    <Page title={TITLE} loading={loading} ready={!!place} onBack={onBack} onNext={onNext}>
      <Field>
        <Label>Address</Label>
        <Form.Group>
          <Input ref={input} />
        </Form.Group>
      </Field>
      <Field>
        <Label>Apt/Suite (optional)</Label>
        <Form.Group>
          <Input value={aptsuite ?? ''} onChange={(event) => setAptsuite(event.target.value || undefined)} />
        </Form.Group>
      </Field>
    </Page>
  );
};
