export const UUID = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (character) => {
    const seed = Math.floor(Math.random() * 16);

    const value = (() => {
      switch (character) {
        case 'x':
          return seed;
        case 'y':
          return (seed & 0x3) | 0x8; // eslint-disable-line no-bitwise
        default:
          throw new Error('undefined');
      }
    })();

    return value.toString(16);
  });
