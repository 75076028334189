import React from 'react';
import { ButtonLink, FontWeight, mq, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { orderURL } from '@portal/config/routes';
import SQUIGGLE from '@portal/images/squiggle.svg';
import AFFIRM from '@portal/images/affirm.svg';
import { Financing__Context, Financing__Intent, Maybe, Order, OrderStatusEnum } from '@portal/schema';

const Affirm = styled.img`
  height: 24px;
  margin: -8px 0 6px;
`;

const Container = styled.div`
  margin: 0 0 32px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: -8px;
`;

const Item = styled.div`
  padding: 8px;
`;

const Link = styled(ButtonLink)`
  ${mq({
    width: ['100%', null, 'initial'],
  })}
`;

const Separator = styled.img`
  margin: -8px 0 8px;
`;

type OrderFragment = Pick<Order, 'id' | 'status'> & {
  financingIntent?: Maybe<Pick<Financing__Intent, 'intended'>>;
  financingContext?: Maybe<Pick<Financing__Context, 'total'>>;
};

export const Widget: React.FC<{ order: OrderFragment }> = ({ order }) => {
  if (!order.financingIntent) return null;

  const intended = order.financingIntent.intended;
  const completed = order.status === OrderStatusEnum.Completed;
  const balance = order.financingContext && order.financingContext.total > 0;
  if (!completed && intended) return null;
  if (completed && !balance) return null;

  return (
    <Container>
      <List>
        <Item>
          <Text.Body weight={FontWeight.Medium}>
            Move now, pay later with <Affirm src={AFFIRM} alt="Affirm" />
          </Text.Body>
          <Separator src={SQUIGGLE} />
          <Text.Callout>No late fees, no hidden fees, no surprises.</Text.Callout>
        </Item>
        <Item>
          <Link kind="secondary" to={orderURL(order.id, completed ? 'financing/checkout' : 'financing/summary')}>
            {completed ? 'Pay Now' : 'Learn More'}
          </Link>
        </Item>
      </List>
    </Container>
  );
};
