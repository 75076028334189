import React from 'react';
import { Box, COLORS, Text } from '@clutter/clean';
import { Stack } from '@portal/components/helpers/stack';

export const RestrictedDisposalItems = () => (
  <>
    <Stack gap="20px">
      <Stack gap="8px">
        <Box textAlign="center">
          <Text.Headline color={COLORS.hippo}>Restricted Items</Text.Headline>
        </Box>
        <Text.Body>Clutter cannot dispose of the following items to ensure the safety of our team:</Text.Body>
      </Stack>
      <Text.Callout>
        <Box.Grid gridTemplateColumns="min-content auto" gridGap="8px 12px">
          <span>🥛</span>
          <span>Liquids of any kind (including alcohol, cleaning products, cosmetics/toiletries etc.)</span>
          <span>👃</span>
          <span>Items that emit smells or fumes</span>
          <span>💊</span>
          <span>Illegal or controlled substances</span>
          <span>🐠</span>
          <span>Live animals or taxidermy</span>
          <span>🎯</span>
          <span>Firearms, guns, or ammunition</span>
          <span>🔥</span>
          <span>Flammable or combustible materials (no gases, explosives, or asbestos)</span>
          <span>☢</span>
          <span>Hazardous materials (radioactive, chemical, or biological wastes)</span>
          <span>🚙</span>
          <span>Vehicles (boats, cars, riding lawn mowers, or anything with an engine)</span>
          <span>🎱</span>
          <span>Pool tables</span>
          <span>☠️</span>
          <span>Human remains</span>
          <span>🎹</span>
          <span>Grand or baby grand pianos (or upright pianos if stairs are present)</span>
          <span>🕰</span>
          <span>Grandfather clocks</span>
          <span>🛏</span>
          <span>Mechanical furniture that has not been professionally disassembled, including Sleep Number beds</span>
          <span>🐜</span>
          <span>
            Items that show signs of infestation, or are at a location with signs of infestation (insects, rodents,
            mold)
          </span>
          <span>🏋️‍♀️</span>
          <span>Items our team cannot safely transport (unusually heavy or bulky items)</span>
        </Box.Grid>
      </Text.Callout>
    </Stack>
  </>
);
