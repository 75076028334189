import React, { useState } from 'react';
import { Autocomplete, Box, Button, COLORS, mq, Text, toggleStyleValue, UnstyledButton } from '@clutter/clean';
import { Estimation__CategoryType, useItemCategorySearchQuery } from '@portal/schema';
import { useDebounce } from '@shared/hooks';
import { wt } from '@portal/initializers/wt';
import styled from '@emotion/styled';
import { Pluralize } from '@shared/components/helpers';
import { omit } from 'lodash';
import { useLastDefined } from '@shared/hooks/state';
import { SelectedItemCategories } from '../data';
import { ITEM_INVENTORY_PAGE_NAME } from '../item_inventory';

const POPULAR_SEARCH_TERMS = ['Bed', 'Mattress', 'Appliance', 'Sofa', 'Desk', 'Box', 'Chair', 'Table'];

const SearchButton = styled(Button)`
  ${mq({
    width: ['100%', null, 'initial'],
  })}
`;

const RowContainer = styled.div<{ selected: boolean }>`
  padding: 12px 16px;
  background: ${toggleStyleValue('selected', COLORS.tealBackground, COLORS.cloud)};
  cursor: pointer;

  &:hover {
    background: ${toggleStyleValue('selected', COLORS.tealBackground, COLORS.grayBackground)};
  }
`;

const SearchResult: React.FC<{
  value: string | Estimation__CategoryType;
  selected?: boolean;
  onClick(): void;
}> = ({ value, selected, onClick }) => {
  const isCategory = typeof value !== 'string';

  return (
    <RowContainer selected={!!selected} onClick={() => onClick()}>
      <UnstyledButton
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onClick();
        }}
      >
        {isCategory && (
          <>
            <Text color={COLORS.tealPrimary}>+</Text>{' '}
          </>
        )}
        <Text>{isCategory ? value.name : value}</Text>
      </UnstyledButton>
    </RowContainer>
  );
};

const DropdownButtonContainer = styled.div`
  border-top: 1px solid ${COLORS.grayBorder};
  padding: 16px;
`;

const SearchResultsContainer = styled.div`
  overflow: scroll;
`;

type SelectedItemMap = Record<string, SelectedItemCategories[number]>;

export function SearchBar({
  selectedItemCategories,
  onSelectedItemsChange,
}: {
  selectedItemCategories: SelectedItemCategories;
  onSelectedItemsChange(items: SelectedItemCategories): void;
}) {
  const [query, setQuery] = useState('');
  const [pendingSelectedItemMap, setMultiSelectValues] = useState<SelectedItemMap>({});
  const currentSelectedItemMap = selectedItemCategories.reduce<SelectedItemMap>(
    (acc, cur) => ({ ...acc, [cur.category.id]: cur }),
    {},
  );
  const debouncedQuery = useDebounce(query!);

  const { data } = useItemCategorySearchQuery({
    variables: {
      query: debouncedQuery,
    },
    skip: !debouncedQuery,
  });
  const searchResults = useLastDefined(data?.itemCategorySearch);

  const onSelectQueryResult = (category: Estimation__CategoryType) => {
    if (pendingSelectedItemMap[category.id]) {
      // Remove the selection if clicked twice
      setMultiSelectValues(omit(pendingSelectedItemMap, category.id));
    } else {
      setMultiSelectValues({ ...pendingSelectedItemMap, [category.id]: { quantity: 1, category } });
    }
  };

  const onAddSelectedItems = () => {
    const update: SelectedItemCategories = [];
    Object.keys(pendingSelectedItemMap).forEach((id) => {
      update.push({
        category: pendingSelectedItemMap[id].category,
        quantity: currentSelectedItemMap[id] ? currentSelectedItemMap[id].quantity + 1 : 1,
      });
    });
    Object.keys(currentSelectedItemMap).forEach((id) => {
      if (!pendingSelectedItemMap[id]) {
        update.push(currentSelectedItemMap[id]);
      }
    });
    onSelectedItemsChange(update);
    setQuery('');
    setMultiSelectValues({});
  };

  const onChangeQuery = (value?: string) => {
    wt.track({
      pageName: ITEM_INVENTORY_PAGE_NAME,
      container: 'item_inventory',
      value: value,
      objectName: 'item_inventory_search_bar',
      objectType: 'search_bar',
      action: 'keystroke',
    });
    setMultiSelectValues({});
    setQuery(value ?? '');
  };

  const pendingItemCount = Object.keys(pendingSelectedItemMap).length;

  return (
    <Box margin="24px 0 16px">
      <Autocomplete.Form
        onSubmit={() => {
          wt.track({
            pageName: ITEM_INVENTORY_PAGE_NAME,
            container: 'item_inventory',
            value: query,
            objectName: 'item_inventory_search_bar',
            objectType: 'search_bar',
            action: 'submit',
          });
        }}
      >
        <Autocomplete.Searchbar
          placeholder="Search and add items from library"
          query={query}
          onChange={(value) => onChangeQuery(value)}
        />
        {searchResults && query.length > 0 ? (
          <Autocomplete.Dropdown>
            <Box.Flex flexDirection="column" maxHeight="400px">
              <SearchResultsContainer>
                {searchResults.length ? (
                  searchResults.map((result, i) => (
                    <SearchResult
                      key={i}
                      value={result}
                      onClick={() => onSelectQueryResult(result)}
                      selected={!!pendingSelectedItemMap[result.id]}
                    />
                  ))
                ) : (
                  <Box padding="16px">
                    <Text color={COLORS.storm}>We couldn't find that item, please try another search term.</Text>
                  </Box>
                )}
              </SearchResultsContainer>
              {pendingItemCount > 0 && (
                <DropdownButtonContainer>
                  <SearchButton onClick={() => onAddSelectedItems()}>
                    Add <Pluralize count={pendingItemCount} singular="item" plural="items" />
                  </SearchButton>
                </DropdownButtonContainer>
              )}
            </Box.Flex>
          </Autocomplete.Dropdown>
        ) : (
          <Autocomplete.Dropdown>
            <Box.Flex flexDirection="column" maxHeight="400px">
              <SearchResultsContainer>
                <Box padding="12px 20px">
                  <Text color={COLORS.hippo}>Search items by category</Text>
                </Box>
                {POPULAR_SEARCH_TERMS.map((result, i) => (
                  <SearchResult key={i} value={result} onClick={() => setQuery(result)} />
                ))}
              </SearchResultsContainer>
            </Box.Flex>
          </Autocomplete.Dropdown>
        )}
      </Autocomplete.Form>
    </Box>
  );
}
