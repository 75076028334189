import { ConfirmError } from '@portal/components/orders/steps/confirm/confirm_error';
import { TextButton } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { payURL } from '@portal/config/routes';

const ButtonContainer = styled.div`
  padding-top: 12px;
`;

export const Suspended: React.FC<{ overrideError?: () => void }> = ({ overrideError }) => (
  <ConfirmError
    headline="Your account is suspended"
    detail="Please pay off your remaining balance before booking an appointment."
    overrideError={overrideError}
  >
    <ButtonContainer>
      <TextButton kind="destructive" href={payURL()}>
        Pay Now
      </TextButton>
    </ButtonContainer>
  </ConfirmError>
);
