import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { InputGroup } from '@shared/components/bootstrap';

import { Step } from '@portal/components/claims/step';

import { ClaimItemSelectionForm } from './form';

export const ProductURL = () => (
  <ClaimItemSelectionForm skippable field="productURL">
    {({ loading, selection, onChange }) => (
      <>
        <Step.Title>Please provide a link to where the same item can be found online.</Step.Title>
        <Step.Subtitle>
          If a link is not provided or does not clearly display the item value, then the maximum value of the item
          defaults to $1/lb.
        </Step.Subtitle>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              {loading ? <FontAwesomeIcon icon="spinner" spin /> : <FontAwesomeIcon icon="link" />}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <input
            className="form-control"
            type="url"
            placeholder="Paste link to the item on the web"
            pattern="https://.*"
            onChange={(event) => onChange({ ...selection, productURL: event.target.value || undefined })}
            value={selection.productURL || ''}
          />
        </InputGroup>
      </>
    )}
  </ClaimItemSelectionForm>
);
