import React from 'react';

import { Button as CleanButton } from '@clutter/clean';
import { Button as CustomerProtectedButton } from '@portal/components/shared/customer_protected/button';

export type Action = {
  text: string;
  kind: 'primary' | 'secondary';
  customerProtected?: boolean;
};

export const StepButton: React.FC<
  React.ComponentProps<typeof CleanButton> & {
    customerProtected?: boolean;
  }
> = ({ customerProtected, children, ...props }) => (
  <>
    {customerProtected ? (
      <CustomerProtectedButton fullWidth size="small" showErrorInTooltip {...props}>
        {children}
      </CustomerProtectedButton>
    ) : (
      <CleanButton fullWidth size="small" {...props}>
        {children}
      </CleanButton>
    )}
  </>
);
