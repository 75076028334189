import React, { useState } from 'react';

import { useSnackbarContext, Box } from '@clutter/clean';

import { useUpdateCustomerItemMutation, CustomerItemTypeEnum, CustomerItemFragment } from '@portal/schema';
import { imagesFor } from '@portal/components/items/utilities/customer_item';

import { ImageCarousel } from './image_carousel';
import { ImageGrid } from './image_grid';

const heroFor = (item: CustomerItemFragment) => {
  if (item.__typename === 'Item') return item.images[item.hero];
  return item.heroImage || item.items[0].images[0];
};

export const Images: React.FC<{ item: CustomerItemFragment }> = ({ item }) => {
  const [update] = useUpdateCustomerItemMutation();
  const { addSnack } = useSnackbarContext();

  // The hero image is always first in the array of images, but we shouldn't
  // shuffle images while the detail panel is open.
  const [cachedImages] = useState(() => imagesFor(item));

  const updateHero = async (id: string) => {
    await update({
      variables: {
        uuid: item.uuid,
        type: item.__typename as CustomerItemTypeEnum,
        input: {
          heroImageID: id,
        },
      },
    });
    addSnack({
      key: item.uuid,
      content: 'Item image updated!',
    });
  };
  return (
    <>
      <Box display={['none', null, 'block']} margin="0 0 48px">
        <ImageGrid images={cachedImages} hero={heroFor(item)} updateHero={updateHero} />
      </Box>
      <Box display={['block', null, 'none']}>
        <ImageCarousel images={cachedImages} hero={heroFor(item)} updateHero={updateHero} />
      </Box>
    </>
  );
};
