import { AriaAttributes, SVGAriaEnhancements } from '@shared/components/helpers/svg_aria_enhancements';
import React, { FC } from 'react';

export const PaperworkClipboard: FC<AriaAttributes> = ({ title, description: desc }) => {
  const Aria = SVGAriaEnhancements(title, desc);

  return (
    <svg
      width="40"
      height="49"
      viewBox="0 0 40 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={Aria.ariaLabelledby}
    >
      <Aria.Title />
      <Aria.Desc />
      <g clipPath="url(#clip0_22_1554)">
        <path
          d="M37.5793 43.3831L9.22191 48.3052C8.01154 48.5342 6.8588 47.7329 6.68589 46.531L0.34583 10.8172C0.115282 9.61526 0.922199 8.47059 2.13257 8.29889L30.4899 3.31955C31.7003 3.09062 32.853 3.89189 33.0259 5.0938L39.366 40.8076C39.5389 42.0095 38.732 43.1542 37.5793 43.3831Z"
          fill="#F79C94"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.1758 41.2083L8.81845 46.1876L5.01442 24.5533L2.47839 10.4738L30.8357 5.49444L33.3141 19.5739L37.1758 41.2083Z"
          fill="#00404D"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.7118 39.663L11.3545 44.5851L6.10953 24.3243L3.05478 10.3593L31.3545 5.37997L34.4092 19.4022L39.7118 39.663Z"
          fill="white"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.9597 5.37997L10.7205 7.32592C9.85591 7.49762 9.10663 8.18442 8.93372 9.04293L8.64554 10.4738L25.0144 7.61208L24.2075 6.35294C23.7464 5.6089 22.8818 5.20827 21.9597 5.37997Z"
          fill="#A1E6E0"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.5101 9.15739C9.62538 8.47059 10.2017 8.01272 10.8357 7.89825L22.0749 5.95231C22.7666 5.83784 23.4006 6.12401 23.7464 6.69634"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.951 7.32591L10.8357 6.81081C10.6628 5.89507 11.2968 5.03657 12.1614 4.9221C13.0836 4.7504 13.6599 3.89189 13.487 3.03339L13.3718 2.51828C13.1988 1.60254 13.8329 0.744038 14.6974 0.629571L15.7925 0.45787C16.7147 0.286169 17.5792 0.915739 17.6945 1.77425L17.8674 2.17488C18.0403 3.09062 18.9049 3.66296 19.7694 3.49126C20.6916 3.31956 21.5562 3.94913 21.6715 4.80763L21.7867 5.32273L10.951 7.32591Z"
          fill="#A1E6E0"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.2767 5.37997C13.487 5.15103 14.294 4.00636 14.0634 2.86169L13.9481 2.28935C13.8329 1.71701 14.2363 1.14467 14.8703 1.03021L15.9654 0.858506C16.5418 0.744038 17.1182 1.14467 17.2334 1.77424L17.3487 2.28935C17.5793 3.49126 18.732 4.29253 19.8847 4.06359"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.7349 3.66296C16.1805 3.66296 16.5418 3.30422 16.5418 2.86169C16.5418 2.41915 16.1805 2.06041 15.7349 2.06041C15.2892 2.06041 14.9279 2.41915 14.9279 2.86169C14.9279 3.30422 15.2892 3.66296 15.7349 3.66296Z"
          fill="white"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.70319 10.4738L25.0144 7.61208"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.7291 4.86486L21.8444 5.37997L10.951 7.32591L10.8358 6.75358"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5187 4.52146L11.8156 6.06677"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.3657 40.8076C39.5962 42.0095 38.7893 43.1542 37.5789 43.3259L9.22157 48.3052C8.0112 48.5342 6.85846 47.7329 6.68555 46.531L0.34549 10.8172"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.2162 28.2735L34.4092 19.4022L22.2479 28.2735"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.66571 14.938L16.196 13.4499"
          stroke="#00404D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.81845 20.089L23.804 17.4563"
          stroke="#00A499"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.39484 22.9507L29.8559 19.4022"
          stroke="#00A499"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.97119 25.8124L16.6571 24.6677"
          stroke="#00A499"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.7003 32.1081L18.3862 30.9634"
          stroke="#00A499"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.0115 29.3037L31.6427 28.6741"
          stroke="#00A499"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.4611 38.6327C22.7666 38.1749 24.6686 36.2862 25.1873 33.9968C24.5533 33.5962 23.6888 33.9396 23.17 34.5119C22.6513 35.0843 22.3055 36.0572 22.8818 36.6296C23.2277 36.973 23.7464 37.0302 24.2075 37.0302C25.245 36.9157 26.2248 36.3434 26.7435 35.4277C26.1095 35.1987 25.3602 35.8283 25.3602 36.4579C25.3602 36.5723 25.4179 36.6868 25.4755 36.744C25.6484 36.9157 25.879 36.8585 26.0519 36.744C26.5706 36.5723 27.0317 36.2862 27.4352 35.9428C27.3199 35.9428 27.2622 36.1717 27.3775 36.2862C27.4928 36.4006 27.6657 36.3434 27.781 36.2862C29.7983 35.8855 31.8156 35.4849 33.8328 35.0843"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_1554">
          <rect width="40" height="48.6486" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
