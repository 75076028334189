import { useMemo } from 'react';
import { useMovingPackingSuppliesQuery } from '@portal/schema';
import { getMovingPackingMaterials } from '@portal/utils/packing_materials';

export const useMovingPackingMaterials = (orderID: string) => {
  const { data, loading } = useMovingPackingSuppliesQuery({
    variables: { orderID },
  });
  const packingMaterials = useMemo(() => getMovingPackingMaterials(data?.order), [data?.order]);
  const packingHelp = data?.order.movingOperation?.packing;
  return { packingMaterials, packingHelp, loading };
};
