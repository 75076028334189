import React from 'react';

const ID = 'flexer';

export const Flexer: React.FC<{ title?: string }> = ({ title = 'The Flexer' }) => (
  <svg width="72" height="54" viewBox="0 0 72 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title id={ID}>{title}</title>
    <path
      d="M57.0271 16.5278C52.9986 7.38366 43.8559 1 33.2222 1C19.8393 1 8.81798 11.1113 7.38092 24.1111M57.7778 35.567C54.2368 45.7174 44.58 53 33.2222 53C25.5219 53 18.6034 49.6525 13.8426 44.3333"
      stroke="#1B9089"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M25.4274 14.5841L23.8334 14.8943V13.9292L28.0986 13.1021V14.0671L26.5045 14.3773V18.8493L25.436 19.0561V14.5841H25.4274Z"
      fill="black"
    />
    <path
      d="M33.346 12.0767V17.5051L32.2776 17.7205V15.394L29.9339 15.8507V18.1772L28.874 18.384V12.9555L29.9339 12.7487V14.8943L32.2862 14.4376V12.2835L33.346 12.0767Z"
      fill="black"
    />
    <path
      d="M34.5696 11.8442L38.3264 11.1118V12.0683L35.6294 12.5939V13.8002L38.0851 13.3263V14.2741L35.6294 14.748V16.1266L38.3264 15.601V16.5488L34.561 17.2813V11.8442H34.5696Z"
      fill="black"
    />
    <path
      d="M0 29.8616L9.44012 28.0244V30.4393L2.68565 31.7565V35.1535L8.8177 33.9634V36.3783L2.68565 37.5684V43.0914L0 43.6113V29.8616Z"
      fill="#01252D"
    />
    <path d="M11.8953 27.5512L14.5809 27.0312V38.3662L20.9089 37.1298V39.5447L11.8953 41.301V27.5512Z" fill="#01252D" />
    <path
      d="M22.7531 25.4366L32.2509 23.5879V26.0028L25.4272 27.3315V30.3703L31.64 29.1571V31.5489L25.4272 32.7621V36.2631L32.2509 34.9343V37.3261L22.7531 39.1748V25.4366Z"
      fill="#01252D"
    />
    <path
      d="M33.4727 36.8981L37.8873 29.3646V29.2837L33.6571 23.5065V23.3101L36.85 22.6861L39.9044 27.0999L39.9851 27.0884L43.0166 21.4845L46.1518 20.8721V21.0685L42.0138 28.498V28.5789L46.1863 34.4254V34.6218L42.9359 35.2573L39.8814 30.8667L39.8007 30.8782L36.7462 36.459L33.4727 37.0945V36.8981Z"
      fill="#01252D"
    />
    <path
      d="M48.0651 20.5025L57.5629 18.6538V21.0687L50.7392 22.3974V25.4363L56.952 24.223V26.6148L50.7392 27.828V31.329L57.5629 30.0003V32.392L48.0651 34.2407V20.5025Z"
      fill="#01252D"
    />
    <path
      d="M71.2677 29.5265V29.723L68.1671 30.3238L65.0089 25.91L62.7036 26.3722V31.3983L60.0179 31.9183V18.1685L66.0347 17.0015C68.9394 16.4354 70.6222 18.0761 70.6222 20.4101C70.6222 22.409 69.5503 24.1191 67.8559 25.055L71.2677 29.5265ZM62.7036 23.9688L65.8964 23.3449C67.268 23.0792 67.8559 22.0624 67.8559 20.9647C67.8559 19.9248 67.245 19.1738 65.8964 19.4395L62.7036 20.0634V23.9688Z"
      fill="#01252D"
    />
  </svg>
);
