import * as React from 'react';

import { ClaimStatus, DetailedClaimFragment } from '@portal/schema';

export const DEFAULT_CLAIM: DetailedClaimFragment = {
  id: '',
  uuid: '',
  status: ClaimStatus.Draft,
  hasDamagedItem: false,
  hasLostItem: false,
  hasDamagedProperty: false,
  hasIssue: false,
  itemSelections: [],
};

export type ClaimChanger = DetailedClaimFragment | ((claim: DetailedClaimFragment) => DetailedClaimFragment);

interface IContext {
  claim: DetailedClaimFragment;
  onChange(changer: ClaimChanger): void;
}

const { Provider, Consumer } = React.createContext<IContext>({
  claim: DEFAULT_CLAIM,
  onChange: (_: ClaimChanger) => {
    /* noop */
  },
});

export { Provider as ClaimProvider };
export { Consumer as ClaimConsumer };
