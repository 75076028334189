import React, { useState } from 'react';
import { ErrorMessage, useForm, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, FontWeight, Input, Label, Text, Textarea, Tooltip, mq, Select } from '@clutter/clean';
import { HelpBlock } from '@shared/components/bootstrap';
import styled from '@emotion/styled';

import { Spacer } from '@shared/components/helpers';

import { Lightbox } from '@shared/components/helpers';

import { Address, OrderCoiRequestInput } from '@portal/schema';

import IMAGE from '@portal/images/sample_coi.png';

const AddressSelect = styled(Select)`
  ${mq({
    minWidth: ['0px', '300px', '500px'],
  })}
`;

const Container = styled.div``;

const Header = styled(Text.Title)`
  font-weight: ${FontWeight.Regular};
`;

const InformationText = styled(Text.Body)`
  margin-bottom: 12px;
`;

const SampleContainer = styled.div`
  ${mq({
    width: ['auto', '324px'],
    height: ['100%', '419px'],
  })}
  margin: auto;
  margin-bottom: 12px;
  text-align: center;
  border: 1px solid black;
`;

const SampleImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const Required = styled.span`
  ::after {
    content: '*';
    color: red;
    margin-left: 2px;
  }
`;

const WideInput = styled(Input)`
  ${mq({
    width: ['100%', '60%'],
  })}
`;

const WideTextarea = styled(Textarea)`
  ${mq({
    width: ['100%', '60%'],
  })}
`;

export const OrderCOIForm: React.FC<{
  addresses: Array<Pick<Address, 'id' | 'formatted'>>;
  email: string;
  loading: boolean;
  onSubmit(input: OrderCoiRequestInput): void;
}> = ({ addresses, email, loading, onSubmit }) => {
  const [showModal, setShowModal] = useState<boolean>();
  const { errors, register, handleSubmit, control } = useForm<OrderCoiRequestInput>();

  return (
    <Container>
      <Header size="extraSmall">Request a COI</Header>
      <InformationText>
        Some properties require a Certificate of Insurance (COI) to be provided by moving companies before your moving
        day. We can provide one for you and your building manager. Note: A COI is not insurance for your items.
      </InformationText>
      <InformationText>First, you'll need to request a sample COI from your building manager.</InformationText>
      <InformationText>Here is an example:</InformationText>
      <SampleContainer
        onClick={() => {
          if (window.innerWidth >= 600) setShowModal(true);
        }}
      >
        <SampleImage src={IMAGE} />
      </SampleContainer>
      <InformationText>
        You'll find the "Description of Operations/Additional Insured" information in the first red box and the
        "Certificate Holder" information in the second red box.
      </InformationText>
      <InformationText>
        Fill out your building manager's contact info, the certificate holder, and additional insured fields below. Once
        the COI is completed, it will be emailed to you ({email}) and your building manager. We currently expect that
        you will receive a completed COI within 48 hours.
      </InformationText>
      <form onSubmit={handleSubmit(onSubmit)} id="coiRequest">
        <Label htmlFor="addressId">
          <Required>Address</Required>
        </Label>
        <Controller
          as={
            <AddressSelect
              value={undefined}
              options={addresses.map((a) => ({ value: a.id, label: a.formatted }))}
              placeholder="Select an address..."
              onChange={() => {
                /* no-op */
              }}
            />
          }
          control={control}
          rules={{ required: 'You must select an address.' }}
          onChange={([selected]) => selected}
          state={errors.addressID ? 'error' : undefined}
          id="addressID"
          name="addressID"
        />
        <ErrorMessage errors={errors} name="addressID">
          {({ message }) => <HelpBlock>{message}</HelpBlock>}
        </ErrorMessage>
        <Label htmlFor="buildingManagerName">Building Manager Name</Label>
        <WideInput type="text" id="buildingManagerName" name="buildingManagerName" ref={register()} />
        <Label htmlFor="buildingManagerEmail">Building Manager Email</Label>
        <WideInput
          type="text"
          id="buildingManagerEmail"
          name="buildingManagerEmail"
          state={errors.buildingManagerEmail ? 'error' : undefined}
          ref={register({
            pattern: {
              message: 'Enter a valid email address.',
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            },
          })}
        />
        <ErrorMessage errors={errors} name="buildingManagerEmail">
          {({ message }) => <HelpBlock>{message}</HelpBlock>}
        </ErrorMessage>
        <Label htmlFor="tenantName">
          Tenant Name{' '}
          <Tooltip text="If this is left blank, we will use the name of the primary customer on the account.">
            <FontAwesomeIcon icon="question-circle" />
          </Tooltip>
        </Label>
        <WideInput type="text" id="tenantName" name="tenantName" ref={register()} />
        <Label htmlFor="additionalInsured">
          <Required>Description of Operations/Additional Insured</Required>{' '}
          <Tooltip text="This is the first red box on the sample COI above.">
            <FontAwesomeIcon icon="question-circle" />
          </Tooltip>
        </Label>
        <WideTextarea
          id="additionalInsured"
          name="additionalInsured"
          state={errors.additionalInsured ? 'error' : undefined}
          ref={register({ required: 'You must provide a description of operations and or additional insured.' })}
        />
        <ErrorMessage errors={errors} name="additionalInsured">
          {({ message }) => <HelpBlock>{message}</HelpBlock>}
        </ErrorMessage>
        <Label htmlFor="certificateHolder">
          <Required>Certificate Holder</Required>{' '}
          <Tooltip text="This is the second red box on the sample COI above.">
            <FontAwesomeIcon icon="question-circle" />
          </Tooltip>
        </Label>
        <WideTextarea
          id="certificateHolder"
          name="certificateHolder"
          state={errors.certificateHolder ? 'error' : undefined}
          ref={register({ required: 'You must provide a certificate holder.' })}
        />
        <ErrorMessage errors={errors} name="certificateHolder">
          {({ message }) => <HelpBlock>{message}</HelpBlock>}
        </ErrorMessage>
        <Spacer height="24px" />
        <Button kind="primary" loading={loading} disabled={loading}>
          Submit
        </Button>
      </form>
      {showModal && <Lightbox imageURL={IMAGE} onClose={() => setShowModal(false)} />}
    </Container>
  );
};
