import * as React from 'react';

const InputGroupAddon: React.FC = ({ children }) => <div className="input-group-addon">{children}</div>;

const InputGroupAppend: React.FC = ({ children }) => <div className="input-group-append">{children}</div>;

const InputGroupPrepend: React.FC = ({ children }) => <div className="input-group-prepend">{children}</div>;

const InputGroupText: React.FC = ({ children }) => <span className="input-group-text">{children}</span>;

const InputGroup: React.FC = ({ children }) => <div className="input-group">{children}</div>;

const Combined = Object.assign(InputGroup, {
  Addon: InputGroupAddon,
  Append: InputGroupAppend,
  Prepend: InputGroupPrepend,
  Text: InputGroupText,
});

export { Combined as InputGroup };
