import * as React from 'react';
import { useContext } from 'react';

import { Text } from '@shared/components/bootstrap';
import { InventoryContext } from '@shared/components/inventory_capture/inventory_context';

import { Picker as RoomCategorySelectionPicker } from '@shared/components/room_category_selection/picker';

export const HowManyRooms: React.FC = () => {
  const { data, onSelect } = useContext(InventoryContext);

  return (
    <>
      <Text tag="p" style="info">
        <strong>Let us know how many rooms we are packing up. It's that easy.</strong>
      </Text>

      <RoomCategorySelectionPicker
        complete={!!data.roomsComplete}
        selections={data.rooms}
        onChange={(rooms) => {
          onSelect({ ...data, rooms });
        }}
        onNextClick={() => onSelect({ ...data, roomsComplete: true })}
      />
    </>
  );
};
