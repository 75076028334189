import React from 'react';

import { payURL } from '@portal/config/routes';
import { useAccountNoticesQuery } from '@portal/schema';
import { AnchorButton } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';

import { PaymentPlanBanner } from './payment_plan_banner';

export const Notices: React.FC = () => {
  const { data, loading } = useAccountNoticesQuery();

  if (loading) {
    return null;
  }

  const offerID = data?.account?.approvedPaymentPlanOffer?.id;
  const delinquentBalance = data?.account?.delinquentBalance || 0;

  if (offerID) {
    return <PaymentPlanBanner offerID={offerID} />;
  }

  return (
    <>
      {delinquentBalance > 0 && (
        <AnchorButton kind="danger" block href={payURL()} className="mt-3 mb-3">
          Uh-oh! You have an outstanding balance of <Currency value={delinquentBalance} /> - Click here to pay now!
        </AnchorButton>
      )}
    </>
  );
};
