import { ClaimPaymentOptionKind } from '@portal/schema';

export const NO_ADDRESS_PAYMENT_OPTION_KINDS = [ClaimPaymentOptionKind.Credit, ClaimPaymentOptionKind.Refund];

export const PAYMENT_OPTION_DESCRIPTIONS = {
  [ClaimPaymentOptionKind.Paypal]: 'PayPal (5 days)',
  [ClaimPaymentOptionKind.Check]: 'Paper check by direct mail (30 days)',
  [ClaimPaymentOptionKind.Credit]: 'Credit (applied to your next bill)',
  [ClaimPaymentOptionKind.Refund]: 'Refund',
};
