import cn from 'classnames';
import * as React from 'react';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface IComponentProps {
  component?: React.ReactType;
}

const VISIBLE_CLASS_NAME = 'show';

export const DropdownContext = createContext<{
  visible: boolean;
  onChange(visible: boolean): void;
}>({
  visible: false,
  onChange: () => {
    /* noop */
  },
});

const Divider: React.FC = () => <div className="dropdown-divider" />;

const Item: React.FC<IComponentProps & React.HTMLProps<HTMLAnchorElement | HTMLButtonElement>> = ({
  component: Component = 'a',
  children,
  ...props
}) => (
  <Component {...props} className="dropdown-item">
    {children}
  </Component>
);

const RouterLink: React.FC<IComponentProps & LinkProps> = ({ children, ...props }) => (
  <Link {...props} className="dropdown-item">
    {children}
  </Link>
);

const Menu: React.FC<{
  alignment?: 'left' | 'right';
}> = ({ alignment, children }) => {
  const { visible } = useContext(DropdownContext);
  return (
    <div className={cn('dropdown-menu', visible && VISIBLE_CLASS_NAME, alignment && `dropdown-menu-${alignment}`)}>
      {children}
    </div>
  );
};

const Toggle: React.FC<IComponentProps & React.HTMLProps<HTMLAnchorElement | HTMLButtonElement>> = ({
  component: Component = 'button',
  children,
  ...props
}) => {
  const { visible, onChange } = useContext(DropdownContext);
  const onClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onChange(!visible);
  };
  return (
    <Component {...props} className="dropdown-toggle" onClick={onClick}>
      {children}
    </Component>
  );
};

const Dropdown: React.FC<IComponentProps> = ({ component: Component = 'div', children }) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!visible) {
      return;
    }
    const listener = (event: Event) => {
      const element = ref.current;
      const target = event.target;
      if (!element || !target || element.contains(event.target as Node)) {
        return;
      }
      setVisible(!visible);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('mouseup', listener);
    document.addEventListener('touchstart', listener);
    document.addEventListener('touchend', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('mouseup', listener);
      document.removeEventListener('touchstart', listener);
      document.removeEventListener('touchend', listener);
    };
  }, [visible]);

  return (
    <div ref={ref}>
      <DropdownContext.Provider value={{ visible, onChange: setVisible }}>
        <Component className={cn('dropdown', visible && VISIBLE_CLASS_NAME)}>{children}</Component>
      </DropdownContext.Provider>
    </div>
  );
};

const Combined = Object.assign(Dropdown, {
  Toggle,
  Divider,
  Item,
  RouterLink,
  Menu,
});

export { Combined as Dropdown };
