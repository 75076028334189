import * as React from 'react';
import { useState } from 'react';

import { useShareMutation } from '@portal/schema';

const DEFAULT_EMAILS = [undefined];

const EMAIL_REGEX = /\S+@\S+\.\S+/;

const THANKS_MESSAGE = 'Thanks for sharing Clutter!';

export const RewardsEmailForm: React.FC<{
  source: string;
  children(props: {
    saving: boolean;
    valid: boolean;
    message?: string;
    emails: Array<string | undefined>;
    onChange(emails: Array<string | undefined>): void;
  }): React.ReactNode;
}> = ({ source, children }) => {
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [emails, setEmails] = useState<Array<string | undefined>>(DEFAULT_EMAILS);
  const saveables = emails.filter((email) => !!email) as string[];
  const valid = saveables.length > 0 && saveables.every((email) => EMAIL_REGEX.test(email));

  const [share, { loading: saving }] = useShareMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!valid) {
      return;
    }

    const result = await share({
      variables: {
        source,
        emails: saveables,
      },
    });

    if (!result?.data?.share) {
      return;
    }
    setEmails(DEFAULT_EMAILS);
    setMessage(THANKS_MESSAGE);
  };

  return (
    <form onSubmit={onSubmit}>
      {children({
        saving,
        valid,
        message,
        emails,
        onChange: (values) => {
          setEmails(values);
          setMessage(undefined);
        },
      })}
    </form>
  );
};
