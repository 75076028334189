import { Text, COLORS, FontWeight } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Pluralize } from '@shared/components/helpers';
import { formatDate } from '@shared/utils/format_date';

import { PlanUpdateLink } from './plan_update_link';

const Container = styled.div`
  background-color: ${COLORS.grayBackground};
  border-radius: 4px;
  padding: 24px;
  margin-top: 24px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Title = styled(Text.Title)`
  color: ${COLORS.tealDark};
`;

const Callout = styled(Text.Callout)`
  color: ${COLORS.storm};
`;

const Emphasis = styled.span`
  font-weight: ${FontWeight.Semibold};
  color: ${COLORS.panther};
`;

export const TermCommitment: React.FC<{
  term: number;
  contractStartDate?: string;
  onboardingID?: string;
  allowPlanUpdate?: boolean;
}> = ({ term, contractStartDate, onboardingID, allowPlanUpdate }) => (
  <Container>
    <TitleContainer>
      <Title size="extraSmall">Commitment</Title>
      {allowPlanUpdate && <PlanUpdateLink container={'term_commitment'} onboardingID={onboardingID!} />}
    </TitleContainer>
    <Callout weight={FontWeight.Regular}>
      {term === 0 ? (
        <>
          You selected <Emphasis>month-to-month</Emphasis>. That means you have the flexibility to store as long as
          you'd like. And when you're ready to move-out, there's no penalty.
        </>
      ) : (
        <>
          {contractStartDate ? (
            <>
              Your <Emphasis>{term} month minimum commitment</Emphasis> started on{' '}
              <Emphasis>{formatDate(contractStartDate)}</Emphasis>.
            </>
          ) : (
            <>
              You selected the <Emphasis>{term} month minimum</Emphasis> commitment.
            </>
          )}{' '}
          After <Pluralize count={term} singular="month" plural="months" />, your account will automatically go
          month-to-month.
          <br />
          <br />
          If you decide to move-out before the {term > 1 ? `${term} months are` : `month is`} up, the{' '}
          {term > 1 ? `${term} months` : `month`} of storage must be paid first.
        </>
      )}
    </Callout>
  </Container>
);
