import { HydrateVirtualWalkthroughFlowQuery } from '@portal/schema';
import { StepProps } from '@clutter/staircase';
import { BaseAddedStepProps, BaseValues } from '@portal/components/orders/estimation/data';

export enum StepName {
  ItemInventory = 'Item Inventory',
  Photos = 'Virtual Walkthrough',
  Review = 'Review',
  Plan = 'Plan',
}

export const FLOW_STEPS_MAP: Record<string, { dataFields: string[] }> = {};

export type Values = BaseValues;

export type AddedStepProps = BaseAddedStepProps & { order: HydrateVirtualWalkthroughFlowQuery['order'] };

export type StepType = StepProps<Values, AddedStepProps>;
