import * as React from 'react';

const SINGULAR_COUNT = 1;

export const Pluralize: React.FC<{
  count: number;
  singular: string;
  plural: string;
}> = ({ count, singular, plural }) => (
  <>
    {count} {count === SINGULAR_COUNT ? singular : plural}
  </>
);
