import * as qs from 'qs';
import React, { useState } from 'react';
import { client } from '@admin/libraries/apollo';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Text, Button, InfoModal } from '@clutter/clean';
import { CONTAINER_SIDE_PADDING } from '@portal/components/shared/layout';
import { orderURL, signedOrderSignaturesURL } from '@portal/config/routes';
import { Spinner } from '@portal/components/helpers/spinner';
import { useShipmentValuationUpdateMutation, useShipmentValuationDeclarationQuery } from '@portal/schema';

import { currency } from '@shared/utils/currency';
import { CoveragePlan } from './truck_protection/coverage_plan';
import { ValueDeclaration } from './truck_protection/value_declaration';
import { ChargeTotal } from './truck_protection/charge_total';
import { formattedCost } from './truck_protection/helpers';

const QS_PARSE_OPTIONS = { ignoreQueryPrefix: true };

const Form = styled.form`
  max-width: 712px;
  padding: 0 ${16 - CONTAINER_SIDE_PADDING}px;
`;

const Section = styled(Box)`
  margin-bottom: 32px;
`;

const Heading = styled(Text.Title)`
  margin-bottom: 4px;
`;

const ModalText = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModalButton = styled.div`
  width: calc(50% - 12px);
`;

const ConfirmationModal: React.FC<{
  showingModal: boolean;
  setShowingModal(showingModal: boolean): void;
  onSubmit(): void;
  submitLoading: boolean;
  price: number;
}> = ({ showingModal, setShowingModal, onSubmit, submitLoading, price }) => (
  <InfoModal isOpen={showingModal} handleModalClose={() => setShowingModal(false)}>
    <Text.Title size="large">Confirm protection plan</Text.Title>
    <ModalText>
      <Text>
        The total cost of your In-Truck protection is <b>${price}</b>. This will be charged at the end of today's
        appointment.
      </Text>
      <br />
      <br />
      <Text>Please confirm that you want to add this protection.</Text>
    </ModalText>
    <ModalButtonContainer>
      <ModalButton>
        <Button kind="secondary" onClick={onSubmit} loading={submitLoading} fullWidth>
          Confirm
        </Button>
      </ModalButton>
      <ModalButton>
        <Button kind="destructive" onClick={() => setShowingModal(false)} fullWidth>
          Cancel
        </Button>
      </ModalButton>
    </ModalButtonContainer>
  </InfoModal>
);

export const TruckProtection: React.FC<RouteComponentProps<{ orderID: string }>> = ({
  match: {
    params: { orderID },
  },
}) => {
  const params = qs.parse(location.search, QS_PARSE_OPTIONS);
  const { order_id: signedOrderID } = params;
  const history = useHistory();

  const [declaredValue, setDeclaredValue] = useState<number | undefined>();
  const [coverageID, setCoverageID] = useState<string | undefined>();
  const [showingModal, setShowingModal] = useState<boolean>(false);

  const { data, loading } = useShipmentValuationDeclarationQuery({
    client,
    variables: { orderID },
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      if (res.declaredCoverage) {
        setDeclaredValue(res.declaredCoverage.value);
        setCoverageID(res.declaredCoverage.coverage.id);
      }
    },
  });

  const [shipmentValuationUpdateMutation, { loading: submitLoading }] = useShipmentValuationUpdateMutation({
    client,
    variables: {
      input: {
        coverageID,
        declaredValue,
        orderID,
      },
    },
  });

  const onSubmit = async () => {
    await shipmentValuationUpdateMutation();
    if (signedOrderID) {
      history.push(signedOrderSignaturesURL() + `?order_id=${signedOrderID}`);
    } else {
      history.push(orderURL(orderID, 'signatures'));
    }
  };

  if (loading || !data) {
    return <Spinner />;
  }

  const selectedCoverage = data.shipmentValuationCoverages.find(
    (valuationCoverage) => valuationCoverage.id === coverageID,
  );

  const chargeTotal = (price: number, value: number) => {
    if (value) {
      return Number(((price / 100) * value).toFixed(2));
    } else {
      return 0.0;
    }
  };

  const onClick = () => {
    if (selectedCoverage && chargeTotal(selectedCoverage.price, declaredValue || 0) > 0) {
      setShowingModal(true);
    } else if (selectedCoverage) {
      onSubmit();
    }
  };

  return (
    <Form>
      <Section>
        <Heading size="large">In-Truck Protection</Heading>
        <Text.Body>
          In-Truck Protection covers your items while in transit. Any previously selected Clutter Protection Plan covers
          your items while in storage.
        </Text.Body>
      </Section>

      <Section>
        <Heading size="medium">1. Select a coverage plan</Heading>
        <hr />
        <CoveragePlan
          setCoverage={setCoverageID}
          currentSelection={coverageID}
          allCoverages={data.shipmentValuationCoverages}
          loading={loading}
        />
      </Section>

      {selectedCoverage && selectedCoverage.price !== 0 && (
        <Section>
          <Heading size="medium">2. Declare the total value of your items</Heading>
          <Text.Body>
            The declared value of your items will dictate how much insurance is for this trip. If you declare a high
            value for your items, the cost of insurance will also be high.{' '}
            {selectedCoverage.minimumDeclaredValue > 0 &&
              `The minimum declared value allowed is ${currency(selectedCoverage.minimumDeclaredValue)}.`}{' '}
            {selectedCoverage.maximumDeclaredValue &&
              `The maximum declared value allowed is ${currency(selectedCoverage.maximumDeclaredValue)}.`}
          </Text.Body>
          <ValueDeclaration
            onChange={setDeclaredValue}
            declaredValue={declaredValue}
            minimumAllowedDeclaredValue={selectedCoverage.minimumDeclaredValue}
            maximumAllowedDeclaredValue={
              selectedCoverage.maximumDeclaredValue ? selectedCoverage.maximumDeclaredValue : undefined
            }
          />
          <hr />
        </Section>
      )}

      <Section>
        <Heading size="medium">Your total</Heading>
        {selectedCoverage && selectedCoverage.price > 0 && (
          <Text.Body>
            Your total cost is calculated as {formattedCost(selectedCoverage.price)} of your declared value.
          </Text.Body>
        )}
        <ChargeTotal
          selectedCoverage={selectedCoverage}
          loading={loading}
          chargeTotal={selectedCoverage && chargeTotal(selectedCoverage.price, declaredValue || 0)}
        />
      </Section>

      <Button type="button" onClick={onClick} loading={submitLoading} size="small" fullWidth>
        Add Protection
      </Button>

      {selectedCoverage && selectedCoverage.price !== 0 && (
        <ConfirmationModal
          showingModal={showingModal}
          setShowingModal={setShowingModal}
          onSubmit={onSubmit}
          submitLoading={submitLoading}
          price={chargeTotal(selectedCoverage.price, declaredValue || 0)}
        />
      )}
    </Form>
  );
};
