import { useState } from 'react';

import { Adapter } from './adapter';

const PREPAID_ERROR = 'Prepaid cards are not accepted at this time. Please add a debit or credit card instead.';

interface IConfig {
  allow?: 'prepaid';
}

export const useAdapter = (token: string, options?: IStripeOptions) => {
  const [adapter] = useState(() => new Adapter(token, options));
  return adapter;
};

export const useElement = (adapter: Adapter, type: 'card', options?: IStripeElementOptions) => {
  const [element] = useState(() => adapter.element(type, options));
  return element;
};

export const useTokenize = (adapter: Adapter, element: IStripeElement, config: IConfig = {}) => {
  const [error, setError] = useState<string | undefined>();
  const [tokenizing, setTokenizing] = useState<boolean>(false);

  const tokenize = async (options?: IStripeTokenOptions) => {
    if (tokenizing) {
      return;
    }
    setError(undefined);
    setTokenizing(true);
    const result = await adapter.tokenize(element, options);
    setTokenizing(false);
    if (result.error) {
      setError(result.error.message);
      return;
    }
    if (!result.token) {
      return;
    }
    if (config.allow !== 'prepaid' && result.token.card?.funding === 'prepaid') {
      setError(PREPAID_ERROR);
      return;
    }
    return result.token;
  };

  return { error, tokenizing, tokenize };
};
