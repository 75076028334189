import { AriaAttributes, SVGAriaEnhancements } from '@shared/components/helpers/svg_aria_enhancements';
import React, { FC } from 'react';

export const Mail: FC<AriaAttributes> = ({ title, description: desc }) => {
  const Aria = SVGAriaEnhancements(title, desc);

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={Aria.ariaLabelledby}
    >
      <Aria.Title />
      <Aria.Desc />
      <g clipPath="url(#clip0_22_1551)">
        <path
          d="M4.47058 0.235291H28.9412C29.9765 0.235291 30.8235 1.08235 30.8235 2.11764V37.8824C30.8235 38.9176 29.9765 39.7647 28.9412 39.7647H4.47058C3.43529 39.7647 2.58823 38.9176 2.58823 37.8824V2.11764C2.58823 1.08235 3.43529 0.235291 4.47058 0.235291Z"
          fill="#FFEABF"
          stroke="#FFEABF"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.4118 39.7647C20.3765 39.7647 19.5294 38.9176 19.5294 37.8823V2.11764C19.5294 1.08235 20.3765 0.235291 21.4118 0.235291"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.5529 1.8353L26.6353 5.45883C25.8823 6.4 24.4235 6.4 23.7176 5.45883L20.847 1.8353"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.1765 6.1647V39.7647"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.7647 39.7647H0.235291"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5821 4.04932L5.15659 4.9689L6.38269 14.8696L13.8082 13.95L12.5821 4.04932Z"
          fill="white"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.05881 7.34118C7.90587 7.24706 8.75292 7.10588 9.59998 7.01176"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.10587 9.08235C8.56469 8.84705 9.97645 8.5647 11.4353 8.32941"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.34119 11.7176C7.62354 11.5294 7.90589 11.2941 8.14119 11.0118C8.2353 10.9176 8.28236 10.6823 8.14119 10.6823C7.76472 10.7294 7.4353 11.0118 7.34119 11.3882C7.95295 11.3412 8.61177 11.1529 9.17648 10.8706C8.84707 11.0118 8.56472 11.2941 8.32942 11.5765C8.56472 11.5294 8.80001 11.4823 8.98824 11.3882C10.0235 11.0588 11.0588 10.7294 12.0471 10.4471"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3647 14.4L5.9294 15.3412L4.70587 5.45882"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.7412 15.4824C14.8847 15.4824 15.8118 14.5553 15.8118 13.4118C15.8118 12.2682 14.8847 11.3412 13.7412 11.3412C12.5976 11.3412 11.6706 12.2682 11.6706 13.4118C11.6706 14.5553 12.5976 15.4824 13.7412 15.4824Z"
          fill="#F79C94"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8118 13.8824C15.8118 15.0118 14.8706 15.9529 13.7412 15.9529C12.6118 15.9529 11.6706 15.0118 11.6706 13.8824"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.0588 22.3529H9.64706L12 39.2941H37.4118L35.0588 22.3529Z"
          fill="white"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.4588 33.2706L27.5765 31.7647C27.0118 31.2941 26.3059 31.0588 25.6 31.0588H21.553C20.8471 31.0588 20.2353 31.2941 19.7647 31.7647L18.3059 33.2706"
          fill="white"
        />
        <path
          d="M29.4588 33.2706L27.5765 31.7647C27.0118 31.2941 26.3059 31.0588 25.6 31.0588H21.553C20.8471 31.0588 20.2353 31.2941 19.7647 31.7647L18.3059 33.2706"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.2941 23.9059L20.6588 31.2C20.9412 31.1059 21.2235 31.0588 21.553 31.0588H25.6C25.9294 31.0588 26.2118 31.1059 26.5412 31.2L33.8824 23.9059"
          fill="white"
        />
        <path
          d="M11.2941 23.9059L20.6588 31.2C20.9412 31.1059 21.2235 31.0588 21.553 31.0588H25.6C25.9294 31.0588 26.2118 31.1059 26.5412 31.2L33.8824 23.9059"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.2353 25.2706C26.3529 25.6 24.5176 25.9294 22.6353 26.2588L22.8706 26.0706C23.2941 25.7882 23.0588 25.0353 22.4941 25.2235C22.1176 25.3176 21.7412 25.4588 21.4118 25.5529C21.5529 25.4118 21.6941 25.2235 21.6 25.0353C21.3647 24.4235 20.7529 24.4706 20.2823 24.7529C19.9059 24.9882 19.2941 25.3647 19.0588 25.8353C18.8706 25.9294 18.6823 26.0235 18.4471 26.1176C17.8823 26.3529 18.1176 27.2471 18.6823 27.0118C18.8706 26.9176 19.0588 26.8706 19.2471 26.7765C19.5294 26.9176 19.9059 26.9176 20.1882 26.8706C20.2353 26.8706 20.2353 26.8706 20.2823 26.8706C20.0471 27.2 20.3294 27.6706 20.8 27.5765C23.3412 27.1059 25.8353 26.6823 28.3765 26.2118C29.0824 26.0706 28.8471 25.1765 28.2353 25.2706Z"
          fill="#F79C94"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_1551">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
