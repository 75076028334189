import { Boxes, MoverWithCartFridge } from '@portal/components/shared/icons';
import { Pricing__RateGroup__OrderService as RateGroupService } from '@portal/schema';

type ServiceMapping = {
  [key in RateGroupService]?: {
    icon: React.FC;
    description: string;
  };
};

export const ServiceInfo: ServiceMapping = {
  [RateGroupService.FullService]: {
    icon: MoverWithCartFridge,
    description: 'We’ll pack, pick up, & move your items into our smart warehouse.',
  },
  [RateGroupService.CurbsidePickup]: {
    icon: Boxes,
    description: 'We’ll pick up your items & move them into our smart warehouse.',
  },
};

export const supportedServiceType = (serviceType?: RateGroupService) => !!serviceType && !!ServiceInfo[serviceType];
