import { ReviewWizard, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import {
  AccountResolution__OfferFragment,
  AccountResolution__OfferKind,
  Rating,
  Review__ReviewableEnum,
  useBuildReviewMutation,
} from '@portal/schema';

import { Accepted as FinalMonthProrationAccepted } from './final_month_proration/accepted';
import { Accepted as WaiveRateAdjustmentAccepted } from './waive_rate_adjustment/accepted';

const REVIEW_KIND = 'csat:automated_resolution';

const Title = styled(Text.Title)`
  padding-top: 16px;
  margin-bottom: 24px;
`;

const Content = styled.div`
  margin-bottom: 28px;
`;

const REVIEW_PROMPT = {
  GOOD: {
    answer: 'Yes',
  },
  POOR: {
    answer: 'No',
    followup: {
      title: 'What can we improve?',
      tags: ['Not satisfied with offer', "Didn't solve my entire issue", 'I would prefer to talk to someone'],
    },
  },
};

export const Accepted: React.FC<{ offer: AccountResolution__OfferFragment; customerTicketID?: string }> = ({
  offer,
  customerTicketID,
}) => {
  const [buildReview] = useBuildReviewMutation();

  return (
    <>
      <Title size="large">Offer accepted!</Title>
      <Content>
        {offer.kind === AccountResolution__OfferKind.FinalMonthProration && (
          <FinalMonthProrationAccepted offer={offer} />
        )}
        {offer.kind === AccountResolution__OfferKind.WaiveRateAdjustment && (
          <WaiveRateAdjustmentAccepted offer={offer} />
        )}
      </Content>
      {customerTicketID && (
        <ReviewWizard
          question={'Did this resolve your issue?'}
          prompts={REVIEW_PROMPT}
          onSubmit={async ({ rating, comments, reasons }) => {
            await buildReview({
              variables: {
                input: {
                  reviewable: { id: customerTicketID, type: Review__ReviewableEnum.CustomerTicket },
                  comments,
                  rating: rating as Rating,
                  reasons: JSON.stringify(reasons),
                  kind: REVIEW_KIND,
                },
              },
            });
          }}
        />
      )}
    </>
  );
};
