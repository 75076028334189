import { LngLat } from 'mapbox-gl';
import * as React from 'react';

import { Mapbox } from './mapbox';
import { Marker } from './marker';

export const LocationPicker: React.FC<{
  latitude: number;
  longitude: number;
  zoom?: number;
  style?: string;
  height?: number;
  onChange: (lngLat: LngLat) => void;
}> = ({ latitude, longitude, onChange, ...props }) => (
  <Mapbox {...props} latitude={latitude} longitude={longitude}>
    <Marker onChange={onChange} latitude={latitude} longitude={longitude} draggable />
  </Mapbox>
);
