import { Fieldset } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Accordion } from '@clutter/clean';
import { wt } from '@portal/initializers/wt';
import { PricingSetPriceEntryFragment, StoragePlanFragment } from '@portal/schema';

import { AccordionLabel } from '../accordion_label';
import { Option } from './option';
import {
  accordionMetadata,
  PLAN_UPDATE_PAGE_NAME,
  planSizeMetadata,
  storageChanged,
  storageTitle,
  storageValue,
} from '../util';

const OptionsContainer = styled(Fieldset)`
  display: flex;
  flex-wrap: wrap;
  padding: 0 24px 24px 24px;
`;

export const Selector: React.FC<{
  orderID: string;
  loading: boolean;
  initialPriceEntry?: PricingSetPriceEntryFragment;
  selectedPriceEntry?: PricingSetPriceEntryFragment;
  options: PricingSetPriceEntryFragment[];
  setSelectedPriceEntry(entry: PricingSetPriceEntryFragment): void;
}> = ({ orderID, loading, initialPriceEntry, selectedPriceEntry, options, setSelectedPriceEntry }) => {
  const sortedOptions = options.sort(
    (a, b) => (a.price.plan as StoragePlanFragment).cuft - (b.price.plan as StoragePlanFragment).cuft,
  );

  const onToggle = (isOpen: boolean) => {
    const params = {
      ...accordionMetadata(isOpen),
      container: 'plan_size_accordion',
      order_id: orderID,
    };
    wt.track(params);
  };

  const onChange = (currentPriceEntry: PricingSetPriceEntryFragment, label: string) => {
    const params = {
      container: 'plan_size_accordion',
      previous_selection: planSizeMetadata(selectedPriceEntry),
      current_selection: planSizeMetadata(currentPriceEntry),
      action: 'click',
      label: label,
      pageName: PLAN_UPDATE_PAGE_NAME,
      objectType: 'input:button',
      objectName: 'plan_size_tile',
      order_id: orderID,
    };
    wt.track(params);
    setSelectedPriceEntry(currentPriceEntry);
  };

  return (
    <Accordion.Item
      name="storage"
      label={
        <AccordionLabel
          loading={loading}
          title={storageTitle(selectedPriceEntry)}
          value={storageValue(selectedPriceEntry)}
          changed={storageChanged(selectedPriceEntry, initialPriceEntry)}
        />
      }
      onToggle={onToggle}
    >
      <OptionsContainer>
        {sortedOptions.map((option) => (
          <Option
            key={option.id}
            option={option}
            selected={selectedPriceEntry?.price?.plan?.name === option.price.plan.name}
            onChange={onChange}
          />
        ))}
      </OptionsContainer>
    </Accordion.Item>
  );
};
