import React, { useState } from 'react';

import { TrackedClick } from '@portal/components/wt/tracked_click';
import { Spacer } from '@shared/components/helpers';
import { OrderStatusEnum } from '@portal/schema';
import { InfoTooltip } from '@portal/components/orders/home/appointment_details/info_list/info_tooltip';
import { Box } from '@clutter/clean';
import { AppointmentDetailsOrder } from '../full_panel';
import { ActionButton, LineItem } from '../../line_item';
import { wtLinkParams } from './constants';
import { AddPromoCodeTextField } from './add_promo_code_text_field';

export const PromoCodeLineItem: React.FC<{ order: AppointmentDetailsOrder }> = ({ order }) => {
  const [showTextField, setShowTextField] = useState<boolean>(false);
  const coupon = order.coupon || order.account.coupon;
  const canAddCoupon = order.status === OrderStatusEnum.Scheduled;

  if (!coupon && !canAddCoupon) return null;

  return (
    <>
      <LineItem
        label={<div>Coupon</div>}
        action={
          !coupon &&
          canAddCoupon &&
          !showTextField && (
            <TrackedClick
              params={{
                text: 'Add',
                trackingParams: {
                  ...wtLinkParams,
                  order_id: order.id,
                  label: 'Add',
                  objectName: 'add_coupon_button',
                },
              }}
            >
              <ActionButton onClick={() => setShowTextField(true)}>Add</ActionButton>
            </TrackedClick>
          )
        }
        description={
          showTextField && (
            <>
              <Spacer height="16px" />
              <AddPromoCodeTextField
                orderID={order.id}
                onCancel={() => setShowTextField(false)}
                onCouponApplied={() => {
                  setShowTextField(false);
                }}
              />
            </>
          )
        }
      >
        {coupon?.discountDescription ?? 'No promo code applied'}
        {coupon?.discountDisclaimer && (
          <Box textAlign="left">
            <InfoTooltip text={coupon.discountDisclaimer} />
          </Box>
        )}
      </LineItem>
    </>
  );
};
