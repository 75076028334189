import { COLORS, FontWeight, Grid, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React from 'react';

import { Label, LabelKind } from '@portal/components/shared/label';

export enum StatusText {
  Paid = 'Paid',
  Refund = 'Refund',
  Failed = 'Failed',
}

export interface IHistoryLine {
  title: string;
  subtitle: string;
  date: DateTime;
  status: StatusText;
  sourceBrand: string | null | undefined;
  icon(): React.ReactElement;
}

const Card = styled.div`
  padding: 24px 0;
  :not(:last-child) {
    border-bottom: 1px solid ${COLORS.grayBorder};
  }
`;

const Container = styled(Grid)`
  margin: 0;
  align-items: flex-start;
  justify-content: space-between;
`;

const Info = styled(Grid)`
  align-items: center;
`;

const Icon = styled.div`
  margin: 0 16px 0 8px;
`;

const PlaceholderIcon = styled.div`
  margin: 0 0 0 8px;
`;

const Title = styled(Text.Callout)`
  color: ${COLORS.panther};
`;

const Subtitle = styled(Text.Caption)`
  color: ${COLORS.hippo};
`;

const labelKind = (status: StatusText) => {
  switch (status) {
    case StatusText.Paid:
      return LabelKind.Primary;
    case StatusText.Refund:
      return LabelKind.Default;
    case StatusText.Failed:
      return LabelKind.Danger;
    default:
      return LabelKind.Default;
  }
};

export const Line: React.FC<{ line: IHistoryLine }> = ({ line }) => (
  <Card>
    <Container>
      <Info>
        {line.sourceBrand ? (
          <Icon>
            <line.icon />
          </Icon>
        ) : (
          <PlaceholderIcon />
        )}
        <div>
          <Title weight={FontWeight.Medium}>{line.title}</Title>
          <Subtitle>{line.subtitle}</Subtitle>
        </div>
      </Info>
      <Label kind={labelKind(line.status)}>{line.status}</Label>
    </Container>
  </Card>
);
