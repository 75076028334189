import React, { forwardRef } from 'react';

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const DecimalInput = forwardRef<HTMLInputElement, { places?: number } & InputProps>(
  ({ places = 2, onChange, onBlur, ...props }, ref) => (
    <input
      className="form-control"
      {...props}
      ref={ref}
      onChange={(event) => {
        if (event.target.value !== undefined && event.target.value !== '') {
          const numberParts = event.target.value.split('.');
          if (numberParts[1] && numberParts[1].length > places) {
            event.target.value = String(Number(event.target.value).toFixed(places));
          }
        }

        if (onChange) {
          onChange(event);
        }
      }}
      onBlur={(event) => {
        if (event.target.value !== undefined && event.target.value !== '') {
          event.target.value = String(Number(event.target.value).toFixed(places));
        }

        if (onBlur) {
          onBlur(event);
        }
      }}
      type="number"
    />
  ),
);
