import { COLORS, FontWeight, Radio, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { PricingSetPriceEntryFragment, StoragePlanFragment } from '@portal/schema';
import { storageName } from '@portal/utils/plan';

const Card = styled.div<{ selected: boolean }>`
  width: 156px;
  height: 60px;
  border: 2px solid ${COLORS.tealPrimary};
  border-radius: 4px;
  color: ${({ selected }) => (selected ? COLORS.cloud : COLORS.tealPrimary)};
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
  background: ${({ selected }) => (selected ? COLORS.tealPrimary : COLORS.cloud)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Label = styled.label`
  display: block;
  margin: 0 7.5px 7.5px 0;
  text-align: center;
`;

const Title = styled(Text.Button)`
  font-weight: ${FontWeight.Bold};
`;

const PLAN_DESCRIPTIONS: Record<string, string> = {
  '5x5': 'Small closet size',
  '5x10': 'Walk-in closet size',
  '10x10': 'Garage size',
  '10x15': 'For studio apartment',
  '10x20': 'For a 1 bedroom home',
  '10x25': 'For a 2 bedroom home',
  '10x30': 'For a 3 bedroom home',
  '10x40': 'For a 4 bedroom home',
  '10x50': 'For a 5 bedroom home',
};

export const Option: React.FC<{
  option: PricingSetPriceEntryFragment;
  selected: boolean;
  onChange(option: PricingSetPriceEntryFragment, label: string): void;
}> = ({ option, selected, onChange }) => {
  const description = PLAN_DESCRIPTIONS[option.price.plan.name];
  if (!description) {
    return null;
  }

  const title = storageName(option.price.plan as StoragePlanFragment);
  const label = `${title} ${description}`;

  return (
    <Label htmlFor={option.id}>
      <Radio.Input
        id={option.id}
        checked={selected}
        value={option.price.plan.name}
        onChange={() => onChange(option, label)}
      />
      <Card selected={selected}>
        <div>
          <Title>{title}</Title>
          <Text.Caption weight={FontWeight.Medium}>{description}</Text.Caption>
        </div>
      </Card>
    </Label>
  );
};
