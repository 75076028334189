import { IIconProps } from '@portal/components/shared/icons/props';
import React from 'react';
import { snakeCase } from 'lodash';

export const LivingRoom: React.FC<IIconProps> = ({ title, width = 98, height = 65 }) => (
  <svg
    aria-hidden={!title}
    aria-labelledby={title && `${snakeCase(title)}-svg`}
    width={width}
    height={height}
    viewBox="0 0 98 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title id={`${snakeCase(title)}-svg`}>{title}</title>}
    <path
      d="M89.3549 28.2469C89.3549 27.3642 88.6327 26.642 87.75 26.642H29.4105C28.5278 26.642 27.8055 27.3642 27.8055 28.2469V36.6728H89.3148V28.2469H89.3549Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.0679 50.716H95.0926C95.9753 50.716 96.6975 49.9938 96.6975 49.1111V46.3024C96.6975 45.4197 95.9753 44.6975 95.0926 44.6975H22.0679C21.1852 44.6975 20.463 45.4197 20.463 46.3024V49.1111C20.463 49.9938 21.1852 50.716 22.0679 50.716Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M96.6975 56.7346L92.6852 50.7161H24.4753L20.463 56.7346"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M96.6975 56.7345H20.463L24.4753 52.7222H92.6852L96.6975 56.7345Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M87.7901 31.8981L86.6667 36.6728H30.4938L29.3704 31.8981C29.2099 31.1759 28.5679 30.6543 27.8055 30.6543H22.0679C21.1852 30.6543 20.463 31.3765 20.463 32.2592V43.0926C20.463 43.9753 21.1852 44.6975 22.0679 44.6975H95.0926C95.9753 44.6975 96.6975 43.9753 96.6975 43.0926V32.2592C96.6975 31.3765 95.9753 30.6543 95.0926 30.6543H89.3549C88.5926 30.6543 87.9506 31.1759 87.7901 31.8981Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.4753 52.7222L25.8796 50.7161"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.6852 52.7222L91.0802 50.7161"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M89.3549 30.6543C88.5926 30.6543 87.9506 31.1759 87.7901 31.8981L86.6666 36.6728H30.4938L29.3704 31.8981C29.2099 31.1759 28.5679 30.6543 27.8055 30.6543"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.2407 37.6759L24.7963 31.858C24.6358 31.1358 23.9938 30.6543 23.2315 30.6543H22.4691"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M90.9197 37.6759L92.3642 31.858C92.5247 31.1358 93.1667 30.6543 93.929 30.6543H94.6913"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M86.1852 36.6729H30.9753C30.6543 36.6729 30.3735 36.7531 30.0926 36.8735L27.6049 38.1173C26.9228 38.4383 26.4815 39.1605 26.4815 39.9228V42.6914C26.4815 43.8148 27.3642 44.6975 28.4876 44.6975H88.6728C89.7963 44.6975 90.679 43.8148 90.679 42.6914V39.9228C90.679 39.1605 90.2376 38.4784 89.5555 38.1173L87.0679 36.8735C86.8272 36.7531 86.5062 36.6729 86.1852 36.6729Z"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.0679 44.6975H95.0926"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.8858 39.1204H89.2747"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.5864 36.6729H60.1852C59.3025 36.6729 58.5802 37.3951 58.5802 38.2778V43.0926C58.5802 43.9753 59.3025 44.6975 60.1852 44.6975H60.5864"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.5741 44.6975H56.9753C57.858 44.6975 58.5802 43.9753 58.5802 43.0926V38.2778C58.5802 37.3951 57.858 36.6729 56.9753 36.6729H56.5741"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.5802 44.6975V26.642"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.5802 64.5989C74.108 64.5989 86.6666 63.5155 86.6666 62.1915C86.6666 60.8674 74.108 59.7841 58.5802 59.7841C43.0524 59.7841 30.4938 60.8674 30.4938 62.1915C30.4938 63.5155 43.0524 64.5989 58.5802 64.5989Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78.8827 54.2871L72.6234 64.318L47.0247 54.2871L39.8426 64.318L41.4074 60.0649L48.0679 50.7562L71.8611 60.105L77.679 50.7562L78.8827 54.2871Z"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.5802 48.0278C43.0524 48.0278 30.4938 48.9908 30.4938 50.1544V52.1606C30.4938 53.3241 43.0524 54.2871 58.5802 54.2871C74.108 54.2871 86.6666 53.3241 86.6666 52.1606V50.1544C86.6666 48.9908 74.108 48.0278 58.5802 48.0278Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.4938 50.1543V52.1605C30.4938 53.0031 36.8333 53.7253 46.1018 54.0463V48.2284C36.8333 48.5895 30.4938 49.3117 30.4938 50.1543Z"
      fill="#FFEABF"
      stroke="#FFEABF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M86.6666 50.1544C86.6666 51.318 74.108 52.281 58.5802 52.281C43.0524 52.281 30.4938 51.318 30.4938 50.1544"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.0308 60.4661L72.6234 64.3179L61.8302 60.0648"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.6111 60.426L39.8426 64.318L41.2469 60.5464"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.90738 54.9291L7.94442 50.9167L0.88269 54.9291H8.90738Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.90738 55.7315C13.321 55.7315 16.9321 55.3704 16.9321 54.9291C16.9321 54.4877 13.321 54.1266 8.90738 54.1266C4.4938 54.1266 0.88269 54.4877 0.88269 54.9291C0.88269 55.3704 4.45368 55.7315 8.90738 55.7315Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.9691 2.76855L14.8055 6.94139C3.89196 10.1513 -2.16668 21.747 1.44443 32.5402L8.90739 54.9291H0.882703"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.9938 8.78711H20.9444V20.8241H36.9938V8.78711Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9444 20.8241H36.9938"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.8672 2.40742L68.6111 0.401245L63.3549 2.40742"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.6543 2.40747H54.5679V20.463H82.6543V2.40747Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.6543 20.463H54.5679"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M75.9135 18.2963H80.6481V8.66663L74.429 10.2314L75.9135 18.2963Z" fill="#A1E6E0" />
    <path d="M56.574 14.5247V18.4568L75.9135 18.2963L74.429 10.2314L56.574 14.5247Z" fill="white" />
    <path d="M56.574 14.5247L74.429 10.2315L73.3456 4.29321L56.574 4.41358V14.5247Z" fill="#FFEABF" />
    <path d="M80.6481 8.66663V4.25305L73.3456 4.29317L74.429 10.2314L80.6481 8.66663Z" fill="#00404D" />
    <path
      d="M80.6481 18.2963V4.25305L56.574 4.41355V18.4568"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
