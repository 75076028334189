import React from 'react';
import { Box } from '@clutter/clean';
import { Link as ReactLink, LinkProps as ReactLinkProps } from 'react-router-dom';
import { Tooltip } from '@shared/components/bootstrap';

import { ConditionalWrapper, L1_AGENT_CUSTOMER_ONLY_ERROR_MESSAGE, BLOCK_ACTION } from './util';

export const Link: React.FC<ReactLinkProps> = ({ children, ...props }) => {
  const containerClasses = props.className
    ?.split(' ')
    .filter((c) => !c.startsWith('btn'))
    .join(' ');

  return (
    <ConditionalWrapper
      condition={BLOCK_ACTION}
      wrapper={(c) => (
        <Box className={containerClasses}>
          <Tooltip title={L1_AGENT_CUSTOMER_ONLY_ERROR_MESSAGE}>{c}</Tooltip>
        </Box>
      )}
    >
      <ReactLink
        {...props}
        onClick={(e) => {
          if (BLOCK_ACTION) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        to={BLOCK_ACTION ? '' : props.to}
      >
        {children}
      </ReactLink>
    </ConditionalWrapper>
  );
};
