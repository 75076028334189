import React, { useState } from 'react';

import { Message as ErrorMessage } from '@portal/components/shared/error';
import { Button } from '@portal/components/shared/customer_protected/button';
import { Selector as SourcesSelector } from '@portal/components/sources/selector';
import { Billing__InvoiceFragment, Status, useResolveDelinquencyMutation } from '@portal/schema';
import { currency } from '@shared/utils/currency';

import { InvoiceList } from './invoice_list';
import { SuccessModal } from '../success_modal';
import { Totals } from '../totals';

import { Container, SubmitContainer, SourceContainer, MainTitle, Heading, Divider } from '../sections';

const sumInvoiceAmountDue = (invoices: Billing__InvoiceFragment[]) =>
  invoices.reduce((sum, invoice) => sum + (invoice?.amountDue || 0), 0);

export const PayInvoiceForm: React.FC<{
  invoices: Billing__InvoiceFragment[];
}> = ({ invoices }) => {
  const [selectedSourceID, setSelectedSourceID] = useState<string>();
  const [invoiceSelections, setInvoiceSelections] = useState<{ [key: string]: boolean }>({});
  const [paymentSuccessful, setPaymentSuccessful] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const delinquentBalance = sumInvoiceAmountDue(invoices);
  const selectedInvoices = invoices.filter(({ id }) => invoiceSelections[id]);
  const paymentAmount = sumInvoiceAmountDue(selectedInvoices);
  const remainingBalance = delinquentBalance - paymentAmount;

  const [resolve, { loading: resolving }] = useResolveDelinquencyMutation();

  const onClick = async () => {
    const response = await resolve({
      variables: { sourceID: selectedSourceID!, invoiceIDs: selectedInvoices.map((i) => i.id) },
    });
    if (response.data?.resolveDelinquency?.status === Status.Unprocessable) {
      setError(response.data.resolveDelinquency.error ?? '');
    } else if (response.data?.resolveDelinquency?.status === Status.Ok) {
      setError('');
      setPaymentSuccessful(true);
    }
  };

  return (
    <>
      <Container>
        <MainTitle size="large">Paying your invoices</MainTitle>
        <Heading size="small">1. Select Invoices</Heading>
        <InvoiceList
          disabled={resolving}
          invoices={invoices}
          invoiceSelections={invoiceSelections}
          setInvoiceSelections={setInvoiceSelections}
        />
        <Divider />
        <Heading size="small">2. Confirm Payment Method</Heading>
        <SourceContainer>
          <SourcesSelector
            useButtonStyle={true}
            showDefaultCheckbox={false}
            disabled={resolving}
            canAddPrepaid={true}
            selectedSourceID={selectedSourceID}
            setSelectedSourceID={setSelectedSourceID}
          />
        </SourceContainer>
        <Divider />
        <Heading size="small">3. Review and Pay</Heading>
        <Totals
          delinquentBalance={delinquentBalance}
          paymentAmount={paymentAmount}
          remainingBalance={remainingBalance}
        />
        <SubmitContainer>
          {error && <ErrorMessage message={error} />}
          <Button
            kind="primary"
            disabled={!paymentAmount || !selectedSourceID || resolving}
            loading={resolving}
            fullWidth
            onClick={onClick}
            onActionBlocked={(errorMessage) => setError(errorMessage)}
          >
            Pay {currency(paymentAmount)} Now
          </Button>
        </SubmitContainer>
      </Container>
      {paymentSuccessful && <SuccessModal />}
    </>
  );
};
