import React from 'react';

import { Theme } from '@portal/components/helpers/theme';

import { Billing as BillingIndex } from './billing/index';

export const Billing: React.FC = () => (
  <Theme name="default">
    <BillingIndex />
  </Theme>
);
