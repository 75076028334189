import styled from '@emotion/styled';
import { Button, Text } from '@clutter/clean';
import React from 'react';

import { Calendar } from '@portal/components/shared/icons';
import { orderURL } from '@portal/config/routes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

const ImageContainer = styled.div`
  padding-bottom: 24px;
`;

const Callout = styled(Text.Callout)`
  text-align: center;
  padding-bottom: 24px;
`;

const ButtonLink = styled.a`
  display: block;
  width: 100%;
  max-width: 400px;
  &:hover {
    text-decoration: none;
  }
`;

export const Expired: React.FC<{ orderID: string }> = ({ orderID }) => (
  <Container>
    <ImageContainer>
      <Calendar width={120} height={100} viewBox={'0 0 68 65'} />
    </ImageContainer>
    <Callout>
      All offers have been claimed.
      <br />
      You may still reschedule if you would like but no discounts will be provided.
    </Callout>
    <ButtonLink href={orderURL(orderID, 'reschedule')}>
      <Button kind="secondary" fullWidth>
        Reschedule
      </Button>
    </ButtonLink>
  </Container>
);
