import { displayableSource } from '@portal/components/helpers';
import { Billing__Source as Source } from '@portal/schema';
import { Box, COLORS, FontWeight, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { currency } from '@shared/utils';
import React from 'react';

const LineBox = styled(Box.Flex)`
  &:last-child {
    padding-bottom: 0;
  }
`;

const LineItem = styled(Text.Callout)<{ refund?: boolean }>`
  color: ${({ refund }) => (refund ? COLORS.toucan : COLORS.panther)};
`;

type DisplaySource = Pick<Source, 'id' | 'brand' | 'number'>;

const ReviewItem: React.FC<{ amount: number; refund?: boolean; source?: DisplaySource }> = ({
  amount,
  source,
  refund,
}) => (
  <LineBox justifyContent="space-between" padding="0 0 12px 0">
    <LineItem weight={FontWeight.Medium} refund={refund}>
      {refund ? 'Refund' : 'Charge'} {source && `to ${displayableSource(source)}`}
    </LineItem>
    <LineItem weight={FontWeight.Medium} refund={refund}>
      {currency(amount)}
    </LineItem>
  </LineBox>
);

export const ReviewBox: React.FC<{
  amount: number;
  chargeSource?: DisplaySource;
  refundSource: DisplaySource;
}> = ({ amount, chargeSource, refundSource }) => (
  <Box background={`${COLORS.grayBackground}`} borderRadius="4px">
    <Box padding="16px">
      <ReviewItem source={chargeSource} amount={amount} />
      <ReviewItem refund source={refundSource} amount={amount} />
    </Box>
  </Box>
);
