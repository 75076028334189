import React from 'react';

import { Banner } from '@portal/components/shared/banner';
import WhatsInTheBox from '@portal/images/whats_in_the_box.svg';
import { Text, FontWeight } from '@clutter/clean';
import { Spacer } from '@shared/components/helpers';

export const MakeSpaceBanner: React.FC<{}> = () => (
  <>
    <Banner
      icon={<img src={WhatsInTheBox}></img>}
      text={
        <Text.Callout weight={FontWeight.Medium}>Your MakeSpace account is now transitioned to Clutter!</Text.Callout>
      }
    ></Banner>
    <Spacer height={'2rem'} />
  </>
);
