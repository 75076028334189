import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';

import { ClaimPaymentContext } from '@portal/contexts/claim_payment';
import { InputGroup } from '@shared/components/bootstrap';
import {
  GoogleMapsPlacesAutocompleteInput,
  IGoogleMapsPlace,
} from '@shared/components/helpers/google_maps_places_autocomplete_input';

const INCOMPLETE_GOOGLE_MAPS_PLACE_MESSAGE = 'You must select a valid address from the dropdown.';

export const ContactDetails = () => {
  const { data, onSelect } = useContext(ClaimPaymentContext);
  const [error, setError] = useState<string | undefined>(undefined);

  const onAutocomplete = (place: IGoogleMapsPlace) => {
    const { latitude, longitude, street, city, state, zip, country } = place;

    if (!street || !city || !state || !country || !zip) {
      setError(INCOMPLETE_GOOGLE_MAPS_PLACE_MESSAGE);
      return;
    }

    setError(undefined);
    onSelect({ ...data, address: { ...data.address, latitude, longitude, street, city, state, zip, country } });
  };

  return (
    <div className="claim-view__contact-details">
      <span className="claim-view__section-header">Contact Details</span>
      <label>
        Name
        <div className="claim-view__contact-details__form-group">
          <input
            className="form-control"
            type="text"
            value={data.name || ''}
            placeholder="First and Last Name"
            onChange={(event) => onSelect({ ...data, name: event.target.value })}
            required
          />
        </div>
      </label>
      <div className="claim-view__contact-details__form-group" style={{ width: '100%' }}>
        <label style={{ width: '80%' }}>
          Mailing Address
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FontAwesomeIcon icon="map-marker" />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <GoogleMapsPlacesAutocompleteInput
              placeholder="Enter a location"
              onAutocomplete={onAutocomplete}
              type="text"
              className="form-control"
              onChange={() => {
                onSelect({ ...data, address: undefined });
                setError(INCOMPLETE_GOOGLE_MAPS_PLACE_MESSAGE);
              }}
              required
            />
          </InputGroup>
        </label>
        <label style={{ width: '20%' }}>
          Apt/Suite
          <input
            className="form-control"
            type="text"
            value={data.address?.aptsuite || ''}
            disabled={!data.address}
            onChange={(event) => {
              event.persist();
              onSelect({ ...data, address: { ...data.address!, aptsuite: event.target.value } });
            }}
          />
        </label>
      </div>
      {error && <div className="claim-view__error">{error}</div>}
    </div>
  );
};
