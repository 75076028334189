import React from 'react';

import { Highlight, TotalsTable } from '@portal/components/shared/totals_table';

export const Totals: React.FC<{
  totalCost: number;
  tax: number;
  discount: number;
}> = ({ totalCost, tax, discount }) => {
  const discountedTotal = totalCost - discount;
  const totalDue = discountedTotal + tax;

  return (
    <TotalsTable.Container>
      <TotalsTable.Block>
        <TotalsTable.Line>
          <TotalsTable.LineItem name="Monthly total" />
          <TotalsTable.LinePrice price={totalCost} />
        </TotalsTable.Line>
        {!!discount && (
          <TotalsTable.Line>
            <TotalsTable.LineItem name="Promotion" highlight={Highlight.Primary} subItem={true} />
            <TotalsTable.LinePrice price={discount} highlight={Highlight.Primary} negative={true} subItem={true} />
          </TotalsTable.Line>
        )}
      </TotalsTable.Block>
      {(!!tax || !!discount) && (
        <TotalsTable.Block>
          {!!tax && (
            <>
              <TotalsTable.Line>
                <TotalsTable.LineItem name="Total" subItem={true} />
                <TotalsTable.LinePrice price={discountedTotal} subItem={true} />
              </TotalsTable.Line>
              <TotalsTable.Line>
                <TotalsTable.LineItem name="Tax" subItem={true} />
                <TotalsTable.LinePrice price={tax} subItem={true} />
              </TotalsTable.Line>
            </>
          )}
          <TotalsTable.Line>
            <TotalsTable.LineItem name="Total due" />
            <TotalsTable.LinePrice price={totalDue} />
          </TotalsTable.Line>
        </TotalsTable.Block>
      )}
    </TotalsTable.Container>
  );
};
