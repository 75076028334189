import { Pricing__StoragePlan, RateGroupKindEnum, useDisposalUpdatedStoragePricingQuery } from '@portal/schema';
import React from 'react';

/**
 *
 * Show modal when:
 *
 * 1. Storage plan is being added
 * 2. Storage cuft moves to a different plan size
 */

type PlanData = Pick<Pricing__StoragePlan, 'id' | 'formattedName' | 'cuft'>;

export function useStoragePricingInfo({
  newCuft,
  rateGroupKind,
  currentPlanCuft,
  signedParentOrderID,
  signedParentOrderPurpose,
}: {
  newCuft: number;
  currentPlanCuft?: number;
  rateGroupKind: RateGroupKindEnum;
  signedParentOrderID: string;
  signedParentOrderPurpose: string;
}) {
  const { data, previousData } = useDisposalUpdatedStoragePricingQuery({
    variables: { signedParentOrderID, signedParentOrderPurpose, rateGroupKind },
  });

  const resolvedData = data || previousData;

  if (!resolvedData?.pricingSet?.quoteID) return null;

  const currentPricing = currentPlanCuft
    ? resolvedData.pricingSet?.storagePriceEntries.find(
        (e) => e.price.plan.__typename === 'Pricing__StoragePlan' && e.price.plan.cuft >= currentPlanCuft,
      )?.price
    : undefined;

  const newPricing = resolvedData.pricingSet?.storagePriceEntries.find(
    (e) => e.price.plan.__typename === 'Pricing__StoragePlan' && e.price.plan.cuft >= newCuft,
  )?.price;

  const currentPlan = currentPricing?.plan as PlanData | undefined;
  const newPlan = newPricing?.plan as PlanData;

  const changeDescription = (() => {
    if (currentPlan === newPlan || newCuft === 0) {
      return null;
    } else if (!currentPlan?.cuft) {
      return `A ${newPlan.formattedName} storage plan will be added to your account, billed monthly at $${newPricing?.amount}/mo`;
    } else if (currentPlan.cuft < newPlan.cuft) {
      return (
        <>
          Your monthly storage cost will increase to ${newPricing?.amount} as your items require a{' '}
          {newPlan.formattedName} plan.
        </>
      );
    } else {
      return (
        <>
          Your monthly storage cost will <b>decrease</b> to ${newPricing?.amount} because your remaining items fit in a
          ${newPlan.formattedName} plan!
        </>
      );
    }
  })();

  return {
    quoteId: resolvedData.pricingSet.quoteID,
    newPlan,
    changeDescription,
  };
}
