import { wt } from '@portal/initializers/wt';
import { Currency, Pluralize } from '@shared/components/helpers';
import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import { Box, Button, COLORS, Modal, mq, Text } from '@clutter/clean';

import { useMovingNextLaborRateQuery } from '@portal/schema';
import { ITEM_INVENTORY_PAGE_NAME } from '@portal/components/orders/estimation/item_inventory';

const MAXIMUM_MOVER_COUNT = 6;

const Callout = styled(Text.SmallCaps)`
  color: ${COLORS.toucan};
  ${mq({ maxWidth: ['200px', '200px', 'initial'] })}
`;

const ActionButton = styled(Button)`
  flex-grow: 1;
`;

const Content = styled.div`
  border-top: 1px ${COLORS.grayBorder} solid;
  padding: 24px 24px 48px;
  text-align: center;
`;

export const IneligibleModal: React.FC<{
  isOpen: boolean;
  movers: number;
  orderID: string;
  onClose(): void;
  onUpgrade(): void;
}> = ({ isOpen, movers, orderID, onClose, onUpgrade }) => {
  const canUpgrade = movers < MAXIMUM_MOVER_COUNT;
  const { data } = useMovingNextLaborRateQuery({ variables: { orderID, movers: movers + 1 }, skip: !canUpgrade });

  useEffect(() => {
    if (isOpen) {
      wt.track({
        action: 'click',
        objectName: 'item_button',
        objectType: 'button',
        pageName: ITEM_INVENTORY_PAGE_NAME,
        container: 'ineligible_modal',
        label: 'You’ve reached the max move time',
        value: movers,
        can_upgrade: canUpgrade,
        order_id: orderID,
      });
    }
  }, [isOpen, canUpgrade, movers, orderID]);

  return (
    <Modal isOpen={isOpen} includeCloseButton handleModalClose={onClose}>
      <Box.Flex justifyContent="center" alignItems="flex-end" height="64px" padding="0 0 16px 0" textAlign="center">
        <Callout>You’ve reached the max move time</Callout>
      </Box.Flex>
      <Content>
        {canUpgrade ? (
          <>
            <Text.Title size="medium">Add 1 mover to continue adding items</Text.Title>
            <Text.Body>
              <Pluralize count={movers} plural="movers" singular="mover" /> is insufficient for this size move. If you
              would like to add more items, you must add 1 mover. Your new rate will be{' '}
              {data ? <Currency value={data.movingConfiguration.laborRate.amount} /> : '$-'} per hour.
            </Text.Body>
            <Box.Flex justifyContent="center" gap="16px" padding="16px 0 0 0">
              <ActionButton
                kind="secondary"
                onClick={() => {
                  onClose();
                }}
              >
                No thanks
              </ActionButton>
              <ActionButton
                onClick={() => {
                  wt.track({
                    action: 'click',
                    objectName: 'add_mover_button',
                    objectType: 'button',
                    pageName: ITEM_INVENTORY_PAGE_NAME,
                    container: 'ineligible_modal',
                    label: 'Add 1 mover',
                    value: movers,
                    order_id: orderID,
                  });
                  onUpgrade();
                }}
              >
                Add 1 mover
              </ActionButton>
            </Box.Flex>
          </>
        ) : (
          <>
            <Text.Title size="medium">Please book a follow-up appointment</Text.Title>
            <Text.Body>
              You cannot add any more items because it will exceed our maximum appointment time. Please remove other
              items first to make space. Otherwise, you’ll need to book a follow-up appointment.
            </Text.Body>
          </>
        )}
      </Content>
    </Modal>
  );
};
