import React, { useState } from 'react';

import { UUID } from '@shared/utils/uuid';

import {
  BuildClaimItemSelectionInput,
  ClaimItemSelectionFragment,
  ClaimItemSelectionKind,
  DetailedClaimFragment,
  Status,
  useBuildClaimItemSelectionBatchMutation,
} from '@portal/schema';

type ClaimItemSelectionInput = BuildClaimItemSelectionInput & { uuid?: string };

interface ISelectorProps {
  claim: DetailedClaimFragment;
  kind: ClaimItemSelectionKind;
  children(props: {
    selectedIDs: Set<string>;
    extras: ClaimItemSelectionInput[];
    valid: boolean;
    saving: boolean;
    error?: string;
    onSelect(itemID: string): void;
    onDeselect(itemID: string): void;
    onBuild(): void;
    onChange(input: ClaimItemSelectionInput): void;
    save(): Promise<DetailedClaimFragment | undefined>;
  }): React.ReactNode;
}

export const Selector: React.FC<ISelectorProps> = ({ claim, kind, children }) => {
  const [selectionsForKind] = useState<ClaimItemSelectionFragment[]>(() =>
    claim.itemSelections.filter(({ kind: selectionKind }) => selectionKind === kind),
  );
  const [selectedIDs, setSelectedIDs] = useState<Set<string>>(
    () => new Set(selectionsForKind.filter(({ item }) => item).map(({ item }) => item!.id)),
  );
  const [extras, setExtras] = useState<ClaimItemSelectionInput[]>(() =>
    selectionsForKind.filter(({ item }) => !item).map(({ id, name }) => ({ id, name, uuid: UUID() })),
  );

  const [mutate, { loading: saving, data }] = useBuildClaimItemSelectionBatchMutation();

  const onSelect = (itemID: string) => {
    const currentSelectedIDs = new Set(selectedIDs);
    currentSelectedIDs.add(itemID);
    setSelectedIDs(currentSelectedIDs);
  };

  const onDeselect = (itemID: string) => {
    const currentSelectedIDs = new Set(selectedIDs);
    currentSelectedIDs.delete(itemID);
    setSelectedIDs(currentSelectedIDs);
  };

  const onBuild = () => setExtras([...extras, { uuid: UUID() }]);

  const onChange = (input: ClaimItemSelectionInput) =>
    setExtras(extras.map((extra) => (extra.uuid === input.uuid ? input : extra)));

  const input = (): ClaimItemSelectionInput[] => {
    const selections = claim.itemSelections.filter(({ kind: selectionKind }) => selectionKind === kind);
    const currentSelectedIDs = new Set(selectedIDs);

    const current = selections.map(({ id, item }) => ({
      id,
      itemID: item ? item.id : undefined,
      _destroy: item && !currentSelectedIDs.delete(item.id),
    }));

    const changes = Array.from(currentSelectedIDs).map((itemID) => ({ itemID }));

    const currentExtras = extras
      .filter(({ id, _destroy }) => id || !_destroy)
      .map(({ id, name, _destroy }) => ({
        id,
        name,
        _destroy,
      }));

    return [...current, ...changes, ...currentExtras];
  };

  return children({
    selectedIDs,
    extras,
    onSelect,
    onDeselect,
    onBuild,
    onChange,
    valid: (!!selectedIDs.size || !!extras.length) && extras.every(({ _destroy, name }) => _destroy || !!name),
    error: data?.buildClaimItemSelectionBatch?.error ?? undefined,
    saving,
    save: async () => {
      const result = await mutate({
        variables: {
          uuid: claim.uuid,
          kind,
          input: input(),
        },
      });
      if (result.data?.buildClaimItemSelectionBatch?.status !== Status.Ok) {
        return;
      }
      return result.data.buildClaimItemSelectionBatch.claim || undefined;
    },
  }) as React.ReactElement;
};
