import { DateTime } from 'luxon';

export const formatDate = (
  date: string,
  formatOption: Intl.DateTimeFormatOptions = DateTime.DATE_FULL,
  setZone = true,
) => DateTime.fromISO(date, { setZone: setZone }).toLocaleString(formatOption);

export const formatDateRange = (
  startDate: string,
  endDate: string,
  formatOption: Intl.DateTimeFormatOptions = DateTime.DATE_FULL,
) =>
  startDate === endDate
    ? DateTime.fromISO(startDate).toLocaleString(formatOption)
    : [
        DateTime.fromISO(startDate).toLocaleString(formatOption),
        DateTime.fromISO(endDate).toLocaleString(formatOption),
      ].join(' - ');
