import {
  AccountResolution__Reason,
  Status,
  CustomerTicket__ContactPreference,
  useBuildCustomerTicketMutation,
  useCustomerTicketCreateQuery,
} from '@portal/schema';
import { Box, Button, Label, Text, Textarea, FontWeight } from '@clutter/clean';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

import { Banner as ErrorBanner } from '@portal/components/shared/error';
import { FileUploader } from '@portal/components/shared/file_uploader';

import { IEventParams } from '@portal/types/wt/event';
import { wt } from '@portal/initializers/wt';
import { Spacer } from '@shared/components/helpers';
import { ZendeskChatTag } from '@shared/types/zendesk';
import { Success } from './success';
import { ContactPreferenceOptionList } from './contact_preference/option_list';
import { isWithinBusinessHours } from './contact_preference/is_within_business_hours';
import { Spinner } from '../helpers';

const CONTACT_PREFERENCE_CTA_COPY: {
  [key in CustomerTicket__ContactPreference]: string;
} = {
  [CustomerTicket__ContactPreference.Chat]: 'Start chat',
  [CustomerTicket__ContactPreference.Email]: 'Submit',
  [CustomerTicket__ContactPreference.Phone]: 'Submit',
};

const Error = styled(ErrorBanner)`
  margin-bottom: 24px;
`;

const HiddenContainer = styled.div<{ visible: boolean }>`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

export const FormGroup = styled.div`
  margin-bottom: 16px;
`;

const FullTextarea = styled(Textarea)`
  height: 144px;
  width: 100%;
`;

const TEXT_AREA_LABEL = 'Details about the issue';
const NO_DESCRIPTION_FORM_ERROR = 'Please provide an explanation in the text box below.';

const FORM_BLUR_WT_PARAMS: IEventParams = {
  action: 'input',
  container: 'ticket_form',
  label: TEXT_AREA_LABEL,
  objectName: 'description',
  objectType: 'input:text',
  pageName: 'portal:help_ticket',
  position: 1,
};

const FILE_UPLOADER_WT_PARAMS: IEventParams = {
  container: 'ticket_form',
  pageName: 'portal:help_ticket',
  position: 2, // indicates component position for uploads, overridden by file index for removals
};

const Disclaimer = () => (
  <Text.Callout>Disclaimer: The wait times are approximate and the actual wait times may vary.</Text.Callout>
);

const BusinessHours = () => (
  <Box>
    <Text.Body weight={FontWeight.Medium}>Business hours</Text.Body>
    <Text.Body>Monday - Friday 5 AM - 5 PM PT</Text.Body>
    <Text.Body>Saturday - Sunday 5 AM - 4 PM PT</Text.Body>
  </Box>
);

export const Create: React.FC<{
  category: string;
  reason: AccountResolution__Reason;
  offerID?: string;
  noteBody?: string;
}> = ({ category, reason, offerID, noteBody }) => {
  const withinBusinessHours = isWithinBusinessHours();

  const [description, setDescription] = useState<string>();
  const [signedIDs, setSignedIDs] = useState<string[]>([]);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');
  const [filesLoading, setFilesLoading] = useState<boolean>(false);
  const [contactPreference, setContactPreference] = React.useState<CustomerTicket__ContactPreference>(
    withinBusinessHours ? CustomerTicket__ContactPreference.Chat : CustomerTicket__ContactPreference.Email,
  );

  const { data, loading: queryLoading } = useCustomerTicketCreateQuery();
  const [buildCustomerTicket, { loading }] = useBuildCustomerTicketMutation();

  useEffect(() => {
    if (submitted && contactPreference === CustomerTicket__ContactPreference.Chat) {
      if (data) {
        zE('webWidget', 'identify', {
          name: data.account.customer.name,
          email: data.account.customer.email,
        });
      }

      zE('webWidget', 'chat:addTags', [ZendeskChatTag.AccountPortal]);

      if (description) {
        zE('webWidget', 'chat:send', description);
      }

      zE('webWidget', 'open');
    }
  }, [submitted, contactPreference, data, description]);

  const submitWtParams: IEventParams = {
    action: 'click',
    container: 'ticket_form',
    label: CONTACT_PREFERENCE_CTA_COPY[contactPreference],
    objectName: 'submit',
    objectType: 'input:button',
    pageName: 'portal:help_ticket',
    position: 3,
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!description) {
      setFormError(NO_DESCRIPTION_FORM_ERROR);
      wt.track({
        ...submitWtParams,
        form_data: {
          description: '',
          reason_id: reason.id,
          error_message: NO_DESCRIPTION_FORM_ERROR,
        },
      });
      return;
    } else {
      setFormError('');
    }

    const response = await buildCustomerTicket({
      variables: {
        input: {
          category,
          subCategory: reason.copy,
          reasonID: reason.id,
          description,
          source: 'account_portal',
          signedIDs,
          offerID,
          noteBody,
          contactPreference,
        },
      },
    });

    if (response.data?.buildCustomerTicket?.status === Status.Unprocessable) {
      setFormError(response.data.buildCustomerTicket.error!);
      wt.track({
        ...submitWtParams,
        form_data: {
          description: description,
          reason_id: reason.id,
          error_message: response.data.buildCustomerTicket.error,
        },
      });
      return;
    }

    setSubmitted(true);

    wt.track({
      ...submitWtParams,
      send_label: CONTACT_PREFERENCE_CTA_COPY[contactPreference],
      form_data: {
        description: description,
        reason_id: reason.id,
        ticket_id: response.data?.buildCustomerTicket?.customerTicketID,
        zendesk_ticket_id: response.data?.buildCustomerTicket?.zendeskTicketID,
      },
    });
  };

  const onTextFormBlur = () => {
    wt.track({
      ...FORM_BLUR_WT_PARAMS,
      value: description,
    });
  };

  if (submitted && contactPreference !== CustomerTicket__ContactPreference.Chat) {
    return <Success />;
  }

  if (queryLoading) {
    return <Spinner />;
  }

  const disableForm = submitted && contactPreference === CustomerTicket__ContactPreference.Chat;

  return (
    <form id="create-ticket-form" onSubmit={onSubmit} style={{ marginBottom: '100px' }}>
      {formError && <Error>{formError}</Error>}
      <HiddenContainer visible={!!category}>
        <FormGroup>
          <Label htmlFor="description">{TEXT_AREA_LABEL}</Label>
          <FullTextarea
            form="create-ticket-form"
            id="description"
            name="description"
            value={description}
            placeholder="Add any information that will help us resolve your issue."
            state={formError ? 'error' : undefined}
            onChange={(e) => setDescription(e.currentTarget.value)}
            onBlur={onTextFormBlur}
            disabled={disableForm}
          />
        </FormGroup>

        <Box padding="24px 0 0">
          <FormGroup>
            <FileUploader
              onUploading={setFilesLoading}
              onFileSelected={setSignedIDs}
              wtEventParams={FILE_UPLOADER_WT_PARAMS}
              withPaperClip={false}
              fullWidthButton={false}
              disabled={disableForm}
            />
          </FormGroup>
        </Box>
      </HiddenContainer>

      <ContactPreferenceOptionList
        options={
          withinBusinessHours
            ? [
                CustomerTicket__ContactPreference.Chat,
                CustomerTicket__ContactPreference.Email,
                CustomerTicket__ContactPreference.Phone,
              ]
            : [
                CustomerTicket__ContactPreference.Email,
                CustomerTicket__ContactPreference.Phone,
                CustomerTicket__ContactPreference.Chat,
              ]
        }
        selectedContactPreference={contactPreference}
        onSelect={setContactPreference}
        disabled={disableForm}
        disabledOptions={withinBusinessHours ? {} : { [CustomerTicket__ContactPreference.Chat]: true }}
      />

      <Disclaimer />
      <Spacer height="24px" />

      <BusinessHours />
      <Spacer height="24px" />

      <Button
        kind="primary"
        type="submit"
        size="medium"
        loading={loading}
        disabled={filesLoading || loading || submitted}
      >
        {CONTACT_PREFERENCE_CTA_COPY[contactPreference]}
      </Button>
    </form>
  );
};
