import * as React from 'react';

import { Spacing, Text } from '@shared/components/bootstrap';

import { useInventoryForSave } from './use_inventory_for_save';

export const MoverSummary: React.FC = () => {
  const inventory = useInventoryForSave();

  return (
    <Spacing pb={3}>
      <Text tag="p" style="info">
        <strong>How many people to lift your largest or heaviest item?</strong>
      </Text>
      <Text tag="p">
        <strong>{inventory.requestedMovers}</strong>
      </Text>
    </Spacing>
  );
};
