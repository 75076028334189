import { useContext } from 'react';

import { useItemPricingQuery } from '@portal/schema';
import { ItemPricingInputKind } from '@portal/components/orders/details/per_item_order_footer';
import { OrderInput, OrderTypeEnum } from '@portal/schema';

import { OrderContext } from './order_context';

export const useItemPricing = (orderInput?: Partial<OrderInput>) => {
  const { order } = useContext(OrderContext);
  const mergedOrder = { ...order!, ...orderInput };

  const skip = (inputOrder?: OrderInput) =>
    !inputOrder || (!inputOrder.serviceType && inputOrder.type !== OrderTypeEnum.PickupReturn);

  const { data } = useItemPricingQuery({
    variables: { input: { order: mergedOrder, kind: ItemPricingInputKind.Booking } },
    skip: skip(mergedOrder),
  });
  return data;
};
