import React, { useState } from 'react';

import { Card } from '@shared/components/bootstrap';
import {
  useContactInformationQuery,
  useCustomerVerifiedEmailUpdateMutation,
  useCustomerNameUpdateMutation,
  useCustomerVerifiedPhoneUpdateMutation,
  Mfa__VerificationKind,
  useMfaRequestSendMutation,
  Mfa__Request,
} from '@portal/schema';
import { Input, List, Text, FontWeight, Box, COLORS, Button } from '@clutter/clean';
import { TextButton } from '@portal/components/shared/customer_protected/text_button';
import { Spacer } from '@shared/components/helpers';
import styled from '@emotion/styled';
import { MFAModal } from './mfa_modal';

enum InformationType {
  Name,
  Phone,
  Email,
}

enum Mode {
  Default,
  EditingName,
  EditingPhone,
  EditingEmail,
}

const ActionButtonText = styled(Text.Button)`
  text-transform: capitalize;

  &:hover {
    color: ${COLORS.tealBrand};
  }
`;

const FullWidthInput = styled(Input)`
  flex-grow: 100;
`;

function informationTypeTitle(informationType: InformationType) {
  switch (informationType) {
    case InformationType.Name:
      return 'Name';
    case InformationType.Phone:
      return 'Phone';
    case InformationType.Email:
      return 'Email';
  }
}

type MFARequest = Pick<Mfa__Request, 'id' | 'verificationKind' | 'verificationValue'>;

const ContactInformationListItem: React.FC<{
  informationType: InformationType;
  existingValue?: string;
  editing: boolean;
  loading: boolean;
  error?: string;
  activeInputValue?: string;
  onEdit(): void;
  onSave(value: string): void;
  onCancel(): void;
  setActiveInputValue(value?: string): void;
  setError(value?: string): void;
}> = ({
  informationType,
  existingValue,
  editing,
  loading,
  error,
  activeInputValue,
  onEdit,
  onSave,
  onCancel,
  setActiveInputValue,
  setError,
}) => (
  <List.Item>
    <Box.Flex justifyContent={'space-between'}>
      <Text.Body weight={FontWeight.Medium}>{informationTypeTitle(informationType)}</Text.Body>
      {editing ? (
        <TextButton
          onClick={() => {
            onCancel();
          }}
        >
          <ActionButtonText>Cancel</ActionButtonText>
        </TextButton>
      ) : (
        <TextButton
          onClick={() => {
            setActiveInputValue(undefined);
            onEdit();
          }}
        >
          <ActionButtonText>Edit</ActionButtonText>
        </TextButton>
      )}
    </Box.Flex>
    <Spacer height="16px" />
    <Box.Flex flexDirection={'column'}>
      {editing ? (
        <FullWidthInput
          value={activeInputValue ?? ''}
          onChange={(event) => {
            setError(undefined);
            setActiveInputValue(event.target.value);
          }}
          state={error ? 'error' : undefined}
          disabled={loading}
        />
      ) : (
        <Text.Body>{existingValue}</Text.Body>
      )}
      {error && <Text.Callout color={COLORS.toucan}>{error}</Text.Callout>}
    </Box.Flex>
    {editing && (
      <>
        <Spacer height="16px" />
        <Button
          kind="primary"
          loading={loading}
          disabled={activeInputValue === '' || activeInputValue === undefined}
          onClick={() => {
            if (!loading) {
              onSave(activeInputValue!);
            }
          }}
        >
          Save
        </Button>
      </>
    )}
  </List.Item>
);

export const ContactInformation: React.FC = () => {
  const [mode, setMode] = useState<Mode>(Mode.Default);
  const [error, setError] = useState<string | undefined>(undefined);
  const [pendingMFARequest, setPendingMFARequest] = useState<MFARequest | undefined>(undefined);
  const [showMFAModal, setShowMFAModal] = useState<boolean>(false);
  const [activeInputValue, setActiveInputValue] = useState<string | undefined>(undefined);

  const { data, refetch: refetchContactInfo } = useContactInformationQuery();

  const returnToDefaultMode = (refetchData = false) => {
    setError(undefined);
    setShowMFAModal(false);
    setPendingMFARequest(undefined);
    setActiveInputValue(undefined);
    setMode(Mode.Default);

    if (refetchData) {
      refetchContactInfo();
    }
  };

  const [updateName, { loading: updateNameLoading }] = useCustomerNameUpdateMutation();
  const [updateVerifiedEmail, { loading: updateVerifiedEmailLoading }] = useCustomerVerifiedEmailUpdateMutation();
  const [updateVerifiedPhone, { loading: updateVerifiedPhoneLoading }] = useCustomerVerifiedPhoneUpdateMutation();
  const [sendMFARequest, { loading: sendMFARequestLoading }] = useMfaRequestSendMutation();

  const loading =
    updateNameLoading || updateVerifiedEmailLoading || updateVerifiedPhoneLoading || sendMFARequestLoading;

  const saveNewName = async (name: string) => {
    await updateName({ variables: { name: name } });

    returnToDefaultMode(true);
  };

  const saveNewVerifiedEmail = async (email: string) => {
    await updateVerifiedEmail({ variables: { verifiedEmail: email } });

    returnToDefaultMode(true);
  };

  const saveNewVerifiedPhone = async (phoneNumber: string) => {
    await updateVerifiedPhone({ variables: { verifiedPhoneNumber: phoneNumber } });

    returnToDefaultMode(true);
  };

  const sendVerificationRequest = async (verificationKind: Mfa__VerificationKind, verificationValue: string) => {
    const response = await sendMFARequest({
      variables: { verificationKind: verificationKind, verificationValue: verificationValue },
    });

    const mfaRequest = response.data?.mfaRequestSend?.mfaRequest;
    if (mfaRequest) {
      setPendingMFARequest({
        id: mfaRequest.id,
        verificationKind: mfaRequest.verificationKind,
        verificationValue: mfaRequest.verificationValue,
      });
      setShowMFAModal(true);
    } else if (response.data?.mfaRequestSend?.error) {
      setError(response.data.mfaRequestSend.error);
    }
  };

  if (data === undefined) {
    return null;
  }

  const customer = data.customer!;

  return (
    <>
      <Card>
        <Card.Header>Contact Information</Card.Header>
        <List density={'compact'}>
          <ContactInformationListItem
            informationType={InformationType.Name}
            existingValue={customer.name}
            editing={mode === Mode.EditingName}
            loading={loading}
            error={mode === Mode.EditingName ? error : undefined}
            activeInputValue={activeInputValue}
            onEdit={() => {
              setError(undefined);
              setMode(Mode.EditingName);
            }}
            onSave={(newName) => {
              setActiveInputValue(newName);
              if (!!newName && newName !== customer.name) {
                saveNewName(newName);
              } else {
                returnToDefaultMode();
              }
            }}
            onCancel={returnToDefaultMode}
            setActiveInputValue={setActiveInputValue}
            setError={setError}
          />
          <ContactInformationListItem
            informationType={InformationType.Email}
            existingValue={customer.email}
            editing={mode === Mode.EditingEmail}
            loading={loading}
            error={mode === Mode.EditingEmail ? error : undefined}
            activeInputValue={activeInputValue}
            onEdit={() => {
              setError(undefined);
              setMode(Mode.EditingEmail);
            }}
            onSave={(newEmail) => {
              setActiveInputValue(newEmail);
              if (newEmail !== customer.email) {
                sendVerificationRequest(Mfa__VerificationKind.EmailUpdate, newEmail);
              } else {
                returnToDefaultMode();
              }
            }}
            onCancel={returnToDefaultMode}
            setActiveInputValue={setActiveInputValue}
            setError={setError}
          />

          <ContactInformationListItem
            informationType={InformationType.Phone}
            existingValue={customer.phone}
            editing={mode === Mode.EditingPhone}
            loading={loading}
            error={mode === Mode.EditingPhone ? error : undefined}
            activeInputValue={activeInputValue}
            onEdit={() => {
              setError(undefined);
              setMode(Mode.EditingPhone);
            }}
            onSave={(newPhoneNumber) => {
              setActiveInputValue(newPhoneNumber);
              if (newPhoneNumber !== customer.phone) {
                sendVerificationRequest(Mfa__VerificationKind.PhoneUpdate, newPhoneNumber);
              } else {
                returnToDefaultMode();
              }
            }}
            onCancel={returnToDefaultMode}
            setActiveInputValue={setActiveInputValue}
            setError={setError}
          />
        </List>
      </Card>
      {showMFAModal && pendingMFARequest && (
        <MFAModal
          contactMethod={pendingMFARequest.verificationValue!}
          requestID={pendingMFARequest.id}
          hideModal={() => setShowMFAModal(false)}
          onVerified={() => {
            if (pendingMFARequest.verificationKind === Mfa__VerificationKind.EmailUpdate) {
              saveNewVerifiedEmail(pendingMFARequest.verificationValue!);
            } else {
              saveNewVerifiedPhone(pendingMFARequest.verificationValue!);
            }
          }}
        />
      )}
    </>
  );
};
