import styled from '@emotion/styled';
import React from 'react';
import { FontWeight, Text } from '@clutter/clean';

import { backgroundColor, borderColor, LaborRateEntryProps } from '../labor_rate_calendar_entry';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 42px;
  padding-top: 18px;
  overflow-x: auto;
  user-select: none;
`;

const Entry = styled.div`
  display: flex;
  align-items: center;
  margin: 0 40px 0 0;
`;

const Label = styled(Text.Body)`
  font-weight: ${FontWeight.Bold};
  font-size: 18px;
`;

type LegendEntry = {
  key?: JSX.Element;
  label?: string;
};

const LaborRateEntry = styled.div<LaborRateEntryProps>`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 16px;

  background: ${backgroundColor};
  border: 1px solid;
  border-color: ${borderColor};
`;

const DEFAULT_ENTRIES: LegendEntry[] = [
  {
    key: <LaborRateEntry />,
    label: 'Available',
  },
  {
    key: <LaborRateEntry disabled />,
    label: 'Unavailable',
  },
];

const WAITLIST_ENTRY: LegendEntry = {
  key: <LaborRateEntry booked />,
  label: 'Waitlist',
};

export const Legend: React.FC<{
  includeWaitlist: boolean;
}> = ({ includeWaitlist }) => {
  const displayedEntries = includeWaitlist ? DEFAULT_ENTRIES.concat(WAITLIST_ENTRY) : DEFAULT_ENTRIES;

  return (
    <Container>
      {displayedEntries.map(({ key, label }, i) => (
        <Entry key={i}>
          {key}
          <Label>{label}</Label>
        </Entry>
      ))}
    </Container>
  );
};
