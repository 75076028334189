import { COLORS, Button } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { TrackedClick } from '@portal/components/wt/tracked_click';
import { accountURL } from '@portal/config/routes';

import { PlanMetadata, PLAN_UPDATE_PAGE_NAME, PLAN_UPDATE_CONFIRM_PAGE_NAME } from './util';

const DEFAULT_NEXT_LABEL = 'Next';
const BACK_LABEL = 'Back';
const DEFAULT_VALID = true;
export const FOOTER_HEIGHT = 88;

const Container = styled.div`
  background: ${COLORS.cloud};
  position: fixed;
  bottom: 0%;
  width: 100%;
  height: ${FOOTER_HEIGHT}px;
  visibility: visible;
  z-index: 2;
  box-shadow: 0px 2px 24px rgba(117, 117, 117, 0.15);
  left: 0;
  margin-bottom: -1px;
`;

const ButtonContainer = styled.div`
  padding: 16px 24px;
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  @media (min-width: 600px) {
    margin: 0 auto;
  }
`;

export const Footer: React.FC<{
  pageName: string;
  metaData?: PlanMetadata;
  nextLabel?: string;
  valid?: boolean;
  loading?: boolean;
  onNext(): void;
  onPrev(): void;
}> = ({ pageName, metaData, valid = DEFAULT_VALID, nextLabel = DEFAULT_NEXT_LABEL, loading, onNext, onPrev }) => (
  <Container>
    <ButtonContainer>
      <TrackedClick
        params={{
          pageName: pageName,
          container: 'footer',
          action: 'click',
          objectType: 'input:button',
          objectName: 'back_button',
          label: BACK_LABEL,
          value: pageName === PLAN_UPDATE_PAGE_NAME ? accountURL() : undefined,
          order_id: metaData?.order_id,
        }}
      >
        <Button kind="secondary" size="large" disabled={loading} onClick={onPrev}>
          {BACK_LABEL}
        </Button>
      </TrackedClick>
      <TrackedClick
        params={{
          pageName: pageName,
          container: 'footer',
          action: 'click',
          objectType: 'input:button',
          objectName: 'cta',
          label: nextLabel,
          value: pageName === PLAN_UPDATE_CONFIRM_PAGE_NAME ? accountURL() : undefined,
          ...metaData,
        }}
      >
        <Button kind="primary" size="large" disabled={!valid || loading} loading={loading} onClick={onNext}>
          {nextLabel}
        </Button>
      </TrackedClick>
    </ButtonContainer>
  </Container>
);
