import React from 'react';
import { COLORS, Input } from '@clutter/clean';
import styled from '@emotion/styled';

import MAGNIFY_GLASS from '@portal/images/magnify_glass.svg';
import SEARCH_CANCEL from '@portal/images/search_cancel.svg';

const StyledInput = styled(Input)`
  width: 100%;
  &::-webkit-search-cancel-button {
    display: none;
  }
`;

const LeftIcon = styled.div`
  width: 46px;
  height: 100%;
  background: ${COLORS.grayBorder};
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px 0 0 3px;
  cursor: default;
  z-index: 2;
`;

const RightIcon = styled.div`
  position: absolute;
  top: 25%;
  right: 15px;
  display: flex;
  z-index: 2;
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  background: white;
  display: inline-block;
  border-radius: 4px;

  input {
    z-index: 1;
    background: transparent;
    position: relative;

    &:disabled {
      background: ${COLORS.grayBackground};
    }
  }

  div + input {
    padding-left: 62px;
  }
`;

const SearchInput = ({
  value,
  onClear,
  onChange,
  ...props
}: {
  onClear?: () => void;
  onChange?: (value: string, event?: React.ChangeEvent<HTMLInputElement>) => void;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => (
  <Container>
    <LeftIcon>
      <img src={MAGNIFY_GLASS} />
    </LeftIcon>
    <StyledInput type="search" value={value} onChange={(e) => onChange?.(e.currentTarget.value, e)} {...props} />
    {value && (
      <RightIcon
        onClick={() => {
          onClear?.();
          onChange?.('');
        }}
      >
        <img src={SEARCH_CANCEL} />
      </RightIcon>
    )}
  </Container>
);

const Combined = Object.assign(SearchInput, {
  Container,
  Input: StyledInput,
  LeftIcon,
  RightIcon,
});

export { Combined as SearchInput };
