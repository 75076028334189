import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

import { AvailabilitiesInputKind } from '@portal/schema';

import { useAvailabilitiesQuery, AvailabilitiesQuery, AvailabilitiesInput, OrderInput } from '@portal/schema';

export enum AvailabilitiesNavigationKind {
  Next = 'NEXT',
  Previous = 'PREVIOUS',
}
interface IAvailabilitiesChildrenProps {
  children(props: {
    loading: boolean;
    availabilities?: AvailabilitiesQuery['availabilities'];
    from?: DateTime;
    till?: DateTime;
    fetchMore(navigation: AvailabilitiesNavigationKind): void;
  }): React.ReactElement;
}

const DATE_TIME_RESET = { hour: 0, minute: 0, second: 0, millisecond: 0 };
const INCREMENT = { months: 1 };
const MONTH = 'month';

const startOfCalendar = (date?: DateTime) => date?.set(DATE_TIME_RESET)?.startOf(MONTH);
const endOfCalendar = (date?: DateTime) => date?.set(DATE_TIME_RESET)?.endOf(MONTH);

const navigate = (cursor: DateTime, navigation: AvailabilitiesNavigationKind): DateTime => {
  if (navigation === AvailabilitiesNavigationKind.Next) {
    return cursor.plus(INCREMENT);
  } else if (navigation === AvailabilitiesNavigationKind.Previous) {
    return cursor.minus(INCREMENT);
  }
  return cursor;
};

interface IAvailablitiesForBookingProps {
  kind: AvailabilitiesInputKind.Booking;
  order: OrderInput;
}

interface IAvailablitiesForReschedulingProps {
  kind: AvailabilitiesInputKind.Rescheduling;
  orderID: string;
}

type IAvailabilitiesProps = IAvailablitiesForBookingProps | IAvailablitiesForReschedulingProps;

export const Availabilities: React.FC<
  IAvailabilitiesChildrenProps &
    IAvailabilitiesProps & {
      tz: string;
      date?: DateTime;
      moverOverride?: number;
    }
> = ({ children, date, tz, moverOverride, ...props }) => {
  const [firstDate, setFirstDate] = useState<DateTime | undefined>(date);
  const [cursor, setCursor] = useState<DateTime | undefined>(date);
  const startOfMonth = startOfCalendar(cursor);
  const endOfMonth = endOfCalendar(cursor);

  useEffect(() => {
    if (!firstDate) setFirstDate(date);
  }, [date]);

  const input = (current: DateTime): AvailabilitiesInput => {
    const from = startOfCalendar(current)!;
    const till = endOfCalendar(current)!;

    const shared = {
      moverOverride: moverOverride,
      from: from.toISODate(),
      till: till.toISODate(),
    };

    switch (props.kind) {
      case AvailabilitiesInputKind.Booking:
        return {
          ...shared,
          kind: AvailabilitiesInputKind.Booking,
          order: props.order,
        };
      case AvailabilitiesInputKind.Rescheduling:
        return {
          ...shared,
          kind: AvailabilitiesInputKind.Rescheduling,
          orderID: props.orderID,
        };
    }
  };

  const { loading, data, fetchMore } = useAvailabilitiesQuery({
    ...(firstDate && {
      variables: {
        input: input(firstDate),
      },
    }),
    skip: !tz || !firstDate,
    onCompleted: () => {
      if (!cursor) setCursor(startOfCalendar(date)!);
    },
  });

  if (!tz) {
    return null;
  }

  return children({
    loading,
    availabilities: data ? data.availabilities : [],
    from: startOfMonth,
    till: endOfMonth,
    fetchMore: (navigation: AvailabilitiesNavigationKind) => {
      if (!cursor) return;
      const current = navigate(cursor, navigation);
      setCursor(current);
      fetchMore({
        variables: {
          input: input(current),
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          return {
            ...prev,
            availabilities: [...prev.availabilities, ...fetchMoreResult.availabilities],
          };
        },
      });
    },
  });
};
