import React, { createContext, useContext, useRef, useState } from 'react';
import { Box, Button, COLORS, Modal, mq, Text, Textarea } from '@clutter/clean';
import styled from '@emotion/styled';

import {
  AppointmentHubDocument,
  EstimationUploadFragment,
  EstimationUploadFragmentDoc,
  Status,
  useEstimationUploadCreateMutation,
  useEstimationUploadDestroyMutation,
  useEstimationUploadsQuery,
  useVirtualWalkthroughSubmitMutation,
} from '@portal/schema';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Manager, Selector, Context as ManagerContext } from '@shared/components/files';
import {
  ImageThumbnail,
  MediaUploader,
  RemoveButton,
  Upload,
  VideoThumbnail,
} from '@portal/components/shared/media_uploader';
import { TrackedClick } from '@portal/components/wt/tracked_click';
import { client } from '@portal/libraries/apollo';
import { useBreakpointFlags } from '@portal/utils';

import example_images_desktop from '@portal/images/vw_example_desktop.png';
import example_images_mobile from '@portal/images/vw_example_mobile.png';
import { wt } from '@portal/initializers/wt';

const SUCCESS_MESSAGE = 'Your virtual walkthrough has been successfully submitted!';
const ERROR_MESSAGE = 'The request could not be submitted. Contact Customer Care for assistance.';

// WTEventTracking Helpers
function trackingParams({ orderID, objectName, label }: { orderID?: string; objectName?: string; label: string }) {
  return {
    pageName: 'portal:virtual_walkthrough:photos',
    container: 'virtual_walkthrough',
    action: 'click',
    objectType: 'button',
    objectName: objectName,
    label: label,
    order_id: orderID,
  };
}

const StyledFlexBox = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const StyledTextarea = styled(Textarea)`
  width: 100%;
  height: 170px;
`;

const FileContainer = styled.div`
  width: 100%;
  min-height: 120px;
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: start;
  grid-template-columns: repeat(auto-fit, 100px);
`;

const StyledLabel = styled.label`
  max-width: 100px;
  margin-bottom: 0;
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  margin-bottom: 16px;
  height: 100px;
  width: 100px;
  max-height: 100px;
  max-width: 100px;
  min-width: unset;
  padding: 0;
  background: transparent;
  color: ${COLORS.tealPrimary};
  cursor: pointer;
`;

const Container = styled.div`
  ${mq({
    padding: ['52px 16px 32px', null, '64px 24px 32px'],
  })}
`;

const StyledImage = styled.img`
  width: 100%;

  ${mq({
    minWidth: ['300px', null, '500px'],
  })}
`;

const StyledTextButton = styled.span`
  cursor: pointer;
  color: ${COLORS.tealPrimary};
  text-decoration: underline;
`;

const VirtualWalkthroughContext = createContext<{
  orderID: string;
  customerNotes: string;
  setCustomerNotes(notes: string): void;
  uploaded: EstimationUploadFragment[];
  onUploaded(file: File, signedID: string): void;
  onDelete(entry: EstimationUploadFragment): void;
}>({
  orderID: '',
  customerNotes: '',
  setCustomerNotes: () => {
    /* noop */
  },
  uploaded: [],
  onUploaded: () => {
    /* noop */
  },
  onDelete: () => {
    /* noop */
  },
});

export const VirtualWalkthroughForm: React.FC = () => {
  const { orderID, customerNotes, setCustomerNotes, uploaded, onDelete } = useContext(VirtualWalkthroughContext);
  const { uploads } = useContext(ManagerContext);
  const labelRef = useRef<HTMLLabelElement>(null);

  const onSeeExamples = () => {
    setShowModal(true);
    const params = trackingParams({ label: 'See examples' });
    wt.track(params);
  };

  const [showModal, setShowModal] = useState(false);
  const { isMobile } = useBreakpointFlags();

  return (
    <StyledFlexBox>
      <Modal includeCloseButton isOpen={showModal} handleModalClose={() => setShowModal(false)}>
        <Container>
          <Box textAlign="center" padding="0 0 8px 0" color={COLORS.tealDark}>
            <Text.Title size="small">Examples of useful photos</Text.Title>
          </Box>
          <StyledImage src={isMobile ? example_images_mobile : example_images_desktop} />
        </Container>
      </Modal>
      <Text.Title size="medium">Anything else we should know?</Text.Title>
      <Text.Body>
        You can upload photos, videos, and leave comments for your team.{' '}
        <StyledTextButton onClick={onSeeExamples}>See example photos</StyledTextButton>
      </Text.Body>
      <FileContainer>
        <StyledGrid>
          <TrackedClick params={trackingParams({ orderID: orderID, objectName: 'upload_item', label: 'Upload Item' })}>
            <StyledButton
              kind={uploaded.length > 0 ? 'secondary' : 'primary'}
              onClick={() => labelRef?.current?.click()}
            >
              <StyledLabel onClick={(event) => event.stopPropagation()} ref={labelRef}>
                <FontAwesomeIcon icon="plus" /> <br />
                Photo <br />
                or video
                <Selector multiple={true} accept="image/*, video/*" />
              </StyledLabel>
            </StyledButton>
          </TrackedClick>
          {uploaded.map((entry) => (
            <Upload key={entry.id}>
              <RemoveButton
                aria-label="Delete"
                onClick={() => {
                  onDelete(entry);
                }}
              >
                <FontAwesomeIcon icon="times" />
              </RemoveButton>
              {entry.media.isImage && <ImageThumbnail src={entry.media.imgixURL + '?w=200&h=200'} />}
              {entry.media.isVideo && <VideoThumbnail src={entry.media.imgixURL + '?w=200&h=200'} />}
            </Upload>
          ))}
          {uploads.map((entry) => (
            <MediaUploader key={entry.uuid} {...entry} />
          ))}
        </StyledGrid>
      </FileContainer>
      <StyledTextarea
        placeholder="Leave comments here..."
        value={customerNotes || ''}
        onChange={(e) => setCustomerNotes(e.currentTarget.value)}
      />
    </StyledFlexBox>
  );
};

export const VirtualWalkthrough: React.FC<{
  orderID: string;
  children(_: {
    loading: boolean;
    disabled: boolean;
    submit(): Promise<{ status: Status; message: string }>;
  }): React.ReactNode;
}> = ({ orderID, children }) => {
  const [uploaded, setUploaded] = useState<EstimationUploadFragment[]>([]);

  useEstimationUploadsQuery({
    client,
    variables: { orderID },
    onCompleted: (data) => setUploaded(data.estimationUploads),
  });
  const [save, { loading: submitting }] = useVirtualWalkthroughSubmitMutation({
    client,
    refetchQueries: [{ query: AppointmentHubDocument, variables: { orderID } }],
  });
  const [createUpload, { loading: creating }] = useEstimationUploadCreateMutation({
    client,
    update(cache, { data }) {
      const newUpload = data?.estimationUploadCreate?.upload;
      if (!newUpload) return;
      cache.modify({
        fields: {
          estimationUploads(existing = []) {
            const newUploadFragment = cache.writeFragment({
              data: newUpload,
              fragment: EstimationUploadFragmentDoc,
            });
            return [...existing, newUploadFragment];
          },
        },
      });
    },
  });
  const [destroyUpload, { loading: destroying }] = useEstimationUploadDestroyMutation({
    client,
    update(cache, { data }) {
      const deleted = data?.estimationUploadDestroy?.upload;
      if (deleted) {
        cache.evict({ id: cache.identify(deleted) });
      }
    },
  });

  const [customerNotes, setCustomerNotes] = React.useState<string>('');

  const loading = submitting || creating || destroying;
  const saveable = (uploaded.length !== 0 || customerNotes) && !loading;

  const handleSubmit = async (): Promise<{ status: Status; message: string }> => {
    if (!saveable) {
      return Promise.resolve({ status: Status.Ok, message: '' });
    }
    const response = await save({
      variables: {
        input: {
          orderID,
          customerNotes,
        },
      },
    });

    const status = response.data?.virtualWalkthroughSubmit?.status ?? Status.Unprocessable;
    return Promise.resolve({
      status,
      message: status === Status.Ok ? SUCCESS_MESSAGE : ERROR_MESSAGE,
    });
  };

  const onUploaded = async (file: File, signedID: string) => {
    const response = await createUpload({ variables: { orderID, signedID } });
    const upload = response.data?.estimationUploadCreate?.upload;
    if (upload) {
      setUploaded((current) => [...current, { ...upload }]);
    }
  };

  const onDelete = async (entry: EstimationUploadFragment) => {
    const response = await destroyUpload({ variables: { orderID, uploadID: entry.id } });
    if (response.data?.estimationUploadDestroy?.status === Status.Ok) {
      setUploaded(uploaded.filter(({ id }) => id !== entry.id));
    }
  };

  return (
    <VirtualWalkthroughContext.Provider
      value={{ orderID, customerNotes, setCustomerNotes, uploaded, onUploaded, onDelete }}
    >
      <Manager onSave={onUploaded}>
        {({ uploading }) =>
          children({
            loading: loading || uploading,
            disabled: uploaded.length < 1 && !customerNotes,
            submit: handleSubmit,
          })
        }
      </Manager>
    </VirtualWalkthroughContext.Provider>
  );
};
