import React from 'react';

const ID = 'saver';

export const Saver: React.FC<{ title?: string }> = ({ title = 'The Saver' }) => (
  <svg aria-labelledby={ID} width="63" height="62" viewBox="0 0 63 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title id={ID}>{title}</title>
    <circle cx="31" cy="31" r="31" fill="#E8F6F5" />
    <path
      d="M0.720947 43.014L3.40051 42.4917C3.51405 43.9905 4.58133 44.5355 6.23902 44.2176C8.16922 43.8429 8.97535 43.0481 8.97535 42.0262C8.97535 41.1179 8.3963 40.7546 7.12464 40.7319L5.16039 40.6865C2.82145 40.641 1.16376 39.9711 1.16376 37.53C1.16376 35.2478 3.12801 33.329 6.26173 32.7159C9.29327 32.1255 11.4505 33.4653 11.4732 35.9859L8.79369 36.5082C8.71421 35.4863 7.96484 34.7596 6.22767 35.1002C4.60404 35.4182 3.88873 36.1675 3.88873 36.9964C3.88873 37.689 4.30883 38.1091 5.6259 38.1431L7.46526 38.1772C9.97451 38.2113 11.7117 38.972 11.7117 41.4472C11.7117 43.8542 10.0313 45.8412 6.25038 46.5792C2.73062 47.2605 0.857196 45.7844 0.720947 43.014Z"
      fill="black"
    />
    <path
      d="M25.5069 42.458V42.651L22.5889 43.2187L21.6578 40.5618L16.3668 41.5951L15.4358 44.6152L12.6541 45.1602V44.9672L17.3773 30.7292L20.7381 30.082L25.5069 42.458ZM20.8858 38.3819L19.0464 33.1022L18.9669 33.1136L17.1389 39.1085L20.8858 38.3819Z"
      fill="black"
    />
    <path
      d="M24.7575 29.4799V29.2868L27.7323 28.7078L31.1044 38.336L31.1839 38.3247L34.522 27.3907L37.5308 26.8003V26.9933L32.7054 41.254L29.617 41.8558L24.7575 29.4799Z"
      fill="black"
    />
    <path
      d="M39.3588 26.4485L48.7146 24.6318V27.0048L41.993 28.3106V31.2967L48.1128 30.1045V32.4548L41.993 33.647V37.0872L48.7146 35.7815V38.1318L39.3588 39.9485V26.4485Z"
      fill="black"
    />
    <path
      d="M62.2146 35.3274V35.5204L59.1603 36.1108L56.0493 31.7736L53.7671 32.2164V37.1554L51.1216 37.6663V24.1664L57.0485 23.0196C59.9097 22.4632 61.5674 24.0755 61.5674 26.369C61.5674 28.3333 60.5115 30.0137 58.8424 30.9334L62.2146 35.3274ZM53.7785 29.8661L56.9236 29.253C58.2747 28.9918 58.8538 27.9927 58.8538 26.914C58.8538 25.8922 58.252 25.1542 56.9236 25.4153L53.7785 26.0284V29.8661Z"
      fill="black"
    />
    <path
      d="M22.7153 20.0478L21.1241 20.3574V19.3941L25.3816 18.5684V19.5317L23.7904 19.8413V24.3053L22.7239 24.5117V20.0478H22.7153Z"
      fill="black"
    />
    <path
      d="M30.6197 17.5449V22.9636L29.5532 23.1787V20.8564L27.2137 21.3122V23.6345L26.1558 23.8409V18.4222L27.2137 18.2158V20.3575L29.5618 19.9016V17.7513L30.6197 17.5449Z"
      fill="black"
    />
    <path
      d="M31.8411 17.3126L35.5911 16.5815V17.5363L32.899 18.0609V19.2651L35.3503 18.792V19.7382L32.899 20.2112V21.5874L35.5911 21.0627V22.0089L31.8325 22.7399V17.3126H31.8411Z"
      fill="black"
    />
    <path
      d="M54.7623 20.5459C50.741 11.4181 41.6146 5.0459 31 5.0459C17.641 5.0459 6.63939 15.1391 5.2049 28.1157M55.5116 39.551C51.977 49.6832 42.3374 56.9529 31 56.9529C23.3134 56.9529 16.4073 53.6113 11.6551 48.3017"
      stroke="#1B9089"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
