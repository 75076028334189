import { Text } from '@clutter/clean';
import React, { useState } from 'react';

import { Order__ContactInput, Order__PickupVehicleKind, OrderServiceTypeEnum } from '@portal/schema';

import { Base } from './base';
import { IStepProps } from '../form';
import { Facility as FacilityContact } from './contact/facility';
import { ContactType, TypeSelector } from './contact/type_selector';

const DEFAULT_EXPLANATION = `We’ll use the provided contact information to confirm the appointment and coordinate our arrival. If we can’t reach this person, we may need to cancel the appointment.`;
const SHIPPING_EXPLANATION = `We’ll provide the given contact information to the shipping carrier to send updates or resolve any issues.`;
const DEFAULT_RADIO_OPTIONS = [
  { value: ContactType.Customer, label: 'Use my contact information' },
  { value: ContactType.Other, label: 'Add a different contact' },
];

export const Contact: React.FC<
  {
    serviceType?: OrderServiceTypeEnum;
    contact?: Order__ContactInput;
    onChange(contact?: Order__ContactInput): void;
    setVehicleKind(kind?: Order__PickupVehicleKind): void;
    onNext?(): void;
    onPrev?(): void;
  } & IStepProps
> = ({ serviceType, contact, onChange, setVehicleKind, onNext, onPrev }) => {
  const [valid, setValid] = useState<boolean>(true);

  return (
    <Base onNext={onNext} onPrev={onPrev} valid={valid}>
      {serviceType === OrderServiceTypeEnum.Facility ? (
        <FacilityContact contact={contact} onChange={onChange} setVehicleKind={setVehicleKind} setValid={setValid} />
      ) : (
        <>
          <Text.Title size="large">Review your contact information</Text.Title>
          <TypeSelector
            contact={contact}
            onChange={onChange}
            explanation={serviceType === OrderServiceTypeEnum.Shipment ? SHIPPING_EXPLANATION : DEFAULT_EXPLANATION}
            options={DEFAULT_RADIO_OPTIONS}
            setValid={setValid}
          />
        </>
      )}
    </Base>
  );
};
