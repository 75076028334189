import * as React from 'react';

import { TextareaFormGroup } from '../textarea_form_group';

export const AdditionalInstructions: React.FC<{
  value?: string | null;
  onChange(value: string | null): void;
}> = ({ value, onChange }) => (
  <TextareaFormGroup
    label="Anything else we should know about parking?"
    placeholder="Optional — tell us about parking at this location"
    value={value ?? ''}
    onChange={(event) => onChange(event.target.value || null)}
  />
);
