import { Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { CONTAINER_SIDE_PADDING } from '@portal/components/shared/layout';
import { Message as ErrorMessage } from '@portal/components/shared/error';
import { Account__CancelIntent__Reason as CancelIntentReason, Maybe } from '@portal/schema';
import { ACCOUNT_CANCEL_REASON_OPTIONS } from '@shared/config/account_cancel_reasons';
import { CancellationUI } from '@portal/components/orders/cancel/reasons/cancellation_ui';

const Container = styled.div`
  margin: 0 ${CONTAINER_SIDE_PADDING}px;
`;

const REASONS = ACCOUNT_CANCEL_REASON_OPTIONS.filter((cancelReason) => cancelReason.roles.length === 0).map(
  (cancelReason) => cancelReason.value,
);

export const Options: React.FC<{
  error?: Maybe<string>;
  reason?: CancelIntentReason;
  setReason(cancelReason: CancelIntentReason): void;
}> = ({ error, reason, setReason }) => {
  const radioOptions = REASONS.map((reasonOption) => ({
    value: reasonOption,
    label: (
      <Text.Body>
        {ACCOUNT_CANCEL_REASON_OPTIONS.filter((cancelReason) => cancelReason.value === reasonOption).map(
          (cancelReason) => cancelReason.label,
        )}
      </Text.Body>
    ),
  }));
  return (
    <Container>
      {!!error && <ErrorMessage message={error} />}
      <CancellationUI
        reason={reason}
        setReason={setReason}
        radioOptions={radioOptions}
        title="Why are you closing your account?"
        showRescheduleAndAddressButtons={false}
      />
    </Container>
  );
};
