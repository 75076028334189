import * as React from 'react';

const PARTS_REGEX = /(<a href=\".*\">.*<\/a>)/;
const LINK_REGEX = /<a href=\"(.*)\">(.*)<\/a>/;

export const Linkify: React.FC<{ text: string }> = ({ text }) => {
  const parts = text.split(PARTS_REGEX);
  return (
    <>
      {parts.map((part, key) => {
        const matches = part.match(LINK_REGEX);
        if (!matches) {
          return <span key={key}>{part}</span>;
        }
        const [, href, content] = matches;
        return (
          <a key={key} href={href}>
            {content}
          </a>
        );
      })}
    </>
  );
};
