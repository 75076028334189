import React from 'react';
import styled from '@emotion/styled';
import { toggleStyleValue, COLORS } from '@clutter/clean';

const Circle = styled.div<{ disabled?: boolean }>`
  background: ${COLORS.cloud};
  border: 2px solid ${toggleStyleValue('disabled', COLORS.grayBorder, COLORS.tealPrimary)};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  box-shadow: ${toggleStyleValue('disabled', 'none', '0px 2px 7px rgba(0, 0, 0, 0.11)')};
`;

const CheckedCircle = styled.div<{ disabled?: boolean }>`
  background: ${toggleStyleValue('disabled', COLORS.grayBorder, COLORS.tealPrimary)};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  margin: 0 auto;
`;

export const RadioCircle: React.FC<{ checked?: boolean; disabled?: boolean }> = ({ checked, disabled }) => (
  <Circle disabled={disabled}>{checked && <CheckedCircle disabled={disabled} />}</Circle>
);
