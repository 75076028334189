import { Box } from '@clutter/clean';
import React from 'react';

import { Spinner } from '@portal/components/helpers';
import { useBillingUpcomingQuery } from '@portal/schema';

import { InvoiceList } from './invoice_list';
import { Button as PrepayButton } from './prepay/button';
import { Reonboard } from './reonboard';

export const Upcoming: React.FC = () => {
  const { data, loading } = useBillingUpcomingQuery();

  if (loading) {
    return <Spinner />;
  } else if (!data) {
    return null;
  }

  const { storageCheckoutLink, upcomingInvoices, account } = data;

  if (storageCheckoutLink && !upcomingInvoices.length) {
    return <Reonboard reonboardingCheckoutLink={storageCheckoutLink} />;
  }

  return (
    <Box maxWidth="712px" margin="auto" overflow="auto">
      <InvoiceList invoices={upcomingInvoices} />
      {!account.delinquentBalance && <PrepayButton />}
    </Box>
  );
};
