import * as React from 'react';

import { AddressDetailField, IAddressDetail } from '@shared/types';

import { Input } from '@clutter/clean';
import { CheckboxFormGroup } from '../checkbox_form_group';

export const LocationType: React.FC<{
  details: IAddressDetail;
  error?: string;
  setDetails(details: IAddressDetail): void;
}> = ({ details, error, setDetails }) => {
  const selected =
    !!details[AddressDetailField.ParkingStreet] ||
    !!details[AddressDetailField.ParkingInFront] ||
    !!details[AddressDetailField.ParkingDriveway] ||
    !!details[AddressDetailField.ParkingLot] ||
    !!details[AddressDetailField.ParkingAlley] ||
    !!details[AddressDetailField.ParkingUnknown];

  const options = [
    {
      label: (
        <Input
          placeholder="Street name"
          onChange={(event) =>
            setDetails({
              ...details,
              [AddressDetailField.ParkingOnSpecificStreet]: event.target.value,
              [AddressDetailField.ParkingStreet]: !!event.target.value,
            })
          }
        />
      ),
      value: AddressDetailField.ParkingStreet,
      key: AddressDetailField.ParkingStreet,
    },
    {
      label: 'In front of building',
      value: AddressDetailField.ParkingInFront,
      key: AddressDetailField.ParkingInFront,
    },
    { label: 'Driveway', value: AddressDetailField.ParkingDriveway, key: AddressDetailField.ParkingDriveway },
    { label: 'Parking lot', value: AddressDetailField.ParkingLot, key: AddressDetailField.ParkingLot },
    {
      label: 'Alley / Behind building',
      value: AddressDetailField.ParkingAlley,
      key: AddressDetailField.ParkingAlley,
    },
    { label: "I don't know", value: AddressDetailField.ParkingUnknown, key: AddressDetailField.ParkingUnknown },
  ];

  return (
    <CheckboxFormGroup
      label="Where can we park?"
      name="location-type"
      error={!selected ? error : undefined}
      options={options}
      values={{
        [AddressDetailField.ParkingStreet]: !!details[AddressDetailField.ParkingStreet],
        [AddressDetailField.ParkingInFront]: details[AddressDetailField.ParkingInFront],
        [AddressDetailField.ParkingDriveway]: details[AddressDetailField.ParkingDriveway],
        [AddressDetailField.ParkingLot]: details[AddressDetailField.ParkingLot],
        [AddressDetailField.ParkingAlley]: details[AddressDetailField.ParkingAlley],
        [AddressDetailField.ParkingUnknown]: details[AddressDetailField.ParkingUnknown],
      }}
      onChange={(_, value) => {
        if (value.parkingUnknown && !details.parkingUnknown) {
          setDetails({
            ...details,
            ...{
              [AddressDetailField.ParkingStreet]: false,
              [AddressDetailField.ParkingOnSpecificStreet]: null,
              [AddressDetailField.ParkingInFront]: false,
              [AddressDetailField.ParkingDriveway]: false,
              [AddressDetailField.ParkingLot]: false,
              [AddressDetailField.ParkingAlley]: false,
              [AddressDetailField.ParkingUnknown]: true,
            },
          });
        } else {
          setDetails({
            ...details,
            ...value,
            ...{
              [AddressDetailField.ParkingUnknown]: false,
            },
          });
        }
      }}
    />
  );
};
