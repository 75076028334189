import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { InputGroup } from '@shared/components/bootstrap';

import { Step } from '@portal/components/claims/step';

import { ClaimPropertySelectionForm } from './form';

export const Name = () => (
  <ClaimPropertySelectionForm field="name">
    {({ loading, selection, onChange }) => (
      <>
        <Step.Title>Please give the damaged property a name.</Step.Title>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              {loading ? <FontAwesomeIcon icon="spinner" spin /> : <FontAwesomeIcon icon="font" />}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <input
            className="form-control"
            type="text"
            placeholder="Property Name"
            onChange={(event) => onChange({ ...selection, name: event.target.value || undefined })}
            value={selection.name || ''}
          />
        </InputGroup>
      </>
    )}
  </ClaimPropertySelectionForm>
);
