import { useEffect } from 'react';
import ReactGA, { FieldsObject, GaOptions } from 'react-ga';
import { useLocation } from 'react-router-dom';

interface IConfig {
  google_analytics_api_key?: string | null;
}

declare const CONFIG: IConfig;
const GOOGLE_ANALYTICS_API_KEY = CONFIG.google_analytics_api_key;

export interface ITrackerArgs {
  gaOptions?: GaOptions;
  pageFields?: (page: string) => FieldsObject;
}

/**
 * Hook for emitting Google Analytics tracking events on page location change.
 */
export const useTracker = ({ gaOptions, pageFields }: ITrackerArgs) => {
  const location = useLocation();
  useEffect(() => {
    if (GOOGLE_ANALYTICS_API_KEY) {
      ReactGA.initialize(GOOGLE_ANALYTICS_API_KEY, { gaOptions });
    }
  }, [gaOptions]);
  useEffect(() => {
    if (GOOGLE_ANALYTICS_API_KEY) {
      trackPage(location.pathname, pageFields);
    }
  }, [gaOptions, location.pathname, pageFields]);
};

const trackPage = (page: string, pageFieldsFn?: (page: string) => FieldsObject) => {
  const pageFields = pageFieldsFn ? pageFieldsFn(page) : {};
  ReactGA.set(pageFields);
  ReactGA.pageview(page);
};
