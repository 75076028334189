import { RefObject, useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const useResizeObserver = <T extends Element = HTMLDivElement>(
  callback: (entry: ResizeObserverEntry) => void,
): RefObject<T> => {
  const ref = useRef<T>(null);
  const observer = useRef<ResizeObserver>(new ResizeObserver((entries, _) => entries.forEach(callback)));

  useEffect(() => {
    if (ref.current) {
      observer.current.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.current.unobserve(ref.current);
      }
    };
  }, [ref.current]);

  return ref;
};
