import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { PackingMaterials } from '../estimation/moving_disassembly_flow/packing_materials';

const NOOP = () => {};

export const PackingBundles: React.FC<RouteComponentProps<{ orderID: string }>> = ({
  match: {
    params: { orderID },
  },
}) => {
  const history = useHistory();

  return (
    <PackingMaterials
      canNext
      canPrev={false}
      next={() => history.push(`/appointments/${orderID}`)}
      prev={NOOP}
      values={{}}
      setCanNext={NOOP}
      setCanPrev={NOOP}
      goToStep={NOOP}
      onChange={NOOP}
      setError={NOOP}
      orderID={orderID}
      createStepTransition={async () => {}}
      currentStep={0}
      nextLabel="Save"
    />
  );
};
