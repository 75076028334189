import React, { useState } from 'react';
import { Button, COLORS, Column, Grid, Input, Label } from '@clutter/clean';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';

import { Form } from '@portal/components/shared/customer_protected/form';
import { Settings__UpdatePasswordInput, useUpdatePasswordMutation } from '@portal/schema';
import { Card } from '@shared/components/bootstrap';
import { Message } from './formatters';

const StyledInput = styled(Input)`
  display: block;
  width: 100%;
`;

const Right = styled.div`
  text-align: right;
`;

export const Password: React.FC = () => {
  const { register, handleSubmit, reset } = useForm<Settings__UpdatePasswordInput>();
  const [message, setMessage] = useState<string | undefined>();
  const [success, setSuccess] = useState(false);

  const [updatePassword, { loading }] = useUpdatePasswordMutation();

  const onSubmit = async (values: Settings__UpdatePasswordInput) => {
    const result = await updatePassword({ variables: { input: values } });
    if (result.data?.updatePassword?.error) {
      setSuccess(false);
      setMessage(result.data?.updatePassword?.error);
    } else {
      setSuccess(true);
      setMessage('Password updated successfully');
      reset();
    }
  };

  const onActionBlocked = (errorMessage: string) => {
    setMessage(errorMessage);
  };

  return (
    <Card>
      <Form onSubmit={handleSubmit(onSubmit)} onActionBlocked={onActionBlocked} id="passwords">
        <Card.Header>Update Password</Card.Header>
        <Card.Body>
          <Grid wrap>
            <Column widths={[12, 12, 4]}>
              <Label htmlFor="currentPassword">Current Password</Label>
              <StyledInput ref={register()} type="password" id="currentPassword" name="currentPassword" />
            </Column>
            <Column widths={[12, 12, 4]}>
              <Label htmlFor="password">New Password</Label>
              <StyledInput ref={register()} type="password" id="password" name="password" />
            </Column>
            <Column widths={[12, 12, 4]}>
              <Label htmlFor="passwordConfirmation">Confirm New Password</Label>
              <StyledInput ref={register()} type="password" id="passwordConfirmation" name="passwordConfirmation" />
            </Column>
          </Grid>
          <Message color={success ? COLORS.tealPrimary : COLORS.toucan}>{message}</Message>
        </Card.Body>
        <Card.Footer>
          <Right>
            <Button kind="primary" loading={loading} disabled={loading}>
              Save
            </Button>
          </Right>
        </Card.Footer>
      </Form>
    </Card>
  );
};
