import React from 'react';

import { AccountResolution__OfferFragment } from '@portal/schema';

import { Summary as DoorToDoorSummary } from './door_to_door/summary';
import { Summary as SelfStorageSummary } from './self_storage/summary';

export const Summary: React.FC<{ offer: AccountResolution__OfferFragment }> = ({ offer }) => (
  <>
    Thanks so much for being a valued Clutter customer!{' '}
    {offer.metadata.product === 'self_storage' ? (
      <SelfStorageSummary offer={offer} />
    ) : (
      <DoorToDoorSummary offer={offer} />
    )}
  </>
);
