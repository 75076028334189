import { useEffect, useRef } from 'react';

const SIGN_URL_REGEX = /^(.*)clutter.(com|test|(localhost(:\d*)?))$/;
const SIGN_SUBMIT_EVENT = 'signed:thanks';
const SIGN_CANCEL_EVENT = 'signed:whoops';

export const useSignEventListener = (callbacks: { onSubmit(): void; onCancel(): void }) => {
  const onSubmitRef = useRef(callbacks.onSubmit);
  const onCancelRef = useRef(callbacks.onCancel);

  useEffect(() => {
    onSubmitRef.current = callbacks.onSubmit;
  }, [callbacks.onSubmit]);

  useEffect(() => {
    onCancelRef.current = callbacks.onCancel;
  }, [callbacks.onCancel]);

  useEffect(() => {
    const listener = ({ origin, data }: MessageEvent) => {
      if (SIGN_URL_REGEX.test(origin)) {
        switch (data) {
          case SIGN_SUBMIT_EVENT:
            onSubmitRef.current();
            return;
          case SIGN_CANCEL_EVENT:
            onCancelRef.current();
        }
      }
    };

    addEventListener('message', listener);

    return (): void => {
      removeEventListener('message', listener);
    };
  }, []);
};
