import * as React from 'react';

import { Spacing, Text } from '@shared/components/bootstrap';

import { useInventoryForSave } from './use_inventory_for_save';

import { EstimationCategoriesSummaryTable } from './estimation_categories_summary_table';

export const UnpackableEstimationSummary: React.FC<{ pickup?: boolean }> = ({ pickup = true }) => {
  const inventory = useInventoryForSave();

  if (inventory.unpackableEstimations !== undefined) {
    return (
      <Spacing pb={3}>
        <Text tag="p" style="info">
          <strong>
            {pickup ? 'How many of these items are we picking up?' : 'Are you packing any of these items?'}
          </strong>
        </Text>
        <EstimationCategoriesSummaryTable estimations={inventory.unpackableEstimations} packable={false} />
        <hr />
      </Spacing>
    );
  }
  return null;
};
