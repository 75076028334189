import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Staircase, { ChangeData } from '@clutter/staircase';

import {
  useCreateStepTransitionMutation,
  HydrateVirtualWalkthroughFlowQuery,
  useHydrateVirtualWalkthroughFlowQuery,
  EstimationMovingQuoteFragment,
  Estimation__AlgorithmName,
  Estimation__ResourceType,
  useEstimationFlowCompletionQuery,
} from '@portal/schema';
import { Spinner } from '@portal/components/helpers';
import { ACCOUNT_ID } from '@portal/config/account';
import { WT_PAGE_UUID } from '@portal/initializers/wt';
import { UUID } from '@shared/utils';

import { Photos } from '../photos';
import { FLOW_STEPS_MAP, StepName, Values, AddedStepProps } from './data';
import { CreateStepTransition } from '../data';
import { Progress } from './progress';
import { sanitizeAddress, sanitizeSelectedItemCategories } from '../utils';
import { ItemInventory } from '../item_inventory';
import { Review } from './review/index';
import { Plan } from '../v1/plan';

const STEPS = [
  {
    name: StepName.ItemInventory,
    step: ItemInventory,
  },
  {
    name: StepName.Photos,
    step: Photos,
  },
  {
    name: StepName.Review,
    step: Review,
  },
  {
    name: StepName.Plan,
    step: Plan,
  },
];

const getInitialValues = (
  address: HydrateVirtualWalkthroughFlowQuery['order']['address'],
  scheduled: HydrateVirtualWalkthroughFlowQuery['order']['scheduled'],
  virtualWalkthroughEstimationItems: HydrateVirtualWalkthroughFlowQuery['virtualWalkthroughEstimationItems'],
  virtualWalkthroughOtherItemCategories: HydrateVirtualWalkthroughFlowQuery['virtualWalkthroughOtherItemCategories'],
  destinationAddress?: HydrateVirtualWalkthroughFlowQuery['order']['address'],
  itemsRequireMoreThanOneMover?: string | null,
  priceMatchGuaranteeEligible?: boolean,
  movingQuote?: EstimationMovingQuoteFragment,
) => ({
  address: sanitizeAddress(address),
  scheduled: scheduled,
  destinationAddress: destinationAddress ? sanitizeAddress(destinationAddress) : undefined,
  selectedItemCategories: sanitizeSelectedItemCategories(virtualWalkthroughEstimationItems),
  otherItemCategoryName: virtualWalkthroughEstimationItems.find((item) =>
    virtualWalkthroughOtherItemCategories.some((otherItem) => item.category.id === otherItem.id),
  )?.category.name,
  itemsRequireMoreThanOneMover,
  priceMatchGuaranteeEligible,
  movingQuote,
});

export const VirtualWalkthroughFlow: React.FC<RouteComponentProps<{ orderID: string }>> = ({
  match: {
    params: { orderID },
  },
}) => {
  const { current: flowInstanceUuid } = useRef(UUID());
  const currentIndexRef = useRef(0);
  const { data: flowData, loading } = useHydrateVirtualWalkthroughFlowQuery({
    variables: {
      orderID,
    },
    fetchPolicy: 'network-only',
  });

  const { data: estimationData } = useEstimationFlowCompletionQuery({
    variables: {
      resourceID: orderID,
      resourceType: Estimation__ResourceType.Order,
      algorithmName: Estimation__AlgorithmName.VirtualWalkthrough,
    },
    fetchPolicy: 'network-only',
  });

  const [persistStepTransition] = useCreateStepTransitionMutation();
  const [error, setError] = useState<string | undefined>(undefined);

  const createStepTransition: CreateStepTransition = ({ name, actionName, position, values, metadata = {} }) => {
    const stepDetails = FLOW_STEPS_MAP[name];
    const fields = stepDetails?.dataFields;
    const data: Record<string, any> = {};
    if (fields && values) {
      Object.entries(values).forEach(([k, v]) => {
        if (fields.includes(k)) {
          data[k] = v;
        }
      });
    }

    persistStepTransition({
      variables: {
        input: {
          flowName: 'estimation_flow',
          flowVersion: 'virtual_walkthrough',
          flowInstanceUuid,
          pageUuid: WT_PAGE_UUID,
          resourceToken: String(ACCOUNT_ID),
          resourceType: 'Account',
          allData: values,
          data,
          name,
          actionName,
          position,
          metadata: {
            ...metadata,
            order_id: orderID,
          },
        },
      },
    });
  };

  useEffect(() => {
    createStepTransition({
      name: 'virtual_walkthrough',
      actionName: 'flow_initiated',
      position: 0,
      values: {},
    });
  }, []);

  if (loading || !flowData?.order) {
    return <Spinner />;
  }

  const onStepChange = (data: ChangeData) => {
    const { currentStep, lastStepIndex, currentStepIndex, values } = data;
    const lastStep = lastStepIndex !== undefined ? STEPS[lastStepIndex] : undefined;
    createStepTransition({
      name: lastStep?.name || '',
      actionName: currentStepIndex < currentIndexRef.current ? 'back' : 'next',
      position: currentStepIndex,
      values,
      metadata: { transitioned_to: currentStep.name },
    });
    currentIndexRef.current = currentStepIndex;
    localStorage.setItem(`virtual_walkthrough_last_step_${orderID}`, currentStep.name);
  };

  const { order, virtualWalkthroughEstimationItems, virtualWalkthroughOtherItemCategories } = flowData;

  const { address, scheduled, type, metadata, movingOperation, permissions } = order;

  const itemsRequireMoreThanOneMover = metadata?.itemsRequireMoreThanOneMover;
  const destinationAddress = movingOperation?.destinationAddress;
  const priceMatchGuaranteeEligible = permissions.priceMatchGuaranteeEligible;

  const initialValues = getInitialValues(
    address,
    scheduled,
    virtualWalkthroughEstimationItems,
    virtualWalkthroughOtherItemCategories,
    destinationAddress,
    itemsRequireMoreThanOneMover,
    priceMatchGuaranteeEligible,
    movingOperation?.movingQuote,
  );

  const virtualWalkthroughCompleted = estimationData?.estimationStatus?.complete;
  const reviewStepIndex = STEPS.findIndex(({ name }) => name === StepName.Review);
  let initialStepIndex = virtualWalkthroughCompleted ? reviewStepIndex : 0;
  const initialStepName = localStorage.getItem(`virtual_walkthrough_last_step_${orderID}`);
  if (initialStepName && initialStepName !== STEPS[STEPS.length - 1].name && !virtualWalkthroughCompleted) {
    initialStepIndex = STEPS.findIndex(({ name }) => name === initialStepName) || 0;
  }
  currentIndexRef.current = initialStepIndex;

  return (
    <Staircase<Values, AddedStepProps>
      onStepChange={onStepChange}
      initialStepIndex={initialStepIndex}
      steps={STEPS}
      initialValues={initialValues as Values}
    >
      {({ Step, getStepProps, currentStep, goToStep, values }) => (
        <>
          <Progress currentStep={currentStep} goToStep={goToStep} values={values} />
          {error && (
            <div className="alert-dismissible alert-danger alert">
              {error}
              <button type="button" className="close" onClick={() => setError(undefined)}>
                ×
              </button>
            </div>
          )}
          <Step
            {...getStepProps({
              algorithmName: Estimation__AlgorithmName.VirtualWalkthrough,
              stepName: STEPS[currentStep].name,
              setError,
              order,
              orderID,
              createStepTransition,
              currentStep,
              orderType: type,
              canPrev: true,
              prev: () => goToStep(currentStep - 1),
            })}
          />
        </>
      )}
    </Staircase>
  );
};
