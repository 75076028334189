import * as React from 'react';

import { AddressDetailBuilingSubtype } from '@shared/types';
import { SelectFormGroup } from '../select_form_group';

export const Subtype: React.FC<{
  value?: string | null;
  options: AddressDetailBuilingSubtype[];
  label: string;
  error?: string;
  onChange(value?: AddressDetailBuilingSubtype): void;
}> = ({ value, options, label, onChange, error }) => (
  <div className="form-group">
    <SelectFormGroup
      label={label}
      error={!value ? error : undefined}
      onChange={onChange}
      value={value}
      options={options.map((option) => ({ label: option, value: option }))}
    />
  </div>
);
