import React from 'react';

import { Text } from '@clutter/clean';

import { Card, Table } from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';

import { OrderStateEnum, useDocumentsQuery } from '@portal/schema';
import { DateTime } from 'luxon';
import { OrderType, Link } from './formatters';

export const Documents: React.FC = () => {
  const { data } = useDocumentsQuery();
  const orders = data?.orders || [];
  const accountIngestedDocuments = data?.account.ingestedDocuments || [];
  const onboarding = data?.account.firstOnboarding;
  const ordersWithDocuments = orders.filter(
    (order) =>
      order.state === OrderStateEnum.Completed ||
      order.coiRequests.filter(({ certificateOfInsurance }) => Boolean(certificateOfInsurance)).length > 0 ||
      order.ingestedDocuments.length > 0,
  );

  return (
    <>
      <Text.Title size="medium">Documents</Text.Title>
      <Spacer height="18px" />
      {!!accountIngestedDocuments.length && (
        <>
          <Card>
            <Card.Header>General Documents</Card.Header>
            <Table id="general-documents-table">
              <tbody>
                <tr>
                  <td className="col-sm-8" />
                  <td className="col-sm-4">
                    <div>
                      {accountIngestedDocuments.map((document) => (
                        <div key={document.id}>
                          <Link target="_blank" href={document.url}>
                            {document.filename}
                          </Link>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
          <Spacer height="18px" />
        </>
      )}
      {onboarding?.consumerBrochure && (
        <>
          <Card>
            <Card.Header>Consumer Brochure</Card.Header>
            <Table id="consumer-brochure-table">
              <tbody>
                <tr>
                  <td className="col-sm-8">
                    {DateTime.fromISO(onboarding.scheduled).toLocaleString(DateTime.DATE_HUGE)}
                  </td>
                  <td className="col-sm-4">
                    <Link target="_blank" href={onboarding.consumerBrochure.url ?? undefined}>
                      {onboarding.consumerBrochure.name}
                    </Link>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
          <Spacer height="18px" />
        </>
      )}
      <Card>
        <Card.Header>Order Contracts & Forms</Card.Header>
        <div className="table-responsive">
          <Table id="order-contracts-forms-table">
            <tbody>
              {ordersWithDocuments.map((order) => (
                <tr key={order.id}>
                  <td className="col-sm-4">
                    {DateTime.fromISO(order.scheduled).startOf('day').toLocaleString(DateTime.DATE_HUGE)}
                  </td>
                  <td className="col-sm-4">
                    <OrderType type={order.type} />
                  </td>
                  <td className="col-sm-4">
                    <div>
                      {order.state === OrderStateEnum.Completed &&
                        order.signatures.map((signature) => {
                          const url = signature.contractUrl || signature.agreement?.imgixURL;
                          return (
                            <div key={signature.id}>
                              {url ? (
                                <Link target="_blank" href={url}>
                                  {signature.documentKind}
                                </Link>
                              ) : (
                                <span>{signature.documentKind}</span>
                              )}
                            </div>
                          );
                        })}
                    </div>
                    <div>
                      {order.coiRequests.map((request) => (
                        <div key={request.id}>
                          {request.certificateOfInsurance && (
                            <Link target="_blank" href={request.certificateOfInsurance.url}>
                              {request.certificateOfInsurance.filename}
                            </Link>
                          )}
                        </div>
                      ))}
                    </div>
                    <div>
                      {order.ingestedDocuments.map((document) => (
                        <div key={document.id}>
                          <Link target="_blank" href={document.url}>
                            {document.filename}
                          </Link>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card>
    </>
  );
};
