import React, { useState } from 'react';

import { entries, isValidClaim } from '@portal/helpers/claims';

import { IClaimEntry } from '@portal/types';

import { ClaimChanger, ClaimConsumer } from '@portal/contexts';

import {
  ClaimDocument,
  ClaimItemSelectionFragment,
  ClaimPropertySelectionFragment,
  DetailedClaimFragment,
  Status,
  useSubmitClaimMutation,
} from '@portal/schema';
import { Step } from './step';

import { Delete } from './confirm/delete';
import { Empty } from './confirm/empty';
import { Entries } from './confirm/entries';
import { Subscriptions } from './confirm/subscriptions';
import { Totals } from './confirm/totals';
import { ClaimSelection } from './confirm/types';

interface IConfirmProps {
  claim: DetailedClaimFragment;
  onChange(changer: ClaimChanger): void;
}

const Confirm = ({ claim }: IConfirmProps) => {
  const results = entries(claim);

  const [submit, { loading }] = useSubmitClaimMutation({
    refetchQueries: [{ query: ClaimDocument, variables: { uuid: claim.uuid } }],
    awaitRefetchQueries: true,
  });

  const [selection, setSelection] = useState<ClaimSelection | undefined>(undefined);
  const [entryToDelete, setEntryToDelete] = useState<IClaimEntry | undefined>(undefined);

  const onSelect = (selected: ClaimItemSelectionFragment | ClaimPropertySelectionFragment) => {
    if (selection !== selected) {
      setSelection(selected);
    }
  };

  const onDeselect = (selected: ClaimItemSelectionFragment | ClaimPropertySelectionFragment) => {
    if (selection === selected) {
      setSelection(undefined);
    }
  };

  const onDelete = (entry: IClaimEntry) => {
    setEntryToDelete(entry);
  };

  const onCancel = () => {
    setEntryToDelete(undefined);
  };

  return (
    <>
      <Step
        next={{ text: 'Submit Claim', kind: 'primary', customerProtected: true }}
        saving={loading}
        valid={isValidClaim(claim)}
        onSave={async (source) => {
          // NOTE: skip mutate when closing to avoid changing the claim from "draft" to "open"
          if (source === 'close') {
            return Status.Ok;
          }
          const result = await submit({ variables: { uuid: claim.uuid } });
          return result.data?.submitClaim?.status || Status.Unprocessable;
        }}
      >
        {!results.length ? (
          <Empty claim={claim} />
        ) : (
          <>
            <Entries
              claim={claim}
              entries={results}
              selected={selection}
              onSelect={onSelect}
              onDeselect={onDeselect}
              onDelete={onDelete}
            />
            <br />
            {!claim.hasIssue && (
              <>
                <Totals entries={results} />
                <Subscriptions />
              </>
            )}
          </>
        )}
      </Step>
      {entryToDelete && <Delete entry={entryToDelete} onClose={onCancel} />}
    </>
  );
};

const ConfirmWithContext = () => (
  <ClaimConsumer>{({ claim, onChange }) => <Confirm claim={claim} onChange={onChange} />}</ClaimConsumer>
);

export { ConfirmWithContext as Confirm };
