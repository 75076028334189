import React from 'react';
import { Box, FontWeight, Text } from '@clutter/clean';
import { flatMap, groupBy, lowerCase, pickBy, startCase } from 'lodash';
import { EasypostOutboundShippingOptionsQuery, Shipping__EasyPostInboundShipment } from '@portal/schema';

import { Currency, Pluralize } from '@shared/components/helpers';

export type Shipment = EasypostOutboundShippingOptionsQuery['easypostOutboundShippingOptions'][number];

interface IEasyPostShippingOption {
  price: number;
  deliveryDays: number;
  carrier: string;
  service: string;
}

export interface IShippingOption {
  price: number;
  deliveryDays: number;
  carrier: string;
  service: string;
  maxPrice: number;
}

const ACCEPTED_CARRIERS = ['UPS', 'USPS', 'FedEx'];

// USPS and UPS use PascalCase whereas FedEx uses UPPER_CASE for their service names
// to maintain readability, FedEx should be converted to lower case before applying startCase
export const formatService = (carrier: string, service: string) =>
  carrier === 'FedEx' ? startCase(lowerCase(service)) : startCase(service);

const OptionLabel: React.FC<{ option: IShippingOption }> = ({ option }) => {
  const { carrier, deliveryDays, service, price, maxPrice } = option;

  return (
    <Box>
      <Text.Body>
        {carrier} - <Pluralize count={deliveryDays} singular="day" plural="days" /> ({formatService(carrier, service)})
      </Text.Body>
      <Text.Body weight={FontWeight.Medium}>
        {maxPrice === 0 ? (
          'Free'
        ) : (
          <>
            <Currency value={price} precision="automatic" /> - <Currency value={maxPrice} precision="automatic" />
          </>
        )}
      </Text.Body>
    </Box>
  );
};

const OptionLabelCarrierOnly: React.FC<{ option: IShippingOption }> = ({ option }) => {
  const { carrier, price, maxPrice } = option;

  return (
    <Box>
      <Text.Body>{carrier}</Text.Body>
      <Text.Body weight={FontWeight.Medium}>
        {maxPrice === 0 ? (
          'Free'
        ) : (
          <>
            <Currency value={price} precision="automatic" /> - <Currency value={maxPrice} precision="automatic" />
          </>
        )}
      </Text.Body>
    </Box>
  );
};

export const convertToSelectorOptions = (options: IShippingOption[], carrierOnly?: boolean) =>
  options.map((option) => ({
    value: option.service,
    label: carrierOnly ? <OptionLabelCarrierOnly option={option} /> : <OptionLabel option={option} />,
  }));

const getBestOption = (options: IEasyPostShippingOption[]) =>
  options.reduce((cheapest, option) => (cheapest.price < option.price ? cheapest : option));

const groupAvailableOptions = (shipments: Array<Shipment | Shipping__EasyPostInboundShipment>) => {
  const flattenedRates = flatMap(shipments.map((shipment) => shipment.rates)).filter((rate) =>
    ACCEPTED_CARRIERS.includes(rate.carrier),
  );
  const groupedRates = groupBy(flattenedRates, (rate) => [rate.carrier, rate.service]);
  const availableRates = pickBy(groupedRates, (rates) => rates.length === shipments.length);
  const options = Object.keys(availableRates).map((key) => {
    const optionRates = availableRates[key];
    return {
      price: optionRates.map((rate) => rate.rate).reduce((total, rate) => total + rate),
      deliveryDays: optionRates
        .map((rate) => rate.deliveryDays || 14)
        .reduce((highestDays, days) => Math.max(highestDays, days)),
      carrier: optionRates[0].carrier,
      service: optionRates[0].service,
    };
  });
  return options;
};

const optionBucketFilters = [
  ({ deliveryDays }: IEasyPostShippingOption) => deliveryDays === 1,
  ({ deliveryDays }: IEasyPostShippingOption) => deliveryDays === 2,
  ({ deliveryDays }: IEasyPostShippingOption) => deliveryDays >= 3 && deliveryDays < 7,
  ({ deliveryDays }: IEasyPostShippingOption) => deliveryDays >= 7,
];

const compareOptions = (a: IEasyPostShippingOption, b?: IEasyPostShippingOption) => {
  if (b === null || b === undefined) return true;
  return (a.deliveryDays || 14) > (b.deliveryDays || 14) && a.price < b.price;
};

export const getBestOptions = (shipments: Shipment[]) => {
  const options = groupAvailableOptions(shipments);
  const bestBucketedOptions: IEasyPostShippingOption[] = [];

  optionBucketFilters.forEach((bucketFilter) => {
    const bucketedOptions = options.filter(bucketFilter);
    if (bucketedOptions.length) bestBucketedOptions.push(getBestOption(bucketedOptions));
  });

  const bestOptions: IEasyPostShippingOption[] = [];

  // This comparison is dependent on bestBucketedOptions being in ascending order by deliveryDays.
  // Based on the implementation of forEach, this should be true as long as optionBucketFilters
  // remains in ascending order based on deliveryDays
  bestBucketedOptions.forEach((option) => {
    if (compareOptions(option, bestOptions.length ? bestOptions[bestOptions.length - 1] : undefined))
      bestOptions.push(option);
  });

  return bestOptions;
};

export const getCheapestOptionsByCarrier = (shipments: Shipping__EasyPostInboundShipment[]) => {
  const options = groupAvailableOptions(shipments);
  const bestOptions: IEasyPostShippingOption[] = [];

  ACCEPTED_CARRIERS.forEach((carrier) => {
    const carrierOptions = options.filter((option) => option.carrier === carrier);
    if (carrierOptions.length > 0) {
      bestOptions.push(getBestOption(carrierOptions));
    }
  });

  const bestOptionsSortedByRate = bestOptions.sort((a, b) => a.price - b.price);

  return bestOptionsSortedByRate;
};
