import cn from 'classnames';
import * as React from 'react';

interface IChoiceProps {
  selected: boolean;
  text: string;
  onSelect(): void;
}

export const ChoiceButton = ({ selected, text, onSelect }: IChoiceProps) => (
  <button
    className={cn('btn btn-block', {
      active: selected,
      'btn-light': !selected,
      'btn-primary': selected,
    })}
    onClick={onSelect}
  >
    {text}
  </button>
);
