import { BorderlessList } from '@portal/components/account_resolution/borderless_list';
import { AccountResolution__ReasonChild } from '@portal/schema';
import { List, TextButton } from '@clutter/clean';
import styled from '@emotion/styled';
import * as React from 'react';
import { TrackedClick } from '@portal/components/wt/tracked_click';
import { IEventParams } from '@portal/types/wt/event';

const ReasonItem = styled(List.Item)`
  & > button {
    width: 100%;
    text-align: left;
  }
`;

const WT_PARAMS: IEventParams = {
  action: 'click',
  container: 'reasons_list',
  pageName: 'portal:help_reasons',
  objectType: 'link',
};

export interface IReasonListProps {
  reasons: AccountResolution__ReasonChild[];
  onSelect(selectedReason: AccountResolution__ReasonChild): void;
}

export const ReasonList: React.FC<IReasonListProps> = ({ reasons, onSelect }) => (
  <BorderlessList density="default" verticalPadding={40}>
    {reasons.map((reason, index) => (
      <ReasonItem key={reason.id} id={`reason_${reason.id}`}>
        <TrackedClick
          params={{
            ...WT_PARAMS,
            label: reason.copy,
            objectName: reason.canonicalName,
            position: index + 1,
            selected_reason_id: reason.id,
          }}
        >
          <TextButton size="medium" onClick={() => onSelect(reason)}>
            {reason.copy}
          </TextButton>
        </TrackedClick>
      </ReasonItem>
    ))}
  </BorderlessList>
);
