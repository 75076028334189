import cn from 'classnames';
import * as React from 'react';
import { useContext, useState } from 'react';

const DEFAULT_NAVBAR_EXPANDED = false;

interface INavbarContext {
  expanded: boolean;
  onToggle(): void;
}

export const NavbarContext = React.createContext<INavbarContext>({
  expanded: DEFAULT_NAVBAR_EXPANDED,
  onToggle: () => {
    /* noop */
  },
});

const NavbarBrand: React.FC<React.HTMLProps<HTMLAnchorElement>> = ({ children, className, ...props }) => (
  <a {...props} className={cn('navbar-brand', className)}>
    {children}
  </a>
);

const NavbarNav: React.FC<
  React.HTMLProps<HTMLUListElement> & {
    margin?: 'mr' | 'ml';
  }
> = ({ children, margin, className, ...props }) => (
  <ul {...props} className={cn('navbar-nav', margin && `${margin}-auto`, className)}>
    {children}
  </ul>
);

const NavbarCollapse: React.FC = ({ children }) => {
  const { expanded } = useContext(NavbarContext);
  return <div className={cn('navbar-collapse collapse', expanded && 'show')}>{children}</div>;
};

const NavbarToggler: React.FC = () => {
  const { onToggle } = useContext(NavbarContext);

  return (
    <button onClick={onToggle} className="navbar-toggler" type="button">
      <span className="navbar-toggler-icon" />
    </button>
  );
};

const NavbarText: React.FC<{
  margin?: 'mr' | 'ml';
}> = ({ children, margin }) => <span className={cn('navbar-nav', margin && `${margin}-auto`)}>{children}</span>;

const Navbar: React.FC<{
  kind?: 'light' | 'dark';
  expand?: 'sm' | 'md' | 'lg';
  className?: string;
}> = ({ kind, expand, className, children }) => {
  const [expanded, setExpanded] = useState<boolean>(DEFAULT_NAVBAR_EXPANDED);
  const onToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <NavbarContext.Provider value={{ expanded, onToggle }}>
      <nav className={cn('navbar', expand && `navbar-expand-${expand}`, kind && `navbar-${kind}`, className)}>
        {children}
      </nav>
    </NavbarContext.Provider>
  );
};

const Combined = Object.assign(Navbar, {
  Brand: NavbarBrand,
  Nav: NavbarNav,
  Collapse: NavbarCollapse,
  Toggler: NavbarToggler,
  Text: NavbarText,
});

export { Combined as Navbar };
