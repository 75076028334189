import { COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { TrackedLink } from '@portal/components/wt/tracked_link';
import { supportURL } from '@portal/config/routes';
import { IEventParams } from '@portal/types/wt/event';

const Title = styled(Text.Title)`
  color: ${COLORS.panther};
  padding-bottom: 12px;
`;

const Body = styled(Text.Body)`
  color: ${COLORS.panther};
  margin-bottom: 28px;
`;

const TextLink = styled.a`
  color: ${COLORS.tealPrimary};
`;

export const UnableToAdd: React.FC<{ orderID?: string }> = ({ orderID }) => {
  const linkParams: IEventParams = {
    pageName: 'portal:plan_add',
    action: 'click',
    objectType: 'link',
    objectName: 'contact_support',
    label: 'Customer Support',
    value: supportURL(),
    order_id: orderID,
  };

  return (
    <>
      <Title size="large">Select a plan</Title>
      <Body>
        You can no longer add a storage plan to this order. This is either because the order has started or because
        you’ve already added a storage plan.
      </Body>
      <Body>
        If you have a concern, please reach out to our{' '}
        <TrackedLink params={linkParams}>
          <TextLink href={supportURL()}>Customer Support</TextLink>
        </TrackedLink>
        .
      </Body>
    </>
  );
};
