import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { Button, ButtonKind, ButtonSize } from '@shared/components/bootstrap';

const DEFAULT_PLACEHOLDER = '0';
const DEFAULT_VALUE = 0;
const DEFAULT_MIN = 0;
const DEFAULT_MAX = Number.MAX_SAFE_INTEGER;

export const Stepper: React.FC<{
  kind?: ButtonKind;
  size?: ButtonSize;
  max?: number;
  min?: number;
  placeholder?: string;
  value?: number;
  onChange(value?: number): void;
}> = ({ kind, size, max = DEFAULT_MAX, min = DEFAULT_MIN, placeholder = DEFAULT_PLACEHOLDER, value, onChange }) => {
  const isInvalid = (replacement: number | undefined) =>
    replacement !== undefined && (replacement < min || replacement > max);

  const onQuantity = (replacement: number | undefined) => {
    if (isInvalid(replacement)) {
      return;
    }
    onChange(replacement);
  };

  // NOTE: "input-group-addon" vs "input-group-prepend" / "input-group-append" are v3 vs v4 bootsrap classes.

  return (
    <div className="input-group" style={{ width: '192px' }}>
      <div className="input-group-addon input-group-prepend">
        <Button
          outline
          kind={kind}
          size={size}
          disabled={isInvalid((value || DEFAULT_VALUE) - 1)}
          onClick={() => onQuantity((value || DEFAULT_VALUE) - 1)}
        >
          <FontAwesomeIcon icon="minus" />
        </Button>
      </div>
      <input
        type="number"
        className="form-control text-center"
        placeholder={placeholder}
        onChange={(event) => onQuantity(Number(event.target.value) || 0)}
        value={value || ''}
      />
      <div className="input-group-addon input-group-append">
        <Button
          outline
          kind={kind}
          size={size}
          disabled={isInvalid((value || DEFAULT_VALUE) + 1)}
          onClick={() => onQuantity((value || DEFAULT_VALUE) + 1)}
        >
          <FontAwesomeIcon icon="plus" />
        </Button>
      </div>
    </div>
  );
};
