import React, { useState } from 'react';

import { Base } from '@portal/components/orders/steps/base';
import { client } from '@portal/libraries/apollo';
import {
  Account__TermCommitment,
  Account__TermCommitment__Type,
  Maybe,
  Status,
  useBuildTermCommitmentCustomerTicketMutation,
} from '@portal/schema';

import { MessageType, Options as TermCommitmentOptions, TermCommitmentAction } from '../../term_commitment/options';
import { Submitted } from '../../term_commitment/submitted';

const generateOptions = (termType: Account__TermCommitment__Type) => {
  const options = [
    {
      value: TermCommitmentAction.RemoveItems,
      label: 'Remove items from this appointment.',
    },
  ];
  if (termType === Account__TermCommitment__Type.StorageTerm) {
    options.push({
      value: TermCommitmentAction.Pay,
      label: 'Pay the remaining balance left on my term and close my account.',
    });
  } else {
    options.push({
      value: TermCommitmentAction.ContactCare,
      label: 'Contact an account manager to discuss my options.',
    });
  }
  return options;
};

export const Actions: React.FC<{
  termCommitment: Account__TermCommitment;
  action?: TermCommitmentAction;
  setAction(action: TermCommitmentAction): void;
  onPrev(): void;
  onNext(): void;
  navigateToReturnStep(): void;
}> = ({ termCommitment, action, setAction, onPrev, onNext, navigateToReturnStep }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<Maybe<string>>();

  const [createCustomerTicket, { loading }] = useBuildTermCommitmentCustomerTicketMutation({ client });

  const onNextClick = async () => {
    setError(undefined);
    if (action === TermCommitmentAction.RemoveItems) {
      navigateToReturnStep();
    } else if (action === TermCommitmentAction.ContactCare) {
      const response = await createCustomerTicket();
      if (response?.data?.buildTicket) {
        if (response.data.buildTicket.status === Status.Unprocessable) {
          setError(response.data.buildTicket.error);
        } else {
          setSubmitted(true);
        }
      }
    } else if (action === TermCommitmentAction.Pay) {
      onNext();
    }
  };

  if (submitted) {
    return <Submitted />;
  }

  return (
    <Base onNext={onNextClick} onPrev={onPrev} valid={!!action && !loading}>
      <TermCommitmentOptions
        messageType={MessageType.Scheduling}
        termCommitment={termCommitment}
        options={generateOptions(termCommitment.type)}
        error={error}
        action={action}
        setAction={setAction}
      />
    </Base>
  );
};
