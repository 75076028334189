import * as React from 'react';

const PageHeader: React.FC = ({ children }) => <div className="page__header">{children}</div>;

const PageFooter: React.FC = ({ children }) => <div className="page__footer">{children}</div>;

const PageSection: React.FC = ({ children }) => <div className="page__section">{children}</div>;

const PageTitle: React.FC = ({ children }) => <div className="page__title">{children}</div>;

const PageSubtitle: React.FC = ({ children }) => <div className="page__subtitle">{children}</div>;

const PageNavigator: React.FC = ({ children }) => <div className="page__navigator">{children}</div>;

const Page: React.FC = ({ children }) => <div className="page">{children}</div>;

const Combined = Object.assign(Page, {
  Title: PageTitle,
  Subtitle: PageSubtitle,
  Header: PageHeader,
  Footer: PageFooter,
  Section: PageSection,
  Navigator: PageNavigator,
});
export { Combined as Page };
