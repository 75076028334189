import { Button } from '@clutter/clean';
import React, { useState } from 'react';

import { Form } from '@portal/components/shared/customer_protected/form';
import { ClaimPaymentContext, ClaimPaymentOptionInput } from '@portal/contexts/claim_payment';
import { DetailedClaimFragment, useAcceptClaimOfferMutation, Status, ClaimDocument } from '@portal/schema';
import { useSignEventListener } from '@shared/hooks/use_sign_event_listener';

import { Agreement } from './agreement';
import { CompensationMethod } from './compensation_method';
import { NO_ADDRESS_PAYMENT_OPTION_KINDS } from './constants';
import { ContactDetails } from './contact_details';
import { PaymentOptions } from './payment_options';

const ERROR_MISSING_PAYMENT_OPTION = 'You must select a payment option.';
const ERROR_INVALID_ADDRESS = 'Invalid address was selected.';
const ERROR_SIGN_CANCELED = 'Please sign the settlement agreement.';
const ERROR_FAILED_TO_GENERATE = 'An unknown error occurred while generating your contract. Please try again.';

export const Approved: React.FC<{
  claim: DetailedClaimFragment;
  setRedirectToReviewPage(redirecting: boolean): void;
}> = ({ claim, setRedirectToReviewPage }) => {
  const [claimPayment, setClaimPayment] = useState<ClaimPaymentOptionInput>({});

  const [showPreview, setShowPreview] = useState<boolean>(false);

  const [formError, setFormError] = useState<string | undefined>(undefined);

  useSignEventListener({
    onSubmit: () => {
      setShowPreview(false);
      acceptClaim();
    },
    onCancel: () => {
      setShowPreview(false);
      setFormError(ERROR_SIGN_CANCELED);
    },
  });

  const [acceptClaimOffer, { loading }] = useAcceptClaimOfferMutation({
    variables: {
      input: {
        uuid: claim.uuid,
        name: claimPayment.name,
        email: claimPayment.email,
        address: claimPayment.address,
        kind: claimPayment.kind!,
      },
    },
    refetchQueries: [{ query: ClaimDocument, variables: { uuid: claim.uuid } }],
    awaitRefetchQueries: true,
  });

  const damageOrLoss = claim.hasDamagedItem || claim.hasDamagedProperty || claim.hasLostItem;
  const addressRequired = !!claimPayment.kind && !NO_ADDRESS_PAYMENT_OPTION_KINDS.includes(claimPayment.kind);

  const addressFilled = () => {
    if (!claimPayment.address) {
      return false;
    }

    const { street, city, state, country, zip } = claimPayment.address;
    return !!street && !!city && !!state && !!country && !!zip;
  };

  const submitPaymentDetails = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!claimPayment.kind) {
      setFormError(ERROR_MISSING_PAYMENT_OPTION);
      return;
    }
    if (addressRequired && !addressFilled()) {
      setFormError(ERROR_INVALID_ADDRESS);
      return;
    }

    setFormError(undefined);

    // Customers do not need to sign the settlement agreement form if they are only filing for an issue.
    // The DocuSign modal should only appear when claiming for damages or loss.
    if (damageOrLoss) {
      setShowPreview(true);
    } else {
      acceptClaim();
    }
  };

  const acceptClaim = async () => {
    const response = await acceptClaimOffer();
    if (response.data?.acceptClaimOffer?.error) {
      setFormError(`There was a problem signing your claim: ${response.data.acceptClaimOffer.error}`);
    } else if (response.data?.acceptClaimOffer?.status === Status.Ok) {
      setRedirectToReviewPage(true);
    }
  };

  const enableSubmit = addressRequired
    ? !!claimPayment.kind && !!claimPayment.name && addressFilled()
    : !!claimPayment.kind;

  return (
    <>
      <ClaimPaymentContext.Provider value={{ data: claimPayment, onSelect: setClaimPayment }}>
        <Form onSubmit={submitPaymentDetails} onActionBlocked={(errorMessage) => setFormError(errorMessage)}>
          {damageOrLoss ? (
            <>
              <PaymentOptions />
              <ContactDetails />
              <Agreement
                uuid={claim.uuid}
                showPreview={showPreview}
                onError={() => setFormError(ERROR_FAILED_TO_GENERATE)}
                onClose={() => setShowPreview(false)}
              />
            </>
          ) : (
            <CompensationMethod uuid={claim.uuid} totalAmount={claim.offer?.totalAmount ?? 0} />
          )}
          <div className="claim-view__error">{formError}</div>
          <Button kind="primary" type="submit" fullWidth disabled={!enableSubmit || loading} loading={loading}>
            {damageOrLoss ? 'Review and Sign Agreement' : 'Confirm And Process Compensation'}
          </Button>
        </Form>
      </ClaimPaymentContext.Provider>
    </>
  );
};
