import { IIconProps } from '@portal/components/shared/icons/props';
import React from 'react';
import { snakeCase } from 'lodash';

export const PaperworkClipboard: React.FC<IIconProps> = ({ title, width = 56, height = 67 }) => (
  <svg
    aria-hidden={!title}
    aria-labelledby={title && `${snakeCase(title)}-svg`}
    width={width}
    height={height}
    viewBox="0 0 56 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title id={`${snakeCase(title)}-svg`}>{title}</title>}
    <path
      d="M51.8373 59.7482L12.7208 66.527C11.0512 66.8423 9.46112 65.7388 9.22261 64.0835L0.477059 14.8976C0.159039 13.2423 1.27211 11.6659 2.94171 11.4294L42.0582 4.57175C43.7278 4.25645 45.3179 5.35998 45.5564 7.01528L54.3019 56.2012C54.5405 57.8565 53.4274 59.4329 51.8373 59.7482Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.2808 56.7529L12.1643 63.6105L6.91698 33.8153L3.41876 14.4247L42.5352 7.56702L45.9539 26.9576L51.2808 56.7529Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.779 54.6247L15.6625 61.4035L8.42757 33.5L4.21381 14.2671L43.2508 7.40942L47.4645 26.7212L54.779 54.6247Z"
      fill="white"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.2914 7.40942L14.788 10.0894C13.5954 10.3259 12.5618 11.2718 12.3233 12.4541L11.9258 14.4247L34.5052 10.4835L33.3921 8.74942C32.7561 7.72472 31.5635 7.17295 30.2914 7.40942Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1183 12.6117C13.2774 11.6658 14.0724 11.0352 14.947 10.8776L30.4504 8.19759C31.4045 8.03994 32.2791 8.43406 32.7561 9.22229"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.106 10.0894L14.947 9.38C14.7084 8.11882 15.583 6.93647 16.7756 6.77882C18.0477 6.54235 18.8427 5.36 18.6042 4.17765L18.4452 3.46823C18.2067 2.20706 19.0812 1.0247 20.2738 0.867057L21.7844 0.630587C23.0565 0.394116 24.249 1.26117 24.4081 2.44353L24.6466 2.99529C24.8851 4.25647 26.0777 5.0447 27.2702 4.80823C28.5423 4.57176 29.7349 5.43882 29.8939 6.62118L30.0529 7.33059L15.106 10.0894Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9346 7.40942C18.6042 7.09412 19.7172 5.51765 19.3992 3.94118L19.2402 3.15295C19.0812 2.36471 19.6377 1.57648 20.5123 1.41883L22.0229 1.18236C22.8179 1.02471 23.613 1.57648 23.772 2.44354L23.931 3.15295C24.249 4.80824 25.8391 5.91177 27.4292 5.59648"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.7049 5.04471C22.3196 5.04471 22.8179 4.55064 22.8179 3.94118C22.8179 3.33171 22.3196 2.83765 21.7049 2.83765C21.0901 2.83765 20.5918 3.33171 20.5918 3.94118C20.5918 4.55064 21.0901 5.04471 21.7049 5.04471Z"
      fill="white"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0053 14.4247L34.5052 10.4835"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.9734 6.69995L30.1324 7.40936L15.106 10.0894L14.947 9.30113"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.3038 6.22705L16.2985 8.35529"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.3015 56.2012C54.6195 57.8565 53.5065 59.433 51.8369 59.6695L12.7204 66.5271C11.0508 66.8424 9.46069 65.7389 9.22217 64.0836L0.476624 14.8977"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9894 38.9388L47.4645 26.7212L30.689 38.9388"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5742 20.573L22.341 18.5236"
      stroke="#00404D"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1643 27.667L32.8356 24.0411"
      stroke="#00A499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9594 31.6082L41.1836 26.7212"
      stroke="#00A499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7544 35.5494L22.977 33.9729"
      stroke="#00A499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.1395 44.22L25.3621 42.6436"
      stroke="#00A499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.6395 40.3577L43.6483 39.4906"
      stroke="#00A499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.2243 53.2059C31.4045 52.5753 34.0282 49.9742 34.7437 46.8212C33.8692 46.2695 32.6766 46.7424 31.961 47.5306C31.2455 48.3189 30.7685 49.6589 31.5635 50.4471C32.0405 50.92 32.7561 50.9989 33.3921 50.9989C34.8232 50.8412 36.1748 50.053 36.8904 48.7918C36.0158 48.4765 34.9822 49.3436 34.9822 50.2106C34.9822 50.3683 35.0617 50.5259 35.1412 50.6048C35.3798 50.8412 35.6978 50.7624 35.9363 50.6048C36.6518 50.3683 37.2879 49.9742 37.8444 49.5012C37.6854 49.5012 37.6059 49.8165 37.7649 49.9742C37.9239 50.1318 38.1624 50.053 38.3214 49.9742C41.1041 49.4224 43.8868 48.8706 46.6695 48.3189"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
