import { useEffect, useState } from 'react';

export const useAffirm = (): Affirm | undefined => {
  const [ready, setReady] = useState(typeof affirm !== 'undefined' && affirm.jsReady());

  useEffect(() => {
    if (typeof affirm === 'undefined') return;
    let active = true;

    affirm?.jsReady(() => {
      if (!active) return;
      setReady(affirm.jsReady());
    });

    return () => {
      active = false;
    };
  }, []);

  return ready ? affirm : undefined;
};
