/* eslint-disable no-underscore-dangle */
import { Accordion, COLORS, mq, Text } from '@clutter/clean';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ReactElement, useState } from 'react';
import { orderURL } from '@portal/config/routes';
import { LightBulb } from '@root/images/light_bulb';
import { PackingTape } from '@root/images/packing_tape';
import { CreditCard } from '@root/images/credit_card';
import { Calendar } from '@root/images/calendar';
import { Clock } from '@root/images/clock';
import { ProtectionPlanShield } from '@root/images/protection_plan_shield';
import { PaperworkClipboard } from '@root/images/paperwork_clipboard';
import { CargoVan } from '@root/images/cargo_van';
import { Mail } from '@root/images/mail';
import cash from '@root/images/cash.svg';

const Container = styled.div`
  display: flex;
  margin: 32px 0;
  border-top: ${COLORS.grayBorder} 1.5px solid;

  ${mq({
    flexDirection: ['column', null, 'column'],
    textAlign: ['initial', null, 'initial'],
    padding: ['24px', null, '24px 24px'],
  })}
`;

export const IconEntry = styled.div`
flex - grow: 0;
flex - shrink: 0;

  ${mq({
    margin: ['0 24px 0 0', null, '0 24px 0 0'],
  })}
`;

export const TextEntry = styled.div`
flex - grow: 1;
flex - shrink: 1;

  ${mq({
    width: ['100%', null, 'initial'],
    margin: ['12px 0 12px 0', null, '0 24px 0 24px'],
  })}
`;

export const TealLink = styled.a`
  color: ${COLORS.tealPrimary};

  &:hover {
    color: ${COLORS.__primaryButtonHover};
  }
`;

const CardStyle = styled.div`
  display: flex;
  align-items: center;
  margin: 32px 0;
`;

const PhoneLink = () => <TealLink href="tel: 18008054023">(800) 805-4023</TealLink>;

const Card = ({ Icon, title, callout }: { Icon: ReactElement | string; title: string; callout: ReactElement }) => (
  <CardStyle>
    <IconEntry>{Icon}</IconEntry>
    <TextEntry>
      <Text.Title size="medium">{title}</Text.Title>
      <Text.Callout color={COLORS.hippo}>{callout}</Text.Callout>
    </TextEntry>
  </CardStyle>
);

const nestedAccordionStyle = css`
  div {
    overflow: visible;
  }

  div:first-of-type {
    border-bottom: none;
  }
`;

const AccordionItemStyle = styled.div<{ applyNestedStyles: boolean }>`
  ${({ applyNestedStyles }) => applyNestedStyles && nestedAccordionStyle}
`;

const ACCORDION_ITEM_NAME = 'more-information';

export const FinePrint = ({
  orderId,
  depositPercent,
  formattedDepositInterval,
  originAddressState,
  brochureLink,
  priceMatchGuaranteeEligible,
  isMoving,
  isMultiDayOrder,
  isLongDistanceOrder,
}: {
  orderId: string;
  depositPercent: number | undefined | null;
  formattedDepositInterval: string | undefined | null;
  originAddressState: string;
  brochureLink: string | undefined | null;
  priceMatchGuaranteeEligible: boolean;
  isMoving: boolean;
  isMultiDayOrder: boolean;
  isLongDistanceOrder: boolean;
}) => {
  const [moreInfoOpen, setMoreInfoOpen] = useState(false);

  return (
    <div>
      <Accordion.Group
        onToggleItem={(_, open) => {
          setMoreInfoOpen(!open);
        }}
        openItems={{ [ACCORDION_ITEM_NAME]: moreInfoOpen }}
      >
        <AccordionItemStyle applyNestedStyles={moreInfoOpen}>
          <Accordion.Item name={ACCORDION_ITEM_NAME} label={<Text.Title size="large">More Information</Text.Title>}>
            <Container>
              {priceMatchGuaranteeEligible && isMoving && (
                <Card
                  Icon={<img src={cash} />}
                  title="Price Match Guarantee"
                  callout={
                    <>
                      We match prices from professional moving competitors. The move details and item inventory must be
                      identical in order to qualify and the quote must not be older than 14 days. If the details of your
                      move change after your Price Match was approved, then you must submit a new request. Please
                      forward the quote you would like matched to{' '}
                      <a href={`mailto:pricematch@clutter.com`}>
                        <Text color={COLORS.tealPrimary}>pricematch@clutter.com</Text>
                      </a>{' '}
                      and allow 48 hours for processing.
                    </>
                  }
                />
              )}
              <Card
                Icon={<LightBulb title="Need a COI?" description="Light Bulb" />}
                title="Need a COI?"
                callout={
                  <>
                    Please click <TealLink href={orderURL(orderId, 'coi')}>here</TealLink> to request a COI (Certificate
                    of Insurance) for your building. Once you fill out the requested materials, we will email a
                    completed COI to you and your building manager.
                  </>
                }
              />
              <Card
                Icon={<PackingTape title="Packing Supplies" description="Packing Tape" />}
                title="Packing Supplies"
                callout={
                  <>
                    Unlimited boxes and packing supplies are included for Packing Help and Full Service moves. This
                    includes small, medium, and large boxes, wardrobe boxes, dish packs, mirror packs, TV boxes, and
                    lamp boxes. Furniture protectors and moving blankets will also be provided and collected back when
                    your move is done.
                  </>
                }
              />
              {depositPercent && formattedDepositInterval && (
                <Card
                  Icon={<CreditCard title="Moving Deposit" description="Credit Card" />}
                  title="Moving Deposit"
                  callout={
                    <>
                      Your deposit is <strong>{depositPercent}% of the minimum cost of your move</strong>, and will be
                      charged to your credit card {formattedDepositInterval} before your scheduled move.
                    </>
                  }
                />
              )}
              {!isMultiDayOrder && (
                <Card
                  Icon={<Calendar title="Rescheduling" description="Calendar with day marked" />}
                  title="Rescheduling"
                  callout={
                    <>
                      To change or reschedule, visit your appointment page. A fee of $100 will be charged for
                      rescheduling within 48 hours of your scheduled move. Your deposit will be applied toward your
                      move. If rescheduled on the day of the appointment, then the fee will be increased to 50% of the
                      total appointment cost or $200, whichever is higher.
                    </>
                  }
                />
              )}
              <Card
                Icon={<Clock title="Cancellation" description="Clock" />}
                title="Cancellation"
                callout={
                  <>
                    Free cancellation until 48 hours before your scheduled move. After this time, your deposit is
                    non-refundable and you will be charged a $100 fee. If canceled on the day of the appointment, then
                    the fee will be increased to 50% of the total appointment cost or $200, whichever is higher.
                  </>
                }
              />
              <Card
                Icon={
                  <ProtectionPlanShield title="In-Truck Protection" description="Shield representing protection plan" />
                }
                title="In-Truck Protection"
                callout={
                  <>
                    Before your move date, you can upgrade your in-truck protection plan by calling our Care team at
                    &nbsp;
                    <PhoneLink />. If you choose not to upgrade, your items will be covered at a rate of $0.60/pound, in
                    the unlikely event an item is damaged.
                  </>
                }
              />
              <Card
                Icon={
                  <PaperworkClipboard
                    title="Day-Of Signatures"
                    description="Paperwork on a clipboard requiring signature"
                  />
                }
                title="Day-Of Signatures"
                callout={
                  <>
                    For your safety and in accordance with state/federal law, Clutter will require your electronic
                    signature before the start and end of your appointment. Please be available to sign your documents
                    via SMS or email at the contact provided to ensure the fastest moving experience.
                  </>
                }
              />
              {originAddressState === 'California' && !isLongDistanceOrder && (
                <Card
                  Icon={<CargoVan title="Travel Time" description="Cargo Van" />}
                  title="Travel Time"
                  callout={
                    <>
                      State law requires us to charge for double the drive-time to transport your items between
                      locations. This protects our customers, by keeping prices low and not charging customers for
                      drives between our warehouses and their home.
                    </>
                  }
                />
              )}
              {brochureLink && (
                <Card
                  Icon={<Mail title="Consumer Brochure" description="Piece of mail" />}
                  title="Consumer Brochure"
                  callout={
                    <>
                      In accordance with state and local law, a copy of the <strong>consumer brochure</strong> is
                      available{' '}
                      <TealLink href={`https://account.clutter.com/${brochureLink}`} target="_blank">
                        here
                      </TealLink>
                    </>
                  }
                />
              )}
            </Container>
          </Accordion.Item>
        </AccordionItemStyle>
      </Accordion.Group>
    </div>
  );
};
