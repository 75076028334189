import React from 'react';
import { DateTime } from 'luxon';

import { Account__TermCommitment } from '@portal/schema';

import { Modal } from '../term_commitment/modal';
import { MessageType, TermCommitmentAction } from '../term_commitment/options';

const options = [
  {
    value: TermCommitmentAction.Reschedule,
    label: 'Reschedule order.',
  },
  {
    value: TermCommitmentAction.Pay,
    label: 'Pay the remaining balance left on my term and close my account.',
  },
  {
    value: TermCommitmentAction.ContactCare,
    label: 'Contact an account manager to discuss my options.',
  },
];

export const TermCommitmentModal: React.FC<{
  termCommitment: Account__TermCommitment;
  scheduled: DateTime;
  onClose(): void;
  onTermCommitmentPayment(): void;
}> = ({ termCommitment, scheduled, onClose, onTermCommitmentPayment }) => (
  <Modal
    messageType={MessageType.Rescheduling}
    termCommitment={termCommitment}
    options={options}
    scheduled={scheduled}
    onClose={onClose}
    onTermCommitmentPayment={onTermCommitmentPayment}
  />
);
