import React, { useState } from 'react';

import { ClaimProvider, DEFAULT_CLAIM } from '@portal/contexts';
import { DetailedClaimFragment, useClaimQuery } from '@portal/schema';
import { Wizard } from './wizard';

export const Claim: React.FC<{
  uuid: string;
}> = ({ uuid }) => {
  const [savedClaim, setSavedClaim] = useState<DetailedClaimFragment | undefined>();
  const { data, loading, error } = useClaimQuery({ variables: { uuid }, fetchPolicy: 'network-only' });

  if (error) {
    throw error;
  }
  if (loading) {
    return null;
  }
  const claim = data!.claim || { ...DEFAULT_CLAIM, uuid };

  let shownClaim = savedClaim || claim;

  // Just after claim has been submitted, show newly fetched claim over existing stale claim
  if (claim && savedClaim && claim.status && claim.status !== 'DRAFT' && savedClaim.status === 'DRAFT') {
    shownClaim = claim;
  }

  return (
    <ClaimProvider
      value={{
        claim: shownClaim,
        onChange: (changer) => {
          setSavedClaim((currentSavedClaim) =>
            typeof changer === 'function' ? changer(currentSavedClaim || claim) : changer,
          );
        },
      }}
    >
      <Wizard />
    </ClaimProvider>
  );
};
