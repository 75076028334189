import wt from '@clutter/wt';

import { CUSTOMER_ID } from '@portal/config/customer';
import { WT_COOKIE_DOMAIN, WT_COOKIE_EXPIRES, WT_TRACKER_URL, WT_TRACKER_DOMAIN } from '@shared/config/wt';

wt.initialize({
  trackerDomain: WT_TRACKER_DOMAIN,
  trackerUrl: WT_TRACKER_URL,
  cookieOptions: {
    domain: WT_COOKIE_DOMAIN,
    expires: WT_COOKIE_EXPIRES,
  },
});
wt.set({ customer_id: CUSTOMER_ID });
wt.track('pageview');

const WT_PAGE_UUID = wt.getUUIDToken();

export { wt, WT_PAGE_UUID };
