import React from 'react';

import { UpgradeDowngrade__SummaryFragment } from '@portal/schema';

import { Name as PlanName } from '@portal/components/plan/name';

export const Name: React.FC<{ summary: UpgradeDowngrade__SummaryFragment }> = ({ summary }) => {
  const entry = summary.proposed.find(
    (proposed) => !summary.current.some((current) => proposed.price === current.price),
  );
  if (!entry) return null;

  return <PlanName plan={entry.price.plan} quantity={entry.quantity} />;
};
