import React, { useState } from 'react';
import { Box, Button, COLORS, Modal, Text } from '@clutter/clean';
import { DisposalPricingSummary } from '@portal/components/orders/disposal_confirmation/pricing_modal';
import { RestrictedDisposalItems } from '@portal/components/orders/home/disposal_restrictions';
import { Tabs } from '@portal/components/helpers/tabs';
import { AppointmentHubDocument, Status, useAttachDisposalMutation } from '@portal/schema';

export function DisposalAttachModal({
  isOpen,
  parentId,
  hasDisposal,
  onClose,
}: {
  parentId: string;
  isOpen: boolean;
  hasDisposal: boolean;
  onClose: () => void;
}) {
  const [tab, setTab] = useState<'pricing' | 'restrictions'>('pricing');
  const [attach, { data, loading, error }] = useAttachDisposalMutation({
    variables: { parentId },
    refetchQueries: [{ query: AppointmentHubDocument, variables: { orderID: parentId } }],
    onCompleted: ({ disposalCreate }) => {
      if (disposalCreate?.status === Status.Ok) {
        onClose();
      }
    },
  });
  return (
    <Modal isOpen={isOpen} handleModalClose={onClose} includeCloseButton>
      <Box.Flex
        padding="48px 24px 24px"
        minWidth="300px"
        maxHeight="90vh"
        width={['calc(100vw - 32px)', 'initial']}
        maxWidth="500px"
        gap="16px"
        flexDirection="column"
        overflow="auto"
      >
        <Box.Flex flexDirection="column" gap="8px">
          <Text.Title size="medium">{!hasDisposal && 'Add '}Disposal Service</Text.Title>
          <Box>
            Clutter can pick up additional items for disposal - you can let us know now or during your appointment.
            Please review pricing and restrictions below.
          </Box>
          <Box textAlign="center" margin="12px 0 0">
            <Button disabled={loading || hasDisposal} onClick={() => attach()}>
              {hasDisposal ? 'Disposal service added' : 'Yes, I have items for disposal'}
            </Button>
          </Box>
          {(error || data?.disposalCreate?.status === Status.Unprocessable) && (
            <Text.Body color={COLORS.toucan}>Something went wrong. Please try again.</Text.Body>
          )}
        </Box.Flex>
        <Tabs>
          <Tabs.Tab onClick={() => setTab('pricing')} active={tab === 'pricing'}>
            Pricing
          </Tabs.Tab>
          <Tabs.Tab onClick={() => setTab('restrictions')} active={tab === 'restrictions'}>
            Restrictions
          </Tabs.Tab>
        </Tabs>
        {tab === 'pricing' ? <DisposalPricingSummary /> : <RestrictedDisposalItems />}
      </Box.Flex>
    </Modal>
  );
}
