import React from 'react';

import { orderURL } from '@portal/config/routes';
import { OrderStatusEnum } from '@portal/schema';
import AFFIRM from '@portal/images/affirm.svg';
import { AppointmentDetailsOrder } from '../full_panel';
import { LineItem, TrackedLinkAction } from '../../line_item';

export const FinancingLineItem: React.FC<{ order: AppointmentDetailsOrder }> = ({ order }) => {
  if (!order.financingIntent?.intended) return null;
  const completed = order.status === OrderStatusEnum.Completed;
  if (completed && order.financingContext.total === 0) return null;

  return (
    <LineItem
      label="Payment Method"
      action={
        <TrackedLinkAction
          params={{
            text: completed ? 'Complete your payment' : 'Learn how to complete your payment',
            url: orderURL(order.id, completed ? 'financing/checkout' : 'financing/summary'),
          }}
        />
      }
    >
      <img src={AFFIRM} alt="Affirm" />
    </LineItem>
  );
};
