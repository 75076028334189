import * as React from 'react';
import { useEffect, useState } from 'react';
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import { Theme } from '@portal/components/helpers/theme';
import { RouteWithTitle } from '@portal/components/shared/route_with_title';
import { loginURL, makespaceConnectURL, makespaceTransitionURL, makespaceURL } from '@portal/config/routes';
import { Spinner } from '@portal/components/helpers';

import { ConnectYourAccount } from './makespace/connect';
import { MakeSpaceTransition } from './makespace_transition';
import { Landing } from './makespace';

export const MakeSpaceFlow: React.FC = () => {
  const [email, setEmail] = useState<string | null>(null);
  const [initialSetupToken, setInitialSetupToken] = useState<string | null>(null);

  const history = useHistory();
  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};
  const { email: emailParam, initial_setup_token: tokenParam } = searchParams;

  useEffect(() => {
    setEmail(emailParam);
    setInitialSetupToken(tokenParam);

    history.replace({
      search: undefined,
    });
  }, []);

  if (location.pathname === makespaceTransitionURL()) {
    return <RouteWithTitle render={() => <MakeSpaceTransition />} title="MakeSpace is now part of Clutter" />;
  }

  if (!email || !initialSetupToken) {
    if (emailParam && tokenParam) {
      return <Spinner />;
    } else {
      return <Redirect to={loginURL()}></Redirect>;
    }
  }

  return (
    <Theme name="default">
      <Switch>
        <RouteWithTitle
          exact
          path={makespaceURL()}
          render={() => <Landing email={email} initialSetupToken={initialSetupToken} />}
          title="MakeSpace"
        />
        <RouteWithTitle
          exact
          path={makespaceConnectURL()}
          render={() => <ConnectYourAccount email={email} initialSetupToken={initialSetupToken} />}
          title="Connect Your Account"
        />
      </Switch>
    </Theme>
  );
};
