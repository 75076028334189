import { Text } from '@clutter/clean';
import React from 'react';
import { Digest } from '../digest';
import { AgreementPreviewModal } from './agreement_preview_modal';

type AgreementProps = {
  uuid: string;
  showPreview: boolean;
  onError(): void;
  onClose(): void;
};

export const Agreement = ({ uuid, showPreview, onError, onClose }: AgreementProps) => (
  <div className="claim-view__agreement">
    <span className="claim-view__section-header">Settlement Agreement</span>

    <Text.Body>
      To accept this settlement for claim <Digest uuid={uuid} /> please click the button below to review and sign the
      Clutter Settlement Agreement.
    </Text.Body>
    <AgreementPreviewModal uuid={uuid} showPreview={showPreview} onError={onError} onClose={onClose} />
  </div>
);
