import * as React from 'react';

import { ClaimItemSelectionFragment, ClaimItemSelectionKind } from '@portal/schema';

export const DEFAULT_CLAIM_ITEM_SELECTION: ClaimItemSelectionFragment = {
  id: '',
  photos: [],
  receipts: [],
  kind: ClaimItemSelectionKind.Damaged,
  tracked: false,
};

export type ClaimItemSelectionChanger =
  | ClaimItemSelectionFragment
  | ((selection: ClaimItemSelectionFragment) => ClaimItemSelectionFragment);

interface IContext {
  page: number;
  total: number;
  selection: ClaimItemSelectionFragment;
  onChange(changer: ClaimItemSelectionChanger): void;
}

const { Provider, Consumer } = React.createContext<IContext>({
  page: 0,
  total: 0,
  selection: DEFAULT_CLAIM_ITEM_SELECTION,
  onChange: (_: ClaimItemSelectionChanger) => {
    /* noop */
  },
});

export { Provider as ClaimItemSelectionProvider };
export { Consumer as ClaimItemSelectionConsumer };
