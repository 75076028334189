import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const RouteWithTitle: React.FC<RouteProps & { title: string }> = ({ title, ...props }) => (
  <>
    <Helmet>
      <title>{`Clutter | ${title}`}</title>
    </Helmet>
    <Route {...props} />
  </>
);
