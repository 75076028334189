import { BREAKPOINTS, FontWeight, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React from 'react';

import { Spinner } from '@portal/components/helpers';
import { Base } from '@portal/components/orders/steps/base';
import { Balance as BalanceDetails } from '@portal/components/orders/term_commitment/balance';
import { useTermCommitmentBalanceQuery } from '@portal/schema';
import { Currency } from '@shared/components/helpers';

const SummaryText = styled(Text.Body)`
  @media (min-width: ${BREAKPOINTS.MD}) {
    text-align: center;
  }
`;

export const Balance: React.FC<{
  scheduled?: DateTime;
  onPrev(): void;
  onNext(): void;
}> = ({ scheduled, onPrev, onNext }) => {
  const { data, loading } = useTermCommitmentBalanceQuery({ variables: { scheduled: scheduled?.toJSON() } });

  if (!data || loading) {
    return <Spinner />;
  }

  if (!data.termCommitmentBalance) {
    throw new Error(`Required 'termCommitmentBalance' is null for '${scheduled}'`);
  }
  const { amountDue } = data.termCommitmentBalance;

  return (
    <Base onNext={onNext} onPrev={onPrev}>
      <Text.Title size="large">Balance Details</Text.Title>
      <BalanceDetails balance={data.termCommitmentBalance} />
      <SummaryText weight={FontWeight.Medium}>
        The <Currency value={amountDue} /> balance will be charged when completing your appointment booking.
      </SummaryText>
    </Base>
  );
};
