import { Order__ContactKind, Order__PickupVehicleKind } from '@portal/schema';

export const KIND_RADIO_OPTIONS = [
  { value: Order__ContactKind.MovingCompany, label: 'Yes' },
  { value: Order__ContactKind.Individual, label: 'No' },
];

export const VEHICLE_RADIO_OPTIONS = [
  { value: Order__PickupVehicleKind.PersonalVehicle, label: 'Personal vehicle (sedan, SUV, pickup truck, etc)' },
  { value: Order__PickupVehicleKind.RentalTruck, label: 'Rental truck (U-Haul)' },
  { value: Order__PickupVehicleKind.ProfessionalMovingTruck, label: 'Professional moving truck' },
];

export const DISPLAYABLE_VEHICLE_MAPPING = {
  [Order__PickupVehicleKind.PersonalVehicle]: 'Personal vehicle (sedan, SUV, pickup truck, etc)',
  [Order__PickupVehicleKind.RentalTruck]: 'Rental truck (U-Haul)',
  [Order__PickupVehicleKind.ProfessionalMovingTruck]: 'Professional moving truck',
};
