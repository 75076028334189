import { Button, COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { TrackedClick } from '@portal/components/wt/tracked_click';
import { orderURL } from '@portal/config/routes';
import { Account__CancelIntent__Reason as AccountCancelIntentReason } from '@portal/schema';
import { AppointmentDetailsOrder } from './full_panel';

export const AppointmentDetailsButtons: React.FC<{
  order: AppointmentDetailsOrder;
}> = ({ order }) => {
  const history = useHistory();

  const hideButtons =
    order.accountCancelIntent?.reason === AccountCancelIntentReason.PayToVacate || !!order.bookingPartner;

  if (hideButtons) {
    return null;
  }

  const showRescheduleButton = !order.multiDay;

  return (
    <Wrapper>
      {showRescheduleButton && (
        <TrackedClick params={buttonTrackingParams(order.id, true)}>
          <LeftButton
            kind="secondary"
            size="small"
            fullWidth={true}
            disabled={!order.permissions.reschedulable}
            onClick={() => {
              history.push(orderURL(order.id, 'reschedule'));
            }}
          >
            Reschedule
          </LeftButton>
        </TrackedClick>
      )}
      <TrackedClick params={buttonTrackingParams(order.id, false)}>
        <RightButton
          kind="secondary"
          size="small"
          fullWidth={true}
          disabled={!order.permissions.cancelable}
          onClick={() => {
            history.push(orderURL(order.id, 'cancel'));
          }}
        >
          Cancel
        </RightButton>
      </TrackedClick>
    </Wrapper>
  );
};

// WTEventTracking Helpers

function buttonTrackingParams(orderID: string, isRescheduling: boolean) {
  return {
    pageName: 'portal:appointment_details',
    container: 'appointment_details',
    action: 'click',
    objectType: 'button',
    objectName: isRescheduling ? 'reschedule_appointment' : 'cancel_appointment',
    label: isRescheduling ? 'Reschedule' : 'Cancel',
    value: isRescheduling ? orderURL(orderID, 'reschedule') : orderURL(orderID, 'cancel'),
    order_id: orderID,
  };
}

// Styling

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LeftButton = styled(Button)`
  margin-right: 8px;
`;

const RightButton = styled(Button)`
  margin-left: 8px;
  color: ${COLORS.toucan};
  border-color: ${COLORS.toucan};
  &:hover {
    color: ${COLORS.toucan};
    background-color: ${COLORS.flower};
  }
`;
