import styled from '@emotion/styled';
import React, { useState } from 'react';
import { DateTime } from 'luxon';

import { client } from '@portal/libraries/apollo';
import {
  Account__TermCommitment,
  Maybe,
  Status,
  useBuildTermCommitmentCustomerTicketMutation,
  useTermCommitmentBalanceLazyQuery,
} from '@portal/schema';
import { Modal as BSModal } from '@shared/components/bootstrap';

import { ITermCommitmentRadioOption, MessageType, TermCommitmentAction } from './options';
import { Submitted } from './submitted';
import { TermCommitmentBalance } from './balance';
import { Pay } from './pay';
import { Selection } from './selection';

enum ModalStep {
  SelectOption = 'SELECTION',
  PayBalance = 'PAY',
  TicketSubmitted = 'TICKET_SUBMITTED',
}

const ContentContainer = styled.div`
  padding: 8px;
`;

export const Modal: React.FC<{
  messageType: MessageType;
  termCommitment: Account__TermCommitment;
  options: ITermCommitmentRadioOption[];
  scheduled: DateTime;
  onClose(): void;
  onTermCommitmentPayment?(): void;
}> = ({ messageType, termCommitment, options, scheduled, onClose, onTermCommitmentPayment }) => {
  const [action, setAction] = useState<TermCommitmentAction>();
  const [error, setError] = useState<Maybe<string>>();
  const [step, setStep] = useState<ModalStep>(ModalStep.SelectOption);
  const [balance, setBalance] = useState<TermCommitmentBalance>();

  const [createCustomerTicket, { loading: buildingTicket }] = useBuildTermCommitmentCustomerTicketMutation({ client });
  const [fetchTermCommitmentBalance, { loading: loadingBalance }] = useTermCommitmentBalanceLazyQuery();

  const onSelection = async () => {
    setError(undefined);
    if (action === TermCommitmentAction.Pay) {
      const response = await fetchTermCommitmentBalance({ variables: { scheduled: scheduled?.toJSON() } });
      if (response?.data?.termCommitmentBalance) {
        setBalance(response?.data?.termCommitmentBalance);
        setStep(ModalStep.PayBalance);
      } else {
        throw new Error(`Required 'termCommitmentBalance' is null for '${scheduled}'`);
      }
    } else if (action === TermCommitmentAction.ContactCare) {
      const response = await createCustomerTicket();
      if (response?.data?.buildTicket) {
        if (response.data.buildTicket.status === Status.Unprocessable) {
          setError(response.data.buildTicket.error);
        } else {
          setStep(ModalStep.TicketSubmitted);
        }
      }
    } else {
      onClose();
    }
  };

  const loading = buildingTicket || loadingBalance;

  return (
    <BSModal onClose={onClose} keyboard={false} backdrop="static">
      <BSModal.Content>
        <BSModal.Body>
          <ContentContainer>
            {step === ModalStep.SelectOption && (
              <Selection
                messageType={messageType}
                termCommitment={termCommitment}
                options={options}
                action={action}
                error={error}
                loading={loading}
                setAction={setAction}
                onSelection={onSelection}
              />
            )}
            {step === ModalStep.PayBalance && (
              <Pay
                balance={balance!}
                loading={loading}
                scheduled={scheduled}
                onBack={() => setStep(ModalStep.SelectOption)}
                onTermCommitmentPayment={onTermCommitmentPayment!}
              />
            )}
            {step === ModalStep.TicketSubmitted && <Submitted />}
          </ContentContainer>
        </BSModal.Body>
      </BSModal.Content>
    </BSModal>
  );
};
