/* eslint-disable no-underscore-dangle */
import * as React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, Text, FontWeight, UnstyledButton, toggleStyleValue, mq } from '@clutter/clean';
import { CustomerItemFragment, InventoryItemFragment, InventoryItemGroupFragment } from '@portal/schema';
import { imagesFor, isItemGroup } from '@portal/components/items/utilities/customer_item';

import { ImageCarousel } from './image_carousel';
import { LARGE_HEIGHT, LARGE_WIDTH, SMALL_HEIGHT, SMALL_WIDTH } from './layout';

const PrimaryAction = styled(UnstyledButton)<{ selected: boolean }>`
  border-top: 1px solid ${COLORS.grayBorder};
  color: ${COLORS.tealPrimary};
  text-align: center;
  background: ${toggleStyleValue('selected', COLORS.tealBackground, COLORS.cloud)};
  width: 100%;

  ${mq({
    padding: ['8px 12px', null, null, '12px'],
  })}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  ${mq({ width: [SMALL_WIDTH, null, null, LARGE_WIDTH], height: [SMALL_HEIGHT, null, null, LARGE_HEIGHT] })};

  &:hover {
    & > a {
      text-decoration: none;

      & > ${PrimaryAction} {
        color: ${COLORS.__primaryButtonHover};
      }
    }
  }
`;

export const Barcode = styled.span`
  color: ${COLORS.hippo};
`;

export const NameContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export type InventoryFragment = InventoryItemFragment | InventoryItemGroupFragment;

const DEFAULT_SELECTED = 'Selected';
const DEFAULT_UNSELECTED = 'Select';

export const itemNameText = (item: InventoryFragment | CustomerItemFragment) => {
  if (isItemGroup(item)) {
    return item.name || item.category?.name;
  } else if (item.name) {
    return item.name;
  } else {
    return (item.category?.name || '') + '#' + item.barcode.value.slice(-4);
  }
};

const ItemName: React.FC<{ item: InventoryFragment | CustomerItemFragment }> = ({ item }) => {
  if (isItemGroup(item)) return <NameContainer>{item.name || item.category?.name}</NameContainer>;
  else if (item.name) return <NameContainer>{item.name}</NameContainer>;
  else {
    return (
      <NameContainer>
        {item.category?.name} <Barcode>#{item.barcode.value.slice(-4)}</Barcode>
      </NameContainer>
    );
  }
};

export const Content: React.FC<{
  item: InventoryFragment | CustomerItemFragment;
  selected: boolean;
  selectedText?: string;
  unselectedText?: string;
  disabled?: boolean;
}> = ({ item, selected, selectedText = DEFAULT_SELECTED, unselectedText = DEFAULT_UNSELECTED, disabled = false }) => (
  <>
    <ImageCarousel images={imagesFor(item)} />
    <Box textAlign="center" padding="8px" color={COLORS.panther}>
      <Text.Caption weight={FontWeight.Medium}>
        <ItemName item={item} />
      </Text.Caption>
    </Box>
    <PrimaryAction selected={selected}>
      {disabled ? (
        <Text.Button color={COLORS.storm}>{'Disabled'}</Text.Button>
      ) : (
        <Text.Button>{selected ? selectedText : unselectedText}</Text.Button>
      )}
    </PrimaryAction>
  </>
);

export const ItemCard = {
  Container,
  Content,
  ItemName,
};
