import { DateTime, Interval } from 'luxon';
import React, { useEffect, useRef } from 'react';

import { Calendar } from '@shared/components/calendar/calendar';
import { DateSelector, Spinner } from '@portal/components/helpers';
import { DateAvailability } from '@portal/components/helpers/scheduled_date_picker';
import { Spacer } from '@shared/components/helpers';

import { Pricing__LaborRate } from '@portal/schema';
import { useTrack } from '@clutter/wt';
import { EventSchema, Portal__DateSelected } from '@shared/wt';
import { Header } from './header';
import { LaborRateCalendarEntry } from './labor_rate_calendar_entry';
import { LaborRateData } from '../orders/steps/scheduled';
import { LaborRateInfoBanner } from './labor_rate_info_banner';
import { Legend } from './calendar/legend';
import { UnavailableDate } from './unavailable_date';

export const LaborRateScheduledDatePicker: React.FC<{
  indexedAvailabilities: Map<string, DateAvailability>;
  date?: DateTime;
  initialDateRequest?: DateTime;
  from?: DateTime;
  till?: DateTime;
  laborRateData?: LaborRateData;
  baseLaborRate: Pricing__LaborRate;
  showNav: boolean;
  loading: boolean;
  includeWaitlist: boolean;
  rescheduling: boolean;
  showUnavailableBanner: boolean;
  trackDateSelection: boolean;
  overrideUnavailability: boolean;
  setOverrideUnavailability(overrideUnavailability: boolean): void;
  onDate(date: DateTime): void;
  onInitialDateRequest(date: DateTime): void;
  onLaborRate(laborRateData: LaborRateData): void;
  onInterval(intervalConfig?: { interval: Interval; forced: boolean }): void;
  onPrevious?(): void;
  onNext?(): void;
}> = ({
  indexedAvailabilities,
  date,
  initialDateRequest,
  from,
  till,
  laborRateData,
  baseLaborRate,
  showNav,
  loading,
  includeWaitlist,
  rescheduling,
  showUnavailableBanner,
  trackDateSelection,
  overrideUnavailability,
  setOverrideUnavailability,
  onDate,
  onInitialDateRequest,
  onInterval,
  onLaborRate,
  onNext,
  onPrevious,
}) => {
  const track = useTrack();
  const initialDateRequestRef = useRef<DateTime>();
  const trackInitialDateRequest = useRef<boolean>();

  const dateDisabled = (value: DateTime) =>
    value <= DateTime.local() ||
    !indexedAvailabilities.get(value.toISODate()) ||
    !!indexedAvailabilities.get(value.toISODate())?.blocked;
  const dateSelected = (value: DateTime) => !!date && date.toISODate() === value.toISODate();
  const dateBooked = (value: DateTime) =>
    !(indexedAvailabilities.get(value.toISODate())?.available || indexedAvailabilities.get(value.toISODate())?.blocked);

  const showLaborRateBanner = date && laborRateData?.laborRate && !dateDisabled(date) && !dateBooked(date);
  const availabilityLoading = date && (loading || indexedAvailabilities.size === 0);

  const handleInitialDateRequest = (initialDate: DateTime) => {
    onDate(initialDate);
    onInterval(undefined);
    onInitialDateRequest(initialDate);
  };

  useEffect(() => {
    if (!initialDateRequest) return;
    const availability = indexedAvailabilities.get(initialDateRequest.toISODate());

    if (initialDateRequest !== initialDateRequestRef.current) trackInitialDateRequest.current = true;

    if (trackDateSelection && availability && trackInitialDateRequest.current) {
      const selectedLaborRateData = availability.laborRateData;

      const params: Portal__DateSelected = {
        schema: EventSchema.Portal__DateSelected,
        action: 'click',
        metadata: {
          date: initialDateRequest.toISODate(),
          is_waitlisted: availability.waitlisted || false,
          is_available: availability.available || false,
          hourly_price: selectedLaborRateData && selectedLaborRateData.laborRate.amount,
          has_hourly_price_adjusted: selectedLaborRateData && selectedLaborRateData.perMoverHourAdjustmentAmount !== 0,
          is_initial_date_requested: true,
        },
      };
      track(params);
      trackInitialDateRequest.current = false;
    }

    initialDateRequestRef.current = initialDateRequest;
  }, [indexedAvailabilities, initialDateRequest, trackDateSelection, track]);

  return (
    <>
      <Header tag="h2">Choose an appointment date</Header>
      <Spacer height="4rem" />
      {!rescheduling && (!date?.isValid || availabilityLoading) && <DateSelector onDate={handleInitialDateRequest} />}
      {availabilityLoading && <Spinner />}
      {showUnavailableBanner && (
        <UnavailableDate
          date={date!}
          overrideUnavailability={overrideUnavailability}
          setOverrideUnavailability={setOverrideUnavailability}
        />
      )}
      {date && from && till && (
        <>
          <Legend includeWaitlist={includeWaitlist} />
          <Calendar
            from={from}
            till={till}
            showNav={showNav}
            disabled={dateDisabled}
            selected={dateSelected}
            booked={dateBooked}
            onSelect={(value) => {
              onDate(value);
              onInterval(undefined);
              const selectedLaborRateData = indexedAvailabilities.get(value.toISODate())?.laborRateData;
              if (selectedLaborRateData) onLaborRate(selectedLaborRateData);

              if (trackDateSelection) {
                const params: Portal__DateSelected = {
                  schema: EventSchema.Portal__DateSelected,
                  action: 'click',
                  metadata: {
                    date: value.toISODate(),
                    is_waitlisted: indexedAvailabilities.get(value.toISODate())?.waitlisted || false,
                    is_available: indexedAvailabilities.get(value.toISODate())?.available || false,
                    hourly_price: selectedLaborRateData && selectedLaborRateData.laborRate.amount,
                    has_hourly_price_adjusted:
                      selectedLaborRateData && selectedLaborRateData.perMoverHourAdjustmentAmount !== 0,
                    is_initial_date_requested:
                      initialDateRequest && initialDateRequest.toISODate() === value.toISODate(),
                  },
                };
                track(params);
              }
            }}
            children={({ date: value, selected, disabled, booked }) => (
              <LaborRateCalendarEntry
                selected={selected}
                date={value}
                disabled={disabled}
                booked={booked}
                amount={indexedAvailabilities.get(value.toISODate())?.laborRateData?.laborRate.amount}
              />
            )}
            onPrevious={onPrevious}
            onNext={onNext}
          />
          {showLaborRateBanner && (
            <LaborRateInfoBanner
              baseLaborRateAmount={baseLaborRate.amount}
              laborRateAmount={laborRateData.laborRate.amount}
              date={date}
            />
          )}
        </>
      )}
    </>
  );
};
