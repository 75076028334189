import {
  CustomerItemFragment,
  InventoryListItemGroupFragment,
  InventoryItemFragment,
  InventoryItemGroupFragment,
  ImageFragment,
} from '@portal/schema';
import { uniqBy } from 'lodash';

type InventoryGroupFragment = InventoryListItemGroupFragment | InventoryItemGroupFragment;
type InventoryFragment = InventoryItemFragment | InventoryItemGroupFragment | CustomerItemFragment;

const ITEM_GROUP_TYPENAME = 'ItemGroup';

export const isItemGroup = (item: InventoryFragment): item is InventoryGroupFragment =>
  item.__typename === ITEM_GROUP_TYPENAME;

export const imagesFor = (item: InventoryFragment) => {
  let images: ImageFragment[] = [];
  if (item.__typename === 'Item') {
    if (item.hero === 0) return item.images;
    const clone = item.images.slice(0);
    const hero = clone.splice(item.hero, 1);
    return [...hero, ...clone];
  }
  if (item.__typename === 'ItemGroup') {
    item.items.forEach((subItem) => images.push(...subItem.images));
    if (item.heroImage) images.unshift(item.heroImage);
    images = uniqBy(images, (image) => image.id);
  }
  return images;
};
