import React from 'react';
import { compact } from 'lodash';
import { Text } from '@clutter/clean';

import styled from '@emotion/styled';
import { Address, OrderTypeEnum } from '@portal/schema';

const AddressBody = styled.div`
  margin-bottom: 24px;
`;

type AddressViewProps = {
  address: Pick<Address, 'aptsuite' | 'street' | 'city' | 'state' | 'zip'>;
};

export const AddressView: React.FC<{ address: AddressViewProps; type: OrderTypeEnum }> = ({ address, type }) => {
  const { aptsuite, street, city, state, zip } = address.address;

  return (
    <>
      <Text.Title size="medium">{type === OrderTypeEnum.Return ? 'Shipment' : 'Return'} Address</Text.Title>
      <AddressBody>
        <Text.Body>{compact([street, aptsuite]).join(' ')}</Text.Body>
        <Text.Body>{`${city}, ${state}, ${zip}`}</Text.Body>
      </AddressBody>
      <hr />
    </>
  );
};
