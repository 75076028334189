import { IEstimatedItem } from '@shared/types';

export const buildEstimatedItemInput = ({
  id,
  categoryId,
  quantity,
  customCategoryName,
  notes,
  length,
  width,
  height,
  sizingSelection,
}: IEstimatedItem) => ({
  id,
  categoryId,
  quantity,
  customCategoryName,
  notes,
  length,
  width,
  height,
  sizingSelection,
});
