import { wt } from '@portal/initializers/wt';
import { sumBy } from 'lodash';
import React from 'react';
import styled from '@emotion/styled';
import { Box, COLORS, FontWeight, mq, Text, TextButton, ToggleCaret } from '@clutter/clean';
import { EstimationMovingQuoteFragment, Moving__QuoteCuftSourceEnum } from '@portal/schema';
import { EIGHT_HOURS_IN_SECONDS, ITEM_INVENTORY_PAGE_NAME } from '@portal/components/orders/estimation/item_inventory';
import {
  Timeline,
  TimelineSection,
} from '@portal/components/orders/estimation/virtual_walkthrough/item_inventory/timeline';
import clock from '@portal/images/clock.svg';
import moverWaving from '@portal/images/mover_waving.svg';
import cargoVan from '@portal/images/cargo_van.svg';
import lightbulbRotated from '@portal/images/illustrations/light_bulb_rotated.svg';
import { Currency, Pluralize } from '@shared/components/helpers';

const BLANK_SECTIONS = [
  {
    key: 'pack',
    seconds: 0,
    label: `Pack:`,
  },
  {
    key: 'drive',
    seconds: 0,
    label: `Drive:`,
  },
  {
    key: 'unpack',
    seconds: 0,
    label: `Unpack:`,
  },
];

const ContainerWithError = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 80px;
`;

const Container = styled.div`
  box-shadow: 0px -9px 24px rgb(117 117 117 / 0.15);
  background: ${COLORS.cloud};
  ${mq({ padding: ['0 12px', null, '0 24px'] })};
  z-index: 10;
`;

const Illustration = styled.img`
  ${mq({
    display: ['none', null, 'block'],
  })}
  height: 24px;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DesktopText = styled(Text.Callout)`
  padding: 4px;
  ${mq({ display: ['none', null, 'block'] })}
`;

const ContentText = styled(Text.Callout)`
  padding: 4px;
`;

const InfoDrawer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${mq({ padding: ['12px 0 0 0', null, '12px 0'] })}
  cursor: pointer;
`;

const DetailContainer = styled.div<{ visible: boolean }>`
  transition: all 0.2s ease-in-out;
  transition-property: max-height, opacity;
  overflow-y: auto;
  max-height: ${({ visible }) => (visible ? '50vh' : '0')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
`;

const ToggleIcon = styled(ToggleCaret)`
  left: 3px;
  top: 3px;
  position: relative;
  flex-shrink: 0;
`;

const DetailButton = styled.div`
  position: relative;
  bottom: 14px;
  &:focus,
  &.focus-visible {
    box-shadow: 0 0 0px 3px white, 0 0 0px 6px ${COLORS.tealBrand};
    outline: none;
  }

  &:focus:not(.focus-visible) {
    box-shadow: none;
  }

  &:hover {
    color: ${COLORS.tealBrand};
  }

  &:before {
    content: '';
    position: absolute;
    left: -8px;
    top: -8px;
    width: 100%;
    margin: 16px 8px;
  }
`;

const ErrorSnack = styled.div`
  position: relative;
  left: 50%;
  width: fit-content;
  background: ${COLORS.toucan};
  color: ${COLORS.cloud};
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 4px 8px;
  min-height: 50px;
  min-width: 300px;
  border-radius: 4px;
  margin-bottom: 8px;
  transform: translateX(-50%);
`;

const buildSections = ({
  estimatedPackDuration,
  deadtimeDuration,
  drivetimeDuration,
  estimatedUnpackDuration,
}: {
  estimatedPackDuration: number;
  deadtimeDuration: number;
  drivetimeDuration: number;
  estimatedUnpackDuration: number;
}): TimelineSection[] => [
  {
    key: 'pack',
    seconds: estimatedPackDuration,
    label: `Pack:`,
  },
  {
    key: 'drive',
    seconds: drivetimeDuration + deadtimeDuration,
    label: `Drive:`,
  },
  {
    key: 'unpack',
    seconds: estimatedUnpackDuration,
    label: `Unpack:`,
  },
];

export const EstimatorBar: React.FC<{
  orderID: string;
  quote: EstimationMovingQuoteFragment;
  whiteGloveTestEligible: boolean;
  onCustomize(): void;
}> = ({ orderID, quote, whiteGloveTestEligible, onCustomize }) => {
  const [showDetails, setShowDetails] = React.useState(false);
  const {
    eligibility,
    deadtimeDuration,
    drivetimeDuration,
    estimatedPackDuration,
    estimatedUnpackDuration,
    estimatedOrderCost,
    minimumOrderCost,
    laborRate,
    moverSelection,
    movingConfiguration,
    truckCount,
  } = quote;

  const quoteEligible =
    eligibility &&
    typeof estimatedOrderCost === 'number' &&
    typeof minimumOrderCost === 'number' &&
    laborRate &&
    typeof moverSelection === 'number' &&
    typeof truckCount === 'number' &&
    typeof drivetimeDuration === 'number' &&
    typeof estimatedPackDuration === 'number' &&
    typeof estimatedUnpackDuration === 'number' &&
    movingConfiguration;

  const hasDoubleDriveTime = movingConfiguration?.travelRateMultiplier === 2;

  const isRoomQuote = quote.cuftSource === Moving__QuoteCuftSourceEnum.Rooms;

  let sections;
  if (isRoomQuote) {
    sections = BLANK_SECTIONS;
  } else if (quoteEligible) {
    sections = buildSections({ estimatedPackDuration, deadtimeDuration, drivetimeDuration, estimatedUnpackDuration });
  }

  const showEstimate =
    quoteEligible && sumBy(sections, 'seconds') > movingConfiguration.requiredLaborDuration && !isRoomQuote;

  return (
    <ContainerWithError>
      {quote.cuftSource === Moving__QuoteCuftSourceEnum.Items &&
        (quote?.estimatedOrderDuration ?? 0) > EIGHT_HOURS_IN_SECONDS && (
          <ErrorSnack>
            <Text.Callout weight={FontWeight.Medium}>
              Remove items {(moverSelection ?? 0) < 6 && 'or add movers'} to continue
            </Text.Callout>
          </ErrorSnack>
        )}
      <Container>
        <InfoDrawer
          onClick={() => {
            wt.track({
              action: 'click',
              objectName: 'show_details_button',
              objectType: 'button',
              pageName: ITEM_INVENTORY_PAGE_NAME,
              container: 'estimator_bar',
              label: 'Show details',
              order_id: orderID,
            });
            setShowDetails(!showDetails);
          }}
        >
          <Box.Flex gap="8px" alignItems="center">
            <Text.Title size="extraSmall">
              {quoteEligible ? (
                <>
                  {showEstimate ? (
                    <>
                      Estimate: <Currency precision={0} value={estimatedOrderCost} />
                    </>
                  ) : (
                    <>
                      Minimum: <Currency precision={0} value={minimumOrderCost} />
                    </>
                  )}
                </>
              ) : (
                '-'
              )}
            </Text.Title>
            <DetailButton>
              <ToggleIcon isOpen={showDetails} aria-label="Show details" />
            </DetailButton>
          </Box.Flex>
          <Box.Flex
            alignSelf="flex-start"
            gap={['0', '0', '8px']}
            flexDirection={['column', 'column', 'row']}
            alignItems={['flex-end', 'flex-end', 'center']}
          >
            <ContentContainer>
              <Illustration src={clock} alt="" />
              <DesktopText>{quoteEligible ? <Currency precision={0} value={laborRate.amount} /> : '-'}/hr</DesktopText>
            </ContentContainer>
            <ContentContainer>
              <Illustration src={moverWaving} alt="" />
              <ContentText>
                {quoteEligible ? <Pluralize count={moverSelection} plural="Movers" singular="Mover" /> : '-'}
              </ContentText>
            </ContentContainer>
            <ContentContainer>
              <Illustration src={cargoVan} alt="" />
              <DesktopText>
                {quoteEligible ? <Pluralize count={truckCount} plural="Trucks" singular="Truck" /> : '-'}
              </DesktopText>
            </ContentContainer>
            {!whiteGloveTestEligible && (
              <Box.FlexItem>
                <TextButton
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    wt.track({
                      action: 'click',
                      objectName: 'customize_button',
                      objectType: 'button',
                      pageName: ITEM_INVENTORY_PAGE_NAME,
                      container: 'estimator_bar',
                      label: 'Customize',
                      value: moverSelection,
                      order_id: orderID,
                    });
                    onCustomize();
                  }}
                >
                  Customize
                </TextButton>
              </Box.FlexItem>
            )}
          </Box.Flex>
        </InfoDrawer>
        <DetailContainer visible={showDetails}>
          <Box.Flex
            flexDirection={['column', 'column', 'row']}
            padding="0 0 20px 0"
            gap={['16px', null, null, '40px']}
            justifyContent={['initial', null, 'space-between']}
          >
            <Box width={['initial', null, '60%']}>
              <Text.Body>
                Tips are not included in this estimate. Once your appointment is complete, you’ll receive a text message
                with a link to rate your Clutter team and add a tip.
              </Text.Body>
            </Box>
            {hasDoubleDriveTime && (
              <Box
                borderRadius="4px"
                border={`1px solid ${COLORS.dust}`}
                padding="12px"
                width={['initial', null, '40%']}
              >
                <Box.Flex gap="8px">
                  <img src={lightbulbRotated} alt="" width="30px" height="36px" />
                  <Box>
                    <Text.Callout weight={FontWeight.Medium}>How does drive time work?</Text.Callout>
                    <Text.Caption>
                      State law requires us to charge double the drive time to transport your items between locations.
                      This protects our customers by keeping prices low and not charging for drives between our
                      warehouses and your home.
                    </Text.Caption>
                  </Box>
                </Box.Flex>
              </Box>
            )}
          </Box.Flex>
        </DetailContainer>
        <Timeline
          sections={sections}
          requiredSeconds={quoteEligible ? movingConfiguration.requiredLaborDuration : undefined}
        />
      </Container>
    </ContainerWithError>
  );
};
