import React from 'react';

const ID = 'chevron-right';

export const ChevronRight: React.FC<{ title?: string; color?: string }> = ({ title = 'Next', color = 'black' }) => (
  <svg aria-labelledby={ID} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title id={ID}>{title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.57901 13.6263C6.38236 13.8304 6.05554 13.8304 5.85889 13.6263L5.33426 13.0818C5.14768 12.8882 5.14768 12.5816 5.33426 12.388L9.22783 8.34692C9.41442 8.15327 9.41442 7.84673 9.22783 7.65308L5.33426 3.61204C5.14768 3.41839 5.14768 3.11185 5.33426 2.91819L5.85889 2.3737C6.05554 2.1696 6.38236 2.1696 6.57901 2.3737L11.6657 7.65308C11.8523 7.84673 11.8523 8.15327 11.6657 8.34692L6.57901 13.6263Z"
      fill={color}
    />
  </svg>
);
