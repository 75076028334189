import { createContext, useContext } from 'react';

import { OrderInput } from '@portal/schema';

export const OrderContext = createContext<{
  order?: OrderInput;
}>({});

export const useOrderInput = () => {
  const { order } = useContext(OrderContext);
  if (!order) throw new Error('Attempting to use order input without being defined in context');
  return order;
};
