import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { FontWeight, SelectableCard, mq, Text, InfoModal, Button, Select, Box } from '@clutter/clean';

import basicMaterialsBox from '@portal/images/basic_materials_box.svg';
import standardMaterialsBoxes from '@portal/images/standard_materials_boxes.svg';
import unlimitedMaterialsBoxes from '@portal/images/unlimited_materials_boxes.svg';
import { Spacer } from '@shared/components/helpers';
import {
  MovingMaterialPackageSetEntriesFetchMutation,
  Moving__PackingEnum,
  Moving__PackingMaterialsUpdateAction,
  useMovingMaterialPackageSetEntriesFetchMutation,
  useMovingPackingMaterialsUpdateMutation,
  useMovingPackingHelpUpdateMutation,
  AccountPackage,
  AppointmentHubDocument,
} from '@portal/schema';
import { Spinner } from '@portal/components/helpers';
import { useMovingPackingMaterials } from '@portal/hooks/packing_materials';
import * as Sentry from '@sentry/browser';
import { StepType } from './data';
import { StepContainer } from './step_container';
import { PackingSuppliesAlert } from '../../home/packing_supplies_alert';

const packingHelpOptions = [
  { label: 'All my items', value: Moving__PackingEnum.AllItems },
  {
    label: 'Some of my items',
    value: Moving__PackingEnum.SomeItems,
  },
  {
    label: "I don't need help packing",
    value: Moving__PackingEnum.EverythingIsPacked,
  },
];

enum Bundle {
  Basic = 'Basic',
  Standard = 'Standard',
  Unlimited = 'Unlimited',
}
type PackingMaterialsResult = MovingMaterialPackageSetEntriesFetchMutation['materialPackageSetEntriesFetch'];

const Container = styled.div`
  display: flex;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-top: 36px;
  text-align: center;
  flex-direction: column;
`;

const Card = styled(SelectableCard.Container)`
  ${mq({ margin: ['0 0 24px', null, '0 16px 0 0'] })};
  &:last-of-type {
    margin: 0;
  }
  width: 327px;
  position: relative;
`;

const Icon = styled.img`
  padding-bottom: 20px;
`;

const MaterialsKit: React.FC<{
  title?: string;
  price?: number;
  icon?: string;
  description?: React.ReactNode;
  selected: boolean;
  onSelect(): void;
}> = ({ title, price, icon, description, selected, onSelect }) => (
  <Card selected={selected} onSelect={onSelect}>
    <Box.Flex>
      <Icon src={icon} alt="" />
      <Box.Flex flexDirection="column" margin="0 0 16px 20px" textAlign="left">
        <Box.Flex justifyContent="space-between" alignItems="center" margin="0 0 8px 0">
          <Text.Title size="extraSmall">{title}</Text.Title>
          <Text.Callout weight={FontWeight.Medium}>${price}</Text.Callout>
        </Box.Flex>
        <Text.Callout>{description}</Text.Callout>
      </Box.Flex>
    </Box.Flex>
    <SelectableCard.Toggle actionText="Add to order" selectedActionText="Remove" />
  </Card>
);

export const FREE_BOX_AMOUNT = 10;

export const PackingMaterials: React.FC<StepType> = (props) => {
  const { orderID } = props;
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const [fetchPackingMaterials, { loading }] = useMovingMaterialPackageSetEntriesFetchMutation({
    variables: { orderID },
  });
  const [updatePackingMaterials, { loading: updateLoading }] = useMovingPackingMaterialsUpdateMutation({
    refetchQueries: [{ query: AppointmentHubDocument, variables: { orderID } }],
  });
  const [updatePackingHelp, { loading: packingHelpLoading }] = useMovingPackingHelpUpdateMutation({
    refetchQueries: [{ query: AppointmentHubDocument, variables: { orderID } }],
  });
  const {
    packingMaterials: existingMaterials,
    packingHelp: existingPackingHelp,
    loading: materialsLoading,
  } = useMovingPackingMaterials(orderID);
  const [prices, setPrices] = useState<PackingMaterialsResult>();

  const [selectedBundle, setSelectedBundle] = useState<Bundle | undefined>();
  const [packingHelp, setPackingHelp] = useState<Moving__PackingEnum>(Moving__PackingEnum.EverythingIsPacked);

  const accountPackageToBundleName = (accountPackage: AccountPackage) => {
    switch (accountPackage.name) {
      case `${Bundle.Basic} Packing Supplies`:
        return Bundle.Basic;
      case `${Bundle.Standard} Packing Supplies`:
        return Bundle.Standard;
      case `${Bundle.Unlimited} Packing Supplies`:
        return Bundle.Unlimited;
    }
  };

  useEffect(() => {
    fetchPackingMaterials().then(({ data }) => {
      if (data?.materialPackageSetEntriesFetch) {
        setPrices(data?.materialPackageSetEntriesFetch);
      } else {
        throw new Error('Failed to fetch material package set entries');
      }
    });
  }, []);

  useEffect(() => {
    if (existingMaterials) {
      setSelectedBundle(accountPackageToBundleName(existingMaterials));
    }
  }, [existingMaterials]);

  useEffect(() => {
    if (existingPackingHelp) {
      setPackingHelp(existingPackingHelp);
    }
  }, [existingPackingHelp]);

  const getPrice = (bundle: Bundle) => {
    const entries = prices?.materialPackageSetEntries;
    return entries?.find(({ package: { name } }) => name === `${bundle} Packing Supplies`)?.fixedCost;
  };

  const onSelectBundle = (bundle: Bundle, selected?: boolean) => {
    if (selected) {
      setSelectedBundle(undefined);
    } else {
      setSelectedBundle(bundle);
    }
  };

  const onPackingHelpSelect = async (value: Moving__PackingEnum) => {
    setPackingHelp(value);
    if (value === Moving__PackingEnum.EverythingIsPacked) {
      setSelectedBundle(undefined);
    }
  };

  const packingHelpSelected = (value?: Moving__PackingEnum) =>
    value === Moving__PackingEnum.AllItems || value === Moving__PackingEnum.SomeItems;

  const showKits = packingHelpSelected(packingHelp);

  const resolveModalTitle = () => {
    if (packingMaterialsAction === 'update' && packingHelpAction === 'update') {
      return 'Update packing kit and help?';
    } else if (packingHelpAction === 'remove') {
      return 'Remove packing kit and help?';
    } else if (packingMaterialsAction === 'remove') {
      return 'Remove packing kit?';
    } else if (packingHelpAction === 'update') {
      return 'Update packing help?';
    } else if (packingMaterialsAction === 'update') {
      return 'Update packing kit?';
    }
  };

  const [packingHelpAction, packingMaterialsAction] = React.useMemo(() => {
    const packingHelpRemoved =
      packingHelpSelected(existingPackingHelp ?? undefined) && packingHelp === Moving__PackingEnum.EverythingIsPacked;
    const packingHelpUpdated = packingHelpSelected(packingHelp) && existingPackingHelp !== packingHelp;
    const packingMaterialsRemoved = existingMaterials && !selectedBundle;
    const packingMaterialsAdded = !existingMaterials && selectedBundle;
    const packingMaterialsUpdated =
      packingMaterialsAdded ||
      (existingMaterials && selectedBundle && accountPackageToBundleName(existingMaterials) !== selectedBundle);

    let helpAction;
    let materialsAction;

    if (packingHelpRemoved) {
      helpAction = 'remove';
    } else if (packingHelpUpdated) {
      helpAction = 'update';
    }
    if (packingMaterialsRemoved) {
      materialsAction = 'remove';
    } else if (packingMaterialsUpdated) {
      materialsAction = 'update';
    }
    return [helpAction, materialsAction];
  }, [existingMaterials, existingPackingHelp, selectedBundle, packingHelp]);

  const updateBundle = async () => {
    if (prices?.materialPackageSetEntries && selectedBundle) {
      const packageSetEntry = prices.materialPackageSetEntries.find(
        (packageSet) => packageSet.package.name === `${selectedBundle} Packing Supplies`,
      );
      const { data: response } = await updatePackingMaterials({
        variables: {
          packageSetEntryID: packageSetEntry!.id,
          orderID,
          action: Moving__PackingMaterialsUpdateAction.Update,
        },
      });
      if (response?.movingPackingMaterialsUpdate?.status !== 'OK') {
        throw new Error(`${response?.movingPackingMaterialsUpdate?.error}`);
      }
    } else {
      throw new Error('Bundle must be selected and prices must exist to complete');
    }
  };

  const removeBundle = async () => {
    const { data: response } = await updatePackingMaterials({
      variables: {
        orderID,
        action: Moving__PackingMaterialsUpdateAction.Delete,
      },
    });
    if (response?.movingPackingMaterialsUpdate?.status !== 'OK') {
      throw new Error(`${response?.movingPackingMaterialsUpdate?.error}`);
    }
  };

  const updatePackingHelpSelection = async () => {
    const { data } = await updatePackingHelp({
      variables: {
        orderID,
        packingHelp,
      },
    });
    if (data?.movingPackingHelpUpdate?.status !== 'OK') {
      throw new Error(`${data?.movingPackingHelpUpdate?.error}`);
    }
  };

  const confirmSelections = async () => {
    try {
      if (packingHelpAction) {
        await updatePackingHelpSelection();
      }
      if (packingMaterialsAction === 'update') {
        await updateBundle();
      } else if (packingMaterialsAction === 'remove') {
        await removeBundle();
      }
      setShowConfirmModal(false);
      props.next();
    } catch (error) {
      setShowConfirmModal(false);
      Sentry.captureException(error);
    }
  };

  if (materialsLoading) {
    return <Spinner />;
  }

  return (
    <StepContainer
      {...props}
      headline="Do you need help packing?"
      canPrev={false}
      canNext={!loading && !updateLoading && !packingHelpLoading && !!(packingHelpAction || packingMaterialsAction)}
      next={() => setShowConfirmModal(true)}
    >
      {showConfirmModal && (
        <InfoModal isOpen={showConfirmModal} handleModalClose={() => setShowConfirmModal(false)}>
          <Box textAlign="center">
            <Text.Title size="large">{resolveModalTitle()}</Text.Title>
            <Spacer height="24px" />
            <Text.Callout>
              Please note that your final bill will reflect the actual services and materials used on move day. If more
              packing help or boxes are needed than what you've ordered here, we've got you covered.
            </Text.Callout>
            <Spacer height="24px" />
            <Button fullWidth loading={updateLoading} onClick={confirmSelections}>
              Confirm
            </Button>
          </Box>
        </InfoModal>
      )}
      <Box maxWidth="700px" margin="0 auto">
        <Select
          placeholder="Choose an option"
          onChange={(value: any) => onPackingHelpSelect(value as Moving__PackingEnum)}
          value={packingHelp}
          options={packingHelpOptions}
        />
      </Box>
      {showKits && (
        <Container>
          <Text.Title size="extraSmall">Need packing materials?</Text.Title>
          <Box padding={['0 8px', null]}>
            <Text.Callout>
              Get up to {FREE_BOX_AMOUNT} boxes for free or add a kit for less than what you'd pay at Home Depot or
              Lowe’s.
            </Text.Callout>
          </Box>
          <Box.Flex flexDirection={['column', null, 'row']} margin="28px auto 36px">
            <MaterialsKit
              title={Bundle.Basic}
              icon={basicMaterialsBox}
              description={
                <>
                  <b>20 box kit</b> with all you need bubble wrap, tape, and packing paper.
                </>
              }
              price={getPrice(Bundle.Basic)}
              selected={selectedBundle === Bundle.Basic}
              onSelect={() => onSelectBundle(Bundle.Basic, selectedBundle === Bundle.Basic)}
            />
            <MaterialsKit
              title={Bundle.Standard}
              icon={standardMaterialsBoxes}
              description={
                <>
                  <b>30 box kit</b> with all you need bubble wrap, tape, and packing paper.
                </>
              }
              price={getPrice(Bundle.Standard)}
              selected={selectedBundle === Bundle.Standard}
              onSelect={() => onSelectBundle(Bundle.Standard, selectedBundle === Bundle.Standard)}
            />
            <MaterialsKit
              title={Bundle.Unlimited}
              icon={unlimitedMaterialsBoxes}
              description={
                <>
                  <b>Unlimited boxes</b>, bubble wrap, tape, and packing paper.
                </>
              }
              price={getPrice(Bundle.Unlimited)}
              selected={selectedBundle === Bundle.Unlimited}
              onSelect={() => onSelectBundle(Bundle.Unlimited, selectedBundle === Bundle.Unlimited)}
            />
          </Box.Flex>
        </Container>
      )}
      {packingHelp === Moving__PackingEnum.EverythingIsPacked && (
        <Box maxWidth="700px" margin="0 auto">
          <PackingSuppliesAlert
            title="Why add Packing Help?"
            body="Add packing help and materials to help make your move as easy as possible."
          />
        </Box>
      )}
    </StepContainer>
  );
};
