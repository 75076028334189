import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Button } from '@portal/components/shared/button';
import { Banner as ErrorBanner } from '@portal/components/shared/error';
import { Input, InputLabel } from '@portal/components/shared/form';

const FormContent = styled.div`
  padding: 16px 0;
`;

const ResetCTA = styled(Button)`
  margin-top: 16px;
  width: 100%;
  @media (max-width: 600px) {
    margin-top: 8px;
  }
`;

const Error = styled(ErrorBanner)`
  padding: 8px 0;
`;

export const ResetForm: React.FC<{
  loading?: boolean;
  error?: React.ReactElement;
  setError(error?: React.ReactElement): void;
  onReset(email: string): void;
}> = ({ loading, error, setError, onReset }) => {
  const [email, setEmail] = useState<string>('');

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!email) {
      setError(<>Enter your email address below to reset your password.</>);
      return;
    }

    setError(undefined);
    onReset(email);
  };

  return (
    <>
      {error && <Error>{error}</Error>}
      <form onSubmit={onSubmit}>
        <FormContent>
          <InputLabel htmlFor="customer-email">Email</InputLabel>
          <Input
            id="customer-email"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value || '')}
            autoFocus
          />
        </FormContent>
        <ResetCTA kind="primary" size="medium" loading={loading}>
          Reset Password
        </ResetCTA>
      </form>
    </>
  );
};
