import React from 'react';

import { Pricing__Set__AppointmentFees } from '@portal/schema';

import { Options } from '../options';
import { ServiceInfo, supportedServiceType } from './util';
import { formattedServiceType, formatFees } from '../util';

export const Option: React.FC<{
  option: Pricing__Set__AppointmentFees;
  selected: boolean;
  onSelect(label: string): void;
}> = ({ option, selected, onSelect }) => {
  const { serviceType } = option;
  if (!supportedServiceType(serviceType)) {
    return null;
  }

  const { description, icon } = ServiceInfo[serviceType]!;

  const title = formattedServiceType(serviceType);
  const formattedFees = formatFees(option);
  const label = `${title} ${description} ${formattedFees}`;

  return (
    <Options.Card
      title={title}
      description={description}
      price={formattedFees}
      selected={selected}
      onSelect={() => onSelect(label)}
      icon={icon}
    />
  );
};
