import * as React from 'react';

import { InputFormGroup } from '../input_form_group';

export const Stories: React.FC<{
  value: number | null;
  onChange(value: number | null): void;
}> = ({ value, onChange }) => (
  <InputFormGroup
    label="How many stories?"
    type="number"
    step={1}
    min={0}
    value={value ?? ''}
    onChange={(event) => onChange(Number(event.target.value) || null)}
  />
);
