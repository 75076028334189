import React from 'react';

const ID = 'times';

export const Times: React.FC<{ title?: string }> = ({ title = 'Remove' }) => (
  <svg aria-labelledby={ID} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title id={ID}>{title}</title>
    <g clipPath="url(#clip0)">
      <rect
        x="1.63623"
        y="3.05023"
        width="2"
        height="16"
        rx="0.5"
        transform="rotate(-45 1.63623 3.05023)"
        fill="black"
      />
      <rect
        x="3.05029"
        y="14.364"
        width="2"
        height="16"
        rx="0.5"
        transform="rotate(-135 3.05029 14.364)"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
