import * as React from 'react';
import { useContext } from 'react';

import { Button } from '@shared/components/bootstrap';
import { CategoriesContext } from '@shared/components/inventory_capture/categories_context';
import { atLeastOneRoomSelected } from '@shared/components/inventory_capture/steps';

import { IRoomCategory, IRoomSelection } from '@shared/types';
import { QuantifiableOption } from './quantifiable_option';
import { SelectableOption } from './selectable_option';

type Selections = Map<IRoomCategory, Partial<IRoomSelection>>;

export const Picker: React.FC<{
  complete: boolean;
  selections?: Selections;
  onChange(selections: Selections): void;
  onNextClick(): void;
}> = ({ complete, selections = new Map<IRoomCategory, Partial<IRoomSelection>>(), onChange, onNextClick }) => {
  const data = useContext(CategoriesContext);
  if (!data) {
    return null;
  }
  const { roomCategories: categories = [] } = data;

  const nextButtonEnabled = () =>
    categories.every((category) => {
      if (category.countable) {
        return true;
      }
      const selection = selections.get(category);
      return !!selection && selection.selected !== undefined;
    }) && atLeastOneRoomSelected(selections);

  return (
    <>
      {categories.map((category) => {
        const Component = category.countable ? QuantifiableOption : SelectableOption;
        const selection = selections.get(category);
        return (
          <Component
            key={category.id}
            {...selection}
            category={category}
            onChange={(changes: Partial<IRoomSelection>) => {
              onChange(new Map(selections).set(category, { ...selection, ...changes }));
            }}
          />
        );
      })}
      {!complete && (
        <Button block outline kind="primary" disabled={!nextButtonEnabled()} onClick={onNextClick}>
          Next
        </Button>
      )}
    </>
  );
};
