import { Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { AnchorButton } from '@portal/components/shared/button';
import { Rating } from '@portal/schema';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Text.Title)`
  margin: 32px 0;
  text-align: center;
`;

const StyledAnchorButton = styled(AnchorButton)`
  width: 100%;
  max-width: 470px;
`;

const FacilityReviewLinks: Record<string, string> = {
  'Long Island City': 'https://g.page/clutter-lic/review',
  'White Plains': 'https://g.page/clutter-white-plains/review',
  'Brooklyn (East Flatbush)': 'http://g.page/clutter-brooklyn/review',
  Yonkers: 'https://g.page/clutter-yonkers/review',
};

export const ReviewSubmitted: React.FC<{
  rating: Rating;
  facilityName: string;
}> = ({ rating, facilityName }) => {
  let url = '/';
  let cta = 'Back to Account';
  if (rating === Rating.Good && FacilityReviewLinks[facilityName]) {
    url = FacilityReviewLinks[facilityName];
    cta = 'Leave us a review on Google!';
  }

  return (
    <Container>
      <Title size="large">Thanks for your feedback</Title>
      <StyledAnchorButton kind="secondary" size="medium" href={url}>
        {cta}
      </StyledAnchorButton>
    </Container>
  );
};
