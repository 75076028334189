import cn from 'classnames';
import * as React from 'react';

export const FormGroup: React.FC<{
  has?: 'warning' | 'error' | 'success';
  hasFeedback?: boolean;
  inline?: boolean;
  className?: string;
}> = ({ children, has, hasFeedback, className, inline }) => (
  <div
    className={cn('form-group', className, [
      has && `has-${has}`,
      hasFeedback && 'has-feedback',
      inline && 'form-inline',
    ])}
  >
    {children}
  </div>
);
