import * as React from 'react';

import { ClaimItemSelectionKind } from '@portal/schema';

import { ClaimConsumer } from '@portal/contexts';

import { Alert } from '@shared/components/bootstrap';

import { Inventory } from '@portal/components/helpers/inventory';

import { Status } from '@portal/schema';
import { Selector } from './selector';
import { Step } from './step';

const KIND = ClaimItemSelectionKind.Damaged;

export const Damages = () => (
  <ClaimConsumer>
    {({ claim, onChange: onSave }) => (
      <Selector claim={claim} kind={KIND}>
        {({ save, saving, valid, error, selectedIDs, extras, onSelect, onDeselect, onBuild, onChange }) => (
          <Step
            saving={saving}
            valid={valid}
            onSave={async () => {
              const result = await save();
              if (!result) {
                return Status.Unprocessable;
              }
              onSave(result);
              return Status.Ok;
            }}
          >
            <Step.Title>Please select the items that are damaged.</Step.Title>
            <Step.Subtitle>If you are filing a claim for something not listed, you can add an item.</Step.Subtitle>
            {error && <Alert style="danger">{error}</Alert>}
            <Inventory>
              {({ items, loading, query, onSearch }) => (
                <>
                  <Inventory.Search loading={loading} query={query} onSearch={onSearch} />
                  {!!items && (
                    <Inventory.Grid>
                      <Inventory.Items
                        items={items}
                        selectedIDs={selectedIDs}
                        onSelect={onSelect}
                        onDeselect={onDeselect}
                        forReturn={false}
                      />
                      <Inventory.Extras extras={extras} onBuild={onBuild} onChange={onChange} />
                    </Inventory.Grid>
                  )}
                </>
              )}
            </Inventory>
          </Step>
        )}
      </Selector>
    )}
  </ClaimConsumer>
);
