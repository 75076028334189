import cn from 'classnames';
import * as React from 'react';

export const Alert: React.FC<{
  style: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  onClose?(): void;
}> = ({ style, children, onClose }) => (
  <div className={cn('alert', style && `alert-${style}`, onClose && 'alert-dismissible')} role="alert">
    {children}
    {onClose && (
      <button type="button" className="close" onClick={onClose}>
        ×
      </button>
    )}
  </div>
);
