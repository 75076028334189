/* eslint-disable no-underscore-dangle */
import * as React from 'react';

import styled from '@emotion/styled';
import { COLORS, Text } from '@clutter/clean';
import { Spacer } from '@shared/components/helpers';
import { toggleStyleValue } from './css';
import { ExtraInfoTag } from './extra_info_tag';

interface IInfoCardProps {
  header: string | React.ReactNode;
  body: React.ReactNode;
  icon?: React.ReactNode;
  expandText?: string;
  expandableContent?: React.ReactNode;
  className?: string;
  extraInfoTagText?: string | React.ReactNode;
}

const InfoCardContainer = styled.div``;

const CardLayout = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const Content = styled.div`
  flex: 1;
  text-align: left;
`;

const Header = styled(Text.Title)`
  font-family: Recoleta;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: ${COLORS.panther};
`;

const Body = styled(Text.Body)`
  font-family: inherit;
  font-size: 14px;
  line-height: 19px;
  color: ${COLORS.panther};
`;

const Icon = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 8px 0 0;
`;

const ExpandAction = styled.div<{ expanded: boolean }>`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: ${COLORS.tealPrimary};
  width: fit-content;
  position: relative;
  text-transform: uppercase;
  display: ${toggleStyleValue('expanded', 'none', 'block')};
  margin: 8px 0 0 116px;

  &:hover {
    color: ${COLORS.__primaryButtonHover};
  }
`;

const TapTarget = styled.div`
  width: 110px;
  height: 44px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -22px -55px;
  z-index: 2;
`;

const ExpandableContainer = styled.div<{ expanded: boolean }>`
  overflow: hidden;
  transition: max-height 0.8s;
  margin-top: ${toggleStyleValue('expanded', '16px', '0')};
  max-height: ${toggleStyleValue('expanded', '500px', '0')};

  & > div:first-of-type:not(:only-child) {
    margin-bottom: 16px;
  }
`;

export const InfoCard: React.FC<IInfoCardProps> = (props) => {
  const { header, body, icon, expandText, expandableContent, className, extraInfoTagText } = props;

  const [expanded, setExpanded] = React.useState<boolean>(false);

  return (
    <InfoCardContainer className={className}>
      <CardLayout>
        <Icon>{icon}</Icon>
        <Content>
          {extraInfoTagText && (
            <>
              <ExtraInfoTag>{extraInfoTagText}</ExtraInfoTag>
              <Spacer height={'0.5rem'} />
            </>
          )}
          <Header size="extraSmall">{header}</Header>
          <Body>{body}</Body>
        </Content>
      </CardLayout>
      <ExpandAction expanded={expanded}>
        {expandText}
        <TapTarget
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(true);
          }}
        />
      </ExpandAction>
      <ExpandableContainer expanded={expanded}>{expandableContent}</ExpandableContainer>
    </InfoCardContainer>
  );
};
