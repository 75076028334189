import React from 'react';

import { Logo } from '@portal/components/shared/logo';
import { mq } from '@clutter/clean';
import styled from '@emotion/styled';

import { MakespaceConnectForm } from './form';
import { Infographic } from './infographic';
import { Container as BaseContainer } from '../../shared/layout';

const LogoContainer = styled.div`
  ${mq({
    display: ['none', 'none', 'none', 'flex'],
  })}
`;

const Container = styled(BaseContainer)`
  ${mq({
    flexDirection: ['column', 'row'],
  })}
`;

export const ConnectYourAccount: React.FC<{
  email: string;
  initialSetupToken: string;
}> = ({ email, initialSetupToken }) => (
  <>
    <LogoContainer>
      <Logo />
    </LogoContainer>
    <Container hasNavbar={false}>
      <MakespaceConnectForm email={email} initialSetupToken={initialSetupToken} />
      <Infographic />
    </Container>
  </>
);
