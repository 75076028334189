import { useCallback, useEffect, useRef } from 'react';

const NOOP_CALLBACK = () => undefined as any;

/** Wraps a value with a ref that updates for every change. See
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 * for more details.
 * */
export function useLatestRef<T>(value: T) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref;
}

/**
 * Wraps a function within a stable callback to allow passing as a dependency
 * without retriggering effects/memoization/etc.
 *
 * Example:
 *
 * ```ts
 * const onUnmount = useLatestCallback(() => { console.log(props); })
 * useEffect(() => {
 *   return () => onUnmount(); // Will log the last received props on cleanup, but without including all of props as a depencency
 * }, [onUnmount])
 * ```
 */
export function useLatestCallback<P extends any[], R>(fn: (...args: P) => R = NOOP_CALLBACK): (...args: P) => R {
  const fnRef = useRef(fn);
  useEffect(() => {
    fnRef.current = fn;
  });
  return useCallback((...args: P) => fnRef.current(...args), []);
}
