import { COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import * as React from 'react';

import { Heading } from '@portal/components/shared/text';
import { AccountPackageFragment, ItemPricingFragment, PickupEstimateFragment } from '@portal/schema';
import { Maybe } from '@portal/schema';
import { currency, WHOLE_NUMBER_OPTIONS } from '@shared/utils/currency';
import { toggleStyleValue } from './steps/delivery_option/css';

const PriceInfoContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.grayBorder};
`;

const PriceInfoFlex = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
`;

const StyledHeading = styled(Heading)`
  white-space: nowrap;
  margin-bottom: -5px;
`;

const PriceContainer = styled.div`
  text-align: right;
`;

const PriceInfoContainerTitle = styled.div`
  font-weight: bold;
`;

const TruckFeeText = styled.div<{
  strikeThrough?: boolean;
}>`
  font-weight: bold;
  font-size: 14px;
  text-decoration-line: ${toggleStyleValue('strikeThrough', 'line-through', 'none')};
  color: ${toggleStyleValue('strikeThrough', COLORS.hippo, 'auto')};
`;

type PerItemInfoFooterProps = {
  pricingInfo?: Maybe<ItemPricingFragment>;
};

export const isPositive = (value?: string | null) => !value?.includes('-') && value !== '$0';

export const PerItemInfoFooter = ({ pricingInfo }: PerItemInfoFooterProps) => {
  if (!pricingInfo) {
    return null;
  }

  const { price, pickupEstimate, returnedItemsCount } = pricingInfo;
  if (pickupEstimate?.lowestEstimatedItems === 0 && returnedItemsCount === 0) {
    return null;
  }

  const { appointmentFee } = pricingInfo;

  return (
    <PriceInfoContainer>
      <PriceInfoFlex>
        <PricingInfoDescription pricingInfo={pricingInfo} />
        <PriceContainer>
          <StyledHeading level={3} size="small">
            {price}
          </StyledHeading>
          {(!appointmentFee || !isPositive(appointmentFee)) && (
            <TruckFeeText strikeThrough={!isPositive(appointmentFee)}>+ service charge</TruckFeeText>
          )}
        </PriceContainer>
      </PriceInfoFlex>
    </PriceInfoContainer>
  );
};

type PricingInfoDescriptionProps = {
  pricingInfo: ItemPricingFragment;
};

const ServiceCharge: React.FC<Pick<ItemPricingFragment, 'appointmentFee' | 'accountPackages'>> = ({
  appointmentFee,
  accountPackages,
}) => {
  const accountPackageDisplay = (accountPackage: AccountPackageFragment) =>
    `${currency(accountPackage.amount, WHOLE_NUMBER_OPTIONS)} ${accountPackage.name.toLocaleLowerCase()} charge`;

  const accountPackageFeesValue = accountPackages.reduce((acc, accountPackage) => acc + accountPackage.amount, 0);
  const appointmentFeeValue = appointmentFee && parseFloat(appointmentFee.substring(1));

  const serviceChargeLineCopy = () => {
    if (appointmentFeeValue && appointmentFeeValue > 0) {
      const totalServiceCharge = accountPackageFeesValue + appointmentFeeValue;
      return `${currency(totalServiceCharge, WHOLE_NUMBER_OPTIONS)} service charge`;
    } else if (accountPackageFeesValue > 0) {
      return accountPackages.map(accountPackageDisplay).join(' + ');
    }
    return null;
  };

  const serviceChargeLine = serviceChargeLineCopy();

  return serviceChargeLine ? (
    <>
      {serviceChargeLine} <br />
    </>
  ) : null;
};

const pluralS = (count: number) => (count > 1 ? 's' : '');

type PickupItemsProps = { pickupEstimate: PickupEstimateFragment; perItemFee: string; large?: boolean };
const PickupItems: React.FC<PickupItemsProps> = ({ pickupEstimate, perItemFee, large = false }) => {
  const { lowestEstimatedItems, highestEstimatedItems } = pickupEstimate;
  const isRange = !!lowestEstimatedItems && !!highestEstimatedItems && lowestEstimatedItems < highestEstimatedItems;

  const pickupItemsDisplay = !!lowestEstimatedItems && !!highestEstimatedItems && (
    <>
      {lowestEstimatedItems}
      {isRange ? ` - ${highestEstimatedItems}` : ''}
    </>
  );

  const moverCopy = large ? '2+ movers' : '1 mover';

  return (
    <>
      {pickupItemsDisplay && (
        <div>
          {pickupItemsDisplay} item{pluralS(highestEstimatedItems)} for pickup requiring {moverCopy} ({perItemFee}
          /item)
        </div>
      )}
    </>
  );
};

type ReturnItemsProps = { itemCount: number; perItemFee: string; large?: boolean };
const ReturnItems: React.FC<ReturnItemsProps> = ({ itemCount, perItemFee, large = false }) =>
  itemCount > 0 ? (
    <div>
      {itemCount} item{pluralS(itemCount)} for return requiring {large ? '2+ movers' : '1 mover'} ({perItemFee}/item)
    </div>
  ) : null;

const PricingInfoDescription: React.FC<PricingInfoDescriptionProps> = ({ pricingInfo }) => {
  const {
    pickupEstimate,
    pickupLargeEstimate,
    returnedItemsCount,
    returnedLargeItemsCount,
    appointmentFee,
    pickupPerItemFee,
    returnPerItemFee,
    pickupLargeItemFee,
    returnLargeItemFee,
    accountPackages,
  } = pricingInfo;

  return (
    <div>
      <PriceInfoContainerTitle>Estimated cost</PriceInfoContainerTitle>
      <ServiceCharge accountPackages={accountPackages} appointmentFee={appointmentFee} />
      {pickupEstimate && pickupPerItemFee && (
        <PickupItems pickupEstimate={pickupEstimate} perItemFee={pickupPerItemFee} />
      )}
      {pickupLargeEstimate && pickupLargeItemFee && (
        <PickupItems pickupEstimate={pickupLargeEstimate} perItemFee={pickupLargeItemFee} large />
      )}
      {returnPerItemFee && <ReturnItems itemCount={returnedItemsCount} perItemFee={returnPerItemFee} />}
      {returnLargeItemFee && <ReturnItems itemCount={returnedLargeItemsCount} perItemFee={returnLargeItemFee} large />}
    </div>
  );
};
