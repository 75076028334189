import React from 'react';

import { planUpdateURL } from '@portal/config/routes';
import { OrderServiceTypeEnum, UpcomingSubscriptionSetFragment } from '@portal/schema';
import { Box } from '@clutter/clean';
import { Spacer } from '@shared/components/helpers';

import { AppointmentDetailsOrder } from '../full_panel';
import { LineItem, TrackedLinkAction } from '../../line_item';
import { wtLinkParams } from './constants';
import { InfoTooltip, TooltipList } from './info_tooltip';

function updatePlanTrackingParams(orderID: string, label: string, objectName: string) {
  return {
    ...wtLinkParams,
    objectName: objectName,
    label: label,
    value: planUpdateURL(),
    order_id: orderID,
  };
}

export const OrderPackingHelpLineItem: React.FC<{
  upcomingSubscriptionSet: UpcomingSubscriptionSetFragment;
  order: AppointmentDetailsOrder;
}> = ({ order }) => {
  const packingHelpCost = order.laborCostDetails?.laborCost;
  const isFullService = order.serviceType === OrderServiceTypeEnum.FullService;

  return (
    <LineItem
      label={
        <Box.Flex flexWrap="wrap">
          Hourly rate
          <Spacer width="6px" inline={true} />
          <InfoTooltip
            text={
              <>
                <p>Our team will disassemble & pack your items. Unlimited packing supplies included!</p>
                <p>Here’s what’s included:</p>
                <TooltipList>
                  <li>
                    <span>{order.movers} professional movers</span>
                  </li>
                  <li>
                    <span>Packing help</span>
                  </li>
                  <li>
                    <span>Furniture disassembly</span>
                  </li>
                  <li>
                    <span>Unlimited packing supplies</span>
                  </li>
                </TooltipList>
              </>
            }
          />
        </Box.Flex>
      }
      action={
        <TrackedLinkAction
          params={{
            text: isFullService ? 'Edit' : 'Upgrade',
            url: planUpdateURL(),
            trackingParams: updatePlanTrackingParams(
              order.id,
              isFullService ? 'Edit' : 'Upgrade',
              'update_packing_help',
            ),
          }}
        />
      }
    >
      {isFullService ? packingHelpCost : 'Not Included'}
    </LineItem>
  );
};
