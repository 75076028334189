import React from 'react';

import { Box, COLORS, FontWeight, RadioCard, Text } from '@clutter/clean';
import { Text as BootstrapText } from '@shared/components/bootstrap';

import { DateTime, Duration, Interval } from 'luxon';
import { AvailabilityFragment } from '@portal/schema';
import { Timeslot } from '@shared/components/timeslot/timeslot';
import { ALL_DAY_DURATION } from '@shared/utils';
import { formatTime } from '@portal/utils/scheduling';

const RadioCardContents: React.FC<{
  label: string;
  description: React.ReactNode;
  formattedPrice?: string;
}> = ({ label, description, formattedPrice }) => (
  <Box.Flex>
    <Box width="100%" margin="0 0 0 16px">
      <Box.Flex justifyContent="space-between" margin="2px 0 4px" flexDirection={['row', null, 'column', 'row']}>
        <Text.Display style={{ fontWeight: `${FontWeight.Semibold}`, fontSize: '16px' }}>{label}</Text.Display>
        {formattedPrice && (
          <Text.Callout weight={FontWeight.Medium} color={COLORS.tealPrimary}>
            {formattedPrice}
          </Text.Callout>
        )}
      </Box.Flex>
      <Text.Caption color={COLORS.hippo}>{description}</Text.Caption>
    </Box>
  </Box.Flex>
);

export const ScheduledIntervalPicker: React.FC<{
  availabilities: AvailabilityFragment[];
  date: DateTime;
  interval?: Interval;
  onInterval(intervalConfig?: { interval: Interval; forced: boolean }): void;
}> = ({ availabilities, date, interval, onInterval }) => {
  const intervalsWithFeeAmounts = availabilities.map((availability) => {
    const start = DateTime.fromISO(availability.datetime, { setZone: true });
    const duration = Duration.fromISO(availability.duration!);
    return { interval: Interval.after(start, duration), feeAmount: availability.feeAmount };
  });

  const matches = intervalsWithFeeAmounts.filter(
    ({ interval: { start } }) => start.year === date.year && start.month === date.month && start.day === date.day,
  );

  const flexibleWindow = matches.find((window) => window.interval.toDuration() >= ALL_DAY_DURATION);
  const nonFlexibleWindows = matches.filter(
    ({ interval: matchingInterval }) => matchingInterval.toDuration() < ALL_DAY_DURATION,
  );

  const nonFlexibleWindowFeeAmount = nonFlexibleWindows.length > 0 ? nonFlexibleWindows[0].feeAmount : undefined;

  if (!flexibleWindow || !nonFlexibleWindowFeeAmount || nonFlexibleWindowFeeAmount.value === 0) {
    return (
      <div>
        <BootstrapText tag="p" style="info">
          <strong>Please select an arrival window.</strong>
        </BootstrapText>
        <Timeslot
          date={date}
          selected={(value) => !!interval && interval.equals(value)}
          disabled={() => !date}
          intervalsWithFeeAmounts={intervalsWithFeeAmounts}
          onSelect={onInterval}
          showPrices={true}
        />
      </div>
    );
  }

  const intervalDuration = interval?.toDuration();
  const selectedFlexibleArrival = !!intervalDuration && intervalDuration >= ALL_DAY_DURATION;
  const selectedScheduledArrival = !!intervalDuration && intervalDuration < ALL_DAY_DURATION;

  const earliestArrivalTime = matches.reduce((prev, current) =>
    prev.interval.start < current.interval.start ? prev : current,
  ).interval.start;
  const latestArrivalTime = matches.reduce((prev, current) =>
    prev.interval.end > current.interval.end ? prev : current,
  ).interval.end;

  return (
    <>
      <Box margin={['48px 0 0', null, '72px 0 0']}>
        <Text.Headline weight={FontWeight.Medium} color={COLORS.tealDark}>
          Select an arrival window
        </Text.Headline>
        <Box.Grid gridGap={['12px', '16px']} gridTemplateColumns={['1fr', '1fr 1fr']} margin={['24px 0 0', '32px 0 0']}>
          <RadioCard
            name="flexibleArrival"
            value="Flexible Arrival"
            selected={selectedFlexibleArrival}
            onChange={() => {
              onInterval({ interval: flexibleWindow.interval, forced: false });
            }}
          >
            <RadioCardContents
              label="Flexible Arrival"
              description={`Receive a 3 hour arrival window the day before your appointment. The earliest possible arrival is at ${formatTime(
                earliestArrivalTime,
              )} and the latest possible arrival is at ${formatTime(latestArrivalTime)}.`}
              formattedPrice="Free"
            />
          </RadioCard>
          <RadioCard
            name="scheduledArrival"
            value="Scheduled Arrival"
            selected={selectedScheduledArrival}
            onChange={() => {
              onInterval({ interval: nonFlexibleWindows[0].interval, forced: false });
            }}
          >
            <RadioCardContents
              label="Scheduled Arrival"
              description="Select a set arrival window. Limited availability."
              formattedPrice={nonFlexibleWindowFeeAmount.formatted}
            />
          </RadioCard>
        </Box.Grid>
        {selectedScheduledArrival && (
          <Timeslot
            date={date}
            selected={(value) => !!interval && interval.equals(value)}
            disabled={() => !date}
            intervalsWithFeeAmounts={nonFlexibleWindows}
            onSelect={onInterval}
            showPrices={false}
          />
        )}
      </Box>
    </>
  );
};
