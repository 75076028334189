import { Box, Button, COLORS, SkeletonLoader, Text, UnstyledButton } from '@clutter/clean';
import React, { ComponentProps, useState, useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { produce } from 'immer';
import { matchSorter } from 'match-sorter';

import { ItemCard } from '@portal/components/helpers/inventory/item_card';
import { Tabs } from '@portal/components/helpers/tabs';
import {
  CustomerItemFragment,
  DisposalConfirmationQuery,
  OrderTypeEnum,
  RateGroupKindEnum,
  Status,
  useAttachedDisposalConfirmationMutation,
  useDisposalConfirmationQuery,
  useDisposalItemPricingQuery,
} from '@portal/schema';
import { SearchInput } from '@portal/components/helpers/search_input';
import { Currency, Pluralize } from '@shared/components/helpers';
import styled from '@emotion/styled';
import { useStoragePricingInfo } from '@portal/components/orders/disposal_confirmation/use_storage_pricing_info';
import { createTypedReducer } from '@shared/utils/reducers';
import { Fallback } from '@portal/components/helpers/fallback';
import { PricingModalToggle, SERVICE_FEE_AMOUNT } from '@portal/components/orders/disposal_confirmation/pricing_modal';
import { flatMap } from 'lodash';
import { ordersURL } from '@portal/config/routes';

const Card: React.FC<{ children: React.ReactNode } & Pick<ComponentProps<typeof Box.Flex>, 'margin'>> = ({
  children,
  ...props
}) => (
  <Box.Flex
    background={COLORS.dust}
    border={`1px solid ${COLORS.sunshine}`}
    padding="20px"
    flexDirection="column"
    gap="16px"
    borderRadius="4px"
    {...props}
  >
    {children}
  </Box.Flex>
);

const JumboButton = styled(Button)`
  height: unset;
  padding: 24px 100px;
`;

const Header = ({ orderEnded }: { orderEnded: boolean }) => (
  <>
    <Text.Title size="large">Disposal Confirmation</Text.Title>
    <Card>
      <div>
        <b>Clutter intends to dispose of the items shown below</b>. If there are any items below you would rather put in
        storage, unselect them to have them added to your storage account.{' '}
        {!orderEnded && <>If you want an item removed entirely and returned to you now, talk to your movers.</>}
      </div>
      <div>
        <b>If you do not confirm below, all items will be added to your storage account.</b>
      </div>
    </Card>
  </>
);

type State = {
  disposalUngroupedItemsAndGroups: CustomerItemFragment[];
  storedUngroupedItemsAndGroups: CustomerItemFragment[];
};

const actions = {
  moveToStorage(state: State, payload: { uuid: string }) {
    return produce(state, (draft) => {
      const movedItem = state.disposalUngroupedItemsAndGroups.findIndex((item) => item.uuid === payload.uuid);
      draft.storedUngroupedItemsAndGroups.push(draft.disposalUngroupedItemsAndGroups[movedItem]);
      draft.disposalUngroupedItemsAndGroups.splice(movedItem, 1);
    });
  },
  moveToDisposal(state: State, payload: { uuid: string }) {
    return produce(state, (draft) => {
      const movedItem = state.storedUngroupedItemsAndGroups.findIndex((item) => item.uuid === payload.uuid);
      draft.disposalUngroupedItemsAndGroups.push(draft.storedUngroupedItemsAndGroups[movedItem]);
      draft.storedUngroupedItemsAndGroups.splice(movedItem, 1);
    });
  },
};

const reducer = createTypedReducer(actions);

const SORT_OPTIONS = { keys: ['name', 'category.name', 'contents'] };

const Inner = ({
  data: { order },
  signedDisposalID,
}: {
  data: DisposalConfirmationQuery;
  signedDisposalID: string;
}) => {
  if (!order.parent) throw new Error('Invalid disposal confirmation without parent!');

  const account = order.account;
  const currentStorageSubscription = account.currentStorageSubscription;

  const [activeTab, setActiveTab] = useState<'disposal' | 'stored'>('disposal');
  const [searchInput, setSearchInput] = useState('');
  const [{ disposalUngroupedItemsAndGroups, storedUngroupedItemsAndGroups }, dispatch] = useReducer(reducer, {
    disposalUngroupedItemsAndGroups: order.customerItems,
    storedUngroupedItemsAndGroups: order.associatedPickup?.customerItems ?? [],
  });
  const allDisposalItems = flatMap(
    disposalUngroupedItemsAndGroups.map((itemOrGroup) =>
      itemOrGroup.__typename === 'Item' ? [itemOrGroup] : itemOrGroup.items,
    ),
  );
  const allStorageItems = flatMap(
    storedUngroupedItemsAndGroups.map((itemOrGroup) =>
      itemOrGroup.__typename === 'Item' ? [itemOrGroup] : itemOrGroup.items,
    ),
  );

  const history = useHistory();

  const [confirm, { data: mutationData, loading }] = useAttachedDisposalConfirmationMutation({
    onCompleted(result) {
      if (result.disposalConfirm?.status === Status.Ok) {
        if (result.disposalConfirm.signingURL) {
          window.location.assign(result.disposalConfirm.signingURL);
        } else {
          history.push(ordersURL());
        }
      }
    },
  });
  const { data: pricingData } = useDisposalItemPricingQuery({
    variables: {
      itemIds: allDisposalItems.map((item) => item.id).sort(),
    },
  });
  const error = mutationData?.disposalConfirm?.error;

  const rateGroupKind =
    // If the customer didn't add storage ahead of time we default to Mover
    order.parent?.type === OrderTypeEnum.Move && !order.associatedPickup
      ? RateGroupKindEnum.Mover
      : account.rateGroup?.kind ?? RateGroupKindEnum.Mover;

  const pricingInfo = useStoragePricingInfo({
    newCuft: storedUngroupedItemsAndGroups.reduce((acc, cur) => acc + (cur.cuft ?? 0), 0),
    currentPlanCuft: currentStorageSubscription?.cubicFootage ?? undefined,
    signedParentOrderID: order.parent.disposalConfirmationSignedOrderId,
    signedParentOrderPurpose: 'DisposalConfirmation',
    rateGroupKind,
  });

  const filteredDisposalItems = searchInput
    ? matchSorter(disposalUngroupedItemsAndGroups, searchInput, SORT_OPTIONS)
    : disposalUngroupedItemsAndGroups;
  const filteredStoredItems = searchInput
    ? matchSorter(storedUngroupedItemsAndGroups, searchInput, SORT_OPTIONS)
    : storedUngroupedItemsAndGroups;
  const items = activeTab === 'disposal' ? filteredDisposalItems : filteredStoredItems;
  const itemCostTotal = pricingData?.disposalItemPricing.reduce((acc, cur) => acc + cur.amount, 0);
  const total = itemCostTotal && itemCostTotal + SERVICE_FEE_AMOUNT;

  return (
    <Box.Flex gap="24px" flexDirection="column">
      <Header orderEnded={!!order.ended} />
      <SearchInput value={searchInput} onChange={setSearchInput} />
      <Tabs>
        <Tabs.Tab onClick={() => setActiveTab('disposal')} active={activeTab === 'disposal'}>
          Disposal Items ({filteredDisposalItems.length} / {disposalUngroupedItemsAndGroups.length})
        </Tabs.Tab>
        <Tabs.Tab onClick={() => setActiveTab('stored')} active={activeTab === 'stored'}>
          Stored Items ({filteredStoredItems.length} / {storedUngroupedItemsAndGroups.length})
        </Tabs.Tab>
      </Tabs>
      <Box.Flex flexWrap="wrap" gap="16px">
        {items.map((item) => (
          <UnstyledButton
            key={item.uuid}
            onClick={() => {
              if (activeTab === 'disposal') {
                dispatch({ type: 'moveToStorage', payload: { uuid: item.uuid } });
              } else {
                dispatch({ type: 'moveToDisposal', payload: { uuid: item.uuid } });
              }
            }}
          >
            <ItemCard.Container key={item.uuid} data-test-id={`item-${item.uuid}`}>
              {activeTab === 'disposal' ? (
                <ItemCard.Content item={item} selected={true} selectedText="Store Item" />
              ) : (
                <ItemCard.Content item={item} selected={false} unselectedText="Dispose Item" />
              )}
            </ItemCard.Container>
          </UnstyledButton>
        ))}
      </Box.Flex>
      <Box margin="32px 0 0">
        <Text.Body>
          {disposalUngroupedItemsAndGroups.length > 0 && (
            <p>
              Clutter will dispose of the{' '}
              <b>
                <Pluralize
                  count={disposalUngroupedItemsAndGroups.length}
                  singular="selected item"
                  plural="selected items"
                />
              </b>{' '}
              and you will be billed for{' '}
              <Fallback
                value={total}
                delayMs={0}
                loader={
                  <SkeletonLoader width="50px" height="17px" display="inline-flex" position="relative" top="2px" />
                }
              >
                {total !== undefined && <Currency value={total} />}
              </Fallback>{' '}
              (<PricingModalToggle itemizedPricing={pricingData?.disposalItemPricing ?? []} />){'. '}
            </p>
          )}
          {storedUngroupedItemsAndGroups.length > 0 && (
            <p>
              Your{' '}
              <b>
                <Pluralize
                  count={storedUngroupedItemsAndGroups.length}
                  singular="remaining item"
                  plural="remaining items"
                />
              </b>{' '}
              will be put in storage.{' '}
            </p>
          )}
          {pricingInfo?.changeDescription}
        </Text.Body>
      </Box>
      <Box.Flex justifyContent="center" flexDirection="column" gap="16px">
        <Text.Body color={COLORS.toucan}>{error ?? '\u00A0'} </Text.Body>
        <JumboButton
          loading={loading}
          onClick={() =>
            confirm({
              variables: {
                signedDisposalID: signedDisposalID,
                disposalItemIDs: allDisposalItems.map((item) => item.id),
                storageItemIDs: allStorageItems.map((item) => item.id),
                storagePlanConfig: pricingInfo && {
                  planID: pricingInfo.newPlan.id,
                  quoteID: pricingInfo.quoteId,
                },
              },
            })
          }
        >
          Confirm & Sign
        </JumboButton>
      </Box.Flex>
    </Box.Flex>
  );
};

export const DisposalConfirmation = () => {
  const { orderID } = useParams<{ orderID: string }>();
  const { data } = useDisposalConfirmationQuery({
    variables: { signedOrderID: orderID, signedOrderPurpose: 'DisposalConfirmation' },
  });

  if (!data)
    return (
      <Box.Flex gap="24px" flexDirection="column">
        <Header orderEnded={false} />
        <SearchInput />
        <Tabs>
          <Tabs.Tab active={true}>Disposal Items</Tabs.Tab>
          <Tabs.Tab active={false}>Stored Items</Tabs.Tab>
        </Tabs>
        <Box.Flex flexWrap="wrap" gap="16px">
          <SkeletonLoader width="208px" height="286px" />
          <SkeletonLoader width="208px" height="286px" />
          <SkeletonLoader width="208px" height="286px" />
        </Box.Flex>
      </Box.Flex>
    );

  return <Inner data={data} signedDisposalID={orderID} />;
};
