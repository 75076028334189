import React from 'react';

import { COLORS, Column, FontWeight, Grid, Text } from '@clutter/clean';
import styled from '@emotion/styled';

import { PaymentPlanDetail__OfferFragment as PaymentPlanOffer } from '@portal/schema';
import { Currency } from '@shared/components/helpers';

const PaymentContainer = styled.div`
  margin-bottom: 40px;
  padding: 0 8px;
`;

const PaymentItems = styled.div`
  margin-bottom: 28px;
`;

const PaymentItem = styled(Grid)`
  background: ${COLORS.tealBackground};
  border-radius: 4px;
  padding: 24px 12px;
`;

const PaymentTitle = styled(Text.Title)`
  margin-bottom: 24px;
`;

const PaymentText = styled(Text.Title)`
  color: ${COLORS.tealDark};
`;

const PaymentMonthsText = styled(Text.Body)`
  margin-left: 24px;
`;

export const PaymentSummary: React.FC<{ offer: PaymentPlanOffer }> = ({ offer }) => {
  const { oneTimeFee, monthlyFee, monthlyFeeTerm } = offer;
  return (
    <PaymentContainer>
      <PaymentTitle size="medium">Make {monthlyFee ? 'your first' : 'a one-time'} payment to accept</PaymentTitle>
      <PaymentItems>
        {oneTimeFee && (
          <PaymentItem wrap={true}>
            <Column widths={[6, 6, 6]}>
              <PaymentText size="extraSmall">One-time payment</PaymentText>
            </Column>
            <Column widths={[6, 6, 6]}>
              <PaymentText size="extraSmall" className="text-right">
                <Currency value={oneTimeFee} />
              </PaymentText>
            </Column>
          </PaymentItem>
        )}
        {!!monthlyFee && (
          <PaymentItem wrap={true}>
            <Column widths={[6, 6, 6]}>
              <PaymentText size="extraSmall">Monthly payments</PaymentText>
            </Column>
            <Column widths={[6, 6, 6]}>
              <PaymentText size="extraSmall" className="text-right">
                <Currency value={monthlyFee} />
              </PaymentText>
            </Column>
          </PaymentItem>
        )}
      </PaymentItems>
      {!!monthlyFeeTerm && (
        <PaymentMonthsText weight={FontWeight.Medium}>For {monthlyFeeTerm} months.</PaymentMonthsText>
      )}
    </PaymentContainer>
  );
};
