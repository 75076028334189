import { RouteWithTitle } from '@portal/components/shared/route_with_title';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { buildClaimURL, claimsURL, claimURL } from '@portal/config/routes';

import { Theme } from '@portal/components/helpers/theme';

import { UUID } from '@shared/utils/uuid';
import { Claim } from './claims/claim';
import { List } from './claims/list';

export const Claims = () => (
  <Theme name="claims">
    <Switch>
      <RouteWithTitle exact path={claimsURL()} component={List} title="Claims History" />
      <Route exact path={buildClaimURL()} render={() => <Redirect to={claimURL(UUID())} />} />
      <RouteWithTitle
        path={claimURL(':uuid')}
        render={({ match }) => <Claim uuid={match.params.uuid} />}
        title="File a Claim"
      />
    </Switch>
  </Theme>
);
