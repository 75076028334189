import * as React from 'react';
import { useContext } from 'react';

import { Checklist } from '@portal/components/helpers';
import { Button } from '@shared/components/bootstrap';
import { CategoriesContext } from '@shared/components/inventory_capture/categories_context';
import { IEstimationCategory, IEstimationSelection } from '@shared/types';

type Selections = Map<IEstimationCategory, Partial<IEstimationSelection>>;

const CHECKBOX_STYLE: React.CSSProperties = {
  padding: '.375rem .75rem',
  fontWeight: 'normal',
};

export const Picker: React.FC<{
  complete: boolean;
  selections?: Selections;
  onChange(selections: Selections): void;
  onNextClick(): void;
}> = ({
  complete,
  selections = new Map<IEstimationCategory, Partial<IEstimationSelection>>(),
  onChange,
  onNextClick,
}) => {
  const data = useContext(CategoriesContext);
  if (!data) {
    return null;
  }
  const { estimationCategories: categories = [] } = data;
  const packableCategories = categories.filter(({ packable }) => packable);

  const nextButtonEnabled = () =>
    selections.size !== 0 &&
    packableCategories.some((category) => {
      const selection = selections.get(category);
      return !!selection && !!selection.selected;
    });

  return (
    <>
      <Checklist>
        {packableCategories.map((category) => {
          const selection = selections.get(category);
          const checked = !!selection && !!selection.selected;
          return (
            <Checklist.Entry
              key={category.name}
              checked={checked}
              format="check"
              style={CHECKBOX_STYLE}
              onChange={() => onChange(new Map(selections).set(category, { ...selection, selected: !checked }))}
            >
              <span className="text-capitalize">{category.name}</span>
            </Checklist.Entry>
          );
        })}
      </Checklist>
      {!complete && (
        <Button block outline kind="primary" disabled={!nextButtonEnabled()} onClick={onNextClick}>
          Next
        </Button>
      )}
    </>
  );
};
