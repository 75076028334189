import { IIconProps } from '@portal/components/shared/icons/props';
import React from 'react';
import { snakeCase } from 'lodash';

export const ManWaving: React.FC<IIconProps & { className?: string; secondary?: boolean }> = ({
  className,
  secondary,
  title,
  width = 64,
  height = 59,
}) => (
  <svg
    aria-hidden={!title}
    aria-labelledby={title && `${snakeCase(title)}-svg`}
    className={className}
    width={width}
    height={height}
    viewBox="0 0 64 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title id={`${snakeCase(title)}-svg`}>{title}</title>}
    <path
      d="M42.5491 10.6763C44.3749 10.2182 45.8501 8.58928 46.1277 6.73492"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.7614 6.16106C43.4693 3.69585 41.7457 2.00875 39.3721 1.38336C37.8092 0.976131 36.1514 1.04885 34.552 1.28883L24.3931 2.87412C23.4364 3.33953 22.7061 4.24852 22.4577 5.27387C22.1948 6.34286 22.4943 8.02996 23.3268 8.81533C22.1364 10.0516 21.4645 11.5205 21.26 13.2222L22.1072 16.7636H22.1145C23.5678 16.7927 24.3566 17.8035 24.5611 18.4434L26.9566 10.6697H42.5417C43.7176 9.78978 43.9221 7.52092 43.7614 6.16106Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.4519 38.2663V32.9432H41.4024L41.5777 27.4092L42.8777 24.1586C43.3013 23.0969 43.4766 21.9552 43.3889 20.8208L43.1771 18.0138L43.5496 14.3196L42.5344 10.6909"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.0999 16.7703C21.3185 16.7339 20.5078 17.0684 19.9966 17.672C19.2662 18.5301 19.1202 19.8027 19.4926 20.8717C19.8651 21.9406 21.114 24.1222 23.6482 24.7113V38.2662L14.4388 40.0333L21.6909 47.2035L35.8374 53.232L43.8637 39.1389"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.4577 5.28052C22.1948 6.3495 22.4943 8.0366 23.3268 8.82197"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.5611 19.1773L28.9577 27.5183C30.0678 26.2529 31.5722 25.3512 33.2082 24.9294H37.4003C38.912 25.3221 40.3289 26.1148 41.4098 27.2419L41.5777 27.3946L42.2131 32.7541C42.403 33.9031 42.2058 35.0811 41.6508 36.1138L41.4025 36.5792H31.9593C31.7694 36.5792 31.5722 36.5719 31.3824 36.5501C28.7605 36.3174 26.6352 34.3176 25.9706 31.7797L24.3712 24.7186L24.5611 19.1773Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.94341 48.1126C3.48385 44.5784 6.12764 41.735 9.6259 40.9351C9.6259 40.9351 21.9319 38.6372 21.9392 38.6299C23.619 42.8694 27.6942 45.8146 32.5509 45.8146C37.298 45.8146 41.3732 42.9422 43.1187 38.8553L53.9056 40.9351C57.3966 41.735 60.0477 44.5856 60.5881 48.1126L62.4359 57.9079H1.08838L2.94341 48.1126Z"
      fill={secondary ? '#FFEABF' : '#00A499'}
      stroke={secondary ? '#FFEABF' : '#00A499'}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M38.4957 18.0065C38.4957 18.3192 38.4957 18.6319 38.4957 18.9446V18.0065Z" fill="white" />
    <path
      d="M38.4957 18.0065C38.4957 18.3192 38.4957 18.6319 38.4957 18.9446"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M29.5565 18.0065C29.5565 18.3192 29.5565 18.6319 29.5565 18.9446V18.0065Z" fill="white" />
    <path
      d="M29.5565 18.0065C29.5565 18.3192 29.5565 18.6319 29.5565 18.9446"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.3699 18.9373L36.1295 21.9915C36.6042 22.0788 37.0716 22.4279 37.2542 22.8715C37.4368 23.3151 37.3783 23.8459 37.1081 24.2386C36.8379 24.6313 36.3559 24.8785 35.8739 24.864"
      fill="white"
    />
    <path
      d="M35.3699 18.9373L36.1295 21.9915C36.6042 22.0788 37.0716 22.4279 37.2542 22.8715C37.4368 23.3151 37.3783 23.8459 37.1081 24.2386C36.8379 24.6313 36.3559 24.8785 35.8739 24.864"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M34.3913 24.3259C33.8947 24.3477 33.4054 24.5804 33.0767 24.9513L34.3913 24.3259Z" fill="white" />
    <path
      d="M34.3913 24.3259C33.8947 24.3477 33.4054 24.5804 33.0767 24.9513"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.6581 15.6577C41.0227 15.0396 40.1828 14.6469 39.3137 14.5523C38.4446 14.4651 37.5463 14.676 36.8014 15.1559"
      fill="white"
    />
    <path
      d="M41.6581 15.6577C41.0227 15.0396 40.1828 14.6469 39.3137 14.5523C38.4446 14.4651 37.5463 14.676 36.8014 15.1559"
      stroke="#F79C94"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.9565 15.6577C27.6211 15.0396 28.4975 14.6469 29.4031 14.5523C30.3087 14.4651 31.2436 14.676 32.0177 15.1559"
      fill="white"
    />
    <path
      d="M26.9565 15.6577C27.6211 15.0396 28.4975 14.6469 29.4031 14.5523C30.3087 14.4651 31.2436 14.676 32.0177 15.1559"
      stroke="#F79C94"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.1465 1.97906C28.5852 2.7499 30.243 2.74262 31.8279 2.68445C33.42 2.63354 35.0632 2.56082 36.5385 3.23712C38.5761 4.16793 40.0367 7.19308 38.6053 9.44012C38.2547 8.0439 36.816 7.28034 35.5379 7.17126C34.2598 7.06218 33.0037 7.60031 31.8644 8.26933C30.7251 8.93835 29.6442 9.74554 28.4464 10.2473C26.6644 10.9963 24.656 11.0109 22.8521 10.3491C22.4212 10.1891 22.005 10.0001 21.6033 9.76736"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9752 19.0392C21.1139 18.6174 21.5302 18.312 21.9684 18.3047C22.4066 18.2974 22.8375 18.5883 22.9909 19.0028C23.0931 19.2937 23.0712 19.6064 23.0128 19.9045C22.9543 20.2027 22.8521 20.4936 22.8156 20.799C22.7279 21.4826 22.9617 22.2025 23.4364 22.7115"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.7586 33.4813C38.7513 34.5867 38.3789 35.6775 37.7289 36.5719"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.5896 33.4813C36.5823 34.5867 36.2098 35.6775 35.5598 36.5719"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.9204 33.4813C40.9131 34.5867 40.5407 35.6775 39.8907 36.5719"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.4205 33.4813C34.4132 34.5867 34.0407 35.6775 33.3907 36.5719"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.2514 33.4813C32.2441 34.5867 31.8717 35.6775 31.2217 36.5719"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.0868 27.511H29.6953C29.6953 27.511 30.8054 29.6489 34.1211 29.6489C37.4368 29.6489 38.0868 27.511 38.0868 27.511Z"
      fill="white"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.0868 27.511C38.0868 27.511 37.4295 29.6489 34.1138 29.6489C30.7981 29.6489 29.688 27.511 29.688 27.511"
      fill="white"
    />
    <path
      d="M38.0868 27.511C38.0868 27.511 37.4295 29.6489 34.1138 29.6489C30.7981 29.6489 29.688 27.511 29.688 27.511"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.1901 9.44014C41.3513 7.82576 41.4243 5.51327 40.3653 3.82617"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.55851 45.4728L1.82599 57.9079H12.8831L14.7893 47.1017L17.3454 44.5056C18.4482 43.3857 19.2151 41.9822 19.5656 40.4551L20.3179 37.1609L21.0482 33.2704L21.6617 31.4088C21.8004 30.987 21.574 30.5434 21.1504 30.398C20.5297 30.1943 19.8505 30.4198 19.4853 30.9579C19.0106 31.6487 18.6819 32.4341 18.5286 33.2631C18.3679 34.1067 18.1853 35.0593 18.1853 35.0593C18.1123 35.4665 17.8129 35.7938 17.4185 35.9174L17.0679 33.954L16.0089 24.8567C15.9505 24.3549 15.6292 23.8895 15.1472 23.7368C14.9061 23.6641 14.6578 23.6568 14.4241 23.7295C13.8472 23.8895 13.4747 24.4858 13.5477 25.1185L14.4314 32.7032C14.4606 32.9359 14.3 33.154 14.059 33.1977C14.0151 33.2049 13.9786 33.2049 13.9348 33.2049C13.7376 33.1831 13.5697 33.0304 13.5404 32.8341L11.9483 23.8314C11.8607 23.3296 11.5028 22.886 11.0062 22.7624C10.7652 22.7042 10.5242 22.7187 10.2978 22.7987C9.73543 22.9951 9.39218 23.6132 9.50173 24.2386L11.0938 33.2631C11.1377 33.5031 10.9697 33.7358 10.7287 33.7794C10.6849 33.7867 10.6483 33.7867 10.6045 33.7867C10.4146 33.7649 10.254 33.6267 10.2101 33.4449L8.44275 26.0275C8.3259 25.533 7.94613 25.1185 7.4422 25.024C7.2012 24.9803 6.96019 25.0094 6.73379 25.104C6.17874 25.3294 5.8793 25.9693 6.02537 26.5874L7.74894 33.8303C7.77816 33.954 7.75625 34.0776 7.69052 34.1794C7.62479 34.2812 7.51524 34.3539 7.39108 34.3757C7.33266 34.383 7.28884 34.3903 7.25232 34.383C7.07704 34.3685 6.92367 34.2448 6.87255 34.0776L5.49223 29.758C5.34617 29.2999 4.93718 28.9581 4.48438 28.9145C4.39674 28.9072 4.3018 28.9072 4.21416 28.9217C3.87821 28.9726 3.57877 29.1617 3.39619 29.4526C3.21361 29.7362 3.16979 30.0853 3.27934 30.4125L6.68997 41.3641C7.10625 42.7022 7.06243 44.1493 6.55851 45.4728Z"
      fill="white"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M17.5134 35.9247C15.4977 36.6228 13.7742 38.1208 12.8101 40.0115"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4258 35.9247L8.43542 37.6991"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8831 57.9079L14.7893 47.1018L17.3454 44.5057C18.4482 43.3858 19.2151 41.9823 19.5656 40.4552L20.1134 38.2663L21.0482 33.2777L21.6617 31.4161C21.8004 30.9943 21.574 30.5507 21.1504 30.4053C20.5297 30.2017 19.8505 30.4271 19.4853 30.9652C19.0106 31.6561 18.6819 32.4414 18.5286 33.2704C18.3679 34.114 18.1853 35.0666 18.1853 35.0666C18.1123 35.4739 17.8129 35.8011 17.4185 35.9247L17.0679 33.9613L16.0089 24.864C15.9505 24.3623 15.6292 23.8969 15.1472 23.7441C14.9061 23.6714 14.6578 23.6642 14.4241 23.7369C13.8472 23.8969 13.4747 24.4932 13.5477 25.1258L14.4314 32.7105C14.4607 32.9432 14.3 33.1614 14.059 33.205C14.0152 33.2123 13.9786 33.2123 13.9348 33.2123C13.7376 33.1905 13.5697 33.0377 13.5404 32.8414L11.9483 23.8387C11.8607 23.3369 11.5028 22.8933 11.0062 22.7697C10.7652 22.7115 10.5242 22.7261 10.2978 22.8061C9.73543 23.0024 9.39218 23.6205 9.50173 24.2459L11.0938 33.2704C11.1377 33.5104 10.9697 33.7431 10.7287 33.7868C10.6849 33.794 10.6483 33.794 10.6045 33.794C10.4146 33.7722 10.254 33.634 10.2101 33.4522L8.44275 26.0348C8.3259 25.5403 7.94613 25.1258 7.44221 25.0313C7.2012 24.9877 6.96019 25.0167 6.73379 25.1113C6.17874 25.3367 5.87931 25.9766 6.02537 26.5948L7.74894 33.8377C7.77816 33.9613 7.75625 34.0849 7.69052 34.1867C7.62479 34.2885 7.51524 34.3612 7.39108 34.3831C7.33266 34.3903 7.28884 34.3976 7.25232 34.3903C7.07704 34.3758 6.92367 34.2522 6.87255 34.0849L5.49223 29.7653C5.34617 29.3072 4.93718 28.9654 4.48438 28.9218C4.39674 28.9145 4.3018 28.9145 4.21416 28.9291C3.87821 28.98 3.57877 29.169 3.39619 29.4599C3.21361 29.7435 3.16979 30.0926 3.27934 30.4198L6.68997 41.3714"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.1147 55.3118L49.9984 57.9079L18.0685 56.6062"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
