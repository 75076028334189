import { Text } from '@clutter/clean';
import React from 'react';

import { AccountResolution__OfferFragment } from '@portal/schema';
import { Currency } from '@shared/components/helpers';

export const Accepted: React.FC<{ offer: AccountResolution__OfferFragment }> = ({ offer }) => (
  <Text.Body>
    Your refund of <Currency value={Number(offer.metadata.refund_amount)} precision="automatic" /> has been processed.
    Depending on your bank, this may take 3-5 business days to show up on your statement. We’re glad we could help and
    thank you for being a great customer.
  </Text.Body>
);
