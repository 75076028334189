import { IPhoneCall } from '@admin/types';

export enum InteractableType {
  PHONE_CALL = 'PhoneCall',
}

export interface ICustomerInteraction {
  id: string;
  interactableType: InteractableType;
  interactable: IPhoneCall;
}
