import { IConversation } from './conversation';
import { INote } from './note';
import { IPhone } from './phone';
import { ITouchTaxon } from './touch_taxon';
import { IUser } from './user';
import { IVoicemail } from './voicemail';

export enum PhoneCallDirection {
  Inbound = 'inbound',
  Outbound = 'outbound',
}

export interface IPhoneCall {
  __typename: 'PhoneCall';
  id: string;
  status: string;
  startAt?: string;
  endAt?: string;
  createdAt: string;
  updatedAt: string;
  duration: number;
  direction: PhoneCallDirection;
  reasons: string[];
  note?: INote;
  notes: INote[];
  touchTaxons: ITouchTaxon[];
  conversations: IConversation[];
  voicemails: IVoicemail[];
  phone: IPhone;
  latestConnectedUser?: IUser;
}
