import React from 'react';
import { ApolloProvider } from '@apollo/client';

import { client } from '@portal/libraries/apollo';
import { SnackbarProvider } from '@clutter/clean';

export const Config: React.FC = ({ children }) => (
  <ApolloProvider client={client}>
    <SnackbarProvider>{children}</SnackbarProvider>
  </ApolloProvider>
);
