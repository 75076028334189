import * as React from 'react';

import { ClaimPropertySelectionFragment } from '@portal/schema';

export const DEFAULT_CLAIM_PROPERTY_SELECTION: ClaimPropertySelectionFragment = {
  id: '',
  photos: [],
  quotes: [],
};

export type ClaimPropertySelectionChanger =
  | ClaimPropertySelectionFragment
  | ((selection: ClaimPropertySelectionFragment) => ClaimPropertySelectionFragment);

interface IContext {
  selection: ClaimPropertySelectionFragment;
  onChange(changer: ClaimPropertySelectionChanger): void;
}

const { Provider, Consumer } = React.createContext<IContext>({
  selection: DEFAULT_CLAIM_PROPERTY_SELECTION,
  onChange: (_: ClaimPropertySelectionChanger) => {
    /* noop */
  },
});

export { Provider as ClaimPropertySelectionProvider };
export { Consumer as ClaimPropertySelectionConsumer };
