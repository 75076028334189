import React from 'react';

import { COLORS } from '@clutter/clean';

export const StarsLeft: React.FC<{ color?: string }> = ({ color = COLORS.tiger }) => (
  <svg aria-hidden="true" width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.6239 6.73535L18.4044 12.0298L12.7835 13.1699L18.4044 14.3186L19.6239 19.613L20.8343 14.3186L26.4551 13.1699L20.8343 12.0298L19.6239 6.73535Z"
      fill={color}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.2259 1L7.8408 3.77246L4.90659 4.13521L7.8408 4.49797L8.2259 7.27043L8.61102 4.49797L11.5544 4.13521L8.61102 3.77246L8.2259 1Z"
      fill={color}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2.85266 9.68066V13.17" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.00065 11.4258H4.70508" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
