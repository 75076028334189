import React from 'react';

import {
  ClaimIssueSelectionChanger,
  ClaimIssueSelectionProvider,
  DEFAULT_CLAIM_ISSUE_SELECTION,
} from '@portal/contexts';
import { DetailedClaimFragment } from '@portal/schema';

import { Wizard } from './wizard';

type SelectionProps = {
  claim: DetailedClaimFragment;
  onChange(changer: (_: DetailedClaimFragment) => DetailedClaimFragment): void;
  onNext(): void;
  onPrev(): void;
};

export const Selection: React.FC<SelectionProps> = ({ onNext, onPrev, claim, onChange }) => {
  const selection = claim.issueSelection || DEFAULT_CLAIM_ISSUE_SELECTION;

  const onSelectionChange = (changer: ClaimIssueSelectionChanger) => {
    onChange((currentClaim) => ({
      ...currentClaim,
      issueSelection:
        typeof changer === 'function' ? changer(claim.issueSelection || DEFAULT_CLAIM_ISSUE_SELECTION) : changer,
    }));
  };

  return (
    <ClaimIssueSelectionProvider value={{ selection, onChange: onSelectionChange }}>
      <Wizard onNext={onNext} onPrev={onPrev} />
    </ClaimIssueSelectionProvider>
  );
};
