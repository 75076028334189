import React from 'react';

import { COLORS, FontWeight, Text, mq } from '@clutter/clean';
import styled from '@emotion/styled';

const Container = styled.div`
  background-color: ${COLORS.grayBackground};
  padding: 24px 24px 12px;
  ${mq({
    width: ['100%', '60%'],
  })}
`;

const Header = styled(Text.Title)`
  font-weight: ${FontWeight.Regular};
`;

const ListItem = styled.li`
  margin: 6px 0px;

  &::marker {
    color: ${COLORS.tealPrimary};
  }
`;

export const Items: React.FC<{ items: string[] }> = ({ items }) => (
  <Container>
    <Header size="extraSmall">What's included</Header>
    <ul>
      {items.map((item, index) => (
        <ListItem key={index}>
          <Text.Body>{item}</Text.Body>
        </ListItem>
      ))}
    </ul>
  </Container>
);
