import styled from '@emotion/styled';
import React, { useState } from 'react';

import { TextButton } from '@portal/components/shared/customer_protected/text_button';
import { BuildSourceDialog } from '@portal/components/sources/build_source_dialog';
import { DefaultSourceDialog } from '@portal/components/sources/default_source_dialog';
import { DestroySourceDialog } from '@portal/components/sources/destroy_source_dailog';
import { SourceFragment } from '@portal/schema';

import { SourceCard } from './source_card';

const Container = styled.div`
  line-height: 0;
  max-width: 500px;
  margin: auto;
  overflow: auto;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin: 24px 0;
`;

export const SourceList: React.FC<{
  sources?: SourceFragment[];
  delinquent: boolean;
  onChange(): void;
}> = ({ sources, delinquent, onChange }) => {
  const [buildingSource, setBuildingSource] = useState<boolean>(false);
  const [destroying, setDestroying] = useState<SourceFragment | undefined>(undefined);
  const [defaulting, setDefaulting] = useState<SourceFragment | undefined>(undefined);

  const onSaveSource = () => {
    onChange();
    setBuildingSource(false);
  };

  const onDestroySource = () => {
    onChange();
    setDestroying(undefined);
  };

  const onDefaultSource = () => {
    onChange();
    setDefaulting(undefined);
  };

  return (
    <>
      <Container>
        {sources &&
          sources.map((source) => (
            <SourceCard key={source.id} source={source} setDestroying={setDestroying} setDefaulting={setDefaulting} />
          ))}
        <ButtonContainer>
          <TextButton size="medium" onClick={() => setBuildingSource(true)}>
            Add Card
          </TextButton>
        </ButtonContainer>
      </Container>
      {buildingSource && (
        <BuildSourceDialog canAddPrepaid={false} onClose={() => setBuildingSource(false)} onSave={onSaveSource} />
      )}
      {destroying && (
        <DestroySourceDialog source={destroying} onClose={() => setDestroying(undefined)} onDestroy={onDestroySource} />
      )}
      {defaulting && (
        <DefaultSourceDialog
          source={defaulting}
          delinquent={delinquent}
          onClose={() => setDefaulting(undefined)}
          onDefault={onDefaultSource}
        />
      )}
    </>
  );
};
SourceList.displayName = 'SourceList';
