import { AriaAttributes, SVGAriaEnhancements } from '@shared/components/helpers/svg_aria_enhancements';
import React, { FC } from 'react';

export const Clock: FC<AriaAttributes> = ({ title, description: desc }) => {
  const Aria = SVGAriaEnhancements(title, desc);

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={Aria.ariaLabelledby}
    >
      <Aria.Title />
      <Aria.Desc />
      <g clipPath="url(#clip0_22_1542)">
        <path
          d="M20 39.7647C30.9157 39.7647 39.7647 30.9157 39.7647 20C39.7647 9.08425 30.9157 0.235291 20 0.235291C9.08425 0.235291 0.235291 9.08425 0.235291 20C0.235291 30.9157 9.08425 39.7647 20 39.7647Z"
          fill="#00A499"
          stroke="#00A499"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.705872 20C0.705872 9.36469 9.36469 0.705872 20 0.705872C30.6353 0.705872 39.2941 9.36469 39.2941 20"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.4706 20C36.4706 29.0824 29.0824 36.4706 20 36.4706C10.9177 36.4706 3.52942 29.0824 3.52942 20"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36Z"
          fill="white"
          stroke="#00A499"
          strokeMiterlimit="10"
        />
        <path
          d="M39.7647 20C39.7647 30.9176 30.9176 39.7647 20 39.7647C9.08235 39.7647 0.235291 30.9176 0.235291 20"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 20C4 11.1529 11.1529 4 20 4C28.8471 4 36 11.1529 36 20"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 7.5294V10.8235C25.0824 10.8235 29.1765 14.9176 29.1765 20C29.1765 25.0823 25.0824 29.1765 20 29.1765C14.9177 29.1765 10.8235 25.0823 10.8235 20C10.8235 18.3529 11.2941 16.7529 12.0471 15.4353L9.17648 13.7882C8.09413 15.6235 7.48236 17.7412 7.48236 20C7.52942 26.8706 13.1294 32.4706 20 32.4706C26.8706 32.4706 32.4706 26.8706 32.4706 20C32.4706 13.1294 26.8706 7.5294 20 7.5294Z"
          fill="#F79C94"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.6941 16.3294L7.57648 12.8471L13.3647 10.4471L13.6941 16.3294Z"
          fill="#F79C94"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 6.35294V8.70588"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.1765 8.18823L14.3529 10.2118"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.18823 13.1765L10.2118 14.3529"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.35294 20H8.70588"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.18823 26.8235L10.2118 25.647"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.1765 31.8118L14.3529 29.7882"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 33.6471V31.2941"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.8235 31.8118L25.6471 29.7882"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.8117 26.8235L29.7882 25.647"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.6471 20H31.2941"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.8117 13.1765L29.7882 14.3529"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.8235 8.18823L25.6471 10.2118"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.2706 8.37646L20 20.9412V11.5294"
          stroke="#00404D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_1542">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
