export enum UserRole {
  Admin = 'admin',
  AUDIT_REVIEWER = 'audit_reviewer',
  AUDITOR = 'auditor',
  Billing = 'billing',
  BillingAdmin = 'billing_admin',
  Care = 'care',
  Claims = 'claims',
  Collections = 'collections',
  DataCorrector = 'data_corrector',
  Dispatcher = 'dispatcher',
  Driver = 'driver',
  EnterpriseManager = 'enterprise_manager',
  L1Agent = 'l1_agent',
  L2Agent = 'l2_agent',
  Manager = 'manager',
  Marketing = 'marketing',
  Mover = 'mover',
  Retention = 'retention',
  SelfStorageAssociate = 'self_storage_associate',
  Teamlead = 'teamlead',
  Tester = 'tester',
  Timekeeper = 'timekeeper',
  Warehouse = 'warehouse',
}

export interface IUser {
  id: number;
  tz: string;
  name: string;
  email: string;
  phone: string;
  roles: string[];
}
