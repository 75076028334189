import * as React from 'react';
import { useContext } from 'react';

import { Text } from '@shared/components/bootstrap';
import { InventoryContext } from '@shared/components/inventory_capture/inventory_context';

import { Picker as EstimationCategorySelectionPicker } from '@shared/components/estimation_category_selection/picker';

export const PickupItems: React.FC<{ pickup?: boolean }> = ({ pickup = true }) => {
  const { data, onSelect } = useContext(InventoryContext);

  return (
    <>
      <Text tag="p" style="info">
        <strong>{pickup ? 'How many of these items are we picking up?' : 'Are you packing any of these items?'}</strong>
      </Text>
      <EstimationCategorySelectionPicker
        selections={data.unpackableEstimations}
        onChange={(unpackableEstimations) => {
          onSelect({ ...data, unpackableEstimations });
        }}
      />
    </>
  );
};
