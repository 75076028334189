import cn from 'classnames';
import React from 'react';

import { Text } from '@shared/components/bootstrap';

export const CollapsibleTableCell: React.FC<{
  name?: string;
  selected: boolean;
}> = ({ name, selected, children }) => (
  <td className={cn('d-md-table-cell', selected ? 'd-block' : 'd-none')}>
    {name && (
      <div className="d-block d-md-none">
        <Text style="info">{name}:</Text>
      </div>
    )}
    {children}
  </td>
);
