import * as React from 'react';

import { ClaimItemSelectionFragment, Status, useUpdateClaimItemSelectionMutation } from '@portal/schema';

import { ClaimItemSelectionConsumer } from '@portal/contexts';

import { Draft } from '@portal/components/helpers/draft';
import { Alert } from '@shared/components/bootstrap';

import { Step } from '@portal/components/claims/step';

interface IFormRenderProps {
  loading: boolean;
  selection: ClaimItemSelectionFragment;
  onChange(claim: ClaimItemSelectionFragment): void;
}

interface IFormProps {
  field: 'name' | 'productURL' | 'declaration';
  skippable?: boolean;
  children(props: IFormRenderProps): React.ReactNode;
}

export const ClaimItemSelectionForm = ({ field, skippable, children }: IFormProps) => {
  const [mutate, { loading, data }] = useUpdateClaimItemSelectionMutation();

  return (
    <ClaimItemSelectionConsumer>
      {({ selection, onChange: onSave }) => (
        <Draft draft={selection}>
          {({ draft, onChange }) => {
            const { id } = draft;
            return (
              <Step
                saving={loading}
                skippable={skippable}
                skip={{ text: 'Skip', kind: 'secondary' }}
                valid={!!draft[field]}
                onSave={async () => {
                  const result = await mutate({
                    variables: {
                      input: { [field]: draft[field], id },
                    },
                  });
                  if (!result.data?.updateClaimItemSelection?.status) {
                    return Status.Unprocessable;
                  }
                  onSave(draft);
                  return result.data.updateClaimItemSelection.status;
                }}
              >
                {data && data.updateClaimItemSelection?.error && (
                  <Alert style="danger">{data.updateClaimItemSelection.error}</Alert>
                )}
                {children({ loading, selection: draft, onChange })}
              </Step>
            );
          }}
        </Draft>
      )}
    </ClaimItemSelectionConsumer>
  );
};
