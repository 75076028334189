import * as React from 'react';

import { Restrictions } from '@portal/components/addresses/form/buildings/restrictions';
import { AddressDetailField, IAddressDetail, IAddressValidationErrors } from '@shared/types';
import { AdditionalNotes } from './additional_notes';

import { GatedCommunity } from './gated_community';
import { SquareFeet } from './square_feet';
import { Stories } from './stories';
import { LongCarry } from './long_carry';

export const House: React.FC<{
  details: IAddressDetail;
  errors?: IAddressValidationErrors;
  setDetails(details: IAddressDetail): void;
}> = ({ details, errors, setDetails }) => (
  <>
    <SquareFeet
      value={details[AddressDetailField.SquareFeet]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.SquareFeet]: value })}
    />
    <Stories
      value={details[AddressDetailField.Stories] ?? null}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.Stories]: value })}
    />
    <LongCarry
      value={details[AddressDetailField.LongCarry]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.LongCarry]: value })}
    />
    <GatedCommunity
      value={details[AddressDetailField.Gated]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.Gated]: value })}
    />
    <Restrictions
      details={details}
      error={errors?.[AddressDetailField.BuildingRestrictionsAnswer]}
      onDetail={setDetails}
      initialBuildingRestrictionsAnswer={details[AddressDetailField.BuildingRestrictionsAnswer]}
    />
    <AdditionalNotes
      value={details[AddressDetailField.AdditionalNotes]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.AdditionalNotes]: value })}
    />
  </>
);
