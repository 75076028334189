import { COLORS, FontWeight, Text, transparentize } from '@clutter/clean';
import styled from '@emotion/styled';
import { Header } from '@portal/components/helpers';
import { Spacer } from '@shared/components/helpers';
import React from 'react';
import { Button as CustomerProtectedButton } from '@portal/components/shared/customer_protected/button';
import { L1_AGENT_L2_ONLY_ERROR_MESSAGE } from '@portal/components/shared/customer_protected/util';

const Container = styled.div`
  border-radius: 4px;
  padding: 16px;
  background: ${transparentize(COLORS.flower, 0.4)};
`;

const Callout = styled(Text.Callout)`
  color: ${COLORS.panther};
  padding-top: 4px;
`;

const Body = styled(Text.Body)`
  color: ${COLORS.panther};
`;

export const ConfirmError: React.FC<{
  headline: string;
  detail: string;
  children?: React.ReactNode;
  overrideError?: () => void;
}> = ({ headline, detail, children, overrideError }) => (
  <Container>
    <Body weight={FontWeight.Medium}>{headline}</Body>
    <Callout>{detail}</Callout>
    {children}
    {overrideError && (
      <div>
        <Spacer height="32px" />
        <Header tag="h4">Agent Options</Header>
        <CustomerProtectedButton
          onClick={() => overrideError()}
          errorMessage={L1_AGENT_L2_ONLY_ERROR_MESSAGE}
          showErrorInTooltip={true}
        >
          Continue Booking
        </CustomerProtectedButton>
      </div>
    )}
  </Container>
);
