import * as React from 'react';

import { Status } from '@portal/schema';

import { ClaimConsumer, NavigationConsumer } from '@portal/contexts';

import { Progress, Text } from '@shared/components/bootstrap';

import { Page } from '@portal/components/helpers/page';

import { Digest } from './digest';
import { Tracker } from './tracker';
import { Action, StepButton } from './step_button';

const StepTitle = ({ children }: { children: React.ReactNode }) => <p className="step__title">{children}</p>;

const StepSubtitle = ({ children }: { children: React.ReactNode }) => <p className="step__subtitle">{children}</p>;

interface IStepProps {
  children?: React.ReactNode;
  saving?: boolean;
  valid?: boolean;
  autosave?: 'next' | 'prev';
  prev?: Action;
  next?: Action;
  skip?: Action;
  skippable?: boolean;
  hasPrev?: boolean;
  onSave?(source: 'next' | 'prev' | 'close'): Promise<Status>;
}

const DEFAULT_AUTOSAVE = 'next';
const DEFAULT_HAS_PREV = true;
const DEFAULT_PREV: Action = { text: 'Back', kind: 'secondary', customerProtected: false };
const DEFAULT_NEXT: Action = { text: 'Next', kind: 'primary', customerProtected: false };
const DEFAULT_SKIP: Action = { text: 'Skip', kind: 'secondary', customerProtected: false };
const INDEX_OFFSET = 1;

export const Step = ({
  children,
  onSave,
  saving,
  valid,
  skippable,
  hasPrev = DEFAULT_HAS_PREV,
  prev = DEFAULT_PREV,
  next = DEFAULT_NEXT,
  skip = DEFAULT_SKIP,
  autosave = DEFAULT_AUTOSAVE,
}: IStepProps) => (
  <NavigationConsumer>
    {({ onNext, onPrev, onClose, summary, progress }) => (
      <Page>
        <Page.Header>
          <ClaimConsumer>
            {({ claim: { uuid } }) => (
              <div className="row">
                <div className="col col-6 col-sm-8 col-lg-9">
                  <Page.Title>
                    <Text transform="uppercase" tag="div" wrapping="truncate">
                      <span className="d-none d-sm-inline d-lg-inline">Claim:</span> <Digest uuid={uuid} />
                    </Text>
                  </Page.Title>
                </div>
                {onClose && (
                  <div className="col col-6 col-sm-4 col-lg-3">
                    <Tracker uuid={uuid}>
                      {({ track }) => (
                        <StepButton
                          kind="secondary"
                          disabled={saving}
                          onClick={async () => {
                            if (onSave) {
                              const response = await onSave('close');
                              if (response !== Status.Ok) {
                                return;
                              }
                            }
                            await track();
                            onClose();
                          }}
                        >
                          Save & Close
                        </StepButton>
                      )}
                    </Tracker>
                  </div>
                )}
              </div>
            )}
          </ClaimConsumer>
        </Page.Header>
        {progress && (
          <Page.Navigator>
            <div className="row">
              <div className="col-9">{summary}</div>
              <div className="col-3">
                <Progress>
                  <Progress.Bar loaded={progress.index + INDEX_OFFSET} total={progress.total} />
                </Progress>
              </div>
            </div>
          </Page.Navigator>
        )}
        <Page.Section>{children}</Page.Section>
        {(onNext || onPrev) && (
          <Page.Footer>
            <div className="row justify-content-between">
              {onPrev && (!skippable || valid || autosave !== 'prev') && (
                <div className="col col-sm-4 col-md-3 col-lg-2">
                  <StepButton
                    customerProtected={prev.customerProtected}
                    kind={prev.kind}
                    disabled={!hasPrev || saving || (autosave === 'prev' && !valid && !!onSave)}
                    onClick={async () => {
                      if (autosave === 'prev' && onSave && (await onSave('prev')) !== Status.Ok) {
                        return;
                      }
                      onPrev();
                    }}
                  >
                    {prev.text}
                  </StepButton>
                </div>
              )}
              {skippable && !valid && (
                <div className="col col-sm-4 col-md-3 col-lg-2">
                  <StepButton
                    customerProtected={skip.customerProtected}
                    kind={skip.kind}
                    disabled={saving}
                    onClick={async () => {
                      if (onNext && autosave === 'next') {
                        onNext();
                      }
                      if (onPrev && autosave === 'prev') {
                        onPrev();
                      }
                    }}
                  >
                    {skip.text}
                  </StepButton>
                </div>
              )}
              {onNext && (!skippable || valid || autosave !== 'next') && (
                <div className="col col-sm-4 col-md-3 col-lg-2">
                  <StepButton
                    customerProtected={next.customerProtected}
                    kind={next.kind}
                    disabled={saving || (autosave === 'next' && !valid && !!onSave)}
                    loading={saving}
                    onClick={async () => {
                      if (autosave === 'next' && onSave && (await onSave('next')) !== Status.Ok) {
                        return;
                      }
                      onNext();
                    }}
                  >
                    {next.text}
                  </StepButton>
                </div>
              )}
            </div>
          </Page.Footer>
        )}
      </Page>
    )}
  </NavigationConsumer>
);

Step.Title = StepTitle;
Step.Subtitle = StepSubtitle;
