import { OrderRescheduleForm as RescheduleForm } from '@portal/components/orders/reschedule/form';
import { BackArrow } from '@portal/components/shared/icons';
import { wt } from '@portal/initializers/wt';
import {
  Account,
  Address,
  LaborRate,
  MovingQuoteRegenerateMutation,
  Order,
  OrderSubtypeEnum,
  OrderTypeEnum,
  useMovingEstimationsUpdateMutation,
} from '@portal/schema';
import { Box, COLORS, Modal, mq, Text, UnstyledButton } from '@clutter/clean';
import styled from '@emotion/styled';
import { DateTime, Duration } from 'luxon';
import React, { useState } from 'react';
import xbutton from '@portal/images/x-button.svg';

import { TeamStep } from './team_step';

enum StepName {
  Team = 'Team',
  Reschedule = 'Reschedule',
}

export type QuoteType = NonNullable<MovingQuoteRegenerateMutation['movingQuoteRegenerate']>['movingQuote'];

const MainModalBoxContainer = styled(Box)`
  overflow-y: auto;
  ${mq({
    maxHeight: ['650px', '700px', '750px', '800px'],
    width: ['340px', '460px', '580px', '700px'],
  })}
`;

const BackButtonContainer = styled(UnstyledButton)`
  position: absolute;
  left: 24px;
  top: 24px;
  z-index: 2;
`;

const CloseButtonContainer = styled(UnstyledButton)`
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 2;
`;

const ErrorMessage = styled(Text.Callout)`
  color: ${COLORS.toucan};
  margin-bottom: 8px;
`;

type MoverCountOrder = Pick<
  Order,
  'id' | 'movers' | 'status' | 'scheduled' | 'window' | 'type' | 'subtype' | 'waitlistRequests'
> & {
  address: Pick<Address, 'id'>;
  laborRate: Pick<LaborRate, 'id' | 'amount'>;
  services: Array<{ type: OrderTypeEnum; subtype: OrderSubtypeEnum }>;
  permissions: { chargeLateFee: boolean; reschedulable: boolean };
  account: Pick<Account, 'id' | 'state'>;
};

export const MoverCountModal: React.FC<{
  pageName: string;
  hideModal: () => void;
  order: MoverCountOrder;
  onUpdateMoverCount: (quote: QuoteType) => void;
}> = ({ pageName, hideModal, order, onUpdateMoverCount }) => {
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [step, setStep] = useState<StepName>(StepName.Team);
  const [moverCount, setMoverCount] = React.useState<number>(order.movers);
  const [moverCountQuoteMap, setMoverCountQuoteMap] = React.useState<Map<number, QuoteType>>(new Map());
  const [estimationsUpdate, { loading: estimationsUpdateLoading }] = useMovingEstimationsUpdateMutation();

  const onSave = async (scheduled?: DateTime, duration?: Duration) => {
    setError(undefined);

    const isRescheduling = scheduled && duration;
    const existingQuote = moverCountQuoteMap.get(moverCount);
    if (!existingQuote) {
      setError('An unknown error occurred. Please, try again later.');
      return;
    }

    if (isRescheduling) {
      wt.track({
        action: 'click',
        objectName: 'reschedule_button',
        objectType: 'button',
        pageName,
        container: 'mover_count_modal',
        value: scheduled.toJSON(),
        label: 'Save Changes',
        order_id: order.id,
      });
    }

    const rescheduleInput = isRescheduling ? { scheduled: scheduled.toJSON(), duration: duration.toJSON() } : undefined;

    const { data: estimationsUpdateData } = await estimationsUpdate({
      variables: {
        input: {
          orderID: order.id,
          quoteID: moverCountQuoteMap.get(moverCount)?.id,
        },
        rescheduleInput,
      },
    });

    if (estimationsUpdateData?.movingEstimationsUpdate?.error) {
      setError(
        estimationsUpdateData?.movingEstimationsUpdate?.error ?? 'An unknown error occurred. Please, try again later.',
      );
    } else {
      hideModal();
      onUpdateMoverCount(moverCountQuoteMap.get(moverCount));
    }
  };

  return (
    <Modal isOpen={true} handleModalClose={hideModal}>
      <MainModalBoxContainer>
        {step === StepName.Reschedule && (
          <BackButtonContainer aria-label="Previous" onClick={() => setStep(StepName.Team)}>
            <BackArrow fill={COLORS.panther} />
          </BackButtonContainer>
        )}
        <CloseButtonContainer onClick={hideModal}>
          <img height="15px" width="14px" src={xbutton} alt="close" />
        </CloseButtonContainer>
        <Box.Flex
          justifyContent="center"
          alignItems="flex-end"
          height="64px"
          padding="0 0 16px 0"
          textAlign="center"
          background={COLORS.cloud}
          position="fixed"
          width="100%"
        >
          <Text.SmallCaps>Customize Move</Text.SmallCaps>
        </Box.Flex>
        <Box padding="64px 24px 24px" background={COLORS.cloud} textAlign={'center'}>
          {step === StepName.Team && (
            <TeamStep
              pageName={pageName}
              error={error}
              order={order}
              estimationsUpdateLoading={estimationsUpdateLoading}
              moverCountQuoteMap={moverCountQuoteMap}
              moverCount={moverCount}
              goToReschedule={() => setStep(StepName.Reschedule)}
              hideModal={hideModal}
              onSave={onSave}
              setError={setError}
              setMoverCount={setMoverCount}
              setMoverCountQuoteMap={setMoverCountQuoteMap}
            />
          )}
          {step === StepName.Reschedule && (
            <RescheduleForm order={order} loading={false} moverOverride={moverCount} onSubmit={onSave} />
          )}
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Box>
      </MainModalBoxContainer>
    </Modal>
  );
};
