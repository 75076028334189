import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import {
  Order__ContactInput,
  BookingDisclaimer,
  useShipmentLabelsQuery,
  ShipmentLabelsQuery,
  useCustomerQuery,
} from '@portal/schema';

import { COLORS, Text, FontWeight } from '@clutter/clean';

import { orderURL } from '@portal/config/routes';
import { Card } from '@portal/components/addresses/card';
import { IAddressWithDetails } from '@portal/types';
import { Alert } from '@shared/components/bootstrap';
import { Phone } from '@shared/components/helpers/phone';
import { Section } from '@portal/components/orders/steps/confirm/section';
import { IInboundShipmentInput } from '../shipping/shipping';

import { Base } from '../base';
import { IShippingOption } from '../shipping/options';
import { ShippingPrice } from './shipping_price';

const CONTACT_CALLOUT = `We'll share these details with the carrier to provide updates or resolve issues.`;

const Subheader = styled(Text.Body)`
  margin-bottom: 32px;
  color: ${COLORS.storm};
`;

const CalloutText = styled(Text.Callout)`
  color: ${COLORS.hippo};
  margin-top: 8px;
`;

const Body = styled(Text.Body)`
  margin-bottom: 32px;
`;

const ItemName = styled.div`
  display: flex;
`;

const ItemContents = styled(Text.Body)`
  color: ${COLORS.storm};
  margin-bottom: 16px;
`;

const Container = styled.div`
  h2 {
    margin-top: 30px;
  }
`;

const ItemDetails: React.FC<{ shipment: IInboundShipmentInput }> = ({ shipment }) => (
  <>
    <ItemName>
      <Text.Body weight={FontWeight.Medium}>Box {shipment.name}</Text.Body>
      <Text.Body>
        <span>&nbsp;&#8212;</span> {shipment.length}" x {shipment.width}" x {shipment.height}"
      </Text.Body>
    </ItemName>
    <ItemContents>"{shipment.contentDescription}"</ItemContents>
  </>
);

export const InboundShipping: React.FC<{
  orderID?: string;
  address: IAddressWithDetails;
  contact?: Order__ContactInput;
  disclaimers: BookingDisclaimer | undefined;
  loading: boolean;
  error?: string;
  onPrev(): void;
  onSave(): void;
  shippingOption: IShippingOption;
  inboundShipments: IInboundShipmentInput[];
}> = ({ orderID, address, contact, disclaimers, onSave, onPrev, loading, error, shippingOption, inboundShipments }) => {
  const [labels, setLabels] = useState<ShipmentLabelsQuery>();
  const [polling, setPolling] = useState<boolean>(false);

  const { data: customerData } = useCustomerQuery();

  const {
    data: shipmentLabels,
    startPolling,
    stopPolling,
  } = useShipmentLabelsQuery({
    variables: { orderId: orderID! },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setLabels(shipmentLabels);
    },
  });

  useEffect(() => {
    if (labels && labels.shipmentLabels.every((label) => !!label.shipmentLabel)) {
      stopPolling();
    }
  }, [labels]);

  const timeoutContinue = () => {
    stopPolling();
    setPolling(false);
  };

  const onSubmit = () => {
    onSave();
    startPolling(500);
    setTimeout(timeoutContinue, 30000);
    setPolling(true);
  };

  if (labels && (labels.shipmentLabels.every((label) => !!label.shipmentLabel) || !polling)) {
    return <Redirect push to={orderURL(orderID!, 'print_labels')} />;
  }

  return (
    <Base onPrev={onPrev} onNext={onSubmit} loading={loading || polling} nextLabel="Confirm">
      <Container>
        {error && <Alert style="danger">{error}</Alert>}

        <Text.Title size="large">Confirm your shipment details</Text.Title>

        <Subheader>Once you confirm, we'll create shipping labels for you to print at home.</Subheader>

        <Section title="Items to ship">
          <Body>
            {inboundShipments.map((shipment) => (
              <ItemDetails key={shipment.name} shipment={shipment} />
            ))}
          </Body>
        </Section>

        <Section title="Return Address">
          <Body>
            <Card address={address} />
          </Body>
        </Section>

        {contact && (
          <Section title="Contact Details">
            <Body>
              {contact?.name}
              <br />
              <Phone value={contact?.phone} />
              <CalloutText>{CONTACT_CALLOUT}</CalloutText>
            </Body>
          </Section>
        )}

        {!contact && (
          <Section title="Contact Details">
            {customerData && (
              <Body>
                {customerData.account.customer.name}
                <br />
                <Phone value={customerData.account.customer.phone} />
                <CalloutText>{CONTACT_CALLOUT}</CalloutText>
              </Body>
            )}
          </Section>
        )}

        {disclaimers && (
          <>
            <Section title="Shipping Costs">
              <Body>
                <ShippingPrice option={shippingOption} includeService={false} />
              </Body>
            </Section>

            {disclaimers.orderCancellationDisclaimer && (
              <Section title="Cancellation Policy">{disclaimers.orderCancellationDisclaimer}</Section>
            )}
            {disclaimers.accountTerminationDisclaimer && (
              <Section title="Early Termination">{disclaimers.accountTerminationDisclaimer}</Section>
            )}
          </>
        )}
      </Container>
    </Base>
  );
};
