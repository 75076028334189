import { COLORS, FontWeight, Grid, Text, TextButton } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { accountURL, orderURL } from '@portal/config/routes';
import {
  Billing__InvoiceableFragment,
  Billing__UpcomingInvoiceFragment,
  Pricing__ProtectionPlan,
} from '@portal/schema';
import { orderDisplayName } from '@portal/utils/order';
import { formattedDimensions, protectionName } from '@portal/utils/plan';
import { formatDate } from '@shared/utils/format_date';

const Card = styled.div`
  background: ${COLORS.cloud};
  border-radius: 4px;
  margin: 8px 16px;
  padding: 16px;
`;

const Header = styled(Grid)`
  margin: 0;
  align-items: flex-start;
  justify-content: space-between;
`;

const Callout = styled(Text.Callout)<{ alignRight?: boolean }>`
  color: ${COLORS.panther};
  text-align: ${({ alignRight }) => alignRight && 'right'};
`;

const Description = styled(Text.Caption)`
  color: ${COLORS.hippo};
  padding-top: 6px;
`;

const ButtonContainer = styled.div`
  padding-top: 16px;
`;

const title = (invoiceable: Billing__InvoiceableFragment) => {
  switch (invoiceable.__typename) {
    case 'UpcomingSubscription': {
      const plan = invoiceable?.pricing?.plan;
      if (plan?.__typename === 'Pricing__StoragePlan') {
        const planName = invoiceable.dimensions ? formattedDimensions(invoiceable.dimensions) : plan?.name;
        return `${planName} Storage Plan`;
      }
      return `${protectionName(plan as Pricing__ProtectionPlan)}`;
    }
    case 'Order': {
      return orderDisplayName(invoiceable);
    }
    case 'SelfStorage__AdjustedRateRental': {
      const unit = invoiceable.unit;
      return `${unit.facility.name} ${unit.classification.kind} #${unit.name}`;
    }
  }
};

const link = (invoiceable: Billing__InvoiceableFragment) => {
  switch (invoiceable.__typename) {
    case 'UpcomingSubscription':
    case 'SelfStorage__AdjustedRateRental':
      return accountURL();
    case 'Order': {
      return orderURL(invoiceable.parentID || invoiceable.id);
    }
  }
};

export const InvoiceCard: React.FC<{ invoice: Billing__UpcomingInvoiceFragment }> = ({ invoice }) => {
  const { billDate, estimatedAmount, invoiceable } = invoice;

  const bookingPartnerName = invoiceable.__typename === 'Order' ? invoiceable.bookingPartner?.name : undefined;

  return (
    <Card>
      <Header>
        <Callout weight={FontWeight.Medium}>{title(invoiceable)}</Callout>
        <Callout weight={FontWeight.Medium} alignRight>
          {!bookingPartnerName && estimatedAmount}
        </Callout>
      </Header>
      <div>
        <Description>
          {bookingPartnerName ? `Billed by ${bookingPartnerName}` : `Estimated amount due on ${formatDate(billDate)}`}
        </Description>
        <ButtonContainer>
          <TextButton href={link(invoiceable)}>
            View {invoiceable.__typename === 'Order' ? 'Appointment' : 'Details'}
          </TextButton>
        </ButtonContainer>
      </div>
    </Card>
  );
};
