import React from 'react';

const ID = 'office';

export const SmallOffice: React.FC<{ title?: string }> = ({ title = 'Office' }) => (
  <svg
    aria-labelledby={ID}
    width="198"
    height="133"
    viewBox="0 0 198 133"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id={ID}>{title}</title>
    <g clipPath="url(#clip0)">
      <path
        d="M76.8626 67.316H23.0098C22.1123 67.316 21.3779 66.5816 21.3779 65.6841V32.2301C21.3779 31.3326 22.1123 30.5982 23.0098 30.5982H76.8626C77.7602 30.5982 78.4945 31.3326 78.4945 32.2301V65.6841C78.4945 66.5816 77.7602 67.316 76.8626 67.316Z"
        fill="#A1E6E0"
        stroke="#A1E6E0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.8626 63.2362H23.0098C22.1123 63.2362 21.3779 62.5019 21.3779 61.6043V32.2301C21.3779 31.3326 22.1123 30.5982 23.0098 30.5982H76.8626C77.7602 30.5982 78.4945 31.3326 78.4945 32.2301V61.6043C78.4945 62.5019 77.7602 63.2362 76.8626 63.2362Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.0098 30.5982C22.1123 30.5982 21.3779 31.3326 21.3779 32.2301V55.0767L66.2552 30.5982H23.0098Z"
        fill="#00A499"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5658 77.7602L8.97559 122.148"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.209 122.148L119.619 77.7602"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.816406 130.552H131.369L123.209 122.393H8.97592L0.816406 130.552Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.209 83.635H86.4912V124.433H123.209V83.635Z"
        fill="#A1E6E0"
        stroke="#A1E6E0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.89616 79.5552L0.816406 130.552"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.129 79.5552H90.571L86.4912 83.635H123.209L119.129 79.5552Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.369 130.552L127.289 79.5552"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.209 95.8743H86.4912"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.209 108.114H86.4912"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.209 83.635H114.397C113.254 83.635 112.275 84.2878 111.786 85.2669C111.296 86.2461 110.235 86.8988 109.175 86.8988H100.607C99.4648 86.8988 98.4857 86.2461 97.9961 85.2669C97.5065 84.2878 96.4458 83.635 95.3851 83.635H86.4912"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.209 95.8743H114.397C113.254 95.8743 112.275 96.527 111.786 97.5062C111.296 98.4853 110.235 99.1381 109.175 99.1381H100.607C99.4648 99.1381 98.4857 98.4853 97.9961 97.5062C97.5065 96.527 96.4458 95.8743 95.3851 95.8743H86.4912"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.209 108.114H114.397C113.254 108.114 112.275 108.766 111.786 109.745C111.296 110.725 110.235 111.377 109.175 111.377H100.607C99.4648 111.377 98.4857 110.725 97.9961 109.745C97.5065 108.766 96.4458 108.114 95.3851 108.114H86.4912"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.369 75.4755H0.816406V79.5553H131.369V75.4755Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.816406 75.4755H131.369L119.129 71.3958H13.0557L0.816406 75.4755Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.369 75.4755H0.816406"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.1676 105.095H64.7867C67.0713 105.095 68.8664 103.299 68.8664 101.015L63.318 98.2405C61.6045 97.4246 59.7278 96.935 57.8511 96.935H42.1032C40.2265 96.935 38.3499 97.343 36.6364 98.2405L31.0879 101.015C31.0879 103.218 32.883 105.095 35.1676 105.095Z"
        fill="#00A499"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.1682 96.8534H64.7056C66.9903 96.8534 68.7854 95.0583 68.7854 92.7736V78.4129C68.7854 77.1074 68.459 75.8835 67.8878 74.7411L60.789 60.5436C59.4019 57.7693 56.5461 56.0558 53.5271 56.0558H46.2651C43.1645 56.0558 40.3087 57.7693 39.0032 60.5436L31.9044 74.7411C31.3332 75.8835 31.0068 77.1074 31.0068 78.4129V92.7736C31.0884 95.0583 32.8835 96.8534 35.1682 96.8534Z"
        fill="#00A499"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.8514 96.8534H42.022C40.5533 96.8534 39.411 95.5479 39.5741 94.0791L43.0011 66.3368C43.1643 65.1129 44.2251 64.2153 45.449 64.2153H54.4244C55.6484 64.2153 56.7091 65.1129 56.8723 66.3368L60.2993 94.0791C60.4625 95.6295 59.3201 96.8534 57.8514 96.8534Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.0879 100.933C31.0879 103.218 32.883 105.013 35.1676 105.013H64.7051C66.9897 105.013 68.7848 103.218 68.7848 100.933"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.918 100.117V86.246C46.918 85.3485 47.6523 84.6141 48.5499 84.6141H51.4057C52.3032 84.6141 53.0376 85.3485 53.0376 86.246V100.117C53.0376 101.015 52.3032 101.749 51.4057 101.749H48.5499C47.5707 101.749 46.918 101.096 46.918 100.117Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.1279 75.4755H66.7451"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.6162 97.1798H62.2579"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.9199 75.4755H56.9543"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.918 96.8534V86.246C46.918 85.3485 47.6523 84.6141 48.5499 84.6141H51.4057C52.3032 84.6141 53.0376 85.3485 53.0376 86.246V96.8534"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M49.9365 105.094V122.556" stroke="#00404D" strokeMiterlimit="10" />
      <path
        d="M110.97 72.2117H99.5469"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.5469 72.2117L97.915 59.1565H109.338L107.706 72.2117H99.5469Z"
        fill="#FFEABF"
        stroke="#FFEABF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.368 41.6136V33.454H196.644V41.6136"
        stroke="#A1E6E0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M196.644 27.7424H131.368V31.8221H196.644V27.7424Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M196.644 31.8221H131.368"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.774 5.71167H131.368V26.1104H135.774V5.71167Z"
        fill="#00A499"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M166.978 5.26127L163.938 25.4316L168.295 26.0881L171.335 5.91779L166.978 5.26127Z"
        fill="#FFEABF"
        stroke="#FFEABF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.075 5.25268L139.838 6.45996L145.427 26.0775L149.665 24.8702L144.075 5.25268Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141.16 25.8657L138.794 26.1104L136.183 2.61106L138.549 2.36627L141.16 25.8657Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.693 10.3436L146.56 11.3675L153.621 26.0791L155.754 25.0552L148.693 10.3436Z"
        fill="#00A499"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.673 1.88186L159.458 25.5575L161.431 26.0754L167.646 2.39979L165.673 1.88186Z"
        fill="#A1E6E0"
        stroke="#A1E6E0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M171.35 5.87488L168.249 26.0289L163.925 25.3761"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.485 0.815979H172.166V26.1105H188.485V0.815979Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M180.325 1.63196V25.2945"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.646 24.8865L145.403 26.0289L139.854 6.44604"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.5381 131.368V129.736L49.9369 122.556L70.3356 129.736V131.368"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.6787 125.82V124.188L49.937 122.556L65.2769 124.188V125.82"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.6842 126.472L49.9363 120.924L34.1885 126.472"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.485 130.552L180.326 110.153H147.688L139.528 130.552"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M182.202 126.472L175.43 110.153H152.175L145.484 126.472"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.528 130.552H188.322L182.203 126.472H145.566L139.528 130.552Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.485 90.5706H139.528V110.153H188.485V90.5706Z"
        fill="#FFEABF"
        stroke="#FFEABF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M184.406 85.6749H143.608L139.528 90.5706H188.485L184.406 85.6749Z"
        fill="#FFEABF"
        stroke="#FFEABF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M186.446 84.043H141.568V79.9632L145.648 77.5154H182.366L186.446 79.9632V84.043Z"
        fill="#A1E6E0"
        stroke="#A1E6E0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M186.446 84.043H141.568V88.1227H186.446V84.043Z"
        fill="#00A499"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141.568 79.9633H186.446"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.927 85.6749C168.94 85.6749 176.246 85.1269 176.246 84.451C176.246 83.775 168.94 83.2271 159.927 83.2271C150.915 83.2271 143.608 83.775 143.608 84.451C143.608 85.1269 150.915 85.6749 159.927 85.6749Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.007 103.871C165.719 103.871 167.107 102.482 167.107 100.77C167.107 99.0576 165.719 97.6694 164.007 97.6694C162.294 97.6694 160.906 99.0576 160.906 100.77C160.906 102.482 162.294 103.871 164.007 103.871Z"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.528 91.7945H188.485"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="197.46" height="133" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
