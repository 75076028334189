import * as React from 'react';
import { ISelection } from './selection';

export const Context = React.createContext<{
  onSave(file: File, signedID: string): void;
  onSelect(file: File): void;
  onCancel(file: File, uuid: string): void;
  onUpload(file: File, uuid: string, signedID: string): void;
  uploads: ISelection[];
}>({
  onSave: () => {
    /* noop */
  },
  onSelect: () => {
    /* noop */
  },
  onCancel: () => {
    /* noop */
  },
  onUpload: () => {
    /* noop */
  },
  uploads: [],
});
