import React from 'react';
import { COLORS, Box, FontWeight, Text } from '@clutter/clean';
import styled from '@emotion/styled';

import { InventoryItemFragment } from '@portal/schema';
import { ItemCard } from '@portal/components/helpers/inventory/item_card';
import { ImageCarousel } from '@portal/components/helpers/inventory/image_carousel';
import { imagesFor } from '@portal/components/items/utilities/customer_item';

const Container = styled(Box.Flex)`
  flex-direction: column;
  justify-content: center;
  padding: 7px 15px;
  margin-bottom: 25px;
  max-width: 400px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export const ItemInfoCard: React.FC<{
  item: InventoryItemFragment;
}> = ({ item }) => (
  <Container>
    <ImageCarousel images={imagesFor(item)} />
    <Box textAlign="center" padding="8px" color={COLORS.panther}>
      <Text.Caption weight={FontWeight.Medium}>
        <ItemCard.ItemName item={item} />
      </Text.Caption>
    </Box>
  </Container>
);
