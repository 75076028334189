import { Box, Button, COLORS, ExternalContent, mq, Text, TextButton } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { TrackedClick } from '@portal/components/wt/tracked_click';
import {
  AccountResolution__Offer,
  AccountResolution__OfferFragment,
  AccountResolution__OfferKind,
  Rating,
} from '@portal/schema';
import { IEventParams } from '@portal/types/wt/event';

import { FollowupAnswers, Review } from './review';

const BorderedBox = styled(Box)`
  border: solid ${COLORS.grayBorder};
  border-width: 1.5px 0;
`;

const MarginContent = styled(ExternalContent)`
  margin-bottom: 24px;
`;

const ShowOfferButton = styled(Button)`
  margin-bottom: 24px;
  ${mq({ width: ['100%', 'initial'] })}
`;

const CTA_COPY = 'Contact us';

const CONTACT_US_WT_PARAMS: IEventParams = {
  action: 'click',
  container: 'still_need_help',
  label: CTA_COPY,
  pageName: 'portal:help_content',
  objectName: 'contact_us',
  objectType: 'button',
};

const offerCopy = (offer: AccountResolution__OfferFragment): string => {
  switch (offer.kind) {
    case AccountResolution__OfferKind.FinalMonthProration:
      return 'Request Proration';
    case AccountResolution__OfferKind.WaiveRateAdjustment:
      return offer.metadata.action === 'reverse' ? 'Reverse Price Change' : 'Waive Upcoming Price Change';
    default:
      return 'See Offer';
  }
};

export const HelpContent: React.FC<{
  zendeskCopy: string;
  reasonID: string;
  offer?: AccountResolution__Offer;
  setHidingHelpContent(shouldHide: boolean): void;
  onContactUs(): void;
  onReview(rating: Rating, answers?: FollowupAnswers): void;
}> = ({ zendeskCopy, reasonID, offer, setHidingHelpContent, onContactUs, onReview }) => (
  <>
    <MarginContent dangerouslySetInnerHTML={{ __html: zendeskCopy }} />
    {offer && <ShowOfferButton onClick={() => setHidingHelpContent(true)}>{offerCopy(offer)}</ShowOfferButton>}
    <Review onReview={onReview} onContactUs={onContactUs} reasonID={reasonID} />
    <BorderedBox width="100vw" margin="0 0 0 -24px">
      <Box padding="32px 0 32px 32px">
        <Text.Title size="medium">Still Need Help?</Text.Title>
        <Text.Body>
          Our team is here to help.{' '}
          <TrackedClick
            params={{
              ...CONTACT_US_WT_PARAMS,
              selected_reason_id: reasonID,
            }}
          >
            <TextButton size="medium" onClick={onContactUs}>
              {CTA_COPY}
            </TextButton>
          </TrackedClick>
        </Text.Body>
      </Box>
    </BorderedBox>
  </>
);
