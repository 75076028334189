import * as React from 'react';

import { AddressDetailEnvironmentEnum } from '@portal/schema';

import { RadioFormGroup } from '../radio_form_group';

const OPTIONS = [
  { label: 'Indoor', value: AddressDetailEnvironmentEnum.Indoor },
  { label: 'Outdoor', value: AddressDetailEnvironmentEnum.Outdoor },
];

export const Environment: React.FC<{
  value?: string | null;
  onChange(value: AddressDetailEnvironmentEnum): void;
}> = ({ value, onChange }) => (
  <RadioFormGroup
    label="Indoor or Outdoor?"
    options={OPTIONS}
    value={value}
    name="environment"
    onChange={(selection) => onChange(selection)}
  />
);
