import { COLORS, Text } from '@clutter/clean';
import React from 'react';

import { AppointmentHubQuery, UpcomingSubscriptionSetFragment } from '@portal/schema';
import { Spacer } from '@shared/components/helpers';
import styled from '@emotion/styled';
import { OrderAppointmentDetailsHeader } from './header';
import { OrderAppointmentDetailsInfoList } from './info_list';
import { AppointmentDetailsButtons } from './buttons';

export type AppointmentDetailsOrder = AppointmentHubQuery['order'];

const FinePrint = styled(Text.Caption)`
  text-align: center;
  color: ${COLORS.storm};
`;

export const AppointmentDetails: React.FC<{
  upcomingSubscriptionSet: UpcomingSubscriptionSetFragment;
  order: AppointmentDetailsOrder;
  refetchData: () => void;
}> = ({ upcomingSubscriptionSet, order, refetchData }) => {
  const isLongDistanceOrder = !!order.movingOperation?.longDistanceTransportationRate;
  const travelRateMultiplier =
    order.movingOperation?.movingConfiguration && !isLongDistanceOrder
      ? order.movingOperation.movingConfiguration.travelRateMultiplier
      : 1;
  return (
    <>
      <OrderAppointmentDetailsHeader sourceOrder={order} />
      <Spacer height="16px" />
      <OrderAppointmentDetailsInfoList
        order={order}
        upcomingSubscriptionSet={upcomingSubscriptionSet}
        refetchData={refetchData}
      />
      <Spacer height="16px" />
      <AppointmentDetailsButtons order={order} />
      {!!order.bookingPartner && (
        <>
          <Spacer height="16px" />
          <FinePrint>Please contact {order.bookingPartner.name} directly if you need to cancel this move.</FinePrint>
        </>
      )}
      {travelRateMultiplier === 2 && (
        <>
          <Spacer height="16px" />
          <FinePrint>
            State law requires us to charge for double the drive time to transport your items between locations. This
            protects our customers, by keeping prices low and not charging our customers for drives between our
            warehouses and your home.
          </FinePrint>
        </>
      )}
    </>
  );
};
