import React, { useState } from 'react';

import { Spinner } from '@portal/components/helpers';
import { useBillingHistoryQuery } from '@portal/schema';

import { InvoiceList } from './invoice_list';

export const History: React.FC = () => {
  const [numPer, setNumPer] = useState<number>(10);

  const { data, loading } = useBillingHistoryQuery({ variables: { numPer } });

  if (!data && loading) {
    return <Spinner />;
  } else if (!data) {
    return null;
  }

  const { paginatedInvoiceList } = data;

  return (
    <InvoiceList
      invoices={paginatedInvoiceList.results}
      loading={loading}
      totalPages={paginatedInvoiceList.pagination.totalPages}
      onViewMore={() => setNumPer(numPer + 10)}
    />
  );
};
