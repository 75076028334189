import * as React from 'react';

import { AddressDetailField, IAddressDetail } from '@shared/types';

import { CheckboxFormGroup } from '../checkbox_form_group';

const OPTIONS = [
  {
    label: 'Stairs',
    value: AddressDetailField.Stairs,
    key: AddressDetailField.Stairs,
  },
  {
    label: 'Elevator',
    value: AddressDetailField.Elevator,
    key: AddressDetailField.Elevator,
  },
  {
    label: 'Service Elevator',
    value: AddressDetailField.ServiceElevator,
    key: AddressDetailField.ServiceElevator,
  },
  {
    label: "I don't know",
    value: AddressDetailField.AccessUnknown,
    key: AddressDetailField.AccessUnknown,
  },
];

export const Access: React.FC<{
  details: IAddressDetail;
  error?: string;
  onChange(value: { [key: string]: boolean }): void;
}> = ({ details, onChange, error }) => {
  const selected =
    !!details[AddressDetailField.Stairs] ||
    !!details[AddressDetailField.Elevator] ||
    !!details[AddressDetailField.ServiceElevator] ||
    !!details[AddressDetailField.AccessUnknown];

  return (
    <CheckboxFormGroup
      name={AddressDetailField.Stairs}
      label="How will we access the unit?"
      error={!selected ? error : undefined}
      options={OPTIONS}
      values={{
        [AddressDetailField.Stairs]: details[AddressDetailField.Stairs],
        [AddressDetailField.Elevator]: details[AddressDetailField.Elevator],
        [AddressDetailField.ServiceElevator]: details[AddressDetailField.ServiceElevator],
        [AddressDetailField.AccessUnknown]: details[AddressDetailField.AccessUnknown],
      }}
      onChange={(_, value) => {
        onChange(value);
      }}
    />
  );
};
