import React from 'react';
import { Box, COLORS, mq, Text, TextButton } from '@clutter/clean';
import cash from '@root/images/cash.svg';
import styled from '@emotion/styled';

import { Account__Marketing__CouponFragment } from '@portal/schema';
import { formattedDiscount } from '@shared/utils/coupon';

const Container = styled(Box.Flex)`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border-radius: 4px;
  padding: 24px;
`;

const Discount = styled.span`
  color: ${COLORS.tealPrimary};
`;

const Title = styled(Text.Title)`
  ${mq({ fontSize: ['20px', '24px'] })}
`;

export const Banner: React.FC<{ coupon: Account__Marketing__CouponFragment; includeCTA?: boolean }> = ({
  coupon,
  includeCTA = false,
}) => (
  <Container>
    <img src={cash} alt="Cash" width="100px" style={{ marginRight: '16px' }} />
    <Box.Flex flexDirection="column" alignItems="start">
      <Title size="small" display="inline">
        Reschedule and get <Discount>{formattedDiscount(coupon)}</Discount> off your upcoming appointment!
      </Title>
      {includeCTA && <TextButton>Claim Offer</TextButton>}
    </Box.Flex>
  </Container>
);
