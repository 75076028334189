import * as React from 'react';

import { AddressDetailField, IAddressDetail, IAddressValidationErrors } from '@shared/types';
import { Access } from './access';
import { AdditionalNotes } from './additional_notes';
import { BuildingFloor } from './building_floor';

import { Restrictions } from './restrictions';
import { LongCarry } from './long_carry';

export const Dorm: React.FC<{
  details: IAddressDetail;
  errors?: IAddressValidationErrors;
  setDetails(details: IAddressDetail): void;
}> = ({ details, errors, setDetails }) => (
  <>
    <BuildingFloor
      details={details}
      error={errors?.[AddressDetailField.Floor]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.Floor]: value })}
    />
    <Access details={details} error={errors?.access} onChange={(values) => setDetails({ ...details, ...values })} />
    <LongCarry
      value={details[AddressDetailField.LongCarry]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.LongCarry]: value })}
    />
    <AdditionalNotes
      value={details[AddressDetailField.AdditionalNotes]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.AdditionalNotes]: value })}
    />
    <Restrictions
      details={details}
      error={errors?.[AddressDetailField.BuildingRestrictionsAnswer]}
      onDetail={setDetails}
      initialBuildingRestrictionsAnswer={details[AddressDetailField.BuildingRestrictionsAnswer]}
    />
  </>
);
