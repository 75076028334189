import React from 'react';
import { COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';

import { Account__Marketing__CouponFragment } from '@portal/schema';
import { CleanCreditCard } from '@portal/components/shared/icons';
import { formattedDiscount } from '@shared/utils/coupon';

const Container = styled.div`
  background: ${COLORS.sunshine};
  display: flex;
  padding: 12px;
  align-items: center;
  border-radius: 0.25rem;
  margin: 12px 0;
`;

const Icon = styled.div`
  width: 46px;
`;

const Copy = styled(Text.Body)`
  margin-left: 12px;
`;

export const RescheduleOfferWarning: React.FC<{
  coupon: Account__Marketing__CouponFragment;
}> = ({ coupon }) => (
  <Container>
    <Icon>
      <CleanCreditCard />
    </Icon>
    <Copy>
      If you reschedule your order now, your original reschedule offer coupon for {formattedDiscount(coupon)} off this
      order will be removed.
    </Copy>
  </Container>
);
