import { COLORS, Text, TextButton } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Spinner } from '@portal/components/helpers';
import { MoverWithBoxes } from '@portal/components/shared/icons';
import { Billing__InvoiceFragment as Invoice } from '@portal/schema';
import { PageReview } from '@portal/components/helpers/page_review';

import { InvoiceCard } from './invoice_card';

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 86px 0;
`;

const Title = styled(Text.Title)`
  color: ${COLORS.panther};
  margin-top: 16px;
  text-align: center;
`;

const Callout = styled(Text.Callout)`
  color: ${COLORS.panther};
  margin-top: 8px;
  padding: 0 24px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  padding: 24px 0;
  text-align: center;
`;

const InvoicesContainer = styled.div`
  max-width: 712px;
  margin: auto;
  overflow: auto;
`;

const PageReviewContainer = styled.div`
  max-width: 712px;
  margin: auto;
`;

const Empty: React.FC = () => (
  <EmptyContainer>
    <MoverWithBoxes />
    <Title size="extraSmall">Nothing here yet!</Title>
    <Callout>After your first pickup, you’ll be able to find all of your past bills here.</Callout>
  </EmptyContainer>
);

const ViewMoreButton: React.FC<{ loading: boolean; onViewMore(): void }> = ({ loading, onViewMore }) => (
  <ButtonContainer>
    {loading ? (
      <Spinner />
    ) : (
      <TextButton size="medium" onClick={() => onViewMore()}>
        View More
      </TextButton>
    )}
  </ButtonContainer>
);

export const InvoiceList: React.FC<{
  invoices: Invoice[];
  loading: boolean;
  totalPages: number;
  onViewMore(): void;
}> = ({ invoices, loading, totalPages, onViewMore }) => {
  if (!invoices.length) {
    return <Empty />;
  }

  return (
    <>
      <InvoicesContainer>
        {invoices.map((invoice) => (
          <InvoiceCard key={invoice.id} invoice={invoice} />
        ))}
      </InvoicesContainer>
      {totalPages > 1 && <ViewMoreButton loading={loading} onViewMore={onViewMore} />}
      <PageReviewContainer>
        <PageReview />
      </PageReviewContainer>
    </>
  );
};
