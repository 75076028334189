import React from 'react';

export const CheckMark: React.FC = () => (
  <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="white"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.2865 2.6442C31.6799 3.0352 31.6799 3.67165 31.2865 4.06266L11.9339 23.2992C11.5439 23.6869 10.914 23.6869 10.524 23.2992L0.713505 13.5476C0.320143 13.1566 0.320142 12.5201 0.713504 12.1291L2.66867 10.1857C3.0587 9.79799 3.68859 9.79799 4.07863 10.1857L10.524 16.5924C10.914 16.9801 11.5439 16.9801 11.9339 16.5924L27.9214 0.700753C28.3114 0.313058 28.9413 0.313059 29.3313 0.700754L31.2865 2.6442Z"
    />
  </svg>
);
