import React from 'react';

import styled from '@emotion/styled';
import { History } from 'history';
import { Link } from 'react-router-dom';

import { COLORS, Button, Text, mq } from '@clutter/clean';
import { orderURL, ordersURL } from '@portal/config/routes';
import { OrdersDocument, Status, useCancelOrderMutation } from '@portal/schema';

import {
  Account__CancelIntent__Reason as AccountCancelIntentReason,
  OrderTypeEnum,
  OrderServiceTypeEnum,
  OrderSubtypeEnum,
} from '@portal/schema';
import { OrderDetailsOrder } from '@portal/components/orders/cancel/steps';

const TextContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  h1 {
    margin-bottom: 16px;
  }
  ${mq({
    textAlign: ['left', 'center'],
  })}
`;

const Error = styled.div`
  color: ${COLORS.toucan};
  margin-top: -16px;
`;

const ButtonLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const Buttons = styled.div`
  ${mq({
    width: ['100%', '400px'],
  })}
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    width: 100%;
  }
  & > div:first-of-type {
    margin-bottom: 16px;
  }
`;

interface IConfirmProps {
  history: History;
  order: OrderDetailsOrder;
}

const reservationWarning = (isOnboarding: boolean, serviceType: OrderServiceTypeEnum, type: OrderTypeEnum) => {
  if (serviceType === OrderServiceTypeEnum.Shipment) {
    return `${
      type === OrderTypeEnum.Return
        ? 'Your shipment will be canceled, and your items will not be sent.'
        : 'Your shipment will be canceled.'
    }`;
  } else if (type === OrderTypeEnum.Disposal) {
    return 'Your order will be canceled, and your items will not be disposed. They will remain in storage and you will be refunded any disposal fees that were already paid.';
  } else if (isOnboarding) {
    return 'Once canceled, your time is no longer reserved, and your rate is no longer locked.';
  } else {
    return 'Once canceled, your appointment is no longer reserved, and your rate is no longer locked.';
  }
};

const cancelType = (serviceType: OrderServiceTypeEnum) => {
  if (serviceType === OrderServiceTypeEnum.Shipment) {
    return 'shipment';
  } else {
    return 'appointment';
  }
};

export const Confirm = ({ history, order }: IConfirmProps) => {
  const [execute, { data }] = useCancelOrderMutation({
    variables: { orderID: order.id },
    refetchQueries: [{ query: OrdersDocument }, { query: OrdersDocument, variables: { onlyActive: true } }],
  });

  const cancelOrder = async () => {
    const result = await execute();
    if (result?.data?.cancelOrder?.status !== Status.Ok) return;
    if (order.type === OrderTypeEnum.RetailDelivery) history.push(ordersURL());
    else history.push(orderURL(order.id, 'cancel/canceled'));
  };

  const isOnboarding = order.subtype === OrderSubtypeEnum.Onboarding;

  if (order.accountCancelIntent?.reason === AccountCancelIntentReason.PayToVacate) {
    return null;
  }
  return (
    <div>
      <TextContainer>
        <Text.Title size="large">Confirm you’d like to cancel</Text.Title>
        <Text.Body>
          {order.permissions.cancellationFeeDisclaimer && (
            <>
              {order.permissions.cancellationFeeDisclaimer}
              <br />
            </>
          )}
          {order.permissions.chargeLateFee && (
            <>
              There is a $25 late fee for each extra day that your items were held in storage.
              <br />
            </>
          )}
          <br />
          {reservationWarning(isOnboarding, order.serviceType!, order.type!)}
        </Text.Body>
      </TextContainer>
      <Buttons>
        <div>
          <Button kind="destructive" onClick={cancelOrder} fullWidth>
            Yes, cancel my {cancelType(order.serviceType!)}
          </Button>
        </div>
        {data?.cancelOrder?.error && <Error>{data.cancelOrder.error}</Error>}
        <div>
          <ButtonLink to={ordersURL()}>
            <Button kind="secondary" fullWidth>
              No, keep my {cancelType(order.serviceType!)}
            </Button>
          </ButtonLink>
        </div>
      </Buttons>
    </div>
  );
};
