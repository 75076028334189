import React, { useState } from 'react';
import styled from '@emotion/styled';
import { DateTime, Duration, Interval } from 'luxon';

import {
  AvailabilityWaitlistRequestFragmentDoc,
  OrderStatusEnum,
  OrderSubtypeEnum,
  OrderTypeEnum,
  RescheduleOrderFragment,
  useCancellationPolicyQuery,
  useWaitlistEligibleQuery,
  useAvailabilityWaitlistRequestCreateMutation,
  useAvailabilityWaitlistRequestDestroyMutation,
  Account__State,
  AvailabilitiesInputKind,
} from '@portal/schema';

import { Availabilities } from '@portal/components/helpers/availabilities';
import { ScheduledDatePicker } from '@portal/components/helpers/scheduled_date_picker';
import { ScheduledIntervalPicker } from '@portal/components/helpers/scheduled_interval_picker';
import { PerItemOrderFooter } from '@portal/components/orders/details/per_item_order_footer';
import { Footer, Button } from '@portal/components/orders/steps/base';
import { Info as FacilityInfo } from '@portal/components/orders/facility/info';

import { useTimeZone } from '@portal/components/helpers/time_zone';
import { Spacing } from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';

import { Confirmation } from './confirmation';
import { RescheduleOfferWarning } from './reschedule_offer_warning';
import { OrderServiceTypeEnum } from '../../../../admin/schema';
import { LaborRateData } from '../steps/scheduled';

const FROM_ISO_OPTIONS = { setZone: true };

const ButtonContainer = styled.div`
  padding: 16px 24px;
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  @media (min-width: 600px) {
    margin: 0 auto;
    button {
      min-width: 150px;
    }
  }
`;

const FACILITY_DISCLAIMER = 'If you arrive after this window, you will be charged the reschedule fee.';

export const OrderRescheduleForm: React.FC<{
  order: RescheduleOrderFragment;
  loading: boolean;
  moverOverride?: number;
  onSubmit(scheduled: DateTime, duration: Duration, laborRateID?: string, perMoverHourAdjustmentAmount?: number): void;
}> = ({ order, loading: loadingForm, moverOverride, onSubmit }) => {
  const tz = useTimeZone({
    warehouseID: order.facilityWarehouse?.id,
    addressID: order.address.id,
  });
  const scheduled = DateTime.fromISO(order.scheduled, FROM_ISO_OPTIONS);
  const [date, setDate] = useState<DateTime>(scheduled);
  const [intervalConfig, setIntervalConfig] = useState<{ interval: Interval; forced: boolean } | undefined>(undefined);
  const [laborRateData, setLaborRateData] = useState<LaborRateData | undefined>(undefined);
  const [confirmedDate, setConfirmedDate] = useState<boolean>(false);

  const { data } = useCancellationPolicyQuery({ variables: { orderID: order.id } });
  const { data: waitlistEligibility } = useWaitlistEligibleQuery();

  const [waitlistRequestCreate, { loading: creating }] = useAvailabilityWaitlistRequestCreateMutation();
  const [waitlistRequestDestroy, { loading: destroying }] = useAvailabilityWaitlistRequestDestroyMutation();

  const valid = !!date && !!intervalConfig && confirmedDate;

  const reset = () => {
    setDate(scheduled);
    setIntervalConfig(undefined);
  };

  const onAddWaitlistDate = async (addDate: DateTime) => {
    await waitlistRequestCreate({
      variables: { input: { orderID: order.id, date: addDate.toISODate() } },
      update(cache, { data: createData }) {
        const newRequest = createData?.result?.waitlistRequest;
        if (!newRequest) return;
        cache.modify({
          id: `Order:${order.id}`,
          fields: {
            waitlistRequests: (existing = []) => {
              const newRequestFragment = cache.writeFragment({
                data: newRequest,
                fragment: AvailabilityWaitlistRequestFragmentDoc,
              });
              return [...existing, newRequestFragment];
            },
          },
        });
      },
    });
  };

  const onRemoveWaitlistDate = async (removeDate: DateTime) => {
    const id = order.waitlistRequests.find((waitlistedDate) => waitlistedDate.date === removeDate.toISODate())?.id;
    if (id)
      await waitlistRequestDestroy({
        variables: {
          input: {
            IDs: [id],
            orderID: order.id,
          },
        },
        update(cache, { data: destroyData }) {
          const deleted = destroyData?.result?.deleted?.[0];
          if (deleted) {
            cache.evict({ id: cache.identify(deleted) });
          }
        },
      });
  };

  const hour = intervalConfig?.interval.start?.hour || scheduled.hour;
  const { facilityWarehouse, serviceType, rescheduleOfferCoupon } = order;
  const facility = serviceType === OrderServiceTypeEnum.Facility;
  const facilityInfo = facilityWarehouse && (
    <FacilityInfo facility={facilityWarehouse} disclaimer={FACILITY_DISCLAIMER} />
  );
  const loading = loadingForm || creating || destroying;
  const waitlistEligible =
    waitlistEligibility?.eligible &&
    order.subtype !== OrderSubtypeEnum.Onboarding &&
    order.type !== OrderTypeEnum.Move &&
    order.type !== OrderTypeEnum.Disposal &&
    order.serviceType !== OrderServiceTypeEnum.LongDistance &&
    order.serviceType !== OrderServiceTypeEnum.Disposal;

  const showSLAPicker = date && confirmedDate && !facility;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        event.stopPropagation();
        if (!intervalConfig) {
          return;
        }
        onSubmit(
          intervalConfig.interval.start,
          intervalConfig.interval.end.diff(intervalConfig.interval.start),
          laborRateData?.laborRate.id,
          laborRateData?.perMoverHourAdjustmentAmount,
        );
      }}
    >
      {order.status === OrderStatusEnum.Pending && order.waitlistRequests.length > 0 ? (
        <p>
          This appointment is not confirmed. To confirm your appointment, select an available date from the calendar
          below. You will not be removed from the waitlist and we will still notify you if one of your waitlisted dates
          become available
        </p>
      ) : (
        <Confirmation scheduled={scheduled} window={Duration.fromISO(order.window)} />
      )}
      {rescheduleOfferCoupon && <RescheduleOfferWarning coupon={rescheduleOfferCoupon} />}
      <hr />
      {tz && (
        <Availabilities
          tz={tz}
          kind={AvailabilitiesInputKind.Rescheduling}
          orderID={order.id}
          moverOverride={moverOverride}
          date={date}
        >
          {({ availabilities = [], loading: availabilityLoading, from, till, fetchMore }) => (
            <Spacing mt={2} mb={4}>
              <ScheduledDatePicker
                serviceType={serviceType || undefined}
                availabilities={availabilities}
                date={date}
                confirmedDate={confirmedDate}
                laborRateData={laborRateData}
                baseLaborRate={order.baseLaborRate || undefined}
                onLaborRate={setLaborRateData}
                onDate={setDate}
                onInterval={setIntervalConfig}
                from={from}
                till={till}
                fetchMore={fetchMore}
                additionalInfo={facilityInfo}
                showNav
                rescheduling
                waitlistEligible={waitlistEligible}
                waitlistedDates={order.waitlistRequests.map((request) => DateTime.fromISO(request.date))}
                onAddWaitlistDate={onAddWaitlistDate}
                onRemoveWaitlistDate={onRemoveWaitlistDate}
                setConfirmedDate={setConfirmedDate}
                loading={availabilityLoading}
                isAccountCanceled={order.account.state === Account__State.Canceled}
                isMove={order.type === OrderTypeEnum.Move}
              />
              {showSLAPicker && (
                <ScheduledIntervalPicker
                  availabilities={availabilities}
                  date={date}
                  interval={intervalConfig?.interval}
                  onInterval={setIntervalConfig}
                />
              )}
            </Spacing>
          )}
        </Availabilities>
      )}

      {!order.services.find(({ type }) => type === OrderTypeEnum.Pickup) && (
        <p className="text-center">{data?.cancellationPolicy}</p>
      )}
      {order.permissions.chargeLateFee && (
        <p className="text-center">$25 late fee for each extra day that your items were held in storage</p>
      )}

      {order.subtype !== OrderSubtypeEnum.Subsequent ? (
        <>
          <Spacer height="100px" />
          <Footer>
            <ButtonContainer>
              <Button type="button" className="secondary" onClick={reset}>
                Reset
              </Button>
              <Button type="submit" className="primary" loading={loading} disabled={!valid}>
                Save Changes
              </Button>
            </ButtonContainer>
          </Footer>
        </>
      ) : (
        <PerItemOrderFooter
          orderID={order.id}
          orderEdits={{ scheduled: date ? date.set({ hour }).toJSON() : scheduled.toJSON() }}
        >
          <Button type="button" className="secondary" onClick={reset}>
            Reset
          </Button>
          <Button type="submit" className="primary" loading={loading} disabled={!valid}>
            Save Changes
          </Button>
        </PerItemOrderFooter>
      )}
    </form>
  );
};
