import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faAt,
  faBan,
  faBox,
  faCloud,
  faComment,
  faCreditCard,
  faDollarSign,
  faEnvelope,
  faFont,
  faInbox,
  faInfoCircle,
  faLink,
  faMapMarker,
  faMarker,
  faMinus,
  faPhone,
  faPlus,
  faQuestionCircle,
  faRedo,
  faSearch,
  faTimes,
  faSpinner,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

import { faCheckSquare, faCircle, faClock, faDotCircle, faSquare } from '@fortawesome/free-regular-svg-icons';

import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
  faFacebook,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faAt,
  faBan,
  faBox,
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
  faCheckSquare,
  faCircle,
  faClock,
  faCloud,
  faComment,
  faCreditCard,
  faDollarSign,
  faDotCircle,
  faEnvelope,
  faFacebook,
  faFont,
  faInbox,
  faInfoCircle,
  faLink,
  faMapMarker,
  faMarker,
  faMinus,
  faPhone,
  faPlus,
  faQuestionCircle,
  faRedo,
  faSearch,
  faSpinner,
  faSquare,
  faTimes,
  faTwitter,
  faExclamationTriangle,
);
