import React, { useState } from 'react';

import { DateTime, Duration } from 'luxon';

import { Box, COLORS, FontWeight, SpinLoader, RadioCard, Text } from '@clutter/clean';
import { CallbackWindow, useCallbackWindowsQuery } from '@portal/schema';
import { useTrack } from '@clutter/wt';

import { formatCallbackWindow } from './util';

export const Windows: React.FC<{
  selected?: CallbackWindow;
  disabled?: boolean;
  onSelect(callbackWindow: CallbackWindow): void;
}> = ({ selected: selectedCallbackWindow, disabled, onSelect }) => {
  const [now] = useState<string>(DateTime.local().toISO());
  const track = useTrack();
  const { data, loading } = useCallbackWindowsQuery({
    fetchPolicy: 'network-only',
  });

  if (!data || loading) {
    return <SpinLoader />;
  }
  const { callbackWindows, allowImmediateCallback } = data;

  const businessDay = DateTime.fromISO(callbackWindows?.[0]?.time);
  const sameDayCallbacks = businessDay.startOf('day').toMillis() === DateTime.fromISO(now).startOf('day').toMillis();

  const onTimeSelect = (callbackWindow: CallbackWindow) => {
    const { time, duration } = callbackWindow;
    track({
      action: 'click',
      objectType: 'button',
      objectName: 'callback_window',
      value: time,
      metadata: {
        duration,
        callback_type: time === now ? 'immediate' : 'scheduled',
      },
    });
    onSelect(callbackWindow);
  };

  return (
    <Box overflow="auto" maxHeight={['calc(100dvh - 424px)', 'initial']} padding="0 0 12px">
      {allowImmediateCallback && (
        <>
          <Box margin="8px 0">
            <Text.Body weight={FontWeight.Medium}>Let’s talk now</Text.Body>
          </Box>
          <Box textAlign="center" width="50%" padding="3px">
            <RadioCard
              selected={selectedCallbackWindow?.time === now}
              disabled={disabled}
              onChange={() =>
                onTimeSelect({
                  time: now,
                  duration: Duration.fromObject({ minutes: 10 }).toISO(),
                })
              }
              value="now"
              name="callbackWindow"
            >
              <Text.SmallCaps color={COLORS.tealPrimary}>Call me now</Text.SmallCaps>
            </RadioCard>
          </Box>
        </>
      )}

      <Box margin="12px 0">
        <Text.Body weight={FontWeight.Medium}>
          Schedule a call back for {sameDayCallbacks ? 'today' : 'tomorrow'}
        </Text.Body>
      </Box>

      <Box.Grid textAlign="center" gridGap="8px" gridTemplateColumns={['1fr 1fr', '1fr 1fr 1fr 1fr']} padding="3px">
        {callbackWindows.map((callbackWindow, key) => (
          <Box.GridItem key={key}>
            <RadioCard
              disabled={disabled}
              selected={selectedCallbackWindow?.time === callbackWindow.time}
              onChange={() => onTimeSelect(callbackWindow)}
              value={callbackWindow.time}
              name="callbackWindow"
            >
              <Text.SmallCaps color={COLORS.tealPrimary}>{formatCallbackWindow(callbackWindow)}</Text.SmallCaps>
            </RadioCard>
          </Box.GridItem>
        ))}
      </Box.Grid>
    </Box>
  );
};
