const grayLightest = '#edf0f2';
const grayLight = '#afb1b3';
const tealDarkest = '#063d3a';

const COLORS = {
  black: '#000000',
  white: '#ffffff',

  grayLightest,
  grayLighter: '#c8cacc',
  grayLight,
  gray: '#969899', // default gray
  grayDark: '#707273',
  grayDarker: '#4b4c4d',
  grayDarkest: '#252626',

  tealLightest: '#d0f0ee',
  tealLighter: '#7cded8',
  tealLight: '#43d1c9',
  teal: '#13bfb5', // default, brand teal
  tealDark: '#0f948c',
  tealDarker: '#0a6963',
  tealNavbar: '#0a3233',
  tealDarkest,

  blueLightest: '#d0e3f0',
  blueLighter: '#6aaede',
  blueLight: '#3891d1',
  blue: '#1790e6', // default blue
  blueDark: '#0f5c94',
  blueDarker: '#0a4169',
  blueDarkest: '#06263d',

  orangeLightest: '#fce5cf',
  orangeLighter: '#f7cba1',
  orangeLight: '#f2ab68',
  orange: '#f08d30', // default orange
  orangeDark: '#a15d1f',
  orangeDarker: '#69380a',
  orangeDarkest: '#3d2106',

  redLightest: '#ffd1d1',
  redLighter: '#ffa6a6',
  redLight: '#ff7373',
  red: '#f54949', // default red
  redDark: '#c22727',
  redDarker: '#690a0a',
  redDarkest: '#3d0606',

  greenLightest: '#c9f0dc',
  greenLight: '#13bf66',
  green: '#3bb576',
  purple: '#6a2ee6',

  yellowLight: '#fff0d7',
  yellow: '#ebaa4b',
  darkYellow: '#fcb22c',

  // Semantic names
  inputPlaceholder: grayLight,
  inputBackground: grayLightest,
  inputBorder: tealDarkest,
};

export { COLORS };
