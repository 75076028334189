import React, { useState } from 'react';
import { IOrder, OrderStatus } from '@portal/types';
import { Button, Text } from '@clutter/clean';
import { Spacer } from '@shared/components/helpers';
import { DateTime } from 'luxon';
import { Header } from '@portal/components/helpers/header';
import { Link } from 'react-router-dom';
import { orderURL } from '@portal/config/routes';
import { useAvailabilityWaitlistRequestDestroyMutation } from '@portal/schema';

export const WaitlistRequests: React.FC<{ order: IOrder; isFirstEntry: boolean }> = ({ order, isFirstEntry }) => {
  const { waitlistRequests } = order;
  const [waitlistRequestDestroy, { loading: destroying }] = useAvailabilityWaitlistRequestDestroyMutation({
    onCompleted: () => setRemovedFromWaitlist(true),
  });
  const [removedFromWaitlist, setRemovedFromWaitlist] = useState<boolean>(false);

  if (!waitlistRequests?.length) return null;
  if (removedFromWaitlist)
    return (
      <>
        {isFirstEntry && <Spacer height="2rem" />}
        <Text.Body>You have been removed from the waitlist.</Text.Body>
        <hr />
      </>
    );

  const onDestroy = async () => {
    await waitlistRequestDestroy({
      variables: {
        input: {
          IDs: waitlistRequests.map(({ id }) => id),
          orderID: order.id,
        },
      },
    });
  };

  return (
    <>
      {isFirstEntry && <Spacer height="2rem" />}
      <Header tag={isFirstEntry ? 'h1' : 'h4'}>You are on the waitlist</Header>
      <Text.Body>You are on the waitlist for the dates below:</Text.Body>
      <ul>
        {waitlistRequests.map(({ date }) => (
          <li key={date}>{DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED)}</li>
        ))}
      </ul>
      {order.status === OrderStatus.Pending && (
        <>
          <Text.Body style={{ marginBottom: '16px' }}>
            You can still schedule your appointment on an available date without removing yourself from the waitlist.
          </Text.Body>
          <Link className="btn btn-primary" to={orderURL(order.id, 'reschedule')}>
            Schedule
          </Link>
        </>
      )}
      {order.status === OrderStatus.Scheduled && (
        <Button loading={destroying} onClick={onDestroy}>
          Remove me from waitlist
        </Button>
      )}
      <hr />
    </>
  );
};
