import styled from '@emotion/styled';

import { BREAKPOINTS, Text } from '@clutter/clean';

export const Container = styled.div`
  max-width: 500px;
  margin: auto;
`;

export const SubmitContainer = styled.div`
  margin: 24px 0 8px;
`;

export const SourceContainer = styled.div`
  margin: 0 8px;
`;

export const MainTitle = styled(Text.Title)`
  margin-bottom: 40px;
  margin-left: 8px;
`;

export const Heading = styled(Text.Title)`
  margin-bottom: 24px;
  margin-left: 8px;
`;

export const Divider = styled.hr`
  margin: 40px 0;

  @media (max-width: ${BREAKPOINTS.MD}) {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }
`;

export const CardContainer = styled.div`
  margin: 8px 0;
`;
