import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLORS } from '@root/colors';
import React, { forwardRef } from 'react';

const Container = styled.div`
  position: relative;
`;

const Input = styled.input`
  padding-right: 30px;
`;

const Icon = styled.div`
  position: absolute;
  top: 7px;
  right 12px;
`;

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const SearchInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <Container>
    <Input className="form-control" placeholder="Search" {...props} type="text" ref={ref} />
    <Icon>
      <FontAwesomeIcon icon="search" color={COLORS.gray} />
    </Icon>
  </Container>
));
