import { Text } from '@clutter/clean';
import React from 'react';

import { AccountResolution__OfferFragment } from '@portal/schema';

import { Accepted as DoorToDoorAccepted } from './door_to_door/accepted';
import { Accepted as SelfStorageAccepted } from './self_storage/accepted';

export const Accepted: React.FC<{ offer: AccountResolution__OfferFragment }> = ({ offer }) => (
  <Text.Body>
    {offer.metadata.product === 'self_storage' ? (
      <SelfStorageAccepted offer={offer} />
    ) : (
      <DoorToDoorAccepted offer={offer} />
    )}
  </Text.Body>
);
