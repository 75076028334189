import styled from '@emotion/styled';
import * as React from 'react';
import { useContext } from 'react';
import { Context } from './context';

const DEFAULT_MULTIPLE = true;
const DEFAULT_DISABLED = false;

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const FileSelector = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const Selector: React.FC<
  InputProps & {
    accept?: string;
    multiple?: boolean;
    disabled?: boolean;
  }
> = ({ accept, multiple = DEFAULT_MULTIPLE, disabled = DEFAULT_DISABLED, ...props }) => {
  const { onSelect } = useContext(Context);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) {
      return;
    }
    for (let index = 0; index < files.length; index++) {
      const file = files.item(index);
      if (file) {
        onSelect(file);
      }
    }

    event.target.value = '';
  };

  return (
    <FileSelector
      {...props}
      type="file"
      name="file"
      aria-hidden="true"
      multiple={multiple}
      accept={accept}
      onChange={onChange}
      disabled={disabled}
      data-test-id="file-upload-selector"
    />
  );
};
