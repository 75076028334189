import { PickupInventoryInput } from '@admin/schema';
import { IOrderInventory, IPickupInventoryInput } from '@shared/types';

import { filterInventoryForSave } from './filter_inventory_for_save';
import { buildEstimatedItemInput } from './build_estimated_item_input';
import { buildEstimationsInput } from './build_estimations_input';
import { buildRoomsInput } from './build_rooms_input';

interface IBuildPickupInventoryInputProps {
  inventory: IOrderInventory;
}

export const buildPickupInventoryInput = ({ inventory }: IBuildPickupInventoryInputProps): IPickupInventoryInput => {
  const filteredInventory = filterInventoryForSave(inventory);

  if (filteredInventory.isFullMoveOut) {
    Object.assign(filteredInventory, { needsPackingHelp: true });
  }

  return {
    estimatedItems: (filteredInventory.extraItems || []).map(buildEstimatedItemInput),
    estimations: [
      ...buildEstimationsInput(filteredInventory.packableEstimations),
      ...buildEstimationsInput(filteredInventory.unpackableEstimations),
    ],
    rooms: buildRoomsInput(filteredInventory.rooms),
    isFullMoveOut: filteredInventory.isFullMoveOut,
    needsPackingHelp: filteredInventory.needsPackingHelp,
    requestedMovers: filteredInventory.requestedMovers,
  };
};

// Same method as above just returns the codegen type.
// TODO: update to return codegen type in original method and fix usages.
export const buildPickupInventoryInputCodegen = ({
  inventory,
}: IBuildPickupInventoryInputProps): PickupInventoryInput => {
  const filteredInventory = filterInventoryForSave(inventory);

  if (filteredInventory.isFullMoveOut) {
    Object.assign(filteredInventory, { needsPackingHelp: true });
  }

  return {
    estimatedItems: (filteredInventory.extraItems || []).map(buildEstimatedItemInput),
    estimations: [
      ...buildEstimationsInput(filteredInventory.packableEstimations),
      ...buildEstimationsInput(filteredInventory.unpackableEstimations),
    ],
    rooms: buildRoomsInput(filteredInventory.rooms),
    isFullMoveOut: filteredInventory.isFullMoveOut ?? false,
    needsPackingHelp: filteredInventory.needsPackingHelp,
    requestedMovers: filteredInventory.requestedMovers ?? 0,
  };
};
