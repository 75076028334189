import { Input } from '@clutter/clean';
import styled from '@emotion/styled';

import React, { useState } from 'react';

const StyledInput = styled(Input)`
  margin-top: 12px;
  width: 100%;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const CurrencyFormControl: React.FC<
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'amount' | 'onChange'> & {
    amount?: number;
    onChange(amount?: number): void;
  }
> = ({ amount, onChange, ...props }) => {
  const [value, setValue] = useState<number | string | undefined>(amount);
  return (
    <StyledInput
      {...props}
      type="number"
      value={value || ''}
      step="0.01"
      required
      onChange={(event) => {
        const raw = !event.target.value.length ? undefined : Number(event.target.value);
        const val = raw && !Number.isNaN(raw) ? raw : undefined;
        onChange(val);
        setValue(val);
      }}
    />
  );
};
