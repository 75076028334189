import * as React from 'react';

import {
  AddressDetailBuildingType,
  AddressDetailField,
  IAddressDetail,
  IAddressValidationErrors,
  IAddressWithDetails,
} from '@shared/types';
import { Box } from '@clutter/clean';
import { AptSuite } from './form/apt_suite';
import { BuildingType } from './form/building_type';

import { Apartment } from './form/buildings/apartment';
import { Commercial } from './form/buildings/commercial';
import { Default } from './form/buildings/default';
import { DonationCenter } from './form/buildings/donation_center';
import { Dorm } from './form/buildings/dorm';
import { House } from './form/buildings/house';
import { Warehouse } from './form/buildings/warehouse';

import { AdditionalInstructions as ParkingAdditionalInstructions } from './form/parking/additional_instructions';
import { LocationType as ParkingLocationType } from './form/parking/location_type';
import { MaxTruckHeight as ParkingMaxTruckHeight } from './form/parking/max_truck_height';
import { PreciseLocation as ParkingPreciseLocation } from './form/parking/precise_location';
import { SpecialCases as ParkingSpecialCases } from './form/parking/special_cases';

import * as Utils from './utils';

export const AppointmentAddressDetails: React.FC<{
  errors?: IAddressValidationErrors;
  address: IAddressWithDetails;
  setAddress(address: IAddressWithDetails): void;
}> = ({ errors, address, setAddress }) => {
  const details = address.details || Utils.DEFAULT_DETAILS;
  const buildingType = details[AddressDetailField.BuildingType];
  const setDetail = (value: IAddressDetail) => setAddress({ ...address, details: value });

  return (
    <Box>
      <BuildingType
        disabled={!address}
        details={details}
        error={errors?.[AddressDetailField.BuildingType]}
        onChange={(value) => setDetail({ ...details, [AddressDetailField.BuildingType]: value })}
      />
      <AptSuite
        address={address}
        disabled={!address}
        error={errors?.aptsuite}
        onChange={(aptsuite) => setAddress({ ...address, aptsuite: aptsuite || undefined })}
      />
      <Box>
        {(() => {
          switch (buildingType) {
            case AddressDetailBuildingType.House || AddressDetailBuildingType.Townhouse:
              return <House details={details} setDetails={setDetail} errors={errors} />;
            case AddressDetailBuildingType.Apartment || AddressDetailBuildingType.Studio:
              return <Apartment details={details} setDetails={setDetail} errors={errors} />;
            case AddressDetailBuildingType.Dorm:
              return <Dorm details={details} setDetails={setDetail} errors={errors} />;
            case AddressDetailBuildingType.Warehouse:
              return (
                <Warehouse
                  address={address}
                  setAddress={setAddress}
                  details={details}
                  setDetails={setDetail}
                  errors={errors}
                />
              );
            case AddressDetailBuildingType.Commercial:
              return (
                <Commercial
                  address={address}
                  setAddress={setAddress}
                  details={details}
                  setDetails={setDetail}
                  errors={errors}
                />
              );
            case AddressDetailBuildingType.DonationCenter:
              return (
                <DonationCenter
                  address={address}
                  setAddress={setAddress}
                  details={details}
                  setDetails={setDetail}
                  errors={errors}
                />
              );
            default:
              return <Default details={details} setDetails={setDetail} errors={errors} />;
          }
        })()}
      </Box>
      <ParkingLocationType details={details} setDetails={setDetail} error={errors?.parkingLocation} />
      <ParkingPreciseLocation address={address} details={details} setDetails={setDetail} />
      <ParkingMaxTruckHeight details={details} setDetails={setDetail} />
      <ParkingSpecialCases details={details} setDetails={setDetail} />
      <ParkingAdditionalInstructions
        value={details[AddressDetailField.ParkingInstructions]}
        onChange={(value) =>
          setDetail({
            ...details,
            [AddressDetailField.ParkingInstructions]: value,
          })
        }
      />
    </Box>
  );
};
