import { COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Container } from '@portal/components/shared/layout';
import { Logo } from '@portal/components/shared/logo';
import { bodyStyle, Heading, TextLink } from '@portal/components/shared/text';
import { activateURL, supportURL } from '@portal/config/routes';

import { LoginForm } from './form';

const LogoContainer = styled.div`
  text-align: center;
  margin-bottom: 48px;
  @media (max-width: 600px) {
    text-align: left;
    position: fixed;
    top: 24px;
    left: 24px;
  }
`;

const Header = styled.div`
  color: ${COLORS.panther};
  margin-bottom: 32px;
  text-align: center;
  @media (max-width: 600px) {
    margin-bottom: 24px;
    text-align: left;
  }
`;

const Title = styled(Heading)`
  margin-bottom: 24px;
  @media (max-width: 600px) {
    margin-bottom: 16px;
  }
`;

const Text = styled.p`
  ${bodyStyle}
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 470px;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActivateContainer = styled.div`
  ${bodyStyle}
  margin-top: 48px;
  text-align: center;
`;

export const Login: React.FC<{
  redirectURL?: string;
}> = ({ redirectURL }) => {
  const title = redirectURL ? 'Log in to continue' : 'Welcome back';

  return (
    <Container hasNavbar={false}>
      <FormContainer>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Header>
          <Title level={1} size="large">
            {title}
          </Title>
          {redirectURL === supportURL() && <Text>Let’s find your account so we can help.</Text>}
        </Header>
        <LoginForm />
        <LinkContainer>
          <TextLink href="/password/new">Forgot password?</TextLink>
          <TextLink href="https://www.clutter.com">Get started</TextLink>
        </LinkContainer>
        <ActivateContainer>
          Don’t have a password yet?
          <br />
          <TextLink href={activateURL()}>Activate your Clutter account</TextLink>
        </ActivateContainer>
      </FormContainer>
    </Container>
  );
};
