import * as React from 'react';

import {
  AddressDetailField,
  COMMERCIAL_BUILDING_SUBTYPE_OPTIONS,
  IAddress,
  IAddressDetail,
  IAddressValidationErrors,
} from '@shared/types';
import { Access } from './access';
import { AdditionalNotes } from './additional_notes';
import { BuildingFloor } from './building_floor';
import { BusinessName } from './business_name';
import { Subtype } from './subtype';

import { Restrictions } from './restrictions';
import { SquareFeet } from './square_feet';
import { LongCarry } from './long_carry';

export const Commercial: React.FC<{
  address: IAddress;
  details: IAddressDetail;
  errors?: IAddressValidationErrors;
  setAddress(address: IAddress): void;
  setDetails(details: IAddressDetail): void;
}> = ({ address, setAddress, details, setDetails, errors }) => (
  <>
    <BusinessName
      title={'Name of Business'}
      value={address.businessName}
      error={errors?.businessName}
      onChange={(value) => setAddress({ ...address, businessName: value })}
    />
    <Subtype
      value={details.buildingSubtype}
      label={'What type of commercial building?'}
      options={COMMERCIAL_BUILDING_SUBTYPE_OPTIONS}
      error={errors?.[AddressDetailField.BuildingSubtype]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.BuildingSubtype]: value })}
    />
    <BuildingFloor
      details={details}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.Floor]: value })}
    />
    <SquareFeet
      value={details[AddressDetailField.SquareFeet]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.SquareFeet]: value })}
    />
    <Access details={details} onChange={(values) => setDetails({ ...details, ...values })} />
    <LongCarry
      value={details[AddressDetailField.LongCarry]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.LongCarry]: value })}
    />
    <AdditionalNotes
      value={details[AddressDetailField.AdditionalNotes]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.AdditionalNotes]: value })}
    />
    <Restrictions
      details={details}
      onDetail={setDetails}
      error={errors?.[AddressDetailField.BuildingRestrictionsAnswer]}
      initialBuildingRestrictionsAnswer={details[AddressDetailField.BuildingRestrictionsAnswer]}
    />
  </>
);
