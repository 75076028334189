/* eslint-disable no-underscore-dangle */
import React from 'react';

import { COLORS, UnstyledButton } from '@clutter/clean';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { smallCapsStyle } from '@portal/components/shared/text';

const Tabs = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.grayBorder};
  display: flex;
  justify-content: space-between;
`;

const STYLE = `
flex: 1;
padding: 16px 0;
text-align: center;
color: ${COLORS.hippo};
${smallCapsStyle};

&.active {
  color: ${COLORS.tealPrimary};
}

&:hover {
  text-decoration: none;
  color: ${COLORS.__primaryButtonHover};
}
`;

const TabLink = styled(NavLink)(STYLE);
const TabInner = styled(UnstyledButton)(STYLE);

const Tab: React.FC<React.HTMLAttributes<HTMLButtonElement> & { active: boolean }> = ({
  active,
  className,
  ...props
}) => {
  const resolvedClassName = active ? `active ${className ?? ''}` : className;
  return <TabInner className={resolvedClassName} {...props} />;
};

const Combined = Object.assign(Tabs, { TabLink, Tab });

export { Combined as Tabs };
