import React from 'react';

const ID = 'mover';

export const Mover: React.FC<{ title?: string }> = ({ title = 'The Mover' }) => (
  <svg width="70" height="54" viewBox="0 0 70 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title id={ID}>{title}</title>
    <path
      d="M57.7778 15.8059C53.7493 6.66175 45.1389 1 33.9444 1C20.5615 1 8.30554 10.7503 8.30554 26.2781M58.5 36.0281C54.959 46.1785 45.3022 53 33.9444 53C26.2441 53 19.2052 50.375 14.4444 45.0559"
      stroke="#1B9089"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M26.3671 16.0279L24.773 16.3381V15.3731L29.0382 14.5459V15.511L27.4441 15.8211V20.2931L26.3757 20.4999V16.0279H26.3671Z"
      fill="black"
    />
    <path
      d="M34.2856 13.5205V18.9489L33.2172 19.1643V16.8379L30.8735 17.2945V19.621L29.8137 19.8278V14.3994L30.8735 14.1926V16.3381L33.2258 15.8814V13.7273L34.2856 13.5205Z"
      fill="black"
    />
    <path
      d="M35.5092 13.2881L39.266 12.5557V13.5121L36.5691 14.0377V15.244L39.0248 14.7701V15.7179L36.5691 16.1918V17.5705L39.266 17.0449V17.9927L35.5006 18.7251V13.2881H35.5092Z"
      fill="black"
    />
    <path
      d="M0 30.2694L3.02571 29.6785L7.17727 35.9188L7.25936 35.907L11.4109 28.0475L14.4014 27.4565V41.5209L11.7745 42.0409V32.3377L11.6924 32.3495L7.30627 40.5635L7.1069 40.599L2.70907 34.0396L2.62697 34.0514V43.8137L0 44.3337V30.2694Z"
      fill="#01252D"
    />
    <path
      d="M16.7469 34.0157C16.7469 29.9737 18.9048 26.3808 23.3496 25.5062C27.7709 24.6435 29.9522 27.3854 29.9522 31.4274C29.9522 35.4458 27.7826 39.0624 23.3496 39.9369C18.9048 40.8115 16.7469 38.0341 16.7469 34.0157ZM27.1493 31.9829C27.1493 29.1464 25.9296 27.5154 23.3378 28.0236C20.7226 28.5318 19.5264 30.6356 19.5264 33.4721C19.5264 36.3086 20.746 37.9396 23.3378 37.4314C25.9296 36.9232 27.1493 34.8076 27.1493 31.9829Z"
      fill="#01252D"
    />
    <path
      d="M30.6441 24.4665V24.2656L33.7167 23.6628L37.1998 33.6851L37.2819 33.6733L40.7298 22.2918L43.8376 21.6772V21.8782L38.8534 36.7107L35.6635 37.3371L30.6441 24.4665Z"
      fill="#01252D"
    />
    <path
      d="M45.7375 21.3109L55.401 19.4199V21.89L48.4583 23.2492V26.3575L54.7794 25.1166V27.563L48.4583 28.804V32.3851L55.401 31.0259V33.4724L45.7375 35.3752V21.3109Z"
      fill="#01252D"
    />
    <path
      d="M69.3334 30.5412V30.7421L66.1786 31.3567L62.977 26.8538L60.6315 27.3147V32.4559L57.899 32.9877V18.9234L64.0208 17.7297C66.9761 17.1506 68.6883 18.8288 68.6883 21.2162C68.6883 23.2609 67.5977 25.01 65.8737 25.9674L69.3334 30.5412ZM60.6315 24.8564L63.88 24.2182C65.2756 23.9463 65.8737 22.9063 65.8737 21.7835C65.8737 20.7198 65.2522 19.9516 63.88 20.2234L60.6315 20.8616V24.8564Z"
      fill="#01252D"
    />
  </svg>
);
