import React from 'react';
import { ReviewWizard } from '@clutter/clean';
import { Rating, usePageReviewMutation } from '@portal/schema';

export const PageReview: React.FC = () => {
  const [submit] = usePageReviewMutation();
  return (
    <ReviewWizard
      question="Was this information helpful?"
      prompts={{
        GOOD: { answer: 'Yes' },
        POOR: {
          answer: 'No',
          followup: {
            title: 'What could improve?',
            tags: ['Difficult to understand', 'Not what I was looking for'],
          },
        },
      }}
      onSubmit={({ rating, comments, reasons }) => {
        const input = {
          url: location.href,
          rating: rating as Rating,
          comments,
          reasons: JSON.stringify(reasons ?? {}),
        };
        submit({ variables: { input } });
      }}
    />
  );
};
