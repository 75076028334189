import * as React from 'react';

import { Textarea } from '@clutter/clean';
import { FieldFormGroup } from './field_form_group';

export const TextareaFormGroup: React.FC<
  React.InputHTMLAttributes<HTMLTextAreaElement> & {
    label: string;
    error?: string;
  }
> = ({ label, error, id, ...props }) => (
  <FieldFormGroup label={label} error={error} id={id}>
    <Textarea {...props} state={error ? 'error' : undefined} id={id} />
  </FieldFormGroup>
);
