import { COLORS, SANS_SERIF_FONT_FAMILY } from '@clutter/clean';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { payURL } from '@portal/config/routes';
import { STRIPE_API_KEY } from '@portal/config/stripe';
import { Billing__BuildSourceInput, Status, useBuildSourceMutation, useDelinquentBalanceQuery } from '@portal/schema';
import { useAdapter, useElement, useTokenize } from '@shared/components/stripe';

const STRIPE_ELEMENT_OPTIONS = {
  fonts: [
    {
      family: 'Basier Square',
      src: 'url(https://fonts.clutter.com/basiersquare-regular.woff)',
      style: 'normal',
    },
  ],
};

const STRIPE_CARD_ELEMENT_STYLE = {
  base: {
    fontSize: '16px',
    fontFamily: SANS_SERIF_FONT_FAMILY,
    lineHeight: '26px',
    padding: '0px',
    color: COLORS.storm,
    '::placeholder': {
      color: COLORS.hippo,
    },
  },
};

interface IFormProps extends Omit<Billing__BuildSourceInput, 'token'> {
  canAddPrepaid?: boolean;
  onSave(sourceID?: string, token?: IStripeToken): void;
  children(props: {
    fields: {
      element: IStripeElement;
      name?: string;
      onName(name: string | undefined): void;
    };
    error?: string | JSX.Element;
    saving: boolean;
    saveable: boolean;
    save(): void;
  }): React.ReactElement;
}

const prepaidErrorMessage = (delinquent: boolean, canAddPrepaid: boolean) => {
  if (delinquent && canAddPrepaid) {
    return null;
  }
  return (
    <>
      Prepaid cards can only be used to pay off a past due balance and cannot be added as a payment method for your
      account.{' '}
      {delinquent ? (
        <>
          <Link to={payURL()}>Click here to pay off your past due balance</Link> using a prepaid card.
        </>
      ) : (
        <>Please add a debit or credit card instead.</>
      )}
    </>
  );
};

export const SourceForm: React.FC<IFormProps> = ({
  canAddPrepaid = false,
  children,
  onSave,
  signedAccountID,
  signedOrderID,
  makeDefault,
}) => {
  const adapter = useAdapter(STRIPE_API_KEY, STRIPE_ELEMENT_OPTIONS);
  const element = useElement(adapter, 'card', {
    style: STRIPE_CARD_ELEMENT_STYLE,
  });

  const { data: balanceData } = useDelinquentBalanceQuery();

  const { error, tokenize, tokenizing } = useTokenize(adapter, element, { allow: 'prepaid' });

  const [name, setName] = useState<string | undefined>(undefined);
  const [prepaidErrors, setPrepaidErrors] = useState<JSX.Element | null>();
  const [save, { loading, data }] = useBuildSourceMutation();

  const saveable = !!name;

  return children({
    fields: {
      element,
      name,
      onName: setName,
    },
    error: data?.buildSource?.error || prepaidErrors || error,
    saving: tokenizing || loading,
    save: async () => {
      if (tokenizing || loading) {
        return;
      }
      setPrepaidErrors(null);
      const token = await tokenize({ name });
      if (!token) {
        return;
      }
      if (token.card?.funding === 'prepaid') {
        const delinquent = !!balanceData?.account.delinquentBalance;
        const errorMessage = prepaidErrorMessage(delinquent, canAddPrepaid);
        if (errorMessage) {
          setPrepaidErrors(errorMessage);
          return;
        }
      }
      const input = {
        token: token.id,
        signedAccountID,
        signedOrderID,
        makeDefault,
      };
      const result = await save({ variables: { input } });
      if (result?.data?.buildSource?.status === Status.Ok) {
        onSave(result.data.buildSource.source?.id, token);
      }
    },
    saveable,
  });
};
