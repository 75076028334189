import * as React from 'react';

import { ADDRESS_DETAIL_BUILDING_TYPE_OPTIONS, AddressDetailField, IAddressDetail } from '@shared/types';

import { SelectFormGroup } from './select_form_group';

export const BuildingType: React.FC<{
  details: IAddressDetail;
  error?: string;
  disabled: boolean;
  onChange(value: string): void;
}> = ({ disabled, details, onChange, error }) => (
  <SelectFormGroup
    label="Building Type"
    error={!details[AddressDetailField.BuildingType] ? error : undefined}
    disabled={disabled}
    onChange={onChange}
    value={details[AddressDetailField.BuildingType] ?? ''}
    options={ADDRESS_DETAIL_BUILDING_TYPE_OPTIONS.map((option) => ({ label: option, value: option }))}
  />
);
