/* @jsx jsx */
import React from 'react';

import { jsx } from '@emotion/react';

import styled from '@emotion/styled';

import { COLORS, FontWeight, mq, Text, transparentize } from '@clutter/clean';

const RewardsCard = styled.div`
  height: 171px;
  border-radius: 4px 4px 0px 0px;
  margin: 16px auto;
  background: ${transparentize(COLORS.tealBackground, 0.6)};
  ${mq({
    width: ['327px', null, '482px'],
  })}
`;

const RewardsCardBackground = styled.div`
  position: absolute;
  background: white;
  border-radius: 4px 4px 0px 0px;
  z-index: -1;
  height: 171px;
  ${mq({
    width: ['327px', null, '482px'],
  })}
`;

const RewardsCardHeader = styled(Text.Callout)`
  color: ${COLORS.tealPrimary};
  padding-top: 26px;
`;

const RewardsCardTitle = styled(Text.Title)`
  font-weight: ${FontWeight.Semibold};
  line-height: 34px;
  margin-top: 6px;
`;

const RewardsCardBody = styled(Text.Callout)`
  ${mq({
    margin: ['4px 34px', null, '4px 50px'],
  })}
`;

export const InfoCard: React.FC<{
  header: string;
  title: string;
  body: string;
}> = ({ header, title, body }) => (
  <RewardsCard>
    <RewardsCardBackground />
    <RewardsCardHeader weight={FontWeight.Medium}>{header}</RewardsCardHeader>
    <RewardsCardTitle size="small">{title}</RewardsCardTitle>
    <RewardsCardBody weight={FontWeight.Regular}>{body}</RewardsCardBody>
  </RewardsCard>
);
