import { TealLink } from '@portal/components/orders/home/fine_print';
import { planAddURL } from '@portal/config/routes';
import React, { useEffect } from 'react';

import styled from '@emotion/styled';
import { COLORS, Text, Box, mq } from '@clutter/clean';
import { SafeSessionStorage } from '@portal/utils';
import { StarsLeft } from '@portal/components/shared/icons/stars_left';
import { StarsRight } from '@portal/components/shared/icons/stars_right';
import { MoverWithCartBoxes } from '@portal/components/shared/icons/mover_with_cart_boxes';

const MoverIcon = styled.div`
  width: 43px;
  margin: auto 28px auto 0;
  ${mq({ display: ['none', 'flex'] })}
`;

export const AddStorageAlert: React.FC<{ orderID: string }> = ({ orderID }) => {
  useEffect(() => {
    // This alert replaces the appointment reserved alert. Hide for the user session to make sure it doesn't surface after this alert is gone.
    SafeSessionStorage.setItem('hideAppointmentReservedAlert', 'true');
  }, []);

  return (
    <Box.Flex
      background={COLORS.dust}
      padding={['16px 8px', '16px 32px']}
      borderRadius="4px"
      margin="36px auto 0"
      justifyContent={['center', 'none']}
    >
      <MoverIcon>
        <MoverWithCartBoxes />
      </MoverIcon>
      <Box.Flex flexDirection="column" textAlign="center" alignItems="center" margin={['0', '0 60px 0 0']}>
        <Box.Flex alignItems="center" margin="0 0 10px 0">
          <Box margin="auto 20px auto 0">
            <StarsLeft />
          </Box>
          <Text.Title size="extraSmall">Need storage? First month free!</Text.Title>
          <Box margin="auto 0 auto 20px">
            <StarsRight />
          </Box>
        </Box.Flex>
        <Text.Callout>
          We’ll store anything you don’t need in your new place. Click{' '}
          <TealLink href={planAddURL(orderID)}>here</TealLink> to see plan sizes and pricing.
        </Text.Callout>
      </Box.Flex>
    </Box.Flex>
  );
};
