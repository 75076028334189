import styled from '@emotion/styled';

import { BREAKPOINTS, mq, toggleStyleValue } from '@clutter/clean';

const PinnedFooterContainer = styled.div<{
  show: boolean;
  hideMobile?: boolean;
}>`
  background-color: white;
  position: fixed;
  bottom: 0%;
  left: 0;
  width: 100%;
  padding: 16px 24px;
  padding-bottom: calc(16px + env(safe-area-inset-bottom));

  visibility: visible;

  z-index: 1;
  box-shadow: 0 0 16px -2px rgba(0, 0, 0, 0.1);

  form {
    float: right;
  }

  b {
    margin: 22px 0;
    font-size: 16px;

    ${mq({
      fontSize: ['16px', null, '24px'],
    })}
  }

  @media (min-width: ${({ hideMobile }) => (hideMobile ? '0px' : BREAKPOINTS.SM)}) {
    bottom: ${toggleStyleValue('show', '-86px', '0px')};
    opacity: ${toggleStyleValue('show', '0', '1')};
    transition: bottom 1s, opacity 1s;
  }
`;

export { PinnedFooterContainer };
