import { Box, mq, Text, COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import CargoVan from '@portal/images/cargo_van.svg';
import Clock from '@portal/images/clock.svg';
import MoverWaving from '@portal/images/mover_waving.svg';
import { Spinner } from '@portal/components/helpers/spinner';
import { Spacer } from '@shared/components/helpers';
import { Stepper } from './stepper';

const MINIMUM_SELECTABLE_MOVER_COUNT = 1;
const MAXIMUM_SELECTABLE_MOVER_COUNT = 6;

const ValuePropContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Illustration = styled.img`
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
`;

const ValuePropDiv = styled(Box.FlexItem)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  width: 33%;

  ${mq({
    background: [`${COLORS.grayBackground}`, `${COLORS.cloud}`],
  })}
`;

export const MobileOnly = styled.span(mq({ display: ['initial', 'none'] }));
export const DesktopOnly = styled.span(mq({ display: ['none', 'inline'] }));

export const RateMoversAndTrucks: React.FC<{
  estimationsUpdateLoading: boolean;
  laborRateAmount: number;
  moverCount: number;
  quoteLoading: boolean;
  truckCount: number | undefined | null;
  onMoverCountChange: (newMoverCount: number) => void;
}> = ({ estimationsUpdateLoading, laborRateAmount, moverCount, quoteLoading, truckCount, onMoverCountChange }) => (
  <>
    <MobileOnly>
      <MobileRateMoversAndTrucks
        estimationsUpdateLoading={estimationsUpdateLoading}
        laborRateAmount={laborRateAmount}
        moverCount={moverCount}
        quoteLoading={quoteLoading}
        truckCount={truckCount}
        onMoverCountChange={onMoverCountChange}
      />
    </MobileOnly>
    <DesktopOnly>
      <DesktopRateMoversAndTrucks
        estimationsUpdateLoading={estimationsUpdateLoading}
        laborRateAmount={laborRateAmount}
        moverCount={moverCount}
        quoteLoading={quoteLoading}
        truckCount={truckCount}
        onMoverCountChange={onMoverCountChange}
      />
    </DesktopOnly>
  </>
);

export const DesktopRateMoversAndTrucks: React.FC<{
  estimationsUpdateLoading: boolean;
  laborRateAmount: number;
  moverCount: number;
  quoteLoading: boolean;
  truckCount: number | undefined | null;
  onMoverCountChange: (newMoverCount: number) => void;
}> = ({ estimationsUpdateLoading, laborRateAmount, moverCount, quoteLoading, truckCount, onMoverCountChange }) => (
  <ValuePropContainer>
    <ValuePropDiv>
      <Illustration src={Clock} />
      <Text.Body>Hourly Rate</Text.Body>
      {quoteLoading ? <Spinner /> : <Text.Title size="small">${laborRateAmount}</Text.Title>}
    </ValuePropDiv>
    <ValuePropDiv>
      <Illustration src={MoverWaving} />
      <Text.Body>Professional Movers</Text.Body>
      <Spacer height="0.5rem" />
      <Text.Title size="small">
        <Stepper
          min={MINIMUM_SELECTABLE_MOVER_COUNT}
          max={MAXIMUM_SELECTABLE_MOVER_COUNT}
          value={moverCount}
          disabled={quoteLoading || estimationsUpdateLoading}
          onChange={onMoverCountChange}
          isMobile={false}
        />
      </Text.Title>
    </ValuePropDiv>
    <ValuePropDiv>
      <Illustration src={CargoVan} />
      <Text.Body>Trucks</Text.Body>
      {quoteLoading || !truckCount ? <Spinner /> : <Text.Title size="small">{truckCount}</Text.Title>}
    </ValuePropDiv>
  </ValuePropContainer>
);

export const MobileRateMoversAndTrucks: React.FC<{
  estimationsUpdateLoading: boolean;
  laborRateAmount: number;
  moverCount: number;
  quoteLoading: boolean;
  truckCount: number | undefined | null;
  onMoverCountChange: (newMoverCount: number) => void;
}> = ({ estimationsUpdateLoading, laborRateAmount, moverCount, quoteLoading, truckCount, onMoverCountChange }) => (
  <ValuePropContainer>
    <ValuePropDiv>
      <Illustration src={Clock} />
      <Text.Callout>Hourly Rate</Text.Callout>
      {quoteLoading ? <Spinner /> : <Text.Title size="extraSmall">${laborRateAmount}</Text.Title>}
    </ValuePropDiv>
    <Spacer width="0.2rem" />
    <ValuePropDiv>
      <Illustration src={MoverWaving} />
      <Text.Callout>Pro Movers</Text.Callout>
      <Text.Title size="extraSmall">
        <Stepper
          min={MINIMUM_SELECTABLE_MOVER_COUNT}
          max={MAXIMUM_SELECTABLE_MOVER_COUNT}
          value={moverCount}
          disabled={quoteLoading || estimationsUpdateLoading}
          onChange={onMoverCountChange}
          isMobile={true}
        />
      </Text.Title>
    </ValuePropDiv>
    <Spacer width="0.2rem" />
    <ValuePropDiv>
      <Illustration src={CargoVan} />
      <Text.Callout>Trucks</Text.Callout>
      {quoteLoading || !truckCount ? <Spinner /> : <Text.Title size="extraSmall">{truckCount}</Text.Title>}
    </ValuePropDiv>
  </ValuePropContainer>
);
