import React, { useState } from 'react';

import styled from '@emotion/styled';
import * as qs from 'qs';

import { Box, COLORS, FontWeight, Text } from '@clutter/clean';
import { Container as BaseContainer } from '@portal/components/shared/layout';
import { Logo } from '@portal/components/shared/logo';
import { CUSTOMER_POST_BOOKING_SIGN_IN } from '@portal/config/customer';

import CheckCircle from '@portal/images/check_circle.svg';
import ManOnCouchUsingPhone from '@portal/images/man_on_couch_using_phone.png';
import StackOfClutterBoxes from '@portal/images/stack_of_clutter_boxes.png';
import ClutterMoversWorkingAndSmiling from '@portal/images/clutter_movers_working_and_smiling.png';
import ClutterMoversWorkingAndSmilingMobile from '@portal/images/clutter_movers_working_and_smiling_mobile.png';

import { useBreakpointFlags } from '@portal/utils';
import { PasswordCreateForm } from './form';

const Container = styled(BaseContainer)`
  min-height: 0;
  @media (max-width: 700px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0 16px;
    display: block;
    width: 100%;
    overflow-y: scroll;
  }
`;

const MovingPostBookingContainer = styled(BaseContainer)`
  min-height: 0;
  @media (max-width: 700px) {
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0 16px;
    display: block;
    width: 100%;
    overflow-y: scroll;
  }
`;

const LogoContainer = styled.div<{ isMoving: boolean }>`
  margin-top: 24px;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: ${(props) => (props.isMoving ? '24px' : '48px')};
  width: 100%;
  text-align: ${(props) => (props.isMoving ? 'center' : 'left')};
  @media (max-width: 700px) {
    margin-bottom: 24px;
    margin-left: 0;
    text-align: left;
  }
`;

const Content = styled.div<{ isMoving: boolean }>`
  width: 100%;
  max-width: ${(props) => (props.isMoving ? '580px' : '700px')};
`;

const Banner = styled.div`
  background: #ffeabf;
  text-align: left;
  padding: 14px;
  display: flex;
  justify-content: center;
  gap: 12px;
`;

const CheckCircleImageContainer = styled.img`
  width: 22px;
  height 22px;
`;

const CardImageContainer = styled.img<{ width: string }>`
  border-radius: 11px 11px 0px 0px;
  width: ${(props) => props.width};
`;

const CardContainer = styled.div`
  border: 1px solid #ffeabf;
  border-radius: 12px;
  max-width: min-content;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 18px;
  margin: 0 -8px;
`;

const Cards: React.FC<{ isMobile: boolean }> = ({ isMobile }) => (
  <CardsContainer>
    <CardContainer>
      <CardImageContainer
        src={ManOnCouchUsingPhone}
        alt="Man on couch using phone"
        width={isMobile ? '360px' : '280px'}
      />
      <Box padding="12px">
        <Text.Callout weight={FontWeight.Medium}>Start Your Virtual Walkthrough</Text.Callout>
        <Text.Caption>Get the right team built just for your needs.</Text.Caption>
      </Box>
    </CardContainer>
    <CardContainer>
      <CardImageContainer src={StackOfClutterBoxes} alt="Stack of Clutter boxes" width={isMobile ? '360px' : '280px'} />
      <Box padding="12px">
        <Text.Callout weight={FontWeight.Medium}>Your One Stop Shop</Text.Callout>
        <Text.Caption>Manage all your items, billing, and pricing.</Text.Caption>
      </Box>
    </CardContainer>
    <CardContainer>
      <CardImageContainer
        src={isMobile ? ClutterMoversWorkingAndSmilingMobile : ClutterMoversWorkingAndSmiling}
        alt="Clutter movers working and smiling"
        width={isMobile ? '360px' : '580px'}
      />
      <Box padding="12px">
        <Text.Callout weight={FontWeight.Medium}>Manage Clutter services</Text.Callout>
        <Text.Caption>Reschedule or cancel your appointment in a few clicks.</Text.Caption>
      </Box>
    </CardContainer>
  </CardsContainer>
);

export const Create: React.FC<{ token: string; orderScheduledDateDescription?: string }> = ({
  token,
  orderScheduledDateDescription,
}) => {
  const [link, setLink] = useState<string | undefined>(undefined);

  if (link) {
    window.location.href = link;
  }

  const postBookingServiceType = qs.parse(window.location.search, { ignoreQueryPrefix: true }).service;
  const { isMobile } = useBreakpointFlags();

  if (CUSTOMER_POST_BOOKING_SIGN_IN && (postBookingServiceType === 'moving' || postBookingServiceType === 'storage')) {
    return (
      <>
        {orderScheduledDateDescription && (
          <Banner>
            <CheckCircleImageContainer src={CheckCircle}></CheckCircleImageContainer>
            <Text.Callout color={COLORS.panther}>
              Your appointment is booked for <b>{orderScheduledDateDescription}</b>
            </Text.Callout>
          </Banner>
        )}
        <MovingPostBookingContainer>
          <LogoContainer isMoving={true}>
            <Logo />
          </LogoContainer>
          <Text.Title size="small">Create a password for your online account:</Text.Title>
          <Content isMoving={true}>
            <PasswordCreateForm token={token} onCreate={setLink} />
            <Box margin="24px auto">
              <Box textAlign="center" margin={'0 0 24px'}>
                <Text.Title size="small">Your Clutter Portal</Text.Title>
              </Box>
              <Cards isMobile={isMobile} />
            </Box>
          </Content>
        </MovingPostBookingContainer>
      </>
    );
  }

  return (
    <Container>
      <LogoContainer isMoving={false}>
        <Logo />
      </LogoContainer>
      <Text.Title size="large">Set a password for your online account:</Text.Title>
      <Content isMoving={false}>
        <PasswordCreateForm token={token} onCreate={setLink} />
      </Content>
    </Container>
  );
};
Create.displayName = 'CreatePassword';
