import * as React from 'react';

import { ItemFragment } from '@portal/schema';
import { Button, Text } from '@shared/components/bootstrap';

export const Actions: React.FC<{
  items?: ItemFragment[];
  selectedIDs: Set<string>;
  onChange(items: ItemFragment[]): void;
  customerItemCount?: number;
}> = ({ items, selectedIDs, onChange, customerItemCount }) => {
  const everything = items && items.every(({ id }) => selectedIDs.has(id));

  const onAll = () => {
    if (!items) {
      return;
    }
    onChange(Array.from(items));
  };

  const onNone = () => {
    if (!items) {
      return;
    }
    onChange([]);
  };

  return (
    <Text alignment="right" tag="div">
      <Button kind="link" onClick={everything ? onNone : onAll} disabled={!items}>
        {everything ? 'Deselect All' : 'Select All'} ({customerItemCount || items?.length || '-'})
      </Button>
    </Text>
  );
};
