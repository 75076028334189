import { Button, Text, TextButton } from '@clutter/clean';
import React, { useState } from 'react';

import { Maybe, SourceFragment, Status, useDestroySourceMutation } from '@portal/schema';
import { Modal } from '@shared/components/bootstrap';

import { BaseModal } from './base_modal';

export const DestroySourceDialog: React.FC<{
  source: SourceFragment;
  onClose(): void;
  onDestroy(): void;
}> = ({ source, onClose, onDestroy }) => {
  const [error, setError] = useState<Maybe<string>>();

  const [execute, { loading: saving }] = useDestroySourceMutation();

  const save = async () => {
    const result = await execute({ variables: { id: source.id } });
    if (result?.data?.destroySource?.status === Status.Ok) {
      onDestroy();
    } else {
      setError(result?.data?.destroySource?.error);
    }
  };

  return (
    <Modal onClose={onClose}>
      <BaseModal.Content>
        <BaseModal.Header>
          <BaseModal.Close onClose={onClose} />
          <BaseModal.Title size="large">Remove payment method</BaseModal.Title>
        </BaseModal.Header>
        <BaseModal.Body>
          <Text.Body>
            Are you sure you want to remove the payment method <strong>****{source.number}?</strong>
          </Text.Body>
          {error && <BaseModal.Error>{error}</BaseModal.Error>}
        </BaseModal.Body>
        <BaseModal.Buttons>
          <Button fullWidth kind="destructive" loading={saving} disabled={saving} onClick={save}>
            Remove
          </Button>
          <TextButton size="medium" onClick={onClose}>
            Cancel
          </TextButton>
        </BaseModal.Buttons>
      </BaseModal.Content>
    </Modal>
  );
};
