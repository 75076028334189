import { COLORS, mq, Text } from '@clutter/clean';
import styled from '@emotion/styled';

export const ProgressContainer = styled.div`
  margin-top: -24px;
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  ${mq({
    marginBottom: ['24px', null, '54px'],
  })}
`;

export const ItemContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-around;
`;

export const Item = styled(Text.SmallCaps)<{ highlighted: boolean; clickable: boolean }>`
  width: 164px;
  text-align: center;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  color: ${({ highlighted }) => (highlighted ? COLORS.tealBrand : '#969899')};
`;
