import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Box } from '@clutter/clean';

import {
  useInventoryQuery,
  ItemStatus,
  Account__State,
  useInventoryContentQuery,
  useUsageWithDownsizeQuery,
} from '@portal/schema';

import { ItemStatusSlug } from '@portal/config/routes';
import { NAV_BAR_HEIGHT } from '@portal/components/shared/layout';

import { useDebounce } from '@shared/hooks';
import { useLastDefined } from '@shared/hooks/state';
import { BookNewAppointment } from '@portal/components/new_appointment';
import { Notices } from '@portal/components/app/notices';
import styled from '@emotion/styled';
import qs from 'qs';
import { Spacer } from '@shared/components/helpers';
import { IMPERSONATOR } from '@portal/config/impersonator';
import { Banner } from './banner';
import { Grid } from './grid';
import { Filters } from './filters';
import { Actions } from './actions';
import { Detail } from '../detail/detail';
import { ItemViewContextProvider, useItemViewContext } from '../item_view_context';
import { NeedSignatureAlert } from './need_signature_alert';
import { MakeSpaceBanner } from './makespace_banner';

const STATUSES: Record<ItemStatusSlug, ItemStatus> = {
  available: ItemStatus.Available,
  requested: ItemStatus.Requested,
  returned: ItemStatus.Returned,
  delayed: ItemStatus.Delayed,
};

export const PAGE_SIZE = 48;

const FullExpandContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  width: 100%;
  top: ${NAV_BAR_HEIGHT}px;
  height: calc(100% - ${NAV_BAR_HEIGHT}px);
  z-index: 0;
`;

const Content: React.FC = ({ children }) => {
  const { selectedItem } = useItemViewContext();
  return (
    <Box
      width="100%"
      display={[selectedItem ? 'none' : 'block', null, null, 'block']}
      maxWidth={['720px', null, null, '1376px']}
      margin="0 auto 8px"
      padding={['24px 24px 0', null, null, selectedItem ? '24px 116px 0' : null]}
    >
      {children}
    </Box>
  );
};

const Skeleton = () => (
  <ItemViewContextProvider>
    <FullExpandContainer>
      <Content>
        {IMPERSONATOR && <Spacer height="36px" />}
        <Banner.Loader />
        <Actions.Loader />
        <Filters.Loader />
        <Grid.Loader />
      </Content>
    </FullExpandContainer>
  </ItemViewContextProvider>
);

export const List: React.FC = () => {
  const searchParams = new URLSearchParams(location.search);
  const [query, setQuery] = useState(() => searchParams.get('query') || '');

  const { status, page } = useParams<{
    status: ItemStatusSlug;
    page?: string;
  }>();

  const debouncedQuery = useDebounce(query);
  const { data } = useInventoryQuery();
  useUsageWithDownsizeQuery(); // Pre-load usage bar while inventory loads

  const variables = (newPage = 1) => ({
    filters: {
      query: debouncedQuery ?? undefined,
      status: STATUSES[status],
    },
    query: debouncedQuery ?? undefined,
    page: newPage,
    per: PAGE_SIZE,
  });
  const { data: customerItemData, fetchMore } = useInventoryContentQuery({
    variables: variables(page ? Number(page) : undefined),
  });

  // Show the last query result while new data is being fetched to prevent flickering
  const cachedCustomerItemData = useLastDefined(customerItemData);

  if (!data || !cachedCustomerItemData) return <Skeleton />;

  const needSignature = data?.needSignature;

  const account = data?.account;

  const pending = account?.onboardable && account.state === Account__State.Pending;
  const canceled = account?.onboardable && account.state === Account__State.Canceled;
  const rescheduleOffer = account.rescheduleOfferDetails;

  const { makespace: makespaceParam } = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};
  const showMakeSpaceBanner = !!makespaceParam;

  if (account && pending) {
    // A storage customer in a reonboardable state may have documents that need signing.
    // However, the NeedSignatureAlert component is here primarily to cover moving-only customers
    return (
      <>
        {needSignature && <NeedSignatureAlert needSignature={needSignature} />}
        <BookNewAppointment requestableItemsExist={account.requestableItemsExist} />
      </>
    );
  }

  return (
    <ItemViewContextProvider>
      <FullExpandContainer>
        <Content>
          {IMPERSONATOR && <Spacer height="36px" />}
          <Notices />
          {showMakeSpaceBanner && <MakeSpaceBanner />}
          {needSignature && <NeedSignatureAlert needSignature={needSignature} />}
          <Banner token={rescheduleOffer?.token} rescheduleOfferCoupon={rescheduleOffer?.coupon} />
          {(!canceled || account.requestableItemsExist) && <Actions />}
          <Filters
            initialQuery={query}
            onQueryChange={setQuery}
            orders={data?.orders}
            inventory={cachedCustomerItemData}
          />
          <Grid
            pageSize={PAGE_SIZE}
            paginationData={cachedCustomerItemData.paginatedCustomerItems}
            fetchMore={(nextPage) => fetchMore({ variables: variables(nextPage) })}
          />
        </Content>
        <Detail />
      </FullExpandContainer>
    </ItemViewContextProvider>
  );
};
