export enum InvoiceStatus {
  Failed = 'FAILED',
  Paid = 'PAID',
}

export interface IInvoice {
  id: string;
  date: string;
  lines: string[];
  amountDue: number;
  total: number;
  payable: boolean;
  status: string;
  summary: string;
}
