import { ConfirmError } from '@portal/components/orders/steps/confirm/confirm_error';
import { DateTime, Interval } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  Account__CancelIntent as AccountCancelIntent,
  Account__TermCommitment as TermCommitment,
  Order__ContactInput,
  InventoryItemFragment,
  Maybe,
  OrderServiceTypeEnum,
  OrderSubtypeEnum,
  OrderTypeEnum,
  RetentionOfferFragment,
  useOrdersAccountQuery,
  Shipping__EasyPostInboundShipment,
  Shipping__EasyPostOutboundShipment,
  AddressWithDetailsFragment,
  FacilityFragment,
  usePricingPolicyNoticeInfoQuery,
  Order__PickupVehicleKind,
  Account__State,
} from '@portal/schema';

import { Spinner } from '@portal/components/helpers';
import { InventoryCaptureForm as StepInventoryCapture } from '@portal/components/inventory_capture/form';
import { TermCommitmentAction } from '@portal/components/orders/term_commitment/options';
import { ItemStatusSlug, itemsURL, orderStepURL } from '@portal/config/routes';
import { useSubtype } from '@portal/helpers/subtype';
import { buildOrderInput } from '@portal/utils';
import { ConfigWithQuery as InventoryConfig } from '@shared/components/inventory_capture';
import { IOrderInventory } from '@shared/types';
import { ALL_DAY_SLA_START_HOUR, ALL_DAY_SLA_END_HOUR_24 } from '@shared/utils/all_day_sla';

import { IMPERSONATOR } from '@portal/config/impersonator';
import { useAccountPerItemPricingData } from './steps/item_pricing_data';
import { OrderContext } from './steps/order_context';
import { Suspended } from './suspended';
import { next } from './workflow';
import { IInboundShipmentInput } from './steps/shipping/shipping';

import { AccountCancelReason as StepAccountCancelReason } from './steps/account_cancel_reason';
import { AddressServiceType as StepAddressServiceType } from './steps/address_service_type';
import { AddressDetails as StepAddressDetails } from './steps/address_details';
import { Confirm as StepConfirm } from './steps/confirm';
import { Contact as StepContact } from './steps/contact';
import { Items as StepItems } from './steps/items';
import { PricingPolicyNotice } from './steps/pricing_policy_notice';
import { LaborRateData, Scheduled as StepScheduled, ScheduledMode } from './steps/scheduled';
import { Waitlist as StepWaitlist } from './steps/waitlist';
import { Actions as StepTermCommitment } from './steps/term_commitment/actions';
import { Balance as StepTermCommitmentBalance } from './steps/term_commitment/balance';
import { Type as StepType } from './steps/type';
import { MissingSignature } from './steps/missing_signature';
import { InboundShippingPrepare as StepInboundShippingPrepare } from './steps/inbound_shipping_prepare';
import { InboundShipmentCount as StepInboundShipmentCount } from './steps/inbound_shipment_count';
import { InboundShipmentItems as StepInboundShipmentItems } from './steps/inbound_shipment_items';
import { OutboundItemInfo as StepOutboundItemInfo } from './steps/outbound_item_info';
import { IItemMetadata } from './steps/shipping/metadata_selector';
import { OutboundShippingOptions as StepOutboundShippingOptions } from './steps/outbound_shipping_options';
import { InboundShippingOptions as StepInboundShippingOptions } from './steps/inbound_shipping_options';
import { IShippingOption } from './steps/shipping/options';
import { FacilityExpectations as StepFacilityExpectations } from './steps/facility_expectations';
import { DisposalExpectations as StepDisposalExpectations } from './steps/disposal_expectations';

const INBOUND_SHIPPING_ITEM_LIMIT = 20;
const DEFAULT_WAITLISTED_DATES: DateTime[] = [];
const DEFAULT_CONFIRMED_DATE = true;

export interface IStepProps {
  orderSubtype?: OrderSubtypeEnum;
  serviceType?: OrderServiceTypeEnum;
}

const Steps: React.FC<{
  termCommitment?: Maybe<TermCommitment>;
  inboundShippingEligible?: boolean;
  isAccountCanceled: boolean;
}> = ({ termCommitment, inboundShippingEligible, isAccountCanceled }) => {
  const [type, setType] = useState<undefined | OrderTypeEnum>(undefined);
  const [address, setAddress] = useState<undefined | AddressWithDetailsFragment>(undefined);
  const [contact, setContact] = useState<undefined | Order__ContactInput>(undefined);
  const [inventory, setInventory] = useState<IOrderInventory>({});
  const [items, setItems] = useState<InventoryItemFragment[]>([]);
  const [accountCancelIntent, setAccountCancelIntent] = useState<AccountCancelIntent>();
  const [retentionOffer, setRetentionOffer] = useState<RetentionOfferFragment>();
  const [date, setDate] = useState<undefined | DateTime>(undefined);
  const [intervalConfig, setIntervalConfig] = useState<{ interval: Interval; forced: boolean } | undefined>(undefined);
  const [serviceType, setServiceType] = React.useState<undefined | OrderServiceTypeEnum>(undefined);
  const [termCommitmentAction, setTermCommitmentAction] = useState<TermCommitmentAction>();
  const [shippingEligible, setShippingEligible] = useState<boolean>();
  const [metadata, setMetadata] = useState<Map<string, IItemMetadata>>(new Map<string, IItemMetadata>());
  const [metadataIndex, setMetadataIndex] = useState<number>(0);
  const [availableShippingOptions, setAvailableShippingOptions] = useState<Shipping__EasyPostInboundShipment[]>([]);
  const [outboundShippingOptions, setOutboundShippingOptions] = useState<Shipping__EasyPostOutboundShipment[]>([]);
  const [shippingOption, setShippingOption] = useState<IShippingOption>();
  const [boxCount, setBoxCount] = useState<number | undefined>(undefined);
  const [inboundShipments, setInboundShipments] = useState<IInboundShipmentInput[]>([]);
  const [facilityWarehouse, setFacilityWarehouse] = useState<FacilityFragment | undefined>(undefined);
  const [extendedServiceArea, setExtendedServiceArea] = useState<boolean>(false);
  const [laborRateData, setLaborRateData] = useState<LaborRateData | undefined>(undefined);
  const [waitlistedDates, setWaitlistedDates] = useState<DateTime[]>(DEFAULT_WAITLISTED_DATES);
  const [confirmedDate, setConfirmedDate] = useState<boolean>(DEFAULT_CONFIRMED_DATE);
  const [pickupVehicleKind, setPickupVehicleKind] = useState<Order__PickupVehicleKind | undefined>(undefined);
  const subtype = useSubtype(items, type);

  const { loading: pricingPolicyLoading, data: pricingPolicyData } = usePricingPolicyNoticeInfoQuery();

  useEffect(() => {
    setDate(undefined);
    setIntervalConfig(undefined);
  }, [subtype]);

  useEffect(() => {
    if (!!serviceType && !(type === OrderTypeEnum.Pickup || type === OrderTypeEnum.Return)) {
      setServiceType(undefined);
    }
  }, [type]);

  useEffect(() => {
    if (date && (serviceType === OrderServiceTypeEnum.Shipment || serviceType === OrderServiceTypeEnum.Disposal)) {
      setIntervalConfig({
        interval: Interval.fromDateTimes(
          date.set({ hour: ALL_DAY_SLA_START_HOUR }),
          date.set({ hour: ALL_DAY_SLA_END_HOUR_24 }),
        ),
        forced: true,
      });
    }
  }, [date, serviceType]);

  useEffect(() => {
    setShippingEligible(type !== OrderTypeEnum.PickupReturn && items.some((item) => item.shippingEligible));
  }, [items, type]);

  const perItemPricingResult = useAccountPerItemPricingData();

  const { loading: perItemPricingLoading, data: perItemPricingData } = perItemPricingResult;

  const skip = {
    type,
    serviceType,
    perItemPricing: perItemPricingData.perItemPricing,
    subtype,
    scheduledDate: date,
    contractEndDate: termCommitment?.contractEndDate ?? undefined,
    intervalConfig,
    shippingEligible,
    inboundShippingEligible,
    waitlistedDates,
    confirmedDate,
  };

  return (
    <OrderContext.Provider
      value={{
        order:
          type &&
          buildOrderInput({
            type,
            addressID: address?.id,
            items,
            inventory,
            serviceType,
            scheduled: date && date.set({ hour: intervalConfig?.interval.start?.hour || ALL_DAY_SLA_START_HOUR }),
            laborRateID: laborRateData?.laborRate.id,
            perMoverHourAdjustmentAmount: laborRateData?.perMoverHourAdjustmentAmount,
            pickupVehicleKind,
          }),
      }}
    >
      <InventoryConfig inventory={inventory} onSelect={setInventory}>
        <Switch>
          <Route
            path={orderStepURL('new')}
            render={({ history }) => (
              <StepType
                type={type}
                onlyAllowReturns={isAccountCanceled}
                onChange={setType}
                onNext={() => history.push(orderStepURL(next('new', skip)))}
                onPrev={() => history.push(itemsURL(ItemStatusSlug.Available))}
                loading={perItemPricingLoading || pricingPolicyLoading}
              />
            )}
          />
          <Route
            path={orderStepURL('return')}
            render={({ history, location }) => {
              if (
                pricingPolicyData?.pricingPolicyNoticeInfo &&
                !perItemPricingData.perItemPricing &&
                localStorage.getItem('hidePricingPolicyNotice') !== 'true'
              ) {
                return (
                  <PricingPolicyNotice
                    pricingPolicy={pricingPolicyData?.pricingPolicyNoticeInfo}
                    onNext={() => history.replace(location.pathname)}
                    onPrev={() => history.goBack()}
                  />
                );
              }
              const preselectedItemIDs = new URLSearchParams(location.search).getAll('item_ids[]');
              if (preselectedItemIDs.length || !type) {
                setType(OrderTypeEnum.Return);
              }
              return (
                <StepItems
                  serviceType={serviceType}
                  selections={items}
                  preselectedItemIDs={preselectedItemIDs}
                  type={type}
                  onChange={setItems}
                  onNext={() => history.push(orderStepURL(next('return', skip)))}
                  onPrev={() => {
                    if (type === OrderTypeEnum.Return) {
                      setItems([]);
                      setInventory({});
                    }
                    history.goBack();
                  }}
                  orderSubtype={subtype}
                />
              );
            }}
          />
          <Route
            path={orderStepURL('pickup')}
            render={({ history, location }) => {
              if (
                pricingPolicyData?.pricingPolicyNoticeInfo &&
                !perItemPricingData.perItemPricing &&
                localStorage.getItem('hidePricingPolicyNotice') !== 'true'
              ) {
                return (
                  <PricingPolicyNotice
                    pricingPolicy={pricingPolicyData?.pricingPolicyNoticeInfo}
                    onNext={() => history.replace(location.pathname)}
                    onPrev={() => history.goBack()}
                  />
                );
              }
              return (
                <StepInventoryCapture
                  serviceType={serviceType}
                  inventory={inventory}
                  onNext={() => history.push(orderStepURL(next('pickup', skip)))}
                  onPrev={() => {
                    setItems([]);
                    setInventory({});
                    history.goBack();
                  }}
                />
              );
            }}
          />
          <Route
            path={orderStepURL('cancel_reason')}
            render={({ history }) => (
              <StepAccountCancelReason
                cancelReason={accountCancelIntent?.reason}
                retentionOffer={retentionOffer}
                setCancelIntent={setAccountCancelIntent}
                setRetentionOffer={setRetentionOffer}
                onNext={() => history.push(orderStepURL(next('cancel_reason', skip)))}
                onPrev={() => history.goBack()}
              />
            )}
          />
          <Route
            path={orderStepURL('address')}
            render={({ history }) => (
              <StepAddressServiceType
                address={address}
                subtype={subtype}
                onSelect={setAddress}
                setAddress={setAddress}
                setServiceType={setServiceType}
                setExtendedServiceArea={setExtendedServiceArea}
                setFacilityWarehouse={setFacilityWarehouse}
                setScheduled={setDate}
                onNext={() => history.push(orderStepURL(next('address', skip)))}
                onPrev={() => {
                  setServiceType(undefined);
                  setAddress(undefined);
                  history.goBack();
                }}
              />
            )}
          />
          <Route
            path={orderStepURL('address_details')}
            render={({ history }) => (
              <StepAddressDetails
                address={address!}
                setAddress={setAddress}
                serviceType={serviceType}
                onNext={() => history.push(orderStepURL(next('address_details', skip)))}
                onPrev={() => history.goBack()}
              />
            )}
          />
          <Route
            path={orderStepURL('inbound_shipping_prepare')}
            render={({ history }) => (
              <StepInboundShippingPrepare
                onNext={() => history.push(orderStepURL(next('inbound_shipping_prepare', skip)))}
                onPrev={() => {
                  setInboundShipments([]);
                  setBoxCount(undefined);
                  history.goBack();
                }}
              />
            )}
          />
          <Route
            path={orderStepURL('inbound_shipment_count')}
            render={({ history }) => (
              <StepInboundShipmentCount
                onNext={() => history.push(orderStepURL(next('inbound_shipment_count', skip)))}
                onPrev={() => history.goBack()}
                inboundShipments={inboundShipments}
                setInboundShipments={setInboundShipments}
                boxCount={boxCount}
                setBoxCount={setBoxCount}
                shippingItemLimit={INBOUND_SHIPPING_ITEM_LIMIT}
              />
            )}
          />
          <Route
            path={orderStepURL('inbound_shipment_items')}
            render={({ history }) => (
              <StepInboundShipmentItems
                onNext={() => history.push(orderStepURL(next('inbound_shipment_items', skip)))}
                onPrev={() => history.goBack()}
                address={address!}
                metadataIndex={metadataIndex}
                setMetadataIndex={setMetadataIndex}
                inboundShipments={inboundShipments}
                setInboundShipments={setInboundShipments}
                availableShippingOptions={availableShippingOptions}
                setAvailableShippingOptions={setAvailableShippingOptions}
              />
            )}
          />
          <Route
            path={orderStepURL('outbound_item_info')}
            render={({ history }) => (
              <StepOutboundItemInfo
                selections={items}
                address={address!}
                metadata={metadata}
                setMetadata={setMetadata}
                metadataIndex={metadataIndex}
                setMetadataIndex={setMetadataIndex}
                outboundShippingOptions={outboundShippingOptions}
                setOutboundShippingOptions={setOutboundShippingOptions}
                onNext={() => history.push(orderStepURL(next('outbound_item_info', skip)))}
                onPrev={() => history.goBack()}
              />
            )}
          />
          <Route
            path={orderStepURL('outbound_shipping_options')}
            render={({ history }) => (
              <StepOutboundShippingOptions
                metadata={metadata}
                setShipping={setShippingOption}
                outboundShippingOptions={outboundShippingOptions}
                setOutboundShippingOptions={setOutboundShippingOptions}
                onNext={() => {
                  setDate(DateTime.local());
                  history.push(orderStepURL(next('outbound_shipping_options', skip)));
                }}
                onPrev={() => {
                  setDate(undefined);
                  setMetadataIndex(items.length - 1);
                  history.goBack();
                }}
              />
            )}
          />
          <Route
            path={orderStepURL('inbound_shipping_options')}
            render={({ history }) => (
              <StepInboundShippingOptions
                onNext={() => {
                  setDate(DateTime.local().plus({ weeks: 4 }));
                  history.push(orderStepURL(next('inbound_shipping_options', skip)));
                }}
                onPrev={() => {
                  setDate(undefined);
                  setMetadataIndex(boxCount! - 1);
                  history.goBack();
                }}
                setShipping={setShippingOption}
                inboundShipments={inboundShipments}
                setInboundShipments={setInboundShipments}
                availableShippingOptions={availableShippingOptions}
                setAvailableShippingOptions={setAvailableShippingOptions}
              />
            )}
          />
          <Route
            path={orderStepURL('disposal_expectations')}
            render={({ history }) => (
              <StepDisposalExpectations
                onNext={() => {
                  history.push(orderStepURL(next('disposal_expectations', skip)));
                }}
                onPrev={() => history.goBack()}
              />
            )}
          />
          <Route
            path={orderStepURL('facility_expectations')}
            render={({ history }) => (
              <StepFacilityExpectations
                setAddress={setAddress}
                onNext={() => {
                  history.push(orderStepURL(next('facility_expectations', skip)));
                }}
                onPrev={() => history.goBack()}
              />
            )}
          />
          <Route
            path={orderStepURL('date')}
            render={({ history }) => (
              <StepScheduled
                subtype={subtype}
                mode={ScheduledMode.Date}
                type={type!}
                addressID={address?.id}
                inventory={inventory}
                items={items}
                date={date}
                facilityWarehouse={facilityWarehouse}
                onDate={setDate}
                laborRateData={laborRateData}
                onLaborRate={setLaborRateData}
                interval={intervalConfig?.interval}
                onInterval={setIntervalConfig}
                onNext={() => history.push(orderStepURL(next('date', skip)))}
                onPrev={() => {
                  setDate(undefined);
                  setWaitlistedDates(DEFAULT_WAITLISTED_DATES);
                  setConfirmedDate(DEFAULT_CONFIRMED_DATE);
                  history.goBack();
                }}
                serviceType={serviceType}
                extendedServiceArea={extendedServiceArea}
                waitlistedDates={waitlistedDates}
                onWaitlistedDates={setWaitlistedDates}
                confirmedDate={confirmedDate}
                setConfirmedDate={setConfirmedDate}
                isAccountCanceled={isAccountCanceled}
              />
            )}
          />
          <Route
            path={orderStepURL('time')}
            render={({ history }) => (
              <StepScheduled
                mode={ScheduledMode.Time}
                type={type!}
                addressID={address?.id}
                inventory={inventory}
                items={items}
                date={date}
                facilityWarehouse={facilityWarehouse}
                onDate={setDate}
                laborRateData={laborRateData}
                onLaborRate={setLaborRateData}
                interval={intervalConfig?.interval}
                onInterval={setIntervalConfig}
                onNext={() => history.push(orderStepURL(next('time', skip)))}
                onPrev={() => history.goBack()}
                serviceType={serviceType}
                extendedServiceArea={extendedServiceArea}
                waitlistedDates={waitlistedDates}
                onWaitlistedDates={setWaitlistedDates}
                confirmedDate={confirmedDate}
                setConfirmedDate={setConfirmedDate}
                isAccountCanceled={isAccountCanceled}
              />
            )}
          />
          <Route
            path={orderStepURL('waitlist')}
            render={({ history }) => (
              <StepWaitlist
                date={date!}
                waitlistedDates={waitlistedDates}
                confirmedDate={confirmedDate}
                onNext={() => history.push(orderStepURL(next('waitlist', skip)))}
                onPrev={() => history.goBack()}
              />
            )}
          />
          <Route
            path={orderStepURL('term_commitment')}
            render={({ history }) => (
              <StepTermCommitment
                termCommitment={termCommitment!}
                action={termCommitmentAction}
                setAction={setTermCommitmentAction}
                onPrev={() => {
                  setTermCommitmentAction(undefined);
                  history.goBack();
                }}
                onNext={() => history.push(orderStepURL(next('term_commitment', skip)))}
                navigateToReturnStep={() => history.push(orderStepURL('return'))}
              />
            )}
          />
          <Route
            path={orderStepURL('term_commitment_balance')}
            render={({ history }) => (
              <StepTermCommitmentBalance
                scheduled={date}
                onPrev={() => history.goBack()}
                onNext={() => history.push(orderStepURL(next('term_commitment_balance', skip)))}
              />
            )}
          />
          <Route
            path={orderStepURL('contact')}
            render={({ history }) => (
              <StepContact
                serviceType={serviceType}
                contact={contact}
                onChange={setContact}
                setVehicleKind={setPickupVehicleKind}
                onNext={() => history.push(orderStepURL(next('contact', skip)))}
                onPrev={() => history.goBack()}
              />
            )}
          />
          <Route
            path={orderStepURL('confirm')}
            render={({ history }) => {
              const scheduled = intervalConfig!.interval.start;
              const duration = intervalConfig!.interval.end.diff(intervalConfig!.interval.start);
              const accountCancelIntentID = accountCancelIntent?.id;
              return (
                <StepConfirm
                  scheduled={scheduled}
                  duration={duration}
                  address={address!}
                  contact={contact}
                  items={items}
                  inventory={inventory}
                  accountCancelIntentID={accountCancelIntentID}
                  subtype={subtype}
                  termCommitmentAction={termCommitmentAction}
                  shippingOption={shippingOption}
                  onPrev={() => history.goBack()}
                  inboundShipments={inboundShipments}
                  facilityWarehouse={facilityWarehouse}
                  extendedServiceArea={extendedServiceArea}
                  laborRateData={laborRateData}
                  confirmedDate={confirmedDate}
                  waitlistedDates={waitlistedDates}
                />
              );
            }}
          />
        </Switch>
      </InventoryConfig>
    </OrderContext.Provider>
  );
};

export const Form: React.FC = () => {
  const { data, loading } = useOrdersAccountQuery();
  const [skipSignatureRequirement, setSkipSignatureRequirement] = useState(false);
  const [skipSuspendedBlock, setSkipSuspendedBlock] = useState(false);
  const [skipBannedBlock, setSkipBannedBlock] = useState(false);

  if (loading || !data) {
    return <Spinner />;
  }

  if (data.account.suspended && !skipSuspendedBlock) {
    return <Suspended overrideError={IMPERSONATOR ? () => setSkipSuspendedBlock(true) : undefined} />;
  }

  if (data.account.bannedFromBooking && !skipBannedBlock) {
    return (
      <ConfirmError
        headline="Your account is disabled and you cannot book appointments"
        detail="If you think this is a mistake, please contact us."
        overrideError={IMPERSONATOR ? () => setSkipBannedBlock(true) : undefined}
      />
    );
  }

  const needSignature = data.needSignature;
  if (needSignature && !skipSignatureRequirement) {
    return (
      <MissingSignature
        needSignature={needSignature!}
        skipSignature={IMPERSONATOR ? () => setSkipSignatureRequirement(true) : undefined}
      />
    );
  }

  return (
    <Steps
      termCommitment={data.account.termCommitment}
      inboundShippingEligible={data.inboundShippingEligible}
      isAccountCanceled={data.account.state === Account__State.Canceled}
    />
  );
};
