import cn from 'classnames';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

enum NavFormat {
  Pills = 'pills',
  Tabs = 'tabs',
}

interface INavLinkProps {
  active?: boolean;
}

const NavLink: React.FC<React.HTMLProps<HTMLAnchorElement> & INavLinkProps> = ({
  active,
  children,
  className,
  ...props
}) => (
  <a {...props} className={cn('nav-link', active && 'active', className)}>
    {children}
  </a>
);

const RouterLink: React.FC<LinkProps & INavLinkProps> = ({ active, children, className, ...props }) => (
  <Link {...props} className={cn('nav-link', active && 'active', className)}>
    {children}
  </Link>
);

const NavItem: React.FC<React.HTMLProps<HTMLLIElement>> = ({ children, className, ...props }) => (
  <li {...props} className={cn('nav-item', className)}>
    {children}
  </li>
);
interface INavProps {
  format?: NavFormat;
  justified?: boolean;
}

const Nav: React.FC<React.HTMLProps<HTMLUListElement> & INavProps> = ({
  format,
  justified,
  children,
  className,
  ...props
}) => (
  <ul {...props} className={cn('nav', format && `nav-${format}`, justified && 'nav-justified', className)}>
    {children}
  </ul>
);

const Combined = Object.assign(Nav, {
  Item: NavItem,
  Link: NavLink,
  RouterLink,
  Format: NavFormat,
});
export { Combined as Nav };
