import * as React from 'react';
import styled from '@emotion/styled';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrderStateEnum, useOrderDocumentsQuery } from '@portal/schema';
import { Header } from '@portal/components/helpers/header';
import { Table } from '@portal/components/shared/table';
import { COLORS, Text } from '@clutter/clean';
import { wt } from '@portal/initializers/wt';
import { flatMap } from 'lodash';

const StyledTable = styled(Table)`
  td {
    padding: 8px 0;
    border-top: none;
    border-bottom: none;
  }
`;

const Link = styled.a`
  color: ${COLORS.tealPrimary};

  &:hover {
    color: ${
      // eslint-disable-next-line no-underscore-dangle
      COLORS.__primaryButtonHover
    };
  }
`;

export const Documents: React.FC<{
  orderID: string;
}> = ({ orderID }) => {
  const { data, loading } = useOrderDocumentsQuery({ variables: { orderID } });

  if (!data || loading) {
    return null;
  }

  const orderDocuments = data.order;
  const orderCoiRequests = flatMap(orderDocuments.nonTravelSourceAndSuccessors.map((order) => order.coiRequests));
  const orderSignatures = flatMap(orderDocuments.nonTravelSourceAndSuccessors.map((order) => order.signatures));
  const orderIngestedDocuments = flatMap(
    orderDocuments.nonTravelSourceAndSuccessors.map((order) => order.ingestedDocuments),
  );
  const orderDocumentsFlag =
    orderDocuments.state === OrderStateEnum.Completed ||
    orderCoiRequests.filter(({ certificateOfInsurance }) => Boolean(certificateOfInsurance)).length > 0 ||
    orderIngestedDocuments.length > 0;

  if (!orderDocumentsFlag) {
    return null;
  }

  return (
    <>
      <Header tag="h4">Documents</Header>
      <StyledTable>
        <tbody>
          {orderDocuments.state === OrderStateEnum.Completed &&
            orderSignatures.map((signature, idx) => {
              const url = signature.contractUrl || signature.agreement?.imgixURL;
              return (
                <tr key={`signature-${idx}`}>
                  <td>
                    <Text.Callout>{signature.documentKind}</Text.Callout>
                  </td>
                  <td>
                    {url && (
                      <Text.Callout>
                        <Link
                          onClick={() => {
                            wt.track({
                              pageName: 'portal:appointment_details',
                              container: 'documents_downloads',
                              action: 'click',
                              objectType: 'link',
                              document_kind: 'signature',
                              objectName: 'download',
                              label: 'DOWNLOAD',
                              value: url,
                            });
                          }}
                          target="_blank"
                          href={url}
                        >
                          <FontAwesomeIcon icon="arrow-down" /> DOWNLOAD
                        </Link>
                      </Text.Callout>
                    )}
                  </td>
                </tr>
              );
            })}
          {orderCoiRequests.map((request, idx) => (
            <>
              {request.certificateOfInsurance && (
                <tr key={`request-${idx}`}>
                  <td>
                    <Text.Callout>{request.certificateOfInsurance.filename}</Text.Callout>
                  </td>
                  <td>
                    <Text.Callout>
                      <Link
                        onClick={() => {
                          wt.track({
                            pageName: 'portal:appointment_details',
                            container: 'documents_downloads',
                            action: 'click',
                            objectType: 'link',
                            document_kind: 'coi',
                            objectName: 'download',
                            label: 'DOWNLOAD',
                            value: request.certificateOfInsurance?.url,
                          });
                        }}
                        target="_blank"
                        href={request.certificateOfInsurance.url}
                      >
                        <FontAwesomeIcon icon="arrow-down" /> DOWNLOAD
                      </Link>
                    </Text.Callout>
                  </td>
                </tr>
              )}
            </>
          ))}
          {orderIngestedDocuments.map((document, idx) => (
            <tr key={`document-${idx}`}>
              <td>
                <Text.Callout>{document.filename}</Text.Callout>
              </td>
              <td>
                <Text.Callout>
                  <Link
                    onClick={() => {
                      wt.track({
                        pageName: 'portal:appointment_details',
                        container: 'documents_downloads',
                        action: 'click',
                        objectType: 'link',
                        document_kind: 'other',
                        objectName: 'download',
                        label: 'DOWNLOAD',
                        value: document.url,
                      });
                    }}
                    target="_blank"
                    href={document.url}
                  >
                    <FontAwesomeIcon icon="arrow-down" /> DOWNLOAD
                  </Link>
                </Text.Callout>
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
      <hr />
    </>
  );
};
