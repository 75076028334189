import styled from '@emotion/styled';
import * as React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { COLORS } from '@root/colors';
import { Modal } from '@shared/components/bootstrap';

const FullWindowImage = styled.img`
  width: auto;
  height: auto;
  max-height: 80vh;
  max-width: 100%;
  margin: auto;
  display: block;
`;

const StyledSlider = styled(Slider)`
  .slick-prev,
  .slick-next {
    font-size: 15px !important;
  }

  .slick-prev:before,
  .slick-next:before {
    content: '' !important;
  }
  margin: 0 24px 36px;
  text-align: center;
`;

const SliderItem = styled.div`
  width: auto;
  :focus {
    outline: 0;
    outline: none;
  }
`;

const SliderCaption = styled.div`
  position: absolute;
  bottom: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: ${COLORS.white};
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  width: 75.2vw;
`;

const StyledModal = styled((props: React.ComponentProps<typeof Modal>) => <Modal {...props} />)`
  .modal-dialog {
    width: 80%;
  }
`;

const Title = styled.div`
  padding: 10px 0;
`;

const imageFormatter = (imageURL: string) => imageURL + '?w=1280&h=1280';

type ArrowProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const SliderArrow: React.FC<ArrowProps> = ({ style, className, onClick, children }) => (
  <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
    {children}
  </div>
);

export interface ILightboxCarouselInput {
  src: string;
  caption?: string;
  activeIndex?: number;
}

export const Lightbox: React.FC<{
  imageURL: string;
  caption?: string;
  onClose(): void;
}> = ({ imageURL, caption, onClose }) => (
  <StyledModal centered onClose={onClose}>
    <Modal.Content>
      <Modal.Header>
        <Modal.Close close={onClose} />
      </Modal.Header>
      <Modal.Body>
        {caption && (
          <Title className="text-center">
            <strong> {caption} </strong>
          </Title>
        )}
        <div className="text-center">
          <FullWindowImage src={imageFormatter(imageURL)} />
        </div>
      </Modal.Body>
    </Modal.Content>
  </StyledModal>
);

export const LightboxWithCarousel: React.FC<{
  imageInputs: ILightboxCarouselInput[];
  defaultActiveIndex?: number;
  onClose(): void;
}> = ({ imageInputs, defaultActiveIndex, onClose }) => (
  <StyledModal centered onClose={onClose}>
    <Modal.Content>
      <Modal.Header>
        <Modal.Close close={onClose} />
      </Modal.Header>
      <Modal.Body>
        <StyledSlider
          className="slider"
          initialSlide={defaultActiveIndex || 0}
          arrows={true}
          dots={true}
          nextArrow={
            <SliderArrow>
              <FontAwesomeIcon icon={faChevronRight} color={COLORS.black} />
            </SliderArrow>
          }
          prevArrow={
            <SliderArrow>
              <FontAwesomeIcon icon={faChevronLeft} color={COLORS.black} />
            </SliderArrow>
          }
        >
          {imageInputs.map((imageInput) => (
            <SliderItem key={imageInput.src}>
              <FullWindowImage src={imageFormatter(imageInput.src)} />
              {imageInput.caption && (
                <SliderCaption>
                  <p>{imageInput.caption}</p>
                </SliderCaption>
              )}
            </SliderItem>
          ))}
        </StyledSlider>
      </Modal.Body>
    </Modal.Content>
  </StyledModal>
);
