import styled from '@emotion/styled';
import React from 'react';

import { Text, Tooltip, TooltipPlacement } from '@shared/components/bootstrap';

const Container = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .tooltip {
    width: 500px;
  }

  .tooltip-inner {
    max-width: 500px;
  }
`;

export const TruncatedWithTooltip: React.FC<{
  text: string;
}> = ({ text }) => (
  <Container>
    <Tooltip title={text} placement={TooltipPlacement.Bottom}>
      <Text wrapping="truncate" alignment="left">
        {text}
      </Text>
    </Tooltip>
  </Container>
);
