import React from 'react';

export const StorageFacility: React.FC = () => (
  <svg width="81" height="54" viewBox="0 0 81 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M3.52904 0.167702H30.417L67.388 13.5838V16.9379L30.417 3.52174H3.52904V0.167702Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.388 53.8323H3.52904V3.52174H30.417L67.388 16.9379V53.8323Z"
        fill="#00A499"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.4709 23.646H33.778V53.8323H77.4709V23.646Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.1099 30.354H50.583V53.8323H74.1099V30.354Z"
        fill="#A1E6E0"
        stroke="#A1E6E0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.861 30.354H37.139V43.7702H43.861V30.354Z"
        fill="#A1E6E0"
        stroke="#A1E6E0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.056 6.87578H6.89003V16.9379H27.056V6.87578Z"
        fill="#A1E6E0"
        stroke="#A1E6E0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.778 27H77.4709"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.222 23.646V53.8323"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.417 3.52174V53.8323"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.388 23.646H3.52904"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.388 20.2919H3.52904"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6701 6.87578H27.056V16.9379"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.22614 16.6025L20.334 7.21117H7.22614V16.6025Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.778 50.4783H3.52904"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6468 33.4163C18.1628 33.3358 17.6889 33.3257 17.2184 33.423C14.7212 33.9328 13.5011 36.7971 14.8556 38.9672C15.7026 40.3289 17.2419 40.9696 18.7543 40.5771C19.5912 40.3591 20.2735 39.8761 20.8987 39.2993C20.9356 39.2657 20.9726 39.2288 21.0096 39.1953C21.0197 39.1852 21.0365 39.1852 21.0734 39.1718C21.3289 39.5743 21.5877 39.9801 21.8431 40.386C22.0784 40.765 22.0885 40.7784 21.7759 41.097C20.3273 42.5761 18.5829 43.2034 16.5327 42.7875C13.6994 42.2106 11.7366 39.6078 11.9483 36.7368C12.0794 34.9222 12.8222 33.3961 14.2876 32.2692C15.3698 31.4374 16.6302 31.1624 17.978 31.2194C18.8686 31.2563 19.719 31.4407 20.4819 31.9237C20.9323 32.2055 21.3053 32.5643 21.5305 33.054C21.796 33.6309 21.6751 34.3084 21.2348 34.7344C20.7642 35.1939 20.0618 35.308 19.4635 35.0229C18.9157 34.7646 18.5863 34.1709 18.6401 33.5437C18.6434 33.5068 18.6434 33.4733 18.6468 33.4163Z"
        fill="white"
      />
      <path
        d="M37.4751 30.6894H43.5249L37.4751 40.0807V30.6894Z"
        fill="#00A499"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.9191 30.6894H66.7158L50.9191 53.1615V30.6894Z"
        fill="#00A499"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.583 37.0621H74.1099"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.583 37.3975H74.1099"
        stroke="#A1E6E0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.583 36.7267H74.1099"
        stroke="#A1E6E0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.305 53.8323V30.354"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.388 53.8323V30.354"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.778 4.74261V53.8323"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.027 15.717V23.646"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.168045 53.8323H80.8319"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.417 3.18634V0.167702"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="81" height="54" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
