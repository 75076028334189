import React from 'react';

const ID = 'standard-protection';

export const Standard: React.FC<{ title?: string }> = ({ title = 'Standard Protection' }) => (
  <svg aria-labelledby={ID} width="61" height="71" viewBox="0 0 61 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title id={ID}>{title}</title>
    <path
      d="M17.324 60.2443C7.23867 52.6546 1.22 40.5765 1.22 27.9271V16.7221C1.22 15.3674 2.30987 14.2738 3.66 14.2738H8.54C13.6315 14.2738 18.5115 12.2418 22.1064 8.63464L28.7757 1.94268C29.7273 0.987856 31.2727 0.987856 32.2243 1.94268L38.8936 8.63464C42.4885 12.2418 47.3685 14.2738 52.46 14.2738H57.34C58.6901 14.2738 59.78 15.3674 59.78 16.7221V27.9352C59.78 40.6499 53.8101 52.622 43.676 60.2524L30.5 70.1598L17.324 60.2443Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.03333 27.9271V16.7222C2.03333 15.8245 2.76533 15.09 3.65999 15.09H8.53999C13.8836 15.09 18.9019 13.0008 22.6839 9.21411L29.3532 2.52216C29.6623 2.21205 30.0689 2.04883 30.5 2.04883C30.9311 2.04883 31.3459 2.22021 31.6468 2.52216L38.3161 9.21411C42.09 13.0008 47.1164 15.09 52.46 15.09H57.34C58.2347 15.09 58.9667 15.8245 58.9667 16.7222V27.9353"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.5 60.9788L21.716 54.3684C13.42 48.1253 8.54001 38.3322 8.54001 27.9271V21.6105C15.5672 21.6105 22.3097 18.8113 27.2873 13.8168L30.5 10.5933L33.7127 13.8168C38.6821 18.8031 45.4247 21.6105 52.46 21.6105V27.9271C52.46 38.3322 47.58 48.1253 39.284 54.3684L30.5 60.9788Z"
      fill="#FFEABF"
      stroke="#FFEABF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.5537 53.2504C14.6563 47.3011 9.93893 37.8345 9.93893 27.9271V22.9816C16.8685 22.6388 23.3345 19.7743 28.2715 14.8125L30.5 12.5764L32.7285 14.8125C37.6655 19.7662 44.1315 22.6388 51.0611 22.9816V27.9271C51.0611 37.8345 46.3437 47.3011 38.4463 53.2504L30.5 59.2324L22.5537 53.2504Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.46 27.9271V21.6105C45.4328 21.6105 38.6903 18.8113 33.7127 13.8168L30.5 10.5933L27.2873 13.8168C22.3179 18.8031 15.5753 21.6105 8.54001 21.6105V27.9271"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1605 70.1599H45.8395"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.2803 34.9209C48.6292 42.1678 44.4649 48.721 38.4544 53.2503L30.5081 59.2323L22.5619 53.2503C16.5513 48.7292 12.3871 42.1678 10.736 34.9209"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.7526 24.3037L34.4447 29.7878L40.4715 30.6692C41.6183 30.8324 42.0737 32.2443 41.2441 33.0522L36.8847 37.3204L37.9176 43.3431C38.1128 44.4856 36.9172 45.3589 35.8924 44.8202L30.5 41.9802L25.1076 44.8202C24.0828 45.3589 22.8872 44.4856 23.0824 43.3431L24.1153 37.3204L19.7559 33.0522C18.9263 32.2443 19.3817 30.8324 20.5285 30.6692L26.5553 29.7878L29.2475 24.3037C29.7599 23.2673 31.2402 23.2673 31.7526 24.3037Z"
      fill="white"
    />
    <path
      d="M41.2442 33.0522L36.8847 37.3204L37.9176 43.3432C38.1128 44.4857 36.9172 45.3589 35.8924 44.8203L30.5 41.9803L25.1076 44.8203C24.0828 45.3589 22.8872 44.4857 23.0824 43.3432L24.1154 37.3204L19.7559 33.0522"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.78 27.927C59.78 40.6417 53.8101 52.6138 43.676 60.2442L30.5 70.1598L17.324 60.2442C7.23867 52.6546 1.22 40.5764 1.22 27.927"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
