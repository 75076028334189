import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router';

import {
  Button,
  COLORS,
  FontWeight,
  Text,
  Toolbar,
  UsageBar,
  useSnackbarContext,
  TOOLBAR_HEIGHT,
} from '@clutter/clean';

import { clamp } from '@portal/utils/clamp';

import {
  UpgradeDowngrade__EntryFragment,
  UpgradeDowngrade__SummaryFragment,
  useUsageWithDownsizeQuery,
  useConfirmUpgradeDowngradeForSuggestedPricingMutation,
} from '@portal/schema';
import { Percent } from '@shared/components/helpers/percent';
import { itemsURL, supportURL } from '@portal/config/routes';

import { Currency } from '@shared/components/helpers';
import { Name as PlanName } from '@portal/components/plan/name';
import { Link } from 'react-router-dom';
import { Name } from './name';

const PRECISION = 0;

const DEFAULT_PADDING = 32; // pixels

const Container = styled.div`
  margin: ${DEFAULT_PADDING}px 0 ${TOOLBAR_HEIGHT + DEFAULT_PADDING}px;
`;

const Section = styled.section`
  margin: 24px 0;
`;

const Ledger = styled.div`
  margin: 16px 0 24px;
  background: ${COLORS.grayBackground};
  border-radius: 4px;
`;

const Entry = styled.div<{
  total?: boolean;
  savings?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  font-weight: ${({ total }) => (total ? FontWeight.Medium : FontWeight.Regular)};
  background: ${({ savings }) => (savings ? COLORS.tealBackground : COLORS.grayBackground)};
`;

const Separator = styled.div`
  height: 2px;
  margin: 0 24px;
  background: ${COLORS.grayBorder};
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
`;

const Highlight = styled.span`
  color: ${COLORS.tealPrimary};
`;

const IneligibleParagraph = styled(Text.Body)`
  margin: 0 0 16px 0;
`;

const IneligibleTitle = styled(Text.Title)`
  margin: 0 0 16px 0;
`;

const IneligibleReturnButton = styled(Button)`
  margin: 32px 0 0 0;
`;

const cuft = (entry: UpgradeDowngrade__EntryFragment): number => {
  const plan = entry.price.plan;
  if (plan.__typename === 'Pricing__StoragePlan') {
    return plan.cuft * entry.quantity;
  }
  return 0;
};

const Entries: React.FC<{
  entries: UpgradeDowngrade__EntryFragment[];
}> = ({ entries }) => {
  const total: number = entries.reduce((memo, entry) => entry.total + memo, 0);
  return (
    <>
      {entries.map((entry, index) => (
        <Entry key={index}>
          <Text.Callout>
            <PlanName plan={entry.price.plan} quantity={entry.quantity} />
          </Text.Callout>
          <Text.Callout>
            <Currency precision={PRECISION} value={entry.total} />
            /mo
          </Text.Callout>
        </Entry>
      ))}
      <Separator />
      <Entry total>
        <Text.Callout weight={FontWeight.Medium}>Monthly total</Text.Callout>
        <Text.Callout weight={FontWeight.Medium}>
          <Currency precision={PRECISION} value={total} />
          /mo
        </Text.Callout>
      </Entry>
    </>
  );
};

const Total: React.FC<{
  summary: UpgradeDowngrade__SummaryFragment;
}> = ({ summary }) => {
  const savings = summary.savings;

  return (
    <Entry total savings>
      <Text.Callout weight={FontWeight.Medium}>Savings</Text.Callout>
      <Text.Callout weight={FontWeight.Medium}>
        <Currency precision={PRECISION} value={savings} />
        /mo
      </Text.Callout>
    </Entry>
  );
};

const Ineligible: React.FC = () => {
  const history = useHistory();

  return (
    <Container>
      <IneligibleTitle size="large">You're not eligible to downsize</IneligibleTitle>
      <IneligibleParagraph>If you would like to downsize your plan, please remove some items.</IneligibleParagraph>
      <IneligibleParagraph>
        Please reach out to <Link to={supportURL()}>Customer Support</Link> if you have any questions.
      </IneligibleParagraph>
      <IneligibleReturnButton type="button" size="large" fullWidth={true} onClick={() => history.push(itemsURL())}>
        Return home
      </IneligibleReturnButton>
    </Container>
  );
};

const ResponsiveToolbarContent = styled(Toolbar.Content)`
  margin: 0 auto;
`;

export const DownsizeForm: React.FC = () => {
  const { addSnack } = useSnackbarContext();

  const history = useHistory();
  const { data } = useUsageWithDownsizeQuery();
  const [submit, { loading: saving }] = useConfirmUpgradeDowngradeForSuggestedPricingMutation();
  if (!data) return null;
  const summary = data.account.summaryForSuggestedUpgradeDowngrade;
  const usage = data.account.usage;
  if (!summary || !usage) return <Ineligible />;

  const used = usage.used;
  const total = summary.proposed.reduce((memo, entry) => memo + cuft(entry), 0);

  const percentage = total > 0 ? clamp(used / total) : 0;

  const cancel = () => history.push(itemsURL());

  const save = async () => {
    await submit();
    addSnack({
      key: 'ConfirmUpgradeDowngradeForSuggestedPricing',
      content: 'Your storage plan and monthly rate have been updated.',
    });
    history.push(itemsURL());
  };

  return (
    <Container>
      <Text.Title size="large">
        Switch to a <Name summary={summary} />
      </Text.Title>
      <Text.Body>Your plan is flexible - you can always upgrade if you need more room in the future.</Text.Body>
      <Section>
        <Title>
          <Text.Title size="extraSmall">Current plan</Text.Title>
        </Title>
        <Ledger>
          <Entries entries={summary.current} />
        </Ledger>
      </Section>
      <Section>
        <Title>
          <Text.Title size="extraSmall">
            New <Name summary={summary} /> plan
          </Text.Title>
          <Text.Title size="extraSmall">
            <Highlight>
              <Percent value={percentage} />
            </Highlight>
          </Text.Title>
        </Title>
        <UsageBar percentage={percentage} />
        <Ledger>
          <Entries entries={summary.proposed} />
          <Total summary={summary} />
        </Ledger>
      </Section>
      <Toolbar>
        <ResponsiveToolbarContent className="container">
          <Toolbar.Start>
            <Toolbar.Item>
              <Toolbar.Back onClick={cancel} />
            </Toolbar.Item>
          </Toolbar.Start>
          <Toolbar.End>
            <Toolbar.Item>
              <Button type="button" size="large" disabled={saving} loading={saving} onClick={save}>
                Confirm Updates
              </Button>
            </Toolbar.Item>
          </Toolbar.End>
        </ResponsiveToolbarContent>
      </Toolbar>
    </Container>
  );
};
