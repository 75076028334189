import { SEND_STARTED } from '@clutter/wt';
import React from 'react';

import { wt } from '@portal/initializers/wt';
import { IEventParams } from '@portal/types/wt/event';

export const TrackedSelect: React.FC<{
  params: IEventParams;
  children: React.ReactElement<HTMLAnchorElement>;
}> = ({ params, children }) => {
  const wrappedOnSelect = (event: React.MouseEvent, ...args: any[]) => {
    if (!event.defaultPrevented) {
      event.preventDefault();
      const unsub = wt.subscribe(SEND_STARTED, () => {
        if (children.props.onSelect) {
          children.props.onSelect(event, ...args);
        }
        unsub();
      });
      wt.track(params);
    }
  };
  return React.cloneElement(children, { onSelect: wrappedOnSelect });
};
