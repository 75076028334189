
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"itemPricing"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ItemPricing"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pickupPerItemFee"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"returnPerItemFee"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"pickupLargeItemFee"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"returnLargeItemFee"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"appointmentFee"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"pickupEstimate"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"pickupEstimate"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"pickupLargeEstimate"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"pickupEstimate"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"returnedItemsCount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"returnedLargeItemsCount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"price"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"accountPackages"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"accountPackage"},"directives":[]}]}}]}}],"loc":{"start":0,"end":422}};
    doc.loc.source = {"body":"#import \"./pickup_estimate.gql\"\n#import \"@portal/fragments/pricing/account_package.gql\"\n\nfragment itemPricing on ItemPricing {\n  pickupPerItemFee\n  returnPerItemFee\n  pickupLargeItemFee\n  returnLargeItemFee\n  appointmentFee\n  pickupEstimate {\n    ...pickupEstimate\n  }\n  pickupLargeEstimate {\n    ...pickupEstimate\n  }\n  returnedItemsCount\n  returnedLargeItemsCount\n  price\n  accountPackages {\n    ...accountPackage\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./pickup_estimate.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("@portal/fragments/pricing/account_package.gql").definitions));


      module.exports = doc;
    
