import cn from 'classnames';
import * as React from 'react';

const Item: React.FC<{ active?: boolean }> = ({ active, children }) => (
  <li className={cn('breadcrumb-item', active && 'active')}>{children}</li>
);

const Breadcrumb: React.FC = ({ children }) => <ol className="breadcrumb">{children}</ol>;

const Combined = Object.assign(Breadcrumb, { Item });
export { Combined as Breadcrumb };
