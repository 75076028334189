import React from 'react';
import { useHistory } from 'react-router';
import { orderURL } from '@portal/config/routes';
import { DetailedBannerAlert } from '@portal/components/shared/detailed_banner_alert';

export const NeedSignatureAlert: React.FC<{ needSignature: string }> = (needSignature) => {
  const history = useHistory();

  const params = {
    pageName: 'portal',
    container: 'need_signature_alert',
    objectType: 'link',
    action: 'click',
    objectName: 'need_signature',
    label: 'Sign Documents',
  };

  return (
    <DetailedBannerAlert
      handleClick={() => history.push(orderURL(needSignature.needSignature, 'signatures'))}
      heading="Please sign your documents from your last appointment!"
      subheading="Looks like you missed signing some documents from your last appointment. Please finish signing your documents in order to schedule your next appointment."
      linkText="Sign Documents"
      trackingParams={params}
      customerProtected
    />
  );
};
