import { IIconProps } from '@portal/components/shared/icons/props';
import React from 'react';
import { snakeCase } from 'lodash';

export const PayingWithCreditCard: React.FC<IIconProps> = ({ title, width = 72, height = 65 }) => (
  <svg
    aria-hidden={!title}
    aria-labelledby={title && `${snakeCase(title)}-svg`}
    width={width}
    height={height}
    viewBox="0 0 72 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title id={`${snakeCase(title)}-svg`}>{title}</title>}
    <path
      d="M46.4811 30.4006L49.5529 34.2764C49.9571 34.7609 49.8358 35.4876 49.3104 35.8509C47.3299 37.1832 44.6623 37.0621 42.8434 35.4876"
      fill="#F9F9F9"
    />
    <path
      d="M46.4811 30.4006L49.5529 34.2764C49.9571 34.7609 49.8358 35.4876 49.3104 35.8509C47.3299 37.1832 44.6623 37.0621 42.8434 35.4876"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.883 36.2144L23.3214 10.6585L24.4531 8.47837C25.7465 5.97527 28.5353 4.60259 31.3242 5.08707"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.2344 40.8573L25.2211 50.2238"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.3228 51.4753L26.5549 48.8511L25.2211 50.2237L27.3228 51.4753Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.1806 29.5125L28.6566 26.969C29.0204 26.7268 28.7374 26.1616 28.3333 26.2423L22.9172 27.4939"
      fill="#F9F9F9"
    />
    <path
      d="M25.1806 29.5125L28.6566 26.969C29.0204 26.7268 28.7374 26.1616 28.3333 26.2423L22.9172 27.4939"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.3226 51.4753L7.47717 39.8076L0.201874 53.2921L19.1985 64.7983L27.3226 51.4753Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.3109 31.6119L35.3661 22.1244C36.2957 21.4784 37.0232 20.5902 37.5487 19.6212L39.5696 15.8262C39.9333 15.0995 40.8225 14.8169 41.5905 15.1399C44.2581 16.7952 43.9348 19.6616 42.5605 22.5281L41.3884 24.9101C41.0246 25.6771 40.58 26.4442 40.1354 27.1709L33.911 37.143C32.2134 40.4939 29.5862 43.2796 26.2719 45.0964L22.8768 46.9939"
      fill="#F9F9F9"
    />
    <path
      d="M22.3109 31.6119L35.3661 22.1243C36.2957 21.4784 37.0232 20.5902 37.5487 19.6212L39.5696 15.8262C39.9333 15.0995 40.8225 14.8169 41.5905 15.1399C44.2581 16.7952 43.9348 19.6616 42.5605 22.5281L41.3884 24.9101C41.0246 25.6771 40.58 26.4442 40.1354 27.1709L33.911 37.143C32.2134 40.4939 29.5862 43.2796 26.2719 45.0964L22.8768 46.9939"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.0142 20.3076L38.3974 19.4598C38.0741 19.2983 37.9528 18.8542 38.1145 18.5312L40.1354 14.7765C40.3375 14.4132 40.8225 14.2517 41.1863 14.4535C42.1159 14.938 42.5605 16.0281 42.1968 16.997L41.1459 19.9039C40.9842 20.3076 40.4588 20.5094 40.0142 20.3076Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.1968 16.9567L41.1459 19.8635C40.9842 20.348 40.4184 20.5498 39.9738 20.3076L38.357 19.4598"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.2058 21.115C38.842 20.9131 38.4379 20.7113 38.0741 20.5094"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.5486 19.5808L39.5696 15.7858C39.9333 15.0591 40.8225 14.7765 41.5905 15.0995"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.1334 0.28266C64.831 -0.0806938 66.5286 1.04974 66.8519 2.74539L71.1767 23.6988C71.5405 25.4348 70.4087 27.0901 68.7112 27.4535L34.6385 34.3168C32.9409 34.6802 31.2434 33.5497 30.92 31.8541L26.5953 10.9007C26.2315 9.16465 27.3632 7.50937 29.0608 7.14601L63.1334 0.28266Z"
      fill="#00A499"
      stroke="#00A499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.1054 20.8727L34.1131 22.2857C33.4664 22.4068 32.8601 22.0031 32.6984 21.3571L31.6071 15.9068C31.4859 15.2609 31.8901 14.6553 32.5368 14.4938L39.5291 13.0807C40.1758 12.9596 40.7821 13.3634 40.9438 14.0093L42.0351 19.4596C42.1563 20.146 41.7521 20.7516 41.1054 20.8727Z"
      fill="#FFEABF"
      stroke="#FFEABF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.0532 13.8478L36.2148 14.6149L37.5082 15.2204C37.8316 15.3819 38.1953 15.4223 38.5591 15.3416L39.9737 15.059"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.1235 17.2391L35.6086 17.5621C35.8511 17.7236 35.9319 18.087 35.7702 18.3292L35.4469 18.8137"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.5187 18.1677L38.0337 17.8447C37.7911 17.6832 37.7103 17.3198 37.872 17.0776L38.1953 16.5931"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.1654 17.2391L41.4692 16.795"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.173 18.6521L34.4768 18.208"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.1744 14.6149L35.2448 15.6646C35.0023 15.9472 34.6789 16.1087 34.3556 16.1894L32.9409 16.472"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.6295 21.5993L37.4678 20.8323L36.1744 20.2267C35.8511 20.0652 35.4873 20.0248 35.1235 20.1056L33.7089 20.3882"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.4678 20.8322L38.3974 19.7826C38.6399 19.4999 38.9633 19.3385 39.2866 19.2577L40.7013 18.9751"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.405 19.7884L33.8456 26.3892L34.6334 30.2666L67.1928 23.6658L66.405 19.7884Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.3237 7.63057C58.0811 6.50013 58.4853 5.3697 59.3341 4.64299C58.8087 4.4815 58.2428 4.44113 57.677 4.56224C55.9794 4.9256 54.8477 6.58088 55.2114 8.3169C55.5752 10.0126 57.2324 11.143 58.9703 10.7796C59.5362 10.6585 60.0212 10.4163 60.4254 10.0529C59.3745 9.6492 58.5662 8.80137 58.3237 7.63057Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.7488 3.87586C60.1829 3.99698 59.6979 4.23922 59.2937 4.60257C60.3446 4.92555 61.1529 5.81375 61.3955 6.94419C61.638 8.07462 61.2338 9.20506 60.385 9.93177C60.9104 10.0933 61.4763 10.1336 62.0421 10.0125C63.7397 9.64916 64.8714 7.99388 64.5077 6.25785C64.1439 4.64294 62.4867 3.55288 60.7488 3.87586Z"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.3955 6.98456C61.153 5.85412 60.3042 4.96593 59.2937 4.64294C58.4853 5.36965 58.0812 6.50009 58.2833 7.63052C58.5258 8.76096 59.3745 9.64916 60.385 9.97214C61.2338 9.2858 61.638 8.15537 61.3955 6.98456Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.217 23.6584C71.5808 25.3944 70.4491 27.0497 68.7515 27.4131L34.6384 34.3168C32.9409 34.6801 31.2433 33.5497 30.9199 31.8541L26.5952 10.9006"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.6071 15.9472C31.4859 15.3013 31.8901 14.6957 32.5368 14.5342L39.5291 13.1211C40.1758 13 40.7821 13.4038 40.9438 14.0497L42.0351 19.5C42.1563 20.146 41.7521 20.7516 41.1054 20.9131"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.3109 31.6118L35.3661 22.1242C36.2957 21.4783 37.0232 20.5901 37.5487 19.6211L39.5696 15.8261C39.9333 15.0994 40.8225 14.8168 41.5905 15.1398C44.2581 16.795 43.9348 19.6615 42.5605 22.528L41.3884 24.9099C41.0246 25.677 40.58 26.4441 40.1354 27.1708L33.911 37.1429C32.2134 40.4938 29.5862 43.2795 26.2719 45.0963L22.8768 46.9938"
      fill="#F9F9F9"
    />
    <path
      d="M22.3109 31.6118L35.3661 22.1242C36.2957 21.4783 37.0232 20.5901 37.5487 19.6211L39.5696 15.8261C39.9333 15.0994 40.8225 14.8168 41.5905 15.1398C44.2581 16.795 43.9348 19.6615 42.5605 22.528L41.3884 24.9099C41.0246 25.677 40.58 26.4441 40.1354 27.1708L33.911 37.1429C32.2134 40.4938 29.5862 43.2795 26.2719 45.0963L22.8768 46.9938"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.0142 20.3075L38.3974 19.4596C38.0741 19.2981 37.9528 18.854 38.1145 18.5311L40.1354 14.7764C40.3375 14.413 40.8225 14.2516 41.1863 14.4534C42.1159 14.9379 42.5605 16.028 42.1968 16.9969L41.1459 19.9037C40.9842 20.3075 40.4588 20.5093 40.0142 20.3075Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.1968 16.9565L41.1459 19.8634C40.9842 20.3478 40.4184 20.5497 39.9738 20.3075L38.357 19.4596"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.2058 21.1149C38.842 20.913 38.4379 20.7111 38.0741 20.5093"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
