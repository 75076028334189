import React, { useState } from 'react';

import { ClaimConsumer, ClaimPropertySelectionConsumer } from '@portal/contexts';

import { Alert } from '@shared/components/bootstrap';

import { Attachments } from '@portal/components/claims/helpers/attachments';
import { Step } from '@portal/components/claims/step';

import { ClaimPropertySelectionAttachmentName } from '@portal/schema';
import { Attacher } from './attacher';

const ACCEPT = '*/*';

export const Quotes = () => {
  const [uploading, setUploading] = useState(false);
  return (
    <ClaimConsumer>
      {({ claim: { uuid } }) => (
        <ClaimPropertySelectionConsumer>
          {({ selection: { quotes }, onChange }) => (
            <Attacher
              name={ClaimPropertySelectionAttachmentName.Quotes}
              uuid={uuid}
              onAttach={(attachment) => {
                onChange((selection) => ({
                  ...selection,
                  quotes: [...selection.quotes, attachment],
                }));
              }}
              onDetach={(attachment) => {
                onChange((selection) => ({
                  ...selection,
                  quotes: selection.quotes.filter(({ id: attachmentID }) => attachmentID !== attachment.id),
                }));
              }}
            >
              {({ attach, detach, loading, error }) => (
                <Step skippable saving={uploading || loading} valid={!!quotes.length}>
                  <Step.Title>
                    Please upload a PDF or image of an estimate from a vendor for the repair to be completed.
                  </Step.Title>
                  {error && <Alert style="danger">{error}</Alert>}
                  <Attachments
                    attachments={quotes}
                    accept={ACCEPT}
                    onAttach={attach}
                    onDetach={detach}
                    onUploading={setUploading}
                  />
                </Step>
              )}
            </Attacher>
          )}
        </ClaimPropertySelectionConsumer>
      )}
    </ClaimConsumer>
  );
};
