import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Staircase, { ChangeData } from '@clutter/staircase';

import {
  HydrateEstimationFlowV1Query,
  Estimation__CategoryType,
  useCreateStepTransitionMutation,
  useHydrateEstimationFlowV1Query,
  Estimation__ResourceType,
  Estimation__AlgorithmName,
} from '@portal/schema';
import { Spinner } from '@portal/components/helpers';
import { ACCOUNT_ID } from '@portal/config/account';
import { WT_PAGE_UUID } from '@portal/initializers/wt';
import { UUID } from '@shared/utils';

import { Photos } from '../photos';
import { FLOW_STEPS_MAP, ItemCounts, ItemSelections, StepName, Values, AddedStepProps } from './data';
import { CreateStepTransition } from '../data';
import { Boxes } from './boxes';
import { AddressDetails } from '../address_details';
import { Intro } from '../intro';
import { Rooms } from './rooms';
import { Items } from './items';
import { Status } from './status';
import { Review } from './review';
import { Plan } from './plan';
import { Progress } from './progress';
import { sanitizeAddress } from '../utils';

const STEPS = [
  {
    name: StepName.Intro,
    step: Intro,
  },
  {
    name: StepName.Address,
    step: AddressDetails,
  },
  {
    name: StepName.Rooms,
    step: Rooms,
  },
  {
    name: StepName.Boxes,
    step: Boxes,
  },
  {
    name: StepName.Items,
    step: Items,
  },
  {
    name: StepName.VirtualWalkthrough,
    step: Photos,
  },
  {
    name: StepName.Status,
    step: Status,
  },
  {
    name: StepName.Review,
    step: Review,
  },
  {
    name: StepName.Plan,
    step: Plan,
  },
];

const getInitialValues = (
  address: HydrateEstimationFlowV1Query['order']['address'],
  rooms?: HydrateEstimationFlowV1Query['estimationSelectedRooms'],
  items?: ItemSelections,
  boxCategoryName?: string,
  allItemsInListedRooms?: boolean | null,
  otherItemCategoryName?: string,
  itemsRequireMoreThanOneMover?: string | null,
) => ({
  address: sanitizeAddress(address),
  rooms,
  selectedItemCounts: items?.reduce((obj: ItemCounts, { category: { id }, quantity }) => {
    if (quantity) {
      obj[id] = quantity;
    }
    return obj;
  }, {}),
  boxCategoryName,
  allItemsInListedRooms,
  otherItemCategoryName,
  itemsRequireMoreThanOneMover,
});

export const EstimationFlowV1: React.FC<RouteComponentProps<{ orderID: string }>> = ({
  match: {
    params: { orderID },
  },
}) => {
  const { current: flowInstanceUuid } = useRef(UUID());
  const currentIndexRef = useRef(0);
  const { data: flowData, loading } = useHydrateEstimationFlowV1Query({
    variables: {
      resourceID: orderID,
      resourceType: Estimation__ResourceType.Order,
      algorithmName: Estimation__AlgorithmName.V1,
    },
    fetchPolicy: 'network-only',
  });
  const [persistStepTransition] = useCreateStepTransitionMutation();
  const [error, setError] = useState<string | undefined>(undefined);

  const createStepTransition: CreateStepTransition = ({ name, actionName, position, values, metadata = {} }) => {
    const stepDetails = FLOW_STEPS_MAP[name];
    const fields = stepDetails?.dataFields;
    const data: Record<string, any> = {};
    if (fields && values) {
      Object.entries(values).forEach(([k, v]) => {
        if (fields.includes(k)) {
          data[k] = v;
        }
      });
    }

    persistStepTransition({
      variables: {
        input: {
          flowName: 'estimation_flow',
          flowVersion: 'v1',
          flowInstanceUuid,
          pageUuid: WT_PAGE_UUID,
          resourceToken: String(ACCOUNT_ID),
          resourceType: 'Account',
          allData: values,
          data,
          name,
          actionName,
          position,
          metadata: {
            ...metadata,
            order_id: orderID,
          },
        },
      },
    });
  };

  useEffect(() => {
    createStepTransition({
      name: 'estimation_flow',
      actionName: 'flow_initiated',
      position: 0,
      values: {},
    });
  }, []);

  if (loading || !flowData?.order) {
    return <Spinner />;
  }

  const onStepChange = (data: ChangeData) => {
    const { currentStep, lastStepIndex, currentStepIndex, values } = data;
    const lastStep = lastStepIndex !== undefined ? STEPS[lastStepIndex] : undefined;
    createStepTransition({
      name: lastStep?.name || '',
      actionName: currentStepIndex < currentIndexRef.current ? 'back' : 'next',
      position: currentStepIndex,
      values,
      metadata: { transitioned_to: currentStep.name },
    });
    currentIndexRef.current = currentStepIndex;
    localStorage.setItem(`estimation_flow_v1_last_step_${orderID}`, currentStep.name);
  };

  const {
    order: { address, type, metadata },
    estimationSelectedRooms: rooms,
    estimationRoomCategories: roomCategories,
    estimationItems: items,
    estimationBoxItemCategories: boxCategories,
    estimationOtherItemCategories: otherItemCategories,
    estimationSelectedBoxEstimatedItem: boxes,
    estimationSelectedOtherEstimatedItem: otherItems,
    estimationItemCategoryThemes,
  } = flowData;

  let additionalItemCategory: Estimation__CategoryType | undefined;
  const itemCategories = estimationItemCategoryThemes.filter((theme) => {
    if (theme.name === 'Custom Items') {
      additionalItemCategory = theme.categories[0];
      return false;
    } else if (theme.name === 'Boxes') {
      return false;
    }
    return true;
  });

  const boxCategoryName = boxes?.category?.name;
  const otherItemCategoryName = otherItems?.category?.name;
  const allItemsInListedRooms = metadata?.allItemsInListedRooms;
  const itemsRequireMoreThanOneMover = metadata?.itemsRequireMoreThanOneMover;
  const initialValues = getInitialValues(
    address,
    rooms,
    items,
    boxCategoryName,
    allItemsInListedRooms,
    otherItemCategoryName,
    itemsRequireMoreThanOneMover,
  );

  let initialStepIndex = 0;
  const initialStepName = localStorage.getItem(`estimation_flow_v1_last_step_${orderID}`);
  if (initialStepName && initialStepName !== STEPS[STEPS.length - 1].name) {
    initialStepIndex = STEPS.findIndex(({ name }) => name === initialStepName) || 0;
  }
  currentIndexRef.current = initialStepIndex;

  return (
    <Staircase<Values, AddedStepProps>
      onStepChange={onStepChange}
      initialStepIndex={initialStepIndex}
      steps={STEPS}
      initialValues={initialValues as Values}
    >
      {({ Step, getStepProps, currentStep, goToStep, values }) => (
        <>
          <Progress currentStep={currentStep} goToStep={goToStep} values={values} />
          {error && (
            <div className="alert-dismissible alert-danger alert">
              {error}
              <button type="button" className="close" onClick={() => setError(undefined)}>
                ×
              </button>
            </div>
          )}
          <Step
            {...getStepProps({
              algorithmName: Estimation__AlgorithmName.V1,
              roomCategories,
              itemCategories,
              boxCategories,
              otherItemCategories,
              additionalItemCategory,
              setError,
              orderID,
              createStepTransition,
              currentStep,
              stepName: STEPS[currentStep].name,
              orderType: type,
              canPrev: true,
              prev: () => goToStep(currentStep - 1),
            })}
          />
        </>
      )}
    </Staircase>
  );
};
