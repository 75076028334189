import { useEffect, useState } from 'react';

export const useIntersectionObserver = ({ root, rootMargin, threshold }: IntersectionObserverInit = {}) => {
  const [entry, updateEntry] = useState<IntersectionObserverEntry | null>(null);
  const [element, setElement] = useState<HTMLElement | null>(null);
  useEffect(() => {
    let observer: IntersectionObserver | undefined;

    if (element) {
      observer = new IntersectionObserver(([currentEntry]) => updateEntry(currentEntry), {
        root,
        rootMargin,
        threshold,
      });
      observer.observe(element);
    }

    return () => observer?.disconnect();
  }, [element, root, rootMargin, threshold]);

  return [setElement, entry] as const;
};
