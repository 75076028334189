import { RouteWithTitle } from '@portal/components/shared/route_with_title';
import * as React from 'react';
import { Switch } from 'react-router-dom';

import { addressEditURL, addressNewURL } from '@portal/config/routes';

import { Theme } from '@portal/components/helpers/theme';
import { EditAddress } from './addresses/edit_address';
import { NewAddress } from './addresses/new_address';

export const Addresses: React.FC = () => (
  <Theme name="default">
    <Switch>
      <RouteWithTitle exact path={addressEditURL()} component={EditAddress} title="Edit Address" />
      <RouteWithTitle exact path={addressNewURL()} component={NewAddress} title="New Address" />
    </Switch>
  </Theme>
);
