import styled from '@emotion/styled';
import React from 'react';

import { Button, Text, Toolbar, TOOLBAR_HEIGHT } from '@clutter/clean';

const DEFAULT_PADDING = 32; // px

const Container = styled.div`
  margin: ${DEFAULT_PADDING}px 0 ${TOOLBAR_HEIGHT + DEFAULT_PADDING}px;
`;

const ResponsiveToolbarContent = styled(Toolbar.Content)`
  margin: 0 auto;
`;

export const Page: React.FC<{
  title: string;
  loading: boolean;
  ready: boolean;
  onBack?(): void;
  onNext?(): void;
}> = ({ children, loading, title, onBack, onNext, ready }) => (
  <Container>
    <Text.Title size="large">{title}</Text.Title>
    {children}
    <Toolbar>
      <ResponsiveToolbarContent className="container">
        {onBack && (
          <Toolbar.Start>
            <Toolbar.Item>
              <Toolbar.Back onClick={onBack} disabled={loading} />
            </Toolbar.Item>
          </Toolbar.Start>
        )}
        {onNext && (
          <Toolbar.End>
            <Toolbar.Item>
              <Button type="button" size="large" disabled={loading || !ready} loading={loading} onClick={onNext}>
                Next
              </Button>
            </Toolbar.Item>
          </Toolbar.End>
        )}
      </ResponsiveToolbarContent>
    </Toolbar>
  </Container>
);
