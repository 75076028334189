
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"address"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Address"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"street"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"aptsuite"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"city"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"state"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"zip"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"formatted"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"formattedWithoutApartmentNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"latitude"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"longitude"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"serviceable"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"businessName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"outsideServiceArea"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"stateAbbreviation"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":217}};
    doc.loc.source = {"body":"fragment address on Address {\n  id\n  street\n  aptsuite\n  city\n  state\n  zip\n  formatted\n  formattedWithoutApartmentNumber\n  latitude\n  longitude\n  serviceable\n  businessName\n  outsideServiceArea\n  stateAbbreviation\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
