import React from 'react';
import { DateTime } from 'luxon';
import { Text } from '@clutter/clean';

import { Section } from '@portal/components/orders/steps/confirm/section';

const WaitlistedDates: React.FC<{ dates: DateTime[] }> = ({ dates }) => (
  <Text.Body>
    <ul>
      {dates.map((date) => (
        <li key={date.toISODate()}>{date.toLocaleString(DateTime.DATE_MED)}</li>
      ))}
    </ul>
  </Text.Body>
);

export const Waitlist: React.FC<{ waitlistedDates: DateTime[] }> = ({ waitlistedDates }) => (
  <Section title="Waitlist">
    <Text.Body>After confirming your appointment info, you will be on the waitlist for the below dates:</Text.Body>
    <WaitlistedDates dates={waitlistedDates} />
  </Section>
);
