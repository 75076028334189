import React, { useState } from 'react';

import { Box, COLORS, FontWeight, Modal, Text, mq } from '@clutter/clean';
import { DisposalItemPricingQuery, useDisposalCategoryPricingQuery } from '@portal/schema';
import { Currency } from '@shared/components/helpers';
import { groupBy } from 'lodash';
import { Stack } from '@portal/components/helpers/stack';
import styled from '@emotion/styled';

export const SERVICE_FEE_AMOUNT = 50;

const Row = ({ left, right }: { left: React.ReactNode; right: React.ReactNode }) => (
  <Box.Flex justifyContent="space-between">
    <Text.Callout>{left}</Text.Callout>
    <Text.Callout weight={FontWeight.Medium} color={COLORS.storm}>
      {right}
    </Text.Callout>
  </Box.Flex>
);

function tallyItems(items: DisposalItemPricingQuery['disposalItemPricing']) {
  return Object.values(groupBy(items, (lineItem) => lineItem.category)).map((group) =>
    group.reduce<{ quantity: number; amount: number; category: string }>(
      (acc, cur) => ({
        ...acc,
        amount: acc.amount + cur.amount,
        quantity: acc.quantity + 1,
      }),
      { category: group[0].category === 'Other' ? 'Standard Item' : group[0].category, quantity: 0, amount: 0 },
    ),
  );
}

export const DisposalPricingSummary = () => {
  const { data } = useDisposalCategoryPricingQuery();
  const items = data?.disposalCategoryPricing ?? [];

  return (
    <Stack gap="20px">
      <Text.Body>
        Our disposal service starts with a $50 flat fee, along with a size-based fee for each item. All items are $1.50
        / cubic foot, with the following minimum amounts for specific items that require special handling:
      </Text.Body>
      <Stack>
        {items.map((item) => (
          <Row
            key={item.category}
            left={item.category}
            right={
              <>
                <Currency value={item.minimum} precision={0} />
              </>
            }
          />
        ))}
      </Stack>
    </Stack>
  );
};

const MainModalBoxContainer = styled(Box)`
  overflow-y: auto;
  ${mq({
    maxHeight: ['550px', '650px', '750px', '800px'],
    width: ['340px', '460px', '500px', '500px'],
  })}
`;

export const PricingModalToggle = ({
  itemizedPricing,
}: {
  itemizedPricing: DisposalItemPricingQuery['disposalItemPricing'];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const grouped = tallyItems(itemizedPricing ?? []);
  const total = grouped.reduce((acc, cur) => acc + cur.amount, 0) + SERVICE_FEE_AMOUNT;

  return (
    <>
      <a
        href="#"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        See your detailed pricing breakdown here
      </a>
      <Modal includeCloseButton isOpen={isOpen} handleModalClose={() => setIsOpen(false)}>
        <MainModalBoxContainer padding="48px 24px 32px">
          <Stack gap="20px">
            <Stack>
              <Box textAlign="center">
                <Text.Title size="medium">Disposal Pricing</Text.Title>
              </Box>
              <Text.Body>
                Our disposal pricing is based on the size of your belongings, see below for the details. Here’s a
                breakdown of your price:
              </Text.Body>
              <Box margin="20px 0 0">
                <Stack>
                  {grouped.map((group) => (
                    <Row
                      key={group.category}
                      left={`${group.category} × ${group.quantity} `}
                      right={<Currency value={group.amount} />}
                    />
                  ))}
                  <Row left={'Disposal Fee'} right={<Currency value={SERVICE_FEE_AMOUNT} precision={0} />} />
                  <Box border={'1px solid ' + COLORS.grayBorder} borderStyle="solid none none" />
                  <Row left="Total" right={<Currency value={total} />} />
                </Stack>
              </Box>
            </Stack>
            <Box textAlign="center" margin="0 0 8px">
              <Text.Headline color={COLORS.hippo}>Pricing Summary</Text.Headline>
            </Box>
            <DisposalPricingSummary />
          </Stack>
        </MainModalBoxContainer>
      </Modal>
    </>
  );
};
