import { IItemCategory } from './item_category';
import { IMaterialCategory } from './material_category';
import { IPartCategory } from './part_category';

export interface IPalletType {
  id: number;
  name: string;
  capacity: number | undefined;
  itemCategories: IItemCategory[];
  partCategories: IPartCategory[];
  materialCategories: IMaterialCategory[];
  legacySize: PalletLegacySize;
  active: boolean;
  description: string;
  position: number;
}

export enum PalletLegacySize {
  SINGLE = 'SINGLE',
  DOUBLE = 'DOUBLE',
  CRATED = 'CRATED',
  OVERSIZED = 'OVERSIZED',
}
