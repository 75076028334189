import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Container } from '@portal/components/shared/layout';
import { bodyStyle, Heading } from '@portal/components/shared/text';
import { Status, useActivateAccountMutation } from '@portal/schema';

import { ResetForm } from './reset_form';
import { Success } from './reset_success';

const Title = styled(Heading)`
  text-align: center;
  margin-bottom: 24px;
  @media (max-width: 600px) {
    text-align: left;
    margin-bottom: 16px;
  }
`;

const Subtitle = styled.p`
  ${bodyStyle}
  text-align: center;
  padding-bottom: 16px;
  margin: 0;
  @media (max-width: 600px) {
    text-align: left;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 470px;
`;

const SUBTITLE =
  'We just sent an email with a link to activate your online account. If you don’t see the email in ' +
  'your inbox in the next five minutes, please check your spam folder.';
const EMAIL_NOT_FOUND =
  'We couldn’t find an account with that email address. Please make sure the spelling is correct or try another.';

export const Activate: React.FC = () => {
  const [reset, setReset] = useState<boolean>(false);
  const [error, setError] = useState<React.ReactElement | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const [activate] = useActivateAccountMutation();

  const onReset = async (email: string) => {
    setLoading(true);
    const response = await activate({ variables: { email } });
    if (response?.data?.activateAccount) {
      if (response.data.activateAccount.status === Status.Unprocessable) {
        setLoading(false);
        setError(<>{EMAIL_NOT_FOUND}</>);
      } else {
        setReset(true);
      }
    }
  };

  if (reset) {
    return <Success subtitle={SUBTITLE} />;
  }

  return (
    <Container hasNavbar={false}>
      <Title level={1} size="large">
        Activate your online account
      </Title>
      <Content>
        <Subtitle>
          Enter the email you used to book your appointment, and we’ll send you a link to activate your online Clutter
          account.
        </Subtitle>
        <ResetForm loading={loading} error={error} setError={setError} onReset={onReset} />
      </Content>
    </Container>
  );
};
