export class Adapter {
  private stripe: Stripe;

  private elements: IStripeElements;

  constructor(token: string, options?: IStripeOptions) {
    this.stripe = new Stripe(token);
    this.elements = this.stripe.elements(options);
  }

  public element = (type: 'card' | 'cardNumber' | 'cardExpiry', options?: IStripeElementOptions) =>
    this.elements.create(type, options);

  public tokenize = (element: IStripeElement, options?: IStripeTokenOptions) =>
    this.stripe.createToken(element, options);
}
