import React from 'react';

import FiveByTenRoom from '@portal/images/five_by_ten_room.svg';
import Star from '@portal/images/star.svg';
import { COLORS, Column, Grid, Icon, Text } from '@clutter/clean';
import styled from '@emotion/styled';

import { Spacer } from '@shared/components/helpers';

const InfographicContainer = styled.div``;

const MainImageContainer = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
`;

const BulletPointsHeaderText = styled(Text.Body)`
  text-align: center;
`;

const BulletPointRowContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const BulletPointText = styled(Text.Body)`
  flex-shrink: 0;
  flex-shrink: 100;
`;

const StarsContainer = styled.div`
  text-align: center;
`;

const StarImageContainer = styled.img`
  margin-right: 0.5rem;
`;

const FiveStarText = styled(Text.Callout)`
  text-align: center;
`;

const leftBulletPointTexts = ['Low monthly rate', 'Moving & packing help', 'Free, local access available'];
const rightBulletPointTexts = ['Home pickup & delivery', 'Online photo catalog of your items'];

const BulletPointRow: React.FC<{
  text: string;
}> = ({ text }) => (
  <BulletPointRowContainer>
    <Icon.CircleCheck color={COLORS.cloud} backgroundColor={COLORS.tealPrimary} />
    <Spacer width="1rem" />
    <BulletPointText>{text}</BulletPointText>
  </BulletPointRowContainer>
);

export const Infographic: React.FC = () => (
  <InfographicContainer>
    <MainImageContainer src={FiveByTenRoom}></MainImageContainer>
    <Spacer height="2rem" />
    <BulletPointsHeaderText>Service upgrades you’ll get with Clutter:</BulletPointsHeaderText>
    <Spacer height="1.5rem" />
    <Grid>
      <Column widths={[10, 10, 10, 10]}>
        {leftBulletPointTexts.map((text) => (
          <BulletPointRow key={text} text={text} />
        ))}
      </Column>
      <Column widths={[10, 10, 10, 10]}>
        {rightBulletPointTexts.map((text) => (
          <BulletPointRow key={text} text={text} />
        ))}
      </Column>
    </Grid>
    <Spacer height="2rem" />
    <StarsContainer>
      {[1, 2, 3, 4, 5].map((key) => (
        <StarImageContainer src={Star} key={key} />
      ))}
    </StarsContainer>
    <Spacer height="1rem" />
    <FiveStarText>Rated 5-star for moving and storage solutions</FiveStarText>
  </InfographicContainer>
);
