import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';

import { BREAKPOINTS } from '@clutter/clean';

const fakeStorage: Storage = {
  getItem() {
    return null;
  },
  setItem() {
    return null;
  },
  clear() {},
  key() {
    return null;
  },
  removeItem(_) {
    return null;
  },
  length: 0,
};

/**
 * Accessing the variable `sessionStorage` in Chrome and Safari with certain
 * privacy settings enabled results in an error being thrown. Because of this,
 * sessionStorage should not be relied on for business critical functions.
 */
// eslint-disable-next-line import/no-mutable-exports
let SafeSessionStorage = fakeStorage;

try {
  SafeSessionStorage = window.sessionStorage;
} catch (error) {
  if (Sentry) {
    Sentry.configureScope((scope) => {
      scope.setTag('sessionStorageError', 'true');
    });
    Sentry.captureException(error);
  }
}

export { SafeSessionStorage };

const isMobile = (window: Window) => window.innerWidth < BREAKPOINTS.SM_INT;
const isTablet = (window: Window) => window.innerWidth >= BREAKPOINTS.SM_INT && window.innerWidth < BREAKPOINTS.MD_INT;

export const useBreakpointFlags = ({ defaultIsMobile = true, defaultIsTablet = false } = {}) => {
  const [breakpointFlags, setBreakpointFlags] = useState(
    typeof window === 'undefined'
      ? { isMobile: defaultIsMobile, isTablet: defaultIsTablet }
      : {
          isMobile: isMobile(window),
          isTablet: isTablet(window),
        },
  );

  useEffect(() => {
    const onResize = () => {
      const pendingUpdate = {
        isMobile: window.innerWidth < BREAKPOINTS.SM_INT,
        isTablet: window.innerWidth >= BREAKPOINTS.SM_INT && window.innerWidth < BREAKPOINTS.MD_INT,
      };
      if (pendingUpdate.isMobile !== breakpointFlags.isMobile || pendingUpdate.isTablet !== breakpointFlags.isTablet) {
        setBreakpointFlags(pendingUpdate);
      }
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [breakpointFlags]);

  return breakpointFlags;
};
