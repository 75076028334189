import React, { useState } from 'react';

import { Status, useCustomerInitialSetupMutation } from '@portal/schema';
import PhoneNotification from '@portal/images/phone_notification.svg';
import { Button, Checkbox, COLORS, FontWeight, Input, Label, mq, Text } from '@clutter/clean';
import styled from '@emotion/styled';

import { Spacer } from '@shared/components/helpers';
import { DEFAULT_ITEMS_STATUS_SLUG, itemsURL } from '@portal/config/routes';
import { Banner } from '@portal/components/shared/banner';
import axios from 'axios';

const FormContainer = styled.div`
  ${mq({
    width: ['90%', '33%'],
    marginRight: ['0px', '4rem'],
    marginBottom: ['4rem', '0rem'],
  })}
`;

const FullWidthInput = styled(Input)`
  width: 100%;
`;

const FullWidthText = styled(Text.Body)`
  width: 100%;
`;

const FullWidthButton = styled(Button)`
  width: 100%;
`;

const MIN_PASSWORD_LENGTH = 8;
const MS_SUPPORT_URL = 'https://www.clutter.com/help/articles/4470804830359';
const TOS_URL = 'https://www.clutter.com/legal/tos';

export const MakespaceConnectForm: React.FC<{
  email: string;
  initialSetupToken: string;
}> = ({ email, initialSetupToken }) => {
  const [password, setPassword] = useState<string>('');
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const setPasswordButtonDisabled = () => !password || password.length < MIN_PASSWORD_LENGTH || !agreedToTerms;
  const [customerInitialSetup, { loading }] = useCustomerInitialSetupMutation();

  const submit = async () => {
    setShowError(false);

    const initialSetupResponse = await customerInitialSetup({
      variables: { input: { initialSetupToken: initialSetupToken, password: password } },
    });

    if (initialSetupResponse.data?.customerInitialSetup?.status === Status.Ok) {
      try {
        await axios.post('/login.json', { customer: { email, password, remember_me: 1 } });
        window.location.replace(itemsURL(DEFAULT_ITEMS_STATUS_SLUG) + '?makespace=true');
      } catch {
        setShowError(true);
      }
    } else {
      setShowError(true);
    }
  };

  return (
    <FormContainer>
      <Text.Title size="medium">Connect your account on Clutter</Text.Title>
      <Spacer height="1rem" />
      <Banner
        icon={<img src={PhoneNotification}></img>}
        text={
          <Text.Callout weight={FontWeight.Medium}>
            Set a password for your Clutter account to access your MakeSpace order history, items, and invoices.
          </Text.Callout>
        }
      ></Banner>
      <Spacer height="2rem" />
      <>
        <Label>Email</Label>
        <FullWidthInput id="customer-email" type="email" value={email} disabled />
      </>
      <Spacer height="1rem" />
      <>
        <Label>Password</Label>
        <FullWidthInput
          id="customer-password"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value || '')}
          autoComplete="off"
          autoFocus
        />
      </>
      <Spacer height="0.5rem" />
      <Text.Caption>Must contain {MIN_PASSWORD_LENGTH} or more characters.</Text.Caption>
      <Spacer height="1.5rem" />
      <>
        <Checkbox.Selector
          name="terms"
          values={{ 'agreed-to-terms': agreedToTerms }}
          options={[
            {
              key: 'agreed-to-terms',
              label: (
                <FullWidthText>
                  By checking this box, I agree to{' '}
                  <a href={TOS_URL} target="_blank">
                    Clutter’s Terms & Policies
                  </a>
                </FullWidthText>
              ),
            },
          ]}
          onChange={() => setAgreedToTerms(!agreedToTerms)}
        />
      </>
      <Spacer height="1rem" />
      <FullWidthButton onClick={submit} disabled={setPasswordButtonDisabled()} loading={loading}>
        Set Password
      </FullWidthButton>
      {showError && (
        <>
          <Spacer height="0.5rem" />
          <Text.Caption color={COLORS.toucan}>
            Something went wrong. Contact our customer support team if the issue continues.
          </Text.Caption>
        </>
      )}
      <Spacer height="2rem" />
      <FullWidthText>
        Have questions? See{' '}
        <a href={MS_SUPPORT_URL} target="_blank">
          FAQ
        </a>
      </FullWidthText>
    </FormContainer>
  );
};
