import React from 'react';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import styled from '@emotion/styled';

import { Billing__Prepayment } from '@portal/schema';
import { COLORS, Text, Box, UnstyledButton, FontWeight } from '@clutter/clean';
import { StarsLeft } from '@portal/components/shared/icons/stars_left';
import { StarsRight } from '@portal/components/shared/icons/stars_right';
import creditCard from '@portal/images/credit_card.svg';
import { Spacer } from '@shared/components/helpers';
import { orderURL } from '@portal/config/routes';

const CreditCardIcon = styled.img`
  width: 67px;
  margin-right: 15px;
`;

const BannerContainer = styled.div`
  background-color: #fcd7d4;
  padding: 24px;
  border-radius: 4px;
  margin: 0 auto 32px;
  text-align: center;
`;

const Button = styled(UnstyledButton)`
  color: ${COLORS.tealPrimary};
  &:hover {
    color: ${COLORS.tealBrand};
  }
`;

export const FailedDepositAlert: React.FC<{ failedPrepayment: Billing__Prepayment }> = ({
  failedPrepayment: { id, dueAt },
}) => {
  const history = useHistory();
  return (
    <BannerContainer>
      <Box.Flex justifyContent="center" alignItems="center">
        <Box display={['none', 'initial']}>
          <Box.FlexItem>
            <CreditCardIcon src={creditCard} alt="" />
          </Box.FlexItem>
        </Box>
        <Box.FlexItem>
          <Box.Flex justifyContent="center">
            <StarsLeft color={COLORS.toucan} />
            <Spacer width="10px" />
            <Text.Title size="extraSmall">Please Pay Your Deposit</Text.Title>
            <Spacer width="10px" />
            <StarsRight color={COLORS.toucan} />
          </Box.Flex>
          <Spacer height="16px" />
          <Text.Callout>
            <Text.Callout>
              We couldn’t charge your card. Please
              <Button onClick={() => history.push(orderURL(id, 'pay_deposit'))}>
                <Text.Callout weight={FontWeight.Medium}>&nbsp;click here&nbsp;</Text.Callout>
              </Button>
              to pay your deposit by tomorrow at {DateTime.fromISO(dueAt).toFormat('t')}.
            </Text.Callout>
          </Text.Callout>
        </Box.FlexItem>
      </Box.Flex>
    </BannerContainer>
  );
};
