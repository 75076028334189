// NOTE: due to mounting flashes outside a router (for use with ERB and React) - events are used for communication.

import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { IFlash } from '@shared/types';

import { EVENTS as FLASHES_EVENTS } from './flashes';

const Events = ({ history }: RouteComponentProps) => {
  useEffect(
    () =>
      history.listen((location) => {
        const flashes: IFlash[] = [];
        const { state } = location;
        if (state && state.flash) {
          flashes.push(location.state.flash);
        }
        const event = new CustomEvent(FLASHES_EVENTS.RESET, { detail: { flashes } });
        document.dispatchEvent(event);
      }),
    [],
  );

  return null;
};

const EventsForRouter = withRouter(Events);
export { EventsForRouter as Events };
