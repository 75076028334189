import * as React from 'react';
import { useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import {
  OrderTypeEnum,
  useUpdatePickupInventoryMutation,
  PickupInventoryInput,
  useOrderFormPickupInventoryQueryQuery,
  Status,
  OrderDetailsDocument,
} from '@portal/schema';
import { FlashKind, IFlash, IPickupInventoryInput } from '@shared/types';

import { orderURL } from '@portal/config/routes';
import { Container } from './container';
import { OrderPickupInventoryForm } from './pickup_inventory/form';

const SUCCESS_MESSAGE = 'Order has been successfully updated.';
const UNABLE_TO_ADD_ITEMS_MESSAGE =
  'Items can no longer be added to this order. If you need additional items picked, please book an additional order.';

export const PickupInventory: React.FC<RouteComponentProps<{ orderID: string }>> = ({
  match: {
    params: { orderID },
  },
  history,
}) => {
  const onSave = (flash: IFlash) => history.push(orderURL(orderID), { flash });
  const [error, setError] = useState<string | undefined | null>(undefined);
  const { data } = useOrderFormPickupInventoryQueryQuery({ variables: { orderID }, fetchPolicy: 'no-cache' });
  const [execute, { loading }] = useUpdatePickupInventoryMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: OrderDetailsDocument, variables: { orderID } }],
  });

  const onSubmit = async (input: IPickupInventoryInput) => {
    setError(undefined);
    const pickup = order.services.find(({ type }) => type === OrderTypeEnum.Pickup);
    if (!pickup) {
      return;
    }
    const response = await execute({ variables: { orderID: pickup.id, input: input as PickupInventoryInput } });
    if (response.data?.updatePickupInventory?.status === Status.Ok) {
      const { updatePickupInventory } = response.data;
      if (updatePickupInventory.message) {
        onSave({
          kind: FlashKind.Warning,
          message: updatePickupInventory.message,
        });
      } else {
        onSave({
          kind: FlashKind.Success,
          message: SUCCESS_MESSAGE,
        });
      }
    } else {
      setError('There was an error saving your selections.');
    }
  };

  if (!data) {
    return null;
  }

  const { order, ...config } = data;

  if (order.permissions.locked) {
    history.push(orderURL(orderID), {
      flash: {
        kind: FlashKind.Danger,
        message: UNABLE_TO_ADD_ITEMS_MESSAGE,
      },
    });
  }

  const service = order.services.find(({ type }) => type === OrderTypeEnum.Pickup);

  if (!service) {
    return <Redirect to={orderURL(orderID)} />;
  }

  return (
    <Container breadcrumb="Storage Inventory" orderID={order.id} scheduled={order.scheduled} error={error}>
      <OrderPickupInventoryForm {...config} loading={loading} order={order} service={service} onSubmit={onSubmit} />
    </Container>
  );
};
