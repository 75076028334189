import { COLORS } from '@clutter/clean';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const removeTopBorder = css`
  tr:first-of-type {
    td {
      border-top: none;
    }
  }
`;

const removeBottomBorder = css`
  tr:last-child {
    td {
      border-bottom: none;
    }
  }
`;

export const Table = styled.table<{ removeTopBorder?: boolean; removeBottomBorder?: boolean }>`
  width: 100%;
  td {
    border-top: 1px solid ${COLORS.grayBorder};
    border-bottom: 1px solid ${COLORS.grayBorder};
    padding: 16px 0;
    min-width: 64px;
    vertical-align: top;
    &:last-child {
      text-align: right;
    }
  }
  ${(props) => !!props.removeTopBorder && removeTopBorder}
  ${(props) => !!props.removeBottomBorder && removeBottomBorder}
`;
