import { invoiceURL } from '@portal/config/routes';
import { Box, Text, TextButton } from '@clutter/clean';
import { DateTime } from 'luxon';
import React from 'react';
import { Link } from 'react-router-dom';

import { CreditCard } from '@portal/components/shared/icons/credit_card';
import { currency } from '@shared/utils/currency';

import { IHistoryLine, Line, StatusText } from './line';
import { ITransaction, TransactionKind, TransactionStatus } from '../transaction';

const description = (action: string, date: DateTime) => `${action} on ${date.toLocaleString(DateTime.DATE_FULL)}`;

export const TransactionHistory: React.FC<{
  transactions: ITransaction[];
  invoiceID: string;
  rebillable?: boolean;
}> = ({ transactions, invoiceID, rebillable }) => {
  const lines: IHistoryLine[] = [];
  transactions.reverse();
  for (const transaction of transactions) {
    let status;
    let subtitle;
    if (transaction.kind === TransactionKind.Refund) {
      status = StatusText.Refund;
      subtitle = `Refunded ${currency(transaction.amount)}`;
    } else if (transaction.status === TransactionStatus.Successful) {
      status = StatusText.Paid;
      subtitle = `Paid ${currency(transaction.amount)}`;
    } else {
      status = StatusText.Failed;
      subtitle = 'Payment failed';
    }

    lines.push({
      title: transaction.summary,
      subtitle: description(subtitle, transaction.date),
      date: transaction.date,
      status: status,
      sourceBrand: transaction.sourceBrand,
      icon: () => <CreditCard brand={transaction.sourceBrand} />,
    });
  }

  return (
    <div>
      <Box.Flex justifyContent="space-between">
        <Text.Title size="medium">Payments</Text.Title>
        {rebillable && (
          <Box margin="14px 16px">
            <Link to={invoiceURL(invoiceID, 'switch_card')}>
              <TextButton>Switch Card</TextButton>
            </Link>
          </Box>
        )}
      </Box.Flex>
      {lines.map((line, idx) => (
        <Line key={idx} line={line} />
      ))}
    </div>
  );
};
