import * as React from 'react';

import styled from '@emotion/styled';

import { OrderTypeEnum } from '@portal/schema';
import { Radio, Text } from '@clutter/clean';
import { Base } from './base';
import { IStepProps } from '../form';

const Header = styled(Text.Title)`
  margin-bottom: 32px;
`;

export const Type: React.FC<
  {
    type?: OrderTypeEnum;
    onlyAllowReturns: boolean;
    onChange(kind: OrderTypeEnum): void;
    onNext?(): void;
    onPrev?(): void;
    loading?: boolean;
  } & IStepProps
> = ({ type, onlyAllowReturns, onChange, onNext, onPrev, loading }) => (
  <Base loading={loading} valid={!!type} onNext={onNext} onPrev={onPrev}>
    <Header size="large">What can we help you with?</Header>
    <Radio.Selector<OrderTypeEnum>
      name="type"
      onChange={onChange}
      value={type}
      options={
        onlyAllowReturns
          ? [{ value: OrderTypeEnum.Return, label: `I’m removing items from storage` }]
          : [
              { value: OrderTypeEnum.Pickup, label: `I’m adding items to storage` },
              { value: OrderTypeEnum.Return, label: `I’m removing items from storage` },
              { value: OrderTypeEnum.PickupReturn, label: `Both` },
            ]
      }
    />
  </Base>
);
