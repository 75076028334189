import React from 'react';
import { Box, COLORS, Text, TextButton, UnstyledButton } from '@clutter/clean';
import { Minus, Plus } from '@portal/components/shared/icons/estimation';
import { Estimation__CategoryType } from '@portal/schema';
import styled from '@emotion/styled';

export const Selectable = styled(UnstyledButton)<{ color?: string }>`
  color: ${({ color }) => color};

  &:hover {
    cursor: pointer;
  }
`;

export const CounterContainer = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
  border: 1px solid ${COLORS.grayBorder};
  overflow: hidden;
  padding: 0 8px;
`;

export const CounterCard: React.FC<{
  item: Estimation__CategoryType;
  count: number;
  onChange(newCount: number | null): void;
}> = ({ item, count, onChange }) => {
  const itemName = item.name;

  return (
    <CounterContainer>
      <Box.Flex width="100%" alignItems="center" justifyContent="space-between" padding="4px 0">
        <Text.Callout>
          <b>{itemName}</b>
        </Text.Callout>
        <Box.Flex alignItems="center" margin="4px 0" gap="4px">
          {count === 0 && (
            <Box margin="0 8px 0 0">
              <TextButton onClick={() => onChange(null)} kind="destructive">
                Remove
              </TextButton>
            </Box>
          )}
          <Selectable onClick={() => onChange(count > 0 ? count - 1 : null)}>
            <Minus fillColor={count === 0 ? COLORS.hippo : COLORS.tealPrimary} iconColor={COLORS.cloud} />
          </Selectable>
          <Box margin="0 4px" minWidth="32px" padding="4px 0 0" textAlign="center">
            <Text.Title size="extraSmall">{count}</Text.Title>
          </Box>
          <Selectable onClick={() => onChange(count + 1)}>
            <Plus fillColor={COLORS.tealPrimary} iconColor={COLORS.cloud} strokeColor={COLORS.tealPrimary} />
          </Selectable>
        </Box.Flex>
      </Box.Flex>
    </CounterContainer>
  );
};
