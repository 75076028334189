import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Page } from '@portal/components/helpers/page';
import { claimURL } from '@portal/config/routes';
import {
  AttachmentFragment,
  ClaimStatus,
  DetailedClaimFragment,
  useLatestProtectionSubscriptionQuery,
} from '@portal/schema';

import { Modal, Text } from '@shared/components/bootstrap';

import { Digest } from '../digest';
import { Attachments } from '../helpers/attachments';
import { Status } from '../helpers/status';

import { Accepted } from './accepted';
import { Approved } from './approved';
import { ApprovedFAQ, ReviewFAQ } from './faq';
import { IssueSelectionDetails } from './issue_selection_details';
import { ItemSelectionTable } from './item_selection_table';
import { OfferSection } from './offer_section';
import { PropertySelectionTable } from './property_selection_table';

interface IViewProps {
  claim: DetailedClaimFragment;
  thanks?: boolean;
}

export const View = ({ claim, thanks }: IViewProps) => {
  const { data: subscriptionData } = useLatestProtectionSubscriptionQuery();
  const [redirectToReviewPage, setRedirectToReviewPage] = useState<boolean>();
  const [openAttachments, setOpenAttachments] = useState<AttachmentFragment[]>([]);
  const [openThanksAlert, setOpenThanksAlert] = useState<boolean>(false);

  const {
    openedAt,
    uuid,
    status,
    hasIssue,
    hasDamagedItem,
    hasLostItem,
    hasDamagedProperty,
    issueSelection,
    itemSelections,
    propertySelection,
    offer,
  } = claim;

  useEffect(() => {
    setOpenThanksAlert(!!thanks);
  }, [thanks]);

  if (redirectToReviewPage) {
    return <Redirect to={claimURL(claim.uuid, 'review')} />;
  }

  return (
    <Page>
      {openThanksAlert && (
        <div className="alert-dismissible alert-success alert">
          Your claim has been successfully received
          <button type="button" className="close" onClick={() => setOpenThanksAlert(false)}>
            ×
          </button>
        </div>
      )}
      {openAttachments && !!openAttachments.length && (
        <Modal size={Modal.Size.Large} onClose={() => setOpenAttachments([])}>
          <Modal.Content>
            <Modal.Body>
              <Attachments attachments={openAttachments} />
            </Modal.Body>
          </Modal.Content>
        </Modal>
      )}
      <Page.Header>
        <div className="row">
          <div className="col col-6 col-sm-8 col-lg-9">
            <Page.Title>
              <div style={{ textTransform: 'uppercase' }}>
                <span className="d-none d-sm-inline d-lg-inline">Claim:</span> <Digest uuid={uuid} />
              </div>
            </Page.Title>
          </div>
        </div>
      </Page.Header>
      <Page.Section>
        <div className="row">
          <div className="col-md-4">
            <Text weight="bold">Opened:</Text>
            <br />
            <Text tag="div" wrapping="truncate">
              {openedAt ? moment(openedAt).format('MMMM D, YYYY') : '-'}
            </Text>
          </div>
          <div className="col-md-4">
            <Text weight="bold">Status:</Text>
            <br />
            <Text tag="div" wrapping="truncate">
              <Status status={status} />
            </Text>
          </div>
          <div className="col-md-4">
            {!hasIssue && (
              <>
                <Text weight="bold">Protection Plan:</Text>
                <br />
                <Text tag="div" wrapping="truncate">
                  {subscriptionData && subscriptionData.account
                    ? subscriptionData.account.latestProtectionSubscription
                      ? subscriptionData.account.latestProtectionSubscription.name
                      : 'N/A'
                    : '-'}
                </Text>
              </>
            )}
          </div>
        </div>
        <hr style={{ margin: '2rem 0' }} />

        {!!offer && (
          <>
            {status !== ClaimStatus.InReview && (
              <OfferSection
                offer={offer}
                showExpiry={status === ClaimStatus.Approved}
                showCompensation={status === ClaimStatus.Approved || status === ClaimStatus.Accepted}
              />
            )}
            {status === ClaimStatus.InReview && (
              <Text tag="div" wrapping="wrap">
                Your claim is currently under review. You will receive an email within 3-5 business days with next
                steps.
              </Text>
            )}
            {status === ClaimStatus.Approved && (
              <Approved claim={claim} setRedirectToReviewPage={setRedirectToReviewPage} />
            )}
            {status === ClaimStatus.Accepted && <Accepted claim={claim} />}
          </>
        )}

        {hasIssue && issueSelection && (
          <>
            <span className="claim-view__section-header">Details</span>
            <IssueSelectionDetails issueSelection={issueSelection} setOpenAttachments={setOpenAttachments} />
          </>
        )}

        {(hasDamagedItem || hasLostItem) && (
          <>
            <span className="claim-view__section-header">Item Details</span>
            <ItemSelectionTable itemSelections={itemSelections} setOpenAttachments={setOpenAttachments} />
          </>
        )}

        {hasDamagedProperty && propertySelection && (
          <>
            <span className="claim-view__section-header">Property Details</span>
            <PropertySelectionTable propertySelection={propertySelection} setOpenAttachments={setOpenAttachments} />
          </>
        )}

        {!offer || status === ClaimStatus.InReview ? (
          <ReviewFAQ hasIssue={hasIssue} />
        ) : (
          <ApprovedFAQ hasIssue={claim.hasIssue} />
        )}
      </Page.Section>
    </Page>
  );
};
