import * as React from 'react';

import { Spacing, Text } from '@shared/components/bootstrap';

import { useInventoryForSave } from './use_inventory_for_save';

export const PackingSummary: React.FC = () => {
  const inventory = useInventoryForSave();

  if (inventory.needsPackingHelp !== undefined) {
    return (
      <Spacing pb={3}>
        <Text tag="p" style="info">
          <strong>Do you need help packing any boxes?</strong>
        </Text>
        <Text tag="p">
          <strong>{inventory.needsPackingHelp ? 'Yes' : 'No'}</strong>
        </Text>
      </Spacing>
    );
  }
  return null;
};
