import { Button, COLORS, Text, TextButton } from '@clutter/clean';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { payURL } from '@portal/config/routes';
import {
  Billing__Source__FundingType as SourceFundingType,
  Maybe,
  SourceFragment,
  Status,
  useDefaultSourceMutation,
} from '@portal/schema';
import { Modal } from '@shared/components/bootstrap';

import { BaseModal } from './base_modal';

const StyledLink = styled(Link)`
  color: ${COLORS.tealPrimary};
  &:hover,
  &:focus {
    color: ${COLORS.tealBrand};
  }
`;

const PrepaidErrorModal: React.FC<{ delinquent: boolean; onClose(): void }> = ({ delinquent, onClose }) => (
  <Modal onClose={onClose}>
    <BaseModal.Content>
      <BaseModal.Header>
        <BaseModal.Close onClose={onClose} />
        <BaseModal.Title size="large">Cannot be set as default</BaseModal.Title>
      </BaseModal.Header>
      <BaseModal.Body>
        <Text.Body>
          Prepaid cards can only be used to pay off a past due balance and cannot be added as a default payment method
          for your account.{' '}
          {delinquent && (
            <>
              <StyledLink to={payURL()}>Click here to pay off your past due balance</StyledLink> using a prepaid card.
            </>
          )}
        </Text.Body>
      </BaseModal.Body>
      <BaseModal.Buttons>
        <TextButton size="medium" onClick={onClose}>
          Cancel
        </TextButton>
      </BaseModal.Buttons>
    </BaseModal.Content>
  </Modal>
);

export const DefaultSourceDialog: React.FC<{
  source: SourceFragment;
  delinquent: boolean;
  onClose(): void;
  onDefault(): void;
}> = ({ source, delinquent, onClose, onDefault }) => {
  const [error, setError] = useState<Maybe<string>>();

  const [execute, { loading: saving }] = useDefaultSourceMutation();

  const save = async () => {
    const result = await execute({ variables: { id: source.id } });
    if (result?.data?.defaultSource?.status === Status.Ok) {
      onDefault();
    } else {
      setError(result?.data?.defaultSource?.error);
    }
  };

  if (source.fundingType === SourceFundingType.Prepaid) {
    return <PrepaidErrorModal delinquent={delinquent} onClose={onClose} />;
  }
  return (
    <Modal onClose={onClose}>
      <BaseModal.Content>
        <BaseModal.Header>
          <BaseModal.Close onClose={onClose} />
          <BaseModal.Title size="large">Change default payment</BaseModal.Title>
        </BaseModal.Header>
        <BaseModal.Body>
          <Text.Body>
            Are you sure you want to change your default payment method to <strong>****{source.number}?</strong>
          </Text.Body>
          {error && <BaseModal.Error>{error}</BaseModal.Error>}
        </BaseModal.Body>
        <BaseModal.Buttons>
          <Button fullWidth kind="primary" loading={saving} disabled={saving} onClick={save}>
            Change
          </Button>
          <TextButton size="medium" onClick={onClose}>
            Cancel
          </TextButton>
        </BaseModal.Buttons>
      </BaseModal.Content>
    </Modal>
  );
};
