import React from 'react';
import { TextButton as CleanTextButton, Tooltip } from '@clutter/clean';
import { L1_AGENT_CUSTOMER_ONLY_ERROR_MESSAGE, BLOCK_ACTION } from './util';

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: (children: React.ReactElement) => React.ReactElement;
  children: React.ReactElement;
}) => (condition ? wrapper(children) : children);

export const TextButton: React.FC<React.ComponentProps<typeof CleanTextButton>> = ({ children, ...props }) => {
  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!BLOCK_ACTION) {
      props.onClick?.(e);
    }
  };
  return (
    <ConditionalWrapper
      condition={BLOCK_ACTION}
      wrapper={(c) => (
        <Tooltip arrow text={L1_AGENT_CUSTOMER_ONLY_ERROR_MESSAGE}>
          {c}
        </Tooltip>
      )}
    >
      <>
        <CleanTextButton {...props} onClick={onButtonClick}>
          {children}
        </CleanTextButton>
      </>
    </ConditionalWrapper>
  );
};
