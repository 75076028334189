import { Button, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';

import { Modal } from '@shared/components/bootstrap';
import { billingURL } from '@portal/config/routes';

const ModalTitle = styled(Text.Title)`
  text-align: center;
`;

const TextBody = styled(Text.Body)`
  text-align: center;
  margin-top: 12px;
  margin-bottom: 24px;
`;

export const SuccessModal: React.FC = () => (
  <Modal backdrop="static" centered onClose={() => null}>
    <Modal.Content>
      <Modal.Body>
        <ModalTitle size="large">Your payment was successful!</ModalTitle>
        <TextBody>
          The card you used has been made the default payment method. If you wish to have a different card as the
          default payment method, you may change it on the Billing page.
        </TextBody>
        <Link to={billingURL()}>
          <Button fullWidth kind="primary">
            Go to Billing
          </Button>
        </Link>
      </Modal.Body>
    </Modal.Content>
  </Modal>
);
