import { Button } from '@clutter/clean';
import React, { useState } from 'react';

import {
  Account__CancelIntent__Reason as CancelIntentReason,
  Maybe,
  Status,
  useBuildAccountCancelIntentMutation,
} from '@portal/schema';
import { Modal } from '@shared/components/bootstrap';

import { Options as AccountCancelReasonOptions } from '../account_cancel_reason/options';

export const AccountCancelReasonModal: React.FC<{
  orderID: string;
  onClose(): void;
  onSubmitted(): void;
}> = ({ orderID, onClose, onSubmitted }) => {
  const [error, setError] = useState<Maybe<string>>();
  const [reason, setReason] = useState<CancelIntentReason>();
  const [buildCancelIntent, { loading }] = useBuildAccountCancelIntentMutation();

  const onSubmit = async () => {
    setError(undefined);
    if (reason) {
      const response = await buildCancelIntent({ variables: { reason, orderID } });
      if (response.data?.build?.status === Status.Ok) {
        onSubmitted();
      } else {
        setError(response.data?.build?.error);
      }
    } else {
      setError('Please select one of the reasons below.');
    }
  };

  return (
    <Modal onClose={onClose} keyboard={false} backdrop="static">
      <Modal.Content>
        <Modal.Header>
          <Modal.Close close={onClose} disabled={loading} />
        </Modal.Header>
        <Modal.Body>
          <AccountCancelReasonOptions error={error} reason={reason} setReason={setReason} />
          <Button
            className="mt-3"
            kind="primary"
            size="medium"
            disabled={loading || !reason}
            loading={loading}
            onClick={onSubmit}
            fullWidth
          >
            Confirm
          </Button>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
