import React from 'react';

import { OrderSubtypeEnum, OrderTypeEnum } from '@portal/schema';
import { OrderDetailsOrder } from '@portal/components/orders/cancel/steps';
import { OnboardingReasons } from './onboarding';
import { MovingReasons } from './moving';

type ReasonsProps = {
  order: OrderDetailsOrder;
  orderType: OrderTypeEnum;
  orderSubtype: OrderSubtypeEnum;
};

export const Reasons = ({ order, orderType, orderSubtype }: ReasonsProps) => {
  if (orderSubtype === OrderSubtypeEnum.Onboarding) {
    return <OnboardingReasons order={order} />;
  } else if (orderType === OrderTypeEnum.Move) {
    return <MovingReasons order={order} />;
  }
  throw new Error('Cancellation page should only be loaded for an onboarding or move');
};
