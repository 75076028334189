import React from 'react';

export type AriaAttributes = {
  title: string;
  description: string;
};

export const SVGAriaEnhancements = (title: string, description: string) => {
  const titleId = `${title.toLocaleLowerCase().split(' ').join('-')}-title`;
  const descId = `${description.toLocaleLowerCase().split(' ').join('-')}-desc`;

  return {
    Title: () => <title id={titleId}>{title}</title>,
    Desc: () => <desc id={descId}>{description}</desc>,
    ariaLabelledby: `${titleId} ${descId}`,
  };
};
