import React, { useEffect, useState } from 'react';

import { Pagination } from '@portal/schema';
import { useIntersectionObserver } from '@shared/hooks/use_intersection_observer';
import { useLatestCallback } from '@shared/hooks/use_latest';
import { Box } from '@clutter/clean';

type PaginatedType<T> = {
  results: T[];
  pagination: Pagination;
};

export function InfiniteScrollContainer<T>({
  data,
  pageSize = 48,
  className,
  fetchMore,
  children,
}: {
  data: PaginatedType<T>;
  pageSize?: number;
  className?: string;
  fetchMore: (page: number) => Promise<unknown>;
  children: (data: { items: T[]; placeholders: number }) => React.ReactNode;
}) {
  const [page, setPage] = useState(1);
  const [inFlight, setInFlight] = useState(0);
  const [triggerRef, entry] = useIntersectionObserver();

  const items = data?.results || [];
  const totalCount = data?.pagination.totalCount;
  const currentCount = items.length;
  const pendingItems = inFlight * pageSize;

  const fetch = useLatestCallback(async () => {
    setPage((cur) => cur + 1);
    setInFlight((cur) => cur + 1);

    try {
      await fetchMore(page + 1);
    } finally {
      setInFlight((cur) => cur - 1);
    }
  });

  useEffect(() => {
    if (entry?.isIntersecting) fetch();
  }, [entry?.isIntersecting, fetch]);

  return (
    <Box padding="0 0 24px" className={className}>
      {children({ items, placeholders: Math.min(pendingItems, totalCount - currentCount) })}
      {page < totalCount / pageSize && <div ref={triggerRef} />}
    </Box>
  );
}
