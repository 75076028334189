import styled from '@emotion/styled';

export const CalendarNavigation = styled.button`
  width: 20px;
  height: 20px;
  border: none;
  color: #097e77;
  background-color: #ffffff;
  outline: none;

  &:disabled {
    color: #ccd2d9;
  }

  &:focus {
    outline: none;
  }
`;
