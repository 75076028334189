import styled from '@emotion/styled';
import React from 'react';

import { AnchorButton } from '@portal/components/shared/button';
import { Container } from '@portal/components/shared/layout';
import { bodyStyle, Heading } from '@portal/components/shared/text';
import { loginURL } from '@portal/config/routes';

const Title = styled(Heading)`
  text-align: center;
  margin-bottom: 24px;
  @media (max-width: 600px) {
    text-align: left;
    margin-bottom: 16px;
  }
`;

const Subtitle = styled.p`
  ${bodyStyle}
  text-align: center;
  padding-bottom: 16px;
  margin: 0;
  @media (max-width: 600px) {
    text-align: left;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 470px;
`;

const LoginCTA = styled(AnchorButton)`
  margin-top: 32px;
  width: 100%;
  @media (max-width: 600px) {
    margin-top: 16px;
  }
`;

export const Success: React.FC<{ subtitle: string }> = ({ subtitle }) => (
  <Container hasNavbar={false}>
    <Title level={1} size="large">
      Check your inbox
    </Title>
    <Content>
      <Subtitle>{subtitle}</Subtitle>
      <LoginCTA kind="primary" size="medium" href={loginURL()}>
        Back to Login
      </LoginCTA>
    </Content>
  </Container>
);
