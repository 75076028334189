import { InventoryItemFragment, ItemStatus, OrderTypeEnum, useItemsQuery, useOrderClassifyQuery } from '@portal/schema';

export const useSubtype = (items: InventoryItemFragment[], type?: OrderTypeEnum) => {
  const { data: inventoryData } = useItemsQuery({
    variables: { status: ItemStatus.Viewable },
  });

  const selectedItemIDS = new Set(items.map(({ id }) => id));
  const cancelable =
    !!inventoryData?.items?.every(({ id }) => selectedItemIDS.has(id)) && type !== OrderTypeEnum.PickupReturn;

  const { data } = useOrderClassifyQuery({ variables: { type: type ?? OrderTypeEnum.Pickup, cancelable } });
  return data?.classify?.subtype;
};
