import React from 'react';

const ID = 'close';

export const Close: React.FC<{ title?: string; onClick?: () => void }> = ({ title = 'Close', ...props }) => (
  <svg
    aria-labelledby={ID}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title id={ID}>{title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6592 0.340805C16.1136 0.795212 16.1136 1.53195 15.6592 1.98636L9.64555 7.99999L15.6592 14.0136C16.1136 14.468 16.1136 15.2048 15.6592 15.6592C15.2048 16.1136 14.468 16.1136 14.0136 15.6592L7.99998 9.64556L1.98636 15.6592C1.53195 16.1136 0.795213 16.1136 0.340805 15.6592C-0.113602 15.2048 -0.113602 14.468 0.340805 14.0136L6.35444 7.99999L0.340805 1.98636C-0.113602 1.53195 -0.113602 0.795212 0.340805 0.340805C0.795213 -0.113601 1.53195 -0.113601 1.98636 0.340805L7.99998 6.35446L14.0136 0.340805C14.468 -0.113602 15.2048 -0.113602 15.6592 0.340805Z"
      fill="black"
    />
  </svg>
);
