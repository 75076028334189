import { AriaAttributes, SVGAriaEnhancements } from '@shared/components/helpers/svg_aria_enhancements';
import React, { FC } from 'react';

export const ProtectionPlanShield: FC<AriaAttributes> = ({ title, description: desc }) => {
  const Aria = SVGAriaEnhancements(title, desc);

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={Aria.ariaLabelledby}
    >
      <Aria.Title />
      <Aria.Desc />
      <g clipPath="url(#clip0_22_1545)">
        <path
          d="M34.6896 21.9772V27.8853H39.2873L37.4483 24.8278L39.2873 21.9772H34.6896Z"
          fill="#F79C94"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.28737 27.8853V21.9772H0.689667L2.52875 25.0347L0.689667 27.8853H5.28737Z"
          fill="#F79C94"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.44827 23.8163H5.88505H36.5287V29.7243H3.44827V23.8163Z"
          fill="#00404D"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5517 33.9404C6.85057 29.6646 3.44827 22.86 3.44827 15.7335V7.43469C3.44827 6.74963 4.17471 6.30365 4.7862 6.6163L7.5862 8.04158C10.4828 8.04158 13.2092 6.91515 15.2552 4.86457L19.0253 1.09446C19.5632 0.556527 20.4368 0.556527 20.9747 1.09446L24.7448 4.86457C26.7954 6.91515 29.5172 8.04158 32.4138 8.04158L35.2138 6.6163C35.8253 6.30365 36.5517 6.74963 36.5517 7.43469V15.7335C36.5517 22.8968 33.177 29.6416 27.4483 33.9404L20 39.5266L12.5517 33.9404Z"
          fill="#A1E6E0"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 34.3542L15.0345 30.6301C10.3448 27.1128 7.58621 21.5956 7.58621 15.7335V12.1749C11.5586 12.1749 15.3701 10.5979 18.1839 7.78411L20 5.96802L21.8161 7.78411C24.6253 10.5933 28.4368 12.1749 32.4138 12.1749V15.7335C32.4138 21.5956 29.6552 27.1128 24.9655 30.6301L20 34.3542Z"
          fill="#00A499"
          stroke="#00A499"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.508 30.0002C11.0437 26.6485 8.37701 21.3152 8.37701 15.7335V12.9473C12.2943 12.7542 15.9494 11.1404 18.7402 8.34504L20 7.08527L21.2598 8.34504C24.0506 11.1358 27.7057 12.7542 31.623 12.9473V15.7335C31.623 21.3152 28.9563 26.6485 24.492 30.0002L20 33.3703L15.508 30.0002Z"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.4138 15.7335V12.1749C28.4414 12.1749 24.6299 10.5979 21.8161 7.78411L20 5.96802L18.1839 7.78411C15.3747 10.5933 11.5632 12.1749 7.58621 12.1749V15.7335"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3287 39.5266H28.6713"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.1816 19.6738C30.2483 23.7565 27.8942 27.4485 24.4965 30.0002L20.0046 33.3703L15.5126 30.0002C12.1149 27.4531 9.76091 23.7565 8.82758 19.6738"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.3724 11.7243L21.177 13.3519L22.9747 13.614C23.3149 13.6646 23.4529 14.083 23.2046 14.322L21.9034 15.5864L22.2115 17.3749C22.2713 17.7151 21.9126 17.9726 21.6092 17.8117L20.0046 16.9657L18.4 17.8117C18.0965 17.9726 17.7379 17.7151 17.7977 17.3749L18.1057 15.5864L16.8046 14.322C16.5563 14.083 16.6942 13.6646 17.0345 13.614L18.8322 13.3519L19.6368 11.7243C19.7793 11.4163 20.2207 11.4163 20.3724 11.7243Z"
          fill="white"
        />
        <path
          d="M23.2 14.322L21.8988 15.5864L22.2069 17.3749C22.2667 17.7151 21.908 17.9726 21.6046 17.8117L20 16.9657L18.3954 17.8117C18.0919 17.9726 17.7333 17.7151 17.7931 17.3749L18.1011 15.5864L16.8 14.322"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.9356 18.5152L16.7402 20.1427L18.5379 20.4048C18.8782 20.4554 19.0161 20.8738 18.7678 21.1129L17.4667 22.3772L17.7747 24.1657C17.8345 24.506 17.4759 24.7634 17.1724 24.6025L15.5678 23.7565L13.9632 24.6025C13.6598 24.7634 13.3012 24.506 13.3609 24.1657L13.669 22.3772L12.3678 21.1129C12.1195 20.8738 12.2575 20.4554 12.5977 20.4048L14.3954 20.1427L15.2 18.5152C15.3425 18.2071 15.7839 18.2071 15.9356 18.5152Z"
          fill="white"
        />
        <path
          d="M18.7632 21.1129L17.4621 22.3772L17.7701 24.1657C17.8299 24.506 17.4713 24.7634 17.1678 24.6025L15.5632 23.7565L13.9586 24.6025C13.6552 24.7634 13.2966 24.506 13.3563 24.1657L13.6644 22.3772L12.3632 21.1129"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.8092 18.5152L25.6138 20.1427L27.4115 20.4048C27.7517 20.4554 27.8897 20.8738 27.6414 21.1129L26.3402 22.3772L26.6483 24.1657C26.7081 24.506 26.3494 24.7634 26.046 24.6025L24.4414 23.7565L22.8368 24.6025C22.5333 24.7634 22.1747 24.506 22.2345 24.1657L22.5425 22.3772L21.2414 21.1129C20.9931 20.8738 21.131 20.4554 21.4713 20.4048L23.269 20.1427L24.0736 18.5152C24.2161 18.2071 24.6575 18.2071 24.8092 18.5152Z"
          fill="white"
        />
        <path
          d="M27.6368 21.1129L26.3356 22.3772L26.6437 24.1657C26.7035 24.506 26.3448 24.7634 26.0414 24.6025L24.4368 23.7565L22.8322 24.6025C22.5287 24.7634 22.1701 24.506 22.2299 24.1657L22.5379 22.3772L21.2368 21.1129"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.5517 15.7335C36.5517 22.8968 33.177 29.6416 27.4483 33.9404L20 39.5266L12.5517 33.9404C6.85057 29.6646 3.44827 22.86 3.44827 15.7335"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.48276 27.0623C8.0046 29.7152 10.0598 32.0738 12.5517 33.9404L12.8736 34.1841C14.846 34.391 17.1954 34.5198 20 34.5198C22.8046 34.5198 25.154 34.391 27.1264 34.1841L27.4483 33.9404C29.9586 32.0554 32.0184 29.7014 33.5402 27.0485C31.1172 27.8117 26.9379 28.6071 20 28.6071C13.0943 28.6071 8.91494 27.8209 6.48276 27.0623Z"
          fill="#00404D"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.092 15.7335C36.092 22.7128 32.7586 29.3841 27.1724 33.5726L20 38.9519L12.8276 33.5726C7.24138 29.3841 3.90805 22.7174 3.90805 15.7335"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.44827 23.8163C3.44827 23.8163 6.61149 26.7726 20 26.7726C33.3885 26.7726 36.5287 23.8163 36.5287 23.8163V29.7289C36.5287 29.7289 33.3885 32.6853 20 32.6853C6.61149 32.6853 3.44827 29.7289 3.44827 29.7289V23.8163Z"
          fill="#F79C94"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.5287 23.8163V29.7289C36.5287 29.7289 33.3885 32.6853 20 32.6853C6.61149 32.6853 3.44827 29.7289 3.44827 29.7289V23.8163"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.90805 15.7333V7.43449C3.90805 7.14943 4.14713 6.97472 4.36782 6.97472C4.43679 6.97472 4.51035 6.99311 4.57472 7.02529L7.47587 8.49656H7.58621C10.6069 8.49656 13.4437 7.31954 15.5816 5.18621L19.3517 1.4161C19.5264 1.24138 19.7563 1.14943 20 1.14943C20.2437 1.14943 20.4782 1.24598 20.6483 1.4161L24.4184 5.18621C26.5517 7.31954 29.3931 8.49656 32.4138 8.49656H32.5241L35.4253 7.02529C35.4897 6.99311 35.5632 6.97472 35.6322 6.97472C35.8529 6.97472 36.092 7.14943 36.092 7.43449V15.7333"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_1545">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
