import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Container } from '@portal/components/shared/layout';
import { Heading } from '@portal/components/shared/text';

import { PasswordSuccess } from '../password_success';
import { PasswordEditForm } from './form';

const Title = styled(Heading)`
  text-align: center;
  margin-bottom: 32px;
  @media (max-width: 600px) {
    text-align: left;
    margin-bottom: 16px;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 470px;
`;

export const Edit: React.FC<{ token: string }> = ({ token }) => {
  const [edited, setEdited] = useState<boolean>(false);

  if (edited) {
    return <PasswordSuccess title="Password updated" link="/" />;
  }

  return (
    <Container hasNavbar={false}>
      <Title level={1} size="large">
        Choose your new password:
      </Title>
      <Content>
        <PasswordEditForm token={token} onEdit={() => setEdited(true)} />
      </Content>
    </Container>
  );
};
Edit.displayName = 'EditPassword';
