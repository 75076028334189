import { Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Rating } from '@portal/schema';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Text.Title)`
  margin: 32px 0;
  text-align: center;
`;

const GOOD_RATING = 'Your feedback has been recorded! Thank you for choosing Clutter.';
const BAD_RATING =
  'Thank you for your feedback. Your satisfaction is our top priority. If further assistance is required, please contact your dedicated account specialist.';

export const ReviewSubmitted: React.FC<{
  rating: Rating;
}> = ({ rating }) => (
  <Container>
    {rating === Rating.Good && <Title size="medium">{GOOD_RATING}</Title>}
    {rating === Rating.Poor && <Title size="small">{BAD_RATING}</Title>}
  </Container>
);
