import React from 'react';

import { Accordion } from '@clutter/clean';
import { wt } from '@portal/initializers/wt';
import { Pricing__Set__AppointmentFees as AppointmentFees } from '@portal/schema';

import { AccordionLabel } from '../accordion_label';
import { Option } from './option';
import { Options } from '../options';
import {
  accordionMetadata,
  formattedServiceType,
  formatFees,
  PLAN_UPDATE_PAGE_NAME,
  serviceChanged,
  sumPackageSetEntries,
} from '../util';

export const Selector: React.FC<{
  orderID: string;
  loading: boolean;
  initialAppointmentFees?: AppointmentFees;
  selectedAppointmentFees?: AppointmentFees;
  options: AppointmentFees[];
  onOrderServiceChange(appointmentFees: AppointmentFees): void;
}> = ({ orderID, loading, initialAppointmentFees, selectedAppointmentFees, options, onOrderServiceChange }) => {
  if (!initialAppointmentFees || !selectedAppointmentFees) {
    return null;
  }

  const sortedOptions = [...options].sort(
    (a, b) => sumPackageSetEntries(a.packageSetEntries) - sumPackageSetEntries(b.packageSetEntries),
  );

  const onToggle = (isOpen: boolean) => {
    const params = {
      ...accordionMetadata(isOpen),
      container: 'service_type_accordion',
      order_id: orderID,
    };
    wt.track(params);
  };

  const onSelect = (currentAppointmentFees: AppointmentFees, label: string) => {
    const params = {
      container: 'servive_type_accordion',
      previous_selection: selectedAppointmentFees.serviceType,
      current_selection: currentAppointmentFees.serviceType,
      action: 'click',
      label: label,
      pageName: PLAN_UPDATE_PAGE_NAME,
      objectType: 'card',
      objectName: 'service_type',
      order_id: orderID,
    };
    wt.track(params);
    onOrderServiceChange(currentAppointmentFees);
  };

  return (
    <Accordion.Item
      name="service"
      label={
        <AccordionLabel
          loading={loading}
          title={formattedServiceType(selectedAppointmentFees.serviceType)}
          value={formatFees(selectedAppointmentFees)}
          changed={serviceChanged(selectedAppointmentFees, initialAppointmentFees)}
        />
      }
      onToggle={onToggle}
    >
      <Options.Container>
        {sortedOptions.map((option) => (
          <Option
            key={option.serviceType}
            option={option}
            selected={option.serviceType === selectedAppointmentFees.serviceType}
            onSelect={(label) => onSelect(option, label)}
          />
        ))}
      </Options.Container>
    </Accordion.Item>
  );
};
