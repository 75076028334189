import React, { useState } from 'react';
import { Box, ButtonLink, COLORS, Icon, Select, Text, mq } from '@clutter/clean';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';

import { orderURL } from '@portal/config/routes';
import { OrdersQuery, useOrdersQuery } from '@portal/schema';
import { orderDisplayName } from '@portal/utils/order';

type Order = OrdersQuery['orders'][0];

const formatOrder = (order: Order) =>
  `${orderDisplayName(order)} - ${DateTime.fromISO(order.scheduled, { setZone: true }).toLocaleString(
    DateTime.DATE_FULL,
  )}`;

const Title = styled(Text.Title)`
  margin-bottom: 24px;
`;

const Body = styled(Text.Headline)`
  margin-bottom: 16px;
`;

const ContinueButton = styled(ButtonLink)`
  margin-left: 16px;
`;

const Background = styled(Box)`
  background-color: ${COLORS.grayBackground};
  padding: 24px;
`;

const OrderSelect = styled(Select)`
  ${mq({
    minWidth: ['0px', '300px', '500px'],
  })}
`;

export const Request: React.FC = () => {
  const [order, setOrder] = useState<string | undefined>(undefined);
  const { data, loading } = useOrdersQuery({ variables: { onlyActive: true } });

  if (loading || !data) return null;

  const { orders } = data;
  const options = orders.map((o) => ({ value: o.id, label: formatOrder(o) }));

  return (
    <Box>
      <Title size="large">Request a Certificate of Insurance</Title>
      {orders.length === 0 && <Body>You have no upcoming orders.</Body>}
      {orders.length > 0 && (
        <Background>
          <Body>Which upcoming order do you need a Certificate of Insurance for?</Body>
          <Box.Flex>
            <OrderSelect
              name="order"
              placeholder="Select an order..."
              value={order}
              options={options}
              onChange={(value: any) => setOrder(value)}
            />
            <ContinueButton to={order ? orderURL(order, 'coi') : '#'}>
              <Icon.Arrow color={COLORS.cloud} />
            </ContinueButton>
          </Box.Flex>
        </Background>
      )}
    </Box>
  );
};
