import { Box, Button, FontWeight, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { TrackedClick } from '@portal/components/wt/tracked_click';
import {
  AccountResolution__OfferFragment,
  AccountResolution__OfferKind,
  Maybe,
  Status,
  useAccountResolutionOfferAcceptMutation,
  useAccountResolutionOfferRejectMutation,
} from '@portal/schema';
import { IEventParams } from '@portal/types/wt/event';

import { Summary as FinalMonthProrationSummary } from './final_month_proration/summary';
import { Summary as WaiveRateAdjustmentSummary } from './waive_rate_adjustment/summary';

const Title = styled(Text.Title)`
  padding-top: 16px;
  margin-bottom: 24px;
`;

const Body = styled(Text.Body)`
  margin-bottom: 28px;
`;

const StyledButton = styled(Button)`
  margin-bottom: 16px;
`;

const BUTTON_WT_PARAMS: IEventParams = {
  pageName: 'portal:help_offer',
  container: 'offer_actions',
  action: 'click',
  objectType: 'button',
};

const ACCEPT_OFFER_CTA = 'Yes, accept offer';
const REJECT_OFFER_CTA = 'No, I need another solution';

export const Extend: React.FC<{
  offer: AccountResolution__OfferFragment;
  reasonID: string;
  category: string;
  onAccept(newTicketID?: string): void;
  onReject(note?: Maybe<string>): void;
  onError(message: string): void;
}> = ({ offer, reasonID, category, onAccept, onReject, onError }) => {
  const [acceptOffer, { loading: accepting }] = useAccountResolutionOfferAcceptMutation();
  const [rejectOffer, { loading: rejecting }] = useAccountResolutionOfferRejectMutation();

  const onAcceptOffer = async () => {
    const response = await acceptOffer({
      variables: {
        offerID: offer.id,
        reasonID,
        category,
      },
    });
    if (response.data?.offerAccept?.status === Status.Ok) {
      onAccept(response.data.offerAccept.customerTicket?.id);
    } else {
      onError(
        'Unfortunately, we faced an issue processing your offer. Please submit a ticket and our team will review this issue.',
      );
    }
  };

  const onRejectOffer = async () => {
    const response = await rejectOffer({
      variables: {
        offerID: offer.id,
      },
    });
    // NOTE: skipping check for mutation status since the customer is rejecting the
    // offer and we don't want to block the customer if this mutation fails.
    onReject(response.data?.offerReject?.note);
  };

  return (
    <>
      <Title size="large">Here’s how we can help</Title>
      <Body>
        {offer.kind === AccountResolution__OfferKind.FinalMonthProration && (
          <FinalMonthProrationSummary offer={offer} />
        )}
        {offer.kind === AccountResolution__OfferKind.WaiveRateAdjustment && (
          <WaiveRateAdjustmentSummary offer={offer} />
        )}
      </Body>
      <Body weight={FontWeight.Medium}>Would you like to accept this offer?</Body>
      <Box maxWidth="348px">
        <TrackedClick
          params={{
            ...BUTTON_WT_PARAMS,
            offer_id: offer.id,
            objectName: 'accept_offer',
            label: ACCEPT_OFFER_CTA,
            position: 1,
          }}
        >
          <StyledButton
            kind="secondary"
            loading={accepting}
            disabled={accepting || rejecting}
            fullWidth
            onClick={onAcceptOffer}
          >
            {ACCEPT_OFFER_CTA}
          </StyledButton>
        </TrackedClick>
        <TrackedClick
          params={{
            ...BUTTON_WT_PARAMS,
            offer_id: offer.id,
            objectName: 'reject_offer',
            label: REJECT_OFFER_CTA,
            position: 2,
          }}
        >
          <StyledButton
            kind="secondary"
            loading={rejecting}
            disabled={accepting || rejecting}
            fullWidth
            onClick={onRejectOffer}
          >
            {REJECT_OFFER_CTA}
          </StyledButton>
        </TrackedClick>
      </Box>
    </>
  );
};
