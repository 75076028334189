import { Box, COLORS, FontWeight, SkeletonLoader, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

const Title = styled(Text.Body)`
  color: ${COLORS.panther};
`;

const Value = styled(Text.Body)<{ highlight?: boolean }>`
  color: ${({ highlight }) => (highlight ? COLORS.tealPrimary : COLORS.panther)};
  text-align: right;
`;

export const AccordionLabel: React.FC<{
  loading: boolean;
  title: string;
  value: string;
  changed: boolean;
  highlight?: boolean;
}> = ({ loading, title, value, changed, highlight }) => (
  <Box.Flex justifyContent="space-between" alignItems="center" width="100%">
    {loading ? (
      <>
        <SkeletonLoader height="34px" width="100px" />
        <SkeletonLoader height="34px" width="50px" />
      </>
    ) : (
      <>
        <Title weight={FontWeight.Medium}>{title}</Title>
        <Value weight={changed ? FontWeight.Medium : FontWeight.Regular} highlight={highlight}>
          {value}
        </Value>
      </>
    )}
  </Box.Flex>
);
