import React from 'react';
import { DateTime } from 'luxon';
import { Box, Button, COLORS, Text, UnstyledButton } from '@clutter/clean';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DateAvailability } from '@portal/components/helpers/scheduled_date_picker';
import { Spacer } from '@shared/components/helpers';

import { Header } from './header';

const formatDate = (date: DateTime) => date.toLocaleString(DateTime.DATE_MED);

const Waitlistable: React.FC<{
  date: DateTime;
  available: boolean;
  onWaitlist: boolean;
  waitlistedDates: DateTime[];
  rescheduling: boolean;
  onAddWaitlistDate(date: DateTime): void;
  onRemoveWaitlistDate(date: DateTime): void;
}> = ({ date, available, onWaitlist, waitlistedDates, rescheduling, onAddWaitlistDate, onRemoveWaitlistDate }) => {
  const hasWaitlistedDates = waitlistedDates.length > 0;

  return (
    <>
      <Spacer height="2rem" />
      {(!available || hasWaitlistedDates) && <Header tag="h5">Waitlist</Header>}
      {!available && !onWaitlist && (
        <>
          <Text.Body>
            <b>{formatDate(date)}</b> is full! But get on the waitlist and we'll be in touch if a spot opens up.
          </Text.Body>
          <Spacer height="1rem" />
          <Button type="button" onClick={() => onAddWaitlistDate(date)}>
            Add me to waitlist
          </Button>
        </>
      )}
      {waitlistedDates.length > 0 && (
        <WaitlistedDates dates={waitlistedDates} rescheduling={rescheduling} onDelete={onRemoveWaitlistDate} />
      )}
    </>
  );
};

export const RemoveButton = styled(UnstyledButton)`
  margin-left: 8px;
  color: ${COLORS.toucan};
`;

const WaitlistedDates: React.FC<{
  dates: DateTime[];
  rescheduling: boolean;
  onDelete(date: DateTime): void;
}> = ({ dates, rescheduling, onDelete }) => (
  <>
    <Spacer height="1rem" />
    <Text.Body>
      You requested to be on the waitlist for the dates below:
      <ul>
        {dates.map((date) => (
          <Box.Flex key={date.toISODate()}>
            <li>{formatDate(date)}</li>
            <RemoveButton aria-label="Delete" onClick={() => onDelete(date)}>
              <FontAwesomeIcon icon="times" />
            </RemoveButton>
          </Box.Flex>
        ))}
      </ul>
      {!rescheduling && (
        <>
          You can now continue with this booking to confirm your waitlist dates. Or if you want a guaranteed date as
          well, then you can select an available date from the calendar before continuing. You will not be removed from
          the waitlist so we will still notify you if any of your waitlisted dates open up.
        </>
      )}
    </Text.Body>
    <Spacer height="2rem" />
  </>
);

export const Waitlist: React.FC<{
  date: DateTime;
  indexedAvailabilities: Map<string, DateAvailability>;
  waitlistedDates: DateTime[];
  rescheduling: boolean;
  onAddWaitlistDate(date: DateTime): void;
  onRemoveWaitlistDate(date: DateTime): void;
}> = ({ date, indexedAvailabilities, waitlistedDates, rescheduling, onAddWaitlistDate, onRemoveWaitlistDate }) => {
  const onWaitlist = !!waitlistedDates.find((waitlistedDate) => waitlistedDate.toISODate() === date.toISODate());
  const available = !!indexedAvailabilities.get(date.toISODate())?.available;
  const blocked = !!indexedAvailabilities.get(date.toISODate())?.blocked;

  if (blocked && !rescheduling) return null;

  return (
    <Waitlistable
      date={date}
      available={available}
      onWaitlist={onWaitlist}
      waitlistedDates={waitlistedDates}
      rescheduling={rescheduling}
      onAddWaitlistDate={onAddWaitlistDate}
      onRemoveWaitlistDate={onRemoveWaitlistDate}
    />
  );
};
