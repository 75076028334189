import React, { useState } from 'react';
import styled from '@emotion/styled';

import { COLORS, FontWeight, Text, Fieldset, Radio, mq } from '@clutter/clean';

import { TealBox } from '@portal/components/shared/icons';
import { useEstimationStatusCreateMutation, Status as MutationStatus } from '@portal/schema';

import { StepType } from './data';
import { StepContainer } from '../step_container';

const Container = styled.div`
  max-width: 700px;
  margin: 25px auto 0;
`;

const BannerContainer = styled.div`
  background-color: ${COLORS.grayBackground};
  padding: 18px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  svg {
    margin-right: 26px;
  }
`;

const RadioContainer = styled(Fieldset)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 24px;
`;

const RadioOption = styled.label`
  ${mq({
    width: ['100%', 'calc(50% - 8px)'],
  })}
`;

const Card = styled.div<{ selected: boolean }>`
  width: 100%;
  height: 48px;
  border: 1.5px solid ${COLORS.tealPrimary};
  border-radius: 4px;
  color: ${({ selected }) => (selected ? COLORS.cloud : COLORS.tealPrimary)};
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
  background: ${({ selected }) => (selected ? COLORS.tealPrimary : COLORS.cloud)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Caption = styled(Text.Caption)`
  display: flex;
  justify-content: center;
`;

const statusOptions = [
  {
    id: 'incomplete',
    value: false,
    label: "No, I'll add the rest later.",
  },
  {
    id: 'complete',
    value: true,
    label: "Yes, I've entered in all my items.",
  },
];

export const Status: React.FC<StepType> = (props) => {
  const { onChange, orderID, next, setError } = props;

  const [completed, setCompleted] = useState<boolean | undefined>(undefined);

  const [estimationStatusCreateMutation, { loading }] = useEstimationStatusCreateMutation();

  return (
    <StepContainer
      {...props}
      headline="Is this a complete list of your items?"
      loading={loading}
      canNext={completed !== undefined}
      next={async () => {
        const { data } = await estimationStatusCreateMutation({
          variables: {
            input: {
              orderID: orderID,
              status: completed!,
            },
          },
        });
        if (data?.estimationStatusCreate?.status === MutationStatus.Ok) {
          onChange('estimationCompleted', completed, () => next());
        } else {
          setError(
            'Sorry, there was an issue while saving your information. If the problem persists, please contact Care for assistance.',
          );
        }
      }}
    >
      <Container>
        <BannerContainer>
          <TealBox />
          <Text.Callout weight={FontWeight.Regular}>
            <strong>Let us know if this is a complete list of everything you plan to store.</strong> This helps us plan
            accordingly to ensure we schedule enough time and movers to complete your appointment.
          </Text.Callout>
        </BannerContainer>
        <RadioContainer>
          {statusOptions.map((option) => (
            <RadioOption key={option.id}>
              <Radio.Input
                id={option.id}
                checked={option.value === completed}
                onChange={() => setCompleted(option.value)}
              />
              <Card selected={option.value === completed}>{option.label}</Card>
            </RadioOption>
          ))}
        </RadioContainer>
        <Caption weight={FontWeight.Regular} color={COLORS.storm}>
          Please complete your inventory list at least 24 hours before your appointment.
        </Caption>
      </Container>
    </StepContainer>
  );
};
