import { COLORS, FontWeight, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { NoEntry } from './icons/no_entry';
import { Warning } from './icons/warning';
import { captionStyle } from './text';

const HEIGHT = 80;
const ICON_CONTAINER_WIDTH = 64;

const Container = styled.div`
  width: 100%;
  min-height: ${HEIGHT}px;
  display: flex;
`;

const IconContainer = styled.div`
  width: ${ICON_CONTAINER_WIDTH}px;
  background-color: ${COLORS.toucan};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BannerMessage = styled.div`
  width: 100%;
  min-height: ${HEIGHT}px;
  border: 1px solid ${COLORS.toucan};
  display: flex;
  align-items: center;
`;

const BannerText = styled.div`
  ${captionStyle}
  margin: 9px 24px;

  a {
    color: ${COLORS.tealPrimary};
  }
`;

export const Banner: React.FC<{ className?: string }> = ({ className, children }) => (
  <Container className={className}>
    <IconContainer>
      <NoEntry />
    </IconContainer>
    <BannerMessage>
      <BannerText>{children}</BannerText>
    </BannerMessage>
  </Container>
);

const MessageContainer = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
`;

const MessageText = styled(Text.Callout)`
  color: ${COLORS.toucan};
`;

const MessageIcon = styled(Warning)`
  margin-right: 8px;
`;

export const Message: React.FC<{ message: string }> = ({ message }) => (
  <MessageContainer>
    <MessageIcon />
    <MessageText weight={FontWeight.Regular}>{message}</MessageText>
  </MessageContainer>
);
