import cn from 'classnames';
import React from 'react';

import { AttachmentFragment } from '@portal/schema';

interface IViewAttachments {
  attachments: AttachmentFragment[];
  isMobile?: boolean;
  setOpenAttachments(attachments: AttachmentFragment[]): void;
}

export const ViewAttachments = ({ attachments, setOpenAttachments, isMobile }: IViewAttachments) => (
  <>
    {attachments && !!attachments.length && (
      <span
        className={cn('link-style', isMobile && 'd-block d-md-none float-right')}
        onClick={() => setOpenAttachments(attachments)}
      >
        View {attachments.length} attachments
      </span>
    )}
  </>
);
