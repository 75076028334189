import { Box, COLORS, mq, Text, UnstyledButton } from '@clutter/clean';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import * as React from 'react';

import credit_card from '@portal/images/credit_card.svg';
import clock from '@portal/images/clock.svg';
import { orderURL } from '@portal/config/routes';
import { useHistory } from 'react-router';
import { wt } from '@portal/initializers/wt';
import { getOrdinalSuffix } from '@shared/utils';
import { AppointmentDetailsOrder } from './orders/home/appointment_details/full_panel';
import { BuildSourceDialog } from './sources/build_source_dialog';

enum ShotClockKind {
  VirtualWalkthrough = 'virtualWalkthrough',
  CreditCard = 'creditCard',
}

const Container = styled.div<{ kind: ShotClockKind }>`
  display: flex;
  justify-content: center;
  background: ${({ kind }) => (kind === ShotClockKind.VirtualWalkthrough ? COLORS.tealBrand : COLORS.flower)};

  ${mq({
    height: [null, null, '84px'],
    width: [null, null, '100vw'],
  })}
`;

const CountdownContainer = styled.div<{ kind: ShotClockKind }>`
  display: flex;
  background: ${({ kind }) =>
    kind === ShotClockKind.VirtualWalkthrough
      ? `linear-gradient(to bottom, ${COLORS.tealPrimary} 50%, ${COLORS.tealJungle} 50%)`
      : `linear-gradient(to bottom, #E67D73 50%, #E78E86 50%)`};
  align-items: center;
  justify-content: center;
  height: 84px;

  ${mq({ minWidth: [null, '300px'] })}
`;

const ContentContainer = styled.div`
  display: flex;
  padding: 16px 20px;
  justify-content: space-between;

  ${mq({
    flexDirection: ['column', null, 'row'],
  })}
`;

const Description = styled.div<{ kind: ShotClockKind }>`
  display: flex;
  color: ${({ kind }) => (kind === ShotClockKind.VirtualWalkthrough ? COLORS.cloud : COLORS.storm)};
  align-items: center;
  margin-right: 16px;
`;

const Button = styled.div<{ kind: ShotClockKind }>`
  display: flex;
  color: ${({ kind }) => (kind === ShotClockKind.VirtualWalkthrough ? COLORS.cloud : COLORS.tealPrimary)};
  align-items: center;
  white-space: nowrap;

  ${mq({
    marginTop: ['12px', null, '0'],
  })}
`;

const Icon = styled.img`
  height: 38px;
  margin-right: 8px;
`;

const TimeUnit: React.FC<{ value: number | string; units: string }> = ({ value, units }) => (
  <Box.Flex flexDirection="column" width="54px" textAlign="center" color={COLORS.cloud}>
    <Text.Title size="medium">{value}</Text.Title>
    <Box margin="-8px 0 0">
      <Text.Caption>{units}</Text.Caption>
    </Box>
  </Box.Flex>
);

const calculateRemainingTime = (cutoffTime: DateTime) => {
  const difference = cutoffTime.diffNow(['days', 'hours', 'minutes', 'seconds']);

  return difference.valueOf() > 0
    ? [
        { value: difference.days, units: 'Days' },
        { value: difference.hours, units: 'Hr' },
        { value: difference.minutes, units: 'Min' },
        { value: difference.seconds.toFixed(0), units: 'Sec' },
      ]
    : null;
};

const ShotClock: React.FC<{ order: AppointmentDetailsOrder; kind: ShotClockKind; onExpire(): void }> = ({
  order,
  kind,
  onExpire,
}) => {
  const history = useHistory();
  const [cutoffTime] = React.useState(() => DateTime.fromISO(order.scheduled).minus({ days: 2 }));
  const formattedDate = cutoffTime.toFormat('MMMM d') + getOrdinalSuffix(cutoffTime.day);

  const [remainingTime, setRemainingTime] = React.useState(calculateRemainingTime(cutoffTime));
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    const params = {
      pageName: 'portal:appointment_details',
      container: 'shot_clock_banner',
      action: 'view',
      objectType: 'banner',
      objectName:
        kind === ShotClockKind.VirtualWalkthrough ? 'virtual_walkthrough_shot_clock' : 'credit_card_shot_clock',
    };
    wt.track(params);
  }, [kind]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      const remaining = calculateRemainingTime(cutoffTime);
      if (remaining) {
        setRemainingTime(remaining);
      } else {
        onExpire();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [cutoffTime, onExpire]);

  const onVirtualWalkthroughClick = () => {
    const url = orderURL(order.id, 'virtual_walkthrough');

    const params = {
      pageName: 'portal:appointment_details',
      container: 'shot_clock_banner',
      action: 'click',
      objectType: 'banner',
      objectName: 'virtual_walkthrough_shot_clock',
      label: 'Get Started',
      order_id: order.id,
    };
    wt.track(params);
    history.push(url);
  };

  const onCreditCardClick = () => {
    setShowModal(true);

    const params = {
      pageName: 'portal:appointment_details',
      container: 'shot_clock_banner',
      action: 'click',
      objectType: 'banner',
      objectName: 'credit_card_shot_clock',
      label: 'Add Card',
      order_id: order.id,
    };
    wt.track(params);
  };

  return (
    <Container kind={kind}>
      <Box.Flex maxWidth="1100px" flexDirection={['column', null, 'row']}>
        <CountdownContainer kind={kind}>
          <Icon src={kind === ShotClockKind.VirtualWalkthrough ? clock : credit_card} />
          <Box.Flex>
            {remainingTime &&
              remainingTime.map((unit, i) => <TimeUnit key={i} value={unit.value} units={unit.units} />)}
          </Box.Flex>
        </CountdownContainer>
        <ContentContainer>
          <Description kind={kind}>
            {kind === ShotClockKind.VirtualWalkthrough ? (
              <Box>
                <b>Virtual Walkthrough Countdown:</b> Complete by {formattedDate} to give us an idea of your items.
                It’ll only take a few minutes and builds the right team for your appointment.
              </Box>
            ) : (
              <Box>
                <b>Credit Card Countdown:</b> Add by {formattedDate} to guarantee your appointment, otherwise it will be
                auto-canceled.{' '}
              </Box>
            )}
          </Description>
          <Button kind={kind}>
            <UnstyledButton
              onClick={kind === ShotClockKind.VirtualWalkthrough ? onVirtualWalkthroughClick : onCreditCardClick}
            >
              <Text.SmallCaps>{kind === ShotClockKind.VirtualWalkthrough ? 'Get Started' : 'Add Card'}</Text.SmallCaps>
            </UnstyledButton>
          </Button>
        </ContentContainer>
      </Box.Flex>
      {showModal && (
        <BuildSourceDialog
          onClose={() => setShowModal(false)}
          onSave={() => {
            window.location.reload();
          }}
        />
      )}
    </Container>
  );
};

export { ShotClock, ShotClockKind };
