import { DateTime } from 'luxon';
import * as React from 'react';

import { Text } from '@shared/components/bootstrap';
import { Currency, Timestamp } from '@shared/components/helpers';
import { ClaimOfferFragment } from '@portal/schema';

export const OfferSection: React.FC<{
  offer: ClaimOfferFragment;
  showExpiry: boolean;
  showCompensation: boolean;
}> = ({ offer, showExpiry, showCompensation }) => {
  // Example: 5:00 PM Jan 5, 2020 PST.
  const formattedExpiry = DateTime.fromISO(offer.expiry).toFormat('t DD ZZZZ');
  const firstName = offer.user.name.split(' ')[0];
  return (
    <span id="offer">
      <div className="row">
        <div className="col-md-4">
          <Text weight="bold">Reviewer:</Text>
          <br />
          <Text tag="div" wrapping="truncate">
            {firstName}
          </Text>
        </div>
        <div className="col-md-4">
          <Text weight="bold">Review Date:</Text>
          <br />
          <Text tag="div" wrapping="truncate">
            <Timestamp value={offer.createdAt} format={Timestamp.Format.Date} />
          </Text>
        </div>
        <div className="col-md-4">
          {showCompensation && (
            <>
              <Text weight="bold">Approved Compensation:</Text>
              <br />
              <Text tag="div" wrapping="truncate">
                <Currency value={offer.totalAmount} />
              </Text>
            </>
          )}
        </div>
      </div>
      <br />

      <Text weight="bold">Determination:</Text>
      <br />
      <Text tag="div" wrapping="overflow">
        <span style={{ whiteSpace: 'pre-wrap' }}>{offer.details}</span>
      </Text>

      {showExpiry && (
        <>
          <br />
          <Text tag="div">
            <span style={{ fontSize: '0.8rem' }}>
              The claim will remain open for 30 days from the date of the settlement offer. After 30 days, the claim
              will automatically close at {formattedExpiry}. If your claim closes and you wish to reopen the claim, you
              can do so by making a request to Clutter Customer Experience via the I Need Help button in your Account
              Portal.
            </span>
          </Text>
        </>
      )}
    </span>
  );
};
