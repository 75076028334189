import * as qs from 'qs';
import * as React from 'react';

import { MAPBOX_API_ACCESS_TOKEN } from '@shared/libraries/mapbox';

interface IStaticMapProps {
  alt?: string;
  latitude: number;
  longitude: number;
  zoom?: number; // 0 - 20
  bearing?: number;
  pitch?: number;
  width: number; // px's
  height: number; // px's
  logo?: boolean;
  marker?: boolean;
}

const STATIC_MAP_BASE_URI = 'https://api.mapbox.com/styles/v1';
const DEFAULT_STYLE_USERNAME = 'mapbox';
const DEFAULT_STYLE_ID = 'streets-v10';
const STATIC_NAMESPACE = 'static';

const DEFAULT_MARKER_COLOR_TEAL = '329a95';
const DEFAULT_MARKER_NAME = 'pin-s';
const DEFAULT_MARKER_LABEL = 'home';

const DEFAULT_ZOOM = 14;
const DEFAULT_BEARING = 0;
const DEFAULT_PITCH = 0;

const RATIOS = [
  1, // i.e. 1 × density (default)
  2, // i.e. 2 × density
  3, // i.e. 3 × density
];

const overlay = ({ latitude, longitude }: { latitude: number; longitude: number }) =>
  `${DEFAULT_MARKER_NAME}-${DEFAULT_MARKER_LABEL}+${DEFAULT_MARKER_COLOR_TEAL}(${longitude},${latitude})`;

const source = ({
  latitude,
  longitude,
  zoom = DEFAULT_ZOOM,
  bearing = DEFAULT_BEARING,
  pitch = DEFAULT_PITCH,
  width,
  height,
  logo = false,
  marker = false,
}: IStaticMapProps): string => {
  const url = [
    STATIC_MAP_BASE_URI,
    DEFAULT_STYLE_USERNAME,
    DEFAULT_STYLE_ID,
    STATIC_NAMESPACE,
    marker ? overlay({ latitude, longitude }) : undefined,
    [longitude, latitude, zoom, bearing, pitch].join(','),
    [width, height].join('x') + '@2x',
  ].join('/');
  const queryParams = qs.stringify({ access_token: MAPBOX_API_ACCESS_TOKEN, logo });
  return [url, queryParams].join('?');
};

export const StaticMap: React.FC<IStaticMapProps> = (props) => {
  const { width, height } = props;
  const srcSet = RATIOS.map((r) => `${source({ ...props, width: width * r, height: height * r })} ${r}x`).join(',');
  return (
    <img
      className="img-fluid img-thumbnail"
      src={source(props)}
      srcSet={srcSet}
      alt={props.alt || `${props.latitude},${props.longitude}`}
    />
  );
};
