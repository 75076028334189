import styled from '@emotion/styled';

export const CalendarEntries = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 16px;

  @media (max-width: 500px) {
    gap: 6px;
  }
`;
