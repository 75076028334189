import { Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { TextLink } from '@portal/components/shared/text';
import { itemsURL } from '@portal/config/routes';

const Description = styled(Text.Body)`
  margin: 24px 0;
`;

export const Submitted: React.FC = () => (
  <>
    <Text.Title size="large">Thank You</Text.Title>
    <Description>An account manager will reach out within 24 hours to discuss your options</Description>

    <Text.Body>
      Take me back to <TextLink href={itemsURL()}>My Storage</TextLink>.
    </Text.Body>
  </>
);
