import { AriaAttributes, SVGAriaEnhancements } from '@shared/components/helpers/svg_aria_enhancements';
import React, { FC } from 'react';

export const PackingTape: FC<AriaAttributes> = ({ title, description: desc }) => {
  const Aria = SVGAriaEnhancements(title, desc);

  return (
    <svg
      width="42"
      height="46"
      viewBox="0 0 42 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={Aria.ariaLabelledby}
    >
      <Aria.Title />
      <Aria.Desc />
      <path
        d="M1 16.7994V24.5489C1 33.2796 9.95 40.3582 21 40.3582C32.05 40.3582 41 33.2796 41 24.5489V16.7994H1Z"
        fill="#A1E6E0"
        stroke="#A1E6E0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5801 16.7994V37.9953C13.1587 39.2468 15.9449 40.0137 18.8001 40.2581V16.7994H10.5801Z"
        fill="#00A499"
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 32.6188C32.0457 32.6188 41 25.5406 41 16.8094C41 8.07809 32.0457 1 21 1C9.9543 1 1 8.07809 1 16.8094C1 25.5406 9.9543 32.6188 21 32.6188Z"
        fill="#A1E6E0"
        stroke="#A1E6E0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 24.7091C26.5228 24.7091 31 21.1723 31 16.8094C31 12.4465 26.5228 8.90973 21 8.90973C15.4772 8.90973 11 12.4465 11 16.8094C11 21.1723 15.4772 24.7091 21 24.7091Z"
        fill="white"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path
        d="M1 24.5489C1 33.2796 9.95 40.3582 21 40.3582C32.05 40.3582 41 33.2796 41 24.5489V16.7994C41 25.5301 32 32.6087 21 32.6087C10 32.6087 1 25.5301 1 16.7994"
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.02051 37.2244C12.4836 38.9256 15.2379 41.7932 16.8005 45.3243V37.5648C15.2379 34.0337 12.4836 31.1661 9.02051 29.4649"
        fill="#A1E6E0"
      />
      <path
        d="M9.02051 37.2244C12.4836 38.9256 15.2379 41.7932 16.8005 45.3243V37.5648C15.2379 34.0337 12.4836 31.1661 9.02051 29.4649"
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 16.6492C22.6632 16.5904 24.3171 16.9219 25.8294 17.6173C27.3418 18.3127 28.6707 19.3527 29.71 20.6541C30.5332 19.525 30.9841 18.1671 31 16.7693C31 12.404 26.52 8.86963 21 8.86963C15.48 8.86963 11 12.404 11 16.7693C11.0112 18.1679 11.4626 19.5272 12.29 20.6541C13.3288 19.3521 14.6576 18.3117 16.1701 17.6162C17.6826 16.9208 19.3367 16.5896 21 16.6492Z"
        fill="#FFA1A1"
        stroke="#FFEABF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.29 20.6841C13.3631 19.4198 14.6978 18.4043 16.2018 17.7078C17.7058 17.0113 19.343 16.6506 21 16.6506C22.6571 16.6506 24.2943 17.0113 25.7983 17.7078C27.3023 18.4043 28.637 19.4198 29.7101 20.6841"
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3203 10.2814C20.8973 10.9183 19.6539 11.898 18.7012 13.1332C17.7484 14.3684 17.116 15.8206 16.8604 17.3601"
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.7403 15.5378C29.157 15.7714 28.6333 16.1328 28.2077 16.5954C27.782 17.0579 27.4651 17.61 27.2803 18.2111"
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
