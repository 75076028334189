import * as React from 'react';

import styled from '@emotion/styled';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { Box, UnstyledButton, Modal, COLORS } from '@clutter/clean';
import { CircleXButton } from '@root/images/circle_x_close';
import { ImageFragment } from '@portal/schema';

const FullWindowImage = styled.img`
  width: auto;
  height: auto;
  max-height: 80vh;
  max-width: 80vw;
  margin: auto;
  display: block;
`;

const imageFormatter = (imageURL: string) => imageURL + '?w=1280&h=1280';

export const ImageModal: React.FC<{
  image: ImageFragment | undefined;
  onClose(): void;
}> = ({ image, onClose }) => (
  <Modal backgroundColor={COLORS.grayBorder} isOpen={!!image} handleModalClose={onClose} overflowY="hidden">
    {image && (
      <Box position="relative">
        <Box position="absolute" left="0" top="0">
          <Box padding="16px 0 0 16px">
            <UnstyledButton onClick={onClose}>
              <CircleXButton />
            </UnstyledButton>
          </Box>
        </Box>
        <FullWindowImage src={imageFormatter(image?.source)} />
      </Box>
    )}
  </Modal>
);
