import React from 'react';
import { Logo } from '@portal/components/shared/logo';
import ForkliftAndFacility from '@portal/images/forklift_and_facility.svg';
import { FontWeight, mq, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { Spacer } from '@shared/components/helpers';
import { Container as BaseContainer } from './shared/layout';

const Container = styled(BaseContainer)`
  ${mq({
    flexDirection: ['column', 'row'],
  })}
`;

const LogoContainer = styled.div`
  ${mq({
    display: ['none', 'none', 'none', 'flex'],
  })}
`;

const TextContainer = styled.div``;

const ImageContainer = styled.img`
  ${mq({
    maxWidth: ['90%', '50%'],
    marginLeft: ['0px', '4rem'],
    marginTop: ['4rem', '0rem'],
  })}
`;

export const MakeSpaceTransition: React.FC<{}> = () => (
  <>
    <LogoContainer>
      <Logo />
    </LogoContainer>
    <Container hasNavbar={false}>
      <TextContainer>
        <Text.Callout weight={FontWeight.Medium}>MakeSpace is now part of Clutter</Text.Callout>
        <Spacer height="1rem" />
        <Text.Title size="medium">
          We're transitioning your account now. Please check back here in a few hours to log in to your new account
        </Text.Title>
        <Spacer height="1.5rem" />
        <Text.Body weight={FontWeight.Regular}>
          Transition in progress, check back in a few hours to log in to your new account
        </Text.Body>
      </TextContainer>
      <ImageContainer src={ForkliftAndFacility}></ImageContainer>
    </Container>
  </>
);
