import styled from '@emotion/styled';
import React, { useState } from 'react';

import {
  Account__CancelIntent as AccountCancelIntent,
  Account__CancelIntent__Reason as CancelIntentReason,
  Maybe,
  RetentionOfferFragment,
  Status,
  useAcceptRetentionOfferMutation,
  useBuildAccountCancelIntentMutation,
} from '@portal/schema';

import { AcceptedRetentionOffer } from '@portal/components/orders/account_cancel_reason/accepted_retention_offer';
import { Options as AccountCancelReasonOptions } from '@portal/components/orders/account_cancel_reason/options';
import { RetentionOffer as AccountRetentionOffer } from '@portal/components/orders/account_cancel_reason/retention_offer';
import { CONTAINER_SIDE_PADDING } from '@portal/components/shared/layout';

import { Base } from './base';

const Container = styled.div`
  margin: 0 ${CONTAINER_SIDE_PADDING}px;
`;

enum ScreenEnum {
  CancelReason,
  RetentionOffer,
  AcceptedOffer,
}

export const AccountCancelReason: React.FC<{
  cancelReason?: CancelIntentReason;
  retentionOffer?: Maybe<RetentionOfferFragment>;
  setCancelIntent(cancelIntent: AccountCancelIntent): void;
  setRetentionOffer(offer: RetentionOfferFragment): void;
  onNext(): void;
  onPrev(): void;
}> = ({ cancelReason, retentionOffer, setCancelIntent, setRetentionOffer, onNext, onPrev }) => {
  const [editedReason, setEditedReason] = useState<CancelIntentReason | undefined>(cancelReason);
  const [error, setError] = useState<Maybe<string>>();
  const [currentScreen, setCurrentScreen] = useState<ScreenEnum>(ScreenEnum.CancelReason);
  const [acceptRetentionOffer, setAcceptRetentionOffer] = useState<boolean>();
  const [storageRate, setStorageRate] = useState<number>();

  const [buildCancelIntent, { loading }] = useBuildAccountCancelIntentMutation();
  const [acceptOffer, { loading: accepting }] = useAcceptRetentionOfferMutation();

  const saveCancelReason = async () => {
    const response = await buildCancelIntent({ variables: { reason: editedReason! } });
    if (response.data?.build?.status === Status.Ok) {
      setCancelIntent(response.data.build.accountCancelIntent!);
      return response.data.build.retentionOffer;
    } else {
      setError(response.data?.build?.error);
    }
  };

  const onConfirm = async () => {
    setError(undefined);
    if (currentScreen === ScreenEnum.CancelReason) {
      let offer = retentionOffer;
      if (editedReason && cancelReason !== editedReason) {
        offer = await saveCancelReason();
      }
      if (offer) {
        setRetentionOffer(offer);
        setAcceptRetentionOffer(undefined);
        setCurrentScreen(ScreenEnum.RetentionOffer);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        onNext();
      }
    } else if (currentScreen === ScreenEnum.RetentionOffer) {
      if (retentionOffer && acceptRetentionOffer) {
        const response = await acceptOffer({ variables: { offerID: retentionOffer.id } });
        if (response.data?.accept?.status === Status.Ok) {
          setStorageRate(response.data.accept.storageRate!);
          setCurrentScreen(ScreenEnum.AcceptedOffer);
        } else {
          setError(response.data?.accept?.error);
        }
      } else {
        onNext();
      }
    }
  };

  const goBack = () => {
    if (currentScreen === ScreenEnum.RetentionOffer) {
      setCurrentScreen(ScreenEnum.CancelReason);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      onPrev();
    }
  };

  if (currentScreen === ScreenEnum.AcceptedOffer) {
    return <AcceptedRetentionOffer offer={retentionOffer!} storageRate={storageRate!} />;
  }

  let valid = true;
  if (currentScreen === ScreenEnum.CancelReason) {
    valid = !!editedReason && !loading;
  } else if (currentScreen === ScreenEnum.RetentionOffer) {
    valid = acceptRetentionOffer !== undefined && !accepting;
  }

  return (
    <Base onNext={onConfirm} onPrev={goBack} valid={valid}>
      <Container>
        {currentScreen === ScreenEnum.RetentionOffer ? (
          <AccountRetentionOffer
            error={error}
            offer={retentionOffer!}
            accept={acceptRetentionOffer}
            onAccept={setAcceptRetentionOffer}
          />
        ) : (
          <AccountCancelReasonOptions error={error} reason={editedReason} setReason={setEditedReason} />
        )}
      </Container>
    </Base>
  );
};
