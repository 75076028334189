import { head, last } from 'lodash';
import React from 'react';

import { Workflow } from '@portal/components/claims/workflow';
import { ClaimIssueSelectionAction, claimIssueSelectionURL } from '@portal/config/routes';

import { Declaration } from './declaration';
import { Description } from './description';
import { Issue } from './issue';
import { Photos } from './photos';

interface IStep {
  action: ClaimIssueSelectionAction;
  component(): JSX.Element;
}

const STEPS: IStep[] = [
  { action: 'issue', component: Issue },
  { action: 'description', component: Description },
  { action: 'declaration', component: Declaration },
  { action: 'photos', component: Photos },
];

export const HEAD = head(STEPS)!.action;
export const TAIL = last(STEPS)!.action;

interface IWizardProps {
  onNext(): void;
  onPrev(): void;
}

export const Wizard = (props: IWizardProps) => (
  <Workflow
    {...props}
    steps={STEPS}
    url={({ uuid, action }) => claimIssueSelectionURL(uuid, action as ClaimIssueSelectionAction)}
  />
);
