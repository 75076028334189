import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { COLORS, Text, FontWeight, Radio, mq } from '@clutter/clean';

import { Minus, Plus } from '@portal/components/shared/icons/estimation';
import { Estimation__AlgorithmName, Status, useEstimationCreateRoomsMutation } from '@portal/schema';

import { CATEGORY_TO_ROOM_DETAILS, RoomCounts, StepType } from './data';
import { StepContainer } from '../step_container';
import { sanitizeRoomsInput } from './utils';

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const QuestionTitle = styled(Text.Title)`
  margin-bottom: 16px;
`;

const QuestionContainer = styled.div`
  margin: 32px 8px;
  display: flex;
  flex-wrap: wrap;
`;

const Card = styled.div`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 8px;
  display: flex;
  height: 72px;
  ${mq({
    width: ['100%', null, 'calc(50% - 16px)'],
  })}
`;

const Display = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Image = styled.div`
  background-color: ${COLORS.tealBackground};
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  margin-right: 24px;
  img {
    margin-left: 4px;
  }
`;

const CountableInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
`;

const Count = styled(Text.Title)`
  ${mq({
    width: ['38px', null, '44px'],
  })}
  margin-top: 4px;
  text-align: center;
`;

const Icon = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const RADIO_OPTIONS = [
  {
    value: true,
    label: 'Yes, all items I plan to store are in the rooms I have selected.',
  },
  {
    value: false,
    label: 'No, I have additional items to store that are not in any of the listed rooms.',
  },
];

type Operation = 'add' | 'remove';

type RoomSelectionProps = {
  field: string;
  label: string;
  onChange: (o: Operation) => void;
  value: number;
};

export const RoomSelection: React.FC<RoomSelectionProps> = ({ field, label, onChange, value }) => {
  const { icon } = CATEGORY_TO_ROOM_DETAILS[field];
  const count = Number(value);

  return (
    <Card key={field}>
      <Display>
        <Image>
          <img src={icon} />
        </Image>
        <Text.Body weight={FontWeight.Medium}>{label}</Text.Body>
      </Display>
      <CountableInput>
        {count ? (
          <>
            <Icon onClick={() => onChange('remove')} data-test-id={`${label}-add`}>
              <Minus
                fillColor={count ? COLORS.tealPrimary : COLORS.grayBorder}
                iconColor={count ? COLORS.cloud : COLORS.storm}
              />
            </Icon>
            <Count size="small">{value}</Count>
            <Icon onClick={() => onChange('add')} data-test-id={`${label}-add`}>
              <Plus />
            </Icon>
          </>
        ) : (
          <Icon onClick={() => onChange('add')} data-test-id={`${label}-add`}>
            <Plus fillColor={COLORS.cloud} iconColor={COLORS.tealPrimary} />
          </Icon>
        )}
      </CountableInput>
    </Card>
  );
};

export const Rooms: React.FC<StepType> = (props) => {
  const {
    roomCategories,
    values: { rooms = [], allItemsInListedRooms = null },
    onChange,
    orderID,
    setError,
  } = props;

  const [selectedRoomCounts, setSelectedRoomCounts] = useState<RoomCounts>({});
  const [allItemsInListedRoomsAnswer, setAllItemsInListedRoomsAnswer] = useState<boolean | null>(allItemsInListedRooms);

  useEffect(() => {
    const roomCounts: RoomCounts = {};
    rooms.forEach(({ roomCategory }) => {
      roomCounts[roomCategory.id] = (roomCounts[roomCategory.id] || 0) + 1;
    });
    return setSelectedRoomCounts(roomCounts);
  }, [rooms]);

  const [createRooms, { loading }] = useEstimationCreateRoomsMutation();

  const onRoomCountChange = (operation: 'add' | 'remove', categoryID: string) => {
    const count = selectedRoomCounts[categoryID] || 0;
    setSelectedRoomCounts({
      ...selectedRoomCounts,
      [categoryID]: operation === 'add' ? count + 1 : count - 1,
    });
  };

  return (
    <StepContainer
      {...props}
      loading={loading}
      headline="Which rooms will you be storing?"
      canNext={allItemsInListedRoomsAnswer !== null}
      next={async () => {
        const { data } = await createRooms({
          variables: {
            roomInputs: sanitizeRoomsInput(selectedRoomCounts),
            deactivateExistingRooms: true,
            algorithmName: Estimation__AlgorithmName.V1,
            orderID,
            allItemsInListedRooms: allItemsInListedRoomsAnswer!,
          },
        });
        if (data?.estimationCreateRooms?.status === Status.Ok) {
          onChange('allItemsInListedRooms', allItemsInListedRoomsAnswer);
          onChange('rooms', data.estimationCreateRooms.selectedRooms, () => props.next());
        } else {
          setError('Failed to create rooms');
        }
      }}
    >
      <CardContainer>
        {roomCategories?.map(({ id, name }) => (
          <RoomSelection
            field={name}
            value={selectedRoomCounts[id] || 0}
            label={name}
            onChange={(operation) => onRoomCountChange(operation, id)}
            key={id}
          />
        ))}
      </CardContainer>
      <QuestionContainer>
        <QuestionTitle size="extraSmall">
          Do the rooms above contain all the items that you plan to store?
        </QuestionTitle>
        <Radio.Selector
          name="all_items"
          value={allItemsInListedRoomsAnswer}
          options={RADIO_OPTIONS}
          onChange={setAllItemsInListedRoomsAnswer}
        />
      </QuestionContainer>
    </StepContainer>
  );
};
