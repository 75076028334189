import { Uploader } from '@shared/hooks/uploader';
import { DirectUpload } from '@rails/activestorage';
import { useEffect, useState } from 'react';

const DEFAULT_UPLOAD_URL = '/rails/active_storage/direct_uploads';

export const useActiveStorageDirectUpload: Uploader = ({ file, onError, onUpload }) => {
  const [retries, setRetries] = useState<number>(0);
  const [loaded, setLoaded] = useState<number | undefined>(undefined);
  const [total, setTotal] = useState<number | undefined>(undefined);
  const retry = () => {
    setLoaded(undefined);
    setTotal(undefined);
    setRetries((count) => count + 1);
  };

  useEffect(() => {
    let active = true;

    const upload = new DirectUpload(file, DEFAULT_UPLOAD_URL, {
      directUploadWillStoreFileWithXHR: (request) => {
        request.upload.addEventListener('progress', (event) => {
          if (!active) {
            return;
          }
          setLoaded(event.loaded);
          setTotal(event.total);
        });
      },
    });

    upload.create((error, blob) => {
      if (!active) {
        return;
      }
      if (error) {
        onError(error);
      } else {
        onUpload(blob.signed_id);
      }
    });

    return () => {
      active = false;
    };
  }, [file, retries]);

  return {
    loaded,
    total,
    retry,
  };
};
