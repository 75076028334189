import React, { useState } from 'react';

import { Checklist } from '@portal/components/helpers/checklist';
import { Draft } from '@portal/components/helpers/draft';
import { ClaimConsumer } from '@portal/contexts';
import { ClaimKindsInput, DetailedClaimFragment, Maybe, Status, useBuildClaimMutation } from '@portal/schema';
import { Alert } from '@shared/components/bootstrap';

import { Step } from './step';

type FieldsProps = {
  claim: DetailedClaimFragment;
  onChange(claim: DetailedClaimFragment): void;
};

const StartStep = ({
  claim,
  loading,
  error,
  onChange,
  onBuildClaim,
}: FieldsProps & {
  loading: boolean;
  error?: Maybe<string>;
  onBuildClaim(input: ClaimKindsInput): Promise<Status>;
}) => {
  const [hasIssue, setHasIssue] = useState<boolean | undefined>(claim.id ? claim.hasIssue : undefined);
  const { hasDamagedItem, hasDamagedProperty, hasLostItem, uuid } = claim;
  return (
    <Step
      saving={loading}
      valid={hasIssue !== undefined}
      hasPrev={false}
      onSave={() =>
        onBuildClaim({
          hasDamagedItem: hasIssue ? false : hasDamagedItem,
          hasLostItem: hasIssue ? false : hasLostItem,
          hasDamagedProperty: hasIssue ? false : hasDamagedProperty,
          hasIssue: !!hasIssue,
          uuid,
        })
      }
    >
      {error && <Alert style="danger">{error}</Alert>}
      <Step.Title>Do you need to file a claim for something that was damaged or lost?</Step.Title>
      <Step.Subtitle>Please select one.</Step.Subtitle>
      <Checklist>
        <Checklist.Entry
          format="radio"
          checked={hasIssue === false}
          onChange={() => {
            setHasIssue(false);
            onChange({ ...claim, hasIssue: false });
          }}
        >
          Yes, something was damaged or lost.
        </Checklist.Entry>
        <Checklist.Entry
          format="radio"
          checked={hasIssue === true}
          onChange={() => {
            setHasIssue(true);
            onChange({ ...claim, hasIssue: true });
          }}
        >
          No, I experienced a billing and/or quality of service issue.
        </Checklist.Entry>
      </Checklist>
    </Step>
  );
};

export const Start = () => {
  const [mutate, { loading, data }] = useBuildClaimMutation();

  return (
    <ClaimConsumer>
      {({ claim, onChange: onSave }) => (
        <Draft<DetailedClaimFragment> draft={claim}>
          {({ draft, onChange }) => {
            const onBuildClaim = async (input: ClaimKindsInput) => {
              const result = await mutate({
                variables: { input },
              });
              onSave({ ...draft, id: result.data?.buildClaim?.claim?.id ?? '' });
              return result.data?.buildClaim?.status ?? Status.Unprocessable;
            };
            return (
              <StartStep
                claim={draft}
                loading={loading}
                onChange={onChange}
                onBuildClaim={onBuildClaim}
                error={data?.buildClaim?.error}
              />
            );
          }}
        </Draft>
      )}
    </ClaimConsumer>
  );
};
