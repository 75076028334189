import React, { useContext, useEffect } from 'react';

import cn from 'classnames';

import { InventoryContext } from './inventory_context';
import { STEPS } from './steps';

const STEPS_WITH_INDEXES = STEPS.map((step, index) => ({ step, index }));

interface IFormProps {
  hideMoving?: boolean;
  hidePacking?: boolean;
}

export const InventoryCaptureForm = ({ hideMoving, hidePacking }: IFormProps) => {
  const { data, onSelect } = useContext(InventoryContext);
  useEffect(() => {
    if (hideMoving) {
      onSelect({ ...data, isFullMoveOut: !hideMoving, needsPackingHelp: !!hidePacking });
    }
  }, [hideMoving, hidePacking]);

  const activeSteps = STEPS_WITH_INDEXES.filter(({ step }) => step.isActive(data, { hideMoving, hidePacking }));

  return (
    <div className="form-steps">
      {activeSteps.map(({ step, index }) => {
        const Step = step.component;
        return (
          <div key={index} className={cn('mb-4', step.className, 'form-step')}>
            <Step pickup={!hidePacking} />
          </div>
        );
      })}
    </div>
  );
};
