import { DateTime, Interval } from 'luxon';

export const isWithinBusinessHours = () => {
  const currentTime = DateTime.local().setZone('America/Los_Angeles');

  const weekend = currentTime.weekday > 5;
  const weekendInterval = Interval.fromDateTimes(
    currentTime.set({ hour: 5, minute: 0, second: 0, millisecond: 0 }),
    currentTime.set({ hour: 16, minute: 0, second: 0, millisecond: 0 }),
  );

  const weekdayInterval = Interval.fromDateTimes(
    currentTime.set({ hour: 5, minute: 0, second: 0, millisecond: 0 }),
    currentTime.set({ hour: 17, minute: 0, second: 0, millisecond: 0 }),
  );

  return (weekend && weekendInterval.contains(currentTime)) || (!weekend && weekdayInterval.contains(currentTime));
};
