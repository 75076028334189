import { appointmentURL } from '@portal/config/routes';
import React, { FC } from 'react';

import { Box, COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';

import { Maybe, useReonboardingCheckoutLinksQuery } from '@portal/schema';

import cargoVanIllustration from '@portal/images/cargo_van.svg';

import selfStorageUnitIllustration from '@portal/images/self_storage_unit.svg';

import storedBoxesIllustration from '@portal/images/stored_boxes.svg';

const CardContainer = styled.div`
  height: 100%;
  width: 100%;
  min-width: 288px;
  border: 2px solid ${COLORS.grayBorder};
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  max-width: 420px;
  padding: 24px;

  :hover {
    border: 2px solid #c4c4c4;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

type CardProps = {
  title: string;
  description: string;
  srcComponent: React.ReactNode;
};

const CardContent: React.FC<CardProps> = ({ title, description, srcComponent }) => (
  <CardContainer>
    <Box.Flex flexDirection="column" alignItems="center" textAlign="center" justifyContent="space-evenly" height="100%">
      <Box>{srcComponent}</Box>
      <Box margin="18px 0 0 24px">
        <Text.Title color={COLORS.panther} size="extraSmall">
          {title}
        </Text.Title>
      </Box>
      <Box padding="4px 0 0 0">
        <Text.Callout color={COLORS.hippo}>{description}</Text.Callout>
      </Box>
    </Box.Flex>
  </CardContainer>
);

const Card: React.FC<CardProps & { href?: Maybe<string> }> = (props) => (
  // Workaround for customers with no phone number (i.e. URL is undefined/null)
  <a href={props.href || 'tel:18008054023'} style={{ textDecoration: 'none', display: 'inline-block', height: '100%' }}>
    <CardContent {...props} />
  </a>
);
export const ServiceCards: FC<{ reonboarding?: boolean; requestableItemsExist: boolean }> = ({
  reonboarding = true,
  requestableItemsExist,
}) => {
  const { loading, data } = useReonboardingCheckoutLinksQuery();

  if (loading) {
    return null;
  }

  const storageLink = reonboarding ? data?.storageLink : appointmentURL();
  const movingLink = data?.movingLink;

  return (
    <Box.Flex flexDirection={['column', 'row']}>
      {reonboarding && requestableItemsExist && (
        <Box margin={['0 0 16px', '0 16px 0 0']}>
          <Card
            title="Remove items from storage"
            description="We offer flexible appointments to remove your items from storage."
            srcComponent={<img src={storedBoxesIllustration} alt="Book return appointment" height={80} width={80} />}
            href={appointmentURL()}
          />
        </Box>
      )}
      <Box margin={['0 0 16px', '0 16px 0 0']}>
        <Card
          title={reonboarding ? 'Put items into storage' : 'Add or return items from storage'}
          description="We offer customizable storage, transportation, pickup and packing services."
          srcComponent={<img src={selfStorageUnitIllustration} alt="Book storage appointment" height={80} width={80} />}
          href={storageLink}
        />
      </Box>
      <Box>
        <Card
          title="Move my items"
          description="We offer customizable moving and packing services for A to B moves."
          srcComponent={<img src={cargoVanIllustration} alt="Book moving appointment" height={80} width={80} />}
          href={movingLink}
        />
      </Box>
    </Box.Flex>
  );
};
