import * as React from 'react';
import { useState } from 'react';

import { Button } from '@shared/components/bootstrap';

export const NewItem: React.FC<{
  onSave(itemName: string, quantity: number): void;
}> = ({ onSave }) => {
  const [quantity, setQuantity] = useState(1);
  const [itemName, setItemName] = useState('');

  const onSaveClick = () => {
    onSave(itemName, quantity);
    setQuantity(1);
    setItemName('');
  };

  return (
    <>
      <div className="row">
        <div className="col-2 col-md-2">Count</div>
        <div className="col-7 col-md-8">Item Name</div>
      </div>
      <div className="row">
        <div className="col-2 col-md-2" style={{ paddingRight: 0 }}>
          <input
            name="item-count"
            className="form-control text-center"
            onChange={(event) => setQuantity(Number(event.target.value))}
            type="number"
            min="1"
            value={quantity || ''}
          />
        </div>
        <div className="col-7 col-md-8">
          <input
            name="item-name"
            className="form-control"
            onChange={(event) => setItemName(event.target.value)}
            type="text"
            value={itemName}
            placeholder="Example: Trampoline"
          />
        </div>
        <div className="col-3 col-md-2" style={{ paddingLeft: 0 }}>
          <Button
            block
            kind="primary"
            onClick={onSaveClick}
            disabled={!itemName || !itemName.replace(/\s+/g, ' ').trim() || quantity < 1}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
