import * as React from 'react';

import { Button, ButtonKind, ButtonSize } from '@shared/components/bootstrap';

const OPTIONS = [
  {
    label: 'No',
    value: false,
  },
  {
    label: 'Yes',
    value: true,
  },
];

export const Switch: React.FC<{
  kind?: ButtonKind;
  size?: ButtonSize;
  selected?: boolean;
  onSelect(selected: boolean): void;
}> = ({ kind, size, selected, onSelect }) => (
  <Button.Group>
    {OPTIONS.map(({ label, value }, index) => (
      <Button
        key={index}
        kind={kind}
        size={size}
        children={label}
        outline={selected !== value}
        active={selected === value}
        onClick={() => onSelect(value)}
      />
    ))}
  </Button.Group>
);
