import React from 'react';
import styled from '@emotion/styled';
import { Text, FontWeight, Radio } from '@clutter/clean';
import { Spinner } from '@portal/components/helpers/spinner';
import { ShipmentValuationCoverage } from '@portal/schema';
import { formattedDeductible, formattedCost } from './helpers';

const PlanContainer = styled.div`
  margin: 24px 0px 8px;
  position: relative;
`;

const Headline = styled(Text.Headline)`
  margin-bottom: 4px;
`;

const Caption = styled(Text.Caption)`
  margin-bottom: 4px;
`;

const RadioLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  div:last-child {
    position: absolute;
    right: 0px;
  }
`;

const RadioLabel: React.FC<{ label: string; price: string }> = ({ label, price }) => (
  <RadioLabelContainer>
    <Text.Body>{label}</Text.Body>
    <Text.Callout>{price}</Text.Callout>
  </RadioLabelContainer>
);

const Plan: React.FC<{
  name: string;
  summary: string;
  options: coverageOptionType[];
  onChange(coverageID?: string): void;
  currentSelection?: string;
}> = ({ name, summary, options, onChange, currentSelection }) => (
  <PlanContainer>
    <Headline weight={FontWeight.Medium}>{name}</Headline>
    <Caption>{summary}</Caption>
    <Radio.Selector name={name} onChange={onChange} value={currentSelection} options={options || []} />
  </PlanContainer>
);

type coverageType = Pick<ShipmentValuationCoverage, 'id' | 'name' | 'deductible' | 'price' | 'key' | 'summary'>;
type coverageOptionType = {
  value: string;
  label: React.ReactElement<any>;
};
type coverageGroupType = {
  [key: string]: {
    name: string;
    summary: string;
    price: number;
    key: string;
    options: coverageOptionType[];
  };
};

const groupCoverages = (coverages: coverageType[]): coverageGroupType =>
  coverages.reduce((accumulator: coverageGroupType, current: coverageType) => {
    accumulator[current.name] = accumulator[current.name] || {
      name: '',
      summary: '',
      price: 0,
      key: '',
      options: [],
    };
    accumulator[current.name].name = current.name;
    accumulator[current.name].summary = current.summary || '';
    accumulator[current.name].price = current.price;
    accumulator[current.name].key = current.key;
    accumulator[current.name].options.push({
      value: current.id,
      label: (
        <RadioLabel
          label={formattedDeductible(current.deductible as number)}
          price={formattedCost(current.price as number)}
          key={current.key}
        />
      ),
    });
    return accumulator;
  }, {});

const CoverageList: React.FC<{
  groupedCoverages: coverageGroupType;
  onChange(coverageID?: string): void;
  currentSelection?: string;
}> = ({ groupedCoverages, onChange, currentSelection }) => {
  if (!groupedCoverages) {
    return null;
  }

  const sortedCoverages = Object.keys(groupedCoverages).sort((a: string, b: string) => {
    if (groupedCoverages[a].price === 0) return -1;
    if (groupedCoverages[a].options.length < groupedCoverages[b].options.length) return -1;
    return 0;
  });

  return (
    <>
      {sortedCoverages.map((coverage, i) => (
        <div key={i}>
          <Plan
            name={groupedCoverages[coverage].name}
            summary={groupedCoverages[coverage].summary || ''}
            options={groupedCoverages[coverage].options}
            onChange={onChange}
            currentSelection={currentSelection}
          />
          <hr />
        </div>
      ))}
    </>
  );
};

export const CoveragePlan: React.FC<{
  setCoverage(coverageID?: string): void;
  currentSelection?: string;
  allCoverages?: coverageType[];
  loading: boolean;
}> = ({ setCoverage, currentSelection, allCoverages, loading }) => {
  if (loading || !allCoverages) {
    return <Spinner />;
  }

  return (
    <>
      <CoverageList
        groupedCoverages={groupCoverages(allCoverages)}
        onChange={setCoverage}
        currentSelection={currentSelection}
      />
    </>
  );
};
