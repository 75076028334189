import React, { useContext } from 'react';

import { ClaimPaymentContext } from '@portal/contexts/claim_payment';
import { ClaimPaymentOptionKind } from '@portal/schema';

import { PAYMENT_OPTION_DESCRIPTIONS } from './constants';

const PAYMENT_OPTIONS = [ClaimPaymentOptionKind.Paypal, ClaimPaymentOptionKind.Check];

export const PaymentOptions = () => {
  const { data, onSelect } = useContext(ClaimPaymentContext);

  return (
    <div className="claim-view__payment-options">
      <span className="claim-view__section-header">Payment Options</span>
      {PAYMENT_OPTIONS.map((option) => (
        <div key={option}>
          <label>
            <input
              type="radio"
              value={option}
              checked={option === data.kind}
              onChange={(event) => onSelect({ ...data, kind: event.target.value as ClaimPaymentOptionKind })}
            />
            {PAYMENT_OPTION_DESCRIPTIONS[option]}
          </label>
          {option === ClaimPaymentOptionKind.Paypal && data.kind === ClaimPaymentOptionKind.Paypal && (
            <div>
              <label className="claim-view__payment-options__paypal-label">
                Please confirm the email address of your PayPal account.
                <input
                  className="form-control"
                  type="email"
                  placeholder="Enter email address"
                  onChange={(event) => onSelect({ ...data, email: event.target.value })}
                  required
                />
              </label>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
