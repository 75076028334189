import { times } from 'lodash';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Box, Text } from '@clutter/clean';
import { TimeslotEntry } from './timeslot_entry';
import { TimeslotTitle } from './timeslot_title';

const LOADING_SLOTS_COUNT = 4;

const Loading: React.FC = () => (
  <>
    {times(LOADING_SLOTS_COUNT, (index) => (
      <TimeslotEntry key={`loading-${index}`} type="button" disabled>
        <FontAwesomeIcon icon={faSpinner} spin />
      </TimeslotEntry>
    ))}
  </>
);

export type IntervalWithFeeAmount = {
  interval: Interval;
  feeAmount?: { value: number; formatted: string } | null;
};

export const Timeslot: React.FC<{
  date: DateTime;
  intervalsWithFeeAmounts: IntervalWithFeeAmount[];
  showPrices: boolean;
  selected(interval: Interval): boolean;
  disabled(interval: Interval): boolean;
  onSelect(intervalConfig: { interval: Interval; forced: boolean }): void;
}> = ({ date, intervalsWithFeeAmounts, showPrices, selected, disabled, onSelect }) => {
  const matches = intervalsWithFeeAmounts.filter(
    ({ interval: { start } }) => start.year === date.year && start.month === date.month && start.day === date.day,
  );

  return (
    <div>
      <TimeslotTitle>{date.toLocaleString(DateTime.DATE_HUGE)}</TimeslotTitle>

      {matches.length > 0 ? (
        matches.map((intervalWithFeeAmount) => {
          const interval = intervalWithFeeAmount.interval;
          const feeAmount = intervalWithFeeAmount.feeAmount;

          const from = interval.start;
          const till = interval.end;
          return (
            <TimeslotEntry
              key={String(interval)}
              type="button"
              disabled={disabled(interval)}
              selected={selected(interval)}
              onClick={() => {
                onSelect({ interval: intervalWithFeeAmount.interval, forced: false });
              }}
            >
              <Box.Flex margin="8px" gap="4px" flexDirection="column" justifyContent="center">
                <Text.Button>
                  {from.toLocaleString(DateTime.TIME_SIMPLE)} - {till.toLocaleString(DateTime.TIME_SIMPLE)}
                </Text.Button>
                {feeAmount && showPrices && <Text.Callout>{feeAmount.formatted}</Text.Callout>}
              </Box.Flex>
            </TimeslotEntry>
          );
        })
      ) : (
        <Loading />
      )}
    </div>
  );
};
