import * as React from 'react';
import { Link } from 'react-router-dom';

import { PickupInventory } from '@portal/components/helpers/pickup_inventory';
import { ReturnInventory } from '@portal/components/helpers/return_inventory';

import { OrderTypeEnum, OrderSubtypeEnum, OrderServiceTypeEnum } from '@portal/schema';
import { IOrder, IOrderService } from '@portal/types';

import { Header } from '@portal/components/helpers/header';
import { orderURL } from '@portal/config/routes';

const INCOMPLETE_PLACEHOLDER =
  'Answer a few questions about what you plan to store, so we can send the right number of movers.';

export const Inventory: React.FC<{
  order: IOrder;
  service: IOrderService;
}> = ({ order, service }) => {
  switch (service.type) {
    case OrderTypeEnum.Pickup:
      return (
        <>
          <Header tag="h4">Storage Inventory</Header>
          <PickupInventory
            hidePacking={false}
            hideMoving={order.subtype === OrderSubtypeEnum.Subsequent}
            incompletePlaceholder={INCOMPLETE_PLACEHOLDER}
          />
          {order.permissions.changeablePickupInventory && (
            <Link className="btn btn-primary" to={orderURL(order.id, 'estimated_items')}>
              Update Pickup Details
            </Link>
          )}
          <hr />
        </>
      );
    case OrderTypeEnum.Return:
      return (
        <>
          <Header tag="h4">
            {order.serviceType === OrderServiceTypeEnum.Disposal
              ? 'Items Selected for Disposal and Donation'
              : 'Return Inventory'}
          </Header>
          <ReturnInventory selections={service.items} />
          {order.permissions.changeableReturnInventory && (
            <Link className="btn btn-primary" to={orderURL(order.id, 'inventory')}>
              Add/Remove Items
            </Link>
          )}
          <hr />
        </>
      );
    case OrderTypeEnum.Disposal:
      return (
        <>
          <Header tag="h4">Items Selected for Disposal and Donation</Header>
          <ReturnInventory selections={service.items} />
          <hr />
        </>
      );
    default:
      return null;
  }
};
