import { STEPS } from '@shared/components/inventory_capture/steps';
import { IOrderInventory } from '@shared/types';

export const inventoryComplete = (inventory: IOrderInventory) =>
  STEPS.every((step) => !step.isActive(inventory) || step.completed(inventory));

interface IOrderInventoryForSave extends IOrderInventory {
  isFullMoveOut?: boolean;
  needsPackingHelp?: boolean;
}

export const filterInventoryForSave = (inventory: IOrderInventory) => {
  const filteredInventory: IOrderInventoryForSave = {};

  for (const step of STEPS) {
    if (step.isActive(inventory)) {
      const data = step.data ? step.data(inventory) : undefined;
      Object.assign(filteredInventory, data);
    }
  }

  return filteredInventory;
};
