import React from 'react';

import { Accordion } from '@clutter/clean';
import { wt } from '@portal/initializers/wt';
import { PricingSetPriceEntryFragment } from '@portal/schema';

import { AccordionLabel } from '../accordion_label';
import { Option } from './option';
import { Options } from '../options';
import {
  accordionMetadata,
  PLAN_UPDATE_PAGE_NAME,
  protectionClassificationMetadata,
  protectionValue,
  protectionChanged,
  protectionTitle,
} from '../util';

export const Selector: React.FC<{
  orderID: string;
  loading: boolean;
  initialPriceEntry?: PricingSetPriceEntryFragment;
  selectedPriceEntry?: PricingSetPriceEntryFragment;
  options: PricingSetPriceEntryFragment[];
  setSelectedPriceEntry(entry?: PricingSetPriceEntryFragment): void;
}> = ({ orderID, loading, initialPriceEntry, selectedPriceEntry, options, setSelectedPriceEntry }) => {
  const selectableOptions = options
    .filter((option) => option.price.amount > 0)
    .sort((a, b) => a.price.amount - b.price.amount);
  const minimalCoverage = options.find((option) => option.price.amount === 0);

  const onToggle = (isOpen: boolean) => {
    const params = {
      ...accordionMetadata(isOpen),
      container: 'item_protection_accordion',
      order_id: orderID,
    };
    wt.track(params);
  };

  const triggerWTEvent = (currentSelection: string, label: string) => {
    const params = {
      container: 'item_protection_accordion',
      previous_selection: protectionClassificationMetadata(selectedPriceEntry),
      current_selection: currentSelection,
      action: 'click',
      label: label,
      pageName: PLAN_UPDATE_PAGE_NAME,
      objectType: 'card',
      objectName: 'item_protection',
      order_id: orderID,
    };
    wt.track(params);
  };

  const setMinimalCoverage = () => {
    triggerWTEvent('NONE', 'Remove');
    setSelectedPriceEntry(minimalCoverage);
  };

  const onSelect = (currentPriceEntry: PricingSetPriceEntryFragment, label: string) => {
    const currentSelection = protectionClassificationMetadata(currentPriceEntry);
    triggerWTEvent(currentSelection, label);
    setSelectedPriceEntry(currentPriceEntry);
  };

  return (
    <Accordion.Item
      name="protection"
      label={
        <AccordionLabel
          loading={loading}
          title={protectionTitle(selectedPriceEntry)}
          value={protectionValue(selectedPriceEntry)}
          changed={protectionChanged(selectedPriceEntry, initialPriceEntry)}
        />
      }
      onToggle={onToggle}
    >
      <Options.Container>
        {selectableOptions.map((option) => (
          <Option
            key={option.id}
            option={option}
            selected={selectedPriceEntry?.id === option.id}
            onSelect={(label) => onSelect(option, label)}
            onRemove={setMinimalCoverage}
          />
        ))}
      </Options.Container>
    </Accordion.Item>
  );
};
