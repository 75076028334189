import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { Alert, Button } from '@shared/components/bootstrap';

export const Fields: React.FC<{
  message?: string;
  emails: Array<string | undefined>;
  onChange(emails: Array<string | undefined>): void;
}> = ({ message, emails, onChange }) => (
  <>
    {message && <Alert style="info">{message}</Alert>}
    {emails.map((email, index) => (
      <React.Fragment key={index}>
        <div className="form-group">
          <input
            key={index}
            className="form-control"
            type="email"
            placeholder="Enter email address"
            value={email || ''}
            onChange={(event) => {
              const copy = [...emails];
              copy[index] = event.target.value || undefined;
              onChange(copy);
            }}
          />
        </div>
      </React.Fragment>
    ))}
    <div className="form-group">
      <Button block kind="info" onClick={() => onChange([...emails, undefined])}>
        <FontAwesomeIcon icon="plus" />
      </Button>
    </div>
  </>
);
