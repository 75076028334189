import { wt } from '@portal/initializers/wt';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const Pageview: React.FC = ({ children }) => {
  const mountedRef = useRef(false);
  const location = useLocation();

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
    } else {
      wt.track('pageview');
    }
  }, [location]);

  return <>{children}</>;
};
