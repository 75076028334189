import * as React from 'react';
import { useContext } from 'react';

import { Spacing, Text } from '@shared/components/bootstrap';
import { Button } from '@shared/components/inventory_capture/button';
import { InventoryContext } from '@shared/components/inventory_capture/inventory_context';

import { AdditionalItems } from './additional_items';

import { Spacer } from '../helpers';

export const AnyUniqueItems: React.FC = () => {
  const { data, onSelect } = useContext(InventoryContext);

  return (
    <>
      <Text tag="p" style="info">
        <strong>Anything unique we will be picking up? We'll make sure to send the right size truck.</strong>
      </Text>

      <Button
        active={data.hasExtraItems === true}
        onClick={() => onSelect({ ...data, hasExtraItems: true, extraItemsComplete: false })}
        text="Yes"
      />
      <Spacer width="1rem" inline />
      <Button
        active={data.hasExtraItems === false}
        onClick={() => onSelect({ ...data, hasExtraItems: false, extraItemsComplete: false })}
        text="No"
      />
      {!!data.hasExtraItems && (
        <Spacing mt={4}>
          <AdditionalItems />
        </Spacing>
      )}
    </>
  );
};
