import { IEstimationCategory, IEstimationInput, IEstimationSelection } from '@shared/types';

export const buildEstimationsInput = (data?: Map<IEstimationCategory, Partial<IEstimationSelection>>) => {
  const results: IEstimationInput[] = [];
  if (!data) {
    return results;
  }

  for (const category of Array.from(data.keys())) {
    const selection = data.get(category);
    if (!selection) {
      continue;
    }
    results.push({
      id: selection.id,
      categoryID: category.id,
      quantity: selection.quantity,
      selected: selection.selected,
    });
  }

  return results;
};
