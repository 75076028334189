import { COLORS } from '@clutter/clean';
import styled from '@emotion/styled';

import { bodyStyle } from '@portal/components/shared/text';

export const InputLabel = styled.label`
  ${bodyStyle}
  margin-bottom: 4px;
  font-weight: bold;
`;

export const Input = styled.input`
  ${bodyStyle}
  background: ${COLORS.cloud};
  border: 1px solid ${COLORS.grayBorder};
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  outline: none;
  :focus {
    background: ${COLORS.tealBackground};
    border: 1px solid ${COLORS.tealPrimary};
  }
`;
