import React from 'react';

export const CargoVanFrontHalf: React.FC = () => (
  <svg width="84" height="78" viewBox="0 0 84 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M80.721 40.1042L56.4217 32.0003C55.2072 31.5951 54.094 30.9569 53.1224 30.1465C52.3836 29.5286 51.7359 28.8195 51.1995 28.0091L39.6419 10.6364L35.8974 5.01429C34.0251 2.19818 30.8573 0.506494 27.4771 0.506494H0.506027V61.2857H83.494V43.9434C83.494 42.201 82.3807 40.6512 80.721 40.1042Z"
      fill="#00A499"
      stroke="#00A499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.506027 50.1429H56.6646C64.68 50.1429 70.4689 54.8026 72.1995 61.4883"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.506027 67.3636H81.4699C82.5831 67.3636 83.494 66.452 83.494 65.3377V57.2338C83.494 56.1195 82.5831 55.2078 81.4699 55.2078H70.3373L69.1128 58.6013C68.5359 60.2119 67.0077 61.2857 65.3075 61.2857H0.506027V67.3636Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.506027 77.4935H83.494"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M83.4029 64.3247H0.506027"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.506027 49.1299H57.1807C64.5788 49.1299 70.8737 53.8706 73.2116 60.4753"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.1807 77.4935C63.329 77.4935 68.3133 72.5047 68.3133 66.3507C68.3133 60.1966 63.329 55.2078 57.1807 55.2078C51.0324 55.2078 46.0482 60.1966 46.0482 66.3507C46.0482 72.5047 51.0324 77.4935 57.1807 77.4935Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.1807 72.4286C60.5344 72.4286 63.253 69.7074 63.253 66.3506C63.253 62.9939 60.5344 60.2727 57.1807 60.2727C53.8271 60.2727 51.1084 62.9939 51.1084 66.3506C51.1084 69.7074 53.8271 72.4286 57.1807 72.4286Z"
      fill="#A1E6E0"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.1807 68.8831C58.5781 68.8831 59.7108 67.7493 59.7108 66.3506C59.7108 64.952 58.5781 63.8182 57.1807 63.8182C55.7834 63.8182 54.6506 64.952 54.6506 66.3506C54.6506 67.7493 55.7834 68.8831 57.1807 68.8831Z"
      fill="#A1E6E0"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.506027 35.961H51.1084C52.2217 35.961 53.1325 36.8727 53.1325 37.987V48.0865C53.1325 48.9577 52.5759 49.7275 51.7561 50.001C46.9287 51.6117 43.0424 55.299 41.1802 60.0195C40.8766 60.7894 40.1378 61.2857 39.3181 61.2857C34.4501 61.2857 18.733 61.2857 18.733 61.2857V5.57143C18.733 4.45714 19.6439 3.54545 20.7571 3.54545H21.7692"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.9166 60.9514C67.8072 62.5519 68.3132 64.3956 68.3132 66.3507C68.3132 66.6951 68.293 67.0294 68.2728 67.3636"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.7711 27.8571C22.7711 29.5387 24.1272 30.8961 25.8072 30.8961H53.1325V30.1465C52.3937 29.5286 51.746 28.8195 51.2096 28.0091L39.6419 10.6364H25.8072C24.1272 10.6364 22.7711 11.9938 22.7711 13.6753V27.8571Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M83.494 46.9823C83.494 45.24 82.3807 43.6901 80.721 43.1431L56.4217 35.0392C55.2072 34.634 54.094 33.9958 53.1224 33.1855C52.3836 32.5675 51.7359 31.8584 51.1995 31.0481L39.6419 13.6753"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.506027 3.54545H27.4771C30.8574 3.54545 34.0251 5.23714 35.8974 8.05325L51.1084 30.8961H25.8072"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.6178 10.6364L51.1084 30.8961"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.4819 45.0779H83.494V53.1818H80.4578V47.1039C80.4578 45.9896 81.3687 45.0779 82.4819 45.0779Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.4699 49.1299H83.494"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.4217 53.1817H79.4458V45.0778H77.4217V53.1817Z"
      fill="#FFEABF"
      stroke="#FFEABF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.4458 53.1818H77.4217V45.0779"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.988 22.7922V30.8961C42.668 30.8961 44.0241 29.5387 44.0241 27.8571V25.8312C44.0241 24.1496 42.668 22.7922 40.988 22.7922Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
