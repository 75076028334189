import * as React from 'react';

interface IGridEntryProps {
  children: React.ReactNode;
}

const GridEntry = ({ children }: IGridEntryProps) => <div className="entry col-md-3 col-sm-4 col-6">{children}</div>;

interface IGridProps {
  children: React.ReactNode;
}

const Grid = ({ children }: IGridProps) => (
  <div className="grid">
    <div className="row">{children}</div>
  </div>
);

Grid.Entry = GridEntry;

export { Grid };
