import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React from 'react';

import { COLORS, mq, Text, FontWeight } from '@clutter/clean';
import { Currency } from '@shared/components/helpers';

export interface LaborRateEntryProps {
  selected?: boolean;
  disabled?: boolean;
  booked?: boolean;
}

export const backgroundColor = ({ selected, disabled, booked }: LaborRateEntryProps) => {
  if (disabled) return COLORS.cloud;
  else if (selected) return COLORS.tealPrimary;
  else if (booked) return COLORS.grayBorder;
  else return COLORS.tealBackground;
};

export const borderColor = ({ disabled, booked, selected }: LaborRateEntryProps) => {
  if (disabled) return COLORS.grayBorder;
  else if (booked && !selected) return COLORS.hippo;
  else return COLORS.tealBrand;
};

const textColor = ({ selected, disabled }: LaborRateEntryProps) => {
  if (disabled) return COLORS.grayBorder;
  else if (selected) return COLORS.cloud;
  else return COLORS.panther;
};

const LaborRateEntry = styled.div<LaborRateEntryProps>`
  height: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: ${backgroundColor};
  border: 1px solid;
  border-color: ${borderColor};
  border-radius: 4px;
  padding: 10px 0px;
  color: ${textColor};
`;

const Day = styled(Text.Body)`
  ${mq({
    fontSize: ['14px', '16px'],
    fontWeight: FontWeight.Semibold,
  })}
`;

const Rate = styled(Text.Body)`
  ${mq({
    fontSize: ['10px', '14px'],
    fontWeight: FontWeight.Medium,
  })}
`;

export const LaborRateCalendarEntry: React.FC<{
  date: DateTime;
  selected?: boolean;
  disabled?: boolean;
  booked?: boolean;
  amount?: number;
}> = ({ date, disabled, selected, booked, amount }) => (
  <LaborRateEntry disabled={disabled} selected={selected} booked={booked}>
    <Day>{date.day}</Day>
    {!disabled && amount ? (
      <Rate>
        <Currency value={amount} precision="automatic" />
        /hr
      </Rate>
    ) : (
      <Rate>-</Rate>
    )}
  </LaborRateEntry>
);
