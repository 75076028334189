import React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import { useFailedPrepaymentQuery } from '@portal/schema';

import { Spinner } from '@portal/components/helpers';

import { Empty } from '../empty';
import { PayDepositForm } from './form';

export const PayDeposit: React.FC<RouteComponentProps<{ orderID: string }>> = ({
  match: {
    params: { orderID },
  },
}) => {
  const { data } = useFailedPrepaymentQuery({
    variables: {
      orderID,
    },
    // If we don't use 'no-cache', the following will happen:
    // 1. User pays their prepayment, `data.order.failedPrepayment` in the cache is set to `null`
    // in response to the MovingPrepaymentPay mutation.
    // 2. This component will show the Empty component, when we want the success modal shown.
    fetchPolicy: 'no-cache',
  });

  if (!data) {
    return <Spinner />;
  } else if (!data.order.failedPrepayment) {
    return <Empty />;
  }

  return <PayDepositForm failedPrepayment={data.order.failedPrepayment} />;
};
