import styled from '@emotion/styled';
import * as React from 'react';

import { UsageBar, Text, COLORS, Box } from '@clutter/clean';

import { useUsageWithDownsizeQuery } from '@portal/schema';
import { Percent } from '@shared/components/helpers/percent';

import { clamp } from '@portal/utils/clamp';

import { List as SubscriptionList } from '@portal/components/subscription/list';

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
`;

const Highlight = styled.span`
  color: ${COLORS.tealPrimary};
`;

export const UsagePanel: React.FC = () => {
  const { data } = useUsageWithDownsizeQuery();
  if (!data) return null;
  const account = data.account;
  const usage = account.usage;

  const percentage = usage.total > 0 ? clamp(usage.used / usage.total) : 0;
  const subscriptions = account.upcomingSubscriptionSet.storageSubscriptions;

  return (
    <Box width="100%">
      <TitleContainer>
        <Text.Title size="small">
          Your <SubscriptionList subscriptions={subscriptions} /> plan
        </Text.Title>
        <Text.Title size="small">
          <Highlight>
            <Percent value={percentage} />
          </Highlight>
        </Text.Title>
      </TitleContainer>
      <UsageBar percentage={percentage} />
    </Box>
  );
};
