import { Box, ButtonLink, COLORS, Modal, mq, Text, FontWeight } from '@clutter/clean';
import { useTrack } from '@clutter/wt';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import styled from '@emotion/styled';
import { DateTime, Duration, Interval } from 'luxon';

import {
  Account__State,
  AvailabilityFragment,
  Maybe,
  OrderTypeEnum,
  Status,
  useOrderRescheduleOfferItemAcceptMutation,
  useRescheduleOfferDetailsQuery,
} from '@portal/schema';
import { Spinner } from '@portal/components/helpers';
import { datetime, formatDate } from '@portal/utils/scheduling';
import { isAllDaySLA } from '@shared/utils';
import { ScheduledDatePicker } from '@portal/components/helpers/scheduled_date_picker';
import { ScheduledIntervalPicker } from '@portal/components/helpers/scheduled_interval_picker';
import { Alert, Spacing } from '@shared/components/bootstrap';
import { Button, Footer } from '@portal/components/orders/steps/base';
import { Spacer } from '@shared/components/helpers';
import { orderURL } from '@portal/config/routes';
import {
  EventSchema,
  Portal__RescheduleOfferAccepted,
  Portal__RescheduleOfferDateSelected,
  Portal__RescheduleOfferDeclined,
  Portal__RescheduleOfferViewed,
} from '@shared/wt';
import { formattedDiscount } from '@shared/utils/coupon';

import { LaborRateData } from '@portal/components/orders/steps/scheduled';
import { Banner } from './banner';
import { Expired } from './expired';

const BannerContainer = styled(Box)`
  margin: 24px 0 48px;
  ${mq({ display: ['none', 'flex'] })}
`;

const CouponContainer = styled.div`
  border-top: 1px solid ${COLORS.grayBorder};
  border-bottom: 1px solid ${COLORS.grayBorder};
  padding: 32px 0;
  margin: 16px 0;
`;

const ButtonContainer = styled.div`
  padding: 16px 24px;
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  @media (min-width: 600px) {
    margin: 0 auto;
    button {
      min-width: 150px;
    }
  }
`;

const ModalContainer = styled.div`
  ${mq({
    padding: ['52px 16px 32px', null, '48px 24px 32px'],
  })}
  width: 400px;
`;

const Discount = styled.span`
  color: ${COLORS.tealPrimary};
`;

const ActionModal: React.FC<{ isOpen: boolean; handleModalClose(): void; continueURL: string }> = ({
  isOpen,
  handleModalClose,
  continueURL,
  children,
}) => (
  <Modal isOpen={isOpen} handleModalClose={handleModalClose}>
    <ModalContainer>
      <Box textAlign="center" padding="0 0 24px 0" color={COLORS.tealDark}>
        <Text.Title size="small">{children}</Text.Title>
      </Box>
      <ButtonLink fullWidth href={continueURL}>
        Continue
      </ButtonLink>
    </ModalContainer>
  </Modal>
);

const hashifyDatetime = (dt: DateTime) => `${dt.year}-${dt.month}-${dt.day}`;
const formatAvailabilities = (availabilities: AvailabilityFragment[]): string[] => {
  const reduced = availabilities.reduce((memo, entry) => {
    if (entry.available) {
      memo.add(hashifyDatetime(DateTime.fromISO(entry.datetime, { setZone: true })));
    }
    return memo;
  }, new Set<string>());
  return Array.from(reduced.values());
};

export const Details: React.FC = () => {
  const track = useTrack();
  const { token } = useParams<{ token: string }>();
  const { data, loading } = useRescheduleOfferDetailsQuery({
    variables: { token },
    onCompleted: ({ details }) => {
      if (!details) {
        return;
      }
      const { orderRescheduleOfferItemID, coupon, availabilities } = details;
      const params: Portal__RescheduleOfferViewed = {
        schema: EventSchema.Portal__RescheduleOfferViewed,
        action: 'display',
        metadata: {
          order_reschedule_offer_item_id: orderRescheduleOfferItemID,
          active: !!coupon && !!availabilities,
          dates_available: availabilities ? formatAvailabilities(availabilities) : undefined,
        },
      };
      track(params);
    },
  });
  const [accept, { loading: accepting }] = useOrderRescheduleOfferItemAcceptMutation();
  const [error, setError] = useState<Maybe<string>>();
  const [date, setDate] = useState<DateTime | undefined>(undefined);
  const [intervalConfig, setIntervalConfig] = useState<{ interval: Interval; forced: boolean } | undefined>(undefined);
  const [showDeclineModal, setShowDeclineModal] = useState<boolean>(false);
  const [showAcceptedModal, setShowAcceptedModal] = useState<boolean>(false);
  const [laborRateData, setLaborRateData] = useState<LaborRateData | undefined>(undefined);

  const errorContainer = React.useRef<HTMLDivElement>(null);

  const onDateSelection = (selected: DateTime) => {
    if (data?.details) {
      const params: Portal__RescheduleOfferDateSelected = {
        schema: EventSchema.Portal__RescheduleOfferDateSelected,
        action: 'click',
        metadata: {
          order_reschedule_offer_item_id: data.details.orderRescheduleOfferItemID,
          date_selected: hashifyDatetime(selected),
        },
      };
      track(params);
    }
    setDate(selected);
  };

  const onAccept = async () => {
    if (data?.details) {
      const params: Portal__RescheduleOfferAccepted = {
        schema: EventSchema.Portal__RescheduleOfferAccepted,
        action: 'click',
        metadata: {
          order_reschedule_offer_item_id: data.details.orderRescheduleOfferItemID,
        },
      };
      track(params);
    }
    if (!date || !intervalConfig) {
      setError('Invalid scheduled date');
      return;
    }
    setError(null);
    const duration = intervalConfig.interval.end.diff(intervalConfig.interval.start);
    const response = await accept({
      variables: {
        input: {
          offerItemSignedID: token,
          rescheduledTo: intervalConfig.interval.start.toJSON(),
          duration: duration.toJSON(),
          laborRateID: laborRateData?.laborRate.id,
          perMoverHourAdjustmentAmount: laborRateData?.perMoverHourAdjustmentAmount,
        },
      },
    });
    if (response.data?.offerAccept) {
      if (response.data.offerAccept.status === Status.Unprocessable) {
        setError(response.data.offerAccept.error);
        if (errorContainer.current) {
          errorContainer.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        setShowAcceptedModal(true);
      }
    }
  };

  const onDecline = () => {
    if (data?.details) {
      const params: Portal__RescheduleOfferDeclined = {
        schema: EventSchema.Portal__RescheduleOfferDeclined,
        action: 'click',
        metadata: {
          order_reschedule_offer_item_id: data.details.orderRescheduleOfferItemID,
        },
      };
      track(params);
    }
    setShowDeclineModal(true);
  };

  const closeModals = () => {
    setShowDeclineModal(false);
    setShowAcceptedModal(false);
  };

  if (loading) {
    return <Spinner />;
  }

  const details = data?.details;

  if (!details) {
    return <>This offer is invalid</>;
  }

  const { order, coupon, availabilities } = details;

  if (!coupon || !availabilities) {
    return <Expired orderID={order.id} />;
  }

  const scheduled = datetime(order.scheduled);
  const allDaySLA = isAllDaySLA(scheduled.hour, scheduled.plus(Duration.fromISO(order.window)).hour);
  const from = datetime(availabilities[0].datetime).startOf('month');
  const till = datetime(availabilities.slice(-1)[0].datetime).endOf('month');
  const valid = !!date && (allDaySLA || !!intervalConfig);

  return (
    <div>
      <ActionModal
        isOpen={showDeclineModal || showAcceptedModal}
        handleModalClose={closeModals}
        continueURL={orderURL(order.id)}
      >
        {showDeclineModal && <>No problem! See you on {formatDate(scheduled)}!</>}
        {showAcceptedModal && (
          <>
            Your <Discount>{formattedDiscount(coupon)}</Discount> offer has been applied!
          </>
        )}
      </ActionModal>
      <BannerContainer>
        <Banner coupon={coupon} />
      </BannerContainer>
      <Text.Title size="medium">{formatDate(scheduled)} Appointment</Text.Title>
      <CouponContainer>
        <Text.Body>
          Claim your{' '}
          <Text.Body weight={FontWeight.Medium} as={'span'}>
            {formattedDiscount(coupon)} offer
          </Text.Body>{' '}
          by rescheduling to any of the eligible dates below.
        </Text.Body>

        <br />
        <Text.Body>
          Your appointment is currently being charged at {order.laborCostDetails?.formattedCost}. Some dates may also
          have a lower labor rate as shown in the calendar.
        </Text.Body>
      </CouponContainer>
      <div>
        <Spacing mt={2} mb={4}>
          <ScheduledDatePicker
            serviceType={order.serviceType || undefined}
            availabilities={availabilities}
            date={date || scheduled}
            onDate={onDateSelection}
            onInterval={setIntervalConfig}
            baseLaborRate={order.baseLaborRate || undefined}
            onLaborRate={setLaborRateData}
            laborRateData={laborRateData}
            from={from}
            till={till}
            showNav={false}
            trackDateSelection={false}
            isAccountCanceled={order.account.state === Account__State.Canceled}
            isMove={order.type === OrderTypeEnum.Move}
          />
        </Spacing>
        {date && !allDaySLA && (
          <Spacing mt={2} mb={4}>
            <ScheduledIntervalPicker
              availabilities={availabilities}
              date={date}
              interval={intervalConfig?.interval}
              onInterval={setIntervalConfig}
            />
          </Spacing>
        )}
      </div>
      {error && (
        <div ref={errorContainer}>
          <Alert style="danger">{error}</Alert>
        </div>
      )}
      <Spacer height="100px" />
      <Footer>
        <ButtonContainer>
          <Button type="button" className="secondary" disabled={accepting} onClick={onDecline}>
            Decline
          </Button>
          <Button type="submit" className="primary" loading={loading || accepting} disabled={!valid} onClick={onAccept}>
            Confirm
          </Button>
        </ButtonContainer>
      </Footer>
    </div>
  );
};
