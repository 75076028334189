import * as React from 'react';

import { InputFormGroup } from '../input_form_group';

export const SquareFeet: React.FC<{
  value?: number | null;
  onChange(value: number | null): void;
}> = ({ value, onChange }) => (
  <InputFormGroup
    label="Square footage"
    type="number"
    step={1}
    value={value ?? ''}
    onChange={(event) => onChange(Number(event.target.value) || null)}
    min={1}
  />
);
