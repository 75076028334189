import React from 'react';

export const CheckCircle = ({
  width = '24',
  height = '24',
  fillColor = '#00A499',
}: {
  width?: string;
  height?: string;
  fillColor?: string;
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.6695 9.2748C17.0015 8.83351 16.9124 8.20734 16.4703 7.8762C16.0283 7.54507 15.4008 7.63437 15.0687 8.07566L10.2257 14.512L8.07505 12.9343C7.6297 12.6076 7.00311 12.7032 6.67552 13.1478C6.34792 13.5924 6.44338 14.2176 6.88873 14.5443L9.83775 16.7076C10.2799 17.032 10.9016 16.9404 11.2313 16.5022L16.6695 9.2748Z"
      fill={fillColor}
    />
  </svg>
);
