import React from 'react';

import { Box, COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import { AppointmentDetailsOrder } from '../full_panel';
import { LineItem } from '../../line_item';

const FinePrint = styled.div`
  color: ${COLORS.storm};
  font-size: 12px;
  line-height: 14px;
`;

export const MoveTransportFeeLineItem: React.FC<{
  order: AppointmentDetailsOrder;
}> = ({ order }) => {
  const minimumFee = order.movingOperation?.longDistanceMinimumTransportFee;
  const minimumWeight = order.movingOperation?.longDistanceMinimumCargoWeightInPounds;

  if (!minimumFee || !minimumWeight) {
    return null;
  }

  return (
    <LineItem
      label={<Box.Flex flexWrap="wrap">Transport Fee</Box.Flex>}
      description={<FinePrint>Up to {minimumWeight.toLocaleString()} lbs</FinePrint>}
    >
      <div>${minimumFee.toLocaleString()}</div>
    </LineItem>
  );
};
