import { DateTime } from 'luxon';
import React from 'react';
import { Link } from 'react-router-dom';
import { Text } from '@shared/components/bootstrap';

import { DropOff__Appointment } from '@portal/schema';
import { IOrder } from '@portal/types';

import { Header } from '@portal/components/helpers/header';
import { orderURL } from '@portal/config/routes';
import { Spacer } from '@shared/components/helpers';

const FROM_ISO_OPTIONS = { setZone: true };

export const Scheduled: React.FC<{
  order: IOrder;
  dropOffAppointment: DropOff__Appointment;
}> = ({ order, dropOffAppointment }) => {
  const date = DateTime.fromISO(dropOffAppointment.expectedAt, FROM_ISO_OPTIONS);

  return (
    <>
      <Spacer height="2rem" />

      <Header tag="h2">{date.toLocaleString(DateTime.DATE_HUGE)}</Header>

      <Text tag="p" weight="bold">
        Drop Off Appointment
        <br />
      </Text>

      {order.permissions.active && (
        <>
          {order.permissions.reschedulable ? (
            <Link className="btn btn-primary" to={orderURL(order.id, 'reschedule')}>
              Reschedule
            </Link>
          ) : (
            <p>Order cannot be rescheduled. Please cancel if you are no longer available.</p>
          )}
        </>
      )}

      <hr />
    </>
  );
};
