import { compact } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { isNil } from 'lodash';

import { AddressDetailBuildingType, AddressDetailField, IAddressWithDetails, IOrder } from '@portal/types';

import { isRequired, isUnit } from '@portal/components/addresses/utils';

import { orderURL } from '@portal/config/routes';

import { Text } from '@shared/components/bootstrap';

import { Header } from '@portal/components/helpers/header';
import { accessFieldsDetail, parkingLocationDetail } from './utils';

const streetAddress = (address: IAddressWithDetails) => {
  const { aptsuite, street, city, state, zip } = address;

  const line1 = compact([street, aptsuite]).join(' ');
  return `${line1}, ${city}, ${state} ${zip}`;
};

const AddressDetail: React.FC<{
  label: string;
  value?: string | number | React.ReactNode | null;
  required?: boolean;
}> = ({ label, value, required }) => {
  const valid = !isNil(value);

  const style = (() => {
    if (!valid && required) {
      return 'danger';
    } else if (!value && !required) {
      return 'muted';
    } else {
      return undefined;
    }
  })();

  return (
    <>
      <Text tag="div" style="info" weight="bold">
        {label}
      </Text>
      <Text tag="p" style={style}>
        {valid ? value : 'Not Answered'}
      </Text>
    </>
  );
};

export const Address: React.FC<{
  order: Required<Pick<IOrder, 'address' | 'permissions' | 'id'>>;
}> = ({ order }) => {
  const { address } = order;
  const { details } = address;
  const { buildingType, buildingSubtype, floor } = details ?? {};

  const required = (field: AddressDetailField) => !!details && isRequired({ field, details });

  return (
    <>
      <Header tag="h4">Address Details</Header>

      <AddressDetail required={true} label="Street Address" value={streetAddress(address)} />
      <AddressDetail required={required(AddressDetailField.BuildingType)} label="Building Type" value={buildingType} />

      {buildingType === AddressDetailBuildingType.Apartment && (
        <AddressDetail
          required={required(AddressDetailField.BuildingSubtype)}
          label="Apartment Type"
          value={buildingSubtype}
        />
      )}
      {buildingType === AddressDetailBuildingType.Commercial && (
        <AddressDetail
          required={required(AddressDetailField.BuildingSubtype)}
          label="Building Type"
          value={buildingSubtype}
        />
      )}

      {isUnit(buildingType) && (
        <AddressDetail required={required(AddressDetailField.Floor)} label="Floor Number" value={floor} />
      )}
      {isUnit(buildingType) && (
        <AddressDetail
          required={true}
          label="How will we access the apartment?"
          value={details && accessFieldsDetail(details)}
        />
      )}

      <AddressDetail
        label="Is paperwork or a COI required to access your building?"
        value={
          <>
            Click{' '}
            <Link to="/coi" target="_blank">
              here
            </Link>{' '}
            to request a Certificate of Insurance (COI). Once you fill out the requested materials, we will email a
            completed COI to you and your building manager.
          </>
        }
      />
      <AddressDetail required={true} label="Where can we park?" value={details && parkingLocationDetail(details)} />

      <Link className="btn btn-primary" to={orderURL(order.id, 'address')}>
        Update Address
      </Link>
      <hr />
    </>
  );
};
