import React from 'react';
import styled from '@emotion/styled';
import { Column, mq } from '@clutter/clean';

import { SelectableCard } from '@portal/components/orders/steps/delivery_option/selectable_card';
import { AddressWithDetailsFragment, OrderEligibleServiceType, OrderServiceTypeEnum } from '@portal/schema';
import { useOrderInput } from '@portal/components/orders/steps/order_context';
import { SERVICE_TYPE_MAPPING } from '@portal/components/orders/service_type/service_type_mapping';
import { InfoCard } from '@portal/components/orders/steps/delivery_option/info_card';

const StyledSelectableCard = styled(SelectableCard)`
  max-width: 470px;
  display: inline-block;
  margin-bottom: 24px;
`;

const StyledInfoCard = styled(InfoCard)`
  ${mq({ minHeight: ['0', '150px'] })}
`;

const actionText = (eligible: boolean, serviceType: OrderServiceTypeEnum, addressID?: string | null): string => {
  if (eligible) {
    return 'Select';
  } else if (!addressID && serviceType !== OrderServiceTypeEnum.Facility) {
    return 'Select an address above';
  } else {
    return 'Unavailable';
  }
};

export const ServiceTypeCard: React.FC<{
  serviceTypeData: OrderEligibleServiceType;
  facilityAddress?: AddressWithDetailsFragment;
  setServiceType(serviceType: OrderServiceTypeEnum): void;
  numCards: number;
}> = ({ serviceTypeData, facilityAddress, setServiceType, numCards }) => {
  const { type, addressID, serviceType: selectedServiceType } = useOrderInput();

  const { serviceTypeOptions: serviceTypeCards } = SERVICE_TYPE_MAPPING[type!]!;
  const cardData = serviceTypeCards.find((card) => card.serviceType === serviceTypeData.serviceType);

  const { serviceType, eligible, price } = serviceTypeData;

  if (!cardData) return null;

  return (
    <Column widths={[12, 12, numCards >= 3 ? 4 : 6]}>
      <StyledSelectableCard
        data-service-type={serviceType}
        actionText={actionText(eligible, serviceType, addressID)}
        selectedActionText={'Selected'}
        selected={selectedServiceType === serviceType}
        onSelect={(selected: any) => setServiceType(selected)}
        value={serviceType}
        disabled={!eligible}
        bottomDescription={
          eligible && (
            <SelectableCard.Title>
              <SelectableCard.Body>{cardData.cardBody(serviceType, price ?? '$0')}</SelectableCard.Body>
            </SelectableCard.Title>
          )
        }
      >
        <StyledInfoCard
          header={cardData.infoHeader}
          body={cardData.infoBody(facilityAddress)}
          icon={<cardData.icon />}
        />
      </StyledSelectableCard>
    </Column>
  );
};
