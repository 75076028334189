import React from 'react';

import {
  SelfStorage__AdjustedRateRentalFragment,
  SelfStorage__UpcomingSubscriptionItem,
  SelfStorage__SubscriptionItem__Kind as SubscriptionItemKind,
} from '@portal/schema';

import { CostRow } from '@portal/components/shared/cost_table';

const item = (
  subscriptionItem: SelfStorage__UpcomingSubscriptionItem,
  rental: SelfStorage__AdjustedRateRentalFragment,
) => {
  switch (subscriptionItem.kind) {
    case SubscriptionItemKind.Unit: {
      const width = rental.unit.classification.width;
      const length = rental.unit.classification.length;
      const unitSize = `${width}′ × ${length}′`;
      return `${unitSize} Storage: Unit #${rental.unit.name}`;
    }
    case SubscriptionItemKind.Protection:
      return `Protection Plan: Unit #${rental.unit.name}`;
    default:
      return subscriptionItem.summary;
  }
};

const description = (subscriptionItem: SelfStorage__UpcomingSubscriptionItem) => {
  switch (subscriptionItem.kind) {
    case SubscriptionItemKind.Protection:
      return subscriptionItem.summary;
    default:
      return undefined;
  }
};

export const RentalCostRows: React.FC<{ rental: SelfStorage__AdjustedRateRentalFragment }> = ({ rental }) => (
  <>
    {rental.subscriptionItems.map((subscriptionItem) => (
      <CostRow
        key={subscriptionItem.id}
        item={item(subscriptionItem, rental)}
        amount={subscriptionItem.rate}
        description={description(subscriptionItem)}
      />
    ))}
  </>
);
