import React from 'react';

import { orderMovingAddressURL, orderURL } from '@portal/config/routes';
import { AppointmentDetailsOrder } from '../full_panel';
import { LineItem, TrackedLinkAction } from '../../line_item';
import { wtLinkParams } from './constants';

function updateAddressTrackingParams(orderID: string) {
  return {
    ...wtLinkParams,
    objectName: 'update_address',
    label: 'Edit',
    value: orderURL(orderID, 'address'),
    order_id: orderID,
  };
}

export const OrderAddressLineItem: React.FC<{
  orderId: string;
  address: AppointmentDetailsOrder['address'];
  label?: string;
  showEdit?: boolean;
  movingAddressKind?: 'origin' | 'destination';
}> = ({ orderId, address, label = 'Address', showEdit = true, movingAddressKind }) => (
  <LineItem
    label={label}
    action={
      showEdit && (
        <TrackedLinkAction
          params={{
            text: 'Edit',
            url: movingAddressKind
              ? `${orderMovingAddressURL(orderId)}?kind=${movingAddressKind}`
              : orderURL(orderId, 'address'),
            trackingParams: updateAddressTrackingParams(orderId),
          }}
        />
      )
    }
  >
    <div>
      <div>
        {address.street} {address.aptsuite}
      </div>
      <div>
        {address.city}, {address.state} {address.zip}
      </div>
    </div>
  </LineItem>
);
