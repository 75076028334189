import React from 'react';
import { ProgressBar } from '@clutter/clean';
import { Values } from './data';
import { Item, ItemContainer, ProgressContainer } from '../progress_helpers';

export const Progress: React.FC<{
  currentStep: number;
  goToStep: (stepName: string) => void;
  values: Values;
}> = ({ currentStep }) => {
  // Hide progress bar on first step or last step
  if (currentStep >= 3) return null;

  let percentage;
  if (currentStep < 1) {
    percentage = 0.33;
  } else if (currentStep < 2) {
    percentage = 0.66;
  } else {
    percentage = 1;
  }

  return (
    <ProgressContainer>
      <ProgressBar percentage={percentage} />
      <ItemContainer>
        <Item highlighted={percentage === 0.33} clickable={false}>
          Your Items
        </Item>
        <Item highlighted={percentage === 0.66} clickable={false}>
          Upload Photos/Videos
        </Item>
        <Item highlighted={percentage === 0.9} clickable={false}>
          Review
        </Item>
      </ItemContainer>
    </ProgressContainer>
  );
};
