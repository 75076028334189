import React from 'react';

import { DateTime } from 'luxon';
import { orderURL } from '@portal/config/routes';
import { OrderStateEnum, RetailOrderFragment } from '@portal/schema';
import { AppointmentDetailsOrder } from '../full_panel';
import { LineItem, TrackedLinkAction } from '../../line_item';
import { wtLinkParams } from './constants';

const FROM_ISO_OPTIONS = { setZone: true };

export function viewSupplyKitAppointmentParams(orderID: string, supplyKitID: string) {
  return {
    ...wtLinkParams,
    objectName: 'supply_kit_view',
    label: 'View',
    value: orderURL(supplyKitID),
    order_id: orderID,
  };
}

export const SupplyKitDeliveryLineItem: React.FC<{ order: AppointmentDetailsOrder; delivery: RetailOrderFragment }> = ({
  order,
  delivery,
}) => (
  <LineItem
    label="Supply Kit Delivery"
    action={
      delivery.state !== OrderStateEnum.Completed &&
      delivery.state !== OrderStateEnum.Canceled && (
        <TrackedLinkAction
          params={{
            text: 'View',
            url: orderURL(delivery.id),
            trackingParams: viewSupplyKitAppointmentParams(order.id, delivery.id),
          }}
        />
      )
    }
  >
    {delivery.state === OrderStateEnum.Canceled && <div>Canceled</div>}
    {delivery.state === OrderStateEnum.Completed && (
      <div>
        Completed on{' '}
        {DateTime.fromISO(delivery.scheduled, FROM_ISO_OPTIONS).toLocaleString({
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })}
      </div>
    )}
    {delivery.state !== OrderStateEnum.Completed && delivery.state !== OrderStateEnum.Canceled && (
      <div>
        {DateTime.fromISO(delivery.scheduled, FROM_ISO_OPTIONS).toLocaleString({
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })}
      </div>
    )}
  </LineItem>
);
