import { COLORS, Text, UnstyledButton } from '@clutter/clean';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

import { Prompt } from '@portal/components/account_resolution/prompt';
import { Spinner } from '@portal/components/helpers/spinner';
import { BackArrow } from '@portal/components/shared/icons';
import { Create as TicketCreateWithContactPreferences } from '@portal/components/ticket/create_with_contact_preferences';
import { TrackedClick } from '@portal/components/wt/tracked_click';
import {
  AccountResolution__OfferFragment,
  AccountResolution__ReasonFragment,
  Maybe,
  Status,
  useAccountResolutionOfferCreateMutation,
} from '@portal/schema';
import { IEventParams } from '@portal/types/wt/event';

import { Accepted } from './accepted';
import { Extend as ExtendOffer } from './extend';

const ErrorMessage = styled(Text.Callout)`
  color: ${COLORS.toucan};
  margin-top: 16px;
`;

const BACK_BUTTON_WT_PARAMS: IEventParams = {
  action: 'click',
  container: 'title',
  objectName: 'back',
  objectType: 'image',
};

export const Offer: React.FC<{
  reason: AccountResolution__ReasonFragment;
  category: string;
  hidingHelpContent: boolean;
  hasHelpContent: boolean;
  setHidingHelpContent(shouldHide: boolean): void;
  onOfferCreate(createdOffer?: AccountResolution__OfferFragment, note?: string): void;
  onBackButton(): void;
}> = ({ reason, category, hidingHelpContent, hasHelpContent, setHidingHelpContent, onOfferCreate, onBackButton }) => {
  const [create, { loading }] = useAccountResolutionOfferCreateMutation();
  const [offer, setOffer] = useState<Maybe<AccountResolution__OfferFragment>>();
  const [noteBody, setNoteBody] = useState<string>();
  const [accepted, setAccepted] = useState<boolean>(false);
  const [customerTicketID, setCustomerTicketID] = useState<string>();
  const [rejected, setRejected] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const reasonID = reason.id;

  useEffect(() => {
    (async () => {
      const result = await create({ variables: { reasonID: reasonID } });
      if (result.data?.offerCreate?.status === Status.Ok) {
        const note = result.data.offerCreate.note || '';
        setNoteBody(note);
        setOffer(result.data.offerCreate.offer);
        onOfferCreate(result.data.offerCreate.offer ?? undefined, note);
      }
    })();
  }, [reasonID]);

  const onAccept = (newTicketID?: string) => {
    setCustomerTicketID(newTicketID);
    setAccepted(true);
  };

  const onPrevious = () => {
    if (rejected) {
      setRejected(false);
    } else if (hasHelpContent && hidingHelpContent) {
      setHidingHelpContent(false);
    } else {
      onBackButton();
    }
  };

  const onRejectedOffer = (rejectOfferNote?: Maybe<string>) => {
    setRejected(true);
    setNoteBody(rejectOfferNote || '');
  };

  if (hasHelpContent && !hidingHelpContent) {
    return null;
  }

  if (loading && !hasHelpContent) {
    return <Spinner />;
  }

  let showOffer;
  let showAccepted;
  let showTicketCreate;
  let pageName;
  if (!offer || rejected || errorMessage) {
    showTicketCreate = true;
    pageName = 'portal:help_offer_ticket';
  } else if (accepted) {
    showAccepted = true;
  } else {
    showOffer = true;
    pageName = 'portal:help_offer';
  }

  return (
    <>
      {!accepted && (
        <TrackedClick
          params={{
            ...BACK_BUTTON_WT_PARAMS,
            pageName,
            selected_reason_id: showOffer ? reason.parentID : null,
            offer_id: offer?.id,
          }}
        >
          <UnstyledButton aria-label="Previous" onClick={onPrevious}>
            <BackArrow />
          </UnstyledButton>
        </TrackedClick>
      )}
      {showTicketCreate && (
        <>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <Prompt
            isLeaf={true}
            isRoot={false}
            showHelpContent={false}
            reasonCopy={reason.copy}
            offerRejected={rejected}
          />
          <TicketCreateWithContactPreferences
            reason={reason}
            category={category}
            offerID={offer?.id}
            noteBody={noteBody}
          />
        </>
      )}
      {showAccepted && offer && <Accepted offer={offer} customerTicketID={customerTicketID} />}
      {showOffer && offer && (
        <ExtendOffer
          offer={offer}
          reasonID={reasonID}
          category={category}
          onAccept={onAccept}
          onReject={onRejectedOffer}
          onError={setErrorMessage}
        />
      )}
    </>
  );
};
