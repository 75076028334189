export enum PackageKind {
  Core = 'core',
  Plus = 'plus',
  PackingSupplies = 'PACKING_SUPPLIES',
  TruckAndDriver = 'TRUCK_AND_DRIVER',
  OnboardingService = 'ONBOARDING_SERVICE',
}

export enum PackageFeature {
  Storage = 'Storage for Your Belongings',
  PickupAndReturn = 'Pickup & Delivery',
  OnlineInventory = 'Online Photo Inventory',
  OnlineOrderManagement = 'Online Order Management',
  PackingHelp = 'Packing Help',
  PackingMaterials = 'Packing Materials',
  FurnitureDisassembly = 'Furniture Disassembly',
}

export interface IPackage {
  id: number;
  name: string;
  kind: PackageKind;
  full_pack: boolean;
}

export const PACKAGE_FEATURES: { [K in PackageFeature]: PackageKind[] } = {
  [PackageFeature.Storage]: [PackageKind.Core, PackageKind.Plus],
  [PackageFeature.PickupAndReturn]: [PackageKind.Core, PackageKind.Plus],
  [PackageFeature.OnlineInventory]: [PackageKind.Core, PackageKind.Plus],
  [PackageFeature.OnlineOrderManagement]: [PackageKind.Core, PackageKind.Plus],
  [PackageFeature.PackingHelp]: [PackageKind.Plus],
  [PackageFeature.PackingMaterials]: [PackageKind.Plus],
  [PackageFeature.FurnitureDisassembly]: [PackageKind.Plus],
};
