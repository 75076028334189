/** @jsx jsx */
import React, { useState } from 'react';

import { Input, Label, Textarea, useSnackbarContext } from '@clutter/clean';
import { css, jsx } from '@emotion/react';
import { CustomerItemFragment, CustomerItemTypeEnum, useUpdateCustomerItemMutation } from '@portal/schema';

const fieldStyle = css`
  width: 100%;
  max-width: 100%;
  margin-bottom: 24px;
  display: block;
`;

export const ItemDetailsForm: React.FC<{ item: CustomerItemFragment }> = ({ item }) => {
  const [name, setName] = useState(item.name || item.category?.name || '');
  const [contents, setContents] = useState(item.contents || '');
  const [updateItem] = useUpdateCustomerItemMutation();
  const { addSnack } = useSnackbarContext();
  const updateProperty = async (property: 'name' | 'contents', value: string) => {
    if (item[property] === value || item.category?.name === value) return;
    await updateItem({
      variables: {
        uuid: item.uuid,
        type: item.__typename as CustomerItemTypeEnum,
        input: {
          [property]: value || null,
        },
      },
    });
    addSnack({
      key: item.uuid,
      content: 'Item updated!',
    });
  };

  return (
    <React.Fragment>
      <Label htmlFor="item_name">Item name</Label>
      <Input
        id="item_name"
        css={fieldStyle}
        type="text"
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
        onBlur={(e) => updateProperty('name', e.currentTarget.value)}
      />
      <Label htmlFor="item_description">Item description</Label>
      <Textarea
        id="item_description"
        css={fieldStyle}
        name="description"
        placeholder="Describe your furniture or what’s in your box so you can easily identify them later."
        value={contents || ''}
        onChange={(e) => setContents(e.currentTarget.value)}
        onBlur={(e) => updateProperty('contents', e.currentTarget.value)}
      />
    </React.Fragment>
  );
};
