/* eslint-disable no-underscore-dangle */
import styled from '@emotion/styled';
import React from 'react';

import { Text, mq, COLORS } from '@clutter/clean';

import { referralsURL, settingsURL, supportURL, accountURL } from '@portal/config/routes';

const HelpfulLinksContainer = styled.div`
  text-align: left;
  margin: 0 auto;
  ${mq({
    width: [null, '600px'],
    padding: ['0 24px', 'initial'],
  })}
`;

const List = styled.ul`
  margin-top: 16px;
  list-style: none;
  padding: 0;
`;

const Link = styled.a`
  text-decoration: underline;
  color: ${COLORS.tealPrimary};
  &:hover {
    color: ${COLORS.__primaryButtonHover};
  }
`;

const ListItem = styled.li`
  padding: 4px 0;
`;

export const HELPFUL_LINKS = [
  { copy: 'View pricing details', link: accountURL(), target: '_self' },
  { copy: 'Manage my account', link: settingsURL(), target: '_self' },
  { copy: 'Refer a friend for up to $100 credit', link: referralsURL(), target: '_self' },
  { copy: 'Contact Customer Support', link: supportURL(), target: '_self' },
  { copy: 'Read our FAQ', link: 'https://www.clutter.com/help', target: '_blank' },
];

export const HelpfulLinks = () => (
  <HelpfulLinksContainer>
    <Text.Title size="small">Other things you can do:</Text.Title>
    <List>
      {HELPFUL_LINKS.map((o, i) => (
        <ListItem key={i}>
          <Link href={o.link} target={o.target}>
            <Text.Body>{o.copy}</Text.Body>
          </Link>
        </ListItem>
      ))}
    </List>
  </HelpfulLinksContainer>
);
