import React from 'react';

const ID = 'secure';

export const Secure: React.FC<{ title?: string }> = ({ title = 'Secure' }) => (
  <svg aria-labelledby={ID} width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title id={ID}>{title}</title>
    <path
      d="M7.27273 1.38716e-05C4.12423 1.38716e-05 1.59091 2.60921 1.59091 5.84015V8.17763V8.18472C0.547652 8.29008 0 9.1728 0 10.2451V17.9325C0 19.0763 0.923368 20 2.06676 20H12.4787C13.6221 20 14.5455 19.0763 14.5455 17.9325V10.2451C14.5455 9.1728 13.9978 8.28297 12.9545 8.17762V5.84014C12.9545 2.6092 10.4212 0 7.27273 0V1.38716e-05ZM7.27273 2.26644C9.28765 2.26644 10.9091 3.86517 10.9091 5.85435V8.17763H3.63636V5.85435C3.63636 3.86517 5.2578 2.26644 7.27273 2.26644ZM7.27273 11.1332C8.15136 11.1332 8.86364 11.8458 8.86364 12.7247C8.86364 13.2834 8.57461 13.7765 8.1392 14.0604C8.16296 14.1418 8.18182 14.2269 8.18182 14.3162V16.135C8.18182 16.6388 7.77636 17.0444 7.27273 17.0444C6.76909 17.0444 6.36364 16.6388 6.36364 16.135V14.3162C6.36364 14.2269 6.3825 14.1418 6.40625 14.0604C5.97084 13.7765 5.68182 13.2834 5.68182 12.7247C5.68182 11.8458 6.39409 11.1332 7.27273 11.1332Z"
      fill="#757575"
    />
  </svg>
);
