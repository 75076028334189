import React from 'react';

import { DateTime, Interval } from 'luxon';
import { AppointmentDetailsOrder } from '../full_panel';
import { OrderAddressLineItem } from './order_address_line_item';

export const MoveAddressLineItems: React.FC<{
  order: AppointmentDetailsOrder;
}> = ({ order }) => {
  const originAddress = order.movingOperation?.originAddress;
  const destinationAddress = order.movingOperation?.destinationAddress;
  const rescheduleable =
    Interval.fromDateTimes(DateTime.local(), DateTime.fromISO(order.scheduled).toLocal()).length('hours') > 48;

  return (
    <>
      {originAddress && (
        <OrderAddressLineItem
          orderId={order.id}
          address={originAddress}
          label="Pickup Address"
          movingAddressKind="origin"
          showEdit={rescheduleable}
        />
      )}
      {destinationAddress && (
        <OrderAddressLineItem
          orderId={order.id}
          address={destinationAddress}
          label="Delivery Address"
          movingAddressKind="destination"
          showEdit={rescheduleable}
        />
      )}
    </>
  );
};
