import React from 'react';
import { Box, COLORS, FontWeight, Text } from '@clutter/clean';
import styled from '@emotion/styled';

import { IOrderService } from '@portal/types';

import { Spacer } from '@shared/components/helpers';
import { itemNameText } from '@portal/components/helpers/inventory/item_card';

const TrackingLink = styled.a`
  color: ${COLORS.tealPrimary};
  text-decoration: underline;
  font-weight: ${FontWeight.Medium};

  &:hover {
    color: ${COLORS.tealPrimary};
  }
`;

export const Tracking: React.FC<{ service: IOrderService }> = ({ service }) => {
  if (!service.items.every((item) => !!item.shipment)) return null;

  return (
    <>
      <Spacer height="1rem" />
      <Text.Title size="medium">Tracking</Text.Title>
      <Text.Body>Click a link below to see tracking information:</Text.Body>
      <Spacer height="1rem" />

      {service.items.map((item) => (
        <Box key={item.uuid}>
          <TrackingLink href={item.shipment!.trackingUrl} target="_blank">
            {itemNameText(item)}
          </TrackingLink>
        </Box>
      ))}

      <hr />
    </>
  );
};
