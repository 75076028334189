import * as React from 'react';
import { useContext } from 'react';

import { Text } from '@shared/components/bootstrap';
import { Button } from '@shared/components/inventory_capture/button';
import { InventoryContext } from '@shared/components/inventory_capture/inventory_context';

import { Spacer } from '../helpers';

export const StorageOrMoving: React.FC = () => {
  const { data, onSelect } = useContext(InventoryContext);

  /* hasExtraItems is reset to prevent certain components from appearing after user swaps between
   'Moving out' and 'Storing extra things' */
  return (
    <>
      <Text tag="p" style="info">
        <strong>Are you moving out or storing extra things?</strong>
      </Text>

      <Button
        active={data.isFullMoveOut === true}
        onClick={() => onSelect({ ...data, isFullMoveOut: true, hasExtraItems: undefined })}
        text="Moving out"
      />
      <Spacer width="1rem" inline />
      <Button
        active={data.isFullMoveOut === false}
        onClick={() => onSelect({ ...data, isFullMoveOut: false, hasExtraItems: undefined })}
        text="Storing extra things"
      />
    </>
  );
};
