import { Box, UnstyledButton, useSnackbarContext } from '@clutter/clean';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Spinner } from '@portal/components/helpers';
import { BackArrow } from '@portal/components/shared/icons';
import {
  AccountResolution__OfferFragment,
  AccountResolution__OfferKind,
  AccountResolution__ReasonChild,
  BuildReviewInput,
  Rating,
  Review__ReviewableEnum,
  useAccountResolutionReasonQuery,
  useBuildReviewMutation,
} from '@portal/schema';
import { Create as TicketCreateWithContactPreferences } from '@portal/components/ticket/create_with_contact_preferences';
import { TrackedClick } from '@portal/components/wt/tracked_click';
import { SupportFlowSlug } from '@portal/config/routes';
import { IEventParams } from '@portal/types/wt/event';
import { FEATURE_FLAG } from '@shared/config/feature_flag';

import { FollowupAnswers } from './help_content/review';
import { HelpContent } from './help_content';
import { Offer } from './offer';
import { Prompt } from './prompt';
import { QuickActions } from './quick_actions';
import { ReasonList } from './reason_list';
import { RootReasons } from './root_reasons';

const REVIEW_KIND = 'csat:account_resolution';

const RESOLUTION_ROOTS: Record<SupportFlowSlug, string> = {
  [SupportFlowSlug.Standard]: 'root',
  [SupportFlowSlug.DayOf]: 'day_of_root',
};

const ENABLED_OFFER_KINDS = [
  AccountResolution__OfferKind.FinalMonthProration,
  AccountResolution__OfferKind.WaiveRateAdjustment,
];
const BACK_BUTTON_WT_PARAMS: IEventParams = {
  action: 'click',
  container: 'title',
  objectName: 'back',
  objectType: 'image',
};

export const Wizard: React.FC<{ supportFlow: SupportFlowSlug }> = ({ supportFlow }) => {
  const [selectedReasonID, setSelectedReasonID] = useState<string | undefined>();
  const [selectedRootReason, setSelectedRootReason] = useState<AccountResolution__ReasonChild | undefined>();
  const [rootReasons, setRootReasons] = useState<AccountResolution__ReasonChild[]>([]);
  const [showContactUs, setShowContactUs] = useState<boolean>(false);
  const [hidingHelpContent, setHidingHelpContent] = useState<boolean>(false);
  const [offer, setOffer] = useState<AccountResolution__OfferFragment | undefined>();
  const [noteBody, setNoteBody] = useState<string>();
  const history = useHistory();

  const { addSnack } = useSnackbarContext();

  const [buildReview] = useBuildReviewMutation();
  const { data, loading } = useAccountResolutionReasonQuery({
    variables: { id: selectedReasonID, rootName: selectedReasonID ? undefined : RESOLUTION_ROOTS[supportFlow] },
  });

  useEffect(() => {
    history.listen(() => {
      setSelectedReasonID(undefined);
      setSelectedRootReason(undefined);
      setRootReasons([]);
      setShowContactUs(false);
    });
  }, []);

  const reason = data?.reason;
  const isRoot = !reason?.parentID;
  const isLeaf = reason?.children?.length === 0;

  const onSelect = (selectedReason: AccountResolution__ReasonChild, setRootReason?: boolean): void => {
    if (setRootReason) {
      setSelectedRootReason(selectedReason);
      if (reason && isRoot) {
        setRootReasons(reason.children);
      }
    }
    setSelectedReasonID(selectedReason.id);
  };

  const onBack = (): void => {
    if (reason && !showContactUs) {
      setSelectedReasonID(reason.parentID!);
      setOffer(undefined);
      setNoteBody(undefined);
    } else {
      setShowContactUs(false);
    }
  };

  const onHelpContentReview = (rating: Rating, followupAnswers?: FollowupAnswers) => {
    if (reason) {
      const input: BuildReviewInput = {
        kind: REVIEW_KIND,
        reviewable: { id: reason.id, type: Review__ReviewableEnum.AccountResolutionReason },
        rating,
        reasons: JSON.stringify(followupAnswers ?? {}),
      };

      // Following the pattern of PageReview by disregarding the actual result of the mutation
      buildReview({ variables: { input } });

      addSnack({
        key: 'help_content_review_success',
        content: 'Thanks for sharing your feedback',
      });
    }
  };

  const onOfferCreate = (createdOffer?: AccountResolution__OfferFragment, note?: string) => {
    setOffer(createdOffer);
    setNoteBody(note);
  };

  const hasHelpContent = !!reason?.zendeskCopy;
  const showHelpContent = hasHelpContent && !showContactUs && !hidingHelpContent;
  const tryOffer =
    FEATURE_FLAG.automated_resolutions_2021_03 && !!reason?.offerKind && ENABLED_OFFER_KINDS.includes(reason.offerKind);
  const hasHelpAndTryOffer = tryOffer && hasHelpContent;
  const showOffer = (hasHelpAndTryOffer && !showHelpContent && !showContactUs) || (tryOffer && !hasHelpContent);
  const showTicketCreate = isLeaf && !showHelpContent && !showOffer;
  const pageName =
    (showTicketCreate && 'portal:help_ticket') || (showHelpContent && 'portal:help_content') || 'portal:help_reasons';
  const showTitle = !showOffer;
  const showBack = !isRoot && !showOffer;

  if (loading) {
    return <Spinner />;
  }

  if (!reason) {
    return null;
  }

  return (
    <>
      {showBack && (
        <TrackedClick
          params={{
            ...BACK_BUTTON_WT_PARAMS,
            pageName: pageName,
            selected_reason_id: reason.parentID,
          }}
        >
          <UnstyledButton aria-label="Previous" onClick={onBack}>
            <BackArrow />
          </UnstyledButton>
        </TrackedClick>
      )}
      {showTitle && (
        <Prompt isLeaf={isLeaf} isRoot={isRoot} reasonCopy={reason.copy} showHelpContent={showHelpContent} />
      )}
      {showHelpContent && reason.zendeskCopy && (
        <HelpContent
          zendeskCopy={reason.zendeskCopy}
          reasonID={reason.id}
          setHidingHelpContent={setHidingHelpContent}
          offer={offer}
          onContactUs={() => setShowContactUs(true)}
          onReview={onHelpContentReview}
        />
      )}
      {showTicketCreate && selectedRootReason && (
        <TicketCreateWithContactPreferences reason={reason} category={selectedRootReason.copy} noteBody={noteBody} />
      )}
      {tryOffer && selectedRootReason ? (
        <Offer
          hasHelpContent={!!reason.zendeskCopy}
          hidingHelpContent={hidingHelpContent}
          reason={reason}
          category={selectedRootReason.copy}
          setHidingHelpContent={setHidingHelpContent}
          onOfferCreate={onOfferCreate}
          onBackButton={() => setSelectedReasonID(reason.parentID!)}
        />
      ) : (
        <>
          {isRoot && (
            <>
              <RootReasons
                reasons={reason.children}
                onSelect={(selected) => onSelect(selected, true)}
                supportFlowSlug={supportFlow}
              />
              <Box padding="54px 0 0 0">
                <QuickActions />
              </Box>
            </>
          )}
          {!isLeaf && !isRoot && (
            <>
              <ReasonList reasons={reason.children} onSelect={onSelect} />
              {supportFlow !== SupportFlowSlug.DayOf && (
                <Box padding="40px 0 0 0">
                  <RootReasons
                    reasons={rootReasons}
                    onSelect={(selected) => onSelect(selected, true)}
                    except={selectedRootReason}
                    supportFlowSlug={supportFlow}
                  />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
