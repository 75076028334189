import { CustomerTicketState, CustomerTicketPriority } from '@admin/types/customer_ticket';

export enum CustomerTicketMode {
  All = 'all',
  Mine = 'mine',
  OpenOrRecentlyResolved = 'open_or_recently_resolved',
  Unassigned = 'unassigned',
}

export interface ICustomerTicketFiltersInput {
  state?: CustomerTicketState;
  groupID?: string;
  category?: string;
  subCategory?: string;
  assigneeID?: string;
  accountID?: string;
  priority?: CustomerTicketPriority;
  mode?: CustomerTicketMode;
}
