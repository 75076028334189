/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { range } from 'lodash';

import styled from '@emotion/styled';
import { mq } from '@clutter/clean';

export const SMALL_WIDTH = '156px';
export const LARGE_WIDTH = '208px';
export const SMALL_HEIGHT = '226px';
export const LARGE_HEIGHT = '286px';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 24px -12px;
  min-height: 500px;
`;

// <Offset /> components left align grid items while allowing the grid to be
// responsive and center aligned. There should be one less than the maximum
// number of grid items.
const Offset = styled.div`
  margin: 8px;
  ${mq({
    width: [SMALL_WIDTH, null, null, LARGE_WIDTH],
  })}
`;

export const Offsets: React.FC<{ numOffsets?: number }> = ({ numOffsets = 5 }) => (
  <>
    {range(numOffsets).map((i) => (
      <Offset key={i} />
    ))}
  </>
);
