import { capitalize } from 'lodash';
import React from 'react';
import styled from '@emotion/styled';
import { COLORS, FontWeight, getPropValue, Text } from '@clutter/clean';

import { OrderTypeEnum } from '@portal/schema';

export const Message = styled(Text.Caption)<{ color: string }>`
  color: ${getPropValue('color')};
  font-weight: ${FontWeight.Medium};
  margin-top: 8px;
  min-height: 17px;
`;

export const OrderType = ({ type }: { type: OrderTypeEnum }) => {
  switch (type) {
    case OrderTypeEnum.Pickup:
      return <>Pickup</>;
    case OrderTypeEnum.Return:
      return <>Delivery</>;
    case OrderTypeEnum.Move:
      return <>Move</>;
    case OrderTypeEnum.SelfStorageShuttle:
      return <>Self-Storage Shuttle</>;
    default:
      return <>{type.split('_').map(capitalize).join(' ')}</>;
  }
};

export const Link = styled.a`
  color: ${COLORS.tealPrimary};

  &:hover {
    color: ${
      // eslint-disable-next-line no-underscore-dangle
      COLORS.__primaryButtonHover
    };
  }
`;
