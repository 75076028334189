import React from 'react';

import { Step } from '@portal/components/claims/step';
import { InputGroup } from '@shared/components/bootstrap';

import { IssueSelectionForm } from './form';

export const Description = () => (
  <IssueSelectionForm field="description">
    {({ loading, selection, onChange }) => (
      <>
        <Step.Title>Please provide all relevant details to this issue.</Step.Title>
        <Step.Subtitle>
          Please include any dates, appointment numbers, invoice numbers, and/or any other information that will help
          the Trust and Safety team when investigating this issue.
        </Step.Subtitle>
        <InputGroup>
          <textarea
            disabled={loading}
            className="form-control"
            placeholder="Start typing here..."
            onChange={(event) => onChange({ ...selection, description: event.target.value || undefined })}
            value={selection.description || ''}
          />
        </InputGroup>
      </>
    )}
  </IssueSelectionForm>
);
