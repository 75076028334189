import { IPackage } from './package';
import { IPackageSet } from './package_set';

export enum OrderSubtypeKind {
  Onboarding = 'Onboarding',
  Final = 'Final',
}

export interface IPackageSetEntry {
  id: string;
  storageCostMultiplier: number;
  costPerCuft: number;
  fixedCost: number;
  minimumTermMonths: number;
  package: IPackage;
  packageSet: IPackageSet;
  orderSubtype: OrderSubtypeKind;
  amount?: number;
}
