import { COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { supportURL } from '@portal/config/routes';
import { PaymentPlanDetail__OfferFragment as PaymentPlanOffer } from '@portal/schema';

const Title = styled(Text.Title)`
  color: ${COLORS.tealPrimary};
  margin-bottom: 24px;
`;

const Body = styled(Text.Body)`
  padding-bottom: 24px;
`;

const CareLink = styled.a`
  color: ${COLORS.tealPrimary};
  text-decoration: underline;
  :hover {
    color: ${COLORS.tealDark};
    text-decoration: underline;
  }
`;

export const Expired: React.FC<{ offer: PaymentPlanOffer }> = ({ offer }) => (
  <>
    <Title size="large">Payment Plan Expired</Title>
    <Body>The payment plan #{offer.id} has expired and is no longer available.</Body>
    <Body>
      Please reach out to our <CareLink href={supportURL()}>Customer Care Team</CareLink> if you need any further
      assistance.
    </Body>
  </>
);
