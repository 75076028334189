import React from 'react';

import { Theme } from '@portal/components/helpers/theme';

import { UpdatePlan } from '../../../plan/update';

export const Plan: React.FC = () => (
  <Theme name="default">
    <UpdatePlan />
  </Theme>
);
