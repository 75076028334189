import React from 'react';

import { COLORS, mq, Text } from '@clutter/clean';
import styled from '@emotion/styled';

const ExtraInfoTagText = styled(Text.SmallCaps)`
  ${mq({
    padding: ['0.5rem 0.5rem', '0.2rem 0.5rem'],
  })}
`;

const ExtraInfoTagWrapper = styled.div`
  background-color: ${COLORS.tealBackground};
  display: inline-block;
  align-items: center;
  border-radius: 4px;
`;

export const ExtraInfoTag: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <ExtraInfoTagWrapper>
    <ExtraInfoTagText color={COLORS.tealPrimary}>{children}</ExtraInfoTagText>
  </ExtraInfoTagWrapper>
);
