import { Layout } from '@clutter/clean';
import { WTProvider } from '@clutter/wt';
import '@shared/initializers/sentry';
import * as React from 'react';
import ReactRailsUJS from 'react_ujs';

import { App } from './app';
import { Login } from './login/index';
import { Create as CreatePassword } from './password/create/index';
import { Edit as EditPassword } from './password/edit/index';
import { Reset as ResetPassword } from './password/reset';

const getDisplayName = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): string =>
  Component.displayName || Component.name || 'Component';

const withLayout = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<P> => {
  const withLayoutComponent = (props: P) => (
    <Layout>
      <WTProvider params={{}}>
        <Component {...props} />
      </WTProvider>
    </Layout>
  );
  withLayoutComponent.displayName = `withLayout(${getDisplayName(Component)})`;
  return withLayoutComponent;
};

ReactRailsUJS.getConstructor = (name: string) => {
  switch (name) {
    case 'App':
      return withLayout(App);
    case 'CreatePassword':
      return withLayout(CreatePassword);
    case 'EditPassword':
      return withLayout(EditPassword);
    case 'Login':
      return withLayout(Login);
    case 'ResetPassword':
      return withLayout(ResetPassword);
    default:
      throw new Error(`unknown constructor for ${name}`);
  }
};
