import { AriaAttributes, SVGAriaEnhancements } from '@shared/components/helpers/svg_aria_enhancements';
import React, { FC } from 'react';

export const CreditCard: FC<AriaAttributes> = ({ title, description: desc }) => {
  const Aria = SVGAriaEnhancements(title, desc);

  return (
    <svg
      width="40"
      height="34"
      viewBox="0 0 40 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={Aria.ariaLabelledby}
    >
      <Aria.Title />
      <Aria.Desc />
      <g clipPath="url(#clip0_22_1536)">
        <path
          d="M29.8353 0.37878C31.2471 -0.142053 32.8 0.568174 33.2706 1.98863L39.5765 19.1761C40.0941 20.5966 39.3883 22.1591 37.9765 22.6799L10.1647 32.9072C8.75296 33.428 7.20001 32.7178 6.72943 31.2973L0.423544 14.1098C-0.0941035 12.6894 0.611779 11.1269 2.02354 10.6061L29.8353 0.37878Z"
          fill="#00A499"
          stroke="#00A499"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.22356 32.1496C8.47062 32.1496 7.81179 31.6761 7.5765 31.0133L1.27062 13.7784C1.12944 13.3523 1.12944 12.8314 1.31767 12.4053C1.55297 11.9792 1.88238 11.6477 2.35297 11.5057L13.7412 7.29166L9.41179 32.1496C9.31768 32.1496 9.27062 32.1496 9.22356 32.1496Z"
          fill="#A1E6E0"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.85882 23.2007C7.29411 23.2007 6.77646 22.822 6.58823 22.3011L4.98823 17.8504C4.75294 17.1401 5.08235 16.3352 5.78823 16.0985L11.4823 14.0151C11.6235 13.9678 11.8118 13.9204 11.9529 13.9204C12.5176 13.9204 13.0353 14.2992 13.2235 14.8201L14.8235 19.2708C15.0588 19.981 14.7294 20.786 14.0235 21.0227L8.32941 23.106C8.18823 23.2007 7.99999 23.2007 7.85882 23.2007Z"
          fill="#FFEABF"
        />
        <path
          d="M11.9529 14.2519C12.3765 14.2519 12.7529 14.4886 12.8941 14.9148L14.4941 19.3655C14.6824 19.8864 14.4 20.5019 13.8824 20.6913L8.18824 22.7746C8.09412 22.822 7.95295 22.822 7.85883 22.822C7.4353 22.822 7.05883 22.5852 6.91765 22.1591L5.31765 17.7083C5.12942 17.1875 5.41177 16.572 5.92942 16.3826L11.6235 14.2992C11.7177 14.2992 11.8118 14.2519 11.9529 14.2519ZM11.9529 13.5417C11.7647 13.5417 11.5765 13.589 11.3412 13.6364L5.64707 15.767C5.22354 15.9091 4.89412 16.2405 4.65883 16.6667C4.47059 17.0454 4.47059 17.5189 4.61177 17.9924L6.21177 22.4432C6.44707 23.106 7.10589 23.5795 7.81177 23.5795C8.00001 23.5795 8.18824 23.5322 8.42354 23.4848L14.1177 21.4015C14.5412 21.2595 14.8706 20.928 15.1059 20.5019C15.2941 20.0757 15.3412 19.6023 15.1529 19.1761L13.5529 14.678C13.3177 14.0151 12.6588 13.5417 11.9529 13.5417Z"
          fill="#00A499"
        />
        <path
          d="M13.8824 20.6913L8.18825 22.7746C7.67061 22.964 7.05884 22.6799 6.87061 22.1591L5.27061 17.7083C5.08237 17.1875 5.36473 16.572 5.88237 16.3826L11.5765 14.2992C12.0941 14.1098 12.7059 14.3939 12.8941 14.9148L14.4941 19.3655C14.6824 19.9337 14.4471 20.5019 13.8824 20.6913Z"
          fill="#FFEABF"
          stroke="#FFEABF"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.75293 15.3882L8.98822 16.0038L10.1647 16.3352C10.447 16.4299 10.7765 16.4299 11.0588 16.2879L12.1882 15.8617"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.37646 18.3712L8.79999 18.5606C9.03529 18.6553 9.12941 18.9394 9.03529 19.1761L8.84705 19.6022"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3883 18.75L10.9647 18.5606C10.7294 18.4659 10.6353 18.1818 10.7294 17.9451L10.9177 17.5189"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.8118 17.8504L13.6941 17.1875"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.07059 19.9337L8 19.2708"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.98823 16.0038L8.28235 17.0455C8.09411 17.2822 7.85882 17.5189 7.57646 17.6136L6.44705 17.9924"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.0588 21.7329L10.8235 21.1174L9.64706 20.786C9.36471 20.6913 9.0353 20.6913 8.75295 20.8333L7.62354 21.2595"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8235 21.1174L11.4823 20.0757C11.6706 19.839 11.9059 19.6023 12.1882 19.5076L13.3176 19.0814"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.0443 16.5872L8.47302 26.3335L9.63349 29.5363L36.2048 19.79L35.0443 16.5872Z"
          fill="#00404D"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.7294 7.29165C26.4 6.34468 26.5883 5.35036 27.2 4.59279C26.7294 4.49809 26.2588 4.54544 25.7883 4.73483C24.3765 5.25567 23.6706 6.81817 24.1883 8.23862C24.7059 9.65908 26.2588 10.3693 27.6706 9.84847C28.1412 9.65908 28.5177 9.37499 28.8 9.04355C27.8588 8.85415 27.0588 8.23862 26.7294 7.29165Z"
          fill="#F79C94"
          stroke="#F79C94"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.2824 3.78787C27.8118 3.97726 27.4353 4.26135 27.153 4.59279C28.0941 4.73484 28.8941 5.39772 29.2235 6.34469C29.553 7.29166 29.3647 8.28597 28.753 9.04355C29.2235 9.13825 29.6941 9.0909 30.1647 8.9015C31.5765 8.38067 32.2824 6.81817 31.7647 5.39772C31.2471 3.97726 29.6941 3.26703 28.2824 3.78787Z"
          stroke="#A1E6E0"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.2235 6.3447C28.8941 5.39773 28.0471 4.7822 27.153 4.5928C26.5412 5.30303 26.353 6.3447 26.6824 7.29167C27.0118 8.23864 27.8588 8.85417 28.7529 9.04356C29.3647 8.28599 29.6 7.29167 29.2235 6.3447Z"
          fill="#00404D"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.5765 19.2235C40.0941 20.6439 39.3883 22.2064 37.9765 22.7273L10.1647 32.9072C8.75297 33.428 7.20002 32.7178 6.72944 31.2973L0.423553 14.1098"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.27061 17.7083C5.08237 17.1875 5.36473 16.572 5.88237 16.3826L11.5765 14.2992C12.0941 14.1098 12.7059 14.3939 12.8941 14.9148L14.4941 19.3655C14.6824 19.8863 14.4 20.5019 13.8824 20.6913"
          stroke="#00404D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_1536">
          <rect width="40" height="33.3333" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
