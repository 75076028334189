import React from 'react';

import { Theme } from '@portal/components/helpers/theme';

import { Request as CertificateOfInsuranceRequest } from './certificate_of_insurance/request';

export const CertificateOfInsurance: React.FC = () => (
  <Theme name="default">
    <CertificateOfInsuranceRequest />
  </Theme>
);
