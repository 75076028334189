import React from 'react';

import { Box } from '@clutter/clean';
import { Currency, Spacer } from '@shared/components/helpers';
import { OrderTypeEnum, PackageKindEnum } from '@portal/schema';
import { AppointmentDetailsOrder } from '../full_panel';
import { LineItem } from '../../line_item';
import { InfoTooltip, TooltipList } from './info_tooltip';

const CONTENT: Partial<
  Record<PackageKindEnum, { name: React.ReactNode; tooltipContent: React.ReactNode } | undefined>
> = {
  [PackageKindEnum.OnboardingService]: {
    name: 'Warehouse Fee',
    tooltipContent: (
      <>
        <p>We’ll load your items onto our truck, drive them to our warehouse, and move them in.</p>
        <p>Here’s what’s included:</p>
        <TooltipList>
          <li>
            <span>Full-size truck</span>
          </li>
          <li>
            <span>Driver</span>
          </li>
          <li>
            <span>Loading and unloading</span>
          </li>
        </TooltipList>
      </>
    ),
  },
  [PackageKindEnum.GasFee]: {
    name: 'Gas Fee',
    tooltipContent: undefined,
  },
  [PackageKindEnum.TimeSlotSelectionFee]: {
    name: 'Scheduled Arrival Fee',
    tooltipContent: undefined,
  },
};

const SMART_STORAGE_DISPLAYED_KINDS = new Set([
  PackageKindEnum.OnboardingService,
  PackageKindEnum.PackingSupplies,
  PackageKindEnum.GasFee,
  PackageKindEnum.TimeSlotSelectionFee,
]);

const MOVING_DISPLAYED_KINDS = new Set([PackageKindEnum.GasFee, PackageKindEnum.TimeSlotSelectionFee]); // Packing Supplies are already handled by `MovePackingSuppliesLineItem`

const formatAmount = (value: number | undefined) =>
  value === undefined ? '' : value === 0 ? 'Free' : <Currency value={value} precision="automatic" />;

export const OrderFeeLineItems: React.FC<{ order: AppointmentDetailsOrder }> = ({ order }) => (
  <>
    {order.accountPackages
      .filter((p) =>
        (order.type === OrderTypeEnum.Move ? MOVING_DISPLAYED_KINDS : SMART_STORAGE_DISPLAYED_KINDS).has(p.kind),
      )
      .map(({ kind, id, name, amount }) => (
        <LineItem
          key={id}
          label={
            <Box.Flex flexWrap="wrap">
              {CONTENT[kind]?.name ?? name}
              <Spacer width="6px" inline={true} />
              {CONTENT[kind]?.tooltipContent && <InfoTooltip text={CONTENT[kind]?.tooltipContent} />}
            </Box.Flex>
          }
        >
          {formatAmount(amount)}
        </LineItem>
      ))}
  </>
);
