import React from 'react';
import { DateTime } from 'luxon';
import { AccountResolution__OfferFragment } from '@portal/schema';
import { Currency } from '@shared/components/helpers';

export const Summary: React.FC<{ offer: AccountResolution__OfferFragment }> = ({ offer }) => {
  const moveOutDate = DateTime.fromISO(offer.metadata.move_out_date).toLocaleString(DateTime.DATE_MED);
  const billingCycleEndDate = DateTime.fromISO(offer.metadata.billing_cycle_end_date).toLocaleString(DateTime.DATE_MED);

  return (
    <>
      Thanks so much for being a valued Clutter customer! We’re happy to refund your unused days of storage from{' '}
      {moveOutDate} to {billingCycleEndDate}, for a total refund of{' '}
      <Currency value={Number(offer.metadata.refund_amount)} precision="automatic" />.
    </>
  );
};
