import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { InputGroup } from '@shared/components/bootstrap';

import { Step } from '@portal/components/claims/step';

import { ClaimPropertySelectionForm } from './form';

export const Declaration = () => (
  <ClaimPropertySelectionForm field="declaration">
    {({ loading, selection, onChange }) => (
      <>
        <Step.Title>Please estimate the repair cost of the damaged property.</Step.Title>
        <Step.Subtitle>We’ll automatically round to the nearest dollar.</Step.Subtitle>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              {loading ? <FontAwesomeIcon icon="spinner" spin /> : <FontAwesomeIcon icon="dollar-sign" />}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <input
            className="form-control"
            type="number"
            min={0}
            max={1000000}
            placeholder="Value"
            onChange={(event) => onChange({ ...selection, declaration: Number(event.target.value) || undefined })}
            value={selection.declaration || ''}
          />
        </InputGroup>
      </>
    )}
  </ClaimPropertySelectionForm>
);
