import { COLORS, FontWeight, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { groupBy } from 'lodash';
import React from 'react';

import { SelfStorage__UpcomingSubscriptionFragment, SelfStorage__FacilityFragment } from '@portal/schema';

import { Subscription } from './subscription';

const OfficeHours = styled.div`
  margin-top: 12px;
`;

const ServiceTitle = styled(Text.Title)`
  color: ${COLORS.panther};
`;

const FacilityTitle = styled(Text.Title)`
  color: ${COLORS.panther};
  margin-top: 32px;
`;

const SubscriptionListContainer = styled.div`
  margin-bottom: 32px;
`;

const Footer = styled.div`
  margin-bottom: 32px;
  text-align: center;
`;

const FacilityDetails: React.FC<{
  facility: SelfStorage__FacilityFragment;
  subscriptions: SelfStorage__UpcomingSubscriptionFragment[];
}> = ({ facility, subscriptions }) => (
  <>
    <FacilityTitle size="medium">{facility.name} Facility</FacilityTitle>
    <SubscriptionListContainer>
      {subscriptions.map((subscription) => (
        <Subscription key={subscription.id} subscription={subscription} />
      ))}
    </SubscriptionListContainer>
    <Footer>
      <Text.Caption weight={FontWeight.Medium}>Clutter Self-Storage</Text.Caption>
      <Text.Caption>{facility.address.street}</Text.Caption>
      <Text.Caption>
        {facility.address.city}, {facility.address.state} {facility.address.zip}
      </Text.Caption>
      <OfficeHours>
        {facility.officeHours.map((hours, index) => (
          <Text.Caption key={index}>{hours}</Text.Caption>
        ))}
      </OfficeHours>
    </Footer>
  </>
);

export const Subscriptions: React.FC<{
  subscriptions: SelfStorage__UpcomingSubscriptionFragment[];
}> = ({ subscriptions }) => {
  const groupedSubscriptions = groupBy(subscriptions, (subscription) => subscription.facility.id);

  return (
    <>
      <ServiceTitle size="large">Your Self-Storage Services</ServiceTitle>
      {Object.keys(groupedSubscriptions).map((id) => {
        const facility = groupedSubscriptions[id][0].facility;
        return (
          <FacilityDetails
            key={`facility-${facility.id}`}
            facility={facility}
            subscriptions={groupedSubscriptions[id]}
          />
        );
      })}
    </>
  );
};
