import React from 'react';
import styled from '@emotion/styled';
import { COLORS, Input, Text } from '@clutter/clean';
import DOLLAR_SIGN from '@portal/images/dollar_sign.svg';
import { currency } from '@shared/utils/currency';

const StyledInput = styled(Input)`
  width: 100%;
`;

const LeftIcon = styled.div`
  width: 46px;
  height: 100%;
  background: ${COLORS.grayBorder};
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px 0 0 3px;
  cursor: default;
  z-index: 2;
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  background: white;
  display: inline-block;
  border-radius: 4px;
  margin: 24px 0px 8px;
  ${StyledInput} {
    z-index: 1;
    background: transparent;
    position: relative;
    &:disabled {
      background: ${COLORS.grayBackground};
    }
  }
  ${LeftIcon} + ${StyledInput} {
    padding-left: 62px;
  }
`;

export const ErrorText = styled(Text.Caption)`
  font-size: 14px;
  color: ${COLORS.toucan};
`;

export const ValueDeclaration: React.FC<{
  onChange(value?: number): void;
  declaredValue?: number;
  minimumAllowedDeclaredValue: number;
  maximumAllowedDeclaredValue?: number;
}> = ({ onChange, declaredValue, minimumAllowedDeclaredValue, maximumAllowedDeclaredValue }) => {
  const belowMinimum = declaredValue && declaredValue < minimumAllowedDeclaredValue;
  const aboveMaximum = declaredValue && maximumAllowedDeclaredValue && declaredValue > maximumAllowedDeclaredValue;

  return (
    <>
      <InputContainer>
        <LeftIcon>
          <img src={DOLLAR_SIGN} />
        </LeftIcon>
        <StyledInput
          type="number"
          step="0.01"
          value={declaredValue || ''}
          onChange={(event) => onChange(parseFloat(event.target.value) || undefined)}
          state={belowMinimum || aboveMaximum ? 'error' : undefined}
        />
      </InputContainer>
      {belowMinimum && (
        <ErrorText>The minimum declared value allowed is {currency(minimumAllowedDeclaredValue)}.</ErrorText>
      )}
      {aboveMaximum && (
        <ErrorText>The maximum declared value allowed is {currency(maximumAllowedDeclaredValue)}.</ErrorText>
      )}
    </>
  );
};
