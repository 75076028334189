import { DateTime, Interval } from 'luxon';
import * as React from 'react';
import { Box, SpinLoader, Text } from '@clutter/clean';

import { Availabilities } from '@portal/components/helpers/availabilities';
import { ScheduledDatePicker } from '@portal/components/helpers/scheduled_date_picker';
import { ScheduledIntervalPicker } from '@portal/components/helpers/scheduled_interval_picker';
import { useTimeZone } from '@portal/components/helpers/time_zone';
import {
  AvailabilitiesInputKind,
  FacilityFragment,
  OrderServiceTypeEnum,
  OrderSubtypeEnum,
  OrderTypeEnum,
  Pricing__LaborRate,
  useOrderBaseLaborRateQuery,
  useWaitlistEligibleQuery,
} from '@portal/schema';
import { buildOrderInput, IBuildOrderInputProps } from '@portal/utils';
import { Info as FacilityInfo } from '@portal/components/orders/facility/info';

import { Banner } from '@portal/components/shared/banner';
import { Base } from './base';
import { IStepProps } from '../form';
import { useOrderInput } from './order_context';

export enum ScheduledMode {
  Date,
  Time,
}

export type LaborRateData = {
  laborRate: Pricing__LaborRate;
  perMoverHourAdjustmentAmount: number;
};

const FACILITY_DISCLAIMER =
  'If you arrive after this window, you will be charged the reschedule fee as noted on the previous page.';

const ExtendedServiceAreaBanner: React.FC = () => (
  <Box margin={'24px 0'}>
    <Banner
      text={
        <Text.Callout>
          Your address falls outside of Clutter's service area, but you qualify for our Extended Service Area Delivery
          where we help customers farther away on select days of the month.
        </Text.Callout>
      }
    />
  </Box>
);

export const Scheduled: React.FC<
  IBuildOrderInputProps & {
    mode: ScheduledMode;
    date?: DateTime;
    interval?: Interval;
    subtype?: OrderSubtypeEnum;
    facilityWarehouse?: FacilityFragment;
    laborRateData?: LaborRateData;
    waitlistedDates: DateTime[];
    confirmedDate: boolean;
    isAccountCanceled: boolean;
    onLaborRate(data: LaborRateData): void;
    onDate(date: DateTime): void;
    onInterval(intervalConfig?: { interval: Interval; forced: boolean }): void;
    onNext?(): void;
    onPrev?(): void;
    onWaitlistedDates: React.Dispatch<React.SetStateAction<DateTime[]>>;
    setConfirmedDate(_: boolean): void;
    extendedServiceArea: boolean;
  } & IStepProps
> = ({
  mode,
  date,
  interval,
  subtype,
  facilityWarehouse,
  laborRateData,
  waitlistedDates,
  confirmedDate,
  isAccountCanceled,
  onDate,
  onInterval,
  onNext,
  onPrev,
  onLaborRate,
  onWaitlistedDates,
  setConfirmedDate,
  extendedServiceArea,
  ...order
}) => {
  const tz = useTimeZone({
    warehouseID: facilityWarehouse?.id,
    addressID: order.addressID,
  });
  const orderInput = useOrderInput();
  const { data, loading } = useOrderBaseLaborRateQuery({
    variables: {
      input: { ...orderInput, scheduled: undefined, laborRateID: undefined, perMoverHourAdjustmentAmount: undefined },
    },
  });
  const { data: waitlistEligibility } = useWaitlistEligibleQuery();

  if (!tz) {
    return null;
  }

  if (loading) {
    return <SpinLoader />;
  }

  const facilityInfo = facilityWarehouse && (
    <FacilityInfo facility={facilityWarehouse} disclaimer={FACILITY_DISCLAIMER} />
  );
  const extendedServiceAreaInfo = extendedServiceArea && <ExtendedServiceAreaBanner />;

  const onAddWaitlistDate = (toAdd: DateTime) => {
    onWaitlistedDates((current) => [...current, toAdd]);
  };

  const onRemoveWaitlistDate = (toRemove: DateTime) => {
    onWaitlistedDates((current) => current.filter((dt) => dt.toISODate() !== toRemove.toISODate()));
  };

  const unconfirmedOnWaitlist =
    !confirmedDate && !!waitlistedDates.find((waitlistedDate) => waitlistedDate.toISODate() === date?.toISODate());
  const valid = !!date && (confirmedDate || unconfirmedOnWaitlist);

  const waitlistEligible =
    waitlistEligibility?.eligible &&
    order.type !== OrderTypeEnum.Disposal &&
    order.serviceType !== OrderServiceTypeEnum.LongDistance &&
    order.serviceType !== OrderServiceTypeEnum.Disposal;

  return (
    <Availabilities
      tz={tz}
      date={date}
      kind={AvailabilitiesInputKind.Booking}
      key="availabilities"
      order={buildOrderInput(order)}
    >
      {({ availabilities = [], loading: availabilityLoading, from, till, fetchMore }) => {
        switch (mode) {
          case ScheduledMode.Date:
            return (
              <Base onNext={onNext} onPrev={onPrev} valid={valid}>
                <ScheduledDatePicker
                  serviceType={order.serviceType}
                  availabilities={availabilities}
                  date={date}
                  confirmedDate={confirmedDate}
                  baseLaborRate={data?.orderBaseLaborRate || undefined}
                  laborRateData={laborRateData}
                  onLaborRate={onLaborRate}
                  onDate={onDate}
                  onInterval={onInterval}
                  from={from}
                  till={till}
                  fetchMore={fetchMore}
                  additionalInfo={facilityInfo || extendedServiceAreaInfo}
                  showNav
                  waitlistEligible={waitlistEligible}
                  waitlistedDates={waitlistedDates}
                  loading={availabilityLoading}
                  onAddWaitlistDate={onAddWaitlistDate}
                  onRemoveWaitlistDate={onRemoveWaitlistDate}
                  setConfirmedDate={setConfirmedDate}
                  isAccountCanceled={isAccountCanceled}
                  isMove={order.type === OrderTypeEnum.Move}
                />
              </Base>
            );
          case ScheduledMode.Time:
            return (
              <Base onNext={onNext} onPrev={onPrev} valid={!!interval}>
                <ScheduledIntervalPicker
                  availabilities={availabilities}
                  date={date!}
                  interval={interval}
                  onInterval={onInterval}
                />
              </Base>
            );
        }
      }}
    </Availabilities>
  );
};
