import * as React from 'react';
import { DoorstepNotice } from '@portal/components/no_contact/doorstep_notice';

const HorizontalRuleDoorstepNotice: React.FC = () => (
  <>
    <DoorstepNotice />
    <hr />
  </>
);

export { HorizontalRuleDoorstepNotice as DoorstepNotice };
