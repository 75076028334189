/* eslint-disable no-underscore-dangle */
import * as React from 'react';

import styled from '@emotion/styled';
import { Box, Carousel, COLORS, toggleStyleValue, useCarousel, mq, BREAKPOINTS } from '@clutter/clean';
import { ChevronLeft, ChevronRight } from '@portal/components/shared/icons';
import { Fit, Picture, Crop } from '@shared/components/helpers';
import { useLatestCallback } from '@shared/hooks/use_latest';
import { ImageFragment } from '@portal/schema';

const LARGE_WIDTH_INT = 208;

const Slide = styled.div<{ focused: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.2s linear;
  opacity: ${toggleStyleValue('focused', 1, 0.5)};
  position: relative;
  background: ${COLORS.grayBackground};
`;

const Circle = styled.div<{ disabled: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${toggleStyleValue('disabled', COLORS.grayBorder, COLORS.cloud)};
  position: relative;

  /* Tap target */
  &::after {
    content: ' ';
    position: absolute;
    left: -8px;
    top: -8px;
    height: 40px;
    width: 40px;
  }
`;

const ButtonsContainer = styled.div`
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 42%;
  padding: 0 10px;

  ${mq({ display: ['flex', 'none'] })}
`;

const StyledPicture = styled(Picture)`
  width: 100%;
  height: 100%;
`;

const Buttons: React.FC<{ atBeginning: boolean; atEnd: boolean; onLeft: () => void; onRight: () => void }> = ({
  atBeginning,
  atEnd,
  onLeft,
  onRight,
}) => (
  <ButtonsContainer>
    <Circle
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onLeft();
      }}
      disabled={atBeginning}
    >
      <ChevronLeft color={atBeginning ? COLORS.storm : COLORS.tealPrimary} />
    </Circle>
    <Circle
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onRight();
      }}
      disabled={atEnd}
    >
      <ChevronRight color={atEnd ? COLORS.storm : COLORS.tealPrimary} />
    </Circle>
  </ButtonsContainer>
);

export const ImageCarousel: React.FC<{ images: ImageFragment[] }> = ({ images }) => {
  const carouselState = useCarousel({
    draggable: false,
    wrapAround: true,
    initialIndex: 0,
  });

  const [active, setActive] = React.useState(false);

  const onClick = (idx: number, direction = 1) => {
    carouselState.setIdx(idx + direction);
  };

  const onLeft = () => {
    if (carouselState.idx > 0) {
      onClick(carouselState.idx, -1);
    }
  };

  const onRight = () => {
    if (carouselState.idx < images.length - 1) {
      onClick(carouselState.idx);
    }
  };

  const { setIdx } = carouselState;
  const latestSetIdx = useLatestCallback(setIdx);

  React.useEffect(() => {
    let interval: any = null;
    let timeout: any;
    if (active && window.innerWidth > BREAKPOINTS.SM_INT) {
      timeout = setTimeout(() => {
        latestSetIdx((idx) => idx + 1);
        interval = setInterval(() => {
          latestSetIdx((idx) => idx + 1);
        }, 1000);
      }, 300);
    } else if (!active) {
      latestSetIdx(0);
    }
    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [active, latestSetIdx]);

  const focusedIdx = carouselState.idx % images.length;
  const atBeginning = carouselState.idx === 0;
  const atEnd = carouselState.idx === images.length - 1;

  return (
    <Box position="relative" onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
      <Carousel {...carouselState} slidesToShow={1}>
        {images.map((image: ImageFragment, idx: number) => (
          <Slide key={idx} focused={focusedIdx === idx}>
            <StyledPicture
              source={image.source}
              width={LARGE_WIDTH_INT}
              height={LARGE_WIDTH_INT}
              background={COLORS.cloud}
              fit={Fit.Crop}
              crop={Crop.Focalpoint}
            />
          </Slide>
        ))}
      </Carousel>
      <Buttons atBeginning={atBeginning} atEnd={atEnd} onLeft={onLeft} onRight={onRight} />
    </Box>
  );
};
