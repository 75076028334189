import React from 'react';

import { Billing__Invoice__Status as InvoiceStatus } from '@portal/schema';
import { Label, LabelKind } from '@portal/components/shared/label';

export const SEPARATE_INVOICE = 'Paid/Credited in Separate Invoice';

enum LabelText {
  Paid = 'Paid',
  Owed = 'Owed',
  Forgiven = 'Forgiven',
  FullRefund = 'Full Refund',
  PartialRefund = 'Partial Refund',
  CombinedPayment = 'Combined Payment',
}

const labelText = (status: InvoiceStatus, forgiven: boolean, forgivenessReasonKind?: string | null) => {
  if (forgiven) {
    if (forgivenessReasonKind === SEPARATE_INVOICE) {
      return LabelText.CombinedPayment;
    } else {
      return LabelText.Forgiven;
    }
  }
  switch (status) {
    case InvoiceStatus.Failed:
    case InvoiceStatus.NotAttempted:
    case InvoiceStatus.PartiallyPaid:
      return LabelText.Owed;
    case InvoiceStatus.Paid:
      return LabelText.Paid;
    case InvoiceStatus.Refunded:
      return LabelText.FullRefund;
    case InvoiceStatus.PartiallyRefunded:
      return LabelText.PartialRefund;
  }
};

const labelKind = (text?: LabelText | string) => {
  switch (text) {
    case LabelText.Forgiven:
      return LabelKind.Warning;
    case LabelText.CombinedPayment:
    case LabelText.Paid:
      return LabelKind.Primary;
    case LabelText.Owed:
      return LabelKind.Danger;
    default:
      return LabelKind.Default;
  }
};

export const StatusLabel: React.FC<{
  status: InvoiceStatus;
  forgiven: boolean;
  forgivenessReasonKind?: string | null;
  amountDue: number;
  textOverride?: string;
  className?: string;
}> = ({ status, forgiven, forgivenessReasonKind, amountDue, textOverride, className }) => {
  const text = textOverride || labelText(status, forgiven, forgivenessReasonKind);
  const kind = labelKind(text);

  if (!text || amountDue <= 0) {
    return null;
  }

  return (
    <Label className={className} kind={kind}>
      {text}
    </Label>
  );
};
