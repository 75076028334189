import React from 'react';
import { ReviewWizard } from '@clutter/clean';
import { Rating, useBuildReviewMutation, Review__ReviewableEnum } from '@portal/schema';

export const PhotoCSATReview: React.FC<{ reviewableID: string; reviewableType: Review__ReviewableEnum }> = ({
  reviewableID,
  reviewableType,
}) => {
  const [submit] = useBuildReviewMutation();
  return (
    <ReviewWizard
      question="How would you rate your photos for this item?"
      prompts={{
        GOOD: {
          answer: 'Good',
          followup: {
            title: 'What went well?',
            tags: ['I can easily tell what’s inside the box', 'In-focus', 'Good lighting'],
          },
        },
        POOR: {
          answer: 'Poor',
          followup: {
            title: 'What could improve?',
            tags: ['Dark or blurry', 'Photos show the wrong item', 'I can’t tell what is inside the box'],
          },
        },
      }}
      onSubmit={({ rating, comments, reasons }) => {
        const input = {
          rating: rating as Rating,
          comments,
          reasons: JSON.stringify(reasons ?? {}),
          kind: 'csat:item:photos',
          reviewable: {
            id: reviewableID,
            type: reviewableType,
          },
        };
        submit({ variables: { input } });
      }}
    />
  );
};
