import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useShipmentLabelsQuery, Maybe } from '@portal/schema';

import styled from '@emotion/styled';

import { Text, COLORS, Box, SpinDotsLoader } from '@clutter/clean';
import { LocationTruckTracking } from '@portal/components/shared/icons';
import { BOX_NAMES } from './steps/shipping/shipping';

const Icon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

const Header = styled(Text.Title)`
  margin-bottom: 16px;
`;

const Subheader = styled(Text.Body)`
  margin-bottom: 32px;
`;

const LabelLink = styled.a`
  color: ${COLORS.tealPrimary};
  &:hover {
    color: ${COLORS.tealBrand};
  }
`;

const DisabledLabelLink = styled(Text.Body)`
  color: ${COLORS.tealPrimary};
  &:hover {
    color: ${COLORS.tealBrand};
  }
`;

const LabelError = styled(Text.Body)`
  color: ${COLORS.toucan};
`;

const ShipmentContents = styled(Text.Body)`
  color: ${COLORS.storm};
  margin-bottom: 24px;
`;

const LoaderContainer = styled(Box)`
  width: 100%;
  text-align: center;
`;

const Label: React.FC<{ label?: Maybe<string>; contents: string; index: number }> = ({ label, contents, index }) => {
  if (!label) {
    return (
      <>
        <DisabledLabelLink>
          <u>Print Label - Box {BOX_NAMES[index]}</u>
        </DisabledLabelLink>
        <LabelError>
          This label is still being created. Please check back again later or contact support for help.
        </LabelError>
        <ShipmentContents>"{contents}"</ShipmentContents>
      </>
    );
  }

  return (
    <>
      <LabelLink href={label} target="_blank" rel="noreferrer">
        <u>Print Label - Box {BOX_NAMES[index]}</u>
      </LabelLink>
      <ShipmentContents>"{contents}"</ShipmentContents>
    </>
  );
};

export const PrintLabels: React.FC<RouteComponentProps<{ orderID: string }>> = ({
  match: {
    params: { orderID },
  },
}) => {
  const { data, loading } = useShipmentLabelsQuery({ variables: { orderId: orderID! } });

  if (loading || !data) {
    return (
      <LoaderContainer>
        <SpinDotsLoader />
      </LoaderContainer>
    );
  }

  const labels = data.shipmentLabels;

  return (
    <>
      <Icon>
        <LocationTruckTracking />
      </Icon>
      <Header size="large">Your shipping labels are ready to print!</Header>
      <Subheader>
        Print your labels and ship your items out with {labels[0].carrier} within 30 days. We'll let you know when they
        arrive.
      </Subheader>
      {labels.map((label, i) => (
        <Label key={label.easyPostShipmentId} label={label.shipmentLabel} contents={label.shipmentContents} index={i} />
      ))}
    </>
  );
};
