import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Spinner } from '@portal/components/helpers';
import { useOrderSupplyKitsQuery } from '@portal/schema';
import { SupplyKit } from './supply_kits/supply_kit';

import { Container } from './container';

export const SupplyKits: React.FC<RouteComponentProps<{ orderID: string }>> = ({
  match: {
    params: { orderID },
  },
}) => {
  const { data, loading } = useOrderSupplyKitsQuery({ variables: { orderID } });

  if (loading || !data) {
    return <Spinner />;
  }

  const { order } = data;

  return (
    <Container breadcrumb="Your Supply Kits" orderID={order.id} scheduled={order.scheduled}>
      <SupplyKit order={order} />
    </Container>
  );
};
