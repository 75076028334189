import React from 'react';
import styled from '@emotion/styled';
import { Text, Checkbox } from '@clutter/clean';

const Container = styled.div`
  margin-top: 32px;
`;

const Title = styled(Text.Title)`
  padding-bottom: 8px;
`;

export const DisposalAgentOptions: React.FC<{
  waiveFees: boolean;
  waiveDocuments: boolean;
  setWaiveFees(waiveFees: boolean): void;
  setWaiveDocuments(waiveDocuments: boolean): void;
}> = ({ waiveFees, waiveDocuments, setWaiveFees, setWaiveDocuments }) => (
  <Container>
    <Title size="extraSmall">Agent Options</Title>
    <Checkbox.Selector
      name="waive-fees"
      values={{ 'waive-fees': waiveFees }}
      options={[
        {
          key: 'waive-fees',
          label: `Waive Fees`,
        },
      ]}
      onChange={() => setWaiveFees(!waiveFees)}
    />
    <Checkbox.Selector
      name="waive-documents"
      values={{ 'waive-documents': waiveDocuments }}
      options={[
        {
          key: 'waive-documents',
          label: `Waive Documents`,
        },
      ]}
      onChange={() => setWaiveDocuments(!waiveDocuments)}
    />
  </Container>
);
