import styled from '@emotion/styled';
import React from 'react';

import { Spinner } from '@portal/components/helpers';
import { CONTAINER_SIDE_PADDING } from '@portal/components/shared/layout';
import { useUpcomingStorageOnboardingQuery } from '@portal/schema';
import { onboardingUpdatable } from '@portal/utils/order';

import { PlanUpdateForm } from './form';
import { UnableToUpdate } from './unable_to_update';

const Container = styled.div`
  margin: 0 ${CONTAINER_SIDE_PADDING}px;
  max-width: 750px;
`;

export const UpdatePlan: React.FC = () => {
  const { data, loading } = useUpcomingStorageOnboardingQuery();

  if (loading) {
    return <Spinner />;
  }

  const onboarding = data?.upcomingStorageOnboarding;

  return (
    <Container>
      {onboarding && onboardingUpdatable(onboarding) ? (
        <PlanUpdateForm orderID={onboarding.id} action="update" parentOrderID={onboarding.parent?.id} />
      ) : (
        <UnableToUpdate onboarding={onboarding} />
      )}
    </Container>
  );
};
