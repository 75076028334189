import React, { useEffect, useState } from 'react';

import { UUID } from '@shared/utils';

import { useAffirmUIComponent } from '@shared/hooks/use_affirm_ui_component';

export const Prequalify: React.FC<{ amount: number }> = ({ amount }) => {
  const [uuid] = useState(UUID);
  const component = useAffirmUIComponent('promo', {
    pageType: 'product',
    learnMoreShow: false,
    amount: Math.round(amount * 100),
  });

  useEffect(() => {
    component?.render(`#affirm-${uuid}`);
  }, [component, uuid]);

  return <div id={`affirm-${uuid}`} />;
};
