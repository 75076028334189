import React from 'react';
import styled from '@emotion/styled';
import { Text } from '@clutter/clean';

import { Alert } from '@shared/components/bootstrap';
import { Selector as SourcesSelector } from '@portal/components/sources/selector';

const Container = styled.div`
  margin-top: 32px;
`;

const PaymentTitle = styled(Text.Title)`
  padding-bottom: 8px;
`;

const SourcesContainer = styled.div`
  padding-bottom: 24px;
`;

export const PaymentMethodSelection: React.FC<{
  prompt: string;
  selectedSourceID?: string;
  setSelectedSourceID(sourceID: string): void;
  error?: string;
}> = ({ prompt, selectedSourceID, setSelectedSourceID, error }) => (
  <Container>
    <PaymentTitle size="extraSmall">Select a payment method</PaymentTitle>
    <Text.Body>{prompt}</Text.Body>
    <SourcesContainer>
      <SourcesSelector
        selectedSourceID={selectedSourceID}
        setSelectedSourceID={setSelectedSourceID}
        canAddPrepaid={false}
      />
    </SourcesContainer>
    {error && <Alert style="danger">{error}</Alert>}
  </Container>
);
