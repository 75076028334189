import React from 'react';

import { Text } from '@clutter/clean';
import { Spacer } from '@shared/components/helpers';
import { AddressesPanel } from '../addresses/panel';
import { Notices } from '../app/notices';

import { RewardsBanner } from '../rewards/rewards_banner';
import { Documents } from './documents';
import { Password } from './password';
import { Preferences } from './preferences';
import { ContactInformation } from './contact_information';

export const Settings: React.FC = () => (
  <>
    <Notices />
    <RewardsBanner source="account_page" />
    <Spacer height="32px" />
    <Text.Title size="large">Settings</Text.Title>
    <Spacer height="18px" />
    <Password />
    <Spacer height="18px" />
    <ContactInformation />
    <Spacer height="18px" />
    <AddressesPanel />
    <Spacer height="18px" />
    <Preferences />
    <Spacer height="18px" />
    <Documents />
  </>
);
