import { AddressDetailEnvironmentEnum, AddressDetailUnitSizeEnum } from '@portal/schema';

export enum AddressDetailBuildingType {
  House = 'House',
  Apartment = 'Apartment',
  Townhouse = 'Townhouse',
  Studio = 'Studio',
  Warehouse = 'Storage Facility / Warehouse',
  Dorm = 'Dorm / Campus Apartment',
  Commercial = 'Commercial',
  DonationCenter = 'Donation Center',
  Other = 'Other',
}

export enum AddressDetailBuilingSubtype {
  HighRise = 'High Rise',
  LowRise = 'Low Rise',
  Complex = 'Complex',
  Duplex = 'Duplex',
  Office = 'Office',
  Retail = 'Retail',
  Industrial = 'Industrial',
  Other = 'Other',
}

export enum AddressDetailBuildingRestrictionsAnswer {
  Yes = 'YES',
  No = 'NO',
  Unknown = 'UNKNOWN',
}

export enum AddressDetailEnvironment {
  Indoor = 'INDOOR',
  Outdoor = 'OUTDOOR',
}

export enum AddressDetailField {
  BuildingType = 'buildingType',
  BuildingSubtype = 'buildingSubtype',

  Floor = 'floor',
  Stories = 'stories',
  FrontDoorSteps = 'frontDoorSteps',
  SquareFeet = 'squareFeet',

  Stairs = 'stairs',
  Elevator = 'elevator',
  Paperwork = 'paperwork',
  Gated = 'gated',
  Steep = 'steep',
  ServiceElevator = 'serviceElevator',
  ServiceEntrance = 'serviceEntrance',
  LoadingDock = 'loadingDock',

  Code = 'code',
  CodeValue = 'codeValue',

  ParkingStreet = 'parkingStreet',
  ParkingDriveway = 'parkingDriveway',
  ParkingLot = 'parkingLot',
  ParkingAlley = 'parkingAlley',
  ParkingPermit = 'parkingPermit',
  ParkingInFront = 'parkingInFront',
  ParkingOnSpecificStreet = 'parkingOnSpecificStreet',
  ParkingInstructions = 'parkingInstructions',

  MaxTruckHeight = 'maxTruckHeight',
  MaxTruckHeightUnknown = 'maxTruckHeightUnknown',

  AccessUnknown = 'accessUnknown',
  PaperworkUnknown = 'paperworkUnknown',
  ParkingUnknown = 'parkingUnknown',

  Environment = 'environment',

  AdditionalNotes = 'additionalNotes',

  BuildingOpeningHour = 'buildingOpeningHour',
  BuildingClosingHour = 'buildingClosingHour',
  BuildingRestrictionsAnswer = 'buildingRestrictionsAnswer',

  ParkingLatitude = 'parkingLatitude',
  ParkingLongitude = 'parkingLongitude',

  SecureLocationDetails = 'secureLocationDetails',
  LongCarry = 'longCarry',
  UnitSize = 'unitSize',
}

export const ADDRESS_DETAIL_BUILDING_TYPE_OPTIONS = [
  AddressDetailBuildingType.House,
  AddressDetailBuildingType.Apartment,
  AddressDetailBuildingType.Warehouse,
  AddressDetailBuildingType.Dorm,
  AddressDetailBuildingType.Commercial,
  AddressDetailBuildingType.DonationCenter,
  AddressDetailBuildingType.Other,
];

export const APARTMENT_BUILDING_SUBTYPE_OPTIONS = [
  AddressDetailBuilingSubtype.HighRise,
  AddressDetailBuilingSubtype.LowRise,
  AddressDetailBuilingSubtype.Complex,
  AddressDetailBuilingSubtype.Duplex,
  AddressDetailBuilingSubtype.Other,
];

export const COMMERCIAL_BUILDING_SUBTYPE_OPTIONS = [
  AddressDetailBuilingSubtype.Office,
  AddressDetailBuilingSubtype.Retail,
  AddressDetailBuilingSubtype.Industrial,
  AddressDetailBuilingSubtype.Other,
];

export interface IAddressDetail {
  [AddressDetailField.BuildingType]?: string | null;
  [AddressDetailField.BuildingSubtype]?: string | null;

  [AddressDetailField.Floor]?: number | null;
  [AddressDetailField.Stories]?: number | null;
  [AddressDetailField.FrontDoorSteps]?: number | null;
  [AddressDetailField.SquareFeet]?: number | null;

  [AddressDetailField.Stairs]: boolean;
  [AddressDetailField.Elevator]: boolean;
  [AddressDetailField.Gated]: boolean;
  [AddressDetailField.Steep]: boolean;
  [AddressDetailField.ServiceElevator]: boolean;
  [AddressDetailField.ServiceEntrance]: boolean;
  [AddressDetailField.LoadingDock]: boolean;

  [AddressDetailField.Code]: boolean;
  [AddressDetailField.CodeValue]?: string | null;

  [AddressDetailField.ParkingStreet]: boolean;
  [AddressDetailField.ParkingDriveway]: boolean;
  [AddressDetailField.ParkingLot]: boolean;
  [AddressDetailField.ParkingAlley]: boolean;
  [AddressDetailField.ParkingPermit]: boolean;
  [AddressDetailField.ParkingInFront]: boolean;
  [AddressDetailField.ParkingOnSpecificStreet]?: string | null;
  [AddressDetailField.ParkingInstructions]?: string | null;

  [AddressDetailField.MaxTruckHeight]?: number | null;
  [AddressDetailField.MaxTruckHeightUnknown]: boolean;

  [AddressDetailField.Paperwork]?: boolean;

  [AddressDetailField.AccessUnknown]: boolean;
  [AddressDetailField.PaperworkUnknown]: boolean;
  [AddressDetailField.ParkingUnknown]: boolean;

  [AddressDetailField.Environment]?: AddressDetailEnvironmentEnum | null;

  [AddressDetailField.AdditionalNotes]?: string | null;

  [AddressDetailField.BuildingOpeningHour]?: number | null;
  [AddressDetailField.BuildingClosingHour]?: number | null;
  [AddressDetailField.BuildingRestrictionsAnswer]?: AddressDetailBuildingRestrictionsAnswer | null;

  [AddressDetailField.ParkingLatitude]?: number | null;
  [AddressDetailField.ParkingLongitude]?: number | null;

  [AddressDetailField.SecureLocationDetails]?: string | null;
  [AddressDetailField.LongCarry]?: boolean;
  [AddressDetailField.UnitSize]?: AddressDetailUnitSizeEnum | null;
}

export interface IAddressValidationErrors {
  streetAddress?: string;
  [AddressDetailField.BuildingType]?: string;
  [AddressDetailField.BuildingRestrictionsAnswer]?: string;
  parkingLocation?: string;
  [AddressDetailField.FrontDoorSteps]?: string;
  [AddressDetailField.BuildingSubtype]?: string;
  aptsuite?: string;
  access?: string;
  [AddressDetailField.Floor]?: string;
  businessName?: string;
}
