import styled from '@emotion/styled';
import React from 'react';

import { BookingDisclaimer, OrderSubtypeEnum } from '@portal/schema';

import { Section } from '@portal/components/orders/steps/confirm/section';
import { useItemPricing } from './item_pricing';
import { PerItemPricingCosts } from '../per_item_pricing_costs';

const ItemList = styled.div`
  white-space: pre-wrap;
  & > div:first-of-type {
    padding-top: 10px;
  }
  & > div:last-of-type {
    padding-bottom: 20px;
  }
  & > div {
    padding: 5px 0px;
  }
`;

const ESADisclaimer = styled.div`
  padding-top: 24px;
`;

export const Disclaimers: React.FC<{
  subtype?: OrderSubtypeEnum;
  disclaimers: BookingDisclaimer | undefined;
  extendedServiceArea: boolean;
  longDistance: boolean;
}> = ({ subtype, disclaimers, extendedServiceArea, longDistance }) => {
  const itemPricingData = useItemPricing();

  if (!disclaimers) {
    return <>...</>;
  }
  const { orderCancellationDisclaimer, accountTerminationDisclaimer, orderLaborDisclaimer, authHoldDisclaimer } =
    disclaimers;
  const orderCostsDisplay =
    subtype !== OrderSubtypeEnum.Final && itemPricingData?.itemPricing ? (
      <PerItemPricingCosts pricingInfo={itemPricingData?.itemPricing} />
    ) : (
      orderLaborDisclaimer || 'Free'
    );

  return (
    <ItemList>
      <Section title="Appointment Costs">
        {orderCostsDisplay} {authHoldDisclaimer}
        {extendedServiceArea && !longDistance && (
          <ESADisclaimer>
            Why an extra fee? Your address is outside of Clutter's primary service area, and this fee allows your Move &
            Pack team to cover the extra distance.
          </ESADisclaimer>
        )}
        {longDistance && (
          <ESADisclaimer>
            Why an extra fee? Since your delivery address is outside of our standard service area, this appointment is
            billed with an additional non-refundable transport fee. This transport fee will be charged when you book
            this appointment. The appointment will not be booked if that charge fails.
          </ESADisclaimer>
        )}
      </Section>
      {orderCancellationDisclaimer && <Section title="Cancellation Policy">{orderCancellationDisclaimer}</Section>}
      {accountTerminationDisclaimer && <Section title="Early Termination">{accountTerminationDisclaimer}</Section>}
    </ItemList>
  );
};
