import cn from 'classnames';
import * as React from 'react';

interface ITableProps {
  className?: string;
  responsive?: boolean;
  bordered?: boolean;
  condensed?: boolean;
  striped?: boolean;
  hover?: boolean;
}

const Container: React.FC<{ responsive?: boolean }> = ({ responsive, children }) =>
  responsive ? <div className="table-responsive">{children}</div> : <>{children}</>;

export const Table: React.FC<ITableProps & React.TableHTMLAttributes<HTMLTableElement>> = ({
  responsive,
  bordered,
  condensed,
  hover,
  striped,
  children,
  className,
  ...props
}) => (
  <Container responsive={responsive}>
    <table
      {...props}
      className={cn(
        'table',
        bordered && 'table-bordered',
        condensed && 'table-condensed table-sm',
        hover && 'table-hover',
        striped && 'table-striped',
        className,
      )}
    >
      {children}
    </table>
  </Container>
);
