import React from 'react';

import { dateDetailsMap, hasMatchingArrivalTimeWindows } from '@portal/utils/scheduling';
import { FontWeight, Text } from '@clutter/clean';
import { OrderSubtypeEnum } from '@portal/schema';
import { AppointmentDetailsOrder } from '../full_panel';
import { LineItem } from '../../line_item';

export const MultiDayTimeline: React.FC<{
  timelineDetailsMap: Map<string, { formattedArrivalWindow: string; serviceDescription: string }>;
  showArrivalTimes: boolean;
  sizing?: 'small' | 'regular';
}> = ({ timelineDetailsMap, showArrivalTimes, sizing = 'regular' }) => {
  const lastEntryIndex = timelineDetailsMap.size - 1;

  return (
    <>
      {Array.from(timelineDetailsMap).map(
        ([formattedDate, { formattedArrivalWindow, serviceDescription }], entryIndex) => (
          <div key={entryIndex}>
            <div>
              {sizing === 'regular' ? (
                <>
                  <Text.Body weight={FontWeight.Regular}>{formattedDate}</Text.Body>
                  {showArrivalTimes && <Text.Callout>{formattedArrivalWindow}</Text.Callout>}
                  <Text.Callout>{serviceDescription}</Text.Callout>
                </>
              ) : (
                <>
                  <Text.Callout weight={FontWeight.Regular}>{formattedDate}</Text.Callout>
                  {showArrivalTimes && <Text.Caption>{formattedArrivalWindow}</Text.Caption>}
                  <Text.Caption>{serviceDescription}</Text.Caption>
                </>
              )}
            </div>
            {entryIndex !== lastEntryIndex && <br />}
          </div>
        ),
      )}
    </>
  );
};

export const MultiDayTimelineLineItem: React.FC<{ order: AppointmentDetailsOrder }> = ({ order }) => {
  if (!order.multiDay) {
    return null;
  }

  const nonTravelSourceAndSuccessors = order.nonTravelSourceAndSuccessors.filter(
    (o) => o.subtype !== OrderSubtypeEnum.Travel,
  );
  const timelineDetailsMap = dateDetailsMap(nonTravelSourceAndSuccessors);
  const showArrivalTimes = !hasMatchingArrivalTimeWindows(nonTravelSourceAndSuccessors);

  return (
    <LineItem label="Timeline">
      <MultiDayTimeline timelineDetailsMap={timelineDetailsMap} showArrivalTimes={showArrivalTimes} />
    </LineItem>
  );
};
