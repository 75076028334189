import React from 'react';
import { COLORS, Column, FontWeight, Grid, Text } from '@clutter/clean';
import styled from '@emotion/styled';

import { ViewButton as ViewInvoicesButton } from '@portal/components/invoices/view_button';
import { Currency, Pluralize } from '@shared/components/helpers';
import { Account__TermCommitmentBalance, Billing__EarlyClosureFeeInvoice, InvoiceFragment } from '@portal/schema';

export type TermCommitmentBalance = Pick<Account__TermCommitmentBalance, 'term' | 'amountDue'> & {
  paidInvoices: InvoiceFragment[];
} & { owedInvoices: Billing__EarlyClosureFeeInvoice[] };

const BalanceContainer = styled.div`
  background: ${COLORS.tealBackground};
  border-radius: 4px;
  width: 100%;
  max-width: 500px;
  margin: 20px auto 32px auto;
`;

const TermContainer = styled.div`
  padding: 24px 0;
  text-align: center;
`;

const Divider = styled.hr`
  margin: 0;
`;

const BalanceList = styled.div`
  padding: 0 24px 2px;
`;

const BalanceItem = styled(Grid)`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const Balance: React.FC<{ balance: TermCommitmentBalance }> = ({ balance }) => {
  const { term, owedInvoices, paidInvoices, amountDue } = balance;
  const numOwed = owedInvoices.length || 0;
  const numPaid = term - numOwed;

  return (
    <BalanceContainer>
      <TermContainer>
        <Text.Title size="small">{term} Month Term</Text.Title>
      </TermContainer>
      <Divider />
      <BalanceList>
        <BalanceItem>
          <Column widths={[8, 8, 8]}>
            <Text.Body weight={FontWeight.Medium}>Paid Invoices</Text.Body>
            <ViewInvoicesButton title="Paid Invoices" invoices={paidInvoices} />
          </Column>
          <Column widths={[4, 4, 4]}>
            <Text.Body weight={FontWeight.Medium} className="text-right">
              <Pluralize count={numPaid} singular="month" plural="months" />
            </Text.Body>
          </Column>
        </BalanceItem>
        <Divider />
        <BalanceItem>
          <Column widths={[8, 8, 8]}>
            <Text.Body weight={FontWeight.Medium}>Owed Invoices</Text.Body>
            <ViewInvoicesButton title="Owed Invoices" invoices={owedInvoices} />
          </Column>
          <Column widths={[4, 4, 4]}>
            <Text.Body weight={FontWeight.Medium} className="text-right">
              <Pluralize count={numOwed} singular="month" plural="months" />
            </Text.Body>
          </Column>
        </BalanceItem>
        <Divider />
        <BalanceItem>
          <Column widths={[8, 8, 8]}>
            <Text.Title size="extraSmall">Total Balance</Text.Title>
          </Column>
          <Column widths={[4, 4, 4]}>
            <Text.Title size="extraSmall" className="text-right">
              <Currency value={amountDue} />
            </Text.Title>
          </Column>
        </BalanceItem>
      </BalanceList>
    </BalanceContainer>
  );
};
