import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text, COLORS } from '@clutter/clean';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  color: ${COLORS.toucan};
  padding: 0px 0 16px;
`;

const Callout = styled(Text.Callout)`
  padding-left: 4px;
`;

export const Error: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Container>
    <FontAwesomeIcon icon="exclamation-triangle" />
    <Callout>{children}</Callout>
  </Container>
);
