import styled from '@emotion/styled';
import { COLORS } from '@clutter/clean';
import React from 'react';

const BannerContainer = styled.div<{ borderColor: string }>`
  border-radius: 4px;
  border: 1px solid ${(props) => props.borderColor};
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  align-items: top;
  background: #fbfbfb;
`;

const IconContainer = styled.div`
  width: 2rem;
  margin-right: 1.25rem;
`;

export const Banner: React.FC<{
  borderColor?: string;
  icon?: React.ReactNode;
  text: React.ReactNode;
}> = ({ borderColor = COLORS.tiger, icon, text }) => (
  <BannerContainer borderColor={borderColor}>
    {icon && <IconContainer>{icon}</IconContainer>}
    {text}
  </BannerContainer>
);
