import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import * as React from 'react';

import { Fit, Picture } from '@shared/components/helpers';
import { Labelled } from './labelled';

const ICONS: {
  [checked in 'checked' | 'unchecked' | 'disabled']: ['far', 'check-square' | 'square' | 'clock'];
} = {
  checked: ['far', 'check-square'],
  unchecked: ['far', 'square'],
  disabled: ['far', 'clock'],
};

const BrickDetails: React.FC = ({ children }) => <div className="brick__details">{children}</div>;
const BrickStatus: React.FC = ({ children }) => <div className="brick__status">{children}</div>;

const BrickSelector: React.FC<{
  selected?: boolean;
  enabled?: boolean;
  onSelect(): void;
  onDeselect(): void;
}> = ({ onSelect, onDeselect, selected, enabled = true }) => (
  <Labelled>
    <input
      name="selector"
      type="checkbox"
      checked={selected}
      disabled={!enabled}
      onChange={(event) => (event.target.checked ? onSelect() : onDeselect())}
      className="brick__selector custom-control-input"
    />
    <div className="brick__selector__checkmark">
      <FontAwesomeIcon icon={ICONS[!enabled ? 'disabled' : selected ? 'checked' : 'unchecked']} />
      {!enabled ? ' DELAYED' : selected ? ' SELECTED' : ' UNSELECTED'}
    </div>
  </Labelled>
);

const BrickContent: React.FC<{
  icon?: 'box' | 'plus';
  imgixURL?: string;
}> = ({ icon, imgixURL, children }) => (
  <>
    <div className="brick__outline">
      <div className="brick__spacer" />
      <div className="brick__hero">
        {icon && <FontAwesomeIcon className="brick__icon" icon={icon} />}
        {imgixURL && (
          <Picture
            className="brick__image"
            source={imgixURL}
            width={640}
            height={640}
            fit={Fit.Fill}
            background="FFF"
          />
        )}
      </div>
    </div>
    {children}
  </>
);

const BrickContainer: React.FC<{
  selected?: boolean;
  selectable?: boolean;
}> = ({ selected, selectable, children }) => (
  <div className={cn('brick', { 'brick--selected': selected, 'brick--unselectable': !selectable })}>{children}</div>
);

interface IBrickProps {
  icon?: 'box' | 'plus';
  imgixURL?: string;
  selectable?: boolean;
  selected?: boolean;
  children?: React.ReactNode;
}

export class Brick extends React.Component<IBrickProps> {
  public static Details = BrickDetails;

  public static Status = BrickStatus;

  public static Selector = BrickSelector;

  public static Content = BrickContent;

  public static Container = BrickContainer;

  public render() {
    const { children, icon, imgixURL, selected, selectable } = this.props;
    return (
      <BrickContainer selected={selected} selectable={selectable}>
        <BrickContent icon={icon} imgixURL={imgixURL} children={children} />
      </BrickContainer>
    );
  }
}
