import { Box, FontWeight, Radio, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Order__ContactInput, Order__ContactKind, Order__PickupVehicleKind } from '@portal/schema';

import { KIND_RADIO_OPTIONS, VEHICLE_RADIO_OPTIONS } from '@portal/components/orders/contact/util';
import { useOrderInput } from '../order_context';
import { ContactType, TypeSelector } from './type_selector';

const EXPLANATION =
  'We’ll ask you or the person collecting your items for identification before releasing your items. If a moving company is picking up your items, include their company name and number below.';
const TYPE_RADIO_OPTIONS = [
  { value: ContactType.Customer, label: 'I’m picking up the items' },
  { value: ContactType.Other, label: 'Someone else is picking up the items' },
];

const Title = styled(Text.Title)`
  margin-bottom: 32px;
`;

const Section = styled(Box)`
  margin: 32px 0;
`;

const Question = styled(Text.Title)`
  margin: 16px 0 8px;
`;

const Callout = styled(Text.Callout)`
  margin-top: 8px;
`;

export const Facility: React.FC<{
  contact?: Order__ContactInput;
  onChange(contact?: Order__ContactInput): void;
  setVehicleKind(kind?: Order__PickupVehicleKind): void;
  setValid(valid: boolean): void;
}> = ({ contact, onChange, setVehicleKind, setValid }) => {
  const orderInput = useOrderInput();
  const { pickupVehicleKind } = orderInput;

  const onValid = (phoneValid: boolean) => {
    if (contact) {
      setValid(phoneValid && !!contact.kind && !!pickupVehicleKind);
    } else {
      setValid(phoneValid && !!pickupVehicleKind);
    }
  };

  return (
    <>
      <Title size="large">Arrival Information</Title>
      <Section>
        <Question size="medium">Who is picking up the items?</Question>
        <TypeSelector
          contact={contact}
          onChange={onChange}
          explanation={EXPLANATION}
          options={TYPE_RADIO_OPTIONS}
          setValid={onValid}
        />
        {contact && (
          <>
            <Callout>Please provide a number that accepts SMS text messaging.</Callout>
            <Box margin="32px 0 0">
              <Text.Body weight={FontWeight.Medium}>Is this a moving company?</Text.Body>
              <Radio.Selector<Order__ContactKind>
                name="contactKind"
                onChange={(kind) => onChange({ ...contact, kind })}
                value={contact?.kind || undefined}
                options={KIND_RADIO_OPTIONS}
              />
            </Box>
          </>
        )}
      </Section>
      <Section>
        <Question size="medium">What type of vehicle are you bringing to your appointment?</Question>
        <Radio.Selector<Order__PickupVehicleKind>
          name="vehicleKind"
          onChange={setVehicleKind}
          value={pickupVehicleKind || undefined}
          options={VEHICLE_RADIO_OPTIONS}
        />
      </Section>
    </>
  );
};
