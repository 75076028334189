import { TrackedClick } from '@portal/components/wt/tracked_click';
import { Box, COLORS, FontWeight, Text, UnstyledButton } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

const SummaryItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid ${COLORS.grayBorder};
`;

const EditButton = styled(UnstyledButton)`
  display: flex;
`;

type TrackingParams = {
  pageName: string;
  container: string;
  action: string;
  objectType: string;
  objectName: string;
  label: string;
  value: string;
  order_id: string;
};

export const SummaryItem: React.FC<{
  name: string;
  value: React.ReactNode;
  trackingParams?: TrackingParams;
  onEdit?(): void;
}> = ({ name, value, trackingParams, onEdit }) => (
  <SummaryItemContainer>
    <Box.Flex justifyContent="space-between" alignItems={'flex-start'}>
      <Text.Callout weight={FontWeight.Medium}>{name}</Text.Callout>
      {onEdit && trackingParams && (
        <TrackedClick params={trackingParams}>
          <EditButton onClick={onEdit}>
            <Text.Callout color={COLORS.tealPrimary}>Edit</Text.Callout>
          </EditButton>
        </TrackedClick>
      )}
    </Box.Flex>
    <Text.Callout>{value}</Text.Callout>
  </SummaryItemContainer>
);

export function editButtonTrackingParams(orderID: string, value: string, buttonName: string) {
  return {
    pageName: 'portal:virtual_walkthrough_review',
    container: 'appointment_details',
    action: 'click',
    objectType: 'button',
    objectName: buttonName,
    label: 'Edit',
    value: value,
    order_id: orderID,
  };
}
