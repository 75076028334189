import { COLORS, FontWeight, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Radio } from '@portal/components/shared/radio';
import { Account__TermCommitment, Maybe } from '@portal/schema';
import { formatDate } from '@shared/utils/format_date';

export enum TermCommitmentAction {
  RemoveItems = 'REMOVE_ITEMS',
  ContactCare = 'CONTACT_CARE',
  Reschedule = 'RESCHEDULE',
  Pay = 'PAY',
}

export enum MessageType {
  Scheduling,
  Rescheduling,
  UpdatingItems,
}

export interface ITermCommitmentRadioOption {
  value: TermCommitmentAction;
  label: string;
}

// TODO: Remove when Clean line-height has been updated.
const Title = styled(Text.Title)`
  line-height: 1.2;
`;

const Description = styled(Text.Body)`
  margin: 24px 0;
`;

const Prompt = styled(Text.Title)`
  padding: 12px 0;
`;

const ErrorMessage = styled(Text.Callout)`
  color: ${COLORS.toucan};
`;

const message = (messageType: MessageType) => {
  switch (messageType) {
    case MessageType.Scheduling:
      return 'This appointment is not scheduled.';
    case MessageType.Rescheduling:
      return 'This appointment is not rescheduled.';
    case MessageType.UpdatingItems:
      return 'The items in this appointment were not updated.';
  }
};

export const Options: React.FC<{
  messageType: MessageType;
  action?: TermCommitmentAction;
  options: ITermCommitmentRadioOption[];
  error?: Maybe<string>;
  setAction(action: TermCommitmentAction): void;
  termCommitment: Account__TermCommitment;
}> = ({ messageType, action, options, error, setAction, termCommitment }) => (
  <>
    <Title size="large">Term Commitment Has Not Been Met</Title>

    <Description>
      {message(messageType)} Your account has a {termCommitment.term} month term commitment to store through{' '}
      {formatDate(termCommitment.contractEndDate!)}
    </Description>

    <Prompt size="extraSmall">How would you like to continue?</Prompt>

    {error && <ErrorMessage weight={FontWeight.Medium}>{error}</ErrorMessage>}

    <Radio name="term-commitment" value={action} options={options} onChange={setAction} />
  </>
);
