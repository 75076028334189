import { useEffect } from 'react';

export const useDocumentEventListener = <T>(type: string, callback: (detail: T) => void) => {
  useEffect(() => {
    const trigger = (event: CustomEvent<T>) => callback(event.detail);

    document.addEventListener(type, trigger as EventListener);
    return () => {
      document.removeEventListener(type, trigger as EventListener);
    };
  }, []);
};

export const dispatchDocumentEvent = <T>(type: string, detail: T) => {
  const event = new CustomEvent<T>(type, { detail });
  document.dispatchEvent(event);
};
