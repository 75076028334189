import React from 'react';

import { ActiveStorageAttachment, ClaimIssueSelectionFragment } from '@portal/schema';
import { Text } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';

import { ViewAttachments } from './attachments';
import { CLAIM_ISSUE_SELECTION_KIND_TO_NAME, CLAIM_ISSUE_SELECTION_NAME_PLACEHOLDER } from '../constants';

type IssueSelectionTableProps = {
  issueSelection: ClaimIssueSelectionFragment;
  setOpenAttachments(attachments: ActiveStorageAttachment[]): void;
};

export const IssueSelectionDetails: React.FC<IssueSelectionTableProps> = ({ issueSelection, setOpenAttachments }) => {
  const { compensationRequested, declaration, description, kind, photos } = issueSelection;

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <Text weight="bold">Issue Type:</Text>
          <br />
          <Text tag="div" wrapping="truncate">
            {kind ? CLAIM_ISSUE_SELECTION_KIND_TO_NAME[kind] : CLAIM_ISSUE_SELECTION_NAME_PLACEHOLDER}
          </Text>
        </div>
        <div className="col-md-4">
          <Text weight="bold">Requesting Compensation:</Text>
          <br />
          <Text tag="div" wrapping="truncate">
            {compensationRequested ? 'Yes' : 'No'}
          </Text>
        </div>
        <div className="col-md-4">
          <Text weight="bold">Estimated Loss:</Text>
          <br />
          <Text tag="div" wrapping="truncate">
            <Currency value={declaration ?? 0} />
          </Text>
        </div>
      </div>
      <br />
      <Text weight="bold">Description:</Text>
      <br />
      <Text tag="div" wrapping="overflow">
        <span style={{ whiteSpace: 'pre-wrap' }}>{description}</span>
      </Text>
      <br />
      <Text weight="bold">Supporting Documentation:</Text>
      <br />
      {photos.length ? <ViewAttachments attachments={photos} setOpenAttachments={setOpenAttachments} /> : 'None.'}
    </>
  );
};
