import { sumBy } from 'lodash';
import React from 'react';

import { COLORS, Column, FontWeight, Grid, Text } from '@clutter/clean';
import styled from '@emotion/styled';

import {
  PaymentPlanDetail__OfferFragment as PaymentPlanOffer,
  PaymentPlan__Offer__Type as OfferType,
} from '@portal/schema';

import { ViewButton as ViewInvoicesButton } from '@portal/components/invoices/view_button';
import { PiggyBank } from '@portal/components/shared/icons/piggy_bank';
import { currency } from '@shared/utils/currency';
import { formatDate } from '@shared/utils/format_date';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  padding-right: 40px;
`;

const BalanceContainer = styled.div`
  background: ${COLORS.tealBackground};
  border-radius: 4px;
  width: 100%;
`;

const BalanceList = styled.div`
  padding: 0 24px 2px;
`;

const BalanceItem = styled(Grid)`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const HeaderContainer = styled.div`
  padding: 18px 18px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const HeaderTextContainer = styled.div`
  margin-top: 12px;
`;

export const BalanceSummary: React.FC<{ offer: PaymentPlanOffer }> = ({ offer }) => {
  const { expiry, oneTimeFee, monthlyFee, monthlyFeeTerm, settledInvoices, type } = offer;
  const previousBalance = sumBy(settledInvoices, (invoice) => invoice.amountDue);
  const newBalance = (oneTimeFee || 0) + (monthlyFee || 0) * (monthlyFeeTerm || 0);
  const totalForgiven = previousBalance - newBalance;
  const offerAction = type === OfferType.AutopayOptIn ? 'discount' : 'forgive';
  return (
    <Container>
      <BalanceContainer>
        <HeaderContainer>
          <ImageContainer>
            <PiggyBank size={51} />
          </ImageContainer>
          <HeaderTextContainer>
            <Text.Title size="extraSmall">
              We'll {offerAction} {currency(totalForgiven)}
            </Text.Title>
            <Text.Callout style={{ fontFamily: 'sans-serif' }}>Offer expires {formatDate(expiry)}</Text.Callout>
          </HeaderTextContainer>
        </HeaderContainer>
        <hr />
        <BalanceList>
          <BalanceItem>
            <Column widths={[8, 8, 8]}>
              <Text.Body weight={FontWeight.Medium}>Your previous balance</Text.Body>
              <ViewInvoicesButton
                invoices={settledInvoices}
                title={`You owe ${currency(previousBalance)} for the following invoices`}
              />
            </Column>
            <Column widths={[4, 4, 4]}>
              <Text.Body weight={FontWeight.Medium} className="text-right">
                {currency(previousBalance)}
              </Text.Body>
            </Column>
          </BalanceItem>
          <hr />
          <BalanceItem className="row">
            <Column widths={[8, 8, 8]}>
              <Text.Body weight={FontWeight.Medium}>Clutter will {offerAction}</Text.Body>
            </Column>
            <Column widths={[4, 4, 4]}>
              <Text.Body weight={FontWeight.Medium} className="text-right">
                - {currency(totalForgiven)}
              </Text.Body>
            </Column>
          </BalanceItem>
          <hr />
          <BalanceItem className="row">
            <Column widths={[8, 8, 8]}>
              <Text.Title size="extraSmall">Your new balance</Text.Title>
            </Column>
            <Column widths={[4, 4, 4]}>
              <Text.Title size="extraSmall" className="text-right">
                {currency(newBalance)}
              </Text.Title>
            </Column>
          </BalanceItem>
        </BalanceList>
      </BalanceContainer>
    </Container>
  );
};
