import styled from '@emotion/styled';
import { Box, COLORS, FontWeight, Input, mq, Radio, Text, UnstyledButton } from '@clutter/clean';
import { ZendeskChatTag } from '@shared/types/zendesk';
import { OrderDetailsOrder } from '@portal/components/orders/cancel/steps';
import {
  Account__CancelIntent__Reason,
  Moving__CancelIntent__Reason,
  Onboarding__CancelIntent__Reason,
  OrderTypeEnum,
} from '@portal/schema';
import React from 'react';
import { TrackedLink } from '@portal/components/wt/tracked_link';
import { orderMovingAddressURL, orderURL } from '@portal/config/routes';
import { Calendar, LocationTruckTracking, ManWaving } from '@portal/components/shared/icons';
import { TrackedClick } from '@portal/components/wt/tracked_click';

const ICON_SIZE = 50;

const StyledTextBody = styled(Text.Body)`
  ${mq({
    fontSize: ['10px', '12px'],
  })}
`;

const Title = styled(Text.Title)`
  line-height: 1.2em;
  margin-bottom: 32px;
`;

const StyledFlexBox = styled.div`
  display: flex;
  gap: 24px;
  ${mq({
    flexDirection: ['column', null, null, 'row'],
  })}
`;

const RowDetail = styled.div`
  display: flex;
  margin-bottom: 24px;
  text-align: center;
  gap: 16px;
`;

const RowEntry = styled.div`
  flex-grow: 1;
  flex-basis: 0;
`;

const RowDetailBusinessHours = styled(RowDetail)`
  height: 72px;
  margin-top: -8px;
  align-items: center;
  justify-content: center;
`;

const RowBody = styled(Text.Body)`
  line-height: 18px;
  padding: 8px 8px 0 8px;
  ${mq({
    fontSize: ['12px', '14px'],
  })}
`;

const StyledButton = styled(UnstyledButton)`
  &:hover {
    border: 2px solid #c4c4c4;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    text-decoration: none;
  }
  border: 2px solid transparent;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  align-items: center;
  width: 100%;
  padding: 8px;
`.withComponent('a');

const ChatSupportButton = styled(StyledButton)`
  width: 100%;
`;

const onChatSupportClick = (name?: string, email?: string) => {
  if (name && email) {
    zE('webWidget', 'identify', {
      name,
      email,
    });
  }
  zE('webWidget', 'chat:addTags', [ZendeskChatTag.CancelIntent]);
  zE('webWidget', 'open');
};

const OtherReason = styled.div`
  margin-left: 48px;
`;

const Error = styled.div`
  color: ${COLORS.toucan};
`;

type CancelIntentReason =
  | Onboarding__CancelIntent__Reason
  | Moving__CancelIntent__Reason
  | Account__CancelIntent__Reason;

export const CancellationUI: React.FC<{
  reason?: CancelIntentReason;
  setReason(reason: CancelIntentReason): void;
  otherReason?: string;
  otherReasonError?: string;
  setOtherReason?(otherReason: string): void;
  order?: OrderDetailsOrder;
  radioOptions: Array<{ value: CancelIntentReason; label: JSX.Element }>;
  title: string;
  showRescheduleAndAddressButtons: boolean;
}> = ({
  reason,
  setReason,
  otherReason,
  otherReasonError,
  setOtherReason,
  order,
  title,
  radioOptions,
  showRescheduleAndAddressButtons,
}) => (
  <>
    <Title size="large">{title}</Title>
    <StyledFlexBox>
      <CancelReasons
        reason={reason}
        setReason={setReason}
        otherReason={otherReason}
        otherReasonError={otherReasonError}
        setOtherReason={setOtherReason}
        radioOptions={radioOptions}
      />
      <Box.Flex flexDirection="column" flexBasis="430px" flexGrow={0} flexShrink={0}>
        <Text.Title size="extraSmall">Other options</Text.Title>
        <br />
        {showRescheduleAndAddressButtons && order!.permissions.reschedulable && (
          <RowDetail>
            <RescheduleAppointment order={order!} />
            <ChangeAddress order={order!} />
          </RowDetail>
        )}
        <ChatSupport order={order} />
      </Box.Flex>
    </StyledFlexBox>
  </>
);

export const CancelReasons: React.FC<{
  reason?: CancelIntentReason;
  setReason(reason: CancelIntentReason): void;
  otherReason?: string;
  otherReasonError?: string;
  setOtherReason?(otherReason: string): void;
  radioOptions: Array<{ value: CancelIntentReason; label: JSX.Element }>;
}> = ({ otherReasonError, reason, setReason, otherReason, setOtherReason, radioOptions }) => (
  <Box.Flex flexDirection="column" flexBasis="auto" flexGrow={1} flexShrink={1}>
    <Title size="extraSmall">Select a reason</Title>
    <Radio.Selector name="reason" value={reason} options={radioOptions} onChange={setReason} />
    {(reason === Onboarding__CancelIntent__Reason.Other || reason === Moving__CancelIntent__Reason.Other) && (
      <OtherReason>
        <Text.Body weight={FontWeight.Medium}>Please describe</Text.Body>
        <Input
          state={otherReasonError ? 'error' : 'valid'}
          value={otherReason || ''}
          onChange={(e) => setOtherReason!(e.target.value)}
        />
        {otherReasonError && <Error>{otherReasonError}</Error>}
      </OtherReason>
    )}
  </Box.Flex>
);

export const RescheduleAppointment: React.FC<{
  order: OrderDetailsOrder;
}> = ({ order }) => (
  <RowEntry>
    <TrackedLink
      params={trackingParams({
        orderID: order.id,
        objectName: 'reschedule_appointment',
        label: 'Reschedule your Appointment',
        value: orderURL(order.id, 'reschedule'),
      })}
    >
      <StyledButton href={orderURL(order.id, 'reschedule')}>
        <Calendar width={ICON_SIZE} height={ICON_SIZE} viewBox="0 0 70 70" />
        <RowBody weight={FontWeight.Medium}>
          Reschedule your <br />
          Appointment
          <br />
          <StyledTextBody color={COLORS.tealPrimary}>Edit appointment</StyledTextBody>
        </RowBody>
      </StyledButton>
    </TrackedLink>
  </RowEntry>
);

export const ChangeAddress: React.FC<{
  order: OrderDetailsOrder;
}> = ({ order }) => {
  let editAddressURL: string;
  if (order.type === OrderTypeEnum.Move) {
    editAddressURL = `${orderMovingAddressURL(order.id)}?kind=origin`;
  } else {
    editAddressURL = `${orderURL(order.id, 'address')}`;
  }
  const addresses = order.type !== OrderTypeEnum.Move ? 'address' : 'address(es)';
  return (
    <RowEntry>
      <TrackedLink
        params={trackingParams({
          orderID: order.id,
          objectName: 'edit_address',
          label: 'Change your address(es)',
          value: editAddressURL,
        })}
      >
        <StyledButton href={editAddressURL}>
          <LocationTruckTracking width={ICON_SIZE} height={ICON_SIZE} />
          <RowBody weight={FontWeight.Medium}>
            Change your <br />
            {addresses}
            <br />
            <StyledTextBody color={COLORS.tealPrimary}>Edit address</StyledTextBody>
          </RowBody>
        </StyledButton>
      </TrackedLink>
    </RowEntry>
  );
};

export const ChatSupport: React.FC<{
  order?: OrderDetailsOrder;
}> = ({ order }) => (
  <>
    <RowDetail>
      <RowEntry>
        <TrackedClick
          params={trackingParams({ orderID: order?.id, objectName: 'chat_support', label: 'Chat with support' })}
        >
          <ChatSupportButton
            onClick={() => onChatSupportClick(order?.account.customer.name, order?.account.customer.email)}
          >
            <ManWaving />
            <RowBody weight={FontWeight.Medium}>
              Chat with support
              <br />
              <StyledTextBody color={COLORS.tealPrimary}>Start chat session</StyledTextBody>
            </RowBody>
          </ChatSupportButton>
        </TrackedClick>
      </RowEntry>
    </RowDetail>
    <RowDetailBusinessHours>
      <div>
        Business hours
        <br />
        Monday - Friday 5 AM - 5 PM PT
        <br />
        Saturday - Sunday 5 AM - 4 PM PT
      </div>
    </RowDetailBusinessHours>
  </>
);

// WTEventTracking Helpers

export function trackingParams({
  orderID,
  objectName,
  label,
  value,
}: {
  orderID?: string;
  objectName: string;
  label: string;
  value?: string;
}) {
  return {
    pageName: 'portal:cancellation_details',
    container: 'cancellation_details',
    action: 'click',
    objectType: 'button',
    objectName: objectName,
    label: label,
    value: value || '',
    order_id: orderID || '',
  };
}
