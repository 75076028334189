import * as React from 'react';

import {
  AttachmentFragment,
  ClaimPropertySelectionAttachmentName,
  Status,
  useAttachPropertySelectionAttachmentMutation,
  useDetachPropertySelectionAttachmentMutation,
} from '@portal/schema';

interface IAttacherProps {
  name: ClaimPropertySelectionAttachmentName;
  uuid: string;
  onAttach(attachment: AttachmentFragment): void;
  onDetach(attachment: AttachmentFragment): void;
  children(props: {
    loading: boolean;
    error?: string;
    attach(signedID: string): void;
    detach(attachmentID: string): void;
  }): React.ReactElement;
}

export const Attacher = ({ name, uuid, onAttach, onDetach, children }: IAttacherProps) => {
  const [attach, { loading: attaching, data: attached }] = useAttachPropertySelectionAttachmentMutation();
  const [detach, { loading: detaching, data: detached }] = useDetachPropertySelectionAttachmentMutation();

  return children({
    loading: attaching || detaching,
    error: attached?.result?.error ?? detached?.result?.error ?? undefined,
    attach: async (signedID: string) => {
      const { data } = await attach({ variables: { uuid, signedID, name } });
      if (data?.result && data.result.attachment && data.result.status === Status.Ok) onAttach(data.result.attachment);
    },
    detach: async (attachmentID: string) => {
      const { data } = await detach({ variables: { uuid, attachmentID, name } });
      if (data?.result && data.result.attachment && data.result.status === Status.Ok) onDetach(data.result.attachment);
    },
  });
};
