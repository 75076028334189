import * as React from 'react';

interface IContext {
  summary?: string;
  progress?: {
    index: number;
    total: number;
  };
  onNext?(): void;
  onPrev?(): void;
  onClose?(): void;
}

const { Provider, Consumer } = React.createContext<IContext>({});

export { Provider as NavigationProvider };
export { Consumer as NavigationConsumer };
