import React from 'react';
import styled from '@emotion/styled';
import { COLORS, FontWeight, Text, TextButton, transparentize } from '@clutter/clean';

import { TextButton as ProtectedTextButton } from '@portal/components/shared//customer_protected/text_button';
import { TrackedClick } from '@portal/components/wt/tracked_click';
import { IEventParams } from '@portal/types/wt/event';

const Container = styled.div`
  background: ${transparentize(COLORS.flower, 0.4)};
  border-radius: 4px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 24px;
  button {
    color: ${COLORS.toucan};
    &:hover {
      color: ${transparentize(COLORS.toucan, 0.8)};
    }
  }
  margin-bottom: 32px;
`;

const Heading = styled(Text.Callout)`
  margin-bottom: 4px;
`;

const Subheading = styled(Text.Caption)`
  margin-bottom: 24px;
`;

export const DetailedBannerAlert: React.FC<{
  handleClick(event: React.MouseEvent<HTMLButtonElement>): void;
  heading: string;
  subheading: string;
  linkText: string;
  trackingParams?: IEventParams;
  customerProtected?: boolean;
}> = ({ handleClick, heading, subheading, linkText, trackingParams, customerProtected = false }) => {
  const Button = customerProtected ? ProtectedTextButton : TextButton;
  const actionButton = <Button onClick={handleClick}>{linkText}</Button>;
  const trackedActionButton = trackingParams && <TrackedClick params={trackingParams}>{actionButton}</TrackedClick>;

  return (
    <>
      <Container>
        <Heading weight={FontWeight.Medium}>{heading}</Heading>
        <Subheading>{subheading}</Subheading>
        {trackedActionButton || actionButton}
      </Container>
    </>
  );
};
