/** @jsx jsx */
import styled from '@emotion/styled';
import React from 'react';
import { jsx, css } from '@emotion/react';
import cn from 'classnames';
import { COLORS } from '@clutter/clean';
import { ThumbsDown as ThumbsDownIcon, ThumbsUp as ThumbsUpIcon } from '../shared/icons';

const DEFAULT_SCALE = 1;
const DEFAULT_DIMENSION = 60;

const Container = styled.div<Required<IThumbProps>>`
  height: ${({ scale }) => DEFAULT_DIMENSION * scale}px;
  width: ${({ scale }) => DEFAULT_DIMENSION * scale}px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

interface IThumbProps {
  selected: boolean;
  scale?: number;
}

export const ThumbsUp: React.FC<IThumbProps> = ({ selected, scale = DEFAULT_SCALE }) => (
  <Container
    className={cn([selected ? 'checklist__entry--checked' : 'checklist__entry--unchecked'])}
    selected={selected}
    scale={scale}
  >
    <div
      css={css`
        margin-top: -15%;
      `}
    >
      <ThumbsUpIcon scale={scale} overrideColor={selected ? COLORS.cloud : undefined} />
    </div>
  </Container>
);

export const ThumbsDown: React.FC<IThumbProps> = ({ selected, scale = DEFAULT_SCALE }) => (
  <Container
    className={cn([selected ? 'checklist__entry--checked' : 'checklist__entry--unchecked'])}
    selected={selected}
    scale={scale}
  >
    <div
      css={css`
        margin-bottom: -15%;
      `}
    >
      <ThumbsDownIcon scale={scale} overrideColor={selected ? COLORS.cloud : undefined} />
    </div>
  </Container>
);
