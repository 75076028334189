import { EstimationEstimatedItemFragment, useEstimationItemsPrepopulateMutation } from '@portal/schema';
import { Box, Button, COLORS, Modal, mq, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { Spacer } from '@shared/components/helpers';
import { getOrdinalSuffix } from '@shared/utils';
import React from 'react';

import LivingRoom from '@portal/images/estimation/living_room.svg';
import PaperworkClipboard from '@portal/images/paperwork_clipboard.svg';
import { TrackedClick } from '@portal/components/wt/tracked_click';
import { DateTime } from 'luxon';

const MainModalBoxContainer = styled.div`
  overflow-y: scroll;
  ${mq({
    width: ['340px', '460px', '580px', '800px'],
  })}
`;

const ValuePropContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const LivingRoomIllustration = styled.img`
  width: 100px;
  height: 70px;
  margin-bottom: 8px;
`;

const ClipboardIllustration = styled.img`
  width: 70px;
  height: 70px;
  margin-bottom: 8px;
`;

const ValuePropDiv = styled(Box.FlexItem)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  width: 50%;
`;

const Banner = styled.div`
  background: linear-gradient(
    90deg,
    rgba(255, 234, 191, 0.25) 0.51%,
    rgba(161, 230, 224, 0.15) 46.69%,
    rgba(255, 217, 217, 0.25) 99.49%
  );
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
`;

const BoldTextSpan = styled.span`
  font-weight: bold;
`;

function getStartedButtonTrackingParams(orderID: string) {
  return {
    pageName: 'portal:virtual_walkthrough',
    container: 'getting_started_modal',
    action: 'click',
    objectType: 'button',
    objectName: 'get_started_button',
    label: 'Get Started',
    value: 'modal',
    order_id: orderID,
  };
}

export const GettingStartedModal: React.FC<{
  orderID: string;
  orderScheduled: string;
  flatRate: boolean;
  hideModal: () => void;
  onPrepopulate?: (items: EstimationEstimatedItemFragment[], changed: boolean) => void;
}> = ({ orderID, orderScheduled, flatRate, hideModal, onPrepopulate }) => {
  const [prepopulate, { loading: prepopulateLoading }] = useEstimationItemsPrepopulateMutation();

  const FROM_ISO_OPTIONS = { setZone: true };
  const dueDate = DateTime.fromISO(orderScheduled, FROM_ISO_OPTIONS).minus({ days: 2 });

  const onClick = async () => {
    const result = await prepopulate({ variables: { orderID } });

    const data = result.data?.estimationItemsPrepopulate;
    if (data?.estimatedItems) {
      onPrepopulate?.(data.estimatedItems, data.changed);
    }
    hideModal();
  };

  return (
    <Modal isOpen={true} handleModalClose={hideModal} includeCloseButton={true}>
      <MainModalBoxContainer>
        <Box background={COLORS.cloud} color={COLORS.panther} padding="24px 0px 0px 0px" textAlign={'center'}>
          <Text.SmallCaps>
            Complete by {dueDate.monthLong} {dueDate.day}
            {getOrdinalSuffix(dueDate.day)}
          </Text.SmallCaps>
        </Box>
        <Box padding="12px 24px 24px 24px" background={COLORS.cloud} textAlign={'center'}>
          <Text.Title size="medium" color={COLORS.tealDark}>
            Start Virtual Walkthrough
          </Text.Title>
          <Text.Body>
            It takes less than 10 minutes and helps us build the right team for your upcoming appointment.
          </Text.Body>
          <Spacer height="1rem" />
          <ValuePropContainer>
            <ValuePropDiv>
              <ClipboardIllustration src={PaperworkClipboard} />
              <Text.Title size="extraSmall">Tell us about your items</Text.Title>
              <Text.Callout>Create your item inventory list so that we know exactly what's moving</Text.Callout>
            </ValuePropDiv>
            <ValuePropDiv>
              <LivingRoomIllustration src={LivingRoom} />
              <Text.Title size="extraSmall">Upload photos or videos</Text.Title>
              <Text.Callout>Give us a visual of the items in your inventory list to help us prepare</Text.Callout>
            </ValuePropDiv>
          </ValuePropContainer>
          <Spacer height="1rem" />
          <Banner>
            <Text.Callout>
              {flatRate ? (
                'Avoid additional fees on move day by telling us exactly what’s moving and anything we should know.'
              ) : (
                <>
                  On average, customers who complete a walkthrough{' '}
                  <BoldTextSpan>save over 2 billable hours</BoldTextSpan> on their appointment.
                </>
              )}
            </Text.Callout>
          </Banner>
          <Spacer height="1rem" />
          <TrackedClick params={getStartedButtonTrackingParams(orderID)}>
            <Button
              size="medium"
              fullWidth={true}
              onClick={onClick}
              loading={prepopulateLoading}
              disabled={prepopulateLoading}
            >
              Get Started
            </Button>
          </TrackedClick>
        </Box>
      </MainModalBoxContainer>
    </Modal>
  );
};
