import { COLORS } from '@clutter/clean';
import axios, { AxiosError } from 'axios';
import React, { useState } from 'react';

import { Box, Text } from '@clutter/clean';
import { PasswordForm } from '@portal/components/password/password_form';
import { CUSTOMER_POST_BOOKING_SIGN_IN } from '@portal/config/customer';
import { wt } from '@portal/initializers/wt';

export const PasswordCreateForm: React.FC<{
  token: string;
  onCreate(location: string): void;
}> = ({ token, onCreate }) => {
  const [error, setError] = useState<React.ReactElement | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const savePassword = async (password: string) => {
    if (!password) {
      setError(<>Enter a new password</>);
      trackSetPasswordClick(false);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post('/welcome', {
        initial_setup_token: token,
        customer: {
          password,
        },
      });
      onCreate(response.data.location);
      trackSetPasswordClick(true);
    } catch (e) {
      setLoading(false);
      const errors = (e as AxiosError).response?.data.errors;
      if (errors.password) {
        setError(<>Password {errors.password.join(', ')}</>);
      } else {
        setError(<>There was an error</>);
      }
      trackSetPasswordClick(false);
    }
  };

  return (
    <>
      <PasswordForm error={error} loading={loading} buttonText="Set Password" savePassword={savePassword} />
      {!CUSTOMER_POST_BOOKING_SIGN_IN && (
        <Box textAlign="center" margin="24px 0 0">
          <Text.Callout color={COLORS.storm}>
            By clicking Set Password, I agree to Clutter’s{' '}
            <a style={{ textDecoration: 'underline' }} href="https://www.clutter.com/legal/tos" target="_blank">
              Terms of Use.
            </a>
          </Text.Callout>
        </Box>
      )}
    </>
  );
};

function trackSetPasswordClick(successful: boolean) {
  const params = {
    pageName: 'portal:password:create',
    container: 'password_create',
    action: 'click',
    objectType: 'button',
    objectName: 'set_password_button',
    label: 'Set Password',
    value: successful,
  };

  wt.track(params);
}
