import React from 'react';

import { Spinner } from '@portal/components/helpers';
import { usePaymentSourcesQuery } from '@portal/schema';

import { SourceList } from './source_list';

export const Payment: React.FC = () => {
  const { data, loading, refetch } = usePaymentSourcesQuery();

  if (loading) {
    return <Spinner />;
  }

  const sources = data?.sources;
  const delinquent = !!data?.account.delinquentBalance;

  return <SourceList sources={sources} delinquent={delinquent} onChange={() => refetch()} />;
};
