import { BREAKPOINTS, COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  PricingSetPriceEntryFragment,
  Pricing__Set__AppointmentFees as AppointmentFees,
  Pricing__RateGroup__OrderService as Service,
  StoragePlanFragment,
  useAppointmentFeeOptionsQuery,
  usePlanUpdateQuery,
  useUpdatePlanMutation,
  AccountDetailDocument,
  OrderDetailsDocument,
  Status,
  useMovingStorageAddMutation,
  Moving__StorageAddInputType,
} from '@portal/schema';

import { Accordion } from '@clutter/clean';
import { orderURL } from '@portal/config/routes';
import { useExperimentsContext } from '@portal/contexts/experiments_context';

import { CommitmentOption, DEFAULT_COMMITMENT_OPTION, Selector as CommitmentSelector } from './commitment/selector';
import { Confirm } from './confirm';
import { Footer, FOOTER_HEIGHT } from './footer';
import { Selector as ProtectionPlanSelector } from './protection_plan/selector';
import { Selector as ServiceSelector } from './service/selector';
import { supportedServiceType } from './service/util';
import { Selector as StoragePlanSelector } from './storage_plan/selector';
import {
  groupStoragePriceEntries,
  planMetadata,
  PLAN_UPDATE_PAGE_NAME,
  StoragePriceEntryMapping,
  buildCommitmentOption,
} from './util';

const Title = styled(Text.Title)`
  color: ${COLORS.panther};
  padding-bottom: 12px;
`;

const Body = styled(Text.Body)`
  color: ${COLORS.panther};
  margin-bottom: 28px;
`;

const SelectorsAccordion = styled(Accordion.Group)`
  margin-bottom: ${FOOTER_HEIGHT}px;
  @media (max-width: ${BREAKPOINTS.MD}) {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }
`;

const DEFAULT_SERVICE_TYPE = Service.FullService;

export const PlanUpdateForm: React.FC<{ orderID: string; action: 'add' | 'update'; parentOrderID?: string }> = ({
  orderID,
  action,
  parentOrderID,
}) => {
  const [storagePriceEntries, setStoragePriceEntries] = useState<StoragePriceEntryMapping>();
  const [protectionPriceEntries, setProtectionPriceEntries] = useState<PricingSetPriceEntryFragment[]>();
  const [appointmentFees, setAppointmentFees] = useState<AppointmentFees[]>([]);
  const [initialStoragePriceEntry, setInitialStoragePriceEntry] = useState<PricingSetPriceEntryFragment>();
  const [selectedStoragePriceEntry, setSelectedStoragePriceEntry] = useState<PricingSetPriceEntryFragment>();
  const [initialProtectionPriceEntry, setInitialProtectionPriceEntry] = useState<PricingSetPriceEntryFragment>();
  const [selectedProtectionPriceEntry, setSelectedProtectionPriceEntry] = useState<PricingSetPriceEntryFragment>();
  const [initialAppointmentFees, setInitialAppointmentFees] = useState<AppointmentFees>();
  const [selectedAppointmentFees, setSelectedAppointmentFees] = useState<AppointmentFees>();
  const [selectedCommitmentOption, setSelectedCommitmentOption] = useState<CommitmentOption | undefined>();
  const [initialCommitmentOption, setInitialCommitmentOption] = useState<CommitmentOption | undefined>();
  const [showConfirmPage, setShowConfirmPage] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const selectedCuft = (selectedStoragePriceEntry?.price?.plan as StoragePlanFragment)?.cuft;
  const selectedRateGroupID = selectedStoragePriceEntry?.rateGroup?.id;

  const history = useHistory();
  const redirectURL = orderURL(parentOrderID || orderID);
  const isAddingPlan = action === 'add';
  const hideLabor = isAddingPlan || !!parentOrderID;
  const { hourlyLaborRequired } = useExperimentsContext();
  const { data, loading } = usePlanUpdateQuery({
    variables: isAddingPlan ? { parentOrderID: orderID } : undefined,
  });
  const { data: feesData, loading: appointmentFeesLoading } = useAppointmentFeeOptionsQuery({
    variables: {
      cuft: selectedCuft!,
      rateGroupID: selectedRateGroupID!,
      pricingSetID: isAddingPlan ? data?.pricingSet?.id : undefined,
    },
    skip: !selectedCuft || !selectedRateGroupID || (isAddingPlan && !data?.pricingSet),
  });

  const groupedStoragePriceEntries = useMemo(
    () =>
      data?.pricingSet?.storagePriceEntries ? groupStoragePriceEntries(data.pricingSet.storagePriceEntries) : undefined,
    [data],
  );

  const mutationOptions = {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: AccountDetailDocument },
      { query: OrderDetailsDocument, variables: { orderID: orderID } },
    ],
  };

  const [update, { loading: updateLoading }] = useUpdatePlanMutation(mutationOptions);

  const [add, { loading: addLoading }] = useMovingStorageAddMutation(mutationOptions);

  const componentLoading = loading || appointmentFeesLoading || updateLoading || addLoading;

  const onOrderServiceChange = (newAppointmentFees: AppointmentFees) => {
    const serviceType = newAppointmentFees.serviceType;
    const commitmentType = selectedStoragePriceEntry?.rateGroup?.commitmentType;
    const planName = selectedStoragePriceEntry?.price?.plan.name;
    if (!storagePriceEntries || !serviceType || !commitmentType || !planName) {
      return;
    }
    const priceEntry = storagePriceEntries[commitmentType][serviceType][planName];
    setSelectedStoragePriceEntry(priceEntry);
    setSelectedAppointmentFees(newAppointmentFees);
  };

  const onStorageCommitmentChange = (option: CommitmentOption) => {
    setSelectedCommitmentOption(option);
    const selectedServiceType = selectedStoragePriceEntry?.rateGroup?.serviceType;
    if (selectedStoragePriceEntry && groupedStoragePriceEntries && selectedServiceType) {
      setSelectedStoragePriceEntry(
        groupedStoragePriceEntries[option.commitmentType][selectedServiceType][
          selectedStoragePriceEntry.price.plan.name
        ],
      );
    }
  };

  useEffect(() => {
    if (!data?.pricingSet?.storagePriceEntries) {
      return;
    }
    setStoragePriceEntries(groupedStoragePriceEntries);
    setProtectionPriceEntries(data.pricingSet.protectionPriceEntries);

    const { rateGroup, subscriptionSet } = data.account;
    const storagePrice = subscriptionSet.storageSubscriptions[0]?.pricing;
    const serviceType = rateGroup?.serviceType;
    const commitmentType = rateGroup?.commitmentType;
    const planName = storagePrice?.plan.name;
    if (serviceType && commitmentType && planName && groupedStoragePriceEntries) {
      const selectedPriceEntry = groupedStoragePriceEntries[commitmentType][serviceType][planName];
      const commitmentOption = buildCommitmentOption(selectedPriceEntry);
      setInitialCommitmentOption(commitmentOption);
      setSelectedCommitmentOption(commitmentOption);
      setInitialStoragePriceEntry(selectedPriceEntry);
      setSelectedStoragePriceEntry(selectedPriceEntry);
    } else {
      setInitialCommitmentOption(DEFAULT_COMMITMENT_OPTION);
      setSelectedCommitmentOption(DEFAULT_COMMITMENT_OPTION);
    }
    const protectionPrice = subscriptionSet.protectionSubscription?.pricing;
    let protectionPriceEntry;
    if (!protectionPrice) {
      protectionPriceEntry = data.pricingSet.protectionPriceEntries.find((entry) => entry.price.amount === 0);
    } else {
      protectionPriceEntry = data.pricingSet.protectionPriceEntries.find(
        (entry) => entry.price.id === protectionPrice.id,
      );
    }
    setInitialProtectionPriceEntry(protectionPriceEntry);
    setSelectedProtectionPriceEntry(protectionPriceEntry);
  }, [data]);

  useEffect(() => {
    const selectedServiceType = selectedStoragePriceEntry?.rateGroup?.serviceType;
    if (!feesData?.appointmentFeeOptions || !selectedServiceType) {
      return;
    }
    const options = feesData.appointmentFeeOptions;
    setAppointmentFees(options);
    const selectedOption = options.find((option) => option.serviceType === selectedServiceType)!;
    if (!initialAppointmentFees) {
      setInitialAppointmentFees(selectedOption);
    }
    onOrderServiceChange(selectedOption);
  }, [feesData]);

  const onSubmitAdd = async () => {
    if (
      !selectedProtectionPriceEntry ||
      !selectedAppointmentFees ||
      !data?.pricingSet?.quoteID ||
      !selectedStoragePriceEntry?.rateGroup
    ) {
      return;
    }

    const input: Moving__StorageAddInputType = {
      planID: selectedStoragePriceEntry.price.plan.id,
      pricing: {
        laborBillingFormat: selectedAppointmentFees.laborEntry.laborPolicy.laborBillingFormat,
        laborRateID: selectedAppointmentFees.laborEntry.laborPolicy.laborRate.id,
        laborPolicyID: selectedAppointmentFees.laborEntry.laborPolicy.id,
        packageSetEntries: selectedAppointmentFees.packageSetEntries.map((entry) => ({
          id: entry.id,
          amount: entry.amount,
        })),
        quoteID: data.pricingSet.quoteID,
      },
      rateGroupID: selectedStoragePriceEntry.rateGroup.id,
      protectionPlanID: selectedProtectionPriceEntry.price.plan.id,
    };

    const response = await add({ variables: { parentID: orderID, input } });
    if (response.errors || response.data?.movingStorageAdd?.status === Status.Unprocessable) {
      setError('An error occurred when adding your storage plan.');
    } else if (response.data?.movingStorageAdd?.status === Status.Ok) {
      setError(undefined);
      history.push(redirectURL);
    }
  };

  const onSubmitUpdate = async () => {
    const input = {
      laborPricingGroupEntryID: selectedAppointmentFees!.laborEntry.id,
      storagePricingGroupEntryID: selectedStoragePriceEntry!.id,
      protectionPricingGroupEntryID: selectedProtectionPriceEntry!.id,
      laborRateID: selectedAppointmentFees!.laborEntry.laborPolicy.laborRate.id,
    };
    const response = await update({ variables: { input } });

    if (response.errors || response.data?.updatePlan?.status === Status.Unprocessable) {
      setError('An error occurred when saving your plan changes.');
    } else if (response.data?.updatePlan?.status === Status.Ok) {
      setError(undefined);
      history.push(redirectURL);
    }
  };

  const formValid = !!selectedStoragePriceEntry && !!selectedProtectionPriceEntry && !!selectedAppointmentFees;
  let storageOptions: PricingSetPriceEntryFragment[] = [];
  let commitmentOptions: PricingSetPriceEntryFragment[] | undefined;
  if (storagePriceEntries && selectedStoragePriceEntry?.rateGroup) {
    const serviceType = selectedStoragePriceEntry.rateGroup.serviceType!;
    const commitmentType = selectedStoragePriceEntry.rateGroup.commitmentType!;
    const planName = selectedStoragePriceEntry.price.plan.name;
    storageOptions = Object.values(storagePriceEntries[commitmentType][serviceType]);
    commitmentOptions = Object.values(storagePriceEntries).map((entries) => entries[serviceType][planName]);
  } else if (storagePriceEntries && selectedCommitmentOption) {
    storageOptions = Object.values(storagePriceEntries[selectedCommitmentOption.commitmentType][DEFAULT_SERVICE_TYPE]);
  }

  return (
    <>
      {showConfirmPage ? (
        <Confirm
          orderID={orderID}
          error={error}
          initialStoragePriceEntry={initialStoragePriceEntry}
          initialProtectionPriceEntry={initialProtectionPriceEntry}
          initialAppointmentFees={initialAppointmentFees}
          initialCommitment={initialCommitmentOption}
          selectedStoragePriceEntry={selectedStoragePriceEntry!}
          selectedProtectionPriceEntry={selectedProtectionPriceEntry!}
          selectedAppointmentFees={selectedAppointmentFees!}
          selectedCommitment={selectedCommitmentOption}
          loading={updateLoading || addLoading}
          isAddingPlan={isAddingPlan}
          hideLabor={hideLabor}
          onNext={isAddingPlan ? onSubmitAdd : onSubmitUpdate}
          onPrev={() => setShowConfirmPage(false)}
        />
      ) : (
        <>
          <Title size="large">{isAddingPlan ? 'Select a plan' : 'Update your plan'}</Title>
          <Body>
            {isAddingPlan
              ? 'Your plan size will be automatically downgraded if you end up needing less storage space. You can make updates to your plan at any point before your move.'
              : 'Make updates to your plan at any point before your move-in date.'}
          </Body>
          <SelectorsAccordion>
            <StoragePlanSelector
              orderID={orderID}
              loading={componentLoading}
              initialPriceEntry={initialStoragePriceEntry}
              selectedPriceEntry={selectedStoragePriceEntry}
              options={storageOptions}
              setSelectedPriceEntry={setSelectedStoragePriceEntry}
            />
            <ProtectionPlanSelector
              orderID={orderID}
              loading={componentLoading}
              initialPriceEntry={initialProtectionPriceEntry}
              selectedPriceEntry={selectedProtectionPriceEntry}
              options={protectionPriceEntries || []}
              setSelectedPriceEntry={setSelectedProtectionPriceEntry}
            />
            <CommitmentSelector
              orderID={orderID}
              loading={componentLoading}
              initialCommitment={initialCommitmentOption}
              selectedCommitment={selectedCommitmentOption}
              onCommitmentChange={onStorageCommitmentChange}
              options={commitmentOptions
                ?.filter((option) => option.storageTerm && option.rateGroup)
                ?.map((option) => buildCommitmentOption(option))}
            />
            {!hourlyLaborRequired && supportedServiceType(initialAppointmentFees?.serviceType) && !hideLabor && (
              <ServiceSelector
                orderID={orderID}
                loading={componentLoading}
                initialAppointmentFees={initialAppointmentFees}
                selectedAppointmentFees={selectedAppointmentFees}
                options={appointmentFees}
                onOrderServiceChange={onOrderServiceChange}
              />
            )}
          </SelectorsAccordion>
          <Footer
            pageName={PLAN_UPDATE_PAGE_NAME}
            metaData={
              formValid ? planMetadata(orderID, selectedStoragePriceEntry, selectedProtectionPriceEntry) : undefined
            }
            onNext={() => setShowConfirmPage(true)}
            onPrev={() => history.push(redirectURL)}
            loading={componentLoading}
            valid={formValid}
          />
        </>
      )}
    </>
  );
};
