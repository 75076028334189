import * as React from 'react';
import { createPortal } from 'react-dom';

import { Button, Modal } from '@shared/components/bootstrap';

import { Fields } from './fields';
import { RewardsEmailForm } from './form';

export const Dialog: React.FC<{
  container: React.RefObject<HTMLElement>;
  source: string;
  onClose(): void;
}> = ({ container, source, onClose }) => {
  const Element = (
    <Modal onClose={onClose}>
      <RewardsEmailForm source={source}>
        {({ emails, onChange, message, saving, valid }) => (
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Refer a Friend</Modal.Title>
              <Modal.Close close={onClose} />
            </Modal.Header>
            <Modal.Body>
              <Fields emails={emails} onChange={onChange} message={message} />
            </Modal.Body>
            <Modal.Footer>
              <Button kind="secondary" disabled={saving} onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" kind="primary" disabled={!valid} loading={saving}>
                Send
              </Button>
            </Modal.Footer>
          </Modal.Content>
        )}
      </RewardsEmailForm>
    </Modal>
  );

  return createPortal(Element, container.current!);
};
