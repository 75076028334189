/* @jsx jsx */
import React from 'react';

import { jsx } from '@emotion/react';

import styled from '@emotion/styled';

import { COLORS, FontWeight, SERIF_FONT_FAMILY, Text } from '@clutter/clean';

import XButton from '../../images/x-button.svg';

import { Action as EmailAction } from './email/action';
import { Action as FBAction } from './fb/action';
import { Action as SMSAction } from './sms/action';
import { Action as TWAction } from './tw/action';

import { Email as EmailIcon, Facebook as FBIcon, SMS as SMSIcon, Twitter as TWIcon } from '../shared/icons';

const ShareBackdrop = styled.div`
  opacity: 0.8;
  background: ${COLORS.panther};
  z-index: 11;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
`;

const ShareCard = styled.div`
  width: 325px;
  height: 431px;
  background: ${COLORS.cloud};
  border-radius: 4px;
  position: fixed;
  z-index: 12;
  left: 50%;
  transform: translateX(-50%);
  top: 45px;
`;

const XButtonContainer = styled.img`
  position: absolute;
  left: -4px;
  padding: 16px;
  cursor: pointer;
`;

const ShareDialog = styled.div`
  font-weight: ${FontWeight.Medium};
  font-family: ${SERIF_FONT_FAMILY};
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  margin: 72px auto 29px;
  text-align: center;
`;

const ShareRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 72px;
  padding-top: 16px;
  padding-left: 25px;
  border-bottom: 1.5px solid ${COLORS.grayBorder};
  cursor: pointer;
`;

const RowName = styled(Text.Body)`
  margin-left: 16px;
  margin-top: 8px;
`;

const ICON_HEIGHT = 40;
const ICON_WIDTH = 40;

export const ShareModal: React.FC<{
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  hidden: boolean;
  hideModal(): void;
  source: string;
}> = ({ containerRef, hidden, hideModal, source }) => (
  <div className="rewards__share">
    <ShareBackdrop hidden={hidden} onClick={hideModal} />
    <ShareCard hidden={hidden}>
      <XButtonContainer src={XButton} onClick={hideModal} />
      <ShareDialog>Share your code</ShareDialog>
      <SMSAction source={source}>
        <ShareRow>
          <SMSIcon height={ICON_HEIGHT} width={ICON_WIDTH} />
          <RowName weight={FontWeight.Medium}>SMS</RowName>
        </ShareRow>
      </SMSAction>
      <EmailAction source={source} container={containerRef} onClick={hideModal}>
        <ShareRow>
          <EmailIcon height={ICON_HEIGHT} width={ICON_WIDTH} />
          <RowName weight={FontWeight.Medium}>Email</RowName>
        </ShareRow>
      </EmailAction>
      <FBAction source={source}>
        <ShareRow>
          <FBIcon height={ICON_HEIGHT} width={ICON_WIDTH} />
          <RowName weight={FontWeight.Medium}>Facebook</RowName>
        </ShareRow>
      </FBAction>
      <TWAction source={source}>
        <ShareRow>
          <TWIcon height={ICON_HEIGHT} width={ICON_WIDTH} />
          <RowName weight={FontWeight.Medium}>Twitter</RowName>
        </ShareRow>
      </TWAction>
    </ShareCard>
  </div>
);
