import cn from 'classnames';
import * as React from 'react';
import { useEffect } from 'react';

import { useKey } from '@shared/hooks';
import { Portal } from 'react-portal';

const BACKDROP_DEFAULT = true;
const KEYBOARD_DEFAULT = true;

interface IModalProps {
  id?: string;
  size?: ModalSize;
  centered?: boolean;
  backdrop?: boolean | 'static';
  keyboard?: boolean;
  className?: string;
  children: React.ReactNode;
  onClose(): void; // NOTE: this callback is fired whenever the modal requests to close
}

export enum ModalSize {
  Large = 'lg',
  Small = 'sm',
}

const ModalClose: React.FC<{
  disabled?: boolean;
  close(): void;
}> = ({ close, disabled }) => (
  <button
    disabled={disabled}
    type="button"
    className="close"
    onClick={(event) => {
      event.preventDefault();
      event.stopPropagation();
      close();
    }}
  >
    &times;
  </button>
);

type ModalTitleTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
const DEFAULT_MODAL_TITLE_TAG = 'h4';

const ModalTitle: React.FC<{
  id?: string;
  tag?: ModalTitleTag;
}> = ({ id, children, tag = DEFAULT_MODAL_TITLE_TAG }) => {
  const Tag = tag;
  return (
    <Tag id={id} className="modal-title">
      {children}
    </Tag>
  );
};

const ModalHeader: React.FC = ({ children }) => <div className="modal-header">{children}</div>;

const ModalBody: React.FC<{ className?: string }> = ({ children, className }) => (
  <div className={cn('modal-body', className)}>{children}</div>
);

const ModalFooter: React.FC = ({ children }) => <div className="modal-footer">{children}</div>;

const ModalContent: React.FC<{ className?: string }> = ({ children, className }) => (
  <div className={cn('modal-content', className)}>{children}</div>
);

const Modal: React.FC<IModalProps> = ({
  id,
  centered,
  size,
  backdrop = BACKDROP_DEFAULT,
  keyboard = KEYBOARD_DEFAULT,
  onClose,
  children,
  className,
}) => {
  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => document.body.classList.remove('modal-open');
  }, []);

  useKey('Escape', 'keydown', () => {
    if (!keyboard) {
      return;
    }
    onClose();
  });

  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (backdrop !== 'static' && event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <Portal>
      <div id={id} className={cn('modal show', className)} role="dialog" style={{ display: 'block' }} onClick={onClick}>
        <div
          className={cn('modal-dialog', centered && 'modal-dialog-centered', size && `modal-${size}`)}
          role="document"
        >
          {children}
        </div>
      </div>
      {/* '.in' is for bootstrap 3 and '.show' is for bootstrap 4 */}
      {backdrop && <div className="modal-backdrop in show" />}
    </Portal>
  );
};

const Combined = Object.assign(Modal, {
  Size: ModalSize,
  Title: ModalTitle,
  Close: ModalClose,
  Header: ModalHeader,
  Body: ModalBody,
  Footer: ModalFooter,
  Content: ModalContent,
});

export { Combined as Modal };
