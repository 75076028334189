import React from 'react';

import { orderURL } from '@portal/config/routes';
import { Pluralize } from '@shared/components/helpers';
import { AppointmentDetailsOrder } from '../full_panel';
import { LineItem, TrackedLinkAction } from '../../line_item';
import { wtLinkParams } from './constants';

function viewSupplyKitMaterialsParams(supplyKitID: string) {
  return {
    ...wtLinkParams,
    objectName: 'supply_kit_materials',
    label: 'View',
    value: orderURL(supplyKitID, 'supply_kits'),
    order_id: supplyKitID,
  };
}

export const SupplyKitMaterialsLineItem: React.FC<{ delivery: AppointmentDetailsOrder }> = ({ delivery }) => (
  <LineItem
    label="Supply Kit Materials"
    action={
      <TrackedLinkAction
        params={{
          text: 'View',
          url: orderURL(delivery.id, 'supply_kits'),
          trackingParams: viewSupplyKitMaterialsParams(delivery.id),
        }}
      />
    }
  >
    <Pluralize count={delivery.retailDeliveryItems[0].quantity} singular="Kit" plural="Kits" />
  </LineItem>
);
