/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router';
import { orderURL } from '@portal/config/routes';

import { Header } from '@portal/components/helpers/header';
import { TrackedClick } from '@portal/components/wt/tracked_click';
import { Button } from '@clutter/clean';
import { PaperworkClipboard } from '@portal/components/shared/icons';
import { Spacer } from '@shared/components/helpers';
import { Button as CustomerProtectedButton } from '@portal/components/shared/customer_protected/button';
import { L1_AGENT_L2_ONLY_ERROR_MESSAGE } from '@portal/components/shared/customer_protected/util';
import { Base } from './base';

const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 36px;
  display: flex;
  align-items: top;
`;

export const MissingSignature: React.FC<{ needSignature: string; skipSignature?: () => void }> = ({
  needSignature,
  skipSignature,
}) => {
  const history = useHistory();

  const params = {
    pageName: 'portal:new_appointment',
    container: 'need_signature_prompt',
    objectType: 'button',
    action: 'click',
    objectName: 'need_signature',
    label: 'Sign Documents',
  };

  return (
    <Base>
      <Header tag="h3">Sign your documents before scheduling your next appointment!</Header>
      <Container>
        <div
          css={css`
            margin-right: 24px;
          `}
        >
          <PaperworkClipboard />
        </div>
        <div>
          Looks like you missed signing some documents from your last appointment. Please finish signing your documents
          in order to schedule your next appointment.
        </div>
      </Container>
      <TrackedClick params={params}>
        <Button onClick={() => history.push(orderURL(needSignature, 'signatures'))}>Sign Documents</Button>
      </TrackedClick>
      {skipSignature && (
        <div>
          <Spacer height="32px" />
          <Header tag="h4">Agent Options</Header>
          <CustomerProtectedButton
            onClick={() => skipSignature()}
            errorMessage={L1_AGENT_L2_ONLY_ERROR_MESSAGE}
            showErrorInTooltip={true}
          >
            Continue Booking
          </CustomerProtectedButton>
        </div>
      )}
    </Base>
  );
};
