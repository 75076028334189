import introspectionQueryResultData from '@admin/fragments';
import { DEFAULT_APOLLO_CLIENT_LINK } from '@shared/libraries/apollo';

import { InMemoryCache, ApolloClient } from '@apollo/client';

export const client = new ApolloClient({
  link: DEFAULT_APOLLO_CLIENT_LINK,
  cache: new InMemoryCache({
    possibleTypes: introspectionQueryResultData.possibleTypes,
  }),
});
