import React from 'react';

import { ClaimIssueSelectionFragment } from '@portal/schema';

export const DEFAULT_CLAIM_ISSUE_SELECTION: ClaimIssueSelectionFragment = {
  id: '',
  photos: [],
};

export type ClaimIssueSelectionChanger =
  | ClaimIssueSelectionFragment
  | ((selection: ClaimIssueSelectionFragment) => ClaimIssueSelectionFragment);

interface IContext {
  selection: ClaimIssueSelectionFragment;
  onChange(changer: ClaimIssueSelectionChanger): void;
}

const { Provider, Consumer } = React.createContext<IContext>({
  selection: DEFAULT_CLAIM_ISSUE_SELECTION,
  onChange: (_: ClaimIssueSelectionChanger) => {
    /* noop */
  },
});

export { Provider as ClaimIssueSelectionProvider };
export { Consumer as ClaimIssueSelectionConsumer };
