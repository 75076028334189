export enum TicketTicketableType {
  CustomerTicket = 'CUSTOMER_TICKET',
  Account = 'ACCOUNT',
  Item = 'Item',
  User = 'User',
  Order = 'Order',
  SelfStorageUnit = 'SELF_STORAGE_UNIT',
  LogisticsLoad = 'Logistics::Load',
  LogisticsSku = 'Logistics::Sku',
}

export interface ITicketable {
  id?: number | string;
  type?: TicketTicketableType;
  displayName?: string;
  feature?: string;
}
