export enum PlanKind {
  Item = 'ITEM',
  Fixed = 'FIXED',
  Custom = 'CUSTOM',
}

export enum PlanType {
  ProtectionPlan = 'ProtectionPlan',
  StoragePlan = 'StoragePlan',
}

export interface IPlan {
  id: number | string;
  kind: PlanKind;
  type: PlanType;
  cuft: number;
  name: string;
  dimensions?: IPlanDimensions;
}

export interface IPlanDimensions {
  width: number;
  length: number;
  height: number;
}

export const MINIMAL_PROTECTION_PLAN_SLUG = 'minimal_coverage';
