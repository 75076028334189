import * as React from 'react';
import styled from '@emotion/styled';
import { COLORS, Column, Grid } from '@clutter/clean';

import { SERVICE_TYPE_MAPPING } from '@portal/components/orders/service_type/service_type_mapping';
import { bodyStyle, Heading } from '@portal/components/shared/text';
import { accountURL } from '@portal/config/routes';

import { Link } from 'react-router-dom';

import { OrderServiceTypeEnum, OrderTypeEnum, Pricing__PolicyNoticeInfoFragment } from '@portal/schema';
import { Button, Footer } from './base';
import { IStepProps } from '../form';

const Container = styled.div`
  /* bottom 80px for handling text display above fixed footer */
  padding: 16px 9px 80px;
  @media (min-width: 600px) {
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  padding: 16px 24px;
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  @media (min-width: 600px) {
    margin: 0 auto;
  }
  button {
    width: 150px;
  }
`;

const CenteredGrid = styled(Grid)`
  align-items: flex-start;
`;

const PricingBox = styled.div`
  width: 100%;
  border: 1px solid ${COLORS.grayBorder};
  border-radius: 4px;
  box-sizing: border-box;
  max-width: 420px;
  min-height: 150px;
  margin: 0 auto;
  margin-bottom: 18px;
  &:first-of-type {
    margin-top: 16px;
  }
  &:last-of-type {
    margin-bottom: 32px;
  }
`;

const PricingBoxContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  h2 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0;
  }
`;

const RowDetail = styled.div`
  display: flex;
  align-items: center;
  & > div {
    font-size: 14px;
    line-height: 19px;
    margin: 0 24px;
    text-align: left;
  }
`;
const RowPrice = styled.div`
  font-weight: bold;
  text-align: right;
`;
const PriceDesc = styled.span`
  font-weight: 600;
  font-size: 12px;
`;

const Description = styled.p`
  ${bodyStyle}
  margin-bottom: 32px;
`;

const Notice = styled.p`
  color: ${COLORS.storm};
  font-size: 14px;
`;

const Icon = styled.span`
  flex-shrink: 0;
  height: 72px;
  width: 80px;
`;

const getPricing = (
  serviceType: OrderServiceTypeEnum,
  type: OrderTypeEnum,
  pricingPolicy: Pricing__PolicyNoticeInfoFragment,
) => {
  let price: string | null = null;

  if (serviceType === OrderServiceTypeEnum.Shipment) {
    price = 'Standard Shipping';
  } else if (serviceType === OrderServiceTypeEnum.FullService) {
    if (type === OrderTypeEnum.Pickup) {
      price = pricingPolicy.subsequentPickupRate;
    } else if (type === OrderTypeEnum.Return) {
      price = pricingPolicy.subsequentReturnRate;
    }
  } else if (serviceType === OrderServiceTypeEnum.Facility) {
    price = pricingPolicy.facilityAppointmentCostPerCuft;
  } else if (serviceType === OrderServiceTypeEnum.Disposal) {
    price = pricingPolicy.disposalDonationCostPerCuft;
  }

  if (price) {
    return <PriceDesc>{price}</PriceDesc>;
  }
};

export const PricingPolicyNotice: React.FC<
  {
    pricingPolicy: Pricing__PolicyNoticeInfoFragment;
    onNext?(): void;
    onPrev?(): void;
  } & IStepProps
> = ({ pricingPolicy, onPrev, onNext }) => {
  const goNext = () => {
    localStorage.setItem('hidePricingPolicyNotice', 'true');
    onNext!();
  };

  return (
    <Container>
      <Heading level={1} size="medium">
        Conveniently store and access items
      </Heading>
      <Description>With Clutter, you have several options for storing and accessing items.</Description>
      <CenteredGrid wrap>
        <Column widths={[12, 12, 6]}>
          <div>
            <Heading level={1} size="small">
              Store more items
            </Heading>
            {SERVICE_TYPE_MAPPING[OrderTypeEnum.Pickup]!.serviceTypeOptions.map((serviceType) => (
              <PricingBox key={serviceType.serviceType}>
                <PricingBoxContent>
                  <RowDetail>
                    <Icon>
                      <serviceType.icon />
                    </Icon>
                    <div>
                      <Heading level={2} size="small">
                        {serviceType.infoHeader}
                      </Heading>
                      <div>{serviceType.infoBody()}</div>
                    </div>
                  </RowDetail>
                  <RowPrice>{getPricing(serviceType.serviceType, OrderTypeEnum.Pickup, pricingPolicy)}</RowPrice>
                </PricingBoxContent>
              </PricingBox>
            ))}
          </div>
        </Column>
        <Column widths={[12, 12, 6]}>
          <div>
            <Heading level={1} size="small">
              Get items back
            </Heading>
            {SERVICE_TYPE_MAPPING[OrderTypeEnum.Return]!.serviceTypeOptions.filter(
              (serviceTypeOption) => serviceTypeOption.serviceType !== OrderServiceTypeEnum.LongDistance,
            ).map((serviceType) => (
              <PricingBox key={serviceType.serviceType}>
                <PricingBoxContent>
                  <RowDetail>
                    <Icon>
                      <serviceType.icon />
                    </Icon>
                    <div>
                      <Heading level={2} size="small">
                        {serviceType.infoHeader}
                      </Heading>
                      <div>{serviceType.infoBody()}</div>
                    </div>
                  </RowDetail>
                  <RowPrice>{getPricing(serviceType.serviceType, OrderTypeEnum.Return, pricingPolicy)}</RowPrice>
                </PricingBoxContent>
              </PricingBox>
            ))}
          </div>
        </Column>
      </CenteredGrid>
      <Notice>
        If you’re closing your account, your final return appointment will be priced according to the policy in your{' '}
        <Link to={accountURL()} target="_blank">
          Account Details.
        </Link>
      </Notice>
      <Footer>
        <ButtonContainer>
          <Button onClick={onPrev} className="secondary">
            Back
          </Button>
          <Button onClick={goNext} className="primary">
            Got It
          </Button>
        </ButtonContainer>
      </Footer>
    </Container>
  );
};
