import React from 'react';

const ID = 'arrow-down';

export const ArrowDown: React.FC<{ title?: string; strokeColor?: string; width?: string; height?: string }> = ({
  title = 'Expand',
  strokeColor = '#097E77',
  width = '18',
  height = '11',
}) => (
  <svg
    aria-labelledby={ID}
    width={width}
    height={height}
    viewBox={`0 0 18 11`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id={ID}>{title}</title>
    <path d="M1 1L9 9L17 1" stroke={strokeColor} strokeWidth="2" />
  </svg>
);
