import React from 'react';

import { COLORS } from '@clutter/clean';

export const Twitter: React.FC<{ height: number; width: number }> = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    preserveAspectRatio="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill={COLORS.tealPrimary} />
    <path
      d="M30.955 13.2626C30.1069 13.6415 29.2013 13.8996 28.2478 14.0175C29.2195 13.4224 29.967 12.4844 30.3206 11.3653C29.4092 11.9058 28.3992 12.2993 27.324 12.5185C26.4654 11.5844 25.2416 11 23.8828 11C21.2791 11 19.168 13.1457 19.168 15.7892C19.168 16.1691 19.2111 16.5343 19.2897 16.884C15.3702 16.696 11.8964 14.7831 9.5716 11.8912C9.16241 12.5945 8.93338 13.4116 8.93338 14.3019C8.93338 15.9674 9.76709 17.4314 11.0301 18.2914C10.2568 18.2661 9.52944 18.0499 8.89505 17.6914V17.7509C8.89505 20.0739 10.5174 22.0112 12.6765 22.4524C12.2807 22.5605 11.8629 22.619 11.4345 22.619C11.1336 22.619 10.8452 22.5897 10.5567 22.5352C11.1614 24.4374 12.8998 25.8244 14.9687 25.8634C13.3588 27.1481 11.3186 27.9137 9.1212 27.9137C8.74747 27.9137 8.37469 27.8913 8 27.8484C10.0977 29.2062 12.5691 30 15.2418 30C23.9182 30 28.657 22.6988 28.657 16.3775C28.657 16.1739 28.657 15.9684 28.6426 15.7639C29.5635 15.0928 30.3675 14.2444 31 13.2821L30.955 13.2626Z"
      fill="white"
    />
  </svg>
);
