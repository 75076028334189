import { useEffect, useState } from 'react';

export const useLastDefined = <T>(value: T) => {
  const [lastDefined, setLastDefined] = useState(value);
  useEffect(() => {
    if (value !== undefined) {
      setLastDefined(value);
    }
  }, [value]);

  return lastDefined;
};
