import React, { useState } from 'react';

import styled from '@emotion/styled';

import { ImageModal } from '@shared/components/helpers';
import { ImageFragment } from '@portal/schema';

import { Fit, Picture } from '@shared/components/helpers';
import { Box, COLORS, Text, toggleStyleValue, UnstyledButton } from '@clutter/clean';

const Image = styled(Picture)`
  overflow: hidden;
  border-radius: 4px 4px 0 0;
`;

const HeroToggle = styled(UnstyledButton)<{ selected: boolean }>`
  background: ${toggleStyleValue('selected', COLORS.tealBackground, COLORS.cloud)};
  border-radius: 0 0 4px 4px;
  border: 1px solid ${COLORS.grayBorder};
  color: ${COLORS.tealPrimary};
  display: block;
  height: 44px;
  width: 100%;
`;

export const ImageGrid: React.FC<{
  hero: ImageFragment;
  images: ImageFragment[];
  updateHero(id: string): void;
}> = ({ hero, images, updateHero }) => {
  const [selection, setSelection] = useState<ImageFragment | undefined>();

  // The hero image is always first in the array of images, but we shouldn't
  // shuffle images while the detail panel is open.
  const [cachedImages] = useState(images);

  return (
    <>
      <Box.Flex flexWrap="wrap" margin="-8px">
        {cachedImages.map((image) => (
          <Box key={image.source} margin="8px">
            <UnstyledButton onClick={() => setSelection(image)}>
              <Image source={image.source} width={186} height={232} fit={Fit.Crop} />
            </UnstyledButton>
            <HeroToggle selected={image.id === hero.id} onClick={() => updateHero(image.id)}>
              <Text.Button>{image.id === hero.id ? 'Default' : 'Set as Default'}</Text.Button>
            </HeroToggle>
          </Box>
        ))}
      </Box.Flex>
      <ImageModal image={selection} onClose={() => setSelection(undefined)} />
    </>
  );
};
