import {
  ClaimIssueSelectionFragment,
  ClaimItemSelectionFragment,
  ClaimPropertySelectionFragment,
} from '@portal/schema';

export enum ClaimEntryKind {
  Issue = 'ISSUE',
  Item = 'ITEM',
  Property = 'PROPERTY',
}

interface IClaimIssueSelectionEntry {
  kind: ClaimEntryKind.Issue;
  selection: ClaimIssueSelectionFragment;
}

interface IClaimItemSelectionEntry {
  kind: ClaimEntryKind.Item;
  selection: ClaimItemSelectionFragment;
}

interface IClaimPropertySelectionEntry {
  kind: ClaimEntryKind.Property;
  selection: ClaimPropertySelectionFragment;
}

export type IClaimEntry = IClaimIssueSelectionEntry | IClaimItemSelectionEntry | IClaimPropertySelectionEntry;
