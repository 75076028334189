import { IReasonListProps, ReasonList } from '@portal/components/account_resolution/reason_list';
import {
  Calendar,
  LivingRoom,
  ManWaving,
  PaperworkClipboard,
  PayingWithCreditCard,
} from '@portal/components/shared/icons';
import { AccountResolution__ReasonChild } from '@portal/schema';
import { SupportFlowSlug } from '@portal/config/routes';
import { Box, COLORS, FontWeight, Text, UnstyledButton } from '@clutter/clean';
import styled from '@emotion/styled';
import * as React from 'react';
import { TrackedClick } from '@portal/components/wt/tracked_click';
import { IEventParams } from '@portal/types/wt/event';

const Label = styled(Text.Body)`
  color: ${COLORS.tealPrimary};
`;

const ReasonButton = styled(UnstyledButton)`
  &:hover {
    & > div > div {
      color: ${COLORS.tealBrand};
    }
  }
`;

enum RootReason {
  Account = 'My Account',
  Appointments = 'Appointments',
  BillingAndPayments = 'Billing & Payments',
  Claims = 'Claims',
  PlanAndItems = 'My Storage Plan & Items',
}

const WT_PARAMS: IEventParams = {
  action: 'click',
  objectType: 'card',
};

const reasonIcon = (reason: RootReason): React.ReactNode | undefined => {
  switch (reason) {
    case RootReason.Account:
      return <ManWaving secondary width={73} height={78} />;
    case RootReason.Appointments:
      return <Calendar />;
    case RootReason.BillingAndPayments:
      return <PayingWithCreditCard />;
    case RootReason.Claims:
      return <PaperworkClipboard />;
    case RootReason.PlanAndItems:
      return <LivingRoom />;
    default:
      return undefined;
  }
};

export const RootReasons: React.FC<
  IReasonListProps & { except?: AccountResolution__ReasonChild; supportFlowSlug?: SupportFlowSlug }
> = ({ reasons, onSelect, except, supportFlowSlug }) => {
  const displayReasons = except ? reasons.filter((reason) => reason.id !== except.id) : reasons;
  const displayAsList = supportFlowSlug && supportFlowSlug === SupportFlowSlug.DayOf;

  return (
    <>
      {except && <Text.Title size="small">Other ways we can help</Text.Title>}
      {displayAsList ? (
        <ReasonList reasons={displayReasons} onSelect={onSelect} />
      ) : (
        <Box.Flex flexWrap="wrap" margin="0 -8px">
          {displayReasons.map((reason, index) => (
            <Box key={reason.id} margin="8px">
              <TrackedClick
                params={{
                  ...WT_PARAMS,
                  container: except ? 'reasons_start' : 'help_tiles_toplevel',
                  label: reason.copy,
                  objectName: reason.canonicalName,
                  pageName: except ? 'portal:help_reasons' : 'portal:help_start',
                  position: index + 1,
                  selected_reason_id: reason.id,
                }}
              >
                <ReasonButton aria-label={reason.copy} onClick={() => onSelect(reason)}>
                  <Box.Flex
                    width="140px"
                    height="180px"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-evenly"
                    background={COLORS.grayBackground}
                    borderRadius="4px"
                    padding="16px"
                  >
                    {reasonIcon(reason.copy as RootReason)}
                    <Label weight={FontWeight.Medium}>{reason.copy}</Label>
                  </Box.Flex>
                </ReasonButton>
              </TrackedClick>
            </Box>
          ))}
        </Box.Flex>
      )}
    </>
  );
};
