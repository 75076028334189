import { COLORS } from '@clutter/clean';
import React from 'react';

const ID = 'warning';

export const Warning: React.FC<{
  className?: string;
  title?: string;
  color?: string;
}> = ({ className, title = 'Warning', color = COLORS.toucan }) => (
  <svg
    className={className}
    aria-labelledby={ID}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id={ID}>{title}</title>
    <path
      d="M15.4129 13.3334L9.18156 1.43335C8.83976 0.7811 8.03392 0.529434 7.38167 0.871231C7.1415 0.997089 6.94541 1.19318 6.81955 1.43335L0.58755 13.3334H0.58755C0.24587 13.9857 0.497686 14.7915 1.15 15.1331C1.34095 15.2332 1.55332 15.2854 1.76888 15.2854H14.2316C14.9679 15.2855 15.565 14.6886 15.5651 13.9522C15.5651 13.7367 15.5129 13.5243 15.4129 13.3334H15.4129ZM7.33356 5.61535C7.33356 5.24716 7.63203 4.94868 8.00022 4.94868C8.36841 4.94868 8.66689 5.24716 8.66689 5.61535V9.61535C8.66689 9.98354 8.36841 10.282 8.00022 10.282C7.63203 10.282 7.33356 9.98354 7.33356 9.61535V5.61535ZM8.03356 13.2887H8.01489C7.46861 13.2869 7.02096 12.8546 7.00022 12.3087C6.98025 11.7656 7.40431 11.3092 7.9474 11.2892C7.95345 11.289 7.9595 11.2888 7.96556 11.2887H7.98423C8.53042 11.2893 8.97877 11.7209 9.00023 12.2667C9.02165 12.8093 8.59911 13.2666 8.05645 13.288C8.04882 13.2883 8.04119 13.2886 8.03356 13.2887L8.03356 13.2887Z"
      fill={color}
    />
  </svg>
);
