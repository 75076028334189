import React from 'react';
import { Accordion, FontWeight, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { Title } from './title';
import { Section } from './section';

const ENTRIES = [
  {
    title: `What happens if I don’t complete payment with Affirm within 48 hrs?`,
    content: `Clutter provides all customers a 48 hour grace period within which to complete their Affirm payment. If you are unable to complete your Affirm payment or do not qualify for Affirm, no worries. We will fall back to charging your credit card on file.`,
  },
  {
    title: `I don’t want to use Affirm anymore. Can I use another form of payment?`,
    content: `Yes. Please make sure the credit card you have on file is accurate. Once the window for your Affirm payment has lapsed (48 hours), we will automatically charge your card. Please do not apply for an Affirm loan if you don’t want to be charged through Affirm.`,
  },
  {
    title: `What happens if my move costs more than I’m prequalified to spend?`,
    content: `If your move costs more than your Affirm pre-qualification, Affirm will provide you with the ability to pay for the remainder (not covered by Affirm) with a credit card. If Affirm is chosen to cover any part of your moving costs, Clutter will not charge you. And Affirm will be your primary provider.`,
  },
  {
    title: `Can Affirm cover tips to my movers?`,
    content: `Yes! All tips flow through to your Affirm payment.`,
  },
];

const StepContainer = styled.div`
  margin: 0 -15px;
`;

const Content = styled.div`
  padding: 0 24px 24px;
`;

export const FAQ: React.FC = () => (
  <Section>
    <Title>We’re here to help</Title>
    <StepContainer>
      <Accordion.Group>
        {ENTRIES.map((entry, key) => (
          <Accordion.Item
            key={key}
            name={entry.title}
            label={<Text.Body weight={FontWeight.Medium}>{entry.title}</Text.Body>}
          >
            <Content>{entry.content}</Content>
          </Accordion.Item>
        ))}
      </Accordion.Group>
    </StepContainer>
  </Section>
);
