import React from 'react';

export const ThreeStars: React.FC<{ flipHorizontal?: boolean }> = ({ flipHorizontal = false }) => (
  <svg
    width="27"
    height="22"
    viewBox="0 0 27 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform={flipHorizontal ? 'scale(-1 1)' : ''}
  >
    <path
      d="M19.1688 7.08847L17.9493 12.7093L12.3285 13.9197L17.9493 15.1392L19.1688 20.76L20.3792 15.1392L26 13.9197L20.3792 12.7093L19.1688 7.08847Z"
      fill="#FCB22C"
      stroke="#FCB22C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.77112 1L7.38603 3.94337L4.45182 4.32848L7.38603 4.71359L7.77112 7.65696L8.15625 4.71359L11.0996 4.32848L8.15625 3.94337L7.77112 1Z"
      fill="#FCB22C"
      stroke="#FCB22C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2.39844 10.2153V13.9197" stroke="#FCB22C" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M0.545567 12.0675H4.25" stroke="#FCB22C" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
