import React from 'react';

import styled from '@emotion/styled';

import { Input } from '@clutter/clean';

const Digit = styled(Input)`
  font-family: 'Basier Square Mono', Monaco, Courier, monospace;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 16px;
  text-align: center;
  width: 100%;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button,
  &::-webkit-textfield-decoration-container {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const PLACEHOLDER = '•';
export const VerificationInput: React.FC<{
  length: number;
  disabled?: boolean;
  value: string;
  onChange(value: string): void;
  state: 'error' | 'valid' | undefined;
}> = ({ length, disabled, value, onChange, state }) => (
  <Digit
    autoFocus
    autoComplete="off"
    inputMode="numeric"
    disabled={disabled}
    type="number"
    name="digits"
    placeholder={PLACEHOLDER.repeat(length)}
    value={value}
    onChange={(event): void => {
      const target = event.target;
      const newValue = target.value;
      onChange(newValue);
      if (newValue.length === length) {
        target.blur();
      }
    }}
    onFocus={(): void => {
      if (value.length === length) {
        onChange('');
      }
    }}
    state={state}
  />
);
