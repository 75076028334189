import React from 'react';
import { Link } from 'react-router-dom';
import { TrackedClick } from '@portal/components/wt/tracked_click';
import { supportURL } from '@portal/config/routes';
import { IEventParams } from '@portal/types/wt/event';
import { COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { HELP_TOGGLE_Z_INDEX } from '@portal/constants/z_index';

const FooterContainer = styled.div`
  pointer-events: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${HELP_TOGGLE_Z_INDEX};
`;

const ButtonContainer = styled.div`
  padding: 1em 1.8em;
  float: right;
  margin: 0em 2.5em 2.2em 0em;
  color: ${COLORS.cloud};
  background: ${COLORS.tealPrimary};
  box-shadow: 0px 4px 12px rgb(0 0 0 / 10%);
  border-radius: 50px;
  pointer-events: all;
  @media (max-width: 600px) {
    margin: 0em 1em 1em 0em;
  }
`;

const BUTTON_TEXT = 'I need help...';

const WT_PARAMS: IEventParams = {
  action: 'click',
  container: 'help_bubble',
  label: BUTTON_TEXT,
  pageName: 'portal',
  objectName: 'help',
  objectType: 'button',
};

export const Footer: React.FC = () => (
  <FooterContainer>
    <TrackedClick
      params={{
        ...WT_PARAMS,
        value: supportURL(),
      }}
    >
      <Link to={supportURL()}>
        <ButtonContainer>
          <Text.Button>{BUTTON_TEXT}</Text.Button>
        </ButtonContainer>
      </Link>
    </TrackedClick>
  </FooterContainer>
);
