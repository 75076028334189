import * as React from 'react';

import { Spacing, Text } from '@shared/components/bootstrap';

import { useInventoryForSave } from './use_inventory_for_save';

import { EstimatedItemSelections } from './estimated_item_selections';

export const ExtraItemsSummary: React.FC = () => {
  const inventory = useInventoryForSave();

  if (inventory.hasExtraItems !== undefined) {
    return (
      <Spacing pb={3}>
        <Text tag="p" style="info">
          <strong>Would you like to list any items not captured above?</strong>
        </Text>
        {inventory.extraItems && inventory.extraItems.length > 0 ? (
          <EstimatedItemSelections selections={inventory.extraItems} />
        ) : (
          <Text tag="p">
            <strong>No</strong>
          </Text>
        )}
        <hr />
      </Spacing>
    );
  }
  return null;
};
