import styled from '@emotion/styled';
import React, { MouseEventHandler, useContext } from 'react';
import { Link } from 'react-router-dom';

import { Box, COLORS, FontWeight, Text, Tooltip } from '@clutter/clean';
import { VISITOR } from '@portal/config/visitor';
import { Display, Dropdown, DropdownContext, Nav, Navbar, NavbarContext } from '@shared/components/bootstrap';
import { L1AgentLimitations, BLOCK_ACTION } from '@portal/components/shared/customer_protected/util';
import { TrackedClick } from '@portal/components/wt/tracked_click';
import { TrackedLink } from '@portal/components/wt/tracked_link';
import {
  accountURL,
  billingURL,
  claimsURL,
  itemsURL,
  logoutURL,
  ordersURL,
  referralsURL,
  settingsURL,
  supportURL,
} from '@portal/config/routes';
import { IEventParams } from '@portal/types/wt/event';

import LOGO from '@portal/images/logo.svg';
import gift from '@portal/images/gift.svg';
import infoTooltip from '@portal/images/info_tooltip.svg';
import { useCustomerNameQuery } from '@portal/schema';
import { IMPERSONATOR } from '@portal/config/impersonator';
import { Copyright } from './copyright';
import { ScheduleAppointmentLink } from './schedule_appointment_link';

export const HEADER_HEIGHT = 64;
export const BANNER_HEIGHT = 40;

const EXPAND = 'md';

const StyledNavbar = styled(Navbar)`
  min-height: ${HEADER_HEIGHT}px;
  z-index: 10;
`;

const FooterWrapper = styled.div`
  font-size: 12px;
  &:hover a {
    background-color: initial !important;
    cursor: default;
  }
  @media (max-width: 600px) {
    padding-top: 5px;
  }
`;

const Teal = styled.span`
  color: ${COLORS.tealPrimary};
`;

const ReferralLabel = styled.div`
  display: flex;
`;

const GiftContainer = styled.img`
  margin-right: 18px;
`;

const WT_PARAMS: IEventParams = {
  pageName: 'portal',
  container: 'navigation',
  action: 'click',
  objectType: 'link',
};

const TrackedAnchorLink: React.FC<{
  href: string;
  objectName: string;
  label: string;
  component: React.ElementType;
  onClick?: MouseEventHandler;
  children?: React.ReactNode;
}> = ({ href, objectName, label, component: Component, onClick, children }) => (
  <TrackedLink
    params={{
      ...WT_PARAMS,
      objectName,
      label,
      value: href,
    }}
  >
    <Component href={href} onClick={onClick}>
      {children ?? label}
    </Component>
  </TrackedLink>
);

const TrackedRouterLink: React.FC<{
  href: string;
  objectName: string;
  label: string;
  component?: React.ElementType;
  onClick?: () => void;
  children?: React.ReactNode;
}> = ({ href, objectName, label, component: Component = Link, onClick, children }) => (
  <TrackedClick
    params={{
      ...WT_PARAMS,
      objectName,
      label,
      value: href,
    }}
  >
    <Component to={href} onClick={onClick}>
      {children ?? label}
    </Component>
  </TrackedClick>
);

const SharedNav: React.FC = () => {
  const { onToggle } = useContext(NavbarContext);

  return (
    <Navbar.Nav margin="mr">
      <Nav.Item>
        <TrackedRouterLink
          href={itemsURL()}
          objectName="my_storage"
          label="My Storage"
          component={Nav.RouterLink}
          onClick={onToggle}
        />
      </Nav.Item>
      <Nav.Item>
        <TrackedRouterLink
          href={ordersURL()}
          objectName="appointments"
          label="Appointments"
          component={Nav.RouterLink}
          onClick={onToggle}
        />
      </Nav.Item>
      <Nav.Item>
        <TrackedRouterLink
          href={referralsURL()}
          objectName="refer_a_friend"
          label="Refer a Friend"
          component={Nav.RouterLink}
          onClick={onToggle}
        />
      </Nav.Item>
    </Navbar.Nav>
  );
};

const MobileNav: React.FC = () => {
  const { onToggle } = useContext(NavbarContext);

  return (
    <>
      <TrackedRouterLink
        href={accountURL()}
        objectName="account_details"
        label="Account Details"
        component={Nav.RouterLink}
        onClick={onToggle}
      />
      <TrackedRouterLink
        href={billingURL()}
        objectName="billing"
        label="Billing"
        component={Nav.RouterLink}
        onClick={onToggle}
      />
      <TrackedRouterLink
        href={settingsURL()}
        objectName="settings"
        label="Settings"
        component={Nav.RouterLink}
        onClick={onToggle}
      />
      <TrackedRouterLink
        href={claimsURL()}
        objectName="claims"
        label="Claims"
        component={Nav.RouterLink}
        onClick={onToggle}
      />
      <ScheduleAppointmentLink
        component={({ href, text, objectName, onClick }) => (
          <TrackedAnchorLink objectName={objectName} href={href} label={text} onClick={onClick} component={Nav.Link} />
        )}
      />
      <TrackedRouterLink
        href={supportURL()}
        objectName="help"
        label="Get Help"
        component={Nav.RouterLink}
        onClick={onToggle}
      />
      <TrackedRouterLink
        href={referralsURL()}
        objectName="refer_a_friend"
        label="Refer a Friend"
        component={Nav.RouterLink}
        onClick={onToggle}
      />
      <TrackedAnchorLink href={logoutURL()} objectName="logout" label="Log Out" component={Nav.Link} />
      <FooterWrapper>
        <Nav.Item>
          <Copyright />
        </Nav.Item>
      </FooterWrapper>
    </>
  );
};

const DesktopNav: React.FC = () => {
  const { onChange: setVisible } = useContext(DropdownContext);

  const onClick = () => setVisible(false);

  const { data } = useCustomerNameQuery();

  return (
    <>
      <Dropdown.Toggle component={Nav.Link} href="#">
        {data?.customer?.name ?? (VISITOR ? VISITOR.name : 'Profile')}
        {IMPERSONATOR && <> ({IMPERSONATOR.name})</>}
      </Dropdown.Toggle>
      <Dropdown.Menu alignment="right">
        <TrackedRouterLink
          href={accountURL()}
          objectName="account_details"
          label="Account Details"
          component={Dropdown.RouterLink}
          onClick={onClick}
        />
        <TrackedRouterLink
          href={billingURL()}
          objectName="billing"
          label="Billing"
          component={Dropdown.RouterLink}
          onClick={onClick}
        />
        <TrackedRouterLink
          href={settingsURL()}
          objectName="settings"
          label="Settings"
          component={Dropdown.RouterLink}
          onClick={onClick}
        />
        <TrackedRouterLink
          href={claimsURL()}
          objectName="claims"
          label="Claims"
          component={Dropdown.RouterLink}
          onClick={onClick}
        />
        <TrackedRouterLink
          href={supportURL()}
          objectName="help"
          label="Get Help"
          component={Dropdown.RouterLink}
          onClick={onClick}
        />
        <Dropdown.Divider />
        <ScheduleAppointmentLink
          component={({ href, text, objectName, onClick: onClickComponent }) => (
            <TrackedAnchorLink
              objectName={objectName}
              href={href}
              label={text}
              component={Dropdown.Item}
              onClick={onClickComponent}
            />
          )}
        />
        <Dropdown.Divider />
        <TrackedRouterLink
          href={referralsURL()}
          objectName="refer_a_friend"
          label="Refer a friend"
          component={Dropdown.RouterLink}
          onClick={onClick}
        >
          <ReferralLabel>
            <GiftContainer src={gift} />
            <div>
              <Teal>Get up to $100</Teal> for
              <br />
              every friend you refer
            </div>
          </ReferralLabel>
        </TrackedRouterLink>
        <Dropdown.Divider />
        <TrackedAnchorLink href={logoutURL()} objectName="logout" label="Log Out" component={Dropdown.Item} />
        <Dropdown.Divider />
        <FooterWrapper>
          <Dropdown.Item>
            <Copyright />
          </Dropdown.Item>
        </FooterWrapper>
      </Dropdown.Menu>
    </>
  );
};

export const Header: React.FC = () => {
  const showImpersonatorBanner = IMPERSONATOR && VISITOR;
  const bufferHeight = showImpersonatorBanner ? HEADER_HEIGHT + 40 : HEADER_HEIGHT;
  return (
    <header>
      <Box height={bufferHeight} />
      <StyledNavbar expand={EXPAND} className="fixed-top">
        <div className="container">
          <Navbar.Brand href="/">
            <img src={LOGO} alt="Clutter" width="100" height="24" />
          </Navbar.Brand>

          <Navbar.Toggler />

          {VISITOR && (
            <Navbar.Collapse>
              <SharedNav />

              <Display breakpoints={[{ value: 'block' }, { size: EXPAND, value: 'none' }]} component={Navbar.Nav}>
                <MobileNav />
              </Display>

              <Display breakpoints={[{ value: 'none' }, { size: EXPAND, value: 'block' }]} component={Navbar.Nav}>
                <Dropdown component={Nav.Item}>
                  <DesktopNav />
                </Dropdown>
              </Display>
            </Navbar.Collapse>
          )}
        </div>
      </StyledNavbar>
      {showImpersonatorBanner && (
        <Box
          background={COLORS.tiger}
          color={COLORS.panther}
          textAlign="center"
          padding="8px"
          width="100%"
          position="absolute"
          top={`${HEADER_HEIGHT}px`}
          left={0}
          style={{ zIndex: 9 }}
        >
          <Text.Body weight={FontWeight.Medium}>
            ATTENTION: {IMPERSONATOR!.name} is making changes on behalf of {VISITOR!.name}.
            {BLOCK_ACTION && (
              <>
                {' '}
                See features unavailable to L1 agents.{' '}
                <Tooltip arrow text={<L1AgentLimitations />}>
                  <img src={infoTooltip} />
                </Tooltip>
              </>
            )}
          </Text.Body>
        </Box>
      )}
    </header>
  );
};
Header.displayName = 'AppHeader';
