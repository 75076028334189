import React from 'react';
import { Text } from '@clutter/clean';
import styled from '@emotion/styled';

import { FacilityFragment } from '@portal/schema';

const OperatingHours = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Hours = styled.div`
  margin-left: 8px;
  padding: 2px 0;
`;

export const Info: React.FC<{ facility: FacilityFragment; disclaimer?: string }> = ({ facility, disclaimer }) => (
  <>
    <Text.Body>
      <strong>Address: </strong>
      {facility.address.formatted}
    </Text.Body>
    <OperatingHours>
      <strong>Operating Hours:</strong>
      {facility.accessHours.map((hours, index) => (
        <Hours key={index}>
          <Text.Body>{hours}</Text.Body>
        </Hours>
      ))}
      {disclaimer && <Text.Body>{disclaimer}</Text.Body>}
    </OperatingHours>
  </>
);
