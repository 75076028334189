import * as React from 'react';
import { Link } from 'react-router-dom';

import { IAccount, IOrder } from '@portal/types';

import { Header } from '@portal/components/helpers/header';
import { orderURL } from '@portal/config/routes';

import { Phone } from '@shared/components/helpers/phone';

export const Contact: React.FC<{
  account: IAccount;
  order: IOrder;
}> = ({ account, order }) => (
  <>
    <Header tag="h4">Contact Information</Header>
    <p>
      {order.contact ? (
        <>
          {order.contact.name} - <Phone value={order.contact.phone} />
        </>
      ) : (
        <>
          {account.customer.name} - <Phone value={account.customer.phone} />
        </>
      )}
    </p>
    {order.permissions.active && (
      <Link className="btn btn-primary" to={orderURL(order.id, 'contact')}>
        Update Contact
      </Link>
    )}
    <hr />
  </>
);
