import * as React from 'react';
import { useContext } from 'react';

import { Text } from '@shared/components/bootstrap';
import { Button } from '@shared/components/inventory_capture/button';
import { InventoryContext } from '@shared/components/inventory_capture/inventory_context';
import { Spacer } from '../helpers';

export const PackingHelp: React.FC = () => {
  const { data, onSelect } = useContext(InventoryContext);

  return (
    <>
      <Text tag="p" style="info">
        <strong>Do you need help packing boxes?</strong>
      </Text>

      <Button
        active={data.needsPackingHelp === true}
        onClick={() => onSelect({ ...data, needsPackingHelp: true, packingCategoriesComplete: false })}
        text="Yes"
      />
      <Spacer width="1rem" inline />
      <Button
        active={data.needsPackingHelp === false}
        onClick={() => onSelect({ ...data, needsPackingHelp: false, packingCategoriesComplete: false })}
        text="No"
      />
    </>
  );
};
