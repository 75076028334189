import React from 'react';
import styled from '@emotion/styled';
import { COLORS, Text, FontWeight } from '@clutter/clean';
import { Spinner } from '@portal/components/helpers/spinner';
import { ShipmentValuationCoverage } from '@portal/schema';
import { formattedDeductible } from './helpers';

const ShadedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${COLORS.grayBackground};
  margin-top: 24px;
  margin-bottom: 14px;
  padding: 24px;
  border-radius: 4px;
`;

const Callout = styled(Text.Callout)`
  font-weight: ${FontWeight.Medium};
  color: ${COLORS.panther};
`;

const Body = styled(Text.Body)`
  color: ${COLORS.panther};
  margin-top: 20px;
`;

const Caption = styled(Text.Caption)`
  color: ${COLORS.storm};
  margin-top: 4px;
`;

type coverageType = Pick<ShipmentValuationCoverage, 'id' | 'name' | 'deductible' | 'price' | 'key'>;

export const ChargeTotal: React.FC<{
  selectedCoverage?: coverageType;
  loading: boolean;
  chargeTotal?: number;
}> = ({ selectedCoverage, loading, chargeTotal }) => {
  if (loading) {
    return <Spinner />;
  }

  if (!selectedCoverage) {
    return <Body>Select a coverage plan to see your total cost.</Body>;
  }

  return (
    <>
      <ShadedContainer>
        <Callout>
          {selectedCoverage.name}
          <br />
          <Caption>{formattedDeductible(selectedCoverage.deductible)}</Caption>
        </Callout>
        <Callout>${chargeTotal}</Callout>
      </ShadedContainer>
      {chargeTotal !== undefined && chargeTotal > 0 && (
        <Caption>Your card will be charged at the end of today's appointment.</Caption>
      )}
    </>
  );
};
