import * as React from 'react';

const HeroSection = ({ children }: { children?: React.ReactNode }) => <div className="hero__section">{children}</div>;

const HeroTitle = ({ children }: { children?: React.ReactNode }) => <div className="hero__title">{children}</div>;

const HeroSubtitle = ({ children }: { children?: React.ReactNode }) => <div className="hero__subtitle">{children}</div>;

export const Hero = ({ children }: { children?: React.ReactNode }) => <div className="hero">{children}</div>;

Hero.Section = HeroSection;
Hero.Title = HeroTitle;
Hero.Subtitle = HeroSubtitle;
