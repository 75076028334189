import { Box, InfoModal, ReviewPrompt } from '@clutter/clean';
import React, { useState } from 'react';

import { Rating } from '@portal/schema';

import { ReviewFollowup } from './review_followup';

export type FollowupAnswers = { [tag: string]: boolean };

const REVIEW_TAGS = ['Difficult to understand', 'Not what I was looking for', 'I still have questions'];

export const Review: React.FC<{
  reasonID: string;
  onContactUs(): void;
  onReview(rating: Rating, answers?: FollowupAnswers): void;
}> = ({ reasonID, onContactUs, onReview }) => {
  const [showFollowupModal, setShowFollowupModal] = useState<boolean>(false);

  const onRate = (rating: Rating): void => {
    if (rating === 'POOR') {
      setShowFollowupModal(true);
    }

    if (rating === 'GOOD') {
      onReview(rating);
    }
  };

  const onSubmitFollowUp = (answers: FollowupAnswers): void => {
    setShowFollowupModal(false);
    onReview(Rating.Poor, answers);
  };

  return (
    <>
      <Box margin="0 0 24px">
        <ReviewPrompt question="Did this answer your question?" onRate={onRate} />
      </Box>
      <InfoModal handleModalClose={() => setShowFollowupModal(false)} isOpen={showFollowupModal}>
        <ReviewFollowup tags={REVIEW_TAGS} onContactUs={onContactUs} onSubmit={onSubmitFollowUp} reasonID={reasonID} />
      </InfoModal>
    </>
  );
};
