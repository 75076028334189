/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import * as React from 'react';

type HeaderTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';

const font = css`
  font-family: 'Recoleta', 'Georgia', serif;
`;

export const Header: React.FC<{ tag?: HeaderTag }> = ({ tag: Tag = 'h1', children }) => (
  <Tag css={font}>{children}</Tag>
);
