import React from 'react';

export const PlanSize: React.FC = () => (
  <svg width="78.4" height="60.8" viewBox="0 0 98 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M0.990234 18.5195L36.9902 30.5195L72.9902 18.5195L36.9902 6.51953L0.990234 18.5195Z"
        fill="#A1E6E0"
        stroke="#A1E6E0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.9902 58.5195V18.5195L36.9902 30.5195V70.5195L72.9902 58.5195Z"
        fill="#A1E6E0"
        stroke="#A1E6E0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.990234 18.5195V58.5195L36.9902 70.5195V30.5195L0.990234 18.5195Z"
        fill="#A1E6E0"
        stroke="#A1E6E0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.990234 58.5195L24.9902 66.5195L48.9902 58.5195L24.9902 50.5195L0.990234 58.5195Z"
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9902 66.5195L48.9902 74.5195L72.9902 66.5195L48.9902 58.5195L24.9902 66.5195Z"
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9902 50.5195L48.9902 58.5195L72.9902 50.5195L48.9902 42.5195L24.9902 50.5195Z"
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.9902 58.5195L72.9902 66.5195L96.9902 58.5195L72.9902 50.5195L48.9902 58.5195Z"
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.990234 32.1201L24.9902 40.1201L48.9902 32.1201L24.9902 24.1201L0.990234 32.1201Z"
        fill="#00A499"
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9902 40.1201V66.5201L48.9902 58.5201V32.1201L24.9902 40.1201Z"
        fill="#00A499"
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.990234 32.1201V58.5201L24.9902 66.5201V40.1201L0.990234 32.1201Z"
        fill="#00A499"
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.990234 32.1201L24.9902 40.1201L48.9902 32.1201"
        stroke="#01252D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M24.9902 66.5201V40.1201" stroke="#01252D" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M0.990234 58.5195L24.9902 66.5195L48.9902 58.5195"
        stroke="#01252D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.990234 18.5195L36.9902 30.5195L72.9902 18.5195"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M36.9902 30.5195V70.5195" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M79.0303 10.5L87.0303 0.5L95.0303 10.5H90.0303L90.0603 30.52H95.0603L87.0603 40.52L79.0603 30.52H84.0603L84.0303 10.5H79.0303Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M36.9902 6.51953V28.1195" stroke="#00A499" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="97" height="75.02" fill="white" transform="translate(0.490234)" />
      </clipPath>
    </defs>
  </svg>
);
