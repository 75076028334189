import * as React from 'react';

import { Button as BootstrapButton } from '@shared/components/bootstrap';

export const Button: React.FC<{
  active?: boolean;
  text: string;
  onClick(): void;
}> = ({ active = true, text, onClick }) => (
  <BootstrapButton outline kind="secondary" active={active} onClick={onClick}>
    {text}
  </BootstrapButton>
);
