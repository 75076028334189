import React, { useState } from 'react';

import { Spinner } from '@portal/components/helpers';
import { client } from '@portal/libraries/apollo';
import { Marker } from '@portal/images/estimation/marker';
import styled from '@emotion/styled';
import { Box, COLORS, FontWeight, Input, Label, mq, Select, Text } from '@clutter/clean';
import { Estimation__AlgorithmName, OrderTypeEnum, Status, useAddressDetailsUpdateMutation } from '@portal/schema';
import { Spacer } from '@shared/components/helpers';
import {
  AccessKind,
  AddressDetailField,
  ADDRESS_BUILDING_TO_FIELDS_MAP,
  ADDRESS_FIELD_MAP,
  BuildingTypeEnum,
  BaseStepType,
} from './data';
import { StepContainer } from './step_container';
import { isAddressCompleted } from './utils';
import { GettingStartedModal } from './virtual_walkthrough/getting_started_modal';

const AddressContainer = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;
`;

const StyledMarker = styled(Marker)`
  margin-right: 24px;
`;

const Card = styled.div`
  background: ${COLORS.cloud};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: min-content;
  flex-grow: 1;
  max-width: 480px;

  ${mq({
    margin: ['0 0 24px', null, '0 auto'],
  })}
`;

const FormContainer = styled.div`
  padding: 24px;
  background-color: ${COLORS.grayBackground};
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const Error = styled(Text.Caption)`
  color: ${COLORS.toucan};
  margin-top: -8px;
  margin-bottom: 8px;
`;

const BUILDING_OPTIONS = [
  { label: 'House', value: BuildingTypeEnum.House },
  { label: 'Apartment/Condo', value: BuildingTypeEnum.Apartment },
  { label: 'Studio Apartment', value: BuildingTypeEnum.Studio },
  { label: 'Townhouse', value: BuildingTypeEnum.Townhouse },
  { label: 'Storage Unit', value: BuildingTypeEnum.StorageFacilityOrWarehouse },
  { label: 'Commercial', value: BuildingTypeEnum.Commercial },
  { label: 'Other', value: BuildingTypeEnum.Other },
];

export const AddressDetails: React.FC<BaseStepType> = (props) => {
  const {
    values: { address, destinationAddress, selectedItemCategories, scheduled },
    algorithmName,
    orderID,
    orderType,
    onChange,
    next,
  } = props;
  const [errors, setErrors] = useState<any>({});
  const [showGettingStartedModal, setShowGettingStartedModal] = useState<boolean>(
    algorithmName === Estimation__AlgorithmName.VirtualWalkthrough && selectedItemCategories?.length === 0,
  );

  const [updateAddress, { loading, error }] = useAddressDetailsUpdateMutation({ client });

  const setError = (field: AddressDetailField, msg?: string) => {
    setErrors({
      ...errors,
      [field]: msg,
    });
  };

  if (!address) {
    return <Spinner />;
  }

  const { street, city, state, zip, buildingType } = address;
  const {
    street: destinationStreet,
    city: destinationCity,
    state: destinationState,
    zip: destinationZip,
    buildingType: destinationBuildingType,
  } = destinationAddress ?? {};

  const resetAddressDetailFields = () => {
    if (address) {
      const addressDetailFields = Object.keys(ADDRESS_FIELD_MAP) as AddressDetailField[];
      addressDetailFields.forEach((field) => delete address[field]);
    }
  };

  const resetDestinationAddressDetailFields = () => {
    if (destinationAddress) {
      const addressDetailFields = Object.keys(ADDRESS_FIELD_MAP) as AddressDetailField[];
      addressDetailFields.forEach((field) => delete destinationAddress[field]);
    }
  };

  const updateAddressFieldByType = (field: string, value?: string | number) => {
    onChange('address', { ...address, [field]: value });
  };

  const updateDestinationAddressFieldByType = (field: string, value?: string | number) => {
    onChange('destinationAddress', { ...destinationAddress, [field]: value });
  };

  const handleInputChange = (
    kind: 'address' | 'destinationAddress',
    inputValue: any,
    input: string,
    field: AddressDetailField,
  ) => {
    if (input === 'tel' && isNaN(Number(inputValue))) {
      setError(field, 'This field requires a number input');
      return;
    }
    if (inputValue === '0' && field === 'squareFootage') {
      setError(field, 'This field cannot be zero');
      return;
    }
    setError(field, undefined);

    if (inputValue === undefined || inputValue === '') {
      if (kind === 'address') {
        updateAddressFieldByType(field, undefined);
      } else {
        updateDestinationAddressFieldByType(field, undefined);
      }
    } else {
      const value = input === 'tel' ? Number(inputValue) : inputValue;
      if (kind === 'address') {
        updateAddressFieldByType(field, value);
      } else {
        updateDestinationAddressFieldByType(field, value);
      }
    }
  };

  const onNext = async () => {
    const destinationAddressInput =
      orderType === OrderTypeEnum.Move && destinationAddress
        ? {
            id: destinationAddress.id,
            street: destinationAddress.street,
            aptsuite: destinationAddress.aptsuite,
            city: destinationAddress.city,
            state: destinationAddress.state,
            zip: destinationAddress.zip!,
            details: {
              buildingType: destinationAddress.buildingType,
              floor: destinationAddress.floor,
              stories: destinationAddress.stories,
              longCarry: destinationAddress.longCarry,
              squareFeet: destinationAddress.squareFootage,
              stairs: destinationAddress.access === AccessKind.STAIRS,
              elevator: destinationAddress.access === AccessKind.ELEVATOR,
              serviceElevator: destinationAddress.access === AccessKind.SERVICE_ELEVATOR,
              unitSize: destinationAddress.unitSize,
            },
          }
        : undefined;

    const { data } = await updateAddress({
      variables: {
        input: {
          orderID: orderID,
          addressInput: {
            id: address.id!,
            street: address.street,
            aptsuite: address.aptsuite,
            city: address.city,
            state: address.state,
            zip: address.zip!,
            details: {
              buildingType: address.buildingType,
              floor: address.floor,
              stories: address.stories,
              longCarry: address.longCarry,
              squareFeet: address.squareFootage,
              stairs: address.access === AccessKind.STAIRS,
              elevator: address.access === AccessKind.ELEVATOR,
              serviceElevator: address.access === AccessKind.SERVICE_ELEVATOR,
              unitSize: address.unitSize,
            },
          },
          destinationAddressInput,
        },
      },
    });

    const status = data?.addressDetailsUpdate?.status;
    if (status === Status.Ok) {
      next();
    } else {
      props.setError(data?.addressDetailsUpdate?.error ?? 'Failed to save address details');
    }
  };

  const canNext = () => {
    if (orderType === OrderTypeEnum.Move) {
      return isAddressCompleted(address) && isAddressCompleted(destinationAddress) && !loading && !error;
    }
    return isAddressCompleted(address) && !loading && !error;
  };

  return (
    <StepContainer
      {...props}
      headline="Add your address details"
      subheading="Tell us more about your address so we can provide you with the most accurate quote."
      canNext={canNext()}
      next={() => onNext()}
      canPrev={algorithmName !== Estimation__AlgorithmName.VirtualWalkthrough}
      neverFullWidthNext={true}
    >
      <Box.Flex flexDirection={['column', null, 'row']}>
        <Card>
          <AddressContainer>
            <StyledMarker />
            <div>
              <Text.Body weight={FontWeight.Medium}>
                {street}
                <br />
                {city}, {state} {zip}
              </Text.Body>
            </div>
          </AddressContainer>

          <FormContainer>
            <Label>Property Type</Label>
            <Spacer height="12px" />
            <Select
              options={BUILDING_OPTIONS}
              onChange={(value: any) => {
                resetAddressDetailFields();
                updateAddressFieldByType('buildingType', value);
              }}
              value={buildingType}
              placeholder="-"
            />
            {buildingType &&
              ADDRESS_BUILDING_TO_FIELDS_MAP[buildingType].map((field, i) => {
                const { label, input, options } = ADDRESS_FIELD_MAP[field];
                const fieldError = errors[field];
                return (
                  <React.Fragment key={i}>
                    <Spacer height="16px" />
                    <Label>{label}</Label>
                    <Spacer height="12px" />
                    {input === 'select' ? (
                      <Select
                        options={options}
                        onChange={(value: any) => {
                          updateAddressFieldByType(field as string, value);
                        }}
                        value={address[field] as string}
                        placeholder="-"
                      />
                    ) : (
                      <StyledInput
                        type={input}
                        onChange={(e) => handleInputChange('address', e.currentTarget.value, input, field)}
                        value={(address[field] as string) || ''}
                        state={fieldError && 'error'}
                      />
                    )}
                    {fieldError && <Error>{fieldError}</Error>}
                  </React.Fragment>
                );
              })}
          </FormContainer>
        </Card>
        {orderType === OrderTypeEnum.Move && destinationAddress && (
          <Card>
            <AddressContainer>
              <StyledMarker />
              <div>
                <Text.Body weight={FontWeight.Medium}>
                  {destinationStreet}
                  <br />
                  {destinationCity}, {destinationState} {destinationZip}
                </Text.Body>
              </div>
            </AddressContainer>

            <FormContainer>
              <Label>Property Type</Label>
              <Spacer height="12px" />
              <Select
                options={BUILDING_OPTIONS}
                onChange={(value: any) => {
                  resetDestinationAddressDetailFields();
                  updateDestinationAddressFieldByType('buildingType', value);
                }}
                value={destinationBuildingType}
                placeholder="-"
              />
              {destinationBuildingType &&
                ADDRESS_BUILDING_TO_FIELDS_MAP[destinationBuildingType].map((field, i) => {
                  const { label, input, options } = ADDRESS_FIELD_MAP[field];
                  const fieldError = errors[field];
                  return (
                    <React.Fragment key={i}>
                      <Spacer height="16px" />
                      <Label>{label}</Label>
                      <Spacer height="12px" />
                      {input === 'select' ? (
                        <Select
                          options={options}
                          onChange={(value: any) => {
                            updateDestinationAddressFieldByType(field as string, value);
                          }}
                          value={destinationAddress[field] as string}
                          placeholder="-"
                        />
                      ) : (
                        <StyledInput
                          type={input}
                          onChange={(e) => handleInputChange('destinationAddress', e.currentTarget.value, input, field)}
                          value={(destinationAddress[field] as string) || ''}
                          state={fieldError && 'error'}
                        />
                      )}
                      {fieldError && <Error>{fieldError}</Error>}
                    </React.Fragment>
                  );
                })}
            </FormContainer>
          </Card>
        )}
      </Box.Flex>
      {showGettingStartedModal && (
        <GettingStartedModal
          flatRate={
            /* This component is only used in the legacy V1 estimation flow. Flat-rate moves will only use virtual walkthroughs */
            false
          }
          orderID={orderID}
          orderScheduled={scheduled}
          hideModal={() => setShowGettingStartedModal(false)}
        />
      )}
    </StepContainer>
  );
};
