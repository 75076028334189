import { Box, Button, Checkbox, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { TrackedClick } from '@portal/components/wt/tracked_click';
import { IEventParams } from '@portal/types/wt/event';

import { FollowupAnswers } from './review';

const TopButton = styled(Button)`
  margin-bottom: 8px;
`;

const SHARE_CTA_COPY = 'Share Feedback';
const SHARE_AND_CONTACT_CTA_COPY = 'Share & Contact Support';

const SHARE_BUTTON_WT_PARAMS: IEventParams = {
  action: 'click',
  container: 'negative_review',
  pageName: 'portal:help_content',
  objectType: 'button',
};

export const ReviewFollowup: React.FC<{
  tags: string[];
  reasonID: string;
  onContactUs(): void;
  onSubmit(answers: FollowupAnswers): void;
}> = ({ tags, reasonID, onContactUs, onSubmit }) => {
  const [selectedTags, setSelectedTags] = React.useState<FollowupAnswers>(
    tags.reduce((initialAnswers, tag) => ({ ...initialAnswers, [tag]: false }), {}),
  );

  const onShareAndContact = () => {
    onSubmit(selectedTags);
    onContactUs();
  };

  return (
    <Box textAlign="center">
      <Text.Title size="medium">What could improve?</Text.Title>
      <Text.Body>Select all that apply.</Text.Body>
      <Box margin="24px 0 40px">
        <Checkbox.Selector
          onChange={(_, value) => setSelectedTags(value)}
          options={tags.map((tag) => ({ key: tag, label: tag }))}
          name="account_resolution_review_options"
          values={selectedTags}
        />
      </Box>
      <TrackedClick
        params={{
          ...SHARE_BUTTON_WT_PARAMS,
          label: SHARE_CTA_COPY,
          objectName: 'share_only',
          position: 1,
          selected_reason_id: reasonID,
        }}
      >
        <TopButton type="submit" size="large" kind="primary" fullWidth onClick={() => onSubmit(selectedTags)}>
          {SHARE_CTA_COPY}
        </TopButton>
      </TrackedClick>
      <TrackedClick
        params={{
          ...SHARE_BUTTON_WT_PARAMS,
          label: SHARE_AND_CONTACT_CTA_COPY,
          objectName: 'share_and_contact',
          position: 2,
          selected_reason_id: reasonID,
        }}
      >
        <Button size="large" kind="secondary" fullWidth onClick={onShareAndContact}>
          {SHARE_AND_CONTACT_CTA_COPY}
        </Button>
      </TrackedClick>
    </Box>
  );
};
