type StateFromActions<ActionMap extends Record<string, (...args: any[]) => any>> = Parameters<
  ActionMap[keyof ActionMap]
>[0];

export type ActionFromActions<ActionMap extends Record<string, (...args: any[]) => any>> = {
  [Type in keyof ActionMap]: { type: Type; payload: Parameters<ActionMap[Type]>[1] };
}[keyof ActionMap];

/**
 * Utitlies for creating a reducer based on a map of actions. Example:
 *
 * ```ts
 * type State = {
 *  name: string;
 *  itemIDs: string[];
 * };
 *
 * const actions = {
 *   changeName(state: State, payload: { name: string }) {
 *     return { ...state, name: payload.name };
 *   },
 *   addItem(state: State, payload: { id: string }) {
 *     return {
 *       ...state,
 *       itemIDs: [...state.itemIDs, payload.id],
 *     };
 *   },
 * };
 *
 * type Action = ActionFromActions<typeof actions>
 * const reducer = createTypedReducer(actions);
 * ```
 *
 * Will yield a reducer that accepts an action of type:
 *
 * ```ts
 * type Action = {
 *   type: "changeName";
 *   payload: { name: string; };
 * } | {
 *   type: "addItem";
 *   payload: { id: string; };
 * }
 * ```
 */
export function createTypedReducer<ActionMap extends Record<string, (...args: any[]) => any>>(actions: ActionMap) {
  return function (
    state: StateFromActions<ActionMap>,
    action: ActionFromActions<ActionMap>,
  ): StateFromActions<ActionMap> {
    const newState = actions[action.type](state, action.payload as any);
    return newState as StateFromActions<ActionMap>;
  };
}
