import React from 'react';

import styled from '@emotion/styled';
import { Text } from '@clutter/clean';

import { Maybe, OrderTypeEnum } from '@portal/schema';

import { AppointmentDetailsOrder } from './appointment_details/full_panel';

const Container = styled.div`
  margin-bottom: 40px;
`;

const Heading = styled(Text.Title)`
  margin-bottom: 12px;
`;

const Subheading = styled(Text.Body)`
  margin-bottom: 12px;
`;

const BodyText = styled(Text.Body)`
  margin-bottom: 12px;
`;

const FollowUpText: React.FC<{ cubicFootage: number; plan: string }> = ({ cubicFootage, plan }) => {
  if (cubicFootage < 1200) {
    return (
      <BodyText>
        You have a <b>{plan}</b> plan size which means you have a lot of items to store! It will be especially important
        that you provide a complete list of your inventory so that we can properly plan and avoid the need for a
        follow-up appointment.
      </BodyText>
    );
  } else {
    return (
      <BodyText>
        You have a <b>{plan}</b> plan size which means you have a lot of items to store! Plan sizes this big usually
        require two appointments to complete. We will review your inventory information and an agent will reach out if a
        follow-up appointment might be needed.
      </BodyText>
    );
  }
};

export const Title: React.FC<{
  order: AppointmentDetailsOrder;
  cubicFootage?: Maybe<number>;
  plan?: Maybe<string>;
}> = ({ order, cubicFootage, plan }) => (
  <>
    <Heading size="large">Hi {order.account.customer.firstName},</Heading>
    <Container>
      <Subheading>
        Welcome to your Clutter account. Everything about your appointment and how to prepare can be found here.
      </Subheading>
      {order.type !== OrderTypeEnum.Move && cubicFootage && cubicFootage >= 600 && (
        <div>
          <FollowUpText cubicFootage={cubicFootage} plan={plan!} />
          <BodyText>
            Follow up appointments will result in a 50% increase of the Pickup fee shown under your move-in details
            below.
          </BodyText>
        </div>
      )}
    </Container>
  </>
);
