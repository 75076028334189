// NOTE: due to mounting flashes outside a router (for use with ERB and React) - events are used for communication.

import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { FLASHES } from '@portal/config/flashes';

import { IFlash } from '@shared/types';

import { Flash } from '@shared/components/helpers';

export const EVENTS = {
  RESET: 'flashes:reset',
};

type DialogEvent = CustomEvent<{
  flashes: IFlash[];
}>;

export const Flashes: React.FC = () => {
  const [flashes, setFlashes] = useState<IFlash[]>(FLASHES);

  useEffect(() => {
    const listener = (event: DialogEvent) => setFlashes(event.detail.flashes);
    document.addEventListener(EVENTS.RESET, listener as EventListener);
    return () => document.removeEventListener(EVENTS.RESET, listener as EventListener);
  }, []);

  return (
    <>
      {flashes.map((flash, key) => (
        <Flash flash={flash} key={key} />
      ))}
    </>
  );
};
Flashes.displayName = 'AppFlashes';
