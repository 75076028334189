import React from 'react';
import styled from '@emotion/styled';
import { ActionCard, Box, Text, Icon } from '@clutter/clean';
import { TrackedSelect } from '@portal/components/wt/tracked_select';

const Card = styled(ActionCard)`
  margin-bottom: 24px;
`;

const TaskInfo = styled(Box.Flex)`
  padding: 2px 10px 10px;
`;

const WT_PARAMS = {
  pageName: 'portal:documents_hub',
  container: 'task_list',
  action: 'click',
  objectType: 'card',
  objectName: 'sign_documents',
};

export const Task: React.FC<{
  completed: boolean;
  onSelect(): void;
  disabled: boolean;
  titleText: string;
  descriptionText: string;
  paramsLabel?: string;
  paramsValue?: string;
}> = ({ completed, onSelect, disabled, titleText, descriptionText, paramsLabel, paramsValue }) => (
  <TrackedSelect
    params={{
      ...WT_PARAMS,
      label: paramsLabel,
      value: paramsValue,
    }}
  >
    <Card
      selected={completed}
      onSelect={onSelect}
      icon={completed ? Icon.Check : Icon.Arrow}
      disabled={disabled}
      iconPosition="center"
    >
      <TaskInfo justifyContent="space-between" flexDirection="column">
        <div>
          <Text.Title size="extraSmall">{titleText}</Text.Title>
          <Text.Callout>{descriptionText}</Text.Callout>
        </div>
      </TaskInfo>
    </Card>
  </TrackedSelect>
);
