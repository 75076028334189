import React from 'react';

import { Step } from '@portal/components/claims/step';
import { Draft } from '@portal/components/helpers/draft';
import { Alert } from '@shared/components/bootstrap';
import { ClaimConsumer, ClaimIssueSelectionConsumer } from '@portal/contexts';
import { ClaimIssueSelectionFragment, Status, useClaimIssueSelectionUpdateMutation } from '@portal/schema';

interface IFormRenderProps {
  loading: boolean;
  selection: ClaimIssueSelectionFragment;
  onChange(selection: ClaimIssueSelectionFragment): void;
}

type Field = 'compensationRequested' | 'description' | 'declaration' | 'kind';

interface IFormProps {
  field: Field;
  children(props: IFormRenderProps): React.ReactNode;
}

export const IssueSelectionForm = ({ field, children }: IFormProps) => {
  const [mutate, { loading, data }] = useClaimIssueSelectionUpdateMutation();
  const compensationRequestedField = field === 'compensationRequested';
  return (
    <ClaimConsumer>
      {({ claim: { uuid } }) => (
        <ClaimIssueSelectionConsumer>
          {({ selection, onChange: onSave }) => (
            <Draft draft={selection}>
              {({ draft, onChange }) => (
                <Step
                  saving={loading}
                  valid={
                    (!compensationRequestedField && !!draft[field]) ||
                    (compensationRequestedField &&
                      ((!!draft.compensationRequested && !!draft.declaration) ||
                        (!draft.compensationRequested && !draft.declaration)))
                  }
                  onSave={async () => {
                    const input = { [field]: draft[field] };
                    if (field === 'compensationRequested') {
                      input.declaration = draft.compensationRequested ? draft.declaration : 0.0;
                    }
                    const result = await mutate({
                      variables: {
                        uuid,
                        input,
                      },
                    });
                    if (!result.data?.claimIssueSelectionUpdate) {
                      return Status.Unprocessable;
                    }
                    const { status } = result.data.claimIssueSelectionUpdate;
                    if (status === Status.Ok) {
                      onSave(draft);
                    }
                    return status;
                  }}
                >
                  {data && data.claimIssueSelectionUpdate?.error && (
                    <Alert style="danger">{data.claimIssueSelectionUpdate.error}</Alert>
                  )}
                  {children({ loading, selection: draft, onChange })}
                </Step>
              )}
            </Draft>
          )}
        </ClaimIssueSelectionConsumer>
      )}
    </ClaimConsumer>
  );
};
