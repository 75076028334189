import * as React from 'react';
import { useContext } from 'react';

import { IEstimationCategory, IEstimationSelection } from '@portal/types';
import { CategoriesContext } from '@shared/components/inventory_capture/categories_context';

const DEFAULT_ESTIMATION_SELECTION = { selected: false, quantity: 0 };

export const EstimationCategoriesSummaryTable: React.FC<{
  estimations: Map<IEstimationCategory, Partial<IEstimationSelection>> | undefined;
  packable: boolean;
}> = ({ estimations, packable }) => {
  const config = useContext(CategoriesContext);

  if (!config || !config.estimationCategories) {
    return null;
  }

  const filteredEstimationCategories = config.estimationCategories.filter((category) => category.packable === packable);

  return (
    <div>
      {filteredEstimationCategories.map((category) => {
        const { selected, quantity } = (estimations && estimations.get(category)) || DEFAULT_ESTIMATION_SELECTION;

        return (
          <div
            key={category.name}
            className="row border-top"
            style={{ margin: 'inherit', paddingTop: '12px', paddingBottom: '12px' }}
          >
            <div className="col-9 col-xs-9" style={{ padding: '0px' }}>
              <span className="text-capitalize">{category.name}</span>
            </div>
            <div className="col-3 col-xs-3 text-right" style={{ padding: '0px' }}>
              <strong>{packable ? (selected ? 'Yes' : 'No') : quantity}</strong>
            </div>
          </div>
        );
      })}
    </div>
  );
};
