import React, { useState } from 'react';

import { Button, COLORS, Text } from '@clutter/clean';

import { IAddressValidationErrors } from '@portal/types';

import { disabled } from '@portal/components/addresses/utils';
import { AddressWithDetailsFragment } from '@portal/schema';
import { PinnedFooterContainer } from '@shared/components/helpers/pinned_footer';
import styled from '@emotion/styled';
import { AppointmentAddressDetails } from '@portal/components/addresses/appointment_address_details';

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
`;

const StyledForm = styled.form`
  padding-bottom: 80px;
`;

export const OrderAddressForm: React.FC<{
  loading: boolean;
  children?: React.ReactNode;
  selection?: AddressWithDetailsFragment;
  onChange(value: AddressWithDetailsFragment): void;
  onSubmit(): void;
}> = ({ loading, children, onChange, selection, onSubmit }) => {
  const [errors, setErrors] = useState<IAddressValidationErrors | undefined>(undefined);

  React.useEffect(() => {
    if (selection && !disabled(selection)) {
      setErrors(undefined);
    }
  }, [selection]);

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!selection || loading) {
      // eslint-disable-next-line no-useless-return
      return;
    } else {
      onSubmit();
    }
  };

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <Text.Title size="medium">Add address details</Text.Title>
      <Text.Body>The more details you provide, the better our team will come prepared.</Text.Body>
      {children && children}
      {selection && <AppointmentAddressDetails setAddress={onChange} address={selection} errors={errors} />}
      <PinnedFooterContainer show={false}>
        <Footer>
          {errors && <Text.Body color={COLORS.toucan}>Complete all required fields to save changes</Text.Body>}
          <Button kind="primary" type="submit" loading={loading} disabled={loading}>
            Save Changes
          </Button>
        </Footer>
      </PinnedFooterContainer>
    </StyledForm>
  );
};
