import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import styled from '@emotion/styled';

import { Spinner } from '@portal/components/helpers';
import {
  OrderTypeEnum,
  useAppointmentHubQuery,
  useEstimationFlowCompletionQuery,
  OrderStatusEnum,
  Estimation__ResourceType,
  Estimation__AlgorithmName,
  useOnboardingAccountDetailQuery,
} from '@portal/schema';
import { Widget as FinancingWidget } from '@portal/components/orders/financing/widget';
import { Spacer } from '@shared/components/helpers';
import { Banner as RescheduleOfferBanner } from '@portal/components/reschedule_offer/banner';
import { rescheduleOfferTokenURL } from '@portal/config/routes';
import { StoragePlan } from './storage_plan';
import { AppointmentDetails } from './appointment_details/full_panel';
import { Title } from './title';
import { TaskList } from './task_list';
import { Alert } from './alert';
import { ReferralsCard } from './referrals_card';
import { FinePrint } from './fine_print';
import { FAQCard } from './faq';
import { Documents } from '../documents';

const Container = styled.div`
  margin: 9px;
`;

const RescheduleOfferContainer = styled.div`
  margin-bottom: 24px;
  a,
  a:hover {
    color: inherit !important;
    text-decoration: none !important;
  }
`;

export const Home: React.FC<RouteComponentProps<{ orderID: string }>> = ({
  match: {
    params: { orderID },
  },
}) => {
  const { data, loading, refetch } = useAppointmentHubQuery({ variables: { orderID } });
  const { data: accountData, loading: accountLoading } = useOnboardingAccountDetailQuery();
  const account = accountData?.account;
  const order = data?.order;
  const isMovingOrder = order?.type === OrderTypeEnum.Move;
  const isRetailDeliveryOrder = order?.type === OrderTypeEnum.RetailDelivery;
  const isStoreAndMoveOrder = isMovingOrder && order.simultaneousPickup;
  const showStoragePlan = isStoreAndMoveOrder || (!isMovingOrder && !isRetailDeliveryOrder);

  const algorithmName: Estimation__AlgorithmName | undefined = data?.order?.estimationAlgorithm?.name;
  const inEstimationFlowTest = !!algorithmName;

  const { data: estimationData, loading: estimationLoading } = useEstimationFlowCompletionQuery({
    variables: {
      resourceID: orderID,
      resourceType: Estimation__ResourceType.Order,
      algorithmName: algorithmName!,
    },
    skip: !algorithmName,
    fetchPolicy: 'network-only',
  });

  if (loading || !data || accountLoading || estimationLoading || !order || !account) {
    return <Spinner />;
  }

  const estimationFlowCompleted = !!estimationData?.estimationStatus?.complete;

  const rescheduleOfferToken = account.rescheduleOfferDetails?.token;
  const rescheduleOfferCoupon = account.rescheduleOfferDetails?.coupon;

  return (
    <Container>
      <Alert order={order} estimationFlowCompleted={estimationFlowCompleted} />
      {!isRetailDeliveryOrder && (
        <Title
          order={order}
          cubicFootage={data.currentStorageSubscription && data.currentStorageSubscription.cubicFootage}
          plan={data.currentStorageSubscription && data.currentStorageSubscription.formattedName!}
        />
      )}
      {![OrderStatusEnum.Completed, OrderStatusEnum.Canceled].includes(order.status) && !isRetailDeliveryOrder && (
        <TaskList
          order={order}
          inEstimationFlowTest={inEstimationFlowTest}
          estimationFlowCompleted={estimationFlowCompleted}
          fitsPlanSize={!!estimationData?.estimationStatus?.fitsPlanSize}
        />
      )}
      {rescheduleOfferToken && rescheduleOfferCoupon && (
        <RescheduleOfferContainer>
          <Link to={rescheduleOfferTokenURL(rescheduleOfferToken)}>
            <RescheduleOfferBanner coupon={rescheduleOfferCoupon} includeCTA />
          </Link>
        </RescheduleOfferContainer>
      )}
      <FinancingWidget order={order} />
      <AppointmentDetails
        upcomingSubscriptionSet={account.upcomingSubscriptionSet}
        order={order}
        refetchData={refetch}
      />
      <Spacer height="40px" />
      {showStoragePlan && (
        <StoragePlan
          upcomingSubscriptionSet={account.upcomingSubscriptionSet}
          term={account.termCommitment?.term}
          orderID={order.id}
        />
      )}
      <Documents orderID={orderID} />
      {isMovingOrder && (
        <>
          <FinePrint
            orderId={order.id}
            originAddressState={order.address.state}
            brochureLink={order.consumerBrochure?.url}
            formattedDepositInterval={order.formattedDepositInterval}
            depositPercent={order.depositPercent}
            priceMatchGuaranteeEligible={order.permissions.priceMatchGuaranteeEligible}
            isMoving={true}
            isMultiDayOrder={order.multiDay}
            isLongDistanceOrder={!!order.movingOperation?.longDistanceTransportationRate}
          />
          <Spacer height="6rem" />
        </>
      )}
      <FAQCard />
      <Spacer height="3rem" />
      <ReferralsCard source="appointment_details_page" />
    </Container>
  );
};
