import * as React from 'react';

const DEFAULT_LOCALE = 'en';

enum TimestampFormat {
  Date = 'date',
  Time = 'time',
  DateTime = 'datetime',
  Custom = 'custom',
}

const DATE_OPTIONS = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
} as Intl.DateTimeFormatOptions;

const TIME_OPTIONS = {
  hour: 'numeric',
  minute: 'numeric',
} as Intl.DateTimeFormatOptions;

const Timestamp: React.FC<{
  locale?: string;
  value: string | Date;
  format: TimestampFormat;
  options?: Intl.DateTimeFormatOptions;
}> = ({ locale = DEFAULT_LOCALE, value, format, options }) => {
  const parsed = value instanceof Date ? value : new Date(value);
  const formatted = (() => {
    switch (format) {
      case TimestampFormat.Date:
        return parsed.toLocaleDateString(locale, DATE_OPTIONS);
      case TimestampFormat.Time:
        return parsed.toLocaleTimeString(locale, TIME_OPTIONS);
      case TimestampFormat.DateTime: {
        const date = parsed.toLocaleDateString(locale, DATE_OPTIONS);
        const time = parsed.toLocaleTimeString(locale, TIME_OPTIONS);
        return `${date} ${time}`;
      }
      case TimestampFormat.Custom:
        return parsed.toLocaleDateString(locale, options);
    }
  })();
  return <>{formatted}</>;
};

const Combined = Object.assign(Timestamp, { Format: TimestampFormat });
export { Combined as Timestamp };
