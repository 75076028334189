import React, { useEffect, useState } from 'react';

import { Button, Checkbox, COLORS } from '@clutter/clean';
import styled from '@emotion/styled';

import { useUpdateNotificationPreferencesMutation, usePreferencesQuery } from '@portal/schema';
import { Card } from '@shared/components/bootstrap';
import { Message } from './formatters';

const Right = styled.div`
  text-align: right;
`;

export const Preferences: React.FC = () => {
  const [message, setMessage] = useState<string | undefined>();
  const [success, setSuccess] = useState(false);
  const [emailReceipts, setEmailReceipts] = useState(false);

  const [updatePassword, { loading: mutationLoading }] = useUpdateNotificationPreferencesMutation();
  const { data, loading } = usePreferencesQuery();
  const currentPreferences = data?.account.preferences;
  useEffect(() => {
    if (currentPreferences) setEmailReceipts(currentPreferences.emailReceipts);
  }, [currentPreferences]);

  const combinedLoading = loading || mutationLoading;

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const result = await updatePassword({ variables: { input: { emailReceipts } } });
    if (result.data?.updateNotificationPreferences?.error) {
      setSuccess(false);
      setMessage(result.data?.updateNotificationPreferences?.error);
    } else {
      setSuccess(true);
      setMessage('Preferences updated successfully');
    }
  };

  const onChange = (value: boolean) => {
    setMessage(undefined);
    setEmailReceipts(value);
  };

  return (
    <form onSubmit={onSubmit}>
      <Card>
        <Card.Header>Communication Preferences</Card.Header>
        <Card.Body>
          <Checkbox.Selector
            onChange={(_, value) => onChange(value.emailReceipts)}
            values={{ emailReceipts: emailReceipts }}
            name=""
            options={[{ key: 'emailReceipts', label: 'Send me email receipts for charges and refunds.' }]}
          />
          <Message color={success ? COLORS.tealPrimary : COLORS.toucan}>{message}</Message>
        </Card.Body>
        <Card.Footer>
          <Right>
            <Button kind="primary" loading={combinedLoading} disabled={combinedLoading}>
              Save
            </Button>
          </Right>
        </Card.Footer>
      </Card>
    </form>
  );
};
