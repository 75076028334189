import React from 'react';
import { Box } from '@clutter/clean';

import { IMPERSONATOR } from '@portal/config/impersonator';
import styled from '@emotion/styled';

export const L1_AGENT_CUSTOMER_ONLY_ERROR_MESSAGE =
  'L1 agents are not permitted to take this action. This action must be taken by the customer.';

export const L1_AGENT_L2_ONLY_ERROR_MESSAGE =
  'L1 agents are not permitted to take this action. This action must be taken by an L2 agent.';

export const BLOCK_ACTION = !!IMPERSONATOR?.roles.includes('l1_agent');

export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: (children: React.ReactElement) => React.ReactElement;
  children: React.ReactElement;
}) => (condition ? wrapper(children) : children);

const List = styled.ul`
  padding: 0 16px;
  margin-bottom: 0;
`;

export const L1AgentLimitations = () => (
  <Box textAlign="left" width="100%">
    L1 agents are not able to perform the following from the Account Portal:
    <List>
      <li>File, edit, or accept claims</li>
      <li>Sign any documents on behalf of customers</li>
      <li>
        Any billing related activity:
        <ul>
          <li>Add a new card</li>
          <li>Make a payment</li>
          <li>Accept a payment plan</li>
          <li>Change a password, email address, or phone number</li>
          <li>Override booking restrictions</li>
        </ul>
      </li>
    </List>
  </Box>
);
