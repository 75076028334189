import * as React from 'react';
import { useEffect, useRef } from 'react';

export const Element: React.FC<{
  element: IStripeElement;
}> = ({ element }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!element || !ref.current) {
      return;
    }
    element.mount(ref.current);
    return () => {
      element.unmount();
    };
  }, [element, ref]);

  return <div ref={ref} />;
};
