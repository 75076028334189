import React from 'react';
import { COLORS, FontWeight, Icon, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { DateTime, Duration } from 'luxon';
import { Financing__Intent, Maybe, Order, OrderStateEnum } from '@portal/schema';
import { Title } from './title';
import { Affirm } from './affirm';
import { Section } from './section';
import { Prequalify } from './prequalify';

enum StepStatus {
  Now,
  Completed,
  Scheduled,
}

const StepBadge = styled(Text.Callout)`
  color: ${COLORS.cloud};
  background: ${COLORS.tealPrimary};
  border-radius: 4px;
  font-size: 12px;
  font-weight: ${FontWeight.Semibold};
  text-align: center;
  text-transform: uppercase;
  padding: 4px;
  margin: 8px 0;
`;

const StepWhen = styled(Text.Callout)`
  color: ${COLORS.tealPrimary};
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: ${FontWeight.Semibold};
  margin: 8px 0;
`;

const StepLine = styled.div`
  background: #a1e6e0;
  border-radius: 1px;
  z-index: 8;
  width: 2px;
  height: 80px;
`;

const StepLineAbove = styled(StepLine)`
  margin: -80px auto 0;
`;

const StepLineBelow = styled(StepLine)`
  margin: 0 auto -80px;
`;

const STEP_STATUS_BACKGROUND = {
  [StepStatus.Now]: COLORS.dust,
  [StepStatus.Completed]: COLORS.grayBackground,
  [StepStatus.Scheduled]: COLORS.tealBackground,
};

const Container = styled.div`
  margin: 0 -15px;
`;

const StepWrapper = styled.div<{ status: StepStatus }>`
  background: ${({ status }) => STEP_STATUS_BACKGROUND[status]};
  box-shadow: inset 0 -4px 0 0 ${COLORS.cloud};
  align-items: center;
  display: flex;
  padding: 24px;
  overflow: hidden;
`;

const StepContainerDefault = styled.div`
  ${StepLineAbove} {
    display: none;
  }

  ${StepLineBelow} {
    display: none;
  }
`;

const StepContainerActive = styled(StepContainerDefault)`
  & + &:not(:first-of-type) {
    ${StepLineAbove} {
      display: block;
    }
  }

  :not(:last-of-type) {
    ${StepLineBelow} {
      display: block;
    }
  }
`;

const StepMode = styled.div`
  padding: 0 18px 0 0;
  width: 64px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StepContent = styled.div`
  padding: 0 0 0 18px;
  flex-grow: 1;
  flex-shrink: 1;
`;

const StepTitle = styled(Text.Title)`
  margin: 0 0 4px;
`;

const StepCaption = styled(Text.Caption)`
  margin: 4px 0 0;
`;

const StepTime: React.FC<{
  when?: DateTime;
  status: StepStatus;
}> = ({ when, status }) => {
  if (status === StepStatus.Completed) {
    return <Icon.CircleCheck color={COLORS.cloud} backgroundColor={COLORS.tealPrimary} />;
  }

  if (when) {
    return (
      <StepWhen>
        {when.toLocaleString({ weekday: 'short' })}
        <br />
        {when.toLocaleString({ month: 'short', day: 'numeric' })}
      </StepWhen>
    );
  }

  return <StepBadge>Now</StepBadge>;
};

const Step: React.FC<{
  when?: DateTime;
  status: StepStatus;
  title: string;
  caption: string;
}> = ({ when, title, caption, status, children }) => {
  const StepContainer = status === StepStatus.Completed ? StepContainerDefault : StepContainerActive;
  return (
    <StepContainer>
      <StepWrapper status={status}>
        <StepMode>
          <StepLineAbove />
          <StepTime when={when} status={status} />
          <StepLineBelow />
        </StepMode>
        <StepContent>
          <StepTitle size="extraSmall">{title}</StepTitle>
          <StepCaption>{caption}</StepCaption>
          {children}
        </StepContent>
      </StepWrapper>
    </StepContainer>
  );
};

type OrderFragment = Pick<Order, 'id' | 'state' | 'scheduled'> & {
  financingIntent?: Maybe<Pick<Financing__Intent, 'id' | 'estimatedAmount'>>;
};

const DELAY = Duration.fromObject({ hours: 48 });
const DATE_TIME_OPTIONS = { setZone: true };

export const Steps: React.FC<{
  order: OrderFragment;
}> = ({ order }) => {
  const scheduled = DateTime.fromISO(order.scheduled, DATE_TIME_OPTIONS);

  return (
    <Section>
      <Title>
        Paying for your move with <Affirm />
      </Title>

      <Container>
        <Step
          status={order.state === OrderStateEnum.Completed ? StepStatus.Completed : StepStatus.Now}
          title="Prequalify with Affirm"
          caption="Affirm will ask you some basic questions to determine how much they can prequalify you to spend."
        >
          {order.financingIntent && order.state !== OrderStateEnum.Completed && (
            <Prequalify amount={order.financingIntent.estimatedAmount} />
          )}
        </Step>
        <Step
          when={scheduled}
          status={order.state === OrderStateEnum.Completed ? StepStatus.Completed : StepStatus.Scheduled}
          title="Move to your new home"
          caption="Our team will arrive at 9:00 AM ready to help you move your items to your new home."
        />
        <Step
          when={scheduled.plus(DELAY)}
          status={StepStatus.Scheduled}
          title="Complete your payment within 48 hrs"
          caption="After you move is done, we’ll send you a link to complete your payment with Affirm. "
        />
      </Container>
    </Section>
  );
};
