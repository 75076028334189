import React from 'react';
import styled from '@emotion/styled';
import { Button, COLORS } from '@clutter/clean';

const StyledButton = styled(Button)`
  display: flex;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
  min-width: inherit;
  max-width: inherit;
  border-radius: 50%;
`;

const Container = styled.div`
  display: flex;
`;

const Value = styled.div<{ isMobile: boolean }>`
  width: ${(props) => (props.isMobile ? '28px' : '64px')};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ICON_COLOR = COLORS.cloud;
const DISABLED_ICON_COLOR = COLORS.hippo;

export const Stepper: React.FC<{
  min?: number;
  max?: number;
  disabled?: boolean;
  value: number;
  onChange(value: number): void;
  isMobile: boolean;
}> = ({ min, max, disabled, value, onChange, isMobile }) => {
  const decrementable = (min === undefined || value > min) && !disabled;
  const incrementable = (max === undefined || value < max) && !disabled;
  const onIncrement = () => {
    onChange(value + 1);
  };
  const onDecrement = () => {
    onChange(value - 1);
  };
  return (
    <Container>
      <StyledButton onClick={onDecrement} disabled={!decrementable} aria-label="Decrement">
        <Decrement color={decrementable ? ICON_COLOR : DISABLED_ICON_COLOR} />
      </StyledButton>
      <Value isMobile={isMobile}>{value}</Value>
      <StyledButton onClick={onIncrement} disabled={!incrementable} aria-label="Increment">
        <Increment color={incrementable ? ICON_COLOR : DISABLED_ICON_COLOR} />
      </StyledButton>
    </Container>
  );
};

type IconProps = {
  color: string;
  strokeWidth?: string;
};

const Decrement: React.FC<IconProps> = ({ color }) => (
  <svg width="14" height="3" viewBox="0 0 14 3" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.8825e-07 1.99866C0.000769084 1.44637 0.449107 0.998657 1.00139 0.998657H13.0014C13.5537 0.998657 14.0008 1.44637 14 1.99866C13.9992 2.55094 13.5509 2.99866 12.9986 2.99866H0.99861C0.446325 2.99866 -0.000767108 2.55094 9.8825e-07 1.99866Z"
      fill={color}
    />
  </svg>
);

const Increment: React.FC<IconProps> = ({ color }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99854 13.0014C5.99854 13.5537 6.44625 14.0008 6.99854 14C7.55082 13.9992 7.99853 13.5509 7.99853 12.9986V7.99864H12.9986C13.5509 7.99864 13.9992 7.55092 14 6.99864C14.0008 6.44635 13.5537 5.99864 13.0014 5.99864H7.99853V0.998611C7.99853 0.446326 7.55082 -0.000765854 6.99854 9.84969e-07C6.44625 0.000770339 5.99854 0.449108 5.99854 1.00139V5.99864H1.00139C0.449107 5.99864 0.000769084 6.44635 9.88199e-07 6.99864C-0.000767108 7.55092 0.446325 7.99864 0.99861 7.99864H5.99854V13.0014Z"
      fill={color}
    />
  </svg>
);
