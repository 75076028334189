import * as React from 'react';

import { BREAKPOINTS, COLORS, mq, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { toggleStyleValue } from './css';

interface ISelectableCardProps<T> {
  children: React.ReactNode;
  actionText: string;
  selectedActionText: string;
  bottomDescription?: React.ReactNode;
  detailedInfo?: string;
  selected: boolean;
  value: T;
  className?: string;
  onSelect: (selected: T) => void;
  disabled?: boolean;
  disabledActionText?: string;
}

const Button = styled.button<{ selected: boolean }>`
  height: 40px;
  border: 1.76px solid ${COLORS.tealPrimary};
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  outline: none;
  transition: all 0.2s ease;
  cursor: pointer;

  color: ${toggleStyleValue('selected', COLORS.cloud, COLORS.tealPrimary)};
  background: ${toggleStyleValue('selected', COLORS.tealPrimary, COLORS.cloud)};

  &:disabled {
    color: ${COLORS.hippo};
    background: ${COLORS.grayBorder};
    pointer-events: none;
    border: none;
  }

  ${mq({ minWidth: ['124px', '100px'] })}
`;

const SelectableCardContainer = styled.div<{
  selected: boolean;
  disabled?: boolean;
}>`
  cursor: ${toggleStyleValue('disabled', 'initial', 'pointer')};
  border-radius: 4px;

  box-shadow: ${toggleStyleValue('selected', '0px 0px 5px rgba(0, 0, 0, 0.1)', '0px 4px 22px rgba(0, 0, 0, 0.1)')};

  @media (max-width: ${BREAKPOINTS.SM}) {
    box-shadow: ${toggleStyleValue('selected', '0px 0px 5px rgba(0, 0, 0, 0.1)', '0px 4px 12px rgba(0, 0, 0, 0.1)')};
  }

  ${({ disabled, selected }) =>
    disabled
      ? `
        box-shadow: none;
        border: 1px solid ${COLORS.grayBorder};`
      : `
        &:hover {
          box-shadow: ${selected ? '0px 0px 5px rgba(0, 0, 0, 0.1)' : '0px 4px 40px rgba(0, 0, 0, 0.1)'};

          ${Button} {
            background: ${selected ? COLORS.tealPrimary : COLORS.tealBackground};
          }
        }`}

  ${mq({ minWidth: ['100%'] })}
`;

const TopContainer = styled.div`
  ${mq({ padding: ['16px', '24px'] })}
`;

const BottomContainer = styled.div`
  border-top: 1px solid ${COLORS.grayBorder};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${mq({ padding: ['16px', '24px'], maxHeight: ['72px', '88px'] })}

  & > button:only-child {
    margin: 0 auto;
    flex-grow: 1;
  }
`;

const DescriptionTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #757575;
`;

const DescriptionBody = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: ${COLORS.panther};
`;

const DetailedInfo = styled(Text.Body)`
  font-size: 12px;
  text-align: center;
  background-color: #ffeabf;
  color: ${COLORS.tealDark};
  border-radius: 0px 0px 4px 4px;
  border-top: 2px solid #ffd078;
  padding: 4px 0px;
`;

function SelectableCard<T>({
  children,
  actionText,
  selectedActionText,
  bottomDescription,
  detailedInfo,
  selected,
  value,
  onSelect,
  className,
  disabled,
  disabledActionText,
  ...props
}: ISelectableCardProps<T>) {
  return (
    <SelectableCardContainer
      className={className}
      selected={selected}
      onClick={() => !disabled && onSelect(value)}
      disabled={disabled}
      {...props}
    >
      <TopContainer>{children}</TopContainer>
      <BottomContainer>
        {bottomDescription}
        <Button disabled={disabled} selected={selected} onClick={() => !disabled && onSelect(value)}>
          {disabled && disabledActionText ? disabledActionText : selected ? selectedActionText : actionText}
        </Button>
      </BottomContainer>
      {detailedInfo && <DetailedInfo>{detailedInfo}</DetailedInfo>}
    </SelectableCardContainer>
  );
}

const Combined = Object.assign(SelectableCard, {
  Title: DescriptionTitle,
  Body: DescriptionBody,
});

export { Combined as SelectableCard };
