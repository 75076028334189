import { booleanResultResolver, useAbeetTest } from '@clutter/abeet';
import { ACCOUNT_ID } from '@portal/config/account';
import { AbeetResultResolver, AbeetTestOptions, AbeetExecuteVariables } from '@clutter/abeet';
import { OrderDetailsOrder } from '@portal/components/orders/cancel/steps';
import { DateTime } from 'luxon';
import { useCallbackWindowsQuery } from '@portal/schema';

const WT_ACCOUNT_KEY = 'account_id';

function useAbeetAccountTest<T>(
  familyName: string,
  options: {
    skip?: boolean;
    resolver: AbeetResultResolver<T>;
  },
): [result: T, loading: boolean];
function useAbeetAccountTest(
  familyName: string,
  options?: {
    skip?: boolean;
  },
): [result: AbeetExecuteVariables, loading: boolean];
function useAbeetAccountTest<T>(
  familyName: string,
  options: AbeetTestOptions<T> = {
    skip: false,
  },
) {
  return useAbeetTest(WT_ACCOUNT_KEY, ACCOUNT_ID, familyName, options);
}

export function useCallToCancelTest(order: OrderDetailsOrder) {
  const { data } = useCallbackWindowsQuery();
  const scheduledDate = DateTime.fromISO(order.scheduled).startOf('day');
  const callToCancelEligible = scheduledDate.diffNow('days').days > 3;
  return useAbeetAccountTest('portal_account_call_to_cancel_order', {
    skip: !(callToCancelEligible || data?.allowImmediateCallback),
    resolver: booleanResultResolver('enabled'),
  })[0];
}
