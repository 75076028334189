import React from 'react';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { Box, COLORS, FontWeight, Text } from '@clutter/clean';
import calendar from '@portal/images/calendar.svg';
import { getOrdinalSuffix } from '@shared/utils';
import { Currency } from '@shared/components/helpers';

const HourlyRateText = styled(Text.Body)<{ strikethrough?: boolean }>`
  display: inline;
  text-decoration: ${({ strikethrough }) => (strikethrough ? 'line-through' : 'none')};
`;

type HourlyRateProps = {
  amount: number;
  strikethrough?: boolean;
};

const HourlyRate: React.FC<HourlyRateProps> = ({ amount, strikethrough }) => (
  <HourlyRateText strikethrough={strikethrough}>
    <Currency value={amount} precision="automatic" />
    /hr
  </HourlyRateText>
);

type RateProps = {
  base: number;
  actual: number;
};

const Rate: React.FC<RateProps> = ({ base, actual }) => {
  const showBase = base !== actual;

  return (
    <Box.Flex flexDirection="column" alignItems="flex-end">
      <Box.FlexItem>
        <Text.Caption weight={FontWeight.Medium} color={COLORS.hippo}>
          Rate
        </Text.Caption>
      </Box.FlexItem>
      <Text.Body style={{ textAlign: 'right' }}>
        {showBase && <HourlyRate amount={base} strikethrough={showBase} />} <HourlyRate amount={actual} />
      </Text.Body>
    </Box.Flex>
  );
};

type DescriptionProps = {
  date: DateTime;
  base: number;
  actual: number;
};

const Description: React.FC<DescriptionProps> = ({ date, base, actual }) => {
  const standard = base === actual;
  const peak = actual > base;
  const formattedDate = `${date.toFormat('MMMM d')}${getOrdinalSuffix(date.day)}, ${date.toFormat('yyyy')}`;

  if (standard) {
    return <Text.Body>{formattedDate} is available at your standard hourly rate.</Text.Body>;
  } else if (peak) {
    return (
      <Text.Body>
        <Text.Body display="inline" weight={FontWeight.Medium}>
          Peak Day!
        </Text.Body>{' '}
        <Text.Body display="inline">{formattedDate} is in high demand!</Text.Body>
        <Text.Body>Receive lower hourly rates by booking on lower demand days.</Text.Body>
      </Text.Body>
    );
  } else {
    return (
      <Text.Body>
        <Text.Body display="inline" weight={FontWeight.Medium}>
          Discounted Day!
        </Text.Body>{' '}
        <Text.Body display="inline">Receive a discount on your hourly rate by booking on {formattedDate}!</Text.Body>
      </Text.Body>
    );
  }
};

type InfoBannerProps = {
  date: DateTime;
  baseLaborRateAmount: number;
  laborRateAmount: number;
};

const Container = styled.div`
  width: 100%;
  border: 1px solid #ffeabf;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 24px;
  margin-top: 24px;
`;

export const LaborRateInfoBanner: React.FC<InfoBannerProps> = ({ date, baseLaborRateAmount, laborRateAmount }) => (
  <Container>
    <Box.Flex flexDirection="row" alignItems="center" margin="0px 16px 0px 0px">
      <img src={calendar} alt="Calendar" width="40px" style={{ marginRight: '16px' }} />
      <Description date={date} base={baseLaborRateAmount} actual={laborRateAmount} />
    </Box.Flex>
    <div>
      <Rate base={baseLaborRateAmount} actual={laborRateAmount} />
    </div>
  </Container>
);
