import React, { useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  Billing__Charge__Status as ChargeStatus,
  Billing__Source as Source,
  InvoiceDetailFragment as Invoice,
  Status,
  useSwapPaymentMethodMutation,
} from '@portal/schema';
import { Box, Text, UnstyledButton } from '@clutter/clean';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { BillingTabs } from '@portal/components/billing/tabs';
import { ReviewBox } from '@portal/components/invoices/switch_payment_method/review_box';
import { Button } from '@portal/components/shared/customer_protected/button';
import { BackArrow } from '@portal/components/shared/icons';
import { Selector as SourcesSelector } from '@portal/components/sources/selector';
import { billingURL, invoiceURL } from '@portal/config/routes';
import { FlashKind } from '@shared/types';
import { Message as ErrorMessage } from '@portal/components/shared/error';

const textPadding = css`
  padding: 8px 0;
`;

const Title = styled(Text.Title)`
  ${textPadding}
`;

const Subtitle = styled(Text.Body)`
  ${textPadding}
`;

const BackButton = styled(UnstyledButton)`
  height: 100%;
`;

const subtitleCopy = (id: string) =>
  `If we are able to successfully process charges on your new card, we will refund your original payment method for Invoice #${id}.`;

const DISCLAIMER_COPY = 'Refunds can take 3-5 business days to appear on your statement, depending on your bank.';
const SUCCESS_MESSAGE = 'Payment method successfully switched.';
const ERROR_MESSAGE =
  'We were unable to process payment on your new card. Please confirm your payment method before trying again.';

export const Switch: React.FC<RouteComponentProps & { invoice: Invoice }> = ({ history, invoice }) => {
  const [swapPaymentMethod, { loading }] = useSwapPaymentMethodMutation();
  const [selectedSource, setSelectedSource] = useState<Source | undefined>();
  const [error, setError] = useState<string>();

  const { id, displayID, charges, rebillable } = invoice;
  const successfulCharge = charges.filter((charge) => charge.status === ChargeStatus.Succeeded)[0];

  if (!rebillable || !successfulCharge) {
    return <div>Sorry, this you cannot switch the payment method on this invoice.</div>;
  }

  const originalSource = successfulCharge.source;

  const onSubmit = async () => {
    if (!selectedSource) return;

    const result = await swapPaymentMethod({ variables: { input: { invoiceID: id, sourceID: selectedSource.id } } });

    if (result?.data?.swapPaymentMethod?.status === Status.Ok && result?.data?.swapPaymentMethod?.swapped) {
      history.push(billingURL(BillingTabs.History), {
        flash: {
          kind: FlashKind.Success,
          message: SUCCESS_MESSAGE,
        },
      });
    } else {
      setError(ERROR_MESSAGE);
    }
  };

  return (
    <Box.Flex flexDirection="column" alignItems="center">
      <Box maxWidth="600px">
        <Box textAlign="center">
          <Title size="large">Switch payment method on invoice</Title>
          <Subtitle>{subtitleCopy(displayID)}</Subtitle>
          <Subtitle>{DISCLAIMER_COPY}</Subtitle>
        </Box>
        <Title size="medium">1. Confirm Payment Method</Title>
        <Box padding="0 0 32px 0">
          <SourcesSelector
            canAddPrepaid
            useButtonStyle
            showDefaultCheckbox={false}
            selectedSourceID={selectedSource?.id}
            omittedID={originalSource.id}
            setSelectedSourceID={() => {
              /* We are setting the full source instead of the ID */
            }}
            setSelectedSource={setSelectedSource}
          />
        </Box>
        <Title size="medium">2. Review and Pay</Title>
        <Box padding="24px 0 0 0">
          <ReviewBox chargeSource={selectedSource} refundSource={originalSource} amount={successfulCharge.amount} />
        </Box>
        {error && <ErrorMessage message={error} />}
        <Box.Flex justifyContent="space-between" padding="14px 0 0 0">
          <Link to={invoiceURL(id)}>
            <BackButton aria-label="Previous">
              <BackArrow />
            </BackButton>
          </Link>
          <Button
            onActionBlocked={(errorMessage) => setError(errorMessage)}
            loading={loading}
            disabled={!selectedSource || loading}
            onClick={onSubmit}
          >
            Finish
          </Button>
        </Box.Flex>
      </Box>
    </Box.Flex>
  );
};
