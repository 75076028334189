
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"estimatedItem"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"EstimatedItem"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"categoryId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"quantity"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"categoryDisplayName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"notes"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"length"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"width"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"height"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"cuft"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"estimatedMovers"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":154}};
    doc.loc.source = {"body":"fragment estimatedItem on EstimatedItem {\n  id\n  categoryId\n  quantity\n  categoryDisplayName\n  notes\n  length\n  width\n  height\n  cuft\n  estimatedMovers\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
