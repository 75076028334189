import * as React from 'react';

const SEPARATOR = <>, </>;
const FINAL_SEPARATOR = <> and </>;

interface ISentenceListProps {
  elements: JSX.Element[];
}

export const SentenceList = ({ elements }: ISentenceListProps) => {
  const length = elements.length;
  if (length === 0) {
    return null;
  }
  if (length === 1) {
    return elements[0];
  }

  const list: JSX.Element[] = [];

  elements.forEach((element, index) => {
    if (index === length - 1) {
      list.push(element);
    } else if (index === length - 2) {
      list.push(element, FINAL_SEPARATOR);
    } else {
      list.push(element, SEPARATOR);
    }
  });
  return <>{list.map((item, index) => React.cloneElement(item, { key: index }))}</>;
};
