import { DateTime } from 'luxon';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { CalendarEntries } from './calendar_entries';
import { CalendarLabel } from './calendar_label';
import { CalendarLabels } from './calendar_labels';
import { CalendarNavigation } from './calendar_navigation';
import { CalendarTitle } from './calendar_title';
import { CalendarHeader } from './calendar_header';
import { CalendarEntry } from './calendar_entry';

const LABELS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const STEP = { days: 1 };

const SEGMENT_OPTIONS = { year: 'numeric', month: 'long' } as Intl.DateTimeFormatOptions;

export const Calendar: React.FC<{
  loading?: boolean;
  from: DateTime;
  till: DateTime;
  showNav?: boolean;
  children?({ date }: { date: DateTime; selected?: boolean; disabled?: boolean; booked?: boolean }): React.ReactNode;
  selected(value: DateTime): boolean;
  disabled(value: DateTime): boolean;
  booked(value: DateTime): boolean;
  onSelect(value: DateTime): void;
  onPrevious?(): void;
  onNext?(): void;
}> = ({ loading, from, till, showNav = true, children, selected, disabled, booked, onSelect, onPrevious, onNext }) => {
  const groups: Map<string, DateTime[]> = new Map();

  for (let date: DateTime = from; date < till; date = date.plus(STEP)) {
    const key = `${date.year}-${date.month}`;
    if (!groups.has(key)) {
      groups.set(key, []);
    }
    groups.get(key)!.push(date);
  }

  return (
    <div>
      <CalendarLabels>
        {LABELS.map((label, i) => (
          <CalendarLabel key={i}>{label}</CalendarLabel>
        ))}
      </CalendarLabels>
      {Array.from(groups.keys()).map((key) => {
        const dates = groups.get(key)!;
        const [year, month] = key.split('-').map((value) => Number(value));
        const section = DateTime.local(year, month);
        return (
          <React.Fragment key={String(section)}>
            <CalendarHeader>
              {showNav && (
                <CalendarNavigation aria-label="previous-month" disabled={!onPrevious} onClick={onPrevious}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </CalendarNavigation>
              )}
              <CalendarTitle>
                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : section.toLocaleString(SEGMENT_OPTIONS)}
              </CalendarTitle>
              {showNav && (
                <CalendarNavigation aria-label="next-month" disabled={!onNext} onClick={onNext}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </CalendarNavigation>
              )}
            </CalendarHeader>
            <CalendarEntries>
              {dates.map((date) => (
                <CalendarEntry
                  key={String(date)}
                  date={date}
                  children={children}
                  disabled={disabled(date)}
                  selected={selected(date)}
                  booked={booked(date)}
                  onSelect={onSelect}
                />
              ))}
            </CalendarEntries>
          </React.Fragment>
        );
      })}
    </div>
  );
};
