import React from 'react';

export const CircleXButton: React.FC = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="white" />
    <line
      x1="1"
      y1="-1"
      x2="16"
      y2="-1"
      transform="matrix(0.707107 -0.707107 0.706123 0.70809 9.93945 22.0208)"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="1"
      y1="-1"
      x2="16"
      y2="-1"
      transform="matrix(0.707107 0.707107 -0.706123 0.70809 9 10.0298)"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
