import * as React from 'react';

import { Radio } from '@clutter/clean';
import { FieldFormGroup } from './field_form_group';

type Option<T> = {
  label: string;
  value: T;
};

type RadioProps<T = any> = {
  options: Array<Option<T>>;
  onChange(value?: T): void;
  value: T;
  label: React.ReactNode;
  name: string;
  error?: string;
};

export const RadioFormGroup: React.FC<
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value' | 'name'> & RadioProps
> = ({ name, label, options, error, value, id, onChange, ...props }) => (
  <FieldFormGroup label={label} error={error} id={id}>
    <Radio.Selector {...props} name={name} options={options} value={value} onChange={onChange} />
  </FieldFormGroup>
);
