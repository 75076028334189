import React from 'react';

export const FiveBoxesVaried: React.FC = () => (
  <svg width="80" height="53" viewBox="0 0 80 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M74.8235 37.0001H46.5882V52.6168H74.8235V37.0001Z"
        fill="#FFEABF"
        stroke="#FFEABF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.5882 37.0001H32.4706V52.6168H46.5882V37.0001Z"
        fill="#FFEABF"
        stroke="#FFEABF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.5294 40.9043H57.8824C57.3176 40.9043 56.9412 40.644 56.9412 40.2536C56.9412 39.8632 57.3176 39.6029 57.8824 39.6029H63.5294C64.0941 39.6029 64.4706 39.8632 64.4706 40.2536C64.4706 40.644 64.0941 40.9043 63.5294 40.9043Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.8235 4.46536H46.5882V20.082H74.8235V4.46536Z"
        fill="#FFEABF"
        stroke="#FFEABF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.5294 8.36953H57.8824C57.3176 8.36953 56.9412 8.10925 56.9412 7.71883C56.9412 7.32842 57.3176 7.06814 57.8824 7.06814H63.5294C64.0941 7.06814 64.4706 7.32842 64.4706 7.71883C64.4706 8.10925 64.0941 8.36953 63.5294 8.36953Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.4118 20.7327H37.1765V36.3494H65.4118V20.7327Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.1176 20.7327H64.4706V36.3494H70.1176V20.7327Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.1176 24.6369H48.4706C47.9059 24.6369 47.5294 24.3766 47.5294 23.9862C47.5294 23.5958 47.9059 23.3355 48.4706 23.3355H54.1176C54.6824 23.3355 55.0588 23.5958 55.0588 23.9862C55.0588 24.3766 54.6824 24.6369 54.1176 24.6369Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.5882 37.0001V51.9661"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.5882 20.7327H70.1176"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.4706 36.3494V20.7327"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.1176 37.0001H37.1765"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.614 13.6803L23.9741 51.4878L33.2428 52.6174L42.8827 14.8099L33.614 13.6803Z"
        fill="#A1E6E0"
        stroke="#A1E6E0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.2235 52.6168L41.3176 20.7327V37.0001H37.5529V52.6168H33.2235Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.4 51.3154L40 13.51"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.8235 4.46536L60.7059 0.561187"
        stroke="#FFEABF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2824 38.8221L19.1059 42.2057V52.6168H15.9059V42.2057L13.0824 38.8221H16.2824Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.6 38.8221L36.4235 42.2057V52.6168H34.1647V42.2057L31.3412 38.8221H33.6Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.0706 52.6168H6.87059C5.92941 52.6168 5.17647 52.0962 5.17647 51.4455V42.6612C5.17647 42.3358 5.3647 42.0755 5.64706 41.8153L7.52941 40.5789C7.81176 40.3837 8.28235 40.2536 8.75294 40.2536H40.2823C40.7529 40.2536 41.1294 40.3837 41.5059 40.5789L43.2941 41.8803C43.5765 42.0755 43.7647 42.4009 43.7647 42.7262V51.5106C43.7647 52.0962 43.0118 52.6168 42.0706 52.6168Z"
        fill="#00A499"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.4235 52.6168V42.6612C36.4235 42.3358 36.6118 42.0755 36.8941 41.8153L38.6824 40.5789C38.9647 40.3837 39.4353 40.2536 39.9059 40.2536"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.4235 43.8975V42.9865C36.4235 42.531 35.9529 42.2057 35.2941 42.2057H6.4C5.74117 42.2057 5.27058 42.531 5.27058 42.9865"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3412 40.2536L28.5176 42.2057V52.6168H30.7765V42.2057L33.6 40.2536H31.3412Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.4 38.8221L27.5765 42.2057V52.6168H30.7765V42.2057L33.6 38.8221H30.4Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0823 40.2536L10.2588 42.2057V52.6168H13.4588V42.2057L16.2823 40.2536H13.0823Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0823 38.8221L10.2588 42.2057V52.6168H13.4588V42.2057L16.2823 38.8221H13.0823Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3412 38.8221H14.7765H13.0824L13.6471 37.0001H33.0353L33.6 38.8221H31.3412Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.2118 38.8221H16.2823L13.4588 42.2057V52.6168"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.7765 52.6168V42.2057L33.6 38.8221"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.2823 40.2536V44.1578"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.2823 44.1578L38.7765 46.6304H41.7882L40.2823 44.1578Z"
        fill="#A1E6E0"
        stroke="#A1E6E0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.5294 52.6168H0.470589"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="80" height="52.7063" fill="white" transform="translate(0 0.23584)" />
      </clipPath>
    </defs>
  </svg>
);
