import { CustomerItemFragment, useUsageWithDownsizeQuery } from '@portal/schema';
import { Label, Text, Box, FontWeight, COLORS } from '@clutter/clean';
import React from 'react';
import styled from '@emotion/styled';
import { Timestamp } from '@shared/components/helpers';

const Separator = styled.hr`
  background: ${COLORS.grayBorder};
  border: none;
  margin: 24px 0;
  height: 1px;
`;

const LineItem: React.FC<{ label: React.ReactNode; content: React.ReactNode }> = ({ label, content }) => (
  <React.Fragment>
    <Separator />
    <Box.Flex justifyContent="space-between">
      <Text.Body weight={FontWeight.Medium}>{label}</Text.Body>
      <span> {content}</span>
    </Box.Flex>
  </React.Fragment>
);

export const Info: React.FC<{ item: CustomerItemFragment }> = ({ item }) => {
  const { data } = useUsageWithDownsizeQuery();
  if (!data) return null;

  const itemCuft = Math.round(item.cuft || 1) || 1;
  const cuftPct = Math.round((itemCuft / data.account.usage.total) * 100) || 0;

  return (
    <>
      <Separator />
      <Label>{itemCuft} cubic feet</Label>
      <Text.Callout>This item takes up {cuftPct}% of your total storage space</Text.Callout>
      {'barcode' in item && <LineItem label="Item barcode" content={item.barcode.value} />}
      {item.pickedUpAt && (
        <LineItem label="Pickup date" content={<Timestamp value={item.pickedUpAt} format={Timestamp.Format.Date} />} />
      )}
      {item.returnedAt && (
        <LineItem
          label="Returned date"
          content={<Timestamp value={item.returnedAt} format={Timestamp.Format.Date} />}
        />
      )}
      <Separator />
    </>
  );
};
