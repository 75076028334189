import React from 'react';
import styled from '@emotion/styled';
import { History } from 'history';

import { itemsURL } from '@portal/config/routes';
import { orderDisplayName } from '@portal/utils/order';
import { Button, Text, mq } from '@clutter/clean';

import { OrderDetailsOrder } from '@portal/components/orders/cancel/steps';
import { Card } from '../active/card';

const TextContainer = styled.div`
  ${mq({
    textAlign: ['left', 'center'],
  })}
`;

const Title = styled(Text.Title)`
  margin-top: 32px;
  margin-bottom: 16px;
`;

const Body = styled(Text.Body)`
  margin-bottom: 32px;
`;

const CardContainer = styled.div`
  transform: translateX(-50%);
  margin-left: 50%;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  ${mq({
    width: ['100vw', 'initial', '600px'],
  })}
`;

const ButtonLink = styled.a`
  margin: 0 auto;
  max-width: 400px;
  display: block;
  &:hover {
    text-decoration: none;
  }
`;

interface ICanceledProps {
  order: OrderDetailsOrder;
  history: History;
}

export const Canceled = ({ order }: ICanceledProps) => {
  const orderNameStr = orderDisplayName(order);
  const appointmentStr = orderNameStr || 'appointment';
  return (
    <div>
      <TextContainer>
        <Title size="large">Your {appointmentStr} has been canceled</Title>
        <Body>You're all set.</Body>
      </TextContainer>
      <CardContainer>
        <Card order={order}></Card>
      </CardContainer>
      <ButtonLink href={itemsURL()}>
        <Button kind="secondary" fullWidth>
          Back to My Storage
        </Button>
      </ButtonLink>
    </div>
  );
};
