import { DateTime } from 'luxon';
import React from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

import { useConfirmOrderMutation } from '@portal/schema';

import { Spinner } from '@portal/components/helpers';
import { RewardsBanner } from '@portal/components/rewards/rewards_banner';
import { Text } from '@shared/components/bootstrap';

import { orderURL, ordersURL } from '@portal/config/routes';

import { FlashKind } from '@portal/types';

const FROM_ISO_OPTIONS = { setZone: true };

export const Confirm: React.FC = () => {
  const [execute, { data }] = useConfirmOrderMutation();
  const history = useHistory();
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    (async () => {
      const confirmMutation = await execute({ variables: { token } });
      const confirmData = confirmMutation.data?.confirm;
      const error = confirmData?.error;
      if (error) {
        const order = confirmData?.order;
        history.push(order ? orderURL(order.id) : ordersURL(), {
          flash: {
            kind: FlashKind.Warning,
            message: error,
          },
        });
      }
    })();
  }, [token]);

  const order = data?.confirm?.order;
  if (!order) return <Spinner />;
  const scheduled = DateTime.fromISO(order.scheduled, FROM_ISO_OPTIONS).toLocaleString({
    month: 'long',
    day: 'numeric',
  });

  return (
    <>
      <div className="mt-3 mb-3 pt-3 pb-3 border-bottom">
        <Text tag="h2" style="info">
          Your appointment on {scheduled} is confirmed.
        </Text>
      </div>

      <p>
        We sent an email confirming details of your appointment. Our Care Team may contact you if we need more info
        about your address or items.
      </p>

      <hr />

      <Text tag="h3" style="info">
        Want $100?
      </Text>

      <p>
        <strong>
          You earn $100 in account credit once your friend books storage with Clutter, and $50 in account credit once
          your friend books a move.
        </strong>{' '}
        <span>
          As a bonus, your friend receives the same amount as account credit as well! There’s no limit on the number of
          referrals you can cash in on. Just give them your referral code:
        </span>{' '}
        <strong>
          <Text style="info">{order.accountReferralCode}</Text>
        </strong>
      </p>
      <RewardsBanner source="order_confirm" />
    </>
  );
};
