import { COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { AnchorButton } from '@portal/components/shared/button';
import { CheckMark } from '@portal/components/shared/icons/check_mark';
import { Heading } from '@portal/components/shared/text';

const Container = styled.div`
  background: ${COLORS.tealJungle};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled(Heading)`
  color: ${COLORS.cloud};
  margin-bottom: 32px;
  text-align: center;
  padding: 24px;

  @media (max-width: 600px) {
    margin-bottom: 24px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 470px;
  @media (max-width: 600px) {
    padding: 0 24px;
  }
`;

const Button = styled(AnchorButton)`
  margin-top: 48px;
  width: 100%;
  color: ${COLORS.tealDark};
  :not(:disabled):hover {
    color: ${COLORS.tealDark};
  }
  @media (max-width: 600px) {
    margin-top: 32px;
  }
`;

export const PasswordSuccess: React.FC<{
  title: string;
  link: string;
}> = ({ title, link }) => (
  <Container>
    <Title level={1} size="large">
      {title}
    </Title>
    <Content>
      <CheckMark />
      <Button href={link} kind="secondary" size="medium">
        Take me to my online account
      </Button>
    </Content>
  </Container>
);
