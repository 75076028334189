import React from 'react';
import { Box, Checkbox } from '@clutter/clean';
import styled from '@emotion/styled';

const Container = styled(Box.Flex)`
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
`;

export interface IItemMetadata {
  [reason: string]: boolean;
}

const OPTIONS = [
  {
    key: 'fragile',
    label: 'Fragile items',
  },
  {
    key: 'heavy',
    label: 'Heavy items - such as books, weights, cookware, equipment, etc.',
  },
  {
    key: 'none',
    label: 'None of the above',
  },
];

export const MetadataSelector: React.FC<{
  itemID: string;
  metadata: Map<string, IItemMetadata>;
  setMetadata(metadata: Map<string, IItemMetadata>): void;
}> = ({ itemID, metadata, setMetadata }) => {
  const itemMetadata = metadata.get(itemID);

  const handleChange = (value: IItemMetadata) => {
    if (itemMetadata && !itemMetadata.none && value.none) {
      value.fragile = false;
      value.heavy = false;
    } else if (value.fragile || value.heavy) {
      value.none = false;
    }
    setMetadata(new Map(metadata.set(itemID, value)));
  };

  return (
    <Container>
      <Checkbox.Selector
        name="item-metadata"
        values={itemMetadata}
        options={OPTIONS}
        onChange={(_, value) => handleChange(value)}
      />
    </Container>
  );
};
