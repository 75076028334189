import { ApolloClient, InMemoryCache } from '@apollo/client';

import introspectionQueryResultData from '@portal/fragments';
import { TypedTypePolicies } from '@portal/apollo-types';
import { createInfiniteScrollFieldPolicy, DEFAULT_APOLLO_CLIENT_LINK } from '@shared/libraries/apollo';

const TYPE_POLICIES: TypedTypePolicies = {
  Account: {
    keyFields: [], // This expects only one account to ever be present in the customer's portal
  },
  Item: {
    keyFields: ['uuid'],
  },
  ItemGroup: {
    keyFields: ['uuid'],
  },
  Query: {
    fields: {
      customerItem: {
        read: (_, { args, toReference }) => {
          if (!args) throw new Error();
          return toReference({ uuid: args.uuid, __typename: args.type });
        },
      },
      paginatedCustomerItems: createInfiniteScrollFieldPolicy(['filters']),
    },
  },
};

export const client = new ApolloClient({
  link: DEFAULT_APOLLO_CLIENT_LINK,
  cache: new InMemoryCache({
    possibleTypes: introspectionQueryResultData.possibleTypes,
    typePolicies: TYPE_POLICIES,
  }),
});
