import * as React from 'react';
import { referralURL } from '@portal/config/routes';

export const Action: React.FC<{
  source: string;
}> = ({ source, children }) => (
  <div
    onClick={() => {
      const URL = referralURL(source, 'sms');
      open(URL);
    }}
    title="SMS"
  >
    {children}
  </div>
);
