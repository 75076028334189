import React, { useContext, useState } from 'react';

import { Box } from '@clutter/clean';
import { ClaimPaymentContext } from '@portal/contexts/claim_payment';
import { useClaimPreviewQuery } from '@portal/schema';
import { Modal } from '@shared/components/bootstrap';
import { Spinner } from '@portal/components/helpers';

type PreviewProps = {
  uuid: string;
  showPreview: boolean;
  onError(): void;
  onClose(): void;
};

export const AgreementPreviewModal = ({ uuid, showPreview, onError, onClose }: PreviewProps) => {
  const [previewURL, setPreviewURL] = useState<string>();
  const { data: context } = useContext(ClaimPaymentContext);

  useClaimPreviewQuery({
    skip: !showPreview,
    variables: { uuid, name: context.name, address: context.address },
    onError: onError,
    onCompleted: (data) => setPreviewURL(data.claimPreview),
  });

  if (!showPreview) {
    return null;
  }

  return (
    <Modal centered onClose={onClose} size={Modal.Size.Large}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Settlement Agreement</Modal.Title>
          <Modal.Close close={onClose} />
        </Modal.Header>
        <Modal.Body>
          <Box height="calc(100vh - 200px)">
            {previewURL ? <iframe width="100%" height="100%" src={previewURL} /> : <Spinner />}
          </Box>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
