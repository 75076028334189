/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import mapbox, { LngLatBounds, LngLat } from 'mapbox-gl';
import React, { useEffect, useState } from 'react';
import { MapContext } from './context';
import { useResizeObserver } from './hooks';

const DEFAULT_LATITUDE = 37.7577;
const DEFAULT_LONGITUDE = -122.4376;
const DEFAULT_HEIGHT = 480;
const DEFAULT_ZOOM = 15;

const DEFAULT_STYLE = 'mapbox://styles/mapbox/streets-v10';

interface IMapProps {
  height?: number;
  style?: string;
  latitude?: number;
  longitude?: number;
  bounds?: Array<[number, number]>;
  zoom?: number;
}

export const Mapbox: React.FC<IMapProps> = ({
  height = DEFAULT_HEIGHT,
  style = DEFAULT_STYLE,
  latitude = DEFAULT_LATITUDE,
  longitude = DEFAULT_LONGITUDE,
  zoom = DEFAULT_ZOOM,
  bounds,
  children,
}) => {
  const containerRef = useResizeObserver<HTMLDivElement>((_) => {
    if (map) {
      map.resize();
    }
  });

  const [loaded, setLoaded] = useState<boolean>(false);
  const [map, setMap] = useState<mapbox.Map | null>(null);

  const toBound = bounds && bounds.length > 1;
  const lngLatBounds = bounds?.reduce(
    (boundsMap, coordinate) => boundsMap.extend(new LngLat(coordinate[0], coordinate[1])),
    new LngLatBounds([...bounds[0], ...bounds[0]] as [number, number, number, number]),
  );

  const center: [number, number] = bounds ? bounds[0] : [longitude, latitude];

  useEffect(() => {
    if (containerRef.current) {
      const initMap = new mapbox.Map({
        style,
        center,
        container: containerRef.current,
        bounds: toBound ? lngLatBounds : undefined,
        fitBoundsOptions: { padding: 40 },
        zoom,
      });
      initMap.on('load', () => {
        setLoaded(true);
      });
      setMap(initMap);
    }

    return () => {
      if (map) {
        map.remove();
      }
      setMap(null);
    };
  }, []);

  return (
    <React.Fragment>
      <div
        ref={containerRef}
        css={css`
          height: ${height}px;
        `}
      />
      {loaded && map && <MapContext.Provider value={map}>{children}</MapContext.Provider>}
    </React.Fragment>
  );
};
