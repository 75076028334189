import cn from 'classnames';
import * as React from 'react';

const DEFAULT_TAG = 'span';

export const Text: React.FC<{
  tag?: 'span' | 'div' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'label' | 'strong';
  alignment?: 'left' | 'center' | 'right' | 'justify';
  style?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'muted';
  transform?: 'lowercase' | 'uppercase' | 'capitalize';
  wrapping?: 'wrap' | 'nowrap' | 'overflow' | 'truncate';
  weight?: 'bold' | 'normal' | 'light';
}> = ({ alignment, style, transform, wrapping, children, weight, tag = DEFAULT_TAG }) => {
  const Tag = tag;
  return (
    <Tag
      className={cn(
        alignment && `text-${alignment}`,
        style && `text-${style}`,
        transform && `text-${transform}`,
        wrapping && `text-${wrapping}`,
        weight && `font-weight-${weight}`,
      )}
    >
      {children}
    </Tag>
  );
};
