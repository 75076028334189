import { Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Message as ErrorMessage } from '@portal/components/shared/error';
import { Selector as SourcesSelector } from '@portal/components/sources/selector';

const HelpText = styled(Text.Callout)`
  margin-top: 24px;
`;

export const PaymentMethodSelector: React.FC<{
  showError: boolean;
  selectedSourceID?: string;
  setSelectedSourceID(sourceID: string): void;
}> = ({ showError, selectedSourceID, setSelectedSourceID }) => (
  <>
    <Text.Title size="extraSmall">Select payment method</Text.Title>
    {showError && <ErrorMessage message="Please verify the payment method has sufficient funds and try again." />}
    <HelpText>
      The selected card will be the default on your account. You can update this any time in your account settings.
    </HelpText>
    <SourcesSelector
      selectedSourceID={selectedSourceID}
      setSelectedSourceID={setSelectedSourceID}
      canAddPrepaid={false}
    />
  </>
);
