import { RouteWithTitle } from '@portal/components/shared/route_with_title';
import * as React from 'react';
import { Switch } from 'react-router-dom';

import { reviewThanksURL, reviewURL } from '@portal/config/routes';

import { Theme } from '@portal/components/helpers/theme';

import { Edit } from './review/edit';
import { Thanks } from './review/thanks';

export const Review: React.FC = () => (
  <Theme name="default">
    <Switch>
      <RouteWithTitle path={reviewThanksURL()} component={Thanks} title="Thanks for your Review" />
      <RouteWithTitle path={reviewURL()} component={Edit} title="Write a Review" />
    </Switch>
  </Theme>
);
