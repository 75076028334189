import cn from 'classnames';
import * as React from 'react';

interface IColSizeProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

type IColProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Col: React.FC<IColProps & IColSizeProps> = ({ xs, sm, md, lg, xl, className, children, ...props }) => (
  <div
    {...props}
    className={cn(
      xs && `col-xs-${xs}`,
      sm && `col-sm-${sm}`,
      md && `col-md-${md}`,
      lg && `col-lg-${lg}`,
      xl && `col-xl-${xl}`,
      className,
    )}
  >
    {children}
  </div>
);
