import * as React from 'react';
import { useContext } from 'react';

import { Spacing, Text } from '@shared/components/bootstrap';
import { Button } from '@shared/components/inventory_capture/button';
import { InventoryContext } from '@shared/components/inventory_capture/inventory_context';

import { AdditionalItems } from './additional_items';

import { Spacer } from '../helpers';

export const AnyAdditionalItems: React.FC = () => {
  const { data, onSelect } = useContext(InventoryContext);

  return (
    <>
      <Text tag="div" style="info">
        <strong>Would you like to list any items not captured above?</strong>
      </Text>
      <Text tag="p" style="muted">
        If we are not picking up any standard items, please list the items that will be picked up here.
      </Text>

      <Button
        active={data.hasExtraItems === true}
        onClick={() => onSelect({ ...data, hasExtraItems: true, extraItemsComplete: false })}
        text="Yes"
      />
      <Spacer width="1rem" inline />
      <Button
        active={data.hasExtraItems === false}
        onClick={() => onSelect({ ...data, hasExtraItems: false, extraItemsComplete: false })}
        text="No"
      />
      {!!data.hasExtraItems && (
        <Spacing mt={4}>
          <AdditionalItems />
        </Spacing>
      )}
    </>
  );
};
