import React from 'react';

import {
  AttachmentFragment,
  Status,
  useAttachClaimIssueSelectionAttachmentMutation,
  useDetachClaimIssueSelectionAttachmentMutation,
} from '@portal/schema';

type AttacherProps = {
  uuid: string;
  onAttach(attachment: AttachmentFragment): void;
  onDetach(attachment: AttachmentFragment): void;
  children(props: {
    loading: boolean;
    error?: string;
    attach(signedID: string): void;
    detach(attachmentID: string): void;
  }): React.ReactElement;
};

export const Attacher = ({ uuid, onAttach, onDetach, children }: AttacherProps) => {
  const [attach, { loading: attaching, data: attached }] = useAttachClaimIssueSelectionAttachmentMutation();
  const [detach, { loading: detaching, data: detached }] = useDetachClaimIssueSelectionAttachmentMutation();

  return children({
    loading: attaching || detaching,
    error:
      (attached && attached?.attachClaimIssueSelectionAttachment?.error) ||
      (detached && detached?.detachClaimIssueSelectionAttachment?.error) ||
      undefined,
    attach: async (signedID: string) => {
      const result = await attach({ variables: { uuid, signedID } });
      const status = result.data?.attachClaimIssueSelectionAttachment?.status;
      const attachment = result.data?.attachClaimIssueSelectionAttachment?.attachment;
      if (status !== Status.Ok || !attachment) {
        return;
      }
      onAttach(attachment);
    },
    detach: async (attachmentID: string) => {
      const result = await detach({ variables: { uuid, attachmentID } });
      const status = result.data?.detachClaimIssueSelectionAttachment?.status;
      const attachment = result.data?.detachClaimIssueSelectionAttachment?.attachment;
      if (status !== Status.Ok || !attachment) {
        return;
      }
      onDetach(attachment);
    },
  });
};
