import * as React from 'react';
import { Query } from '@apollo/client/react/components';

import { Spacing } from '@shared/components/bootstrap';
import { IEstimatedItem } from '@shared/types';

import * as QUERY from './custom_item_category.gql';
import { ExistingItems } from './existing';
import { NewItem } from './new';

interface IQueryData {
  customItemCategory: {
    id: string;
    name: string;
  };
}

export const EstimatedItemsForm: React.FC<{
  estimatedItems: IEstimatedItem[] | undefined;
  onSave(items: IEstimatedItem[]): void;
}> = ({ estimatedItems = [], onSave }) => {
  const removeItem = (index: number) => {
    onSave(estimatedItems.filter((_, i) => i !== index));
  };

  const editQuantity = (index: number, quantity: number | undefined) => {
    const newEstimatedItems = [...estimatedItems];
    newEstimatedItems[index] = { ...newEstimatedItems[index], quantity: quantity || 0 };
    onSave(newEstimatedItems);
  };

  return (
    <Query<IQueryData> query={QUERY}>
      {({ data, loading }) => {
        if (loading || !data) {
          return null;
        }

        const { customItemCategory } = data;

        const addItem = (itemName: string, quantity: number) => {
          const item: IEstimatedItem = {
            quantity,
            categoryId: customItemCategory.id,
            customCategoryName: itemName,
            categoryDisplayName: itemName,
          };
          onSave([...estimatedItems, item]);
        };

        return (
          <Spacing mb={4}>
            {!!estimatedItems && estimatedItems.length > 0 && (
              <ExistingItems items={estimatedItems} onEditQuantity={editQuantity} onRemove={removeItem} />
            )}
            <NewItem onSave={addItem} />
          </Spacing>
        );
      }}
    </Query>
  );
};
