import React from 'react';

import { VISITOR } from '@portal/config/visitor';
import {
  ActiveStorageAttachment,
  BuildClaimPropertySelectionOwnerInput,
  ClaimPropertySelectionFragment,
  ClaimPropertySelectionOwnership,
  Maybe,
} from '@portal/schema';

import { capitalize } from '@shared/utils/capitalize';

import { ViewAttachments } from './attachments';

interface IOwnerProps {
  ownership?: Maybe<ClaimPropertySelectionOwnership>;
  owner?: Maybe<BuildClaimPropertySelectionOwnerInput>;
  isMobile?: boolean;
}

interface IPropertySelectionTableProps {
  propertySelection: ClaimPropertySelectionFragment;
  setOpenAttachments(attachments: ActiveStorageAttachment[]): void;
}

const Owner = ({ ownership, owner }: IOwnerProps) => (
  <>
    {ownership === 'LANDLORD' && owner ? (
      <div className="property-selection__owner-details">
        <div>
          <span>Name:</span> {owner.name}
        </div>
        <div>
          <span>Email:</span> {owner.email}
        </div>
        <div>
          <span>Phone:</span> {owner.phone}
        </div>
      </div>
    ) : (
      <span>{VISITOR ? VISITOR.name : capitalize(ownership as string)}</span>
    )}
  </>
);

export const PropertySelectionTable = ({ propertySelection, setOpenAttachments }: IPropertySelectionTableProps) => {
  const { ownership, owner, photos, quotes } = propertySelection;
  const attachments = photos.concat(quotes);

  return (
    <table className="claim-view__table table">
      <thead>
        <tr className="d-none d-md-table-row">
          <th className="d-none d-md-table-cell">Property</th>
          <th className="d-none d-md-table-cell">Owner</th>
          <th className="d-none d-md-table-cell">Attachments</th>
        </tr>
      </thead>
      <tbody>
        <tr className="d-md-table-row">
          <td className="d-block d-md-table-cell">
            <ViewAttachments attachments={photos} setOpenAttachments={setOpenAttachments} isMobile />
            <span className="d-md-none claim-view__table__property-header">Name: </span>
            {propertySelection.name || '-'}
            <div className="d-md-none">
              <span className="claim-view__table__property-header">Owner: </span>
              <Owner ownership={ownership} owner={owner} isMobile />
            </div>
          </td>
          <td className="d-none d-md-table-cell">
            <Owner ownership={ownership} owner={owner} />
          </td>
          <td className="d-none d-md-table-cell">
            <ViewAttachments attachments={attachments} setOpenAttachments={setOpenAttachments} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
