import React from 'react';
import { DateTime } from 'luxon';
import styled from '@emotion/styled';
import { Box, Text } from '@clutter/clean';

import { IStepProps } from '@portal/components/orders/form';
import { Spacer } from '@shared/components/helpers';

import { Base } from './base';

const Title = styled(Text.Title)`
  margin-bottom: 16px;
`;

const Container = styled(Box)`
  max-width: 48rem;
`;

const List = styled(Text.Body)`
  margin-top: 8px;
`;

const formatDate = (date: DateTime) => date.toLocaleString(DateTime.DATE_MED);

const WaitlistedDates: React.FC<{ dates: DateTime[] }> = ({ dates }) => (
  <List>
    <ul>
      {dates.map((date) => (
        <li key={date.toISODate()}>{formatDate(date)}</li>
      ))}
    </ul>
  </List>
);

const ConfirmedDate: React.FC<{ date: DateTime; waitlistedDates: DateTime[] }> = ({ date, waitlistedDates }) => (
  <Box>
    <Text.Body>Your appointment will be guaranteed for {formatDate(date)} once you complete this booking!</Text.Body>
    <Spacer height="1rem" />
    <Text.Body>
      In addition to your guaranteed date, you requested to be on the waitlist for the dates below. You will need to
      finish booking in order to confirm your spot on the waitlist.
    </Text.Body>
    <WaitlistedDates dates={waitlistedDates} />
    <Text.Body>
      Once you finish this booking, you will be confirmed for the waitlist. And we will notify you at least 48 hours in
      advance of a waitlisted date if one becomes available.
    </Text.Body>
    <Spacer height="1rem" />
    <Text.Body>
      If a waitlisted date does not become available, then you will still have your guaranteed date.
    </Text.Body>
  </Box>
);

const UnconfirmedDate: React.FC<{ waitlistedDates: DateTime[] }> = ({ waitlistedDates }) => (
  <Box>
    <Text.Body>
      You requested to be on the waitlist for the dates below. You will need to finish booking in order to confirm your
      spot on the waitlist.
    </Text.Body>
    <WaitlistedDates dates={waitlistedDates} />
    <Text.Body>
      Once you are confirmed on the waitlist we will notify you at least 48 hours in advance if a date becomes
      available.
    </Text.Body>
    <Spacer height="1rem" />
    <Text.Body>
      If a waitlisted date does not become available, then we will let you know and your appointment will automatically
      be canceled.
    </Text.Body>
  </Box>
);

export const Waitlist: React.FC<
  {
    date: DateTime;
    waitlistedDates: DateTime[];
    confirmedDate: boolean;
    onNext?(): void;
    onPrev?(): void;
  } & IStepProps
> = ({ date, waitlistedDates, confirmedDate, onNext, onPrev }) => (
  <Base onNext={onNext} onPrev={onPrev}>
    <Container>
      <Title size="large">How does the waitlist work?</Title>
      {confirmedDate ? (
        <ConfirmedDate date={date} waitlistedDates={waitlistedDates} />
      ) : (
        <UnconfirmedDate waitlistedDates={waitlistedDates} />
      )}
    </Container>
  </Base>
);
