import { COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import cn from 'classnames';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { billingURL } from '@portal/config/routes';

const StyledTabs = styled.nav`
  width: 100%;
`;

const TabList = styled.ul`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
`;

const StyledTab = styled.li`
  list-style: none;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
`;

const TabLink = styled.a`
  display: block;
  color: ${COLORS.hippo} !important;
  text-align: center;
  padding: 8px;
  text-decoration: none;
  border-bottom: 2px solid ${COLORS.grayBorder};
  &:hover {
    color: ${COLORS.tealBrand} !important;
    text-decoration: none;
  }
  &:active,
  &.active {
    color: ${COLORS.tealPrimary} !important;
    border-bottom: 2px solid ${COLORS.tealPrimary};
  }
`;

export enum BillingTabs {
  Upcoming = 'upcoming',
  History = 'history',
  Payment = 'payment',
}

export const Tabs: React.FC<{ activeTab: BillingTabs }> = ({ activeTab }) => {
  const [selectedTab, setSelectedTab] = useState<BillingTabs>();

  const onClick = (event: React.MouseEvent, tab: BillingTabs) => {
    event.preventDefault();
    event.stopPropagation();

    if (tab !== activeTab) {
      setSelectedTab(tab);
    }
  };

  if (selectedTab) {
    return <Redirect to={billingURL(selectedTab)} />;
  }

  return (
    <StyledTabs>
      <TabList>
        {Object.values(BillingTabs).map((tab) => (
          <StyledTab key={tab}>
            <TabLink href="#" onClick={(event) => onClick(event, tab)} className={cn({ active: tab === activeTab })}>
              <Text.SmallCaps>{tab}</Text.SmallCaps>
            </TabLink>
          </StyledTab>
        ))}
      </TabList>
    </StyledTabs>
  );
};
