import { FieldPolicy } from '@apollo/client';
import { Reference } from '@apollo/client/utilities';
import { Maybe } from 'graphql/jsutils/Maybe';

// Duplicated from shared PaginationType
type Pagination = {
  __typename?: 'Pagination';
  currentPage: number;
  nextPage?: Maybe<number>;
  prevPage?: Maybe<number>;
  totalCount: number;
  totalPages: number;
};

type CachedPaginatedType = {
  /** Based on the query some fields may not be present, but for the sake of merging this isn't relevant */
  pagination: Partial<Pagination>;
  results: Reference[];
};

/** Guard against results added in real time causing duplicate results in Apollo's cache. */
const dedupe = (arr: Reference[]) => {
  const seen: Record<string, boolean> = {};
  // Note: this array may be sparse if records are returned out of order, and as
  // such `.filter` should not be used
  for (let i = 0; i < arr.length; i++) {
    const id = arr[i]?.__ref; // eslint-disable-line no-underscore-dangle
    if (seen[id]) delete arr[i];
    seen[id] = true;
  }
  return arr;
};

export function createInfiniteScrollFieldPolicy(
  keyArgs?: string[] | ((args: Record<string, any> | null) => string | string[]),
): FieldPolicy {
  return {
    keyArgs: keyArgs ?? false,
    merge: (existing: CachedPaginatedType | undefined, incoming: CachedPaginatedType, { args }) => {
      if (!args || !args.per || !args.page) throw new Error('`page` and `per` arguments must be provided!');
      if (!existing) return incoming;

      const { per, page } = args;
      const offset = (page - 1) * per;
      const limit = Math.min(incoming.results.length, per);
      const combined = existing.results.slice(0);

      for (let i = 0; i < limit; i++) {
        combined[offset + i] = incoming.results[i];
      }

      return {
        ...existing,
        pagination: { ...existing.pagination, ...incoming.pagination },
        /** This approach assumes that:
         * 1. We start with page 1
         * 2. Pages are requested sequentially
         * 3. We separately account for new items being prepended.
         *
         * If these are not followed, things will probably work but results may
         * be unpredictable (out of order results, less items than fetched, etc)
         */
        results: dedupe(combined),
      };
    },
  };
}
