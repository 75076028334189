import { Rating, usePageReviewMutation, useWalkthroughCsatReviewQuery } from '@portal/schema';
import { Layout, SnackbarProvider, ReviewWizard } from '@clutter/clean';
import * as React from 'react';

const WalkthroughCsat: React.FC<{ orderID: string }> = ({ orderID }) => {
  const [submit] = usePageReviewMutation();
  const { data } = useWalkthroughCsatReviewQuery({ variables: { orderID } });
  const submittedSurvey = data?.submittedWalkthroughCsatReview;

  return submittedSurvey ? null : (
    <Layout>
      <SnackbarProvider>
        <ReviewWizard
          question="How was your virtual walkthrough?"
          prompts={{
            GOOD: {
              answer: 'Great!',
              followup: {
                title: 'What went well?',
                tags: ['Easy to use', 'Clarified my quote', 'Helped me prepare', 'Found all the items I searched for'],
                commentsPlaceholder: 'Let us know how we can improve our virtual walkthrough.',
              },
            },
            POOR: {
              answer: 'Not so great...',
              followup: {
                title: 'What can be improved?',
                tags: [
                  'Difficult to use',
                  'Created more stress',
                  'Too expensive',
                  'Did not find all items I searched for',
                ],
                commentsPlaceholder: 'Let us know how we can improve our virtual walkthrough.',
              },
            },
          }}
          onSubmit={({ rating, comments, reasons }) => {
            const input = {
              url: location.href,
              rating: rating as Rating,
              comments,
              reasons: JSON.stringify(reasons ?? {}),
            };
            submit({ variables: { input } });
          }}
        />
      </SnackbarProvider>
    </Layout>
  );
};

export { WalkthroughCsat };
