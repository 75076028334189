import { Uploader as UploadMethod } from '@shared/hooks/uploader';
import * as React from 'react';
import { useContext, useState } from 'react';

import { useActiveStorageDirectUpload } from '@shared/hooks';

import { Progress } from '@shared/components/bootstrap';
import { Text } from '@shared/components/bootstrap';
import { Context } from './context';

export const Uploader: React.FC<{
  file: File;
  uuid: string;
  uploader?: UploadMethod;
}> = ({ file, uuid, uploader = useActiveStorageDirectUpload }) => {
  const [error, setError] = useState<Error | string | undefined>(undefined);
  const { onCancel, onUpload } = useContext(Context);

  const { loaded, total, retry } = uploader({
    file,
    onUpload: (signedID) => {
      setError(undefined);
      onUpload(file, uuid, signedID);
    },
    onError: setError,
  });

  const onDelete = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onCancel(file, uuid);
  };

  const onRetry = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setError(undefined);
    retry();
  };

  return (
    <>
      <Text wrapping="truncate" tag="div">
        {file.name}
      </Text>
      {error && (
        <Text tag="div">
          <a href="#" onClick={onDelete}>
            Delete
          </a>
          <span> | </span>
          <a href="#" onClick={onRetry}>
            Retry
          </a>
          <span> - </span>
          <Text tag="span" style="danger">
            {typeof error === 'string' ? error : error.message}
          </Text>
        </Text>
      )}
      <Progress>
        {!error && loaded !== undefined && total !== undefined && <Progress.Bar loaded={loaded} total={total} />}
      </Progress>
    </>
  );
};
