import * as React from 'react';

import { Step } from './step';

export const Ready = () => (
  <Step>
    <Step.Title>We’re going to go through each section you’ve selected, in order.</Step.Title>
    <Step.Subtitle>
      We’ll get through it as quickly as we can. You can always save your progress and come back to your claim when you
      have more time.
    </Step.Subtitle>
  </Step>
);
