import * as React from 'react';

const DEFAULT_COMPONENT = 'div';

interface IBreakpoint {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  value: 'none' | 'inline' | 'block';
}

const buildClassName = ({ size, value }: IBreakpoint) => {
  if (!size) {
    return `d-${value}`;
  } else {
    return `d-${size}-${value}`;
  }
};

export const Display: React.FC<{
  component?: React.ReactType;
  breakpoints: IBreakpoint[];
}> = ({ component: Component = DEFAULT_COMPONENT, breakpoints, children }) => (
  <Component className={breakpoints.map(buildClassName)}>{children}</Component>
);
