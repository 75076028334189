import React from 'react';

import { Box, Carousel, COLORS, toggleStyleValue, UnstyledButton, useCarousel, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { ImageFragment } from '@portal/schema';
import { Picture } from '@shared/components/helpers';

const StyledCarousel = styled(Carousel)`
  overflow: visible !important;
`;

const StyledImage = styled(Picture)`
  border-radius: 4px 4px 0 0;
  overflow: hidden;
`;

const HeroToggle = styled(UnstyledButton)<{ selected: boolean }>`
  background: ${toggleStyleValue('selected', COLORS.tealBackground, COLORS.cloud)};
  border-radius: 0 0 4px 4px;
  border: 1px solid ${COLORS.grayBorder};
  color: ${COLORS.tealPrimary};
  display: block;
  height: 44px;
  width: 100%;
`;

const Dot = styled(UnstyledButton)<{ selected: boolean }>`
  display: inline-block;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  margin: 24px 7px;
  opacity: ${toggleStyleValue('selected', 1, 0.2)};
  background: ${COLORS.tealPrimary};
  transition: opacity 0.2s;
`;

export const ImageCarousel: React.FC<{
  hero: ImageFragment;
  images: ImageFragment[];
  updateHero(id: string): void;
}> = ({ images, hero, updateHero }) => {
  const carouselProps = useCarousel({ wrapAround: false, initialIndex: 0 });
  const focusedIdx = (images.length + carouselProps.idx) % images.length;

  return (
    <Box margin="0 -24px">
      <Box margin="0 auto" width="375px" maxWidth="100vw" padding="0 6px">
        <Box maxWidth="339px" width="calc(100vw - 36px)" margin="0 auto" minHeight="364px">
          <StyledCarousel {...carouselProps} slidesToShow={1}>
            {images.map((image, i) => (
              <Box
                key={image.source}
                borderRadius="4px"
                overflow="hidden"
                padding="0 6px"
                onPointerUp={() => {
                  if (Math.abs(carouselProps.dragDeltaRef.current) < 0.01) carouselProps.setIdx(i);
                }}
              >
                <StyledImage source={image.source} width={327} height={440} draggable="false" />
                <HeroToggle selected={hero.id === image.id} onClick={() => updateHero(image.id)}>
                  <Text.Button>{image.id === hero.id ? 'Default' : 'Set as Default'}</Text.Button>
                </HeroToggle>
              </Box>
            ))}
          </StyledCarousel>
        </Box>
        <Box.Flex justifyContent="center">
          {images.map((_, i) => (
            <Dot
              aria-label={`Image ${i}`}
              key={i}
              selected={focusedIdx === i}
              onClick={() => carouselProps.setIdx(i)}
            />
          ))}
        </Box.Flex>
      </Box>
    </Box>
  );
};
