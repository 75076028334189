import { COLORS, Box, FontWeight, Text, TextButton } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { StatusLabel } from '@portal/components/invoices/status_label';
import { invoiceURL } from '@portal/config/routes';
import { Billing__InvoiceFragment as Invoice, Billing__Invoice__Status as InvoiceStatus } from '@portal/schema';
import { Currency } from '@shared/components/helpers';
import { formatDate } from '@shared/utils/format_date';

const Callout = styled(Text.Callout)`
  color: ${COLORS.panther};
  padding: 4px 0;
`;

const Description = styled(Text.Caption)`
  color: ${COLORS.hippo};
  padding: 4px 0;
`;

const descriptionText = (invoice: Invoice) => {
  const { amountDue, paidAt, failedAt, refundedAt, date, displayStatus: status, lumpSumInvoice } = invoice;
  if (status === InvoiceStatus.Paid && !!paidAt) {
    const action = amountDue > 0 ? 'Paid' : 'Added';
    return amountDue ? `${action} on ${formatDate(paidAt)}` : formatDate(paidAt);
  } else if (lumpSumInvoice) {
    return `Paid in Combined Invoice #${lumpSumInvoice.id} on ${formatDate(lumpSumInvoice.paidAt!)}`;
  } else if ((status === InvoiceStatus.Failed || status === InvoiceStatus.NotAttempted) && !!failedAt) {
    return `Payment failed on ${formatDate(failedAt)}`;
  } else if ((status === InvoiceStatus.Refunded || status === InvoiceStatus.PartiallyRefunded) && !!refundedAt) {
    return `Refunded on ${formatDate(refundedAt)}`;
  } else {
    return date ? formatDate(date) : undefined;
  }
};

export const InvoiceCard: React.FC<{ invoice: Invoice }> = ({ invoice }) => {
  const { displayName, amountDue, total, displayStatus: status, forgiven, forgivenessReasonKind, order } = invoice;
  const description = descriptionText(invoice);
  const bookingPartnerName = order?.bookingPartner?.name;
  const textOverride = bookingPartnerName ? `Billed by ${bookingPartnerName}` : undefined;
  return (
    <Box.Flex
      background={COLORS.cloud}
      borderRadius="4px"
      margin="8px 16px"
      padding="16px"
      minHeight="108px"
      justifyContent="space-between"
    >
      <Box.FlexItem>
        <Callout weight={FontWeight.Medium}>{displayName}</Callout>
        <Description>{description}</Description>
        <Box padding="16px 0 0 0">
          <TextButton href={invoiceURL(invoice.id)}>View Details</TextButton>
        </Box>
      </Box.FlexItem>
      <Box.FlexItem textAlign="right">
        <Box.Flex flexDirection="column" justifyContent="space-between" height="100%">
          <Box.FlexItem>
            <Callout weight={FontWeight.Medium}>
              <Currency value={total < 0 ? total : amountDue} />
            </Callout>
          </Box.FlexItem>
          <Box.FlexItem>
            <StatusLabel
              status={status}
              forgiven={forgiven}
              forgivenessReasonKind={forgivenessReasonKind}
              amountDue={amountDue}
              textOverride={textOverride}
            />
          </Box.FlexItem>
        </Box.Flex>
      </Box.FlexItem>
    </Box.Flex>
  );
};
