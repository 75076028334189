import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useParams } from 'react-router';

import { NAV_BAR_HEIGHT } from '@portal/components/shared/layout';
import { SupportFlowSlug } from '@portal/config/routes';

import { Wizard } from './wizard';

const verticallyCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  ${verticallyCenter}
  margin-top: 32px;
  position: absolute;
  top: ${NAV_BAR_HEIGHT}px;
  left: 0;
  right: 0;
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 0 24px;
`;

export const AccountResolution: React.FC = () => {
  const { support_flow: supportFlow } = useParams<{
    support_flow: SupportFlowSlug;
  }>();

  return (
    <Container>
      <ContentContainer>
        <Wizard supportFlow={supportFlow ?? SupportFlowSlug.Standard} />
      </ContentContainer>
    </Container>
  );
};
