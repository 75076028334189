import { IUser } from '@admin/types';

export interface IOpsAuditException {
  id: string;
  status: IOpsAuditExceptionStatus;
  reason: string;
  creator: IUser;
}

export enum IOpsAuditExceptionStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Denied = 'Denied',
}
