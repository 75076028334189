/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { CustomerTicket__ContactPreference } from '@portal/schema';
import { Box, Radio, Text, FontWeight, COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';
import mail from '@portal/images/mail.svg';
import phoneCall from '@portal/images/phone_call.svg';
import { WhatToStoreBox } from '@portal/components/shared/icons';
import { RadioCircle } from './radio_circle';

type OptionProps = {
  id: string;
  title: string;
  value: CustomerTicket__ContactPreference;
  description: string;
  sla: string;
  icon: React.ReactNode;
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
};

type ContactPreferenceOption = Pick<OptionProps, 'id' | 'title' | 'value' | 'description' | 'sla' | 'icon'>;

const CONTACT_PREFERENCE_OPTIONS: { [key in CustomerTicket__ContactPreference]: ContactPreferenceOption } = {
  CHAT: {
    id: 'chat',
    value: CustomerTicket__ContactPreference.Chat,
    title: 'Chat',
    icon: <WhatToStoreBox width="56px" />,
    description: 'Start a chat session with a Customer Support agent.',
    sla: 'Average time: 5 minutes',
  },
  EMAIL: {
    id: 'email',
    value: CustomerTicket__ContactPreference.Email,
    title: 'Email',
    icon: <img src={mail} width="56px" />,
    description: 'Tell us more about your issues to our Customer Support agent.',
    sla: 'Average time: 24 business hours',
  },
  PHONE: {
    id: 'phone',
    value: CustomerTicket__ContactPreference.Phone,
    title: 'Call',
    icon: <img src={phoneCall} width="47.16px" />,
    description: 'A Customer Support agent will call you.',
    sla: 'Average time: 48+ business hours',
  },
};

const Container = styled(Box.Flex)`
  flex-wrap: wrap;
`;

const disabledOptionStyle = css`
  background: ${COLORS.grayBackground};
  color: ${COLORS.storm};
  box-shadow: none;
`;

const Option: React.FC<OptionProps> = ({ id, value, checked, title, description, sla, icon, onChange, disabled }) => (
  <label style={{ cursor: disabled ? 'default' : 'pointer' }} htmlFor={id}>
    <Box.Flex
      key={id}
      width="318px"
      height="134px"
      boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
      borderRadius="4px 4px 0px 0px"
      padding="16px"
      css={disabled && disabledOptionStyle}
    >
      <Radio.Input
        name="contact_method"
        id={id}
        checked={checked}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      <Box.FlexItem margin="0px 16px 0px 0px" alignSelf="center" flexShrink={0}>
        {icon}
      </Box.FlexItem>

      <Box.FlexItem>
        <Text.Title size="extraSmall" style={{ marginBottom: '4px' }}>
          {title}
        </Text.Title>
        <Text.Caption>{description}</Text.Caption>
        <Text.Caption weight={FontWeight.Medium}>{sla}</Text.Caption>
      </Box.FlexItem>

      <RadioCircle checked={checked} disabled={disabled} />
    </Box.Flex>
  </label>
);

const DEFAULT_OPTIONS = [
  CustomerTicket__ContactPreference.Chat,
  CustomerTicket__ContactPreference.Email,
  CustomerTicket__ContactPreference.Phone,
];

export const ContactPreferenceOptionList: React.FC<{
  options?: CustomerTicket__ContactPreference[];
  selectedContactPreference: CustomerTicket__ContactPreference;
  onSelect: (value: CustomerTicket__ContactPreference) => void;
  disabled?: boolean;
  disabledOptions?: { [key in CustomerTicket__ContactPreference]?: boolean };
}> = ({ options = DEFAULT_OPTIONS, selectedContactPreference, onSelect, disabled, disabledOptions = {} }) => (
  <React.Fragment>
    <Text.Body weight={FontWeight.Medium} style={{ marginBottom: '24px' }}>
      Contact method
    </Text.Body>

    <Container>
      {options.map((preference) => {
        const option = CONTACT_PREFERENCE_OPTIONS[preference];

        return (
          <div key={option.id} style={{ marginRight: '16px', marginBottom: '24px' }}>
            <Option
              {...option}
              checked={option.value === selectedContactPreference}
              onChange={() => onSelect(option.value)}
              disabled={disabledOptions[preference] || disabled}
            />
          </div>
        );
      })}
    </Container>
  </React.Fragment>
);
