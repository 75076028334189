import styled from '@emotion/styled';
import * as React from 'react';
import { Button, ButtonLink, mq, Text } from '@clutter/clean';
import { BillingTabs } from '@portal/components/billing/tabs';
import { billingURL } from '@portal/config/routes';
import { Financing__FailureKind } from '@portal/schema';

const widthMQ = mq({
  width: ['100%', 'initial'],
});

const RetryButton = styled(Button)`
  ${widthMQ}
`;

const BillingButton = styled(ButtonLink)`
  ${widthMQ}
`;

const Body = styled(Text.Body)`
  padding-bottom: 32px;
`;

const Title = styled(Text.Title)`
  padding-bottom: 8px;
`;

const RETRY_COPY = 'Retry Payment with Affirm';
const BILLING_COPY = 'Billing History';

const FAILURE_CONTENT: Record<Financing__FailureKind, { title: string; body: React.ReactNode }> = {
  [Financing__FailureKind.PastDue]: {
    title: 'We’re unable to complete your payment with Affirm',
    body: <Body>It’s 48 hours past your move, so we’re unable to complete your payment with Affirm.</Body>,
  },
  [Financing__FailureKind.Retriable]: {
    title: 'Please try again',
    body: (
      <>
        <Body>We were unable to complete your payment with Affirm.</Body>
        <Body>Please try paying with Affirm again.</Body>
      </>
    ),
  },
  [Financing__FailureKind.Pending]: {
    title: 'Affirm payment processing',
    body: (
      <>
        <Body>Your loan is currently being processed.</Body>
        <Body>Please wait a few moments before trying again.</Body>
      </>
    ),
  },
  [Financing__FailureKind.Processed]: {
    title: 'Affirm payment processed',
    body: (
      <>
        <Body>This loan has already been processed through Affirm.</Body>
        <Body>Please view your billing history page to check the status of this payment.</Body>
      </>
    ),
  },
};

export const Failed: React.FC<{ kind: Financing__FailureKind; loading: boolean; onRetry(): void }> = ({
  kind,
  loading,
  onRetry,
}) => (
  <>
    <Title size="large">{FAILURE_CONTENT[kind].title}</Title>
    {FAILURE_CONTENT[kind].body}
    {(kind === Financing__FailureKind.Retriable || kind === Financing__FailureKind.Pending) && (
      <RetryButton type="button" size="large" disabled={loading} loading={loading} onClick={onRetry}>
        {RETRY_COPY}
      </RetryButton>
    )}
    {kind === Financing__FailureKind.Processed && (
      <BillingButton type="button" size="large" to={billingURL(BillingTabs.History)}>
        {BILLING_COPY}
      </BillingButton>
    )}
  </>
);
