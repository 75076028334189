import React, { useRef, useState } from 'react';
import * as Sentry from '@sentry/browser';

import { CouponCard } from '@portal/components/rewards/coupon_card';
import { useReferralHistoryQuery } from '@portal/schema';
import { CUSTOMER_ID } from '@portal/config/customer';
import { ShareModal } from '@portal/components/rewards/share_modal';

export const ReferralsCard = ({ source }: { source: string }) => {
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const { data, loading } = useReferralHistoryQuery({
    onCompleted: (d) => {
      if (!d?.referralHistory) {
        Sentry.captureMessage(`Could not find referral data for customer #${CUSTOMER_ID}`);
      }
    },
  });

  if (!data || loading) {
    return <>Loading</>;
  } else if (!data.referralHistory) {
    return <></>;
  }

  return (
    <div ref={modalContainerRef}>
      <ShareModal
        containerRef={modalContainerRef}
        hidden={!showShareModal}
        hideModal={() => setShowShareModal(false)}
        source={source}
      />
      <CouponCard
        referralCode={data.referralHistory.referralCode}
        showShareModal={() => setShowShareModal(!showShareModal)}
      />
    </div>
  );
};
