import { IIconProps } from '@portal/components/shared/icons/props';
import React from 'react';
import { snakeCase } from 'lodash';

export const MoverWithCartBoxes: React.FC<IIconProps> = ({ title, width = 50, height = 50 }) => (
  <svg
    aria-hidden={!title}
    aria-labelledby={title && `${snakeCase(title)}-svg`}
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title id={`${snakeCase(title)}-svg`}>{title}</title>}
    <path
      d="M23.0588 46.647L24.7647 46.1176L25.4706 47.2353L21.9412 49.2941C21.5294 49.5294 21 49.7058 20.4706 49.7058H19.353L18.0588 47.5294L19.7059 47.8235L20.5882 46.4117L23.0588 46.647Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.9317 24.4447L31.7678 27.9062L34.3599 37.0151L46.5238 33.5536L43.9317 24.4447Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.9412 28.8824L40 28"
      stroke="#00404D"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.5294 25.4117L31.7647 27.8823"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.7008 34.6856L32.5368 38.1471L35.1289 47.2559L47.2929 43.7944L44.7008 34.6856Z"
      fill="#FFEABF"
      stroke="#FFEABF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.7647 39.1176L40.8235 38.2353"
      stroke="#00404D"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.7059 34.647L32.5294 38.1176"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.4118 24.8235L25.4118 23.7059C26.1177 23.5294 26.8235 23.9412 27.0588 24.647L33.1177 46.4706C33.2941 47.1764 34.0588 47.5882 34.7647 47.4117L43.7059 44.8235C44.2941 44.647 44.8824 44.7647 45.4118 45.0588L46.4118 45.7059"
      stroke="#F79C94"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.4118 47.4706H30.4706C29.8823 47.4706 29.4118 46.8823 29.5294 46.2941L30.7647 39.8824C30.8824 39.1765 30.8824 38.4706 30.7059 37.7647L30.2941 36.2353"
      stroke="#F79C94"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7059 25.647L17.1765 22.7059L18.2353 24.1176V26.7647C18.2353 27.7647 18.0588 28.7059 17.8235 29.7059L15.5882 38.0588L10.2941 35.9412L12.7059 25.647Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.82353 43.7647L4.88235 44.7647"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.7059 45.8823L7.05884 49.2353C7.35296 49.5294 7.76473 49.7059 8.23531 49.7059H9.58825V48.5294L8.17649 47.8823L7.88237 45.647"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.82355 43.7647L6.00002 45.2941L7.88237 45.7059L8.17649 47.9412L9.58825 48.5882V49.1177V49.7647H8.23531C7.82355 49.7647 7.35296 49.5882 7.05884 49.2941L3.7059 45.8824L5.82355 43.7647Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0588 25.4706L10.2353 23.7059H15.5294L17.0588 24.8823L16.5882 27.4706L18.0588 37.7059L12.1177 39.2941L10.2353 29.4118C9.94118 28.1176 9.88236 26.7647 10.0588 25.4706Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.7059 45.8823L7.05884 49.2353C7.35296 49.5294 7.76473 49.7058 8.23531 49.7058H9.58825"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.00002 44.2353L14.1177 38.0588L10.2941 35.8823L5.82355 43.7647"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8236 7.94116C12.1177 8.47057 13.4118 8.99999 14.7059 9.47057C15.8824 10.5294 17.5883 12.0588 17.8824 15.4118L18.2353 24.1176L9.88238 23.5882L10.5294 20.8823C8.82355 14.8235 8.9412 11.4706 10.8236 7.94116Z"
      fill="#00A499"
      stroke="#00A499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4706 13.7647C16 14.4706 16.3529 15.5882 16.5882 16.9412L22.2941 22.4118H22.7059C22.9412 22.4118 23.1765 22.4706 23.4118 22.5882H23.4706C23.7059 22.7059 23.9412 22.7059 24.1765 22.7647L25.1176 22.8235C25.1176 23.1177 24.8824 23.3529 24.5882 23.3529L23.7647 23.5294L24 23.8824L24.6471 24.6471L23 25.7647C22.7059 25.9412 22.3529 25.8235 22.1176 25.5294L21.1765 24.3529C21.1765 24.3529 13.2353 18 14.1765 18.7647L12 15.7059L15.4706 13.7647Z"
      fill="white"
    />
    <path
      d="M15.9412 7.70585C16 7.47055 15.9412 7.23526 15.8824 6.99996C15.7647 6.76467 15.6471 6.5882 15.5294 6.35291C15.2941 6.41173 15.0588 6.41173 14.8235 6.35291"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7059 8.64706C14.2941 8.17647 14.2353 7.58824 14.2353 7.58824C13.7647 7.58824 13.2941 7.41177 12.9412 7.05883C12.5882 6.70589 12.4118 6.2353 12.4118 5.76471"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2353 3.23529C15.3529 3.41176 15.4118 3.64706 15.4118 3.88235V4.23529L16.2353 5.29411C16.1176 5.41176 15.8824 5.52941 15.7059 5.52941"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7647 2.52938C15.0588 2.52938 15.3529 2.29409 15.4706 1.99997C15.5882 1.70585 15.4706 1.35291 15.2941 1.11761"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3529 7.58822C11.4706 6.76469 11.1177 5.88234 10.4706 5.2941C9.64706 4.47057 9.2353 2.94116 10.5882 1.94116"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7647 2.52939L12.7647 2.41174"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.6471 24.6471L23 25.7647C22.7059 25.9412 22.353 25.8235 22.1177 25.5294L21.1765 24.3529L18.2353 22.2353L14.1765 18.7647"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1176 12.647C11.1765 13.7059 11.4118 14.8235 12.0588 15.7059L14.2353 18.7647"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.4706 47.2353L21.9412 49.2941C21.5294 49.5294 21 49.7059 20.4706 49.7059H19.353"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.1177 49.7059H0.294128"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.0588 21.2353L22.353 22.4706H22.7647C23 22.4706 23.2353 22.5294 23.4706 22.6471H23.5294C23.7647 22.7647 24 22.7647 24.2353 22.8235L25.1765 22.8824C25.1765 23.1765 24.9412 23.4118 24.6471 23.4118L23.7647 23.5294"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0588 47.5294L13.0588 39.1176L17.1765 37.9412L19.7647 45"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.8824 25.8823L16.8235 26.3529C16.7059 27.1176 16.6471 27.8823 16.7647 28.647L17.1765 31.5294"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.4706 49.1764C30.7376 49.1764 31.7647 48.1493 31.7647 46.8823C31.7647 45.6153 30.7376 44.5882 29.4706 44.5882C28.2036 44.5882 27.1765 45.6153 27.1765 46.8823C27.1765 48.1493 28.2036 49.1764 29.4706 49.1764Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.4706 47.7059C29.9254 47.7059 30.2941 47.3372 30.2941 46.8824C30.2941 46.4275 29.9254 46.0588 29.4706 46.0588C29.0158 46.0588 28.6471 46.4275 28.6471 46.8824C28.6471 47.3372 29.0158 47.7059 29.4706 47.7059Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.353 1.23528C14.1177 0.764694 13.6471 0.411753 13.1177 0.294106C12.5882 0.176459 12.0588 0.294106 11.5882 0.588224C11.1177 0.882341 10.7059 1.29411 10.1765 1.41175"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.2941 17.8823L33.1765 19.7059L35.0588 26.3529L33.6471 26.7647L31.7647 20.1176L29.8824 18.2941L31.2941 17.8823Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.9412 15.7059L40.8235 17.5294L42.7059 24.1765L41.7059 24.4706L39.8235 17.8235L37.9412 16L38.9412 15.7059Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.2353 23.4705L29.6471 27.8823C29.2353 27.9999 28.8235 27.7647 28.7059 27.3529L27.1176 21.7647C27.0588 21.5882 27.0588 21.3529 27.1765 21.1764L27.7647 20.1176C27.8824 19.9411 28.0588 19.8235 28.2353 19.7647L42.1765 15.7647C42.3529 15.7058 42.5882 15.7058 42.7647 15.8235L43.8235 16.4117C44 16.5294 44.1176 16.7058 44.1765 16.8823L45.7647 22.4705C45.8824 22.9411 45.6471 23.3529 45.2353 23.4705Z"
      fill="#00A499"
      stroke="#00A499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.7059 24.1764L40.8824 17.8235C40.8235 17.647 40.8235 17.4117 40.9412 17.2353L41.5294 16.1764C41.6471 16 41.8235 15.8823 42 15.8235"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.1177 18.5882L40.9412 18C40.8824 17.7059 40.5882 17.5882 40.2941 17.647L27.4706 21.2941C27.1765 21.3529 27.0588 21.647 27.1176 21.9411"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.2353 16.8823L37.3529 18.5294L39.2353 25.1764L40.2353 24.8823L38.3529 18.2353L39.2353 16.647L38.2353 16.8823Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.5294 16.1176L36.9412 18.647L38.8235 25.2941L40.2353 24.8823L38.3529 18.2353L38.9412 15.7059L37.5294 16.1176Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.1176 19.2353L29.2353 20.8235L31.1765 27.4705L32.5882 27.0588L30.6471 20.4117L31.5882 18.8235L30.1176 19.2353Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.8823 18.2941L29.2353 20.8235L31.1765 27.4706L32.5882 27.0588L30.6471 20.4117L31.2941 17.8823L29.8823 18.2941Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.9412 16L30.647 18.0588L29.8823 18.2941L29.8235 17.0588L38.4117 14.5882L38.9412 15.7058L37.9412 16Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.4706 16.1176L31.2941 17.8823L30.6471 20.4117L32.5882 27.0588"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.2353 24.8823L38.3529 18.2353L38.9412 15.7059"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.1765 15.7646L42.8823 18.2941"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.8824 18.2941L42.6471 20.0588L44 19.7058L42.8824 18.2941Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
