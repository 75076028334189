import { COLORS, FontWeight, Text, TextButton, transparentize } from '@clutter/clean';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Table } from '@portal/components/shared/table';
import { Currency } from '@shared/components/helpers';

export enum BannerKind {
  Primary,
  Danger,
}

export const bannerStyle = css`
  border-radius: 4px;
  padding: 16px;
  margin: 0 16px;
`;

const styles: Record<BannerKind, SerializedStyles> = {
  [BannerKind.Primary]: css`
    background: ${COLORS.tealBackground};
  `,
  [BannerKind.Danger]: css`
    background: ${transparentize(COLORS.flower, 0.4)};
  `,
};

const Callout = styled(Text.Callout)`
  color: ${COLORS.panther};
  padding-bottom: 4px;
`;

const Caption = styled(Text.Caption)`
  color: ${COLORS.panther};
`;

const Container = styled.div<{ kind: BannerKind }>`
  ${bannerStyle}
  ${({ kind }) => styles[kind]};
`;

const Row = styled.tr`
  td {
    border: none;
    padding: 0;
  }
`;

const ButtonRow = styled.tr`
  vertical-align: bottom;
  td {
    border: none;
    padding: 0;
    padding: 16px 0 0;
    vertical-align: bottom;
  }
`;

export const Base: React.FC<{
  title: string;
  description: string;
  amount: number;
  kind: BannerKind;
  buttonText?: string;
  onButtonClick?(): void;
}> = ({ kind, title, description, amount, buttonText, onButtonClick }) => (
  <Container kind={kind}>
    <Table>
      <tbody>
        <Row>
          <td>
            <Callout weight={FontWeight.Medium}>{title}</Callout>
            <Caption>{description}</Caption>
          </td>
          <td>
            <Callout weight={FontWeight.Medium}>
              <Currency value={amount} />
            </Callout>
          </td>
        </Row>
        {buttonText && (
          <ButtonRow>
            <td>
              <TextButton
                size="small"
                kind={kind === BannerKind.Danger ? 'destructive' : 'primary'}
                onClick={onButtonClick}
              >
                {buttonText}
              </TextButton>
            </td>
            <td />
          </ButtonRow>
        )}
      </tbody>
    </Table>
  </Container>
);
