import React from 'react';
import styled from '@emotion/styled';

import { Select, Label, Text, COLORS } from '@clutter/clean';

import boxesIcon from '@portal/images/estimation/boxes_step.svg';
import {
  Estimation__CategoryType,
  OrderTypeEnum,
  Status,
  useEstimationCreateBoxEstimatedItemsMutation,
} from '@portal/schema';
import { Spacer } from '@shared/components/helpers';

import { StepType } from './data';
import { StepContainer } from '../step_container';

const buildBoxesOptions = (boxCategories: Estimation__CategoryType[]) => {
  const categories = boxCategories.map(({ name }) => ({
    label: name,
    value: name,
  }));
  categories.unshift({ label: '-', value: '' });
  return categories;
};

const Header = styled.div`
  text-align: center;
  margin-bottom: 32px;
`;

const Form = styled.div`
  max-width: 480px;
  margin: 0 auto;
`;

const Icon = styled.img`
  margin-bottom: 8px;
`;

const Caption = styled(Text.Caption)`
  color: ${COLORS.storm};
  margin-top: 8px;
`;

export const Boxes: React.FC<StepType> = (props) => {
  const {
    values: { boxCategoryName },
    onChange,
    boxCategories,
    setError,
    orderID,
    orderType,
  } = props;

  const [createBoxes, { loading }] = useEstimationCreateBoxEstimatedItemsMutation();

  return (
    <StepContainer
      {...props}
      loading={loading}
      next={async () => {
        if (!boxCategoryName) return;
        const { data } = await createBoxes({
          variables: {
            orderID,
            boxCategoryName,
          },
        });
        if (data?.estimationCreateBoxEstimatedItems?.status === Status.Ok) {
          props.next();
        } else {
          setError('Failed to save estimated boxes');
        }
      }}
      canNext={!!boxCategoryName}
    >
      <Header>
        <Icon src={boxesIcon} />
        <Text.Title size="large">
          How many boxes will you {orderType === OrderTypeEnum.Pickup ? 'store' : 'move'}?
        </Text.Title>
        <Spacer height="8px" />
        <Text.Body style={{ color: COLORS.hippo }}>
          Take your best guess at how many cardboard boxes you'll store.
        </Text.Body>
      </Header>
      <Form>
        <Label>Estimated Boxes</Label>
        <Select
          value={boxCategoryName}
          onChange={(value: any) => onChange('boxCategoryName', value)}
          options={buildBoxesOptions(boxCategories!)}
        />
        <Caption>It’s okay to use your best guess</Caption>
      </Form>
    </StepContainer>
  );
};
