import React from 'react';

import { Options } from '@portal/components/plan/update/options';
import { Flexer, Mover, Saver } from '@portal/components/shared/icons';
import { Pricing__RateGroup__Commitment } from '@portal/schema';

import { CommitmentOption } from './selector';
import { currency } from '../util';

const CommitmentSticker = {
  [Pricing__RateGroup__Commitment.Flexer]: Flexer,
  [Pricing__RateGroup__Commitment.Mover]: Mover,
  [Pricing__RateGroup__Commitment.Saver]: Saver,
};

export const Option: React.FC<{
  option: CommitmentOption;
  selected: boolean;
  onSelect(label: string): void;
}> = ({ option, selected, onSelect }) => {
  const { commitmentType, pricingSetEntry, minimumMonths } = option;

  const icon = CommitmentSticker[commitmentType];

  let description;
  if (minimumMonths > 0) {
    description = `Store for a minimum of ${minimumMonths} months`;
    if (commitmentType === Pricing__RateGroup__Commitment.Saver) {
      description = `${description} & save`;
    }
  } else {
    description = 'Store month to month without a minimum';
  }

  let formattedPrice;
  let label = `${commitmentType} ${description}.`;

  if (pricingSetEntry) {
    formattedPrice = `${currency(pricingSetEntry.price.amount || 0)}/mo`;
    label += ' ' + formattedPrice;
  }

  return (
    <Options.Card
      title={commitmentType}
      description={`${description}.`}
      price={formattedPrice}
      selected={selected}
      onSelect={() => onSelect(label)}
      icon={icon}
    />
  );
};
