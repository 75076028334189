import React, { useState } from 'react';
import { DateTime } from 'luxon';

import { Box, Button, Input, mq } from '@clutter/clean';
import styled from '@emotion/styled';

import { useTrack } from '@clutter/wt';
import { EventSchema, Portal__DateRequested } from '@shared/wt';
import { Header } from './header';

const Container = styled(Box.Flex)`
  ${mq({
    flexDirection: ['column', 'row'],
  })}
  gap: 8px;
`;

export const DateSelector: React.FC<{ onDate(date: DateTime): void }> = ({ onDate }) => {
  const [selectedDate, setSelectedDate] = useState<string>('');
  const track = useTrack();

  return (
    <Container justifyContent="space-between" alignItems="baseline">
      <Header tag="h5">Select date</Header>
      <Container>
        <Input
          id="date_selector"
          name="date_selector"
          type="date"
          min={DateTime.local().toISODate()}
          value={selectedDate}
          onChange={(event) => setSelectedDate(event.target.value)}
        />
        <Button
          disabled={!selectedDate}
          onClick={() => {
            const requestParams: Portal__DateRequested = {
              schema: EventSchema.Portal__DateRequested,
              action: 'click',
              metadata: { date_requested: selectedDate },
            };
            track(requestParams);

            onDate(DateTime.fromISO(selectedDate));
          }}
        >
          Check Availability
        </Button>
      </Container>
    </Container>
  );
};
