import * as React from 'react';

export enum Fit {
  Crop = 'crop',
  Fill = 'fill',
}

export enum Crop {
  Faces = 'faces',
  Focalpoint = 'focalpoint',
}

const DEFAULT_FM = 'jpg';

interface IPictureProps {
  source: string;
  width: number;
  height: number;
  background?: string;
  fit?: Fit;
  crop?: Crop;
}

const RATIOS = [
  1, // i.e. 1 × density (default)
  2, // i.e. 2 × density
  3, // i.e. 3 × density
];

// NOTE: uses the imgix formatting options documented here: https://docs.imgix.com/apis/url/format
const format = (source: string, w: number, h: number, bg?: string, fit?: Fit, crop?: Crop) => {
  const options: any = { w, h, bg, fit, crop, fm: DEFAULT_FM };
  const query = Object.keys(options)
    .filter((key) => options[key] !== undefined)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(options[key])}`)
    .join('&');
  return `${source}?${query}`;
};

type IPictureDefaults = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const Picture = ({ source, width, height, background, fit, crop, ...defaults }: IPictureProps & IPictureDefaults) => (
  <img
    {...defaults}
    src={format(source, width, height, background, fit)}
    srcSet={RATIOS.map((r) => `${format(source, width * r, height * r, background, fit, crop)} ${r}x`).join(', ')}
    height={height}
    width={width}
  />
);

export { Picture };
