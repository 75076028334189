import { COLORS } from '@clutter/clean';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { bodyStyle } from '@portal/components/shared/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spacer } from '@shared/components/helpers';

export type Size = 'large' | 'medium';
export type Kind = 'primary' | 'secondary';

type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
type AnchorProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

interface IButtonStyleProps {
  size: Size;
  kind: Kind;
  disabled?: boolean;
}

const sizeStyles: Record<Size, SerializedStyles> = {
  large: css`
    padding: 15px 32px;
  `,
  medium: css`
    padding: 11px 32px;
  `,
};

const disabledStyle = css`
  :disabled {
    color: ${COLORS.cloud};
    background: ${COLORS.grayBorder};
    border: 1px solid ${COLORS.grayBorder};
  }
`;

const hoverStyle = css`
  :not(:disabled):hover {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
    text-decoration: none;
  }
`;

const focusStyle = css`
  &:focus {
    outline: 2px solid ${COLORS.tealPrimary};
    outline-offset: 2px;
  }
  :focus-within {
    outline: 2px solid ${COLORS.tealPrimary};
    outline-offset: 2px;
  }
`;

const kindStyles: Record<Kind, SerializedStyles> = {
  primary: css`
    color: ${COLORS.cloud};
    background: ${COLORS.tealPrimary};
    border: 1px solid ${COLORS.tealPrimary};
    ${disabledStyle} :not (:disabled):hover {
      color: ${COLORS.cloud};
    }
  `,
  secondary: css`
    color: ${COLORS.tealPrimary};
    background: ${COLORS.cloud};
    border: 1px solid ${COLORS.tealPrimary};
    ${disabledStyle} :not (:disabled):hover {
      color: ${COLORS.tealPrimary};
    }
  `,
};

const StyledButton = styled.button<IButtonStyleProps>`
  ${({ size }) => sizeStyles[size]}
  ${({ kind }) => kindStyles[kind]}
  ${bodyStyle}
  font-weight: bold;
  outline: none;
  display: block;
  text-align: center;
  ${hoverStyle}
  ${focusStyle}
`;

export const Button: React.FC<
  ButtonProps &
    IButtonStyleProps & {
      loading?: boolean;
    }
> = ({ size, kind, disabled = false, loading = false, className, children, ...other }) => (
  <StyledButton size={size} kind={kind} disabled={disabled || loading} className={className} {...other}>
    {loading && (
      <>
        <FontAwesomeIcon icon="spinner" spin />
        <Spacer width="8px" inline />
      </>
    )}
    {children}
  </StyledButton>
);

export const AnchorButton: React.FC<AnchorProps & IButtonStyleProps> = ({
  size,
  kind,
  disabled = false,
  className,
  children,
  ...other
}) => {
  const Wrapper = StyledButton.withComponent('a');

  return (
    <Wrapper size={size} kind={kind} disabled={disabled} className={className} {...other}>
      {children}
    </Wrapper>
  );
};
