import { Duration } from 'luxon';

export const ALL_DAY_SLA_START_HOUR = 7;
export const ALL_DAY_SLA_END_HOUR_24 = 15;
const ALL_DAY_SLA_END_HOUR = 3;

export const ALL_DAY_DURATION = Duration.fromObject({ hours: 8 });

export const ALL_DAY_SLA_START = `${ALL_DAY_SLA_START_HOUR} AM`;
export const ALL_DAY_SLA_END = `${ALL_DAY_SLA_END_HOUR} PM`;

export const NO_SLA_WINDOW_SHORT = 'By 3:00 PM';

export const isAllDaySLA = (from: number, till: number) =>
  from <= ALL_DAY_SLA_START_HOUR && till >= ALL_DAY_SLA_END_HOUR_24;
