import { Box, FontWeight, Radio, Text } from '@clutter/clean';
import React, { useState } from 'react';

import {
  ContactFragment,
  CustomerFragment,
  Maybe,
  Order__ContactKind,
  Order__PickupVehicleKind,
  OrderServiceTypeEnum,
} from '@portal/schema';
import { Button } from '@shared/components/bootstrap';
import { Phone } from '@shared/components/helpers/phone';

import { DISPLAYABLE_VEHICLE_MAPPING, KIND_RADIO_OPTIONS, VEHICLE_RADIO_OPTIONS } from './util';

export const OrderContactForm: React.FC<{
  serviceType?: Maybe<OrderServiceTypeEnum>;
  customer: CustomerFragment;
  savedVehicleKind?: Maybe<Order__PickupVehicleKind>;
  contact?: Maybe<ContactFragment>;
  loading: boolean;
  onSubmit(name: string, phone: string, kind?: Order__ContactKind, pickupVehicleKind?: Order__PickupVehicleKind): void;
}> = ({ serviceType, customer, savedVehicleKind, contact, loading, onSubmit }) => {
  const [name, setName] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [kind, setKind] = useState<Order__ContactKind>();
  const [vehicleKind, setVehicleKind] = useState<Order__PickupVehicleKind>();

  const person: ContactFragment | CustomerFragment = contact || customer;

  const isFacility = serviceType === OrderServiceTypeEnum.Facility;
  const validFacility = kind !== undefined && vehicleKind !== undefined;
  const valid = name !== undefined && phone !== undefined && (!isFacility || validFacility);

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (loading) {
      return;
    }
    if (!valid) {
      return;
    }
    onSubmit(name, phone, kind, vehicleKind);
  };

  return (
    <form onSubmit={submit}>
      <Text.Body weight={FontWeight.Medium}>Confirmed Contact</Text.Body>
      <Text.Body>
        {person.name} - {<Phone value={person.phone} />}
        {contact?.kind === Order__ContactKind.MovingCompany && ' (Moving Company)'}
        {savedVehicleKind && <Text.Body>Vehicle type: {DISPLAYABLE_VEHICLE_MAPPING[savedVehicleKind]}</Text.Body>}
      </Text.Body>
      <hr />
      <Text.Body weight={FontWeight.Medium}>New Contact</Text.Body>

      <div className="form-group">
        <label className="sr-only">Name:</label>
        <input
          type="text"
          name="name"
          placeholder="Name"
          className="form-control"
          value={name || ''}
          onChange={(event) => setName(event.target.value || undefined)}
        />
      </div>

      <div className="form-group">
        <label className="sr-only">Phone:</label>
        <input
          type="tel"
          name="phone"
          placeholder="Phone"
          className="form-control"
          value={phone || ''}
          onChange={(event) => setPhone(event.target.value || undefined)}
        />
      </div>

      {isFacility && (
        <>
          <Box margin="32px 0">
            <Text.Body weight={FontWeight.Medium}>Is this a moving company?</Text.Body>
            <Radio.Selector<Order__ContactKind>
              name="contactKind"
              onChange={setKind}
              value={kind || undefined}
              options={KIND_RADIO_OPTIONS}
            />
          </Box>
          <Box margin="32px 0">
            <Text.Body weight={FontWeight.Medium}>What type of vehicle are you bringing to your appointment?</Text.Body>
            <Radio.Selector<Order__PickupVehicleKind>
              name="vehicleKind"
              onChange={setVehicleKind}
              value={vehicleKind || undefined}
              options={VEHICLE_RADIO_OPTIONS}
            />
          </Box>
        </>
      )}

      <div className="form-group">
        <Button kind="primary" type="submit" disabled={!valid || loading}>
          Save Changes
        </Button>
      </div>
    </form>
  );
};
