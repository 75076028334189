import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { onboardingIncentiveGCURL } from '@portal/config/routes';

import { Theme } from '@portal/components/helpers/theme';

import { OnboardingIncentive } from './gift_cards/onboarding_incentive';

export const GiftCard: React.FC = () => (
  <Theme name="default">
    <Switch>
      <Route exact path={onboardingIncentiveGCURL()} component={OnboardingIncentive} />
    </Switch>
  </Theme>
);
