import * as React from 'react';

import { Button } from '@shared/components/bootstrap';

import { addressEditURL } from '@portal/config/routes';

import { Address, useDestroyAddressMutation } from '@portal/schema';

const RemoveButton: React.FC<{
  address: Pick<Address, 'id'>;
  onCompleted(): void;
}> = ({ address, onCompleted }) => {
  const [destroy, { loading }] = useDestroyAddressMutation({ variables: { id: address.id }, onCompleted });

  return (
    <Button kind="link" loading={loading} onClick={() => destroy()}>
      Remove
    </Button>
  );
};

export const Table: React.FC<{
  addresses: Array<Pick<Address, 'id' | 'aptsuite' | 'street' | 'city' | 'state' | 'zip'>>;
  onDestroy(address: Pick<Address, 'id'>): void;
}> = ({ addresses, onDestroy }) => (
  <div className="table-responsive">
    <table className="table table-striped">
      <tbody>
        {addresses.map((address) => (
          <tr key={address.id}>
            <td className="col-fill col-nowrap">
              {address.street} {address.aptsuite}, {address.city}, {address.state} {address.zip}
            </td>
            <td className="col-zero col-nowrap text-right">
              <a className="btn btn-link" href={addressEditURL(address.id)}>
                Edit
              </a>
              <span>|</span>
              <RemoveButton address={address} onCompleted={() => onDestroy(address)} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);
