import { IInventoryResource } from './inventory_resource';
import { IPallet } from './pallet';
import { IWarehouse } from './warehouse';

export enum InventorySectionMode {
  Pallets,
  Warehouses,
}

export interface IPalletInventorySection {
  mode: InventorySectionMode.Pallets;
  pallet?: IPallet;
  resources: IInventoryResource[];
}

export interface IWarehouseInventorySection {
  mode: InventorySectionMode.Warehouses;
  warehouse?: IWarehouse;
  resources: IInventoryResource[];
}

export type IInventorySection = IPalletInventorySection | IWarehouseInventorySection;
