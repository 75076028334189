import { capitalize } from 'lodash';
import React from 'react';

import { Premium, Standard } from '@portal/components/shared/icons';
import {
  Pricing__Plan__ProtectionClassification,
  PricingSetPriceEntryFragment,
  ProtectionPlanFragment,
} from '@portal/schema';

import { Options } from '../options';
import { currency } from '../util';

const ProtectionIcon = {
  [Pricing__Plan__ProtectionClassification.Standard]: Standard,
  [Pricing__Plan__ProtectionClassification.Premium]: Premium,
};

export const Option: React.FC<{
  option: PricingSetPriceEntryFragment;
  selected: boolean;
  onSelect(label: string): void;
  onRemove(): void;
}> = ({ option, selected, onSelect, onRemove }) => {
  const plan = option.price.plan as ProtectionPlanFragment;
  const title = plan.classification ? capitalize(plan.classification) : plan.name;
  const description = `Protects up to ${currency(plan.maxCovered)} of total item value.`;
  const label = `${title} ${description}`;

  return (
    <Options.Card
      title={title}
      description={description}
      price={`${currency(option.price.amount)}/mo`}
      selected={selected}
      onSelect={() => onSelect(label)}
      onRemove={onRemove}
      icon={plan.classification ? ProtectionIcon[plan.classification] : Standard}
    />
  );
};
