import React, { useState } from 'react';

import { Logo } from '@portal/components/shared/logo';
import { loginURL, makespaceConnectURL } from '@portal/config/routes';
import ForkliftAndFacility from '@portal/images/forklift_and_facility.svg';
import { useFirstTimeCustomerQuery } from '@portal/schema';
import { FontWeight, mq, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { Spacer } from '@shared/components/helpers';
import useTimeout from '@shared/hooks/use_timeout';

import { Link, Redirect } from 'react-router-dom';
import { Container as BaseContainer } from '../shared/layout';
import { Spinner } from '../helpers';

const Container = styled(BaseContainer)`
  ${mq({
    flexDirection: ['column', 'row'],
  })}
`;

const LogoContainer = styled.div`
  ${mq({
    display: ['none', 'none', 'none', 'flex'],
  })}
`;

const TextContainer = styled.div``;

const ImageContainer = styled.img`
  ${mq({
    maxWidth: ['90%', '50%'],
    marginLeft: ['0px', '4rem'],
    marginTop: ['4rem', '0rem'],
  })}
`;

export const Landing: React.FC<{
  email: string;
  initialSetupToken: string;
}> = ({ email, initialSetupToken }) => {
  const [autoRedirect, setAutoRedirect] = useState<boolean>(false);

  const { data, loading } = useFirstTimeCustomerQuery({
    variables: {
      initialSetupToken: initialSetupToken,
    },
    fetchPolicy: 'no-cache',
  });

  const loginSearchParams = new URLSearchParams({ email }).toString();

  useTimeout(() => {
    setAutoRedirect(true);
  }, 5000);

  if (loading) {
    return <Spinner />;
  }

  const redirectLocation = () => {
    if (data?.firstTimeCustomer) {
      return makespaceConnectURL();
    } else {
      return { pathname: loginURL(), search: loginSearchParams };
    }
  };

  if (autoRedirect) {
    return <Redirect to={redirectLocation()}></Redirect>;
  }

  return (
    <>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Container hasNavbar={false}>
        <TextContainer>
          <Text.Callout weight={FontWeight.Medium}>MakeSpace now powered by Clutter</Text.Callout>
          <Spacer height="1rem" />
          <Text.Title size="medium">
            We've worked with Clutter to transfer your Makespace account information!
          </Text.Title>
          <Spacer height="1.5rem" />
          <Text.Body weight={FontWeight.Regular}>
            You can now access your account through Clutter’s website. You will be automatically redirected to a login
            page, or you can <Link to={redirectLocation()}>click here</Link>.
          </Text.Body>
        </TextContainer>
        <ImageContainer src={ForkliftAndFacility}></ImageContainer>
      </Container>
    </>
  );
};
