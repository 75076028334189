import React, { useState } from 'react';

import { Text } from '@clutter/clean';
import styled from '@emotion/styled';

import { TextButton } from '@portal/components/shared/clean/text_button';
import { Billing__EarlyClosureFeeInvoice, InvoiceFragment } from '@portal/schema';
import { Modal } from '@shared/components/bootstrap';

import { List } from './list';

const Container = styled.div`
  padding: 0px 24px 24px;
`;

const CloseContainer = styled.div`
  padding-top: 8px;
  padding-right: 12px;
`;

const StyledTextButton = styled(TextButton)`
  margin: 10px 0 0;
  padding: 0;
`;

type Invoice = Billing__EarlyClosureFeeInvoice | InvoiceFragment;

interface IProps {
  invoices: Invoice[];
  title?: string;
}

const InvoiceModal: React.FC<
  IProps & {
    onClose(): void;
  }
> = ({ invoices, title, onClose }) => (
  <Modal onClose={onClose}>
    <Modal.Content>
      <CloseContainer>
        <Modal.Close close={onClose} />
      </CloseContainer>
      <Container>
        {title && <Text.Title size="small">{title}</Text.Title>}
        <List invoices={invoices} />
      </Container>
    </Modal.Content>
  </Modal>
);

export const ViewButton: React.FC<IProps> = ({ invoices, title }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <>
      <StyledTextButton onClick={() => setShowModal(true)}>VIEW INVOICES</StyledTextButton>
      {showModal && <InvoiceModal invoices={invoices} title={title} onClose={() => setShowModal(false)} />}
    </>
  );
};
