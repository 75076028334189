import { COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';

import { TrackedClick } from '@portal/components/wt/tracked_click';
import { planUpdateURL } from '@portal/config/routes';

const SmallCaps = styled(Text.SmallCaps)`
  color: ${COLORS.tealPrimary};
`;

export const PlanUpdateLink: React.FC<{ container: string; onboardingID: string | undefined }> = ({
  container,
  onboardingID,
}) => {
  const label = 'UPDATE';
  const href = planUpdateURL();
  return (
    <TrackedClick
      params={{
        pageName: 'portal:account_details',
        container,
        action: 'click',
        objectType: 'link',
        objectName: 'update_plan',
        label,
        value: href,
        order_id: onboardingID,
      }}
    >
      <Link to={href}>
        <SmallCaps>{label}</SmallCaps>
      </Link>
    </TrackedClick>
  );
};
