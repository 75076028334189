import { COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { TrackedLink } from '@portal/components/wt/tracked_link';
import { supportURL } from '@portal/config/routes';
import { Maybe, UpcomingStorageOnboardingFragment } from '@portal/schema';
import { IEventParams } from '@portal/types/wt/event';

const Title = styled(Text.Title)`
  color: ${COLORS.panther};
  padding-bottom: 12px;
`;

const Body = styled(Text.Body)`
  color: ${COLORS.panther};
  margin-bottom: 28px;
`;

const TextLink = styled.a`
  color: ${COLORS.tealPrimary};
`;

export const UnableToUpdate: React.FC<{
  onboarding?: Maybe<UpcomingStorageOnboardingFragment>;
}> = ({ onboarding }) => {
  let message;
  if (onboarding?.started) {
    message =
      'Your appointment already started, which means you can no longer make changes to your ' +
      'appointment details or Storage Plan.';
  } else {
    message = 'This appointment has already passed, which means you can no longer make changes.';
  }

  const linkParams: IEventParams = {
    pageName: 'portal:plan_update',
    action: 'click',
    objectType: 'link',
    objectName: 'contact_support',
    label: 'Customer Support',
    value: supportURL(),
  };
  if (onboarding) {
    linkParams.order_id = onboarding.id;
  }

  return (
    <>
      <Title size="large">Update your plan</Title>
      <Body>{message}</Body>
      <Body>
        If you have a concern, please reach out to our{' '}
        <TrackedLink params={linkParams}>
          <TextLink href={supportURL()}>Customer Support</TextLink>
        </TrackedLink>
        .
      </Body>
    </>
  );
};
