import * as React from 'react';

import { Restrictions } from '@portal/components/addresses/form/buildings/restrictions';
import {
  AddressDetailField,
  APARTMENT_BUILDING_SUBTYPE_OPTIONS,
  IAddressDetail,
  IAddressValidationErrors,
} from '@shared/types';
import { Access } from './access';
import { AdditionalNotes } from './additional_notes';
import { BuildingFloor } from './building_floor';
import { Subtype } from './subtype';
import { SquareFeet } from './square_feet';
import { GatedCommunity } from './gated_community';
import { LongCarry } from './long_carry';

export const Apartment: React.FC<{
  details: IAddressDetail;
  errors?: IAddressValidationErrors;
  setDetails(details: IAddressDetail): void;
}> = ({ details, errors, setDetails }) => (
  <>
    <Subtype
      value={details.buildingSubtype}
      label="What type of apartment building?"
      options={APARTMENT_BUILDING_SUBTYPE_OPTIONS}
      error={errors?.[AddressDetailField.BuildingSubtype]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.BuildingSubtype]: value })}
    />
    <BuildingFloor
      details={details}
      error={errors?.[AddressDetailField.Floor]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.Floor]: value })}
    />
    <SquareFeet
      value={details[AddressDetailField.SquareFeet]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.SquareFeet]: value })}
    />
    <Access details={details} error={errors?.access} onChange={(values) => setDetails({ ...details, ...values })} />
    <LongCarry
      value={details[AddressDetailField.LongCarry]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.LongCarry]: value })}
    />
    <GatedCommunity
      value={details[AddressDetailField.Gated]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.Gated]: value })}
    />
    <Restrictions
      details={details}
      onDetail={setDetails}
      error={errors?.[AddressDetailField.BuildingRestrictionsAnswer]}
      initialBuildingRestrictionsAnswer={details[AddressDetailField.BuildingRestrictionsAnswer]}
    />
    <AdditionalNotes
      value={details[AddressDetailField.AdditionalNotes]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.AdditionalNotes]: value })}
    />
  </>
);
