import { round } from 'lodash';

import {
  IEstimatedItem,
  IEstimationCategory,
  IEstimationSelection,
  IOrderInventory,
  IRoomCategory,
  IRoomSelection,
} from '@shared/types';

const DEFAULT_SELECTION = {
  quantity: 0,
  selected: false,
};

const CUFT_WITH_SINGLE_REQUESTED_MOVERS = 5;
const CUFT_WITH_MULTIPLE_REQUESTED_MOVERS = 10;

const roomsCUFT = (selections?: Map<IRoomCategory, Partial<IRoomSelection>>): number => {
  if (!selections) {
    return 0.0;
  }

  let cuft = 0.0;
  for (const category of Array.from(selections.keys())) {
    const { quantity = DEFAULT_SELECTION.quantity, selected = DEFAULT_SELECTION.selected } =
      selections.get(category) || DEFAULT_SELECTION;
    if (category.countable) {
      cuft += category.cuft * quantity;
    } else {
      cuft += selected ? category.cuft : 0.0;
    }
  }
  return cuft;
};

const estimationsCUFT = (selections?: Map<IEstimationCategory, Partial<IEstimationSelection>>): number => {
  if (!selections) {
    return 0.0;
  }

  let cuft = 0.0;
  for (const category of Array.from(selections.keys())) {
    const { quantity = DEFAULT_SELECTION.quantity, selected = DEFAULT_SELECTION.selected } =
      selections.get(category) || DEFAULT_SELECTION;
    if (category.packable) {
      cuft += selected ? category.cuft : 0;
    } else {
      cuft += category.cuft * quantity;
    }
  }
  return cuft;
};

const CUBIC_INCHES_IN_CUBIC_FOOT = 1728;
const cubicInchesToFeet = (cubicInches: number): number => round(cubicInches / CUBIC_INCHES_IN_CUBIC_FOOT, 2);

const estimatedItemsCUFT = (requestedMovers?: number, items?: IEstimatedItem[]): number => {
  if (!items) {
    return 0;
  }
  const cuftPer =
    requestedMovers && requestedMovers > 1 ? CUFT_WITH_MULTIPLE_REQUESTED_MOVERS : CUFT_WITH_SINGLE_REQUESTED_MOVERS;

  let totalCuft = 0.0;
  items.forEach((item) => {
    let cuft = cuftPer;
    if (item.length && item.width && item.height) {
      cuft = cubicInchesToFeet(item.length * item.width * item.height);
    } else if (item.cuft) {
      cuft = item.cuft;
    }
    totalCuft += item.quantity * cuft;
  });
  return totalCuft;
};

export const cuftForOrderInventory = (inventory: IOrderInventory): number => {
  const { extraItems, rooms, packableEstimations, unpackableEstimations, requestedMovers } = inventory;

  return (
    roomsCUFT(rooms) +
    estimationsCUFT(packableEstimations) +
    estimationsCUFT(unpackableEstimations) +
    estimatedItemsCUFT(requestedMovers, extraItems)
  );
};
