import { Button, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { SmallOffice } from '@portal/components/shared/icons/small_office';
import { ordersURL } from '@portal/config/routes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  padding-bottom: 24px;
`;

const Callout = styled(Text.Callout)`
  text-align: center;
  padding-bottom: 24px;
`;

const ButtonLink = styled.a`
  display: block;
  width: 100%;
  max-width: 400px;
  &:hover {
    text-decoration: none;
  }
`;

export const Onboarded: React.FC = () => (
  <Container>
    <ImageContainer>
      <SmallOffice />
    </ImageContainer>
    <Callout>More details on this account will be available after your first appointment.</Callout>
    <ButtonLink href={ordersURL()}>
      <Button kind="secondary" fullWidth>
        View Upcoming Appointment
      </Button>
    </ButtonLink>
  </Container>
);
