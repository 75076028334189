import React from 'react';

const ID = 'premium-protection';

export const Premium: React.FC<{ title?: string }> = ({ title = 'Premium Protection' }) => (
  <svg aria-labelledby={ID} width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title id={ID}>{title}</title>
    <path
      d="M61.5742 39.0095V49.4963H69.7351L66.4707 44.0693L69.7351 39.0095H61.5742Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.38507 49.4963V39.0095H1.22415L4.48852 44.4365L1.22415 49.4963H9.38507Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.1207 42.2739H10.446H64.8385V52.7607H6.1207V42.2739Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.2793 60.2443C12.1598 52.6546 6.1207 40.5765 6.1207 27.9271V13.1966C6.1207 11.9806 7.41012 11.189 8.49552 11.7439L13.4655 14.2738C18.6069 14.2738 23.4463 12.2744 27.0779 8.63464L33.7699 1.94268C34.7247 0.987856 36.2753 0.987856 37.2301 1.94268L43.9221 8.63464C47.5618 12.2744 52.3931 14.2738 57.5345 14.2738L62.5045 11.7439C63.5899 11.189 64.8793 11.9806 64.8793 13.1966V27.9271C64.8793 40.6418 58.8892 52.6138 48.7207 60.2443L35.5 70.1598L22.2793 60.2443Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.5 60.9788L26.6862 54.3684C18.3621 48.1253 13.4655 38.3322 13.4655 27.9271V21.6105C20.5165 21.6105 27.2819 18.8113 32.2764 13.8168L35.5 10.5933L38.7236 13.8168C43.7099 18.8031 50.4753 21.6105 57.5345 21.6105V27.9271C57.5345 38.3322 52.6379 48.1253 44.3138 54.3684L35.5 60.9788Z"
      fill="#00A499"
      stroke="#00A499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5268 53.2504C19.6025 47.3011 14.8692 37.8345 14.8692 27.9271V22.9816C21.8223 22.6388 28.3102 19.7743 33.2639 14.8125L35.5 12.5764L37.7361 14.8125C42.6898 19.7662 49.1777 22.6388 56.1308 22.9816V27.9271C56.1308 37.8345 51.3975 47.3011 43.4732 53.2504L35.5 59.2324L27.5268 53.2504Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.5345 27.9271V21.6105C50.4834 21.6105 43.718 18.8113 38.7236 13.8168L35.5 10.5933L32.2764 13.8168C27.2901 18.8031 20.5247 21.6105 13.4655 21.6105V27.9271"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1085 70.1599H50.8915"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.3474 34.9209C53.6907 42.1678 49.5123 48.721 43.4814 53.2503L35.5082 59.2323L27.535 53.2503C21.504 48.7292 17.3256 42.1678 15.669 34.9209"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.161 20.8107L37.5892 23.6997L40.7801 24.1649C41.384 24.2546 41.6288 24.9973 41.1882 25.4216L38.8786 27.6659L39.4254 30.8405C39.5315 31.4444 38.8949 31.9014 38.3563 31.6158L35.5082 30.1142L32.66 31.6158C32.1214 31.9014 31.4848 31.4444 31.5909 30.8405L32.1377 27.6659L29.8282 25.4216C29.3875 24.9973 29.6323 24.2546 30.2362 24.1649L33.4271 23.6997L34.8553 20.8107C35.1083 20.2639 35.8917 20.2639 36.161 20.8107Z"
      fill="white"
    />
    <path
      d="M41.18 25.4216L38.8705 27.6659L39.4172 30.8405C39.5233 31.4444 38.8868 31.9014 38.3482 31.6158L35.5 30.1142L32.6518 31.6158C32.1132 31.9014 31.4767 31.4444 31.5828 30.8405L32.1295 27.6659L29.82 25.4216"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.2858 32.8644L29.7139 35.7534L32.9048 36.2186C33.5087 36.3083 33.7536 37.051 33.3129 37.4753L31.0033 39.7196L31.5501 42.8942C31.6562 43.4981 31.0197 43.9551 30.481 43.6695L27.6329 42.1679L24.7847 43.6695C24.2461 43.9551 23.6095 43.4981 23.7156 42.8942L24.2624 39.7196L21.9529 37.4753C21.5122 37.051 21.757 36.3083 22.3609 36.2186L25.5518 35.7534L26.98 32.8644C27.233 32.3177 28.0164 32.3177 28.2858 32.8644Z"
      fill="white"
    />
    <path
      d="M33.3047 37.4753L30.9952 39.7196L31.542 42.8942C31.6481 43.4981 31.0115 43.9551 30.4729 43.6695L27.6247 42.1679L24.7766 43.6695C24.238 43.9551 23.6014 43.4981 23.7075 42.8942L24.2543 39.7196L21.9447 37.4753"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.0363 32.8644L45.4645 35.7534L48.6554 36.2186C49.2593 36.3083 49.5041 37.051 49.0635 37.4753L46.7539 39.7196L47.3007 42.8942C47.4068 43.4981 46.7702 43.9551 46.2316 43.6695L43.3835 42.1679L40.5353 43.6695C39.9967 43.9551 39.3601 43.4981 39.4662 42.8942L40.013 39.7196L37.7035 37.4753C37.2628 37.051 37.5076 36.3083 38.1115 36.2186L41.3024 35.7534L42.7306 32.8644C42.9836 32.3177 43.767 32.3177 44.0363 32.8644Z"
      fill="white"
    />
    <path
      d="M49.0553 37.4753L46.7458 39.7196L47.2926 42.8942C47.3986 43.4981 46.7621 43.9551 46.2235 43.6695L43.3753 42.1679L40.5272 43.6695C39.9885 43.9551 39.352 43.4981 39.4581 42.8942L40.0049 39.7196L37.6953 37.4753"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.8793 27.927C64.8793 40.6417 58.8892 52.6138 48.7207 60.2442L35.5 70.1598L22.2793 60.2442C12.1598 52.6546 6.1207 40.5764 6.1207 27.927"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5069 48.0355C14.2082 52.7443 17.8561 56.9309 22.2793 60.2442L22.8506 60.6767C26.3516 61.044 30.5218 61.2725 35.5 61.2725C40.4782 61.2725 44.6484 61.044 48.1494 60.6767L48.7207 60.2442C53.1766 56.8982 56.8326 52.7198 59.5339 48.011C55.2331 49.3657 47.8148 50.7775 35.5 50.7775C23.2423 50.7775 15.824 49.382 11.5069 48.0355Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.0632 27.927C64.0632 40.3153 58.1466 52.1568 48.231 59.5914L35.5 69.1396L22.769 59.5914C12.8535 52.1568 6.9368 40.3234 6.9368 27.927"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.1207 42.2739C6.1207 42.2739 11.7354 47.5214 35.5 47.5214C59.2646 47.5214 64.8385 42.2739 64.8385 42.2739V52.7689C64.8385 52.7689 59.2646 58.0163 35.5 58.0163C11.7354 58.0163 6.1207 52.7689 6.1207 52.7689V42.2739Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.8385 42.2739V52.7689C64.8385 52.7689 59.2646 58.0163 35.5 58.0163C11.7354 58.0163 6.1207 52.7689 6.1207 52.7689V42.2739"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.93677 27.9267V13.1963C6.93677 12.6903 7.36114 12.3802 7.75286 12.3802C7.87527 12.3802 8.00585 12.4128 8.1201 12.4699L13.2696 15.0814H13.4655C18.8272 15.0814 23.8625 12.9922 27.6573 9.20557L34.3493 2.51362C34.6594 2.2035 35.0675 2.04028 35.5 2.04028C35.9325 2.04028 36.3487 2.21166 36.6507 2.51362L43.3426 9.20557C47.1293 12.9922 52.1727 15.0814 57.5345 15.0814H57.7303L62.8799 12.4699C62.9941 12.4128 63.1247 12.3802 63.2471 12.3802C63.6388 12.3802 64.0632 12.6903 64.0632 13.1963V27.9267"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
