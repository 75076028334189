import * as React from 'react';

import { FlashKind, IFlash } from '@shared/types';

import { Linkify } from './linkify';

const styling = (kind: FlashKind) => {
  switch (kind) {
    case FlashKind.Danger:
      return 'alert-danger';
    case FlashKind.Info:
      return 'alert-info';
    case FlashKind.Success:
      return 'alert-success';
    case FlashKind.Warning:
      return 'alert-warning';
  }
};

export const Flash: React.FC<{
  flash: IFlash;
  onClose?(flash: IFlash): void;
}> = ({ flash, onClose }) => (
  <div className={`alert ${styling(flash.kind)}`}>
    {onClose && (
      <button
        className="close"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onClose(flash);
        }}
      >
        <i className="fa fa-times-circle" />
      </button>
    )}
    {typeof flash.message === 'string' ? <Linkify text={flash.message} /> : flash.message}
  </div>
);
