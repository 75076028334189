import styled from '@emotion/styled';
import React, { useState } from 'react';

import { SERIF_FONT_FAMILY } from '@portal/constants/fonts';
import { ItemPricingFragment, OrderInput, OrderTypeEnum, useTermsForBookingOrderQuery } from '@portal/schema';
import { currency, WHOLE_NUMBER_OPTIONS } from '@shared/utils';
import { Button as CustomButton, Footer } from './base';
import { isPositive } from '../per_item_info_footer';

const TextContainer = styled.div`
  padding: 16px 24px;
  max-width: 1140px;
  @media (min-width: 600px) {
    margin: 0 auto;
  }
`;

const ButtonContainer = styled.div`
  padding: 16px 24px;
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  @media (min-width: 600px) {
    margin: 0 auto;
  }
`;

const ConfirmHeading = styled.h2`
  font-size: 24px;
  font-weight: 600;
  padding: 16px 0;
  font-family: ${SERIF_FONT_FAMILY};
`;

const BottomPadding = styled.div`
  margin-bottom: 100px;
  @media (max-width: 600px) {
    margin-bottom: 200px;
  }
`;
interface IPerItemPricingConfirmFooter {
  pricingInfo: ItemPricingFragment;
  type: OrderTypeEnum;
  input: OrderInput;
  loading?: boolean;
  onPrev(): void;
  onSave(): void;
}

export const PerItemPricingConfirmFooter = ({
  onPrev,
  onSave,
  loading,
  pricingInfo,
  type,
  input,
}: IPerItemPricingConfirmFooter) => {
  const [showTerms, setShowTerms] = useState<boolean>(false);

  const { data } = useTermsForBookingOrderQuery({ variables: { input } });

  const accountPackageCosts = pricingInfo.accountPackages.map(
    (accountPackage) =>
      `${currency(accountPackage.amount, WHOLE_NUMBER_OPTIONS)} ${accountPackage.name.toLocaleLowerCase()} charge`,
  );

  const appointmentCost = [
    isPositive(pricingInfo.appointmentFee) && pricingInfo.appointmentFee,
    pricingInfo.pickupPerItemFee && `${pricingInfo.pickupPerItemFee}/picked up item requiring 1 mover`,
    pricingInfo.pickupLargeItemFee && `${pricingInfo.pickupLargeItemFee}/picked up item requiring 2+ movers`,
    pricingInfo.returnPerItemFee && `${pricingInfo.returnPerItemFee}/returned item requiring 1 mover`,
    pricingInfo.returnLargeItemFee && `${pricingInfo.returnLargeItemFee}/returned item requiring 2+ movers`,
  ]
    .concat(accountPackageCosts)
    .filter(Boolean)
    .join(' + ');
  return (
    <>
      {showTerms ? <BottomPadding /> : null}
      <Footer>
        {showTerms ? (
          <>
            <TextContainer>
              <ConfirmHeading>Confirm terms to book</ConfirmHeading>
              You agree to pay a service charge of {appointmentCost} for your appointment.
              {type !== OrderTypeEnum.Return
                ? ` If during your appointment you give us more items for pick-up than scheduled, you agree to pay
                ${pricingInfo.pickupPerItemFee} for each additional item requiring 1 mover and ${pricingInfo.pickupLargeItemFee} for each additional item requiring 2+ movers. `
                : ' '}
              {data?.terms && <>{data.terms}</>}
            </TextContainer>
            <ButtonContainer>
              <CustomButton onClick={() => setShowTerms(false)} className="secondary">
                Back
              </CustomButton>
              <CustomButton
                loading={loading}
                loadingLabel={'Booking Appointment...'}
                onClick={onSave}
                className="primary"
              >
                Confirm and book
              </CustomButton>
            </ButtonContainer>
          </>
        ) : (
          <ButtonContainer>
            <CustomButton onClick={onPrev} className="secondary">
              Back
            </CustomButton>
            <CustomButton loading={loading} onClick={() => setShowTerms(true)} className="primary">
              Looks good
            </CustomButton>
          </ButtonContainer>
        )}
      </Footer>
    </>
  );
};
