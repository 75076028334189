import React from 'react';
import styled from '@emotion/styled';

import { Button, UnstyledButton, Text } from '@clutter/clean';
import BackArrow from '@portal/images/back_arrow.svg';

import { StepType } from './data';

const Container = styled.div`
  margin-top: 40px;
  padding-bottom: 80px;

  // Override global Account Portal styles
  label {
    margin-bottom: 0;
  }

  // Disable text & image highlighting on click
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const Header = styled.div`
  margin-bottom: 32px;
`;

const Headline = styled(Text.Title)`
  text-align: center;
  margin-bottom: 8px;
`;

const Subheading = styled(Text.Body)`
  text-align: center;
`;

const Next = styled(Button)`
  max-width: 600px;
`;

const Footer = styled.div<{ hide?: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  padding: 16px 24px;
  box-shadow: 0px 2px 20px rgba(194, 194, 194, 0.25);
  background-color: white;
  z-index: 1;
`;

const FooterContent = styled.div<{ canPrev?: boolean }>`
  justify-content: ${({ canPrev }) => (canPrev ? 'space-between' : 'center')};
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
`;

export const StepContainer: React.FC<StepType> = ({
  canPrev,
  canNext,
  prev,
  next,
  nextLabel,
  hideNav,
  headline,
  subheading,
  children,
}) => (
  <Container>
    {(headline || subheading) && (
      <Header>
        {headline && <Headline size="large">{headline}</Headline>}
        {subheading && <Subheading>{subheading}</Subheading>}
      </Header>
    )}
    {children}
    <Footer hide={hideNav}>
      <FooterContent canPrev={canPrev}>
        {canPrev && (
          <UnstyledButton onClick={prev}>
            <img src={BackArrow} />
          </UnstyledButton>
        )}
        <Next onClick={next} disabled={!canNext} fullWidth={!canPrev}>
          {nextLabel ?? 'Next'}
        </Next>
      </FooterContent>
    </Footer>
  </Container>
);
