import { DateTime } from 'luxon';
import React from 'react';

import { PaymentMethodSelection } from '@portal/components/orders/steps/confirm/payment_method_selection';
import { TermCommitmentBalance } from '@portal/components/orders/steps/confirm/term_commitment_balance';

export const TermCommitmentPayment: React.FC<{
  scheduled: DateTime;
  selectedSourceID?: string;
  setSelectedSourceID(sourceID: string): void;
  error?: string;
}> = ({ scheduled, selectedSourceID, setSelectedSourceID, error }) => (
  <>
    <TermCommitmentBalance scheduled={scheduled} />
    <PaymentMethodSelection
      prompt={'This card will be used to charge the term commitment balance.'}
      selectedSourceID={selectedSourceID}
      setSelectedSourceID={setSelectedSourceID}
      error={error}
    />
  </>
);
