import { client } from '@portal/libraries/apollo';
import { AvailabilitiesInput, AvailableForMoversDocument, AvailableForMoversQuery } from '@portal/schema';

type AvailableForMovers = {
  availableForMovers?: boolean | null;
};

export const fetchAvailableForMovers: (input: AvailabilitiesInput) => Promise<AvailableForMovers | null> = async (
  input,
) => {
  try {
    const { data } = await client.query<AvailableForMoversQuery>({
      query: AvailableForMoversDocument,
      variables: { input },
    });
    return data;
  } catch {
    return null;
  }
};
