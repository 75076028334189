import React, { useState } from 'react';
import styled from '@emotion/styled';

import { Box, COLORS, FontWeight, Select, Text, UnstyledButton } from '@clutter/clean';
import { Minus, Plus } from '@portal/components/shared/icons/estimation';
import { ArrowDown, ArrowUp } from '@portal/components/shared/icons';
import {
  Estimation__AlgorithmName,
  Estimation__CategoryType,
  Estimation__ResourceType,
  Order__Metadata__ItemsRequireMoreThanOneMover as RequireMoreThanOneMoverEnum,
  useEstimationCreateItemsMutation,
} from '@portal/schema';
import boxes from '@portal/images/estimation/boxes_step.svg';
import { Spacer } from '@shared/components/helpers';

import { capitalize } from 'lodash';
import { CATEGORY_TO_ITEM_DETAILS, StepType } from './data';
import { StepContainer } from '../step_container';

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 660px;
  margin: 0 auto;
  @media (min-width: 768px) {
    justify-content: left;
    & > :nth-child(odd) {
      margin-right: 16px;
    }
  }
`;

const Examples = styled.div<{ wrap?: boolean }>`
  color: ${COLORS.hippo};
  padding: 24px;
  ul {
    padding: 9px 0 0 9px;
    display: flex;
    flex-wrap: wrap;
  }
  li {
    flex-basis: calc(50% - 9px);
    margin-left: 9px;
    div {
      margin-right: 12px;
    }
  }
`;

const ExampleCTA = styled(Text.Callout)`
  color: ${COLORS.hippo};
  cursor: pointer;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 660px;
  margin: 32px auto;
  align-items: left;
  flex-direction: column;
  @media (min-width: 768px) {
    justify-content: left;
    & > :nth-child(odd) {
      margin-right: 16px;
    }
  }
`;

const QuestionTitle = styled(Text.Title)`
  margin-bottom: 16px;
`;

const buildOtherItemOptions = (otherItemCategories: Estimation__CategoryType[]) => {
  const categories = otherItemCategories.map(({ name }) => ({
    label: name,
    value: name,
  }));
  categories.unshift({ label: '-', value: '' });
  return categories;
};

const buildRequireMoreThanOneMoverOptions = () => {
  const orderedOptions = [
    RequireMoreThanOneMoverEnum.Yes,
    RequireMoreThanOneMoverEnum.No,
    RequireMoreThanOneMoverEnum.NotSure,
  ];
  const options: Array<{
    label: string;
    value: string;
  }> = orderedOptions.map((option) => ({
    label: option.split('_').map(capitalize).join(' '),
    value: option,
  }));
  options.unshift({ label: '-', value: '' });
  return options;
};

export const Items: React.FC<StepType> = (props) => {
  const {
    values: { selectedItemCounts = {}, boxCategoryName, otherItemCategoryName, itemsRequireMoreThanOneMover },
    onChange,
    orderID,
    itemCategories,
    otherItemCategories,
  } = props;
  const [examplesExpanded, setExamplesExpanded] = useState<string>('');
  const canNext =
    !!Object.entries(selectedItemCounts).find(([_, value]) => !!value) &&
    otherItemCategoryName !== undefined &&
    !!itemsRequireMoreThanOneMover;

  const [createItems, { loading }] = useEstimationCreateItemsMutation();

  return (
    <StepContainer
      {...props}
      loading={loading}
      headline="Are you moving any of these items?"
      subheading="Let us know if you are storing any of these items so that we can better plan for your appointment."
      canNext={canNext}
      next={async () => {
        const { data } = await createItems({
          variables: {
            input: {
              resourceType: Estimation__ResourceType.Order,
              resourceID: orderID,
              algorithmName: Estimation__AlgorithmName.V1,
              otherItemCategoryName: otherItemCategoryName,
              itemsRequireMoreThanOneMover: itemsRequireMoreThanOneMover as RequireMoreThanOneMoverEnum,
              itemInputs: Object.entries(selectedItemCounts)
                .filter(([_, quantity]) => !!quantity)
                .map(([categoryID, quantity]) => ({
                  categoryID,
                  quantity,
                })),
            },
          },
        });
        if (data) {
          props.next();
        }
      }}
    >
      <CardContainer>
        {itemCategories?.map(({ id, name }, i) => {
          const { icon, tagline, examples } = CATEGORY_TO_ITEM_DETAILS[name];
          const isExpanded = examplesExpanded === id;
          const itemCount = selectedItemCounts[id] || 0;

          return (
            <Box
              key={id}
              width="322px"
              height="min-content"
              overflow="hidden"
              margin="0 0 24px"
              borderRadius="4px"
              style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}
            >
              <div>
                <img src={icon} />
              </div>
              <Box.Flex
                padding="12px 24px"
                background={COLORS.cloud}
                justifyContent="space-between"
                alignItems="center"
                style={{ borderBottom: isExpanded ? `1px solid ${COLORS.grayBorder}` : undefined }}
              >
                <div>
                  <Text.Body weight={FontWeight.Medium}>{name}</Text.Body>
                  <ExampleCTA onClick={() => setExamplesExpanded(isExpanded ? '' : id)}>
                    See examples
                    <Spacer width="6px" inline />
                    {isExpanded ? (
                      <ArrowUp width="12px" height="7px" strokeColor={COLORS.hippo} />
                    ) : (
                      <ArrowDown width="12px" height="7px" strokeColor={COLORS.hippo} />
                    )}
                  </ExampleCTA>
                </div>
                <Box.Flex justifyContent="space-between" alignItems="center">
                  {!!itemCount && (
                    <UnstyledButton
                      onClick={() => {
                        const quantity = itemCount - 1;
                        onChange('selectedItemCounts', {
                          ...selectedItemCounts,
                          [id]: quantity,
                        });
                      }}
                    >
                      <Minus iconColor={COLORS.cloud} />
                    </UnstyledButton>
                  )}
                  {!!itemCount && (
                    <Text.Title size="extraSmall" style={{ width: '36px', textAlign: 'center' }}>
                      {itemCount}
                    </Text.Title>
                  )}
                  <UnstyledButton
                    onClick={() => {
                      const quantity = itemCount + 1;
                      onChange('selectedItemCounts', {
                        ...selectedItemCounts,
                        [id]: quantity,
                      });
                    }}
                    data-test-id={`items-add-${i}`}
                  >
                    <Plus
                      fillColor={itemCount ? undefined : COLORS.cloud}
                      iconColor={itemCount ? undefined : COLORS.tealPrimary}
                    />
                  </UnstyledButton>
                </Box.Flex>
              </Box.Flex>
              {isExpanded && (
                <Examples>
                  <Text.Callout>{tagline}</Text.Callout>
                  <ul>
                    {examples.map((example: string, idx: number) => (
                      <li key={idx}>
                        <Text.Callout>{example}</Text.Callout>
                      </li>
                    ))}
                  </ul>
                </Examples>
              )}
            </Box>
          );
        })}
        <Box
          width="322px"
          height="min-content"
          overflow="hidden"
          margin="0 0 24px"
          borderRadius="4px"
          style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}
        >
          <div>
            <img
              width="322px"
              height="150px"
              style={{ backgroundColor: '#E8F6F5' }}
              src={boxes + '#svgView(viewBox(-109,-5,322,134))'}
            />
          </div>
          <Box.Flex padding="12px 24px" background={COLORS.cloud} justifyContent="space-between" alignItems="center">
            <div>
              <Text.Body weight={FontWeight.Medium}>Boxes</Text.Body>
              <ExampleCTA>Selected from previous step</ExampleCTA>
            </div>
            <Box.Flex justifyContent="space-between" alignItems="center">
              <Text.Title size="extraSmall" style={{ width: '72px', textAlign: 'center' }}>
                {!boxCategoryName || boxCategoryName === 'None' ? 0 : boxCategoryName}
              </Text.Title>
            </Box.Flex>
          </Box.Flex>
        </Box>
      </CardContainer>
      <QuestionContainer>
        <QuestionTitle size="extraSmall">
          Roughly how many additional items do you plan to store that are not listed above?
        </QuestionTitle>
        <Select
          name="other_items"
          options={buildOtherItemOptions(otherItemCategories!)}
          value={otherItemCategoryName}
          onChange={(value: any) => onChange('otherItemCategoryName', value)}
        />
      </QuestionContainer>
      <QuestionContainer>
        <QuestionTitle size="extraSmall">Do any of your items require more than 1 person to carry?</QuestionTitle>
        <Select
          name="items_require_more_than_one_mover"
          options={buildRequireMoreThanOneMoverOptions()}
          value={itemsRequireMoreThanOneMover}
          onChange={(value: any) => onChange('itemsRequireMoreThanOneMover', value)}
        />
      </QuestionContainer>
    </StepContainer>
  );
};
