import React from 'react';

import { Theme } from '@portal/components/helpers/theme';

import { Details as AccountDetails } from './account/details';

export const Account = () => (
  <Theme name="default">
    <AccountDetails />
  </Theme>
);
