import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Events as AppEvents } from '@portal/components/app/events';
import { Flashes } from '@portal/components/app/flashes';
import { Footer } from '@portal/components/app/footer';
import { Header, HEADER_HEIGHT } from '@portal/components/app/header';
import { DialogManager } from '@portal/components/dialog_manager';
import { CUSTOMER_ID } from '@portal/config/customer';
import {
  activateURL,
  downsizeURL,
  loginURL,
  logoutURL,
  ordersURL,
  planUpdateURL,
  resetPasswordURL,
  reviewURL,
  supportURL,
  signedOrderSignaturesURL,
  makespaceURL,
  planAddURL,
  baseRescheduleOfferURL,
} from '@portal/config/routes';
import { ExperimentsContextProvider } from '@portal/contexts/experiments_context';
import { AbeetProvider } from '@clutter/abeet';
import { ABEET_HOST } from '@portal/config/abeet';

export const NAV_BAR_HEIGHT = HEADER_HEIGHT;
const GLOBAL_VERTICAL_PADDING = 48;
// Side padding is set to 9px to offset 15px side padding from global container, reaching 24px.
export const CONTAINER_SIDE_PADDING = 9;

const StyledContainer = styled.div<{ fixedHeight: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 ${CONTAINER_SIDE_PADDING}px;
  min-height: calc(100vh - ${(props) => props.fixedHeight}px);
`;

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Container: React.FC<
  DivProps & {
    hasNavbar?: boolean;
  }
> = ({ hasNavbar = true, children, ...other }) => {
  const fixedHeight = GLOBAL_VERTICAL_PADDING + (hasNavbar ? HEADER_HEIGHT : 0);
  return (
    <StyledContainer fixedHeight={fixedHeight} {...other}>
      {children}
    </StyledContainer>
  );
};

const HEADERLESS_URLS = [
  new RegExp(`^${activateURL()}`),
  new RegExp(`^${loginURL()}`),
  new RegExp(`^${logoutURL()}`),
  new RegExp(`^${resetPasswordURL()}`),
  new RegExp(`^${makespaceURL()}`),
];

const FOOTERLESS_URLS = [
  new RegExp(`^${activateURL()}`),
  new RegExp(`^${downsizeURL()}`),
  new RegExp(`^${ordersURL()}/steps/.*`),
  new RegExp(`^${ordersURL()}/\\d+/reschedule`),
  new RegExp(`^${ordersURL()}/\\d+/estimated_items`),
  new RegExp(`^${ordersURL()}/\\d+/estimation_flow`),
  new RegExp(`^${ordersURL()}/\\d+/virtual_walkthrough`),
  new RegExp(`^${ordersURL()}/.*/address`),
  new RegExp(`^${ordersURL()}/\\d+/financing/checkout`),
  new RegExp(`^${planUpdateURL()}`),
  new RegExp(`^${reviewURL()}`),
  new RegExp(`^${supportURL()}`),
  new RegExp(`^${signedOrderSignaturesURL()}`),
  new RegExp(`^${makespaceURL()}`),
  new RegExp(`^${planAddURL()}`),
  new RegExp(`^${baseRescheduleOfferURL()}`),
];

const FOOTER_HEIGHT = 80;

const App = styled.div<{ showFooter: boolean }>`
  margin: 24px 0 24px 0;
  margin-bottom: ${(props) => (props.showFooter ? FOOTER_HEIGHT : 0) + 24}px;
`;

interface IAppContext {
  hideFooter(): void;
}

export const AppContext = React.createContext<IAppContext>({ hideFooter: () => {} });

const isFooterlessURL = (pathname: string) => FOOTERLESS_URLS.some((regex) => regex.test(pathname));
const isHeaderlessURL = (pathname: string) => HEADERLESS_URLS.some((regex) => regex.test(pathname));

export const Layout: React.FC = ({ children }) => {
  const location = useLocation();
  const showHeader = !isHeaderlessURL(location.pathname + location.search);
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    setShowFooter(!isFooterlessURL(location.pathname));
  }, [location]);

  return (
    <AppContext.Provider
      value={{
        hideFooter: () => setShowFooter(false),
      }}
    >
      <AbeetProvider baseUrl={ABEET_HOST}>
        <ExperimentsContextProvider>
          {CUSTOMER_ID && <DialogManager />}
          <AppEvents />
          {showHeader && <Header />}
          <App id="app" showFooter={showFooter}>
            <main role="main" className="container">
              <Flashes />
              {children}
            </main>
          </App>
          {showFooter && <Footer />}
        </ExperimentsContextProvider>
      </AbeetProvider>
    </AppContext.Provider>
  );
};
