import { BREAKPOINTS, COLORS, Text, Button } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Accordion } from '@clutter/clean';
import { LaborCostDetailsFragment, LaborRateFragment } from '@portal/schema';

import { Questions } from './questions';

const PRICING_FAQ_URL = 'https://www.clutter.com/help/categories/1500000585701/sections/1500000936281';

const Container = styled.div`
  margin-top: 48px;
  @media (max-width: ${BREAKPOINTS.MD}) {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }
`;

const Title = styled(Text.Title)`
  color: ${COLORS.tealDark};
  text-align: center;
  padding-bottom: 16px;
  border-bottom: 1.5px solid ${COLORS.grayBorder};
`;

const ButtonLink = styled.a`
  display: block;
  margin: 24px auto 0;
  padding: 0 24px;
  max-width: 650px;
  &:hover {
    text-decoration: none;
  }
`;

export const FAQ: React.FC<{
  planUpdatable?: boolean;
  perItemPricing: boolean;
  laborRate?: LaborRateFragment;
  onboardingCostDetails?: LaborCostDetailsFragment;
  subsequentPickupCostDetails?: LaborCostDetailsFragment;
  subsequentReturnCostDetails?: LaborCostDetailsFragment;
  storeAndMoveCostDetails?: LaborCostDetailsFragment;
}> = ({
  planUpdatable,
  perItemPricing,
  laborRate,
  onboardingCostDetails,
  subsequentPickupCostDetails,
  subsequentReturnCostDetails,
  storeAndMoveCostDetails,
}) => (
  <Container>
    <Title size="small">Learn more about pricing</Title>
    <Accordion.Group>
      <Questions
        planUpdatable={planUpdatable}
        perItemPricing={perItemPricing}
        laborRate={laborRate}
        onboardingCostDetails={onboardingCostDetails}
        subsequentPickupCostDetails={subsequentPickupCostDetails}
        subsequentReturnCostDetails={subsequentReturnCostDetails}
        storeAndMoveCostDetails={storeAndMoveCostDetails}
      />
    </Accordion.Group>
    <ButtonLink href={PRICING_FAQ_URL}>
      <Button kind="secondary" size="small" fullWidth>
        View Pricing FAQ
      </Button>
    </ButtonLink>
  </Container>
);
