import { Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Billing__NestedInvoiceFragment } from '@portal/schema';
import { Table } from '@portal/components/shared/table';
import { Currency } from '@shared/components/helpers';

const StyledTitle = styled(Text.Title)`
  max-width: 500px;
  margin-top: 24px;
`;

const StyledTable = styled(Table)`
  max-width: 450px;
  margin-top: 16px;
`;

export const SettledInvoices: React.FC<{ invoices: Billing__NestedInvoiceFragment[] }> = ({ invoices }) => (
  <div>
    <StyledTitle size="medium">Settled Invoices</StyledTitle>
    <StyledTable>
      <tbody>
        {invoices.map((invoice, idx) => (
          <tr key={`invoice-${idx}`}>
            <td>
              <Text.Callout>{invoice.summary}</Text.Callout>
            </td>
            <td>
              <Text.Callout>
                <Currency value={invoice.amountDue} />
              </Text.Callout>
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  </div>
);
