import { createContext } from 'react';

import { AcceptClaimOfferInput } from '@portal/schema';

export type ClaimPaymentOptionInput = Partial<Pick<AcceptClaimOfferInput, 'email' | 'kind' | 'name' | 'address'>>;
export const ClaimPaymentContext = createContext<{
  data: ClaimPaymentOptionInput;
  onSelect(data: ClaimPaymentOptionInput): void;
}>({
  data: {},
  onSelect: () => {
    /* noop */
  },
});
