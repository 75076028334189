import React from 'react';

const ID = 'checkmark';

export const Checkmark: React.FC<{
  title?: string;
}> = ({ title = 'Check' }) => (
  <svg aria-labelledby={ID} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title id={ID}>{title}</title>
    <path
      d="M1.76311 7.63857L5.30112 10.2363L11.8255 1.55751"
      stroke="#ffffff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
