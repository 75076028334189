import { Button, COLORS, FontWeight, InfoModal, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { BillingTabs } from '@portal/components/billing/tabs';
import { CurrencyFormControl } from '@portal/components/shared/currency_form_control';
import { billingURL } from '@portal/config/routes';
import { DisplaySourceFragment, Maybe, Status, useBuildPrepaymentMutation } from '@portal/schema';

const Title = styled(Text.Title)`
  text-align: center;
  padding: 0 24px;
`;

const Description = styled(Text.Body)`
  margin-top: 16px;
  text-align: center;
`;

const Label = styled(Text.Body)`
  margin-top: 24px;
`;

const Caption = styled(Text.Caption)`
  color: ${COLORS.storm};
  margin-top: 8px;
`;

const StyledButton = styled(Button)`
  margin-top: 36px;
`;

const PREPAY_MODAL_TITLE = 'Prepay your upcoming bills';

const PrepayForm: React.FC<{ defaultSource: DisplaySourceFragment; onPrepayment(): void }> = ({
  defaultSource,
  onPrepayment,
}) => {
  const [amount, setAmount] = useState<number | undefined>(0);
  const [showError, setShowError] = useState<boolean>(false);
  const [build, { loading }] = useBuildPrepaymentMutation();

  const onConfirm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const response = await build({ variables: { amount: amount || 0 } });
    if (response.data?.buildPrepayment?.status === Status.Ok) {
      setAmount(undefined);
      onPrepayment();
    } else {
      setShowError(true);
    }
  };

  if (showError) {
    return (
      <>
        <Title size="large">Your payment failed</Title>
        <Description>
          We were unable to process your payment. Please confirm your payment method and try again.
        </Description>
        <StyledButton kind="primary" size="small" fullWidth onClick={() => setShowError(false)}>
          Try Again
        </StyledButton>
      </>
    );
  }

  return (
    <>
      <Title size="large">{PREPAY_MODAL_TITLE}</Title>
      <Description>Purchase credits to apply to your upcoming payments.</Description>
      <form onSubmit={onConfirm}>
        <Label weight={FontWeight.Medium}>Prepayment amount</Label>
        <CurrencyFormControl name="prepayment_amount" min="1" amount={amount} onChange={setAmount} />
        <Caption>
          Charged to default payment {defaultSource.brand} {defaultSource.number}
        </Caption>
        <StyledButton kind="primary" size="small" fullWidth disabled={!amount || loading} loading={loading}>
          Confirm
        </StyledButton>
      </form>
    </>
  );
};

export const Modal: React.FC<{
  isOpen: boolean;
  defaultSource?: Maybe<DisplaySourceFragment>;
  onSuccess(): void;
  onClose(): void;
}> = ({ isOpen, defaultSource, onSuccess, onClose }) => {
  const onPrepayment = () => {
    onClose();
    onSuccess();
  };
  return (
    <InfoModal isOpen={isOpen} handleModalClose={onClose}>
      {defaultSource ? (
        <PrepayForm defaultSource={defaultSource} onPrepayment={onPrepayment} />
      ) : (
        <>
          <Title size="large">{PREPAY_MODAL_TITLE}</Title>
          <Description>
            Prepayments can only be purchased using a default payment method. Please add a default credit or debit card
            then try again.
          </Description>
          <Link to={billingURL(BillingTabs.Payment)}>
            <StyledButton fullWidth kind="primary">
              Add Default Card
            </StyledButton>
          </Link>
        </>
      )}
    </InfoModal>
  );
};
