
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"pickupInventory"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OrderService"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isFullMoveOut"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"needsPackingHelp"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"requestedMovers"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"estimatedItems"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"estimatedItem"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"rooms"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"selected"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"quantity"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"categoryID"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"estimations"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"selected"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"quantity"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"categoryID"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":320}};
    doc.loc.source = {"body":"#import \"@portal/fragments/estimated_item.gql\"\n\nfragment pickupInventory on OrderService {\n  isFullMoveOut\n  needsPackingHelp\n  requestedMovers\n  estimatedItems {\n    ...estimatedItem\n  }\n  rooms {\n    id\n    selected\n    quantity\n    categoryID\n  }\n  estimations {\n    id\n    selected\n    quantity\n    categoryID\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("@portal/fragments/estimated_item.gql").definitions));


      module.exports = doc;
    
