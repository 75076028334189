import { FontWeight } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { supportURL } from '@portal/config/routes';
import { Text } from '@shared/components/bootstrap';

const LCSW_LINK = 'https://www.clutter.com/legal/limited_security_warranty';
const TOS_LINK = 'https://www.clutter.com/legal/tos';

const FAQContainer: React.FC = ({ children }) => (
  <div>
    <span className="claim-view__section-header">Frequently Asked Questions</span>
    {children}
  </div>
);

const FAQBlock = styled.div`
  margin-bottom: 30px;

  h3 {
    font-size: 1.1em;
    font-weight: 600;
  }
`;

export const ReviewFAQ: React.FC<{ hasIssue: boolean }> = ({ hasIssue }) => (
  <FAQContainer>
    {hasIssue ? (
      <>
        <FAQBlock>
          <Text tag="h3">Who reviews the claim?</Text>
          <Text tag="p">
            The Clutter Trust & Safety Team is responsible for completing a thorough investigation of all concerns and
            issues presented in the claim.
          </Text>
        </FAQBlock>
        <FAQBlock>
          <Text tag="h3">
            What does the Clutter Trust & Safety Team consider when making compensation determination?
          </Text>
          <Text tag="p">
            While each claim is investigated on its own merit, the Trust & Safety Team will utilize any of the
            following:
          </Text>
          <ul>
            <li>
              <a href={TOS_LINK}>Clutter’s Terms of Service</a>
            </li>
            <li>
              <a href={LCSW_LINK}>The Limited Security Warranty Policy</a>
            </li>
            <li>Customer provided invoices & receipts</li>
            <li>Customer provided statements</li>
            <li>Internal interviews & investigations</li>
            <li>Photos</li>
            <ul>
              <li>Customer provided</li>
              <li>Clutter internal documentation</li>
            </ul>
            <li>Contracts & Documents</li>
            <li>Email correspondence</li>
          </ul>
        </FAQBlock>
        <FAQBlock>
          <Text tag="h3">Who is the Clutter Trust & Safety Team, what are their qualifications?</Text>
          <Text tag="p">
            The Clutter Trust & Safety Team is composed of qualified Claims Professionals that have an extensive
            knowledge of Clutter’s Terms of Service, Limited Security Warranty Policy, applicable torts, tariffs, &
            National or Local Compliance requirements.
          </Text>
        </FAQBlock>
        <FAQBlock>
          <Text tag="h3">How long does it take for the Trust & Safety Team to render a decision?</Text>
          <Text tag="p">
            The Trust & Safety Team attempts to complete and communicate the results of the claim in 5-7 business days.
          </Text>
          <Text tag="p">
            Please note: While the majority of claims are completed within that timeframe, the complexity of an
            investigation or peak periods may cause delays.
          </Text>
        </FAQBlock>
      </>
    ) : (
      <>
        <FAQBlock>
          <Text tag="h3">When will I hear back about my claim?</Text>
          <Text tag="p">Claims are typically processed in 5-7 business days.</Text>
        </FAQBlock>
        <FAQBlock>
          <Text tag="h3">Under what circumstances will my claim be approved?</Text>
          <Text tag="p">
            Clutter’s claims specialists will review your claim for evidence of loss or damage. Claims are subject to
            our{' '}
            <a href={LCSW_LINK} target="_blank">
              Limited Security Warranty Policy
            </a>
            .
          </Text>
        </FAQBlock>
        <FAQBlock>
          <Text tag="h3">What happens if my claim is approved?</Text>
          <Text tag="p">
            If your claim is approved, you will receive notice via email and can visit this page for additional details.
            Claims will be paid only upon execution of a Claim Settlement Agreement.
          </Text>
        </FAQBlock>
        <FAQBlock>
          <Text tag="h3">What happens if my claim value is above the value of my Protection Plan?</Text>
          <Text tag="p">Claims will be approved only up to the maximum value of the Protection Plan held.</Text>
        </FAQBlock>
        <FAQBlock>
          <Text tag="h3">If my claim is approved, how will I be paid?</Text>
          <Text tag="p">
            If your claim is approved, you can elect to receive payment via PayPal or check.
            <br />
            <span style={{ fontWeight: FontWeight.Medium }}>PayPal</span> (5 days){' '}
            <span style={{ fontWeight: FontWeight.Medium }}>Check via mail</span> (30 days)
          </Text>
        </FAQBlock>
      </>
    )}
  </FAQContainer>
);

export const ApprovedFAQ: React.FC<{ hasIssue: boolean }> = ({ hasIssue }) => (
  <FAQContainer>
    {hasIssue ? (
      <FAQBlock>
        <Text tag="h3">What if I disagree with the final determination result of the claim?</Text>
        <Text tag="p">
          The goal of the Trust & Safety team is to complete a single investigation and provide an accurate Request for
          Compensation resolution. Typically, the compensation decision is final, but if you feel that evidence was
          overlooked or a policy was misinterpreted, please click <a href={supportURL()}>here</a> to start direct
          written correspondence with the Trust & Safety Team.
        </Text>
        <Text tag="p">
          Please Note: For accuracy, quality, & compliance purposes the Trust & Safety Team primarily conducts
          communication in written form.
        </Text>
      </FAQBlock>
    ) : (
      <>
        <FAQBlock>
          <Text tag="h3">How is my claim evaluated?</Text>
          <Text tag="p">
            An agent goes through each item of the claim using the information you provided to determine the validity
            and severity of the claimed damage or loss. Each item receives the following classifications:
          </Text>
          <ul>
            <li>Minor damage</li>
            <li>Major damage</li>
            <li>Total loss</li>
            <li>Not damaged</li>
            <li>Pre-existing damage</li>
          </ul>
        </FAQBlock>
        <FAQBlock>
          <Text tag="h3">How is my Approved Payment amount determined?</Text>
          <Text tag="p">
            If your item is determined to be a "Total loss" then you are covered for the entire value of the item up to
            the maximum amount allotted under your Protection Plan. If you purchased additional coverage under your
            Protection Plan and the additional coverage limits are met, coverage on any subsequent item is capped at a
            maximum amount of $1/lb per article. Additional coverage under the selected Protection Plan belongs to your
            account and not your individual claim.
          </Text>

          <Text tag="p">
            Any item that is not determined to be a "Total loss" is covered at a portion of the value of the item
            depending on the severity of the damage itself.
          </Text>

          <Text tag="p">
            In no case will Clutter payout any amount for an item that is "Not damaged", had "Pre-existing damage", or
            is not covered under{' '}
            <a href={LCSW_LINK} target="_blank">
              Clutter's Limited Security Warranty Policy
            </a>
            .
          </Text>
        </FAQBlock>
        <FAQBlock>
          <Text tag="h3">How is the value of my item determined?</Text>
          <Text tag="p">
            The value of an item is determined by the information you provided when filing a claim for the item itself.
            If no link was provided or the link does not clearly display the value of the item, then the value of the
            item is capped at $1/lb per article.
          </Text>
        </FAQBlock>
        <FAQBlock>
          <Text tag="h3">What does each classification mean under evaluation results?</Text>

          <Text weight="bold">Not damaged</Text>
          <ul>
            <li>
              The photos provided by the customer or photographed by the mover do not display visible damage that can be
              seen from the item's normal resting position.
            </li>
          </ul>

          <Text weight="bold">Pre-existing damage</Text>
          <ul>
            <li>
              The photos provided by the customer or photographed by the mover show damage that was documented when the
              item was initially picked up.
            </li>
          </ul>

          <Text weight="bold">Minor damage</Text>
          <ul>
            <li>Item can still fulfill its primary function.</li>
            <li>
              Item displays minor cosmetic damage from transport that can be seen from its normal resting position.
            </li>
          </ul>

          <Text weight="bold">Major damage</Text>
          <ul>
            <li>Item can still perform its primary function with minor repairs by the customer.</li>
            <li>
              Item displays major cosmetic damage from transport that can be seen from its normal resting position.
            </li>
            <li>
              Item has either missing hardware, loose hardware, or broken parts that can be easily replaced or fixed by
              the customer.
            </li>
          </ul>

          <Text weight="bold">Total loss</Text>
          <ul>
            <li>Item is no longer functional and is beyond repair.</li>
          </ul>
        </FAQBlock>
        <FAQBlock>
          <Text tag="h3">Can I see the itemized results of my claim evaluation?</Text>
          <Text tag="p">
            Yes! An itemized breakdown of the claim evaluation including historical claim payments and distribution of
            payments under your account Protection Plan and standard $1/lb coverage can be found{' '}
            <a href="#offer">here</a>.
          </Text>
        </FAQBlock>
      </>
    )}
  </FAQContainer>
);
