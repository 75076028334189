import * as React from 'react';

import { Header } from '../helpers/header';

const SCHEDULED_LOCALE = 'en-US';
const SCHEDULED_OPTIONS = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
} as Intl.DateTimeFormatOptions;

const appointmentDescriptor = (scheduled: string, unconfirmed: boolean) => {
  if (unconfirmed) {
    return 'Unconfirmed';
  } else {
    return new Date(scheduled).toLocaleDateString(SCHEDULED_LOCALE, SCHEDULED_OPTIONS);
  }
};

export const Prompt: React.FC<{ scheduled: string; unconfirmed: boolean }> = ({ scheduled, unconfirmed }) => {
  const descriptor = appointmentDescriptor(scheduled, unconfirmed);
  return <Header tag="h2">{descriptor} Appointment</Header>;
};
