import { Box, Button as StyledButton, useSnackbarContext } from '@clutter/clean';
import React, { useState } from 'react';

import { usePrepayFormQuery } from '@portal/schema';

import { Modal as PrepayModal } from './modal';

export const Button: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { data } = usePrepayFormQuery({ fetchPolicy: 'network-only' });
  const { addSnack } = useSnackbarContext();

  const onClose = () => setShowModal(false);

  const onSuccess = () => {
    onClose();
    addSnack({
      key: 'prepaymentSuccess',
      content: 'Your credits have been purchased!',
    });
  };
  return (
    <>
      <Box margin="24px 16px 16px">
        <StyledButton kind="secondary" fullWidth onClick={() => setShowModal(true)}>
          Prepay Upcoming Bills
        </StyledButton>
      </Box>
      <PrepayModal isOpen={showModal} defaultSource={data?.defaultSource} onSuccess={onSuccess} onClose={onClose} />
    </>
  );
};
