import { useEffect, useState } from 'react';
import { useAffirm } from './use_affirm';

export const useAffirmUIComponent = (type: 'promo', options: AffirmUIComponentOptions) => {
  const [component, setComponent] = useState<AffirmUIComponent | undefined>();
  const affirm = useAffirm();

  useEffect(() => {
    if (!affirm) return;
    setComponent(affirm.ui.components.create(type, options));
  }, [affirm]);

  return component;
};
