import React, { useEffect } from 'react';

import styled from '@emotion/styled';
import { Text } from '@clutter/clean';

import {
  AddressWithDetailsFragment,
  FacilityFragment,
  OrderServiceTypeEnum,
  OrderSubtypeEnum,
  useAddressListQuery,
  useEligibleFacilityQuery,
  useExtendedServiceAreaOrderLazyQuery,
} from '@portal/schema';

import { AvailableServiceTypes } from '@portal/components/orders/service_type/available_service_types';
import { useOrderInput } from '@portal/components/orders/steps/order_context';
import { Selector as AddressSelector } from '@portal/components/addresses/selector';
import { DateTime } from 'luxon';
import { IStepProps } from '../form';
import { Base } from './base';

const Header = styled(Text.Title)`
  margin-bottom: 16px;
`;

const ServiceTypeHeader = styled(Text.Title)`
  margin: 48px 0 24px 0;
`;

export const AddressServiceType: React.FC<
  {
    address?: AddressWithDetailsFragment;
    subtype?: OrderSubtypeEnum;
    onSelect(address: AddressWithDetailsFragment): void;
    setAddress(address: AddressWithDetailsFragment | undefined): void;
    setServiceType(serviceType: OrderServiceTypeEnum | undefined): void;
    setFacilityWarehouse(facility: FacilityFragment | undefined): void;
    onNext?(): void;
    onPrev?(): void;
    setExtendedServiceArea(isExtendedServiceArea: boolean): void;
    setScheduled(date: DateTime): void;
  } & IStepProps
> = ({
  address,
  subtype,
  onSelect,
  setAddress,
  setServiceType,
  setFacilityWarehouse,
  onNext,
  onPrev,
  setExtendedServiceArea,
  setScheduled,
}) => {
  const orderInput = useOrderInput();
  const { serviceType, itemIDs, addressID: selectedAddressID } = orderInput;

  const { data, loading: loadingAddresses } = useAddressListQuery();
  const [isExtendedServiceAreaQuery, { loading: loadingIsExtendedServiceArea }] =
    useExtendedServiceAreaOrderLazyQuery();

  const { data: facilityData } = useEligibleFacilityQuery({
    variables: { itemIDs: itemIDs! },
    skip: !itemIDs,
  });

  useEffect(() => {
    setAddress(undefined);
    setServiceType(undefined);
  }, [setAddress, setServiceType]);

  const facilityWarehouse = facilityData?.facility;

  const onAddressServiceTypeSubmit = async () => {
    if (serviceType === OrderServiceTypeEnum.Facility) {
      if (facilityWarehouse) {
        setFacilityWarehouse(facilityWarehouse);
      }
    } else {
      setFacilityWarehouse(undefined);
    }

    let extendedServiceArea = false;
    if (serviceType === OrderServiceTypeEnum.FullService && itemIDs && selectedAddressID && subtype) {
      const esaResponse = await isExtendedServiceAreaQuery({
        variables: {
          itemIDs: itemIDs,
          addressID: selectedAddressID,
          orderSubtype: subtype,
        },
      });
      extendedServiceArea = esaResponse.data?.extendedServiceAreaOrder ?? false;
    }
    setExtendedServiceArea(extendedServiceArea);

    if (serviceType === OrderServiceTypeEnum.Disposal) {
      // Once the order is booked we will update this to the first available date for the warehouse.
      setScheduled(DateTime.local());
    }
    onNext?.();
  };

  const loading = loadingAddresses || loadingIsExtendedServiceArea;

  const enableNext =
    (!!address && !!serviceType) ||
    (serviceType === OrderServiceTypeEnum.Facility && !!facilityWarehouse) ||
    serviceType === OrderServiceTypeEnum.Disposal;

  return (
    <>
      <Base onNext={onAddressServiceTypeSubmit} loading={loading} onPrev={onPrev} valid={enableNext}>
        <Header size="large">Choose an Address and Appointment Type</Header>

        <AddressSelector addresses={data?.addresses} selection={address} onSelect={onSelect} />

        <ServiceTypeHeader size="medium">Available Appointment Types</ServiceTypeHeader>
        <AvailableServiceTypes
          address={address}
          setServiceType={setServiceType}
          facilityAddress={facilityWarehouse?.address}
        />
      </Base>
    </>
  );
};
