import React from 'react';

import { COLORS } from '@clutter/clean';

export const SMS: React.FC<{ height: number; width: number }> = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    preserveAspectRatio="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill={COLORS.tealPrimary} />
    <ellipse cx="20" cy="19" rx="11" ry="9" fill="white" />
    <path
      d="M11.1936 29.6803C13.4469 27.5829 13.5408 25.0195 13.3061 24C15.0665 24.4369 18.6956 25.8655 17.8832 26.6217C16.4748 27.9325 9.78533 30.9911 11.1936 29.6803Z"
      fill="white"
    />
  </svg>
);
