import { COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { planUpdateURL } from '@portal/config/routes';
import { LaborCostDetailsFragment, LaborRateFragment } from '@portal/schema';

import { QuestionItem } from './question_item';

const Link = styled.a`
  color: ${COLORS.tealPrimary};

  &:hover,
  &:active {
    color: ${COLORS.tealBrand};
  }
`;

export const Questions: React.FC<{
  planUpdatable?: boolean;
  perItemPricing: boolean;
  laborRate?: LaborRateFragment;
  onboardingCostDetails?: LaborCostDetailsFragment;
  subsequentPickupCostDetails?: LaborCostDetailsFragment;
  subsequentReturnCostDetails?: LaborCostDetailsFragment;
  storeAndMoveCostDetails?: LaborCostDetailsFragment;
}> = ({
  planUpdatable,
  perItemPricing,
  laborRate,
  onboardingCostDetails,
  subsequentPickupCostDetails,
  subsequentReturnCostDetails,
  storeAndMoveCostDetails,
}) => {
  const onboardingAppointmentFee = onboardingCostDetails?.appointmentFeeCost;
  const laborRateAmount = laborRate?.amount;
  return (
    <>
      {planUpdatable && (
        <>
          <QuestionItem
            name="storage_upgrade_or_downgrade"
            question="What if I end up needing a bigger or smaller storage size?"
          >
            If you aren’t sure what storage size you’ll need – no problem! Just take your best guess. At your Initial
            Pickup, our technology will measure each item and place you in the storage size that’s right for you.
            <br />
            <br />
            Rest assured you’ll be in control every step of the way. At the start of your appointment, we’ll text you a
            link that gives you real-time visibility into the amount of space your items are taking up so far. We’ll
            also notify you when you are close to being upgraded to the next size up.
          </QuestionItem>
        </>
      )}
      <QuestionItem name="cost_to_access_items" question="How much does it cost to access my items?">
        To add or remove items from storage, you can schedule a pickup or delivery.{' '}
        {perItemPricing ? (
          <>
            The cost of the appointment will depend on the number of items, as well as the date and level of assistance
            you choose. Typically, these appointments cost $69 plus a per item rate that starts at $15 per item
          </>
        ) : (
          <>
            The cost of an additional pickup appointment is{' '}
            {!!subsequentPickupCostDetails?.discountTierDescription &&
              `${subsequentPickupCostDetails.discountTierDescription}, and `}
            {subsequentPickupCostDetails?.formattedCost}. The cost of an additional delivery appointment is{' '}
            {!!subsequentReturnCostDetails?.discountTierDescription &&
              `${subsequentReturnCostDetails.discountTierDescription}, and `}
            {subsequentReturnCostDetails?.formattedCost}. Additional fees may apply if your items are delivered outside
            of your original pickup zone.
          </>
        )}
      </QuestionItem>
      {storeAndMoveCostDetails ? (
        <>
          <QuestionItem name="labor_rate_calculation_store_and_move" question="How is the labor rate calculated?">
            <>
              For your initial pickup and move, the labor rate only applies to the time we spend in front of you and the
              time we spend driving between your move origin and destination. It does not apply to the time we spend
              driving to and from our warehouse, storing your items in our warehouse, etc.
              <br />
              <br />
              For any additional pickup or return orders, the labor rate only applies to the time we spend in front of
              you. It does not apply to the time we spend driving, storing your items in our warehouse, etc.
            </>
          </QuestionItem>
          <QuestionItem
            name="how_much_is_initial_pickup_and_move"
            question="How much will my initial pickup and move cost?"
          >
            The cost of your initial pickup will be: {storeAndMoveCostDetails.formattedCost}
          </QuestionItem>
        </>
      ) : (
        <>
          {!!laborRateAmount && (
            <QuestionItem name="labor_rate_calculation" question="How is the labor rate calculated?">
              It’s simple: the labor rate only applies to the time we spend in front of you. It does not apply to the
              time we spend driving, storing your items in our warehouse, etc.
            </QuestionItem>
          )}
          {planUpdatable && (
            <QuestionItem name="how_much_is_initial_pickup" question="How much will my initial pickup cost?">
              {!onboardingAppointmentFee && !laborRateAmount && 'Your initial pickup is free'}
              {(!!onboardingAppointmentFee || !!laborRateAmount) &&
                `The cost of your initial pickup will be: ${onboardingCostDetails?.formattedCost}`}
              .{!!laborRateAmount && ' The hourly rate only applies to the time we spend in front of you.'}
              <br />
              <br />
              The only way this price will change is if updates are made to your storage size, commitment or pickup
              service. To make updates – or find out how updates would affect your price – visit the{' '}
              <Link href={planUpdateURL()}>Plan Update</Link> page.
            </QuestionItem>
          )}
        </>
      )}
      <QuestionItem name="how_much_paid_monthly" question="How much will I pay each month?">
        Your monthly price can be found on this page under "Storage Plan".
        <br />
        <br />
        {planUpdatable && (
          <>
            The only way this price will change is if updates are made to your storage size, protection or commitment.
            To make updates – or find out how updates would affect your price – visit the{' '}
            <Link href={planUpdateURL()}>Plan Update</Link> page.
          </>
        )}
      </QuestionItem>
    </>
  );
};
