import { take } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { Box } from '@clutter/clean';
import styled from '@emotion/styled';

import { Button, Text } from '@shared/components/bootstrap';

import { Offsets } from './inventory/layout';

import { InventoryFragment, ItemCard } from './inventory/item_card';

const DEFAULT_VIEWABLE_SELECTION_COUNT = 4;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 24px -12px;
`;

export const ReturnInventory: React.FC<{ selections: InventoryFragment[] }> = ({ selections }) => {
  const [more, setMore] = useState<boolean>(false);
  const filtered = more ? selections : take(selections, DEFAULT_VIEWABLE_SELECTION_COUNT);
  return (
    <>
      <Container>
        {filtered.map((item) => (
          <Box key={item.uuid} margin="8px">
            <ItemCard.Container data-test-id={`item-${item.uuid}`}>
              <ItemCard.Content item={item} selected selectedText="Requested" />
            </ItemCard.Container>
          </Box>
        ))}
        <Offsets numOffsets={DEFAULT_VIEWABLE_SELECTION_COUNT - 1} />
      </Container>
      {selections.length > DEFAULT_VIEWABLE_SELECTION_COUNT && (
        <Text tag="p" alignment="center">
          <Button block kind="light" onClick={() => setMore(!more)}>
            {more ? 'See Fewer' : 'See All'}
          </Button>
        </Text>
      )}
    </>
  );
};
