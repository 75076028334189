import React from 'react';
import { Button as CleanButton } from '@clutter/clean';
import { Tooltip } from '@shared/components/bootstrap';
import { ConditionalWrapper, L1_AGENT_CUSTOMER_ONLY_ERROR_MESSAGE, BLOCK_ACTION } from './util';

export const Button: React.FC<
  React.ComponentProps<typeof CleanButton> & {
    showErrorInTooltip?: boolean;
    errorMessage?: string;
    onActionBlocked?(error: string): void;
  }
> = ({
  showErrorInTooltip = false,
  errorMessage = L1_AGENT_CUSTOMER_ONLY_ERROR_MESSAGE,
  onActionBlocked,
  children,
  ...props
}) => {
  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!BLOCK_ACTION) {
      props.onClick?.(e);
    } else {
      onActionBlocked?.(errorMessage);
    }
  };
  return (
    <ConditionalWrapper
      condition={BLOCK_ACTION && showErrorInTooltip}
      wrapper={(c) => <Tooltip title={errorMessage}>{c}</Tooltip>}
    >
      <CleanButton {...props} onClick={onButtonClick}>
        {children}
      </CleanButton>
    </ConditionalWrapper>
  );
};
