/* eslint-disable no-underscore-dangle */
import styled from '@emotion/styled';
import React from 'react';

import { Text, mq } from '@clutter/clean';

import { OrderTypeEnum, useUncancellableShipmentOrderIDsQuery } from '@portal/schema';
import { Order } from '@portal/components/orders/index';
import { Card } from './card';

const Container = styled.div`
  text-align: center;
`;

const Title = styled(Text.Title)`
  ${mq({
    marginTop: [0, '90px'],
  })}
`;

const Cards = styled.div`
  text-align: left;
  padding: 24px 0;
  margin: 0 auto;
  ${mq({
    width: [null, '600px'],
  })}
  & > div {
    margin-bottom: 12px;
  }
`;

const titleString = (orders: Order[], anyUncancellable: boolean) => {
  if (orders.length === 1 && orders[0].type === OrderTypeEnum.Pickup) {
    if (anyUncancellable) {
      return 'Your items are on the way!';
    } else {
      return 'Print labels & ship your items.';
    }
  } else if (orders.length === 1 && orders[0].type === OrderTypeEnum.Return) {
    return 'Your shipment is coming up.';
  } else {
    return 'Your shipments are coming up.';
  }
};

interface IActiveShipmentsProps {
  orders: Order[];
}
export const ActiveShipments = ({ orders }: IActiveShipmentsProps) => {
  const { data } = useUncancellableShipmentOrderIDsQuery({ variables: { orderIDs: orders.map((order) => order.id) } });
  const anyUncancellable = (data && data?.uncancellableShipmentOrderIds.length > 0) || false;

  return (
    <Container>
      <Title size="large">{titleString(orders, anyUncancellable)}</Title>
      <Cards>
        {orders.map((order) => (
          <Card key={order.id} order={order} uncancellable={data?.uncancellableShipmentOrderIds.includes(order.id)} />
        ))}
      </Cards>
    </Container>
  );
};
