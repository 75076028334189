import { Cancelable, debounce } from 'lodash';
import * as React from 'react';

interface IDebouncerProps<T> {
  wait: number;
  data: T;
  children(data: T): React.ReactNode;
}

interface IDebouncerState<T> {
  data: T;
}

export class Debouncer<T> extends React.Component<IDebouncerProps<T>, IDebouncerState<T>> {
  private debounced: Cancelable & ((data: T) => void);

  constructor(props: IDebouncerProps<T>) {
    super(props);
    this.debounced = debounce(this.sync, props.wait);
    this.state = { data: props.data };
  }

  public render() {
    const { children } = this.props;
    return children(this.state.data);
  }

  public componentDidMount() {
    this.debounced.cancel();
  }

  public componentWillUnmount() {
    this.debounced.cancel();
  }

  public componentDidUpdate(props: IDebouncerProps<T>) {
    if (this.props.data !== props.data) {
      this.debounced(this.props.data);
    }
  }

  private sync = (data: T) => {
    this.setState({ data });
  };
}
