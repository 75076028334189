/** THIS FILE IS AUTOGENERATED. EDITS MUST BE MADE WITHIN PLATFORM IN INDIVIDUAL EVENT FILES */

export enum EventSchema {
  Pageview = 'pageview@1',
  ScrollDepth = 'scroll_depth@1',
  Portal__DateRequested = 'portal/date_requested@1',
  Portal__DateSelected = 'portal/date_selected@1',
  Portal__LoginAttempt = 'portal/login_attempt@1',
  Portal__RescheduleOfferAccepted = 'portal/reschedule_offer_accepted@1',
  Portal__RescheduleOfferDateSelected = 'portal/reschedule_offer_date_selected@1',
  Portal__RescheduleOfferDeclined = 'portal/reschedule_offer_declined@1',
  Portal__RescheduleOfferViewed = 'portal/reschedule_offer_viewed@1',
}

/** An event fired from the browser when a page is initially loaded or navigated to */
export type Pageview = {
  schema: EventSchema.Pageview;
  kind: 'pageview';
  metadata: {
    customer_id?: string;
    today_app_token?: string;
    user_id?: string;
  };
};

/** An event fired when the browser reaches a certain percentage of the total
 * height of the page. Percentage is calculated as
 *
 * scroll_offset / (page_height - viewport_height) * 100 */
export type ScrollDepth = {
  schema: EventSchema.ScrollDepth;
  kind: 'event';
  metadata: {
    scroll_percent: number;
    page_height: number;
    viewport_height: number;
  };
};

/** The customer selected a date from the date picker to jump to a date in the full availability calendar */
export type Portal__DateRequested = {
  schema: EventSchema.Portal__DateRequested;
  action: 'click';
  metadata: {
    date_requested: string;
  };
};

/** The customer saw the full calendar with availability and pricing. This event should be created every time the focused date changes. */
export type Portal__DateSelected = {
  schema: EventSchema.Portal__DateSelected;
  action: 'click';
  metadata: {
    date: string;
    is_available: boolean;
    is_waitlisted: boolean;
    hourly_price?: number;
    has_hourly_price_adjusted?: boolean;
    is_initial_date_requested?: boolean;
  };
};

/** Any attempt to log in to the account portal, including failed attempts */
export type Portal__LoginAttempt = {
  schema: EventSchema.Portal__LoginAttempt;
  action: 'click';
  metadata: {
    email: string;
  };
};

/** A customer accepted a reschedule offer */
export type Portal__RescheduleOfferAccepted = {
  schema: EventSchema.Portal__RescheduleOfferAccepted;
  action: 'click';
  metadata: {
    order_reschedule_offer_item_id: string;
  };
};

/** A customer clicked an available date in the calendar to reschedule to */
export type Portal__RescheduleOfferDateSelected = {
  schema: EventSchema.Portal__RescheduleOfferDateSelected;
  action: 'click';
  metadata: {
    order_reschedule_offer_item_id: string;
    date_selected: string;
  };
};

/** A customer declined a reschedule offer */
export type Portal__RescheduleOfferDeclined = {
  schema: EventSchema.Portal__RescheduleOfferDeclined;
  action: 'click';
  metadata: {
    order_reschedule_offer_item_id: string;
  };
};

/** A customer viewed the reschedule offer page */
export type Portal__RescheduleOfferViewed = {
  schema: EventSchema.Portal__RescheduleOfferViewed;
  action: 'display';
  metadata: {
    order_reschedule_offer_item_id: string;
    active: boolean;
    dates_available?: string[];
  };
};
export type EventWithSchema =
  | Pageview
  | ScrollDepth
  | Portal__DateRequested
  | Portal__DateSelected
  | Portal__LoginAttempt
  | Portal__RescheduleOfferAccepted
  | Portal__RescheduleOfferDateSelected
  | Portal__RescheduleOfferDeclined
  | Portal__RescheduleOfferViewed;
