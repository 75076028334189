import React from 'react';
import { FontWeight, Text } from '@clutter/clean';
import { DateTime } from 'luxon';
import { AvailabilityWaitlistRequestFragment } from '@portal/schema';

export const WaitlistRequests: React.FC<{ waitlistRequests: AvailabilityWaitlistRequestFragment[] }> = ({
  waitlistRequests,
}) => (
  <>
    <Text.Body weight={FontWeight.Medium}>You are on the waitlist for the dates below:</Text.Body>
    <ul>
      {waitlistRequests.map(({ date }) => (
        <li key={date}>{DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED)}</li>
      ))}
    </ul>
  </>
);
