import React, { useState } from 'react';
import cn from 'classnames';

import {
  CallbackWindow,
  OrderTypeEnum,
  Status,
  useContactInformationQuery,
  useZendeskCallbackRequestCreateMutation,
} from '@portal/schema';
import { Box, Button, COLORS, FontWeight, Text, mq } from '@clutter/clean';
import { PhoneInput } from '@portal/components/helpers/phone_input';
import { Phone } from '@shared/components/helpers/phone';
import { useTrack } from '@clutter/wt';
import styled from '@emotion/styled';
import { Confirmed } from './callback/confirmed';
import { Windows } from './callback/windows';
import { OrderDetailsOrder } from './steps';

const CUSTOMER_SUCCESS_NUMBER = '+18885988837';
const SUBMIT_BUTTON_LABEL = 'Confirm Call';

const PhoneContainer = styled.div`
  ${mq({
    borderTop: [`1px solid ${COLORS.grayBorder}`, 'none'],
    maxWidth: ['initial', '352px'],
    paddingTop: '12px',
  })}
`;

enum ErrorType {
  API = 'api',
  MissingWindow = 'missing_window',
}

export const Callback: React.FC<{
  order: OrderDetailsOrder;
}> = ({ order }) => {
  const [selectedCallbackWindow, setSelectedCallbackWindow] = useState<CallbackWindow>();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [scheduled, setScheduled] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType | null>(null);
  const [blurred, setBlurred] = useState<boolean>(false);
  const [phoneValid, setPhoneValid] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  useContactInformationQuery({ onCompleted: (data) => onPhoneNumberChange(data.customer?.phone) });
  const [createCallback] = useZendeskCallbackRequestCreateMutation();

  const track = useTrack();

  const showPhoneInvalid = !phoneValid && blurred;

  const sanitize = (value: string) => value.replace(/\D/g, '');

  const onPhoneNumberChange = (phone?: string) => {
    const newPhoneNumber = phone ? sanitize(phone) : '';
    setPhoneNumber(newPhoneNumber);
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();

    track({
      action: 'click',
      objectName: 'callback_submit',
      objectType: 'button',
      label: SUBMIT_BUTTON_LABEL,
      metadata: {
        callback_time: selectedCallbackWindow?.time,
        duration: selectedCallbackWindow?.duration,
        phone_number: phoneNumber,
      },
    });

    setError(null);

    if (!selectedCallbackWindow) {
      setError(ErrorType.MissingWindow);
      return;
    }
    if (!phoneValid) {
      setBlurred(true);
      return;
    }
    setLoading(true);
    try {
      const orderName = order.type === OrderTypeEnum.Pickup ? 'ob' : 'move';

      const { data } = await createCallback({
        variables: {
          phoneNumber,
          callbackAt: selectedCallbackWindow!.time,
          tags: [`account:${orderName}:cancellation_request`],
        },
      });
      if (data?.result?.status === Status.Unprocessable) {
        setError(ErrorType.API);
        return;
      }
      track({
        action: 'display',
        objectName: 'callback_create_success_message',
        metadata: {
          callback_time: selectedCallbackWindow?.time,
          duration: selectedCallbackWindow?.duration,
        },
      });
      setScheduled(true);
    } finally {
      setLoading(false);
    }
  };

  if (scheduled && selectedCallbackWindow) {
    return <Confirmed callbackWindow={selectedCallbackWindow} />;
  }

  return (
    <Box margin="0 auto" maxWidth="728px">
      <Text.Title size="medium" color={COLORS.tealDark}>
        Talk with an agent to process your cancellation
      </Text.Title>
      <Box margin="8px 0">
        <Text.Body color={COLORS.storm}>
          This ensures that your cancellation is fully processed and any movers currently assigned to your appointment
          are re-routed correctly.
        </Text.Body>
      </Box>
      <form onSubmit={onSubmit}>
        <Box margin="16px 0 0">
          <Windows selected={selectedCallbackWindow} disabled={loading} onSelect={setSelectedCallbackWindow} />
        </Box>
        {error === ErrorType.MissingWindow && (
          <Box background={COLORS.flower} padding="12px" borderRadius="4px" margin={['12px 0', '0 0 12px']}>
            <Text.Callout>Please select a time for us to call you.</Text.Callout>
          </Box>
        )}
        <PhoneContainer className="form-group">
          <label>
            <Text.Body weight={FontWeight.Medium}>Phone number</Text.Body>
          </label>
          <PhoneInput
            className={cn('form-control', { 'is-invalid': showPhoneInvalid })}
            value={phoneNumber}
            onChange={(event) => onPhoneNumberChange(event.target.value)}
            onValidation={setPhoneValid}
            onFocus={() => setBlurred(false)}
            onBlur={() => setBlurred(true)}
            disabled={loading}
          />
          {showPhoneInvalid && <Text.Callout color={COLORS.toucan}>Please provide a valid phone number.</Text.Callout>}
        </PhoneContainer>
        {error === ErrorType.API && (
          <Box background={COLORS.flower} padding="12px" borderRadius="4px" margin={['12px 0', '0 0 12px']}>
            <Text.Callout>
              An unexpected error occured and your callback was not scheduled. Please call us at{' '}
              <Phone value={CUSTOMER_SUCCESS_NUMBER} />.
            </Text.Callout>
          </Box>
        )}
        <Box margin="32px 0 0">
          <Button kind="primary" fullWidth type="submit" size="large" loading={loading} disabled={loading}>
            {SUBMIT_BUTTON_LABEL}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
