import * as React from 'react';

import { IInventoryConfig, IOrderInventory } from '@shared/types';
import { CategoriesContext } from './categories_context';
import { InventoryContext } from './inventory_context';

export const ConfigWithSetup: React.FC<
  IInventoryConfig & {
    inventory: IOrderInventory;
    onSelect?(inventory: IOrderInventory): void;
  }
> = ({
  onSelect = () => {
    /* noop */
  },
  inventory,
  children,
  ...config
}) => (
  <CategoriesContext.Provider value={config}>
    <InventoryContext.Provider value={{ data: inventory, onSelect }}>{children}</InventoryContext.Provider>
  </CategoriesContext.Provider>
);
