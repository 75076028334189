import * as Sentry from '@sentry/browser';
import { ENV } from '../config/environment';

interface IConfig {
  sentry_dsn: string | null;
  visitor?: {
    id: string;
    name: string;
    email: string;
  };
}

declare const CONFIG: IConfig;
const SENTRY_DSN = CONFIG.sentry_dsn;

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV,
    ignoreErrors: [
      /Object Not Found Matching Id/, // See https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/13
    ],
  });

  Sentry.configureScope((scope) => {
    if (CONFIG.visitor) {
      scope.setUser({
        id: CONFIG.visitor.id,
        username: CONFIG.visitor.name,
        email: CONFIG.visitor.email,
      });
    }
  });
}
