import React from 'react';

import { Billing__UpcomingInvoiceFragment } from '@portal/schema';

import { InvoiceCard } from './invoice_card';

export const InvoiceList: React.FC<{
  invoices: Billing__UpcomingInvoiceFragment[];
}> = ({ invoices }) => (
  <>
    {invoices.map((invoice) => (
      <InvoiceCard key={`${invoice.invoiceable.__typename}-${invoice.invoiceable.id}`} invoice={invoice} />
    ))}
  </>
);
