/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { useState } from 'react';

import styled from '@emotion/styled';
import { Button, COLORS, ExternalContent, InfoModal, List, SpinLoader, Text } from '@clutter/clean';

import { Base } from '@portal/components/orders/steps/base';
import { CheckCircle } from '@portal/components/shared/icons/check_circle';
import { wt } from '@portal/initializers/wt';

import { useZendeskCopyQuery, Zendesk__ArticleName } from '@portal/schema';

const HEADING = `Here's what you'll need to do to ship items`;

const SUBHEADING = `If you're not quite ready now, come back any time to add the details.`;

const Header = styled(Text.Title)`
  margin-bottom: 16px;
`;

const Subheader = styled(Text.Body)`
  margin-bottom: 12px;
  color: ${COLORS.storm};
`;

const BorderlessList = styled(List)<{ verticalPadding: number }>`
  border: none;
  padding: 0;
  & > div {
    padding: ${({ verticalPadding }) => verticalPadding}px 0;
  }
`;

const BorderlessListItem = styled.div`
  display: flex;
  align-items: center;
`;

const ListItemCopy = styled(Text.Body)`
  margin-left: 18px;
`;

const ModalContent = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 32px;
`;

const SpinnerContainer = styled.div`
  margin-top: 32px;
`;

const StyledExternalContent = styled(ExternalContent)`
  & > ul {
    margin: 32px 0;
    padding: 0;
    list-style-type: none;

    & > li {
      margin-bottom: 16px;
    }
  }
`;

const PROHIBITED_ITEMS_BUTTON_TRACKING_PARAMS = {
  pageName: 'portal:new_appointment',
  container: 'inbound_shipping_prepare',
  action: 'click',
  objectType: 'button',
  objectName: 'prohibited_items_button',
  label: "Review Clutter's prohibited items",
};

const ZendeskContent: React.FC<{ setShowingModal(showingModal: boolean): void }> = ({ setShowingModal }) => {
  const { data, loading } = useZendeskCopyQuery({
    variables: { articleName: Zendesk__ArticleName.ShippingProhibitedItems },
  });

  if (loading || !data) {
    return (
      <SpinnerContainer>
        <SpinLoader />
      </SpinnerContainer>
    );
  }

  const { zendeskCopy } = data;

  return (
    <div>
      {zendeskCopy && <StyledExternalContent dangerouslySetInnerHTML={{ __html: zendeskCopy }} />}
      <Button kind="secondary" onClick={() => setShowingModal(false)} fullWidth>
        Got it
      </Button>
    </div>
  );
};

const ProhibitedItemsModal: React.FC<{ showingModal: boolean; setShowingModal(showingModal: boolean): void }> = ({
  showingModal,
  setShowingModal,
}) => (
  <InfoModal isOpen={showingModal} handleModalClose={() => setShowingModal(false)}>
    <ModalContent>
      <Text.Title size="large">Prohibited items</Text.Title>
      <ZendeskContent setShowingModal={setShowingModal} />
    </ModalContent>
  </InfoModal>
);

export const InboundShippingPrepare: React.FC<{
  onNext(): void;
  onPrev(): void;
}> = ({ onNext, onPrev }) => {
  const [showingModal, setShowingModal] = useState<boolean>(false);

  return (
    <Base onNext={onNext} onPrev={onPrev}>
      <Header size="large">{HEADING}</Header>
      <Subheader>{SUBHEADING}</Subheader>
      <BorderlessList density="default" verticalPadding={12}>
        <BorderlessListItem>
          <CheckCircle width="30" height="30" fillColor={COLORS.tealPrimary} />
          <ListItemCopy>
            Review Clutter's{' '}
            <a
              onClick={(e) => {
                e.preventDefault();
                setShowingModal(true);
                wt.track(PROHIBITED_ITEMS_BUTTON_TRACKING_PARAMS);
              }}
              href="#"
            >
              <u>prohibited items</u>
            </a>
          </ListItemCopy>
        </BorderlessListItem>
        <BorderlessListItem>
          <CheckCircle width="30" height="30" fillColor={COLORS.tealPrimary} />
          <ListItemCopy>Pack all your items in boxes</ListItemCopy>
        </BorderlessListItem>
        <BorderlessListItem>
          <CheckCircle width="30" height="30" fillColor={COLORS.tealPrimary} />
          <ListItemCopy>Tell us dimensions for each box</ListItemCopy>
        </BorderlessListItem>
      </BorderlessList>
      <ProhibitedItemsModal showingModal={showingModal} setShowingModal={setShowingModal} />
    </Base>
  );
};
