import React from 'react';
import { DateTime } from 'luxon';
import styled from '@emotion/styled';
import { Box, Text } from '@clutter/clean';

import { disposalConfirmationURL, orderURL } from '@portal/config/routes';
import {
  OrderSignatureRequestsQuery,
  OrderStateEnum,
  OrderStatusEnum,
  OrderTypeEnum,
  SignatureRequest,
  SignatureRequestKindEnum,
} from '@portal/schema';
import { useHistory } from 'react-router';
import { Task } from './task';

const IN_ADVANCE_READY = 'Sign these documents ahead of your upcoming appointment.';
const PREJOB_READY = 'Sign these documents so your team can get started.';
const MOVE_POSTJOB_READY = 'Sign these documents so we can end your appointment.';
const POSTJOB_READY = 'Sign these documents so we can end your appointment and transport your items to storage.';
const SIGNED_TEXT = "Thanks for signing! You're all set.";
const POSTJOB_UNAVAILABLE =
  "These documents aren't yet available. Check back when your movers complete your appointment.";

type Order = OrderSignatureRequestsQuery['orderSignatures'];

const FROM_ISO_OPTIONS = { setZone: true };

const Section = styled(Box)`
  margin: 32px 0px;
`;

const Heading = styled(Text.Title)`
  margin-bottom: 4px;
`;

const KIND_TO_TITLE: Record<SignatureRequestKindEnum, string> = {
  [SignatureRequestKindEnum.InAdvance]: 'Ahead-of-Time Docs',
  [SignatureRequestKindEnum.PreJob]: 'Pre-Appointment Docs',
  [SignatureRequestKindEnum.PostJob]: 'Post-Appointment Docs',
};

const pickDescriptionText = (notCompletedText: string, completedText: string, completed: boolean) => {
  if (completed) {
    return completedText;
  } else {
    return notCompletedText;
  }
};
const pickPostJobReadyText = (order: Order) =>
  order?.type === OrderTypeEnum.Move ? MOVE_POSTJOB_READY : POSTJOB_READY;

export const SignaturesHome: React.FC<{ order: Order; offerInTruck: boolean }> = ({ order, offerInTruck }) => {
  const { id: orderID, scheduled, account, physicallySigned, signatureRequests, status, childDisposal } = order!;
  const date = DateTime.fromISO(scheduled, FROM_ISO_OPTIONS);
  const inAdvanceSignatureRequest = signatureRequests.find(({ kind }) => kind === SignatureRequestKindEnum.InAdvance);
  const preJobSignatureRequest = signatureRequests.find(({ kind }) => kind === SignatureRequestKindEnum.PreJob);
  const postJobSignatureRequest = signatureRequests.find(({ kind }) => kind === SignatureRequestKindEnum.PostJob);

  const inAdvanceCompleted = !!inAdvanceSignatureRequest?.signedAt;
  const preJobCompleted = !!preJobSignatureRequest?.signedAt;
  const postJobCompleted = !!postJobSignatureRequest?.signedAt;
  const inTruckCompleted = preJobCompleted || postJobCompleted;

  const history = useHistory();

  const onSelect = (request?: Pick<SignatureRequest, 'url' | 'signedAt' | 'kind'>) => {
    if (
      request?.kind === SignatureRequestKindEnum.PostJob &&
      childDisposal &&
      childDisposal.state === OrderStateEnum.Unconfirmed &&
      childDisposal.items.length !== 0
    ) {
      history.push(disposalConfirmationURL(childDisposal.disposalConfirmationSignedOrderId));
    } else if (request && request.url && !request.signedAt) {
      window.location.assign(request.url);
    }
  };

  return (
    <>
      <Section>
        <Heading size="large">Hey {account.customer.firstName},</Heading>
        <Text.Body>
          We need you to review a few documents before and after your appointment. These documents make sure we're
          complying with your local regulations.
        </Text.Body>
      </Section>
      <Section>
        <Heading size="large">{date.toLocaleString(DateTime.DATE_FULL)}</Heading>
        {physicallySigned ? (
          <Text.Body>Paper documents were signed on-site for this appointment.</Text.Body>
        ) : (
          <Box.Flex flexDirection="column">
            {inAdvanceSignatureRequest && (
              <Task
                completed={inAdvanceCompleted}
                onSelect={() => onSelect(inAdvanceSignatureRequest)}
                disabled={!inAdvanceSignatureRequest}
                titleText={KIND_TO_TITLE[SignatureRequestKindEnum.InAdvance]}
                descriptionText={pickDescriptionText(IN_ADVANCE_READY, SIGNED_TEXT, inAdvanceCompleted)}
                paramsLabel={inAdvanceSignatureRequest.kind ?? undefined}
                paramsValue={inAdvanceSignatureRequest.url ?? undefined}
              />
            )}
            {preJobSignatureRequest && (
              <Task
                completed={preJobCompleted}
                onSelect={() => onSelect(preJobSignatureRequest)}
                disabled={!preJobSignatureRequest}
                titleText={KIND_TO_TITLE[SignatureRequestKindEnum.PreJob]}
                descriptionText={pickDescriptionText(PREJOB_READY, SIGNED_TEXT, preJobCompleted)}
                paramsLabel={preJobSignatureRequest.kind ?? undefined}
                paramsValue={preJobSignatureRequest.url ?? undefined}
              />
            )}
            <Task
              completed={postJobCompleted}
              onSelect={() => onSelect(postJobSignatureRequest)}
              disabled={!postJobSignatureRequest}
              titleText={KIND_TO_TITLE[SignatureRequestKindEnum.PostJob]}
              descriptionText={
                postJobSignatureRequest
                  ? pickDescriptionText(pickPostJobReadyText(order), SIGNED_TEXT, postJobCompleted)
                  : POSTJOB_UNAVAILABLE
              }
              paramsLabel={postJobSignatureRequest?.kind ?? undefined}
              paramsValue={postJobSignatureRequest?.url ?? undefined}
            />
            {offerInTruck && preJobSignatureRequest && !inTruckCompleted && status === OrderStatusEnum.Scheduled && (
              <Text>
                Add increased <a href={orderURL(orderID, 'truck_protection')}>protection</a> for your items while in
                transit on our trucks.
              </Text>
            )}
          </Box.Flex>
        )}
      </Section>
      <Section>
        <Heading size="large">Need help?</Heading>
        <Text.Body>
          Give us a call at{' '}
          <a type="tel" href="tel:+18008054023">
            1-800-805-4023
          </a>
          .
        </Text.Body>
      </Section>
    </>
  );
};
