import { Button } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Account__TermCommitment, Maybe } from '@portal/schema';

import { ITermCommitmentRadioOption, MessageType, Options, TermCommitmentAction } from './options';

const StyledButton = styled(Button)`
  margin: 12px 0;
`;

export const Selection: React.FC<{
  messageType: MessageType;
  termCommitment: Account__TermCommitment;
  options: ITermCommitmentRadioOption[];
  action?: TermCommitmentAction;
  error?: Maybe<string>;
  loading: boolean;
  setAction: React.Dispatch<React.SetStateAction<TermCommitmentAction | undefined>>;
  onSelection(): void;
}> = ({ messageType, termCommitment, options, action, error, loading, setAction, onSelection }) => (
  <>
    <Options
      messageType={messageType}
      termCommitment={termCommitment}
      options={options}
      action={action}
      error={error}
      setAction={setAction}
    />
    <StyledButton kind="primary" size="medium" disabled={!action || loading} onClick={onSelection} fullWidth>
      Continue
    </StyledButton>
  </>
);
