import React from 'react';

import { AccountResolution__OfferFragment } from '@portal/schema';
import { Currency } from '@shared/components/helpers';

export const Accepted: React.FC<{ offer: AccountResolution__OfferFragment }> = ({ offer }) => (
  <>
    {offer.metadata.action === 'reverse' ? (
      <>
        You
        {!!offer.metadata.refund_amount && (
          <>
            {' '}
            have been refunded <Currency value={Number(offer.metadata.refund_amount)} precision="automatic" /> and you
          </>
        )}
        r monthly storage rate is now <Currency value={Number(offer.metadata.old_plan_price)} precision="automatic" />
        /mo.
      </>
    ) : (
      <>
        Your storage rate will remain at{' '}
        <Currency value={Number(offer.metadata.old_plan_price)} precision="automatic" />
        /mo.
      </>
    )}
  </>
);
