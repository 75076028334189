import React from 'react';

const DEFAULT_COUNTRY = 'USA';
const DEFAULT_CURRENCY = 'USD';

type AddressFragment = {
  aptsuite?: string | null;
  street: string;
  city: string;
  state: string;
  zip: string;
  country?: string | null;
};

type CustomerFragment = {
  name: string;
  email: string;
  phone: string;
};

export const useAffirmCheckout =
  () =>
  ({
    orderID,
    customer,
    address,
    total,
    taxAmount,
    items,
    discounts,
  }: {
    orderID: string;
    customer: CustomerFragment;
    address: AddressFragment;
    total: number;
    taxAmount: number;
    items: AffirmItem[];
    discounts: Record<string, AffirmDiscount>;
  }) =>
    new Promise<AffirmPayload>((resolve, reject) => {
      affirm.checkout({
        onFail: (error) => {
          // Affirm calls both callbacks with no arguments on initial load. This behavior is strange - but requires the following guard.
          if (!error) return;
          reject(error);
        },
        onSuccess: (checkout) => {
          // Affirm calls both callbacks with no arguments on initial load. This behavior is strange - but requires the following guard.
          if (!checkout) return;
          resolve(checkout);
        },
        merchant: {
          name: 'Clutter',
          user_confirmation_url: '',
          user_cancel_url: '',
          user_confirmation_url_action: 'POST',
        },
        shipping: {
          name: {
            full: customer.name,
          },
          address: {
            line1: address.street,
            line2: address.aptsuite ?? undefined,
            city: address.city,
            state: address.state,
            zipcode: address.zip,
            country: address.country ?? DEFAULT_COUNTRY,
          },
          phone_number: customer.phone,
          email: customer.email,
        },
        billing: {
          name: {
            full: customer.name,
          },
          address: {
            line1: address.street,
            line2: address.aptsuite ?? undefined,
            city: address.city,
            state: address.state,
            zipcode: address.zip,
            country: address.country ?? DEFAULT_COUNTRY,
          },
          phone_number: customer.phone,
          email: customer.email,
        },
        items,
        discounts,
        metadata: {
          mode: 'modal',
        },
        order_id: orderID,
        currency: DEFAULT_CURRENCY,
        tax_amount: taxAmount,
        total,
      });
      affirm.checkout.open();
    });
