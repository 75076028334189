import {
  Pricing__RateGroup__Commitment as RateGroupCommitment,
  Pricing__RateGroup__OrderService as RateGroupService,
  Pricing__Set__AppointmentFees as AppointmentFees,
  Pricing__Set__PackageSetEntry as PackageSetEntry,
  PricingSetPriceEntryFragment,
  ProtectionPlanFragment,
  StoragePlanFragment,
} from '@portal/schema';
import { protectionName, storageName } from '@portal/utils/plan';
import { CommitmentOption } from './commitment/selector';

const AMOUNT_PLACEHOLDER = '-';
type StorageToPricingEntry = Record<string, PricingSetPriceEntryFragment>;
type ServiceTypeToCommitment = Record<RateGroupService, StorageToPricingEntry>;
export type StoragePriceEntryMapping = Record<RateGroupCommitment, ServiceTypeToCommitment>;

export const groupStoragePriceEntries = (storagePricingGroupEntries: PricingSetPriceEntryFragment[]) => {
  const mapping: StoragePriceEntryMapping = {} as StoragePriceEntryMapping;
  storagePricingGroupEntries.forEach((entry) => {
    const serviceType = entry.rateGroup?.serviceType;
    const commitmentType = entry.rateGroup?.commitmentType;
    const storagePlanName = entry.price.plan.name;

    if (!serviceType || !commitmentType) {
      return;
    }

    if (!mapping[commitmentType]) {
      mapping[commitmentType] = {} as ServiceTypeToCommitment;
    }
    if (!mapping[commitmentType][serviceType]) {
      mapping[commitmentType][serviceType] = {};
    }
    mapping[commitmentType][serviceType][storagePlanName] = entry;
  });
  return mapping;
};

export const formattedServiceType = (serviceType: RateGroupService): string => {
  switch (serviceType) {
    case RateGroupService.CurbsidePickup:
      return 'Pickup only';
    case RateGroupService.FullService:
      return 'Pickup & Packing';
    case RateGroupService.DropOff:
      return 'Drop off';
    case RateGroupService.Capsule:
      return 'Capsule';
  }
};

export const formatFees = (option: AppointmentFees) => {
  const laborPolicy = option.laborEntry.laborPolicy;
  const formattedFees = option.packageSetEntries
    .filter((entry) => entry.amount > 0)
    .map((entry) => currency(entry.amount));
  if (laborPolicy.laborRate.amount > 0) {
    formattedFees.push(`${currency(laborPolicy.laborRate.amount)}/hr${laborPolicy.perMoverHour ? ' per mover' : ''}`);
  } else if (!formattedFees.length) {
    formattedFees.push('Free');
  }
  return formattedFees.join(' + ');
};

export const sumPackageSetEntries = (packageSetEntries: PackageSetEntry[]) =>
  packageSetEntries.reduce((acc, entry) => acc + entry.amount, 0);

export const currency = (amount: number) =>
  amount.toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

export const storageTitle = (storage?: PricingSetPriceEntryFragment) =>
  storage ? `${storageName(storage.price.plan as StoragePlanFragment)} Storage` : 'Storage Plan';

export const storageValue = (storage?: PricingSetPriceEntryFragment) =>
  storage ? `${currency(storage.price.amount)}/mo` : `${AMOUNT_PLACEHOLDER}/mo`;

export const storageChanged = (selected?: PricingSetPriceEntryFragment, initial?: PricingSetPriceEntryFragment) =>
  initial?.price?.amount !== selected?.price?.amount;

export const protectionTitle = (protection?: PricingSetPriceEntryFragment) =>
  protection ? protectionName(protection.price.plan as ProtectionPlanFragment) : 'Protection Plan';

export const protectionValue = (protection?: PricingSetPriceEntryFragment) => {
  if (!protection) {
    return `${AMOUNT_PLACEHOLDER}/mo`;
  }

  return protection.price.amount > 0 ? `${currency(protection.price.amount)}/mo` : 'None';
};

export const protectionChanged = (selected?: PricingSetPriceEntryFragment, initial?: PricingSetPriceEntryFragment) =>
  initial?.price?.amount !== selected?.price?.amount;

export const commitmentValue = (commitment?: CommitmentOption) => {
  if (!commitment) {
    return AMOUNT_PLACEHOLDER;
  }
  const minimumMonths = commitment.minimumMonths;
  return minimumMonths > 0 ? `${minimumMonths} months` : 'Month to month';
};

export const commitmentChanged = (selected?: CommitmentOption, initial?: CommitmentOption) =>
  selected?.minimumMonths !== initial?.minimumMonths;

export const serviceChanged = (selected?: AppointmentFees, initial?: AppointmentFees) => {
  if (!initial || !selected) {
    return true;
  }

  const {
    serviceType: initialServiceType,
    laborEntry: initialLaborEntry,
    packageSetEntries: initialPackageSetEntries,
  } = initial;

  const {
    serviceType: selectedServiceType,
    laborEntry: selectedLaborEntry,
    packageSetEntries: selectedPackageSetEntries,
  } = selected;

  const initialLaborRate = initialLaborEntry.laborPolicy.laborRate;
  const selectedLaborRate = selectedLaborEntry.laborPolicy.laborRate;

  return (
    initialServiceType !== selectedServiceType ||
    initialLaborRate.amount !== selectedLaborRate.amount ||
    sumPackageSetEntries(initialPackageSetEntries) !== sumPackageSetEntries(selectedPackageSetEntries)
  );
};

export const PLAN_UPDATE_PAGE_NAME = 'portal:plan_update';
export const PLAN_UPDATE_CONFIRM_PAGE_NAME = 'portal:plan_update_confirm';

export const accordionMetadata = (isOpen: boolean) => ({
  objectType: 'image',
  objectName: isOpen ? 'collapse_accordion' : 'expand_accordion',
  label: isOpen ? '^' : 'v',
  pageName: PLAN_UPDATE_PAGE_NAME,
  action: 'click',
});

export const buildCommitmentOption = (pricingSetEntry: PricingSetPriceEntryFragment) => ({
  minimumMonths: pricingSetEntry.storageTerm!.minimumMonths,
  commitmentType: pricingSetEntry.rateGroup!.commitmentType!,
  pricingSetEntry: pricingSetEntry,
});

export const planSizeMetadata = (storagePriceEntry?: PricingSetPriceEntryFragment) =>
  storagePriceEntry?.price?.plan?.name ?? 'NONE';
export const protectionClassificationMetadata = (protectionPriceEntry?: PricingSetPriceEntryFragment) =>
  (protectionPriceEntry?.price?.plan as ProtectionPlanFragment)?.classification ?? 'NONE';
export const commitmentMetadata = (commitment?: CommitmentOption) => commitment?.commitmentType ?? 'NONE';
export const serviceTypeMetadata = (storagePriceEntry: PricingSetPriceEntryFragment) =>
  storagePriceEntry.rateGroup?.serviceType ?? 'NONE';

export type PlanMetadata = {
  order_id: string;
  plan_size?: string;
  item_protection?: string;
  commitment?: string;
  service_type?: string;
};

export const planMetadata = (
  onboardingID: string,
  storagePriceEntry: PricingSetPriceEntryFragment,
  protectionPriceEntry: PricingSetPriceEntryFragment,
) => {
  const planSize = planSizeMetadata(storagePriceEntry);
  const protectionClassification = protectionClassificationMetadata(protectionPriceEntry);

  const commitment = commitmentMetadata(buildCommitmentOption(storagePriceEntry));
  const serviceType = serviceTypeMetadata(storagePriceEntry);

  return {
    order_id: onboardingID,
    plan_size: planSize,
    item_protection: protectionClassification,
    commitment: commitment,
    service_type: serviceType,
  };
};
