import * as React from 'react';

import { IRoomCategory, IRoomSelection } from '@shared/types';

import { Stepper } from '@shared/components/helpers';

export const QuantifiableOption: React.FC<{
  category: IRoomCategory;
  quantity?: number;
  onChange(changes: Partial<IRoomSelection>): void;
}> = ({ category: { name }, quantity, onChange }) => (
  <div className="form-group row">
    <label className="col-auto mr-auto col-form-label">
      <span className="text-capitalize">{name}</span>
    </label>
    <div className="col-auto">
      <Stepper kind="info" value={quantity} onChange={(value) => onChange({ quantity: value })} />
    </div>
  </div>
);
