import * as qs from 'qs';
import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, SpinDotsLoader } from '@clutter/clean';

import { orderURL } from '@portal/config/routes';
import { useOrderSignatureRequestsQuery } from '@portal/schema';

import { SignaturesHome } from './signatures/home';

const QS_PARSE_OPTIONS = { ignoreQueryPrefix: true };

const LoaderContainer = styled(Box)`
  width: 100%;
  text-align: center;
`;

export const Signatures: React.FC<RouteComponentProps<{ orderID: string }>> = ({
  match: {
    params: { orderID },
  },
}) => {
  const { data, loading } = useOrderSignatureRequestsQuery({
    variables: {
      input: {
        orderID,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  if (loading || !data || !data.orderSignatures) {
    return (
      <LoaderContainer>
        <SpinDotsLoader />
      </LoaderContainer>
    );
  }

  const { orderSignatures, offerInTruckProtection } = data;

  return <SignaturesHome order={orderSignatures} offerInTruck={offerInTruckProtection} />;
};

export const SignedOrderSignatures: React.FC<{}> = () => {
  const params = qs.parse(location.search, QS_PARSE_OPTIONS);
  const { order_id: signedOrderID } = params;

  const { data, loading } = useOrderSignatureRequestsQuery({
    variables: {
      input: {
        signedOrderID,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  if (loading || !data || !data.orderSignatures) return null;

  if (data.correctCustomerLoggedIn) {
    return <Redirect to={orderURL(data.orderSignatures.id, 'signatures')} />;
  } else {
    return <SignaturesHome order={data.orderSignatures} offerInTruck={data.offerInTruckProtection} />;
  }
};
