import React from 'react';
import { Link } from 'react-router-dom';

import { AttachmentFragment, ClaimItemSelectionFragment } from '@portal/schema';

import { itemURL } from '@portal/config/routes';

import { Badge, Text } from '@shared/components/bootstrap';

import { ViewAttachments } from './attachments';

interface IItemSelectionTableProps {
  itemSelections: ClaimItemSelectionFragment[];
  setOpenAttachments(attachments: AttachmentFragment[]): void;
}

export const ItemSelectionTable = ({ itemSelections, setOpenAttachments }: IItemSelectionTableProps) => (
  <table className="claim-view__table table">
    <thead>
      <tr className="d-none d-md-table-row">
        <th className="d-none d-md-table-cell">Item #</th>
        <th className="d-none d-md-table-cell">Name</th>
        <th className="d-none d-md-table-cell">Attachments</th>
      </tr>
    </thead>
    <tbody>
      {itemSelections.map((itemSelection: ClaimItemSelectionFragment) => {
        const { id, name, kind, photos, item } = itemSelection;
        return (
          <tr className="d-md-table-row" key={id}>
            <td className="d-block d-md-table-cell">
              <ViewAttachments attachments={photos} setOpenAttachments={setOpenAttachments} isMobile />
              <Text weight="bold" wrapping="wrap">
                {item && item.uuid && item.barcode ? (
                  <Link to={itemURL(item.uuid)} target="_blank">
                    <Text style="info" tag="div">
                      #{item.barcode.value}
                    </Text>
                  </Link>
                ) : (
                  '-'
                )}
              </Text>
              <div className="d-block d-md-none">{name}</div>
              <Badge style="info">{kind}</Badge>
            </td>
            <td className="d-none d-md-table-cell">{name || '-'}</td>
            <td className="d-none d-md-table-cell">
              <ViewAttachments attachments={photos} setOpenAttachments={setOpenAttachments} />
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
);
