import * as qs from 'qs';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { NavigationProvider } from '@portal/contexts/navigation';

interface IStep {
  action: string;
  component(): JSX.Element;
}

interface IWorkflowProps {
  steps: IStep[];
  summary?: string;
  url(params: { uuid: string; id?: string; action?: string }): string;
  onNext(): void;
  onPrev(): void;
}

const QS_PARSE_OPTIONS = { ignoreQueryPrefix: true };

export class Workflow extends React.Component<IWorkflowProps> {
  public render() {
    const { steps, url } = this.props;
    const routes = steps.map(this.route);
    // Temporary redirect for users that have previously saved claims at the now deprecated receipts step
    routes.push(
      <Redirect
        key="receipts"
        from={url({ uuid: ':uuid', id: ':id', action: 'receipts' })}
        to={url({ uuid: ':uuid', id: ':id', action: 'declaration' })}
      />,
    );
    return <Switch>{routes}</Switch>;
  }

  private route = (step: IStep, index: number, steps: IStep[]) => {
    const { onNext: nextSelection, onPrev: prevSelection, summary, url } = this.props;
    return (
      <Route
        key={step.action}
        path={url({ uuid: ':uuid', id: ':id', action: step.action })}
        render={({
          match: {
            params: { uuid, id },
          },
          history,
          location,
        }) => {
          const redirectURL = qs.parse(location.search, QS_PARSE_OPTIONS).redirect_url;
          const goNext = () => history.push(redirectURL || url({ uuid, id, action: steps[index + 1].action }));
          const goPrev = () => history.push(redirectURL || url({ uuid, id, action: steps[index - 1].action }));

          const onPrev = index === 0 ? prevSelection : goPrev;
          const onNext = index === steps.length - 1 ? nextSelection : goNext;
          const onClose = () => history.push('/claims');
          return (
            <NavigationProvider value={{ onNext, onPrev, onClose, summary, progress: { index, total: steps.length } }}>
              <step.component />
            </NavigationProvider>
          );
        }}
      />
    );
  };
}
