import React from 'react';
import { L1_AGENT_CUSTOMER_ONLY_ERROR_MESSAGE, BLOCK_ACTION } from './util';

export const Form: React.FC<React.HTMLProps<HTMLFormElement> & { onActionBlocked?(error: string): void }> = ({
  onActionBlocked,
  children,
  ...props
}) => {
  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (!BLOCK_ACTION) {
      props.onSubmit?.(e);
    } else {
      e.preventDefault();
      e.stopPropagation();
      onActionBlocked?.(L1_AGENT_CUSTOMER_ONLY_ERROR_MESSAGE);
    }
  };
  return (
    <form {...props} onSubmit={onFormSubmit}>
      {children}
    </form>
  );
};
