import * as React from 'react';
import { useContext } from 'react';

import { filterInventoryForSave } from '@portal/utils';
import { Text } from '@shared/components/bootstrap';
import { InventoryContext } from '@shared/components/inventory_capture/inventory_context';

import { incompleteInventory } from './incomplete';
import { STEPS } from './steps';

const DEFAULT_INCOMPLETE_PLACEHOLDER = 'Not answered';

export const Summary: React.FC<{
  hideMoving?: boolean;
  hidePacking?: boolean;
  incompletePlaceholder?: string;
}> = ({ incompletePlaceholder = DEFAULT_INCOMPLETE_PLACEHOLDER, hideMoving, hidePacking }) => {
  const { data } = useContext(InventoryContext);

  const activeSteps = STEPS.filter((step) => step.isActive(data, { hideMoving, hidePacking }));

  const inventory = filterInventoryForSave(data);

  if (incompleteInventory(inventory)) {
    return (
      <Text tag="p" style="danger">
        {incompletePlaceholder}
      </Text>
    );
  }

  return (
    <>
      {activeSteps.map((step, index) => (
        <step.summary pickup={!hidePacking} key={index} />
      ))}
    </>
  );
};
