/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { useState } from 'react';

import styled from '@emotion/styled';
import { Text, COLORS, Label, Input } from '@clutter/clean';

import { Base } from '@portal/components/orders/steps/base';
import { IInboundShipmentInput, BOX_NAMES } from './shipping/shipping';

const Header = styled(Text.Title)`
  margin-bottom: 16px;
`;

const Subheader = styled(Text.Body)`
  margin-bottom: 32px;
  color: ${COLORS.storm};
`;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 200px;
`;

const Error = styled(Text.Callout)`
  margin-top: 4px;
  color: ${COLORS.toucan};
`;

const initializeShipmentArray = (count?: number) =>
  BOX_NAMES.slice(0, count || 0).map((char) => ({
    name: char,
    length: undefined,
    width: undefined,
    height: undefined,
    weight: undefined,
    contentDescription: '',
    easyPostRate: undefined,
    easyPostShipmentID: '',
  }));

export const InboundShipmentCount: React.FC<{
  onNext(): void;
  onPrev(): void;
  inboundShipments: IInboundShipmentInput[];
  setInboundShipments(inboundShipments: IInboundShipmentInput[]): void;
  boxCount?: number;
  setBoxCount(boxCount?: number): void;
  shippingItemLimit: number;
}> = ({ onNext, onPrev, inboundShipments, setInboundShipments, boxCount, setBoxCount, shippingItemLimit }) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const onChange = (value?: number) => {
    setBoxCount(value);
    if (value !== undefined && value <= 0) {
      setErrorMessage('Please enter a value greater than 0.');
    } else if (value !== undefined && value > shippingItemLimit) {
      setErrorMessage(`You can ship up to ${shippingItemLimit} boxes at once.`);
    } else {
      setErrorMessage(undefined);
      setInboundShipments(initializeShipmentArray(value));
    }
  };

  return (
    <Base onNext={onNext} onPrev={onPrev} valid={inboundShipments.length > 0 && !errorMessage}>
      <Header size="large">How many separate boxes will you ship?</Header>
      <Subheader>
        We will create a shipping label for each box. You can ship up to {shippingItemLimit} boxes at once.
      </Subheader>
      <Label>Number of boxes</Label>
      <StyledInput
        name="shipment_count"
        value={boxCount === null ? '' : boxCount}
        onChange={(event) => onChange(event.target.value === '' ? undefined : Number(event.target.value))}
        type="number"
        min={1}
        max={shippingItemLimit}
        state={errorMessage ? 'error' : undefined}
      />
      {errorMessage && <Error>{errorMessage}</Error>}
    </Base>
  );
};
