import { Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Billing__EarlyClosureFeeInvoice, InvoiceFragment } from '@portal/schema';
import { Table } from '@portal/components/shared/table';
import { Currency } from '@shared/components/helpers';

const StyledTable = styled(Table)`
  max-width: 450px;
  margin-top: 16px;
`;

type Invoice = Billing__EarlyClosureFeeInvoice | InvoiceFragment;

export const List: React.FC<{ invoices: Invoice[] }> = ({ invoices }) => (
  <StyledTable>
    <tbody>
      {invoices.map((invoice, idx) => (
        <tr key={`invoice-${idx}`}>
          <td>
            <Text.Callout>{invoice.summary}</Text.Callout>
          </td>
          <td>
            <Text.Callout>
              <Currency value={invoice.amountDue} />
            </Text.Callout>
          </td>
        </tr>
      ))}
    </tbody>
  </StyledTable>
);
