export function getOrdinalSuffix(value: number) {
  if (value < 0) {
    throw new Error(`Value cannot be less than zero: ${value}`);
  }

  value %= 100;

  if (value >= 11 && value <= 13) {
    return 'th';
  }

  switch (value % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}
