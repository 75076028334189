import React, { useState } from 'react';

import { ReviewForm } from '@portal/components/review/form';
import { Rating, Review__ReviewableEnum } from '@portal/schema';

import { ReviewSubmitted } from './review_submitted';

export const Review: React.FC<{
  reviewableID: string;
  rating?: Rating;
  facilityName: string;
  kind?: string;
  schedulingID?: string;
}> = ({ reviewableID, rating, facilityName, kind, schedulingID }) => {
  const [selectedRating, setSelectedRating] = useState<Rating | undefined>(rating);
  const [submitted, setSubmitted] = useState<boolean>(false);

  if (submitted) {
    return <ReviewSubmitted rating={selectedRating!} facilityName={facilityName} />;
  }

  return (
    <ReviewForm
      title="How would you rate your experience?"
      selectedRating={selectedRating}
      setSelectedRating={setSelectedRating}
      reviewable={{ id: reviewableID, type: Review__ReviewableEnum.SelfStorageRental }}
      onSubmission={() => setSubmitted(true)}
      kind={kind}
      schedulingID={schedulingID}
    />
  );
};
Review.displayName = 'SelfStorageReview';
