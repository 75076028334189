import { capitalize } from 'lodash';

import { ProtectionPlanFragment, StoragePlanFragment, Pricing__Plan__Dimensions } from '@portal/schema';

export const protectionName = (plan: ProtectionPlanFragment) =>
  plan.classification ? `${capitalize(plan.classification)} Item Protection` : 'Item Protection';

export const storageName = (plan: StoragePlanFragment) => {
  const { dimensions } = plan;
  return dimensions ? formattedDimensions(dimensions) : plan.name;
};

export const formattedDimensions = (dimensions: Pricing__Plan__Dimensions) =>
  `${dimensions.length}′ × ${dimensions.width}′`;
