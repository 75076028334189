import React from 'react';
import { DateTime } from 'luxon';
import { COLORS, Checkbox, Text } from '@clutter/clean';

import { Spacer } from '@shared/components/helpers';
import { Banner } from '@portal/components/shared/banner';
import { IMPERSONATOR } from '@portal/config/impersonator';

const formatDate = (date: DateTime) => date.toLocaleString(DateTime.DATE_MED);

export const UnavailableDate: React.FC<{
  date: DateTime;
  overrideUnavailability: boolean;
  setOverrideUnavailability(overrideUnavailability: boolean): void;
}> = ({ date, overrideUnavailability, setOverrideUnavailability }) => {
  const allowUnavailabilityOverride =
    IMPERSONATOR?.roles.includes('admin') || IMPERSONATOR?.roles.includes('dispatcher');

  return (
    <>
      <Spacer height="1rem" />
      <Banner
        borderColor={COLORS.sunshine}
        text={
          <Text.Body>
            <b>{formatDate(date)}</b> is not available.{' '}
            {allowUnavailabilityOverride
              ? `Please select another date from the calendar. You may also click 'Override Unavailability' to select ${formatDate(
                  date,
                )} if you have confirmed with the warehouse that they can service the appointment on that date.`
              : 'Please select another date from the calendar.'}
          </Text.Body>
        }
      />
      <Spacer height="1rem" />
      {allowUnavailabilityOverride && (
        <>
          <Checkbox.Selector
            name="override-unavailability"
            values={{ 'override-unavailability': overrideUnavailability }}
            options={[
              {
                key: 'override-unavailability',
                label: `Override Unavailability`,
              },
            ]}
            onChange={() => setOverrideUnavailability(!overrideUnavailability)}
          />
          <Spacer height="1rem" />
        </>
      )}
    </>
  );
};
