import { OrderServiceTypeEnum } from '@portal/schema';

import { ISkipProps } from './workflows/types';
import { STANDARD_WORKFLOW } from './workflows/standard';
import { SHIPPING_WORKFLOW } from './workflows/shipping';

export const next = (step: string, skipProps: ISkipProps): string => {
  const workflow = skipProps.serviceType === OrderServiceTypeEnum.Shipment ? SHIPPING_WORKFLOW : STANDARD_WORKFLOW;
  const iterator = workflow[Symbol.iterator]();

  for (let result = iterator.next(); !result.done; result = iterator.next()) {
    if (result.value.name === step) {
      break;
    }
  }
  for (let result = iterator.next(); !result.done; result = iterator.next()) {
    if (!result.value.skip || !result.value.skip(skipProps)) {
      return result.value.name;
    }
  }
  throw new Error(`unable to advance for step ${step}`);
};
