import React from 'react';

import { Box, ButtonLink, SkeletonLoader } from '@clutter/clean';

import { UsagePanel } from '@portal/components/usage/usage_panel';
import { ScheduleAppointmentLink } from '@portal/components/app/schedule_appointment_link';
import { DownsizePanel } from '@portal/components/downsize/panel';
import { useItemViewContext } from '../item_view_context';

const CONTAINER_MARGIN = ['48px 0 0', null, null, '60px 0 0'];

const Loader = () => (
  <Box margin={CONTAINER_MARGIN}>
    <SkeletonLoader height={['124px', null, null, '61px']} />
  </Box>
);

const Actions: React.FC = () => {
  const hasItem = !!useItemViewContext().selectedItem;

  return (
    <Box margin={CONTAINER_MARGIN}>
      <DownsizePanel />
      <Box.Flex margin="-12px -16px" flexWrap={['wrap', null, hasItem ? 'wrap' : 'nowrap']} alignItems="flex-end">
        <Box.Flex margin="12px 16px" width="100%">
          <UsagePanel />
        </Box.Flex>
        <Box.Flex margin="12px 16px" width="100%" maxWidth={[null, null, hasItem ? null : '300px']}>
          <ScheduleAppointmentLink
            component={({ href, children, onClick }) => (
              <ButtonLink fullWidth to={href} onClick={onClick}>
                {children}
              </ButtonLink>
            )}
          />
        </Box.Flex>
      </Box.Flex>
    </Box>
  );
};

const Combined = Object.assign(Actions, { Loader });

export { Combined as Actions };
