import * as React from 'react';
import { useContext } from 'react';

import { CategoriesContext } from '@shared/components/inventory_capture/categories_context';

import { IEstimationCategory, IEstimationSelection } from '@shared/types';
import { Option } from './option';

type Selections = Map<IEstimationCategory, Partial<IEstimationSelection>>;

export const Picker: React.FC<{
  selections?: Selections;
  onChange(selections: Selections): void;
}> = ({ selections = new Map<IEstimationCategory, Partial<IEstimationSelection>>(), onChange }) => {
  const data = useContext(CategoriesContext);
  if (!data) {
    return null;
  }
  const { estimationCategories: categories = [] } = data;
  const unpackableCategories = categories.filter(({ packable }) => !packable);

  return (
    <>
      {unpackableCategories.map((category) => {
        const selection = selections.get(category);
        return (
          <Option
            key={category.id}
            {...selection}
            category={category}
            onChange={(changes: Partial<IEstimationSelection>) => {
              onChange(new Map(selections).set(category, { ...selection, ...changes }));
            }}
          />
        );
      })}
    </>
  );
};
