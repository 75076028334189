import * as React from 'react';

import {
  ClaimPropertySelectionChanger,
  ClaimPropertySelectionProvider,
  DEFAULT_CLAIM_PROPERTY_SELECTION,
} from '@portal/contexts';
import { DetailedClaimFragment } from '@portal/schema';

import { Wizard } from './wizard';

interface ISelectionProps {
  claim: DetailedClaimFragment;
  onChange(changer: (_: DetailedClaimFragment) => DetailedClaimFragment): void;
  onNext(): void;
  onPrev(): void;
}

export class Selection extends React.Component<ISelectionProps, {}> {
  public render() {
    const { onNext, onPrev, claim } = this.props;
    const { onChange } = this;
    const selection = claim.propertySelection || DEFAULT_CLAIM_PROPERTY_SELECTION;

    return (
      <ClaimPropertySelectionProvider value={{ selection, onChange }}>
        <Wizard onNext={onNext} onPrev={onPrev} />
      </ClaimPropertySelectionProvider>
    );
  }

  private onChange = (changer: ClaimPropertySelectionChanger) => {
    const { onChange } = this.props;
    onChange((claim) => ({
      ...claim,
      propertySelection:
        typeof changer === 'function' ? changer(claim.propertySelection || DEFAULT_CLAIM_PROPERTY_SELECTION) : changer,
    }));
  };
}
