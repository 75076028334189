import styled from '@emotion/styled';
import axios from 'axios';
import React, { useState } from 'react';

import { Button } from '@portal/components/shared/button';
import { Banner as ErrorBanner } from '@portal/components/shared/error';
import { Input, InputLabel } from '@portal/components/shared/form';
import { useTrack } from '@clutter/wt';
import { EventSchema, Portal__LoginAttempt } from '@shared/wt';

const Form = styled.form`
  margin-top: 16px;
`;

const InputContainer = styled.div`
  margin-bottom: 16px;
`;

const CTAButton = styled(Button)`
  width: 100%;
  margin: 24px 0;
`;

export const LoginForm: React.FC = () => {
  const [email, setEmail] = useState<string>(() => new URLSearchParams(window.location.search).get('email') || '');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const track = useTrack();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);

    const params: Portal__LoginAttempt = {
      schema: EventSchema.Portal__LoginAttempt,
      action: 'click',
      metadata: { email },
    };
    track(params);

    if (!email || !password) {
      setError('Please enter your login credentials to continue.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('/login.json', { customer: { email, password, remember_me: 1 } });
      window.location.replace(response.data.location);
    } catch {
      setError("We couldn't find an account with that email and password.");
    }
    setLoading(false);
  };

  return (
    <>
      {error && <ErrorBanner>{error}</ErrorBanner>}
      <Form onSubmit={onSubmit}>
        <InputContainer>
          <InputLabel htmlFor="customer-email">Email</InputLabel>
          <Input
            id="customer-email"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value || '')}
            autoFocus
          />
        </InputContainer>
        <InputContainer>
          <InputLabel htmlFor="customer-password">Password</InputLabel>
          <Input
            id="customer-password"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value || '')}
            autoComplete="off"
          />
        </InputContainer>
        <CTAButton kind="primary" size="medium" loading={loading}>
          Log in
        </CTAButton>
      </Form>
    </>
  );
};
