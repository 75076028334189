import React, { useState } from 'react';

import { Radio } from '@clutter/clean';
import { Address } from '@portal/schema';
import { Page } from './page';

const TITLE = 'Choose your billing address';

type AddressFragment = Pick<Address, 'aptsuite' | 'street' | 'city' | 'state' | 'zip' | 'country'>;

export const Selector: React.FC<{
  loading: boolean;
  addresses: readonly AddressFragment[];
  onBack?(): void;
  onContinue(address: AddressFragment | null): void;
}> = ({ addresses, loading, onBack, onContinue }) => {
  const [address, setAddress] = useState<AddressFragment | undefined | null>();

  const onNext = () => {
    if (address !== undefined) {
      onContinue(address);
    }
  };

  const options:
    | Array<{
        value: AddressFragment | null;
        label: string | JSX.Element;
      }>
    | undefined = addresses?.map((entry) => ({
    value: entry,
    label: (
      <>
        {entry.street}, {entry.city}, {entry.state}
      </>
    ),
  }));

  options?.push({
    value: null,
    label: <>Add a new address</>,
  });

  return (
    <Page title={TITLE} loading={loading} ready={address !== undefined} onBack={onBack} onNext={onNext}>
      <Radio.Selector name="address" options={options ?? []} value={address} onChange={setAddress} />
    </Page>
  );
};
