import React from 'react';

import { COLORS } from '@clutter/clean';

export const StarsRight: React.FC<{ color?: string }> = ({ color = COLORS.tiger }) => (
  <svg aria-hidden="true" width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.83119 6.73438L9.05073 12.0288L14.6716 13.1689L9.05073 14.3176L7.83119 19.6121L6.62086 14.3176L1.00003 13.1689L6.62086 12.0288L7.83119 6.73438Z"
      fill={color}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.2292 1L19.6143 3.77246L22.5485 4.13521L19.6143 4.49797L19.2292 7.27043L18.8441 4.49797L15.9007 4.13521L18.8441 3.77246L19.2292 1Z"
      fill={color}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M24.6025 9.68066V13.17" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M26.4545 11.4258H22.75" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
