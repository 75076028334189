import { Button, COLORS, FontWeight, Text, UnstyledButton } from '@clutter/clean';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

import { EIGHT_HOURS_IN_SECONDS, ITEM_INVENTORY_PAGE_NAME } from '@portal/components/orders/estimation/item_inventory';
import { TrackedClick } from '@portal/components/wt/tracked_click';
import { orderURL } from '@portal/config/routes';
import { wt } from '@portal/initializers/wt';
import { Pluralize, Spacer } from '@shared/components/helpers';
import {
  LaborRate,
  Moving__QuoteSourceEnum,
  Order,
  useMovingQuoteRegenerateMutation,
  AvailabilitiesInputKind,
} from '@portal/schema';
import { fetchAvailableForMovers } from '@portal/components/orders/home/available_for_movers';

import { RateMoversAndTrucks } from './rate_movers_and_trucks';
import { QuoteType } from './mover_count_modal';

const Warning = styled.div`
  padding: 12px 16px;
  border: 1px solid ${COLORS.toucan};
  background: ${COLORS.grayBackground};
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const ActionLink = styled(Link)`
  color: ${COLORS.tealPrimary};
  font-weight: 500;
  &:hover {
    color: ${COLORS.tealBrand};
    text-decoration: none;
  }

  cursor: pointer;
`;

const ActionButton = styled(UnstyledButton)`
  color: ${COLORS.tealPrimary};
  font-weight: 500;
  font-size: 14px;
  &:hover {
    color: ${COLORS.tealBrand};
    text-decoration: none;
  }

  cursor: pointer;
`;

const quoteOverMaxDuration = (quote: QuoteType): boolean =>
  (quote?.estimatedOrderDuration ?? 0) > EIGHT_HOURS_IN_SECONDS;

export const TeamStep: React.FC<{
  pageName: string;
  error?: string;
  estimationsUpdateLoading: boolean;
  moverCount: number;
  moverCountQuoteMap: Map<number, QuoteType>;
  order: Pick<Order, 'id' | 'movers' | 'scheduled'> & { laborRate: Pick<LaborRate, 'id' | 'amount'> };
  onSave: () => void;
  goToReschedule: () => void;
  hideModal: () => void;
  setMoverCount: (newMoverCount: number) => void;
  setMoverCountQuoteMap: (newMoverCountQuoteMap: Map<number, QuoteType>) => void;
  setError: (newError?: string) => void;
}> = ({
  pageName,
  error,
  estimationsUpdateLoading,
  order,
  moverCount,
  moverCountQuoteMap,
  onSave,
  hideModal,
  goToReschedule,
  setMoverCount,
  setMoverCountQuoteMap,
  setError,
}) => {
  const [laborRateAmount, setLaborRateAmount] = React.useState<number>(order.laborRate.amount);
  const [truckCount, setTruckCount] = React.useState<number | undefined | null>(undefined);
  const [availableForMovers, setAvailableForMovers] = React.useState(true);
  const [overMaxDuration, setOverMaxDuration] = React.useState(false);

  const [quoteRegenerate, { loading: quoteLoading }] = useMovingQuoteRegenerateMutation();

  const formattedDate = DateTime.fromISO(order.scheduled).toLocaleString({
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
  });

  const hasMoverCountChanged = moverCount !== order.movers;

  useEffect(() => {
    onMoverCountChange(order.movers);
  }, []);

  const onMoverCountChange = async (newMoverCount: number) => {
    setError(undefined);
    setMoverCount(newMoverCount);

    const existingQuote = moverCountQuoteMap.get(newMoverCount);

    const data = await fetchAvailableForMovers({
      from: order.scheduled,
      till: order.scheduled,
      orderID: order.id,
      moverOverride: newMoverCount,
      kind: AvailabilitiesInputKind.Rescheduling,
    });

    if (!data?.availableForMovers && hasMoverCountChanged) {
      wt.track({
        action: 'click',
        objectName: 'movers_unavailable_card',
        objectType: 'card',
        pageName: pageName,
        container: 'mover_count_modal',
        value: newMoverCount,
        label: `${newMoverCount} movers not available on ${formattedDate}. You'll need to change the date & time of your move or reduce the number of movers.`,
        order_id: order.id,
        date: formattedDate,
      });
    }

    setAvailableForMovers(!!data?.availableForMovers);

    if (existingQuote) {
      setOverMaxDuration(quoteOverMaxDuration(existingQuote));
      setLaborRateAmount(existingQuote.laborRate?.amount ?? 0);
      setTruckCount(existingQuote.truckCount);
      return;
    }

    const { data: quoteRegenerateData } = await quoteRegenerate({
      variables: {
        orderID: order.id,
        source: Moving__QuoteSourceEnum.AccountPortalMoverOverride,
        quoteInput: {
          moverOverride: newMoverCount,
        },
      },
    });

    const quote = quoteRegenerateData?.movingQuoteRegenerate?.movingQuote;

    if (quoteRegenerateData?.movingQuoteRegenerate?.error || !quote || !quote.eligibility) {
      setMoverCount(order.movers);
      setLaborRateAmount(order.laborRate.amount);
      setError(
        quoteRegenerateData?.movingQuoteRegenerate?.error ?? 'An unknown error occurred. Please, try again later.',
      );
    } else {
      setLaborRateAmount(quote.laborRate?.amount ?? 0);

      const exceedsMaxDuration = quoteOverMaxDuration(quote);

      if (exceedsMaxDuration) {
        wt.track({
          action: 'click',
          objectName: 'max_duration_card',
          objectType: 'card',
          pageName: pageName,
          container: 'mover_count_modal',
          value: newMoverCount,
          label: `${newMoverCount} movers is insufficient for your move size. You’ll need to remove items in order to reduce the number of movers.`,
          order_id: order.id,
        });
      }

      setOverMaxDuration(exceedsMaxDuration);

      const newMoverCountQuoteMap = moverCountQuoteMap;
      newMoverCountQuoteMap.set(newMoverCount, quote);
      setMoverCountQuoteMap(newMoverCountQuoteMap);

      setTruckCount(quote.truckCount);
    }
  };

  return (
    <>
      <Text.Title size="medium" color={COLORS.tealDark}>
        Your moving team
      </Text.Title>
      <Text.Body>Add or remove movers to fit your needs</Text.Body>
      <Spacer height="1rem" />
      <RateMoversAndTrucks
        estimationsUpdateLoading={estimationsUpdateLoading}
        laborRateAmount={laborRateAmount}
        moverCount={moverCount}
        quoteLoading={quoteLoading}
        truckCount={truckCount}
        onMoverCountChange={onMoverCountChange}
      />
      {overMaxDuration && !quoteLoading && (
        <Warning>
          <Text.Callout weight={FontWeight.Medium} color={COLORS.toucan}>
            <Pluralize count={moverCount} singular="mover" plural="movers" /> is insufficient for your move size
          </Text.Callout>
          <Text.Callout>
            You’ll need to{' '}
            <TrackedClick
              params={{
                action: 'click',
                objectName: 'remove_items_button',
                objectType: 'button',
                pageName: pageName,
                container: 'mover_count_modal',
                label: 'remove items',
                order_id: order.id,
              }}
            >
              {pageName === ITEM_INVENTORY_PAGE_NAME ? (
                <ActionButton onClick={hideModal}>remove items</ActionButton>
              ) : (
                <ActionLink to={orderURL(order.id, 'virtual_walkthrough')}>remove items</ActionLink>
              )}
            </TrackedClick>{' '}
            in order to reduce the number of movers.
          </Text.Callout>
        </Warning>
      )}
      {!availableForMovers && !quoteLoading && hasMoverCountChanged && (
        <Warning>
          <Text.Callout weight={FontWeight.Medium} color={COLORS.toucan}>
            <Pluralize count={moverCount} singular="mover" plural="movers" /> not available on {formattedDate}
          </Text.Callout>
          <Text.Callout>
            You'll need to change the date & time of your move or reduce the number of movers.
          </Text.Callout>
        </Warning>
      )}
      <Spacer height="1rem" />
      <TrackedClick params={saveChangesButtonTrackingParams(order.id, availableForMovers, pageName)}>
        <Button
          size="small"
          fullWidth={true}
          disabled={!hasMoverCountChanged || quoteLoading || !!error || overMaxDuration}
          loading={estimationsUpdateLoading}
          onClick={availableForMovers ? onSave : goToReschedule}
        >
          {availableForMovers ? 'Save Changes' : 'Continue to reschedule'}
        </Button>
      </TrackedClick>
    </>
  );
};

function saveChangesButtonTrackingParams(orderID: string, availableForMovers: boolean, pageName: string) {
  return {
    pageName,
    container: 'mover_count_modal',
    action: 'click',
    objectType: 'button',
    objectName: availableForMovers ? 'save_changes_button' : 'continue_to_reschedule_button',
    label: availableForMovers ? 'Save Changes' : 'Continue to reschedule',
    value: 'modal',
    order_id: orderID,
  };
}
