import React from 'react';
import { sumBy } from 'lodash';
import { Box, COLORS, FontWeight, mq, Text } from '@clutter/clean';
import styled from '@emotion/styled';

const MAX_DURATION = 8;
const TIMELINE = ['Estimated time', '1 hr', '2 hrs', '3 hrs', '4 hrs', '5 hrs', '6 hrs', '7 hrs', '8 hrs max'];
const CIRCLE_COLORS = [COLORS.tealBrand, COLORS.dew, COLORS.tealPrimary];
const SECONDS_IN_AN_HOUR = 3600;
const PLACEHOLDER_SECTIONS = [
  {
    key: 'packing',
    seconds: 9576,
    label: 'Packing:',
  },
  {
    key: 'driving',
    seconds: 9576,
    label: 'Driving:',
  },
  {
    key: 'unpacking',
    seconds: 9576,
    label: 'Unpacking:',
  },
];

const SectionBar = styled.div<{ percentage: number }>`
  text-align: center;
  padding: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 34px;
  width: ${({ percentage }) => `${percentage * 100}%`};
  transition: width 0.5s;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 34px;
  border-radius: 60px;
  background: ${COLORS.grayBackground};
  box-shadow: inset -1px 1px 4px rgba(0, 0, 0, 0.1);
  ${SectionBar}:first-of-type {
    background: ${COLORS.tealBrand};
    border-radius: 60px 0 0 60px;
  }
  ${SectionBar}:nth-of-type(2) {
    background: ${COLORS.dew};
  }
  ${SectionBar}:last-of-type {
    border-radius: 0 60px 60px 0;
    background: ${COLORS.tealPrimary};
  }
  color: ${COLORS.cloud};
`;

const CounterBar = styled.div`
  border-left: 1px ${COLORS.grayBorder} solid;
  padding: 0 4px 0;
`;

const Counter = styled.div`
  ${mq({ display: ['none', null, 'flex'] })}
  justify-content: space-between;
  background: ${COLORS.cloud};
  ${CounterBar}:first-of-type {
    border: none;
  }
`;

const CounterText = styled(Text.Caption)`
  font-size: 9px;
`;

const MobileText = styled(Text.Caption)`
  ${mq({ display: ['block', null, 'none'] })}
`;

const LegendCircle = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ color }) => color};
`;

const Legend = styled.div`
  display: flex;
  ${mq({
    flexDirection: ['column', null, 'row'],
    alignItems: ['flex-start', null, 'center'],
    gap: ['2px', null, '20px'],
  })}
  padding-bottom: 8px;
`;

const LegendText = styled(Text.Caption)<{ total?: boolean; time?: boolean }>`
  display: inline;
  font-weight: ${({ total, time }) => (total || time ? FontWeight.Medium : FontWeight.Regular)};
  color: ${({ total }) => (total ? COLORS.panther : COLORS.hippo)};
`;

export type TimelineSection = { key: string; seconds: number; label: string };

const roundedTime = (time: number) => Number((time / SECONDS_IN_AN_HOUR).toFixed(1));

export const Timeline: React.FC<{
  sections?: TimelineSection[];
  requiredSeconds?: number;
}> = ({ sections, requiredSeconds = 0 }) => {
  const requiredHours = roundedTime(requiredSeconds);
  // Note: All current required labor durations are rounded to the hour
  const roundedMinimum = Math.round(requiredHours);
  const total = roundedTime(sumBy(sections, 'seconds'));
  const usePlaceholder = !sections;
  const requiredSections = sections ?? PLACEHOLDER_SECTIONS;

  return (
    <Box padding="0 0 8px 0">
      <Legend>
        <Box>
          <LegendText total>Total: {total} hrs</LegendText>
        </Box>
        <Box.Flex gap={['8px', '8px', '20px']}>
          {requiredSections.map(({ key, seconds, label }, idx) => (
            <Box.Flex key={key} alignItems="center" gap="4px">
              <LegendCircle color={CIRCLE_COLORS[idx]} />
              <LegendText>
                {label} <LegendText time>{usePlaceholder ? '-' : roundedTime(seconds)} hrs</LegendText>
              </LegendText>
            </Box.Flex>
          ))}
        </Box.Flex>
      </Legend>
      <Box.Flex alignItems="center">
        <MobileText>Est. time</MobileText>
        <Container>
          {requiredSections.map(({ key, seconds }) => (
            <SectionBar key={key} percentage={roundedTime(seconds) / MAX_DURATION} />
          ))}
        </Container>
        <MobileText style={{ textAlign: 'end' }}>8hr max</MobileText>
      </Box.Flex>
      <Counter>
        {TIMELINE.map((time, idx) => (
          <CounterBar key={time}>
            <CounterText>
              {time}
              {roundedMinimum !== 0 && idx === roundedMinimum ? ' min' : ''}
            </CounterText>
          </CounterBar>
        ))}
      </Counter>
    </Box>
  );
};
