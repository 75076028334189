import React from 'react';

import { CostRow, CostTable } from '@portal/components/shared/cost_table';
import { Billing__InvoiceItem } from '@portal/schema';
import { currency } from '@shared/utils/currency';

const InvoiceItemRow: React.FC<{ invoiceItem: Billing__InvoiceItem }> = ({ invoiceItem }) => {
  const { amount, displayDescription, quantity, unitPrice } = invoiceItem;

  let details;
  if (quantity && unitPrice) {
    details = `${quantity} items at ${currency(unitPrice)} each`;
  }

  return <CostRow item={displayDescription} description={details} amount={amount} />;
};

export const InvoiceItems: React.FC<{ invoiceItems: Billing__InvoiceItem[] }> = ({ invoiceItems }) => (
  <CostTable removeBottomBorder removeTopBorder>
    <tbody>
      {invoiceItems.map((item) => (
        <InvoiceItemRow key={item.id} invoiceItem={item} />
      ))}
    </tbody>
  </CostTable>
);
