import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Button, InputGroup } from '@shared/components/bootstrap';

import { Label } from '@clutter/clean';

import { DEFAULT_ADDRESS } from '@portal/components/addresses/utils';
import {
  GoogleMapsPlacesAutocompleteInput,
  IGoogleMapsPlace,
} from '@shared/components/helpers/google_maps_places_autocomplete_input';
import { IAddress, IGeocoded } from '@shared/types';
import { Error } from './error';

const ALLOWED_GOOGLE_MAPS_PLACE_TYPES = ['street_address', 'premise', 'subpremise'];

const UNALLOWED_GOOGLE_MAPS_PLACE_TYPE_MESSAGE = 'Please select a street address.';
const INCOMPLETE_GOOGLE_MAPS_PLACE_MESSAGE = 'You must select a valid address from the dropdown.';

export const StreetAddress: React.FC<{
  address: IAddress;
  onSelect(address: IAddress & IGeocoded): void;
  validationError?: string;
  disabled?: boolean;
}> = ({ address, onSelect, validationError, disabled = false }) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const [saved, setSaved] = React.useState<boolean>(!!address.id);
  const [addressError, setAddressError] = React.useState<string | undefined>(undefined);

  const onAutocomplete = (place: IGoogleMapsPlace) => {
    const { latitude, longitude, street, city, state, zip, country } = place;

    if (!place.types.some((type) => ALLOWED_GOOGLE_MAPS_PLACE_TYPES.some((allowed) => allowed === type))) {
      setAddressError(UNALLOWED_GOOGLE_MAPS_PLACE_TYPE_MESSAGE);
      return;
    }
    if (!street || !city || !state || !country || !zip) {
      setAddressError(INCOMPLETE_GOOGLE_MAPS_PLACE_MESSAGE);
      return;
    }

    setQuery(undefined);
    setAddressError(undefined);
    onSelect({ latitude, longitude, street, city, state, zip, country });
    setSaved(true);
  };

  const onReset = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onSelect({ ...DEFAULT_ADDRESS, latitude: 0, longitude: 0 });
    setAddressError(undefined);
    setSaved(false);
  };

  const error = addressError || (!saved && validationError);

  return (
    <div className="form-group">
      <Label>Address</Label>
      {error && <Error>{error}</Error>}
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <FontAwesomeIcon icon="map-marker" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <GoogleMapsPlacesAutocompleteInput
          value={query || (saved && `${address.street}, ${address.city}, ${address.state} ${address.zip}`) || ''}
          onChange={(event) => {
            setAddressError(undefined);
            setQuery(event.target.value || undefined);
          }}
          onBlur={() => {
            if (!address.street || !address.city || !address.state || !address.country || !address.zip) {
              setAddressError(INCOMPLETE_GOOGLE_MAPS_PLACE_MESSAGE);
            }
          }}
          disabled={disabled || saved}
          type="text"
          className="form-control"
          onAutocomplete={onAutocomplete}
        />
        {saved && !disabled && (
          <InputGroup.Append>
            <Button kind="secondary" onClick={onReset}>
              Reset
            </Button>
          </InputGroup.Append>
        )}
      </InputGroup>
    </div>
  );
};
