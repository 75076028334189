import styled from '@emotion/styled';
import React from 'react';

import { Currency } from '@shared/components/helpers';

type RateAdjustmentMetadata = {
  rate_adjustment_id: string;
  unit_name: string;
  old_rent_price: string;
};

const List = styled.ul`
  margin-top: 16px;
`;

const ListItem = styled.li`
  padding: 4px 0;
`;

const RentalListItem: React.FC<{ metadata: RateAdjustmentMetadata; eligible: boolean }> = ({ metadata, eligible }) => {
  const { unit_name, old_rent_price } = metadata;
  return (
    <ListItem>
      {unit_name}
      {eligible && <> for {<Currency value={Number(old_rent_price)} precision="automatic" />}/mo</>}
    </ListItem>
  );
};

export const RentalList: React.FC<{ rateAdjustments: RateAdjustmentMetadata[]; eligible: boolean }> = ({
  rateAdjustments,
  eligible,
}) => (
  <List>
    {rateAdjustments?.map((rateAdjustmentMetadata: RateAdjustmentMetadata) => (
      <RentalListItem
        key={rateAdjustmentMetadata.rate_adjustment_id}
        metadata={rateAdjustmentMetadata}
        eligible={eligible}
      />
    ))}
  </List>
);
