import { COLORS, List, Text, TextButton } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Message as ErrorMessage } from '@portal/components/shared/error';
import { TrackedLink } from '@portal/components/wt/tracked_link';
import { accountURL } from '@portal/config/routes';
import { Pricing__Set__AppointmentFees as AppointmentFees, PricingSetPriceEntryFragment } from '@portal/schema';

import { CommitmentOption } from '../commitment/selector';
import { Footer } from '../footer';
import { SummaryListItem } from './summary_list_item';
import { supportedServiceType } from '../service/util';
import {
  commitmentChanged,
  commitmentValue,
  formattedServiceType,
  formatFees,
  planMetadata,
  PLAN_UPDATE_CONFIRM_PAGE_NAME,
  protectionChanged,
  protectionTitle,
  protectionValue,
  serviceChanged,
  storageChanged,
  storageTitle,
  storageValue,
} from '../util';

const Container = styled.div`
  max-width: 650px;
`;

const Title = styled(Text.Title)`
  color: ${COLORS.panther};
  padding-bottom: 24px;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 42px;
`;

const CANCEL_BUTTON_TEXT = 'Cancel Updates';

export const Confirm: React.FC<{
  orderID: string;
  error?: string;
  isAddingPlan: boolean;
  hideLabor: boolean;
  initialStoragePriceEntry?: PricingSetPriceEntryFragment;
  initialProtectionPriceEntry?: PricingSetPriceEntryFragment;
  initialCommitment?: CommitmentOption;
  selectedCommitment?: CommitmentOption;
  initialAppointmentFees?: AppointmentFees;
  selectedStoragePriceEntry: PricingSetPriceEntryFragment;
  selectedProtectionPriceEntry: PricingSetPriceEntryFragment;
  selectedAppointmentFees: AppointmentFees;
  loading: boolean;
  onNext(): void;
  onPrev(): void;
}> = ({
  orderID,
  error,
  isAddingPlan,
  hideLabor,
  initialStoragePriceEntry,
  selectedStoragePriceEntry,
  initialCommitment,
  selectedCommitment,
  initialProtectionPriceEntry,
  selectedProtectionPriceEntry,
  initialAppointmentFees,
  selectedAppointmentFees,
  loading,
  onNext,
  onPrev,
}) => {
  const metaData = planMetadata(orderID, selectedStoragePriceEntry, selectedProtectionPriceEntry);

  return (
    <>
      <Container>
        <Title size="large">{isAddingPlan ? 'Confirm your plan' : 'Confirm your updates'}</Title>
        {error && <ErrorMessage message={error} />}
        <List borderColor={COLORS.grayBorder} backgroundColor={COLORS.cloud} shadow={false}>
          <SummaryListItem
            title={storageTitle(selectedStoragePriceEntry)}
            value={storageValue(selectedStoragePriceEntry)}
            highlight={storageChanged(selectedStoragePriceEntry, initialStoragePriceEntry)}
          />
          <SummaryListItem
            title={protectionTitle(selectedProtectionPriceEntry)}
            value={protectionValue(selectedProtectionPriceEntry)}
            highlight={protectionChanged(selectedProtectionPriceEntry, initialProtectionPriceEntry)}
          />
          <SummaryListItem
            title="Commitment"
            value={commitmentValue(selectedCommitment)}
            highlight={commitmentChanged(selectedCommitment, initialCommitment)}
          />
          {supportedServiceType(initialAppointmentFees?.serviceType) && !hideLabor && (
            <SummaryListItem
              title={formattedServiceType(selectedAppointmentFees.serviceType)}
              value={formatFees(selectedAppointmentFees)}
              highlight={serviceChanged(selectedAppointmentFees, initialAppointmentFees)}
            />
          )}
        </List>
        <ButtonContainer>
          <TrackedLink
            params={{
              pageName: PLAN_UPDATE_CONFIRM_PAGE_NAME,
              container: 'review_changes',
              action: 'click',
              objectType: 'link',
              objectName: 'cancel',
              label: CANCEL_BUTTON_TEXT,
              value: accountURL(),
              ...metaData,
            }}
          >
            <TextButton kind="destructive" href={accountURL()}>
              {CANCEL_BUTTON_TEXT}
            </TextButton>
          </TrackedLink>
        </ButtonContainer>
      </Container>
      <Footer
        pageName={PLAN_UPDATE_CONFIRM_PAGE_NAME}
        metaData={metaData}
        nextLabel="Confirm"
        onNext={onNext}
        onPrev={onPrev}
        loading={loading}
      />
    </>
  );
};
