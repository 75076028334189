import { COLORS, FontWeight, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Maybe } from '@portal/schema';
import { currency } from '@shared/utils/currency';

import { Table } from './table';

const Callout = styled(Text.Callout)`
  color: ${COLORS.panther};
`;

const Caption = styled(Text.Caption)`
  color: ${COLORS.storm};
  margin-top: 8px;
`;

export const CostRow: React.FC<{ item: string; description?: string; amount?: Maybe<number>; useFree?: boolean }> = ({
  item,
  description,
  amount,
  useFree = true,
}) => (
  <tr>
    <td>
      <Callout weight={FontWeight.Medium}>{item}</Callout>
      <Caption>{description}</Caption>
    </td>
    <td>
      <Callout weight={FontWeight.Medium}>{amount || !useFree ? currency(amount ?? 0) : 'Free'}</Callout>
    </td>
  </tr>
);
CostRow.displayName = 'CostRow';

export const CostTable = styled(Table)`
  td {
    border-width: 1.5px;
  }
`;
CostTable.displayName = 'CostTable';
