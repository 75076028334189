import React from 'react';

export const TealBox = () => (
  <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.1833 41.865L44.5317 32.1803L18.509 17.6307L1.16052 27.338L27.1833 41.865Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.509 1.13513L9.83477 5.97745L1.16052 10.8198V19.1241V25.0752L27.1833 39.6022L44.5317 29.9175V15.6621L18.509 1.13513Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.1832 25.3467V39.6022"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.6831 26.0933L38.1722 23.5817V25.5729L33.6831 28.0846V26.0933Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.96082 16.2051L5.41579 17.5854L4.17661 14.7569L2.96082 16.2051Z"
      fill="#00A499"
      stroke="#00A499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.5317 15.6621L35.8574 20.5044L9.83472 5.97745L18.509 1.13513L44.5317 15.6621Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.5317 15.6622L27.1833 25.3469L1.16052 10.8199"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.5317 15.6621L18.509 1.13513L9.83472 1.45192L35.8574 15.9789L44.5317 15.6621Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
