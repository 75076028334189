import React, { useEffect, useRef } from 'react';
import { Box, BREAKPOINTS, COLORS, toggleStyleValue, Text, UnstyledButton } from '@clutter/clean';
import styled from '@emotion/styled';

import {
  CustomerItemFragment,
  CustomerItemTypeEnum,
  useCustomerItemQuery,
  Review__ReviewableEnum,
} from '@portal/schema';
import { BackArrow, Close } from '@portal/components/shared/icons';
import { NAV_BAR_HEIGHT } from '@portal/components/shared/layout';
import { itemNameText } from '@portal/components/helpers/inventory/item_card';

import { useItemViewContext } from '../item_view_context';

import { ItemDetailsForm } from './form';
import { Info } from './info';
import { Images } from './images';
import { PhotoCSATReview } from './photo_csat_review';

const reviewableEnum = (item: CustomerItemFragment) => {
  switch (item.__typename) {
    case 'Item':
      return Review__ReviewableEnum.Item;
    case 'ItemGroup':
      return Review__ReviewableEnum.ItemGroup;
    default:
      throw new Error('unknown Item type for review');
  }
};

const Content: React.FC<{
  item: CustomerItemFragment;
  planCuft: number;
}> = ({ item }) => {
  const { setSelectedItem, selectedItem } = useItemViewContext();
  return (
    // This key is required to ensure sub-components remount when the selected item changes
    <React.Fragment key={selectedItem?.uuid || 'item'}>
      <UnstyledButton onClick={() => setSelectedItem(undefined)}>
        <Box display={['none', null, null, 'inline']}>
          <Close />
        </Box>
        <Box display={['inline', null, null, 'none']}>
          <BackArrow fill={COLORS.panther} />
        </Box>
      </UnstyledButton>
      <Box padding="32px 0 24px">
        <Text.Title size="large">{itemNameText(item)}</Text.Title>
      </Box>
      <Images item={item} />
      <ItemDetailsForm item={item} />
      <Info item={item} />
      <Box margin="-16px">
        <PhotoCSATReview reviewableID={item.id} reviewableType={reviewableEnum(item)} />
      </Box>
    </React.Fragment>
  );
};

export const DetailInner: React.FC = () => {
  const { selectedItem } = useItemViewContext();

  const { data, error } = useCustomerItemQuery({
    variables: {
      uuid: selectedItem?.uuid || '',
      type: (selectedItem?.__typename || 'Item') as CustomerItemTypeEnum,
    },
    skip: !selectedItem,
  });

  if (error) throw error;
  const item = data?.customerItem;

  return (
    <Box margin="0 auto 128px" maxWidth="590px" data-test-id="item-detail-panel">
      {item && <Content item={item} planCuft={100} />}
    </Box>
  );
};

const Container = styled.div<{ open: boolean }>`
  left: 0;
  position: absolute;
  width: 100vw;
  display: ${toggleStyleValue('open', 'block', 'none')};
  z-index: 10;

  @media (min-width: ${BREAKPOINTS.LG}) {
    display: block;
    position: relative;
    flex-shrink: 0;
    will-change: width;
    transition: width 0.3s ease;
    width: ${toggleStyleValue('open', '488px', '0px')};
  }
`;

const Overlay = styled.div`
  position: fixed;
  height: calc(100vh - ${NAV_BAR_HEIGHT}px);
  flex-shrink: 0;
  background: ${COLORS.grayBackground};
  height: 100%;
  padding: 24px 24px 128px;
  overflow: hidden scroll;
  overscroll-behavior: contain;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.LG}) {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    padding: 64px 40px;
    width: initial;
  }
`;

export const Detail: React.FC = () => {
  const { selectedItem } = useItemViewContext();

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (selectedItem && containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [selectedItem]);

  return (
    <Container open={!!selectedItem}>
      <Overlay ref={containerRef}>
        <DetailInner />
      </Overlay>
    </Container>
  );
};
