import React from 'react';
import styled from '@emotion/styled';

import { COLORS, Text } from '@clutter/clean';

import { PlanSize, ThreeStars } from '@portal/components/shared/icons';
import { Spacer } from '@shared/components/helpers';

const SummaryCard = styled.div`
  background-color: ${COLORS.cloud};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

const SummaryCardHeader = styled.div`
  background-color: ${COLORS.dust};
  border-radius: 4px 4px 0 0;
  padding: 13px 0 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  h4 {
    margin: 0 9px 0;
  }
  svg:last-child {
    margin-top: -15px;
  }
`;

const SummaryCardBody = styled.div`
  padding: 24px;
  display: flex;
  align-items: flex-start;
`;

const IconContainer = styled.div`
  margin-right: 24px;
`;

export const GoodFitCard: React.FC<{ planSizeName: string }> = ({ planSizeName }) => (
  <SummaryCard>
    <SummaryCardHeader>
      <ThreeStars />
      <Text.Title size="extraSmall">You'll fit into a {planSizeName}</Text.Title>
      <ThreeStars flipHorizontal />
    </SummaryCardHeader>
    <SummaryCardBody>
      <IconContainer>
        <PlanSize />
      </IconContainer>
      <div>
        <Text.Callout>
          During your appointment, you'll be able to see how much space is left as your items are being moved.
        </Text.Callout>
        <Spacer height="12px" />
        <Text.Callout>
          If you end up needing less space, we'll automatically downgrade your plan size. Or if you need more space,
          we'll upgrade your plan to fit all your items.
        </Text.Callout>
      </div>
    </SummaryCardBody>
  </SummaryCard>
);
