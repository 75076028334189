import React, { useContext } from 'react';

import { ClaimPaymentContext } from '@portal/contexts/claim_payment';
import { ClaimPaymentOptionKind } from '@portal/schema';
import { Text } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';

import { PAYMENT_OPTION_DESCRIPTIONS } from './constants';
import { Digest } from '../digest';

const PAYMENT_OPTIONS = [ClaimPaymentOptionKind.Credit, ClaimPaymentOptionKind.Refund];

export const CompensationMethod: React.FC<{ uuid: string; totalAmount: number }> = ({ uuid, totalAmount }) => {
  const { data, onSelect } = useContext(ClaimPaymentContext);

  return (
    <div className="claim-view__payment-options">
      <span className="claim-view__section-header">Compensation Method</span>
      <Text tag="div">
        <span>
          The Trust & Safety Team completed the investigation into your request for compensation associated to claim{' '}
          <Digest uuid={uuid} />. Above is a detailed review of the determination.
          <br />
          <br />
          The approved compensation amount is <Currency value={totalAmount} />. You have an option to accept
          compensation as a credit or refund. Both credits and refunds are processed within 30 days of accepting a
          settlement.
        </span>
      </Text>
      <br />
      {PAYMENT_OPTIONS.map((option) => (
        <div key={option}>
          <label>
            <input
              type="radio"
              value={option}
              checked={option === data.kind}
              onChange={(event) => onSelect({ ...data, kind: event.target.value as ClaimPaymentOptionKind })}
            />
            {PAYMENT_OPTION_DESCRIPTIONS[option]}
          </label>
        </div>
      ))}
    </div>
  );
};
