import React, { useContext, useState } from 'react';
import { Box, Radio, Text, COLORS, SkeletonLoader } from '@clutter/clean';
import styled from '@emotion/styled';
import { range } from 'lodash';

import { Shipping__EasyPostInboundShipment, useCustomerPriceQuery } from '@portal/schema';
import { client } from '@portal/libraries/apollo';
import { IInboundShipmentInput } from './shipping/shipping';

import { Base } from './base';
import { IStepProps } from '../form';
import { convertToSelectorOptions, getCheapestOptionsByCarrier, IShippingOption } from './shipping/options';
import { OrderContext } from './order_context';

const HEADING = 'Select a shipping option';

const SUBHEADING = `Choose a carrier for your shipment. You'll be able to ship at your convenience, labels are good for 30 days.`;

const Header = styled(Text.Title)`
  margin-bottom: 16px;
`;

const Subheader = styled(Text.Body)`
  margin-bottom: 32px;
  color: ${COLORS.storm};
`;

const Container = styled(Box.Flex)`
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
`;

export const InboundShippingOptions: React.FC<
  {
    onNext(): void;
    onPrev(): void;
    setShipping(option: IShippingOption): void;
    inboundShipments: IInboundShipmentInput[];
    setInboundShipments(inboundShipments: IInboundShipmentInput[]): void;
    availableShippingOptions: Shipping__EasyPostInboundShipment[];
    setAvailableShippingOptions(availableShippingOptions: Shipping__EasyPostInboundShipment[]): void;
  } & IStepProps
> = ({
  onNext,
  onPrev,
  setShipping,
  inboundShipments,
  setInboundShipments,
  availableShippingOptions,
  setAvailableShippingOptions,
}) => {
  const { order: orderInput } = useContext(OrderContext);
  const [shippingOption, setShippingOption] = useState<string>();
  const { data, loading } = useCustomerPriceQuery({
    client,
    variables: {
      orderInput: orderInput,
      shippingOptions: getCheapestOptionsByCarrier(availableShippingOptions!),
    },
  });

  const updateShipment = (service: string) => {
    const updatedShipments = inboundShipments.map((shipment) => {
      const allOptionsForShipment = shipmentOptions.find((option) => option.itemName === shipment.name)!;
      return {
        ...shipment,
        easyPostRate: allOptionsForShipment.rates.find((option) => option.service === service),
        easyPostShipmentID: allOptionsForShipment.id,
        weight: allOptionsForShipment.weight,
      };
    });

    setShipping(bestOptions.find((option) => option.service === service)!);
    setInboundShipments(updatedShipments);
  };

  const onPrevClick = () => {
    const updatedAvailableShippingOptions = availableShippingOptions.slice(0, availableShippingOptions.length - 1);
    setAvailableShippingOptions(updatedAvailableShippingOptions);
    onPrev();
  };

  const Loader = () => {
    const loaderOptions = range(4).map((i) => ({
      value: i,
      label: <SkeletonLoader key={i} height="24px" width="225px" />,
    }));

    return (
      <Base>
        <Header size="large">{HEADING}</Header>
        <Container>
          <Radio.Selector name="loader" value={null} options={loaderOptions} onChange={() => null} />
        </Container>
      </Base>
    );
  };

  if (loading || !data) return <Loader />;

  const shipmentOptions = availableShippingOptions;
  const bestOptions = data.customerPrice;
  const selectorOptions = convertToSelectorOptions(bestOptions, true);

  return (
    <Base
      onNext={() => {
        updateShipment(shippingOption!);
        onNext();
      }}
      onPrev={onPrevClick}
      valid={!!shippingOption}
    >
      <Header size="large">{HEADING}</Header>
      <Subheader>{SUBHEADING}</Subheader>
      <Container>
        <Radio.Selector name="option" value={shippingOption} options={selectorOptions} onChange={setShippingOption} />
      </Container>
    </Base>
  );
};
