import { Uploader } from '@shared/hooks/uploader';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { head } from 'lodash';

const DEFAULT_PRESIGNING_URL = '/presigned_urls';

const upload = async ({
  file,
  setLoaded,
  setTotal,
}: {
  file: File;
  setLoaded: (_: number) => void;
  setTotal: (_: number) => void;
}) => {
  const presigning = await axios.post<{ url: string }>(DEFAULT_PRESIGNING_URL, { filename: file.name });
  const url = head(presigning.data.url.split('?'));
  if (!url) {
    throw new Error('No URL');
  }
  await axios({
    method: 'put',
    url: presigning.data.url,
    data: file,
    headers: {
      'content-type': file.type,
    },
    onUploadProgress: (event: ProgressEvent) => {
      setLoaded(event.loaded);
      setTotal(event.total);
    },
  });
  return url;
};

export const useLegacyAttachmentUpload: Uploader = ({ file, onError, onUpload }) => {
  const [retries, setRetries] = useState<number>(0);
  const [loaded, setLoaded] = useState<number | undefined>(undefined);
  const [total, setTotal] = useState<number | undefined>(undefined);
  const retry = () => {
    setLoaded(undefined);
    setTotal(undefined);
    setRetries((count) => count + 1);
  };

  useEffect(() => {
    let active = true;

    const attempt = upload({
      file,
      setLoaded: (value) => {
        if (active) {
          setLoaded(value);
        }
      },
      setTotal: (value) => {
        if (active) {
          setTotal(value);
        }
      },
    });
    attempt.then((url) => {
      if (active) {
        onUpload(url);
      }
    });
    attempt.catch((error) => {
      if (active) {
        onError(error);
      }
    });

    return () => {
      active = false;
    };
  }, [file, retries]);

  return {
    loaded,
    total,
    retry,
  };
};
