import cn from 'classnames';
import * as React from 'react';

const CardHeader: React.FC = ({ children }) => <div className="card-header">{children}</div>;

const CardFooter: React.FC = ({ children }) => <div className="card-footer">{children}</div>;

const CardBody: React.FC = ({ children }) => <div className="card-body">{children}</div>;

const Card: React.FC<{
  bg?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'transparent';
}> = ({ children, bg }) => <div className={cn('card', bg && `bg-${bg}`)}>{children}</div>;

const Combined = Object.assign(Card, {
  Header: CardHeader,
  Footer: CardFooter,
  Body: CardBody,
});
export { Combined as Card };
