import React from 'react';

import { range } from 'lodash';
import { Box } from '@clutter/clean';

import { InventoryContentQuery } from '@portal/schema';
import { Container, Offsets } from '@portal/components/helpers/inventory/layout';

import { Empty } from './empty';
import { ItemCard } from './item';

import { InfiniteScrollContainer } from './infinite_scroll_container';

const Loader = () => (
  <Container>
    {range(8).map((i) => (
      <Box key={i} margin="8px">
        <ItemCard.Loader />
      </Box>
    ))}
    <Offsets />
  </Container>
);

const Grid: React.FC<{
  pageSize: number;
  paginationData: InventoryContentQuery['paginatedCustomerItems'];
  fetchMore(page: number): Promise<unknown>;
}> = ({ paginationData, fetchMore, pageSize }) => {
  if (!paginationData.results.length)
    return (
      <Container>
        <Empty caption="No items matched your search filters." />
      </Container>
    );

  return (
    <InfiniteScrollContainer data={paginationData} fetchMore={fetchMore} pageSize={pageSize}>
      {({ items, placeholders }) => (
        <Container>
          {items.map((item) => (
            <Box key={item.uuid} margin="8px">
              <ItemCard item={item} />
            </Box>
          ))}
          {new Array(placeholders).fill(null).map((_, i) => (
            <Box key={i} margin="8px">
              <ItemCard.Loader />
            </Box>
          ))}
          <Offsets />
        </Container>
      )}
    </InfiniteScrollContainer>
  );
};

const Combined = Object.assign(Grid, { Loader });

export { Combined as Grid };
