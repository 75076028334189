import React from 'react';

import { COLORS, List } from '@clutter/clean';
import { OrderSubtypeEnum, OrderTypeEnum, UpcomingSubscriptionSetFragment } from '@portal/schema';
import { AppointmentDetailsOrder } from './full_panel';
import { OrderFeeLineItems } from './info_list/order_fee_line_items';
import { MovePackingSuppliesLineItem } from './info_list/move_packing_supplies_line_item';
import { OrderPackingHelpLineItem } from './info_list/order_packing_help_line_item';
import { MoveLaborLineItem } from './info_list/move_labor_line_item';
import { SupplyKitDeliveryLineItem } from './info_list/supply_kit_delivery_line_item';
import { SupplyKitMaterialsLineItem } from './info_list/supply_kit_materials_line_item';
import { MoveAddressLineItems } from './info_list/move_address_line_items';
import { FinancingLineItem } from './info_list/financing_line_item';
import { OrderContactLineItem } from './info_list/order_contact_line_item';
import { OrderAddressLineItem } from './info_list/order_address_line_item';
import { PromoCodeLineItem } from './info_list/promo_code_line_item';
import { MultiDayTimelineLineItem } from './info_list/multi_day_timeline';
import { MoveTransportFeeLineItem } from './info_list/move_transport_fee_line_item';

export const OrderAppointmentDetailsInfoList: React.FC<{
  upcomingSubscriptionSet: UpcomingSubscriptionSetFragment;
  order: AppointmentDetailsOrder;
  refetchData: () => void;
}> = ({ upcomingSubscriptionSet, order, refetchData }) => (
  <>
    {order.type === OrderTypeEnum.Move ? (
      <List borderColor={COLORS.grayBorder}>
        <MultiDayTimelineLineItem order={order} />
        <MoveAddressLineItems order={order} />
        <OrderContactLineItem order={order} />
        <MoveLaborLineItem
          order={order}
          refetchData={refetchData}
          flatRateAmount={order.movingPricingSummary?.flatRateAmount ?? undefined}
        />
        {!order.movingPricingSummary?.flatRateAmount && order.movingOperation?.longDistanceMinimumTransportFee && (
          <MoveTransportFeeLineItem order={order} />
        )}
        {!order.movingPricingSummary?.flatRateAmount && <MovePackingSuppliesLineItem order={order} />}
        <OrderFeeLineItems order={order} />
        <FinancingLineItem order={order} />
        <PromoCodeLineItem order={order} />
      </List>
    ) : (
      <List borderColor={COLORS.grayBorder}>
        <MultiDayTimelineLineItem order={order} />
        {order.type === OrderTypeEnum.RetailDelivery && <SupplyKitMaterialsLineItem delivery={order} />}
        {order.retailDelivery && <SupplyKitDeliveryLineItem order={order} delivery={order.retailDelivery} />}
        <OrderAddressLineItem
          orderId={order.id}
          address={order.address}
          showEdit={order.type !== OrderTypeEnum.RetailDelivery}
        />
        <OrderContactLineItem order={order} />
        {order.type !== OrderTypeEnum.RetailDelivery && <OrderFeeLineItems order={order} />}
        {order.type !== OrderTypeEnum.RetailDelivery && (
          <OrderPackingHelpLineItem order={order} upcomingSubscriptionSet={upcomingSubscriptionSet} />
        )}
        {order.subtype === OrderSubtypeEnum.Onboarding && <PromoCodeLineItem order={order} />}
      </List>
    )}
  </>
);
