import React, { useState } from 'react';

import { ClaimConsumer, ClaimPropertySelectionConsumer } from '@portal/contexts';

import { Alert } from '@shared/components/bootstrap';

import { Attachments } from '@portal/components/claims/helpers/attachments';
import { Step } from '@portal/components/claims/step';

import { ClaimPropertySelectionAttachmentName } from '@portal/schema';
import { Attacher } from './attacher';

const ACCEPT = 'image/*';

export const Photos = () => {
  const [uploading, setUploading] = useState(false);
  return (
    <ClaimConsumer>
      {({ claim: { uuid } }) => (
        <ClaimPropertySelectionConsumer>
          {({ selection: { photos }, onChange }) => (
            <Attacher
              name={ClaimPropertySelectionAttachmentName.Photos}
              uuid={uuid}
              onAttach={(attachment) => {
                onChange((selection) => ({
                  ...selection,
                  photos: [...selection.photos, attachment],
                }));
              }}
              onDetach={(attachment) => {
                onChange((selection) => ({
                  ...selection,
                  photos: selection.photos.filter(({ id: attachmentID }) => attachmentID !== attachment.id),
                }));
              }}
            >
              {({ attach, detach, loading, error }) => (
                <Step skippable saving={uploading || loading} valid={!!photos.length}>
                  <Step.Title>Please attach some photos of the damaged property.</Step.Title>
                  <Step.Subtitle>
                    Try to cover as many angles as you can, and make sure there is enough light.
                  </Step.Subtitle>
                  {error && <Alert style="danger">{error}</Alert>}
                  <Attachments
                    attachments={photos}
                    accept={ACCEPT}
                    onAttach={attach}
                    onDetach={detach}
                    onUploading={setUploading}
                  />
                </Step>
              )}
            </Attacher>
          )}
        </ClaimPropertySelectionConsumer>
      )}
    </ClaimConsumer>
  );
};
