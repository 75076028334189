import * as React from 'react';

import { IRoomCategory, IRoomSelection } from '@shared/types';

import { Switch } from '@shared/components/helpers';

export const SelectableOption: React.FC<{
  category: IRoomCategory;
  selected?: boolean;
  onChange(changes: Partial<IRoomSelection>): void;
}> = ({ category: { name }, selected, onChange }) => (
  <div className="form-group row">
    <label className="col-8 col-xs-8 col-form-label text-left">
      <span className="text-capitalize">{name}</span>
    </label>
    <div className="col-4 col-xs-4 text-right">
      <Switch kind="info" selected={selected} onSelect={(value) => onChange({ selected: value })} />
    </div>
  </div>
);
