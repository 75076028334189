import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLORS } from '@clutter/clean';

import { Link as CustomerProtectedLink } from '@portal/components/shared/customer_protected/link';
import { buildClaimURL, claimURL } from '@portal/config/routes';

import { Text } from '@shared/components/bootstrap';

import { Hero } from '@portal/components/helpers/hero';
import { Page } from '@portal/components/helpers/page';
import { Selection } from '@portal/components/helpers/selection';
import { UnstyledButton } from '@portal/components/shared/customer_protected/unstyled_button';
import { Timestamp } from '@shared/components/helpers';

import EMPTY from '@portal/images/empty.svg';
import { useClaimsQuery, ClaimFragment, ClaimStatus } from '@portal/schema';
import { Digest } from './digest';
import { Status } from './helpers/status';
import { Delete } from './list/delete';

const STATUSES = [
  ClaimStatus.Draft,
  ClaimStatus.InReview,
  ClaimStatus.Approved,
  ClaimStatus.Accepted,
  ClaimStatus.Denied,
];

interface IGroup {
  status: ClaimStatus;
  claims: ClaimFragment[];
}

const group = (all: ClaimFragment[]): IGroup[] => {
  const groups: IGroup[] = [];
  for (const status of STATUSES) {
    const claims = all.filter((claim) => claim.status === status);
    if (!claims.length) {
      continue;
    }
    groups.push({ status, claims });
  }
  return groups;
};

const Table: React.FC<{
  claims: ClaimFragment[];
  onDelete(claim: ClaimFragment): void;
}> = ({ claims, onDelete }) => {
  const drafts = claims.some((claim) => claim.status === ClaimStatus.Draft);
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th scope="col" className="w-25">
              <Text tag="div" wrapping="nowrap">
                Claim Number
              </Text>
            </th>
            {!drafts && (
              <>
                <th scope="col" className="w-25">
                  {claims.some((claim) => !!claim.openedAt) && (
                    <Text tag="div" wrapping="nowrap">
                      Date Opened
                    </Text>
                  )}
                </th>
                <th scope="col" className="w-25">
                  {claims.some((claim) => !!claim.closedAt) && (
                    <Text tag="div" wrapping="nowrap">
                      Date Closed
                    </Text>
                  )}
                </th>
              </>
            )}
            {drafts && <th scope="col" className="w-25" />}
          </tr>
        </thead>
        <tbody>
          {claims.map((claim) => (
            <tr key={claim.uuid}>
              <td>
                <Link to={claimURL(claim.uuid)}>
                  <Digest uuid={claim.uuid} />
                </Link>
              </td>
              {!drafts && (
                <>
                  <td>
                    {claim.openedAt && (
                      <Text tag="div" wrapping="nowrap">
                        <Timestamp value={claim.openedAt} format={Timestamp.Format.Date} />
                      </Text>
                    )}
                  </td>
                  <td>
                    {claim.closedAt && (
                      <Text tag="div" wrapping="nowrap">
                        <Timestamp value={claim.closedAt} format={Timestamp.Format.Date} />
                      </Text>
                    )}
                  </td>
                </>
              )}
              {drafts && (
                <td>
                  {claim.status === ClaimStatus.Draft && (
                    <Text alignment="right" tag="div" wrapping="nowrap">
                      <Link className="btn btn-link" to={claimURL(claim.uuid)}>
                        Resume
                      </Link>
                      <UnstyledButton showErrorInTooltip className="btn btn-link" onClick={() => onDelete(claim)}>
                        <span style={{ color: COLORS.tealPrimary }}>Delete</span>
                      </UnstyledButton>
                    </Text>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Empty = () => (
  <Hero>
    <Hero.Section>
      <Hero.Subtitle>You don’t have any claims.</Hero.Subtitle>
    </Hero.Section>
    <Hero.Section>
      <img className="mx-auto d-block" src={EMPTY} width="320" height="320" />
    </Hero.Section>
    <Hero.Section>
      <Hero.Title>Do you need to file a claim?</Hero.Title>
    </Hero.Section>
  </Hero>
);

export const List: React.FC = () => {
  const { data, error, refetch } = useClaimsQuery({ fetchPolicy: 'network-only' });

  if (error) {
    throw error;
  }
  if (!data) {
    return null;
  }
  const groups = group(data.claims);
  return (
    <Selection<ClaimFragment>>
      {({ selected, onSelect, onDeselect }) => (
        <>
          <Page>
            <Page.Header>
              <CustomerProtectedLink to={buildClaimURL()} className="btn btn-primary float-right">
                <FontAwesomeIcon icon="inbox" /> File a Claim
              </CustomerProtectedLink>
              <Page.Title>
                <Text transform="uppercase">Claims</Text>
              </Page.Title>
            </Page.Header>
            {!groups.length && (
              <Page.Section>
                <Empty />
              </Page.Section>
            )}
            {groups.map(({ status, claims }, key) => (
              <Page.Section key={key}>
                <Page.Subtitle>
                  <Status status={status} />
                </Page.Subtitle>
                <Table claims={claims} onDelete={onSelect} />
              </Page.Section>
            ))}
          </Page>
          {selected && (
            <Delete
              claim={selected}
              onClose={() => {
                onDeselect();
                refetch();
              }}
            />
          )}
        </>
      )}
    </Selection>
  );
};
