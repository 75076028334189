import { COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { AnchorButton } from '@portal/components/shared/button';
import { Container, NAV_BAR_HEIGHT } from '@portal/components/shared/layout';
import { bodyLargeStyle, Heading } from '@portal/components/shared/text';
import { IEventParams } from '@portal/types/wt/event';
import { TrackedLink } from '@portal/components/wt/tracked_link';

const StyledContainer = styled(Container)`
  background-color: ${COLORS.tealBackground};
  color: ${COLORS.tealDark};
  padding: 0 24px;
  position: fixed;
  left: 0;
  top: ${NAV_BAR_HEIGHT}px;
  right: 0;
  bottom: 0;
`;

const Content = styled(Container)`
  max-width: 712px;
  text-align: center;
  @media (max-width: 600px) {
    text-align: left;
  }
`;

const Text = styled.p`
  ${bodyLargeStyle}
  margin-top: 24px;
  margin-bottom: 48px;
  width: 100%;
`;

const SuccessButton = styled(AnchorButton)`
  width: 100%;
  max-width: 470px;
`;

const BUTTON_LABEL = 'Home';

const WT_PARAMS: IEventParams = {
  action: 'click',
  container: 'body',
  label: BUTTON_LABEL,
  pageName: 'portal:help_ticket_submit',
  objectName: 'home',
  objectType: 'button',
  value: '/',
};

export const Success: React.FC = () => (
  <StyledContainer>
    <Content>
      <Heading level={1} size="large">
        Thanks for getting in touch.
      </Heading>
      <Text>Your support request has been submitted. Our Customer Experience team will be in touch with you soon.</Text>
      <TrackedLink params={WT_PARAMS}>
        <SuccessButton kind="primary" size="medium" href="/">
          {BUTTON_LABEL}
        </SuccessButton>
      </TrackedLink>
    </Content>
  </StyledContainer>
);
