import * as React from 'react';

import { useValidPhoneNumberQuery } from '@portal/schema';

type inputProps = { onValidation(valid: boolean): void } & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const PhoneInput = ({ value, placeholder, onChange, onValidation, ...props }: inputProps) => {
  useValidPhoneNumberQuery({
    variables: {
      phoneNumber: value as string,
    },
    onCompleted: ({ validPhoneNumber }) => onValidation(validPhoneNumber),
    fetchPolicy: 'no-cache',
  });

  return (
    <input
      type="tel"
      name="phone"
      placeholder={placeholder}
      pattern="[0-9]*"
      {...props}
      value={value}
      onChange={onChange}
      maxLength={11}
    />
  );
};
