import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { heroURL } from '@shared/utils/hero';

import { InputGroup } from '@shared/components/bootstrap';
import { Fit, Picture } from '@shared/components/helpers';

import { Step } from '@portal/components/claims/step';

import { ClaimItemSelectionForm } from './form';

export const Name = () => (
  <ClaimItemSelectionForm field="name">
    {({ loading, selection, onChange }) => {
      const source = selection.item ? heroURL(selection.item) : undefined;
      return (
        <>
          <Step.Title>Please give the item a name.</Step.Title>
          <div className="row">
            {source && (
              <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                <Picture className="img-fluid rounded" source={source} width={640} height={640} fit={Fit.Fill} />
              </div>
            )}
            <div className="col">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {loading ? <FontAwesomeIcon icon="spinner" spin /> : <FontAwesomeIcon icon="font" />}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Item Name"
                  onChange={(event) => onChange({ ...selection, name: event.target.value || undefined })}
                  value={selection.name || ''}
                />
              </InputGroup>
            </div>
          </div>
        </>
      );
    }}
  </ClaimItemSelectionForm>
);
