import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Page } from '@portal/components/helpers/page';
import { Spacing, Text } from '@shared/components/bootstrap';
import { useOnboardingIncentiveGiftCardQueryQuery } from '@portal/schema';

enum GiftCardStatus {
  Failure = 'FAILURE',
  TooSoon = 'TOO_SOON',
  Canceled = 'CANCELED',
  TooLate = 'TOO_LATE',
  NoEmail = 'NO_EMAIL',
  Success = 'SUCCESS',
}

export const OnboardingIncentive: React.FC = () => {
  const { data, loading } = useOnboardingIncentiveGiftCardQueryQuery();

  const resolveCopy = () => {
    if (loading) {
      return (
        <Text tag="div" alignment="center">
          <FontAwesomeIcon icon="spinner" size="4x" spin />
        </Text>
      );
    }

    if (!data || data.onboardingIncentiveGiftCard.status === GiftCardStatus.Failure) {
      return (
        <Text tag="p" alignment="center">
          Something went wrong trying to retrieve your card. Try again later.
        </Text>
      );
    }

    if (data.onboardingIncentiveGiftCard.status === GiftCardStatus.TooSoon) {
      return (
        <Text tag="p" alignment="center">
          You’re a little early. Come back after Clutter completes your initial pickup to get your Amazon gift code
          then. Thanks!
        </Text>
      );
    }

    if (data.onboardingIncentiveGiftCard.status === GiftCardStatus.Canceled) {
      return (
        <Text tag="p" alignment="center">
          Oops – looks like you decided not to store with Clutter, so, you’re no longer eligible for this gift card.
          Give us a call if you’ve changed your mind: <a href="tel: 18008054023">1-800-805-4023</a>
        </Text>
      );
    }

    if (data.onboardingIncentiveGiftCard.status === GiftCardStatus.TooLate) {
      return (
        <Text tag="p" alignment="center">
          Oh no, looks like this offer is no longer valid. Unfortunately gift cards can only be redeemed within 30 days
          of your initial Clutter pickup. We apologize.
        </Text>
      );
    }

    if (data.onboardingIncentiveGiftCard.status === GiftCardStatus.NoEmail) {
      return (
        <Text tag="p" alignment="center">
          Sorry but you aren't eligible for a gift card.
        </Text>
      );
    }

    return (
      <>
        <Text tag="p" alignment="center">
          Thank you for storing with Clutter. We’re so happy to have you. Here is your Amazon gift code:
        </Text>
        <Text tag="p" alignment="center">
          {data.onboardingIncentiveGiftCard.claimCode}
        </Text>
        <Spacing pt={1} pb={1} mt={1} mb={1} />
        <Text tag="p" alignment="center">
          Click <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201138870">here</a> for help
          redeeming this gift code.
        </Text>
        <Text tag="p" alignment="center">
          Enjoy!
        </Text>
      </>
    );
  };

  return (
    <Page>
      <Page.Section>
        <Text tag="h2" style="info" alignment="center">
          New Customer Gift Card
        </Text>
        <Spacing pt={3} pb={3} mt={3} mb={3} />
        {resolveCopy()}
      </Page.Section>
    </Page>
  );
};
