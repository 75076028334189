import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AddressForm } from '@portal/components/addresses/form';
import { Button } from '@clutter/clean';
import { IAddressValidationErrors, IAddressWithDetails } from '@shared/types';
import { useBuildAddressMutation } from '@portal/schema';
import { serialize } from './form/mutation';

import { DEFAULT_ADDRESS, DEFAULT_DETAILS, disabled, redirectToAccount, validate } from './utils';
import { Error } from './form/error';

const NewAddress: React.FC<{
  onCompleted(): void & RouteComponentProps;
}> = () => {
  const [address, setAddress] = React.useState<IAddressWithDetails>({ ...DEFAULT_ADDRESS, details: DEFAULT_DETAILS });
  const [execute] = useBuildAddressMutation({
    onCompleted: redirectToAccount,
  });
  const [errors, setErrors] = useState<IAddressValidationErrors | undefined>(undefined);
  const [submissionError, setSubmissionError] = useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (address && !disabled(address)) {
      setSubmissionError(undefined);
    }
  }, [address]);

  const onFormSubmit = () => {
    if (disabled(address)) {
      setErrors(validate(address));
      setSubmissionError('Complete all required fields to save changes');
    } else {
      execute({ variables: { input: serialize(address) } });
    }
  };

  return (
    <React.Fragment>
      <AddressForm title="New Address" address={address} setAddress={setAddress} errors={errors} />
      {submissionError && <Error>{submissionError}</Error>}
      <Button onClick={onFormSubmit} kind="primary" type="button">
        Save Changes
      </Button>
    </React.Fragment>
  );
};

export { NewAddress };
