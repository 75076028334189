import * as React from 'react';

import { InputFormGroup } from '../input_form_group';

export const BusinessName: React.FC<{
  title?: string;
  value?: string | null;
  error?: string;
  onChange(value?: string): void;
}> = ({ title, value, onChange, error }) => (
  <InputFormGroup
    label={title ?? 'Name of Business'}
    type="text"
    value={value ?? ''}
    onChange={(event) => onChange(event.target.value)}
    error={!value ? error : undefined}
  />
);
