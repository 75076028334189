import * as React from 'react';

import { isNil } from 'lodash';

import { IOrderInventory, IRoomCategory, IRoomSelection } from '@shared/types';

import { AnyAdditionalItems } from './any_additional_items';
import { AnyUniqueItems } from './any_unique_items';
import { HowManyMovers } from './how_many_movers';
import { HowManyRooms } from './how_many_rooms';
import { PackingCategories } from './packing_categories';
import { PackingHelp } from './packing_help';
import { PickupItems } from './pickup_items';
import { StorageOrMoving } from './storage_or_moving';
import { FullMoveOutSummary } from './summary/full_move_out_summary';
import { PackingSummary } from './summary/packing_summary';
import { RoomSummary } from './summary/room_summary';
import { ExtraItemsSummary } from './summary/extra_items_summary';
import { PackableEstimationSummary } from './summary/packable_estimation_summary';
import { UnpackableEstimationSummary } from './summary/unpackable_estimation_summary';
import { MoverSummary } from './summary/mover_summary';

export interface ISkip {
  hideMoving?: boolean;
  hidePacking?: boolean;
}

interface IStep {
  id: string;
  className: string;
  component: React.ComponentType<any>;
  data?: (inventory: IOrderInventory) => IOrderInventory;
  completed: (inventory: IOrderInventory) => boolean;
  isActive: (inventory: IOrderInventory, skip?: ISkip) => boolean;
  summary: React.FC<{ pickup?: boolean }>;
}

const isStorage = (inventory: IOrderInventory) => inventory.isFullMoveOut === false;
const isMoving = (inventory: IOrderInventory) => inventory.isFullMoveOut === true;

export const atLeastOneRoomSelected = (selections?: Map<IRoomCategory, Partial<IRoomSelection>>): boolean => {
  if (!selections) {
    return false;
  }
  return Array.from(selections.values()).some(
    (selection) => !!selection.selected || (!!selection.quantity && selection.quantity > 0),
  );
};

export const STEPS: IStep[] = [
  {
    id: 'FULL_MOVE_OUT',
    className: 'storage-or-moving',
    component: StorageOrMoving,
    data: ({ isFullMoveOut }) => ({ isFullMoveOut }),
    completed: ({ isFullMoveOut }) => !isNil(isFullMoveOut),
    isActive: (_, skip) => !skip?.hideMoving,
    summary: FullMoveOutSummary,
  },
  {
    id: 'FULL_PACK',
    className: 'packing-help',
    component: PackingHelp,
    data: ({ needsPackingHelp }) => ({ needsPackingHelp }),
    completed: ({ needsPackingHelp }) => !isNil(needsPackingHelp),
    isActive: (inventory, skip) => isStorage(inventory) && !skip?.hidePacking,
    summary: PackingSummary,
  },
  {
    id: 'ROOMS',
    className: 'how-many-rooms',
    component: HowManyRooms,
    data: ({ rooms }) => ({ rooms }),
    completed: ({ rooms, roomsComplete }) => rooms !== undefined && !!roomsComplete,
    isActive: isMoving,
    summary: RoomSummary,
  },
  {
    id: 'ADDITIONAL_ITEMS',
    className: 'any-unique-items',
    component: AnyUniqueItems,
    data: ({ hasExtraItems, extraItems }) => (hasExtraItems ? { hasExtraItems, extraItems } : { hasExtraItems }),
    completed: ({ hasExtraItems, extraItems, extraItemsComplete, rooms }) =>
      atLeastOneRoomSelected(rooms) &&
      (hasExtraItems === false || (!!hasExtraItems && extraItems !== undefined && !!extraItemsComplete)),
    isActive: (inventory) => isMoving(inventory) && !!inventory.roomsComplete,
    summary: ExtraItemsSummary,
  },
  {
    id: 'PACKABLE_ESTIMATIONS',
    className: 'packing-categories',
    component: PackingCategories,
    data: ({ packableEstimations }) => ({ packableEstimations }),
    completed: ({ packableEstimations, packingCategoriesComplete }) =>
      packableEstimations !== undefined && !!packingCategoriesComplete,
    isActive: (inventory) => isStorage(inventory) && !!inventory.needsPackingHelp,
    summary: PackableEstimationSummary,
  },
  {
    id: 'UNPACKABLE_ESTIMATIONS',
    className: 'pickup-items',
    component: PickupItems,
    data: ({ unpackableEstimations }) => ({ unpackableEstimations }),
    completed: (inventory) => {
      if (inventory.unpackableEstimations) {
        if (Array.from(inventory.unpackableEstimations.values()).some((element) => !!element.quantity)) {
          return true;
        }
      }
      return !!inventory.extraItems?.length;
    },
    isActive: (inventory) =>
      isStorage(inventory) &&
      ((!!inventory.needsPackingHelp && !!inventory.packingCategoriesComplete) || inventory.needsPackingHelp === false),
    summary: UnpackableEstimationSummary,
  },
  {
    id: 'ADDITIONAL_ITEMS',
    className: 'any-additional-items',
    component: AnyAdditionalItems,
    data: ({ hasExtraItems, extraItems }) => (hasExtraItems ? { hasExtraItems, extraItems } : { hasExtraItems }),
    completed: ({ hasExtraItems, extraItems, extraItemsComplete }) =>
      hasExtraItems === false || (!!hasExtraItems && extraItems !== undefined && !!extraItemsComplete),
    isActive: (inventory) =>
      isStorage(inventory) &&
      ((!!inventory.needsPackingHelp && !!inventory.packingCategoriesComplete) || inventory.needsPackingHelp === false),
    summary: ExtraItemsSummary,
  },
  {
    id: 'ADDITIONAL_MOVERS',
    className: 'how-many-movers',
    component: HowManyMovers,
    data: ({ requestedMovers }) => ({ requestedMovers }),
    completed: (inventory) =>
      inventory.requestedMovers !== undefined &&
      (isStorage(inventory) || (isMoving(inventory) && atLeastOneRoomSelected(inventory.rooms))),
    isActive: ({ hasExtraItems, extraItemsComplete }) =>
      (!!hasExtraItems && !!extraItemsComplete) || hasExtraItems === false,
    summary: MoverSummary,
  },
];
