import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Page } from '@portal/components/helpers';
import { ReviewForm } from '@portal/components/review/form';
import { claimURL } from '@portal/config/routes';
import { DetailedClaimFragment, Rating, Review__ReviewableEnum } from '@portal/schema';

import { Digest } from './digest';

export const Review: React.FC<{
  claim: DetailedClaimFragment;
}> = ({ claim }) => {
  const [selectedRating, setSelectedRating] = useState<Rating | undefined>();
  const [showSubmittedAlert, setShowSubmittedAlert] = useState<boolean>(true);
  const [submitted, setSubmitted] = useState<boolean>(false);

  if (submitted) {
    return <Redirect to={claimURL(claim.uuid)} />;
  }

  return (
    <Page>
      {showSubmittedAlert && (
        <div className="alert-dismissible alert-success alert">
          Your claim has been successfully accepted and your payment is being processed.
          <button type="button" className="close" onClick={() => setShowSubmittedAlert(false)}>
            ×
          </button>
        </div>
      )}
      <Page.Header>
        <div className="row">
          <div className="col col-6 col-sm-8 col-lg-9">
            <Page.Title>
              <div style={{ textTransform: 'uppercase' }}>
                <span className="d-none d-sm-inline d-lg-inline">Claim: </span>
                <Digest uuid={claim.uuid} />
              </div>
            </Page.Title>
          </div>
        </div>
      </Page.Header>
      <Page.Section>
        <ReviewForm
          title="How would you rate this claims experience?"
          reviewable={{ id: claim.id!, type: Review__ReviewableEnum.Claim }}
          selectedRating={selectedRating}
          setSelectedRating={setSelectedRating}
          onSubmission={() => setSubmitted(true)}
        />
      </Page.Section>
    </Page>
  );
};
