import { head, last } from 'lodash';
import * as React from 'react';

import { ClaimItemSelectionAction, claimItemSelectionURL } from '@portal/config/routes';

import { ClaimItemSelectionConsumer } from '@portal/contexts';

import { Workflow } from '@portal/components/claims/workflow';
import { ClaimItemSelectionFragment, ClaimItemSelectionKind } from '@portal/schema';
import { Declaration } from './declaration';
import { Name } from './name';
import { Photos } from './photos';
import { ProductURL } from './product_url';

import { Submitted } from './submitted';

interface IStep {
  action: ClaimItemSelectionAction;
  component(): JSX.Element;
  skip?(selection: ClaimItemSelectionFragment): boolean;
}

const STEPS: IStep[] = [
  {
    action: 'name',
    component: Name,
  },
  {
    action: 'photos',
    component: Photos,
    skip: (selection: ClaimItemSelectionFragment) => selection.kind === ClaimItemSelectionKind.Lost,
  },
  {
    action: 'product_url',
    component: ProductURL,
  },
  /* Hiding receipts step to streamline claims process
  {
    action: 'receipts',
    component: Receipts,
  },
  */
  {
    action: 'declaration',
    component: Declaration,
  },
  {
    action: 'submitted',
    component: Submitted,
  },
];

export const HEAD = head(STEPS)!.action;
export const TAIL = last(STEPS)!.action;

interface IWizardProps {
  summary: string;
  onNext(): void;
  onPrev(): void;
}

export const Wizard = (props: IWizardProps) => (
  <ClaimItemSelectionConsumer>
    {({ selection }) => (
      <Workflow
        {...props}
        url={({ uuid, id, action }) => claimItemSelectionURL(uuid, id!, action as ClaimItemSelectionAction)}
        steps={STEPS.filter((step) => !(step.skip && step.skip(selection)))}
      />
    )}
  </ClaimItemSelectionConsumer>
);
