import { Button, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';

import { BillingTabs } from '@portal/components/billing/tabs';
import { FloatingCheck } from '@portal/components/shared/icons';
import { billingURL } from '@portal/config/routes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 48px;
`;

const Title = styled(Text.Title)`
  text-align: center;
`;

const IconContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 36px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 360px;
`;

export const Empty: React.FC = () => (
  <Container>
    <Title size="large">You’re all settled up!</Title>
    <IconContainer>
      <FloatingCheck />
    </IconContainer>
    <ButtonContainer>
      <Link to={billingURL(BillingTabs.History)}>
        <Button kind="primary" fullWidth>
          View Billing History
        </Button>
      </Link>
    </ButtonContainer>
  </Container>
);
