import { DateTime } from 'luxon';

import { OrderSubtypeEnum, OrderTypeEnum } from '@portal/schema';
import { fulfilledTermCommitment } from '@shared/utils/fulfilled_term_commitment';
import { ISkipProps, IWorkFlowStep } from './types';

const fulfilledTerm = ({ subtype, scheduledDate, contractEndDate }: ISkipProps) => {
  if (scheduledDate) {
    return fulfilledTermCommitment(scheduledDate, subtype, contractEndDate);
  } else {
    return fulfilledTermCommitment(DateTime.local(), subtype, contractEndDate);
  }
};

export const SHIPPING_WORKFLOW: IWorkFlowStep[] = [
  { name: 'new' },
  {
    name: 'return',
    skip: ({ type }) => type === OrderTypeEnum.Pickup,
  },
  {
    name: 'cancel_reason',
    skip: ({ subtype }) => subtype !== OrderSubtypeEnum.Final,
  },
  {
    name: 'address',
  },
  {
    name: 'inbound_shipping_prepare',
    skip: ({ type }) => type === OrderTypeEnum.Return,
  },
  {
    name: 'inbound_shipment_count',
    skip: ({ type }) => type === OrderTypeEnum.Return,
  },
  {
    name: 'inbound_shipment_items',
    skip: ({ type }) => type === OrderTypeEnum.Return,
  },
  {
    name: 'outbound_item_info',
    skip: ({ type }) => type === OrderTypeEnum.Pickup,
  },
  {
    name: 'inbound_shipping_options',
    skip: ({ type }) => type === OrderTypeEnum.Return,
  },
  {
    name: 'outbound_shipping_options',
    skip: ({ type }) => type === OrderTypeEnum.Pickup,
  },
  {
    name: 'term_commitment',
    skip: fulfilledTerm,
  },
  {
    name: 'term_commitment_balance',
    skip: fulfilledTerm,
  },
  {
    name: 'contact',
  },
  { name: 'confirm' },
];
