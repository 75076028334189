import { IIconProps } from '@portal/components/shared/icons/props';
import React from 'react';
import { snakeCase } from 'lodash';

export const Boxes: React.FC<IIconProps> = ({ title = 'Boxes', width = 67, height = 63 }) => (
  <svg
    aria-hidden={!title}
    aria-labelledby={title && `${snakeCase(title)}-svg`}
    width={width}
    height={height}
    viewBox="0 0 67 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title id={`${snakeCase(title)}-svg`}>{title}</title>}
    <path
      d="M62.6647 43.9443H39.0177V62.611H62.6647V43.9443Z"
      fill="#FFEABF"
      stroke="#FFEABF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.0177 43.9443H27.1941V62.611H39.0177V43.9443Z"
      fill="#FFEABF"
      stroke="#FFEABF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.2059 48.6112H48.4765C48.0035 48.6112 47.6882 48.3001 47.6882 47.8334C47.6882 47.3668 48.0035 47.0557 48.4765 47.0557H53.2059C53.6788 47.0557 53.9941 47.3668 53.9941 47.8334C53.9941 48.3001 53.6788 48.6112 53.2059 48.6112Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.6647 5.05518H39.0176V23.7218H62.6647V5.05518Z"
      fill="#FFEABF"
      stroke="#FFEABF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.2059 9.72206H48.4765C48.0035 9.72206 47.6882 9.41095 47.6882 8.94428C47.6882 8.47762 48.0035 8.1665 48.4765 8.1665H53.2059C53.6788 8.1665 53.9941 8.47762 53.9941 8.94428C53.9941 9.41095 53.6788 9.72206 53.2059 9.72206Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.7824 24.5H31.1353V43.1667H54.7824V24.5Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.7235 24.5H53.9941V43.1667H58.7235V24.5Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.3235 29.1669H40.5941C40.1212 29.1669 39.8059 28.8558 39.8059 28.3891C39.8059 27.9224 40.1212 27.6113 40.5941 27.6113H45.3235C45.7965 27.6113 46.1118 27.9224 46.1118 28.3891C46.1118 28.8558 45.7965 29.1669 45.3235 29.1669Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.0176 43.9443V61.8332"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.0176 24.5H58.7235"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.9941 43.1667V24.5"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.7235 43.9443H31.1353"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.1517 16.0699L20.0783 61.2612L27.8408 62.6115L35.9143 17.4201L28.1517 16.0699Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.8247 62.6111L34.6035 24.5V43.9444H31.4506V62.6111H27.8247Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.46 61.0551L33.5 15.8662"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.6647 5.05534L50.8412 0.388672"
      stroke="#FFEABF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6365 46.1221L16.0012 50.1665V62.611H13.3212V50.1665L10.9565 46.1221H13.6365Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.14 46.1221L30.5047 50.1665V62.611H28.6129V50.1665L26.2482 46.1221H28.14Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.2341 62.6108H5.75412C4.96589 62.6108 4.3353 61.9886 4.3353 61.2108V50.7108C4.3353 50.3219 4.49294 50.0108 4.72941 49.6997L6.30589 48.2219C6.54236 47.9886 6.93647 47.833 7.33059 47.833H33.7365C34.1306 47.833 34.4459 47.9886 34.7612 48.2219L36.2588 49.7775C36.4953 50.0108 36.6529 50.3997 36.6529 50.7886V61.2886C36.6529 61.9886 36.0224 62.6108 35.2341 62.6108Z"
      fill="#00A499"
      stroke="#00A499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.5047 62.6108V50.7108C30.5047 50.3219 30.6624 50.0108 30.8988 49.6997L32.3965 48.2219C32.6329 47.9886 33.0271 47.833 33.4212 47.833"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.5047 52.1887V51.0998C30.5047 50.5554 30.1106 50.1665 29.5588 50.1665H5.36001C4.80824 50.1665 4.41412 50.5554 4.41412 51.0998"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.2482 47.833L23.8835 50.1663V62.6108H25.7753V50.1663L28.14 47.833H26.2482Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.46 46.1221L23.0953 50.1665V62.611H25.7753V50.1665L28.14 46.1221H25.46Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9565 47.833L8.59177 50.1663V62.6108H11.2718V50.1663L13.6365 47.833H10.9565Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9565 46.1221L8.59177 50.1665V62.611H11.2718V50.1665L13.6365 46.1221H10.9565Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.2482 46.1221H12.3753H10.9565L11.4294 43.9443H27.6671L28.14 46.1221H26.2482Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.3023 46.1221H13.6365L11.2718 50.1665V62.611"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.7753 62.611V50.1665L28.14 46.1221"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.7365 47.833V52.4997"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.7365 52.5L32.4753 55.4556H34.9977L33.7365 52.5Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.6059 62.6108H0.394119"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0">
        <rect width="67" height="63" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
