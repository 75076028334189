import { CONTAINER_SIDE_PADDING } from '@portal/components/shared/layout';
import { BREAKPOINTS, COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React from 'react';

import { SettledInvoices } from '@portal/components/invoices/detail/settled_invoices';
import { StatusLabel } from '@portal/components/invoices/status_label';
import { orderURL } from '@portal/config/routes';
import { InvoiceDetailFragment as Invoice } from '@portal/schema';

import _ from 'lodash';
import { EmailReceiptButton } from './email_receipt/button';
import { InvoiceItems } from './invoice_items';
import { Lines } from './lines';
import { Totals } from './totals';
import { buildTransactions } from './transaction';
import { TransactionHistory } from './transaction_history';

const MAX_WIDTH = 500;

const Container = styled.div`
  padding: 0 ${16 - CONTAINER_SIDE_PADDING}px;
`;

const Status = styled(StatusLabel)`
  margin-right: 12px;
`;

const Subtitle = styled(Text.Callout)`
  color: ${COLORS.storm};
  display: inline-block;
`;

const Appointment = styled(Text.Caption)`
  margin-top: 8px;
  color: ${COLORS.storm};
`;

const Link = styled.a`
  color: ${COLORS.tealPrimary};
  text-decoration: none;
  :hover {
    color: ${COLORS.tealDark};
    text-decoration: none;
  }
`;

const Divider = styled.hr`
  margin-top: 24px;
  margin-bottom: 8px;
  @media (max-width: ${BREAKPOINTS.MD}) {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }
`;

const ItemBreakdown = styled.div`
  max-width: ${MAX_WIDTH}px;
`;

const Transactions = styled.div`
  max-width: ${MAX_WIDTH}px;
  margin-top: 24px;
`;

const EmailReceiptContainer = styled.div`
  max-width: ${MAX_WIDTH}px;
  margin-top: 16px;
`;

export const View: React.FC<{ invoice: Invoice }> = ({ invoice }) => {
  const {
    id,
    charges,
    invoiceItems,
    lines,
    offlinePayments,
    order,
    refunds,
    subscription,
    selfStorageSubscription,
    lumpSumInvoice,
    settledInvoices,
    rebillable,
    swapPaymentInvoices,
  } = invoice;

  const swapPaymentCharges = _.flatMap(swapPaymentInvoices, (swapPaymentInvoice) => swapPaymentInvoice.charges);
  const swapPaymentRefunds = _.flatMap(swapPaymentInvoices, (swapPaymentInvoice) => swapPaymentInvoice.refunds);

  const transactionCharges = charges.concat(swapPaymentCharges);
  const transactionRefunds = refunds.concat(swapPaymentRefunds);

  const bookingPartnerName = order?.bookingPartner?.name;

  const textOverride = bookingPartnerName ? `Billed by ${bookingPartnerName}` : undefined;

  const transactions = bookingPartnerName
    ? []
    : buildTransactions(transactionCharges, offlinePayments, transactionRefunds, lumpSumInvoice);

  return (
    <Container>
      <Text.Title size="large">{invoice.displayName}</Text.Title>
      <div>
        <Status
          status={invoice.displayStatus}
          forgiven={invoice.forgiven}
          forgivenessReasonKind={invoice.forgivenessReasonKind}
          amountDue={invoice.amountDue}
          textOverride={textOverride}
        />
        <Subtitle>Invoice #{invoice.displayID}</Subtitle>
      </div>
      {order && (
        <Appointment>
          Appointment <Link href={orderURL(order.id)}>#{order.number}</Link> on{' '}
          {DateTime.fromISO(order.scheduled, { setZone: true }).toLocaleString(DateTime.DATE_FULL)}
        </Appointment>
      )}
      <Divider />
      <ItemBreakdown>
        {!!subscription || !!selfStorageSubscription ? (
          <Lines lines={lines} />
        ) : (
          <InvoiceItems invoiceItems={invoiceItems} />
        )}
        <Totals invoice={invoice} transactions={transactions} />
      </ItemBreakdown>
      <Divider />
      {invoice.paidAt && settledInvoices && (
        <>
          <SettledInvoices invoices={settledInvoices} />
          <Divider />
        </>
      )}
      <Transactions>
        {!!transactions.length && (
          <TransactionHistory transactions={transactions} invoiceID={id} rebillable={rebillable} />
        )}
      </Transactions>
      {bookingPartnerName ? (
        <Text.Callout color={COLORS.storm}>
          This invoice will be billed and paid through {bookingPartnerName}. Please contact {bookingPartnerName}{' '}
          directly for any issues regarding payments for this invoice.
        </Text.Callout>
      ) : (
        <EmailReceiptContainer>
          <EmailReceiptButton invoiceID={invoice.id} displayID={invoice.displayID} />
        </EmailReceiptContainer>
      )}
    </Container>
  );
};
