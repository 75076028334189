import * as React from 'react';

interface IPhoneProps {
  value: string;
}

const REGEX = /^(\+\d)?\W*(\d{3})\W*(\d{3})\W*(\d{4})$/;
const FORMAT = '($2) $3-$4';

const Phone = ({ value }: IPhoneProps) => <>{value.replace(REGEX, FORMAT)}</>;

export { Phone };
