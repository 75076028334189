import { CustomerItemFragment } from '@portal/schema';
import { createContextHook } from '@shared/hooks/context';
import { useLatestCallback, useLatestRef } from '@shared/hooks/use_latest';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type ItemDescriptor = Pick<CustomerItemFragment, '__typename' | 'uuid'>;

const ItemViewContext = React.createContext<
  { selectedItem?: ItemDescriptor; setSelectedItem: (item?: ItemDescriptor) => void } | undefined
>(undefined);

export const useItemViewContext = createContextHook(ItemViewContext);

const getItemFromSearch = (search: string) => {
  const params = new URLSearchParams(search);
  const uuid = params.get('selectedItem');
  const group = params.get('group');

  return uuid ? ({ uuid, __typename: group ? 'ItemGroup' : 'Item' } as ItemDescriptor) : undefined;
};

const getNewSearch = (item: ItemDescriptor) => {
  const params = new URLSearchParams(window.location.search);
  params.set('selectedItem', item.uuid);
  if (item.__typename === 'ItemGroup') params.set('group', 'true');
  else params.delete('group');
  return params.toString();
};

export const ItemViewContextProvider: React.FC = ({ children }) => {
  const { search } = useLocation();
  const history = useHistory();
  const [selectedItem, internalSetSelectedItem] = useState<ItemDescriptor | undefined>(() => getItemFromSearch(search));

  const selectedItemRef = useLatestRef(selectedItem);
  const setSelectedItem = useLatestCallback((newItem: ItemDescriptor | undefined) => {
    if (newItem) {
      const newSearch = getNewSearch(newItem);
      internalSetSelectedItem(newItem);

      // We don't want to create a deep history stack if a customer toggles
      // between items, but we do want to support using the back button to clear
      // the selected item overlay on mobile.
      if (selectedItem) {
        history.replace({ search: newSearch });
      } else {
        history.push({ search: newSearch });
      }
    } else {
      internalSetSelectedItem(undefined);
      history.replace({ search: '' });
    }
  });

  useEffect(() => {
    const item = getItemFromSearch(search);
    if (item?.uuid !== selectedItemRef.current?.uuid) setSelectedItem(item);
  }, [search, selectedItemRef, setSelectedItem]);

  const value = useMemo(() => ({ selectedItem, setSelectedItem }), [selectedItem, setSelectedItem]);

  return <ItemViewContext.Provider value={value}>{children}</ItemViewContext.Provider>;
};
