import { Box, Form, Label, Text, COLORS } from '@clutter/clean';
import React from 'react';

export const FieldFormGroup: React.FC<{
  label: React.ReactNode;
  error?: string;
  id?: string;
  children: React.ReactNode;
}> = ({ label, id, error, children }) => (
  <Box margin="0 0 20px">
    <Label htmlFor={id}>{label}</Label>
    <Form.Field>{children}</Form.Field>
    {error && <Text.Body color={COLORS.toucan}>{error}</Text.Body>}
  </Box>
);
