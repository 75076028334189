import { COLORS, Radio, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Message as ErrorMessage } from '@portal/components/shared/error';
import { Maybe, RetentionOfferFragment, useCurrentStorageSubscriptionQuery } from '@portal/schema';
import { Spinner } from '@portal/components/helpers';
import { currency } from '@shared/utils';
import { Discount } from '@portal/components/retention/discount';
import { savings } from '@portal/components/retention/savings';

const Title = styled(Text.Title)`
  line-height: 1.2em;
  margin-bottom: 32px;
`;

const Description = styled(Text.Body)`
  margin-bottom: 32px;
`;

const RadioSubtitle = styled(Text.Body)`
  color: ${COLORS.hippo};
`;

const WaiveRateHikeDescription: React.FC = () => (
  <>
    We recently notified you that your monthly storage rate would change.
    <br />
    <br />
    Stay with Clutter and we’ll keep you at your current monthly storage rate.
  </>
);

const StorageDiscountDescription: React.FC<{
  offer: RetentionOfferFragment;
}> = ({ offer }) => (
  <>
    As a valued Clutter customer, we want to keep your storage convenient and affordable.
    <br />
    <br />
    Stay with Clutter, and{' '}
    <b>
      we’ll give you <Discount offer={offer} discountable="your monthly storage price" />
    </b>
    . No monthly commitment required.
  </>
);

const RadioLabel: React.FC<{
  title: React.ReactNode;
  subtitle: React.ReactNode;
}> = ({ title, subtitle }) => (
  <>
    <Text.Title size="extraSmall">{title}</Text.Title>
    <RadioSubtitle>{subtitle}</RadioSubtitle>
  </>
);

export const RetentionOffer: React.FC<{
  error?: Maybe<string>;
  offer: RetentionOfferFragment;
  accept?: boolean;
  onAccept(accept: boolean): void;
}> = ({ error, offer, accept, onAccept }) => {
  const { waiveRateAdjustment } = offer.detail;
  const { data, loading } = useCurrentStorageSubscriptionQuery({
    skip: waiveRateAdjustment,
  });

  let OfferDescription;
  let labelDescription;
  let labelSubtitle;
  if (waiveRateAdjustment) {
    OfferDescription = WaiveRateHikeDescription;
    labelDescription = <>Yes, keep my current rate</>;
    labelSubtitle = <>We’ll waive your monthly increase</>;
  } else {
    OfferDescription = () => <StorageDiscountDescription offer={offer} />;
    labelDescription = (
      <>
        Yes, get <Discount offer={offer} discountable="my storage" />
      </>
    );
    const storageSubscriptions = data?.account.subscriptionSet.storageSubscriptions;
    if (storageSubscriptions && storageSubscriptions.length > 0) {
      const storagePrice = storageSubscriptions[0].total;
      labelSubtitle = (
        <>
          We’ll lower your monthly price from {currency(storagePrice)} to {currency(savings(offer, storagePrice))}
        </>
      );
    } else {
      labelSubtitle = 'We’ll lower your monthly price';
    }
  }

  const radioOptions = [
    {
      value: true,
      label: <RadioLabel title={labelDescription} subtitle={labelSubtitle} />,
    },
    {
      value: false,
      label: <RadioLabel title="No, continue closing account" subtitle="We’ll deliver all your items" />,
    },
  ];

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Title size="large">Would you consider staying with Clutter?</Title>
      {!!error && <ErrorMessage message={error} />}
      <Description>
        <OfferDescription />
      </Description>
      <Radio.Selector name="retentionOffer" value={accept} options={radioOptions} onChange={onAccept} />
    </>
  );
};
