import { COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { PiggyBank } from '@portal/components/shared/icons';
import { paymentPlansURL } from '@portal/config/routes';

const Container = styled.div`
  background: ${COLORS.tealBackground};
  display: flex;
  padding: 12px;
  align-items: center;
  border-radius: 0.25rem;
  margin: 12px 0;
`;

const Icon = styled.div`
  width: 30px;
`;

const Copy = styled(Text.Body)`
  margin-left: 12px;
`;

const Link = styled.a`
  color: ${COLORS.tealPrimary};

  :hover {
    color: ${COLORS.tealBrand};
    text-decoration: none;
  }
`;

export const PaymentPlanBanner: React.FC<{ offerID: string; className?: string }> = ({ offerID, className }) => (
  <Container className={className}>
    <Icon>
      <PiggyBank />
    </Icon>
    <Copy>
      We’ll forgive some of what you owe.{' '}
      <Link href={paymentPlansURL(offerID)}>Review your custom payment plan now.</Link>
    </Copy>
  </Container>
);
