import { Spinner } from '@portal/components/helpers';
import { useReferralHistoryQuery } from '@portal/schema';
import React, { useRef, useState } from 'react';

import styled from '@emotion/styled';

import { mq } from '@clutter/clean';

import BackgroundWaveTeal from '@portal/images/background-wave-teal.svg';

import { CouponCard } from './coupon_card';
import { InfoCard } from './info_card';
import { RewardsHeader } from './rewards_header';
import { ShareModal } from './share_modal';

const ClutterRewards = styled.div`
  text-align: center;
`;

const Background = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: -1;
  margin-top: 64px;
  ${mq({ height: ['297px', null, '307px'] })}
`;

const RewardsDetails = styled.div`
  margin-top: 24px;
`;

const RewardsContent = styled.div`
  ${mq({
    marginLeft: ['0px', null, '-300px'],
  })}
`;

const StyledCouponCard = styled(CouponCard)`
  ${mq({
    width: ['327px', null, '288px'],
    height: ['329px', null, '393px'],
    position: ['static', null, 'absolute'],
    left: ['auto', null, 'calc(50% + 120px)'],
    top: ['auto', null, '62px'],
  })}
`;

const GROWTH_EVENT_SOURCE = 'get_dollars_page';

export const Rewards: React.FC = () => {
  const modalContainerRef = useRef<HTMLInputElement | null>(null);
  const [hiddenShareModel, setHiddenShareModel] = useState<boolean>(true);
  const { data, loading } = useReferralHistoryQuery();

  if (loading) {
    return <Spinner />;
  }

  if (!data || !data.referralHistory) {
    return <div>Sorry, we cannot fetch your referral history.</div>;
  }

  const { creditsEarned, referralCode, referralsCount } = data.referralHistory;

  return (
    <ClutterRewards>
      <Background src={BackgroundWaveTeal} />
      <RewardsContent ref={modalContainerRef}>
        <ShareModal
          containerRef={modalContainerRef}
          hidden={hiddenShareModel}
          hideModal={() => setHiddenShareModel(true)}
          source={GROWTH_EVENT_SOURCE}
        />
        <RewardsHeader referralsCount={referralsCount} creditsEarned={creditsEarned} />
        <div className="rewards__body">
          <StyledCouponCard referralCode={referralCode} showShareModal={() => setHiddenShareModel(false)} />
          <RewardsDetails>
            <InfoCard
              header="Moving Referral Reward"
              title="Give $50, Get $50"
              body="Once your friend books a move with Clutter, you’ll both earn a $50 reward."
            />
            <InfoCard
              header="Storage Referral Reward"
              title="Give $100, Get $100"
              body="Once your friend books storage with Clutter, you’ll both earn a $100 reward."
            />
          </RewardsDetails>
        </div>
      </RewardsContent>
    </ClutterRewards>
  );
};
