import { BREAKPOINTS, Button, Checkbox, COLORS, FontWeight, mq, Text, Textarea } from '@clutter/clean';
import styled from '@emotion/styled';
import { range } from 'lodash';
import * as qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { reviewThanksURL } from '@portal/config/routes';
import { Rating, Review__ReviewableEnum, Status, useBuildReviewMutation, useReviewReasonsQuery } from '@portal/schema';

const Title = styled(Text.Title)`
  color: ${COLORS.tealDark};
`;

const Legend = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.SM}) {
    display: none;
  }
`;

const MobileLegend = styled.span`
  ${mq({ display: ['inline', 'none', 'none', 'none'] })}
`;

const ScoreContainer = styled.div`
  max-width: 450px;
  margin: auto;
  margin-top: 16px;
`;

const Scores = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top: 8px;

  @media (max-width: ${BREAKPOINTS.SM}) {
    flex-direction: column-reverse;
  }
`;

const Score = styled.div<{ opacity: number; selected: boolean }>`
  color: ${COLORS.cloud};
  background-color: ${COLORS.tealPrimary};
  opacity: ${({ opacity }) => opacity};
  border: ${({ selected }) => (selected ? `2px solid ${COLORS.tealDark}` : 'None')};
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: ${BREAKPOINTS.SM}) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 8px;
  }
`;

const SelectedScore = styled(Text.Body)`
  margin: 16px 0;
  text-align: center;
`;

const Comments = styled(Textarea)`
  width: 100%;
  height: 120px;
  margin-top: 16px;
`;

const ErrorMessage = styled(Text.Callout)`
  color: ${COLORS.toucan};
`;

const SubmitButton = styled(Button)`
  margin-top: 16px;
`;

const isGoodRating = (score: number) => score > 6;

const GOOD_COMMENT_PLACEHOLDER = 'Let us know what went well.';
const POOR_COMMENT_PLACEHOLDER = 'Let us know how we could improve your experience.';

export const NPS: React.FC<{
  signedReviewableID: string;
  reviewableID: string;
  initialScore?: number;
  kind?: string;
  schedulingID?: string;
}> = ({ signedReviewableID, reviewableID, initialScore, kind, schedulingID }) => {
  const [selectedScore, setSelectedScore] = useState<number | undefined>(initialScore);
  const [error, setError] = useState<string | undefined>();
  const [selectedReasons, setSelectedReasons] = useState<{ [reason: string]: boolean }>({});
  const [comments, setComments] = useState<string>();

  const { data } = useReviewReasonsQuery({
    variables: { reviewableType: Review__ReviewableEnum.Account, schedulingID },
  });
  const [buildReview, { loading: building }] = useBuildReviewMutation();
  const history = useHistory();

  const reviewReasons = data?.reviewReasons;
  const scores = range(0, 11);
  const reasonContainer = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!reviewReasons || selectedScore === undefined || Number.isNaN(selectedScore)) {
      return;
    }
    const refreshReasons: { [reason: string]: boolean } = {};
    if (isGoodRating(selectedScore)) {
      reviewReasons.good.forEach((reason) => (refreshReasons[reason] = false));
    } else {
      reviewReasons.poor.forEach((reason) => (refreshReasons[reason] = false));
    }
    setSelectedReasons(refreshReasons);
  }, [reviewReasons, selectedScore]);

  const onSelect = (score: number) => {
    setSelectedScore(score);
    setTimeout(() => {
      if (reasonContainer.current) {
        reasonContainer.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  const onSubmit = async () => {
    setError(undefined);
    const response = await buildReview({
      variables: {
        input: {
          reviewable: { id: reviewableID, type: Review__ReviewableEnum.Account },
          score: selectedScore,
          comments,
          reasons: JSON.stringify(selectedReasons),
          kind,
          schedulingID,
        },
      },
    });
    if (response && response.data) {
      if (response.data.buildReview?.status === Status.Unprocessable) {
        setError(`There was a problem submitting your review: ${response.data.buildReview.error}`);
      } else {
        const rating = isGoodRating(selectedScore!) ? Rating.Good : Rating.Poor;
        history.push(`${reviewThanksURL()}?${qs.stringify({ reviewable_id: signedReviewableID, rating })}`);
      }
    }
  };

  return (
    <>
      <Title size="medium">How likely are you to recommend Clutter to a friend or colleague?</Title>
      <ScoreContainer>
        <Legend>
          <Text.Body>0: Not likely</Text.Body>
          <Text.Body>10: Very likely</Text.Body>
        </Legend>
        <Scores>
          {scores.map((score) => (
            <Score
              key={score}
              opacity={(score * 0.5) / 10.0 + 0.5}
              selected={score === selectedScore}
              onClick={() => onSelect(score)}
            >
              <div>
                {score}
                {score === 0 && <MobileLegend> - Not likely</MobileLegend>}
                {score === 10 && <MobileLegend> - Very likely</MobileLegend>}
              </div>
            </Score>
          ))}
        </Scores>
      </ScoreContainer>
      {selectedScore !== undefined && Object.keys(selectedReasons).length > 0 ? (
        <div ref={reasonContainer}>
          <SelectedScore weight={FontWeight.Medium}>You gave us a score of: {selectedScore}</SelectedScore>
          <hr />
          <Text.Title size="extraSmall">
            {isGoodRating(selectedScore) ? 'Why would you recommend Clutter?' : 'What can we improve?'}
          </Text.Title>
          <Text.Body weight={FontWeight.Medium}>Please select at least one reason</Text.Body>
          <Checkbox.Selector
            name="nps-reasons"
            values={selectedReasons}
            options={Object.keys(selectedReasons).map((reason) => ({ key: reason, label: reason }))}
            onChange={(_, value) => setSelectedReasons(value)}
          />
          <Comments
            name="nps_comments"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            placeholder={isGoodRating(selectedScore) ? GOOD_COMMENT_PLACEHOLDER : POOR_COMMENT_PLACEHOLDER}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <SubmitButton onClick={onSubmit} disabled={building} loading={building}>
            Share Feedback
          </SubmitButton>
        </div>
      ) : null}
    </>
  );
};
