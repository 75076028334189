import { Box, mq, SkeletonLoader } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';

import { Account__Marketing__CouponFragment, Maybe } from '@portal/schema';
import { rescheduleOfferTokenURL } from '@portal/config/routes';
import { Banner as RescheduleOfferBanner } from '@portal/components/reschedule_offer/banner';
import { RewardsBanner } from '@portal/components/rewards/rewards_banner';

const BannerContainer = styled.div`
  a,
  a:hover {
    color: inherit !important;
    text-decoration: none !important;
  }
`;

const Banner: React.FC<{ token?: string; rescheduleOfferCoupon?: Maybe<Account__Marketing__CouponFragment> }> = ({
  token,
  rescheduleOfferCoupon,
}) => (
  <>
    {token && rescheduleOfferCoupon ? (
      <BannerContainer>
        <Link to={rescheduleOfferTokenURL(token)}>
          <RescheduleOfferBanner coupon={rescheduleOfferCoupon} includeCTA />
        </Link>
      </BannerContainer>
    ) : (
      <RewardsBanner source="inventory_page" />
    )}
  </>
);

const LoaderContainer = styled(Box.Flex)`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border-radius: 4px;
  padding: 24px;
  ${mq({ height: ['74px', null, null, '148px'] })}
`;

const LoaderImage = styled(SkeletonLoader)`
  ${mq({ height: ['50px', null, null, '100px'] })}
  ${mq({ width: ['50px', null, null, '100px'] })}
`;

const LoaderTitle = styled(SkeletonLoader)`
  ${mq({ width: ['250px', null, null, '500px'] })}
  ${mq({ height: ['20px', null, null, '40px'] })}
`;

const Loader = () => (
  <LoaderContainer>
    <Box>
      <LoaderImage borderRadius="50%" />
    </Box>
    <Box.Flex margin="0 0 0 16px">
      <LoaderTitle />
    </Box.Flex>
  </LoaderContainer>
);

const Combo = Object.assign(Banner, { Loader });
export { Combo as Banner };
