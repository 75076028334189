import React from 'react';

import { COLORS } from '@clutter/clean';

export const Facebook: React.FC<{ height: number; width: number }> = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    preserveAspectRatio="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="white" />
    <path
      d="M40 20.1223C40 9.00901 31.0458 0 20.0001 0C8.95431 0 0 9.00901 0 20.1223C0 30.1659 7.31373 38.4904 16.875 40V25.9389H11.7969V20.1223H16.875V15.689C16.875 10.6459 19.8609 7.86024 24.4293 7.86024C26.6174 7.86024 28.9063 8.25324 28.9063 8.25324V13.2052H26.3844C23.8999 13.2052 23.125 14.7563 23.125 16.3476V20.1223H28.6719L27.7852 25.9389H23.125V40C32.6864 38.4904 40 30.1659 40 20.1223Z"
      fill={COLORS.tealPrimary}
    />
  </svg>
);
