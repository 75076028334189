export enum DocumentAcknowledgmentKind {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export interface IDocument {
  id: string;
  name: string;
  url?: string;
}
