import { COLORS, Text, FontWeight } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Spinner } from '@portal/components/helpers';
import { Header } from '@portal/components/helpers/header';
import { TrackedLink } from '@portal/components/wt/tracked_link';
import { planUpdateURL } from '@portal/config/routes';
import { usePlanDetailsQuery } from '@portal/schema';
import { onboardingUpdatable, orderDisplayName } from '@portal/utils/order';
import { IOrder } from '@shared/types';

const UPDATE_PLAN_LABEL = 'Update Plan';

const Body = styled(Text.Body)<{ color?: string }>`
  color: ${({ color }) => color || COLORS.panther};
`;

const Section = styled.div`
  margin-bottom: 16px;
`;

export const Plan: React.FC<{ order: IOrder }> = ({ order }) => {
  const { data, loading } = usePlanDetailsQuery();

  if (loading) {
    return <Spinner />;
  }

  if (!order.serviceType || !data?.account.subscriptionSet.storageSubscriptions.length) {
    return null;
  }

  const { subscriptionSet } = data.account;
  const subscription = subscriptionSet.storageSubscriptions[0];
  const { cubicFootage, dimensions, name } = subscription;
  const storageName = dimensions ? `${dimensions.length}′ × ${dimensions.width}′` : name;

  return (
    <>
      <Header tag="h4">Storage Plan</Header>
      <Section>
        <Body weight={FontWeight.Medium}>Move-in Service</Body>
        <Body color={COLORS.storm}>{orderDisplayName(order, false)}</Body>
      </Section>
      <Section>
        <Body weight={FontWeight.Medium}>Storage Space</Body>
        <Body weight={FontWeight.Medium} color={COLORS.storm}>
          {storageName}
        </Body>
        <Body color={COLORS.storm}>{cubicFootage} cubic feet of storage</Body>
      </Section>
      {onboardingUpdatable(order) && (
        <TrackedLink
          params={{
            pageName: 'portal:appointment_details',
            container: 'storage_plan',
            action: 'click',
            objectType: 'input:button',
            objectName: 'update_plan',
            label: UPDATE_PLAN_LABEL,
            value: planUpdateURL(),
            order_id: order.id,
          }}
        >
          <a className="btn btn-primary" href={planUpdateURL()}>
            {UPDATE_PLAN_LABEL}
          </a>
        </TrackedLink>
      )}
      <hr />
    </>
  );
};
