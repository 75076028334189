import { Status, useCouponApplyMutation } from '@portal/schema';
import { useBreakpointFlags } from '@portal/utils';
import { Button, COLORS, Text, Input, Box, mq } from '@clutter/clean';
import styled from '@emotion/styled';
import { Spacer } from '@shared/components/helpers';
import React, { useState } from 'react';

const ErrorMessage = styled(Text.Callout)`
  color: ${COLORS.toucan};
  margin-bottom: 8px;
`;

const ButtonsContainer = styled(Box.Flex)`
  flex-direction: row;

  ${mq({
    justifyContent: ['space-between', 'start'],
  })}
`;

export const AddPromoCodeTextField: React.FC<{
  orderID: string;
  onCancel(): void;
  onCouponApplied(): void;
}> = ({ orderID, onCancel, onCouponApplied }) => {
  const { isMobile } = useBreakpointFlags();

  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  const [applyCoupon, { loading }] = useCouponApplyMutation();

  const onApplyCoupon = async () => {
    if (inputValue) {
      const response = await applyCoupon({ variables: { promoCode: inputValue, orderID: orderID } });
      if (response.data?.couponApply?.status === Status.Ok) {
        onCouponApplied();
      } else {
        setError(response.data?.couponApply?.error ?? 'Something went wrong. Please try again.');
      }
    }
  };

  return (
    <Box.Flex flexDirection="column">
      <Input
        value={inputValue ?? ''}
        onChange={(event) => {
          setError(undefined);
          setInputValue(event.target.value);
        }}
        state={error ? 'error' : undefined}
        disabled={loading}
        placeholder="Enter promo code"
      />
      {error && (
        <>
          <Spacer height="12px" />
          <ErrorMessage>{error}</ErrorMessage>
        </>
      )}
      <Spacer height="12px" />
      <ButtonsContainer>
        <Button size="small" kind="secondary" fullWidth={isMobile} onClick={onCancel}>
          Cancel
        </Button>
        <Spacer width="8px" />
        <Button
          size="small"
          fullWidth={isMobile}
          disabled={!inputValue || !!error}
          loading={loading}
          onClick={onApplyCoupon}
        >
          Add
        </Button>
      </ButtonsContainer>
    </Box.Flex>
  );
};
