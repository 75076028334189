import { Spinner } from '@portal/components/helpers';
import { orderURL } from '@portal/config/routes';
import { useEstimationFlowStatusQuery } from '@portal/schema';
import { Box, Text, mq } from '@clutter/clean';
import styled from '@emotion/styled';
import { Spacer } from '@shared/components/helpers';
import * as React from 'react';
import { useHistory } from 'react-router';
import { BaseStepType } from '../../data';
import { StepContainer } from '../../step_container';
import { estimationFitEnum } from '../../v1/data';
import { BadFitCard } from '../../v1/review/bad_fit_card';
import { GoodFitCard } from '../../v1/review/good_fit_card';
import { StepName, Values } from '../data';
import { editButtonTrackingParams, SummaryItem } from './summary_item';
import { WalkthroughCsat } from './walkthrough_csat';

const SummaryContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  margin-top: 20px;

  ${mq({
    gridTemplateColumns: ['repeat(1, 1fr)', null, 'repeat(2, 1fr)'],
  })};
`;

const SelectCard: React.FC<{
  onChange(key: keyof Values, value?: any, cb?: (values: Values) => void): void;
  goToStep(step: string | number, meta?: any, toPrevious?: boolean): void;
  complete: boolean;
  estimationCalculatedCuft: number;
  planSizeName: string;
  planSizeCuft: number;
  planSizeComparison: string;
  estimationFit: estimationFitEnum;
}> = ({
  onChange,
  goToStep,
  complete,
  estimationCalculatedCuft,
  planSizeName,
  planSizeCuft,
  planSizeComparison,
  estimationFit,
}) =>
  estimationFit === estimationFitEnum.GoodFit ? (
    <GoodFitCard planSizeName={planSizeName} />
  ) : (
    <BadFitCard
      onChange={onChange}
      goToStep={goToStep}
      complete={complete}
      estimationCalculatedCuft={estimationCalculatedCuft}
      planSizeName={planSizeName}
      planSizeCuft={planSizeCuft}
      planSizeComparison={planSizeComparison}
      estimationFit={estimationFit}
      stepName={StepName.Plan}
    />
  );

const OnboardingReview: React.FC<BaseStepType> = (props) => {
  const {
    values: { selectedItemCategories, address },
    onChange,
    orderID,
    goToStep,
    createStepTransition,
  } = props;

  const { data, loading } = useEstimationFlowStatusQuery({
    variables: {
      orderID: orderID,
    },
    fetchPolicy: 'network-only',
  });

  const history = useHistory();

  if (loading || !data || !data.estimationStatus) {
    return <Spinner />;
  }

  // Since moving orders won't see this step we can assert that planSizeComparison is present
  const { complete, estimationCalculatedCuft, planSizeName, planSizeCuft, planSizeComparison, fitsPlanSize } =
    data.estimationStatus;

  const numItems = selectedItemCategories?.reduce((count, obj) => count + obj.quantity, 0);

  const estimationFit = fitsPlanSize
    ? estimationFitEnum.GoodFit
    : estimationCalculatedCuft > planSizeCuft
    ? estimationFitEnum.OverCapacity
    : estimationFitEnum.UnderCapacity;

  const onNext = () => {
    createStepTransition({
      name: 'virtual_walkthrough',
      actionName: 'flow_completed',
      position: 0,
      values: {
        estimationFit: estimationFit,
      },
    });
    history.push(orderURL(orderID));
  };

  return (
    <StepContainer {...props} canNext={true} next={onNext} nextLabel={'Close Walkthrough'}>
      <Box maxWidth="768px" margin="0 auto">
        <Text.SmallCaps>Virtual Walkthrough</Text.SmallCaps>
        <Text.Title size="medium">Your results are ready</Text.Title>
        <Box margin="20px 0">
          <SelectCard
            onChange={onChange}
            goToStep={goToStep}
            complete={complete}
            estimationCalculatedCuft={estimationCalculatedCuft}
            planSizeName={planSizeName}
            planSizeCuft={planSizeCuft}
            planSizeComparison={planSizeComparison!}
            estimationFit={estimationFit}
          />
        </Box>
        <Text.Title size="extraSmall">Your selection</Text.Title>
        <SummaryContainer>
          <SummaryItem
            name="Starting address"
            value={address!.street!}
            onEdit={() => history.push(orderURL(orderID, 'address'))}
            trackingParams={editButtonTrackingParams(orderID, 'edit_address_step', 'edit_address_button')}
          />
          <SummaryItem
            name="Items"
            value={`${numItems} items`}
            onEdit={() => goToStep(StepName.ItemInventory)}
            trackingParams={editButtonTrackingParams(orderID, 'edit_items_step', 'edit_items_button')}
          />
        </SummaryContainer>
        <Spacer height="2rem" />
        <WalkthroughCsat orderID={orderID} />
      </Box>
    </StepContainer>
  );
};

export { OnboardingReview };
