import React from 'react';
import { UnstyledButton as CleanUnstyledButton } from '@clutter/clean';
import { Tooltip } from '@shared/components/bootstrap';
import { ConditionalWrapper, L1_AGENT_CUSTOMER_ONLY_ERROR_MESSAGE, BLOCK_ACTION } from './util';

export const UnstyledButton: React.FC<
  React.ComponentProps<typeof CleanUnstyledButton> & {
    showErrorInTooltip?: boolean;
    onActionBlocked?(error: string): void;
  }
> = ({ showErrorInTooltip = false, onActionBlocked, children, ...props }) => {
  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!BLOCK_ACTION) {
      props.onClick?.(e);
    } else {
      onActionBlocked?.(L1_AGENT_CUSTOMER_ONLY_ERROR_MESSAGE);
    }
  };
  return (
    <ConditionalWrapper
      condition={BLOCK_ACTION && showErrorInTooltip}
      wrapper={(c) => <Tooltip title={L1_AGENT_CUSTOMER_ONLY_ERROR_MESSAGE}>{c}</Tooltip>}
    >
      <CleanUnstyledButton {...props} onClick={onButtonClick}>
        {children}
      </CleanUnstyledButton>
    </ConditionalWrapper>
  );
};
