import styled from '@emotion/styled';
import React from 'react';

import AFFIRM from '@portal/images/affirm.svg';

const Image = styled.img`
  margin: -12px 0 10px;
`;

export const Affirm: React.FC = () => <Image src={AFFIRM} alt="Affirm" />;
