import React from 'react';

import { Box } from '@clutter/clean';
import { Mode } from '@portal/components/helpers/inventory/filters';
import { InventoryItemFragment } from '@portal/schema';

import { Container, Offsets } from '@portal/components/helpers/inventory/layout';
import { InventoryFragment } from '@portal/components/helpers/inventory/item_card';

import { ItemCard } from './item';

export const isSelected = (item: InventoryFragment, selectedItemIDs: Set<string>) => {
  if (item.__typename === 'Item') {
    return selectedItemIDs.has(item.id);
  }
  if (item.__typename === 'ItemGroup') {
    return item.items.every(({ id }) => selectedItemIDs.has(id));
  }
  return false;
};

const Items: React.FC<{
  items: InventoryFragment[];
  selectedIDs: Set<string>;
  onSelect(items: InventoryItemFragment[]): void;
  onDeselect(itemIDs: string[]): void;
  disableShippingTag?: boolean;
  disablePrimaryOrders?: boolean;
}> = ({ items, selectedIDs, onSelect, onDeselect, disableShippingTag, disablePrimaryOrders }) => (
  <Container>
    {items.map((item) => (
      <Box key={item.uuid} margin="8px">
        <ItemCard
          item={item}
          selected={isSelected(item, selectedIDs)}
          onSelect={onSelect}
          onDeselect={onDeselect}
          disableShippingTag={disableShippingTag}
          disablePrimaryOrders={disablePrimaryOrders}
        />
      </Box>
    ))}
    <Offsets />
  </Container>
);

export const ReturnInventory: React.FC<{
  mode: Mode;
  inventory: InventoryFragment[];
  selections: InventoryItemFragment[];
  onChange(_: InventoryItemFragment[]): void;
  disableShippingTag?: boolean;
  disablePrimaryOrders?: boolean;
}> = ({ mode, inventory, selections, onChange, disableShippingTag, disablePrimaryOrders }) => {
  const selectedIDs = new Set(selections.map(({ id }) => id));

  const onSelect = (items: InventoryItemFragment[]) => {
    onChange([...selections, ...items]);
  };

  const onDeselect = (itemIDs: string[]) => {
    onChange(selections.filter(({ id }) => !itemIDs.includes(id)));
  };

  const selectedInventory = () => inventory.filter((item) => isSelected(item, selectedIDs));

  return (
    <Items
      items={mode === Mode.All ? inventory : selectedInventory()}
      selectedIDs={selectedIDs}
      onSelect={onSelect}
      onDeselect={onDeselect}
      disableShippingTag={disableShippingTag}
      disablePrimaryOrders={disablePrimaryOrders}
    />
  );
};
