import React from 'react';
import styled from '@emotion/styled';

import { Text, COLORS } from '@clutter/clean';

// TODO: Extract to @clutter/clean

interface ITextButtonProps {
  href?: string;
  themeName?: 'primary' | 'destructive';
  className?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const LinkElement = styled(Text.SmallCaps)`
  background: none;
  border: none;
  position: relative;
  text-transform: uppercase;
  color: ${COLORS.tealPrimary};

  &:hover {
    color: ${COLORS.tealBrand};
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: -8px;
  }
`.withComponent('a');

const ButtonElement = LinkElement.withComponent('button');

export const TextButton: React.FC<ITextButtonProps> = ({ href, ...props }) => {
  if (href) {
    return <LinkElement href={href} {...props} />;
  } else {
    return <ButtonElement {...props} />;
  }
};
