import * as React from 'react';
import { useState } from 'react';

import { Button, Modal } from '@shared/components/bootstrap';

import { SentenceList } from '@portal/components/helpers/sentence_list';

import { Document, DocumentAcknowledgmentKind } from '@portal/schema';

const link = ({ id, url, name }: Pick<Document, 'id' | 'url' | 'name'>) => (
  <a key={id} href={url ?? undefined} target="_blank">
    {name}
  </a>
);
const display = ({ name }: Pick<Document, 'name'>) => <>{name}</>;

export const Prompt: React.FC<{
  saving: boolean;
  documents: Array<Pick<Document, 'id' | 'url' | 'name'>>;
  acknowledge(kind: DocumentAcknowledgmentKind): Promise<void>;
  onClose(): void;
}> = ({ saving, documents, acknowledge, onClose }) => {
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(true);

  if (!visible) {
    return null;
  }

  return (
    <Modal onClose={() => setVisible(false)}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>New Terms of Use</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Welcome back to Clutter! We have revised our <SentenceList elements={documents.map(link)} />, (collectively,
            the "Terms"). Please confirm you have read and agreed to our new Terms.
          </p>
          <div className="form-check">
            <input
              className="form-check-input"
              checked={confirmed}
              type="checkbox"
              onChange={(event) => setConfirmed(event.target.checked)}
              id="confirmed"
            />
            <label className="form-check-label" htmlFor="confirmed">
              I have read the new <SentenceList elements={documents.map(display)} />
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            kind="secondary"
            disabled={saving || !confirmed}
            onClick={() => acknowledge(DocumentAcknowledgmentKind.Declined).then(onClose)}
          >
            Decline
          </Button>
          <Button
            kind="primary"
            disabled={saving || !confirmed}
            onClick={() => acknowledge(DocumentAcknowledgmentKind.Accepted).then(onClose)}
          >
            Accept
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
