import * as React from 'react';

import { Spacing, Text } from '@shared/components/bootstrap';

import { useInventoryForSave } from './use_inventory_for_save';

import { RoomCategoriesSummaryTable } from './room_categories_summary_table';

export const RoomSummary: React.FC = () => {
  const inventory = useInventoryForSave();

  if (inventory.rooms !== undefined) {
    return (
      <Spacing pb={3}>
        <Text tag="p" style="info">
          <strong>How many rooms are we packing up?</strong>
        </Text>
        <RoomCategoriesSummaryTable rooms={inventory.rooms} />
      </Spacing>
    );
  }

  return null;
};
