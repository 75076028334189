import { COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

const RadioInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
`;

const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  z-index: 1;
  cursor: pointer;
  margin: 0;
  padding: 12px 0;
`;

const Description = styled.div`
  margin-left: 16px;
  font-size: 16px;
  line-height: 26px;
  color: ${COLORS.panther};
`;

const CheckedCircle = styled.div`
  background: ${COLORS.tealPrimary};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  margin: 0 auto;
`;

const Circle = styled.div`
  background: ${COLORS.cloud};
  border: 2px solid ${COLORS.tealPrimary};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.11);
`;

export interface IRadioOption<T = any> {
  value: T;
  label: string | React.ReactElement<any>;
}

interface IRadioProps<T = any> {
  name: string;
  className?: string;
  value?: T;
  options: Array<IRadioOption<T>>;
  compareOption?(option: IRadioOption<T>, value: T): boolean;
  onChange(value?: T): void;
}

export const Radio = <T extends any = any>({
  name,
  className,
  options,
  value,
  compareOption,
  onChange,
}: IRadioProps<T>) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const optionIndex = Number(e.currentTarget.value);
    const option = options[optionIndex];
    onChange(option.value);
  };

  return (
    <div className={className}>
      {options.map((option, i) => {
        const checked = compareOption && value !== undefined ? compareOption(option, value) : option.value === value;
        const id = `${name}-${i}`;
        return (
          <React.Fragment key={id}>
            <RadioInput id={id} type="radio" name={name} value={i} onChange={handleOnChange} checked={checked} />
            <Label htmlFor={id}>
              <Circle>{checked && <CheckedCircle />}</Circle>
              <Description>{option.label}</Description>
            </Label>
          </React.Fragment>
        );
      })}
    </div>
  );
};
