import * as React from 'react';
import { SelectFormGroup } from '../select_form_group';

const OPTIONS = [
  { label: 'More than a 5 minute walk', value: true },
  { label: 'Less than a 5 minute walk', value: false },
];

export const LongCarry: React.FC<{
  value?: boolean;
  onChange(value: boolean): void;
}> = ({ value, onChange }) => (
  <SelectFormGroup
    label="How far is the walk from your door to the truck?"
    value={value}
    onChange={onChange}
    options={OPTIONS}
  />
);
