import React from 'react';

const ID = 'mover-with-boxes';

export const MoverWithBoxes: React.FC<{ title?: string }> = ({ title = 'Mover With Boxes' }) => (
  <svg aria-labelledby={ID} width="79" height="125" viewBox="0 0 79 125" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title id={ID}>{title}</title>
    <g clipPath="url(#clip0)">
      <path
        d="M43.485 64.2142L29.579 55.1694L29.5123 56.3C29.2455 60.5564 29.2789 61.1882 29.7458 70.8648L36.382 95.3723L49.621 90.0186L43.485 64.2142Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.8262 109.771L63.2606 112.198"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.1617 115.092L57.6914 123.438C56.891 124.203 55.8573 124.635 54.7568 124.635H51.3887L51.422 121.676L54.8568 120.213C54.8902 120.179 54.9235 120.146 54.9235 120.113L55.7239 114.56"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.861 113.761L56.7575 121.775C55.9572 122.54 54.9234 122.972 53.8229 122.972H51.4552L51.4219 124.635H54.79C55.8905 124.635 56.9576 124.202 57.7246 123.438L66.1949 115.091L64.861 113.761Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.1617 115.091L57.6914 123.438C56.891 124.203 55.8573 124.635 54.7568 124.635H51.3887L51.422 121.675"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.7885 68.7698L51.3887 67.7057L49.688 59.2594H36.3156L32.5139 62.2854L33.6811 68.803L29.9795 94.5077L44.9193 98.5646L50.7885 68.7698Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.3255 106.545L49.6217 90.0185L40.0176 95.3723L55.7577 114.593L60.4597 113.595L60.8265 109.771"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.2178 60.8892L40.3845 62.1195C40.6846 64.281 42.5521 65.8771 44.7531 65.8771H45.7868"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.5547 60.4896L50.0553 68.1045"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.8787 61.0884L2.93457 59.1264L4.6353 40.6377L29.5794 42.5996L27.8787 61.0884Z"
        fill="#FFEABF"
        stroke="#FFEABF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.8787 61.0886L2.93457 59.1266"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7077 41.735L18.3409 45.4926L15.1729 45.2266L15.5063 41.5023"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.6785 42.4332L2.73438 40.4713L4.43511 21.9493L29.3792 23.9445L27.6785 42.4332Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.6785 42.4329L2.73438 40.4709"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5075 23.0799L18.1407 26.8042L14.9727 26.5714L15.3061 22.8138"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.2539 19.8212C45.0191 21.1513 41.7844 22.4814 38.5163 23.7118C35.5817 26.372 31.3466 30.2626 30.5129 38.6092L29.3457 60.4897L50.5549 59.1928L48.9542 52.376C53.2894 37.1128 52.9559 28.7663 48.2539 19.8212Z"
        fill="#00A499"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.3216 48.8511C49.6213 51.0126 48.7209 53.0743 47.5537 55.0695"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.321 33.8538L46.9199 35.6827"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.5829 34.4856C35.2157 36.248 34.382 39.0413 33.715 42.4996L19.3755 56.1666L18.3417 56.2331C17.7081 56.2664 17.1079 56.3994 16.541 56.6322L16.4743 56.6654C15.9074 56.8982 15.2738 57.0312 14.6735 57.0644L12.2725 57.1975C12.3058 57.8958 12.8394 58.4943 13.5397 58.5941L15.7406 58.9266L14.6068 60.556C14.3067 60.9883 14.4734 61.5869 14.9403 61.8196L17.6748 63.1498C18.4418 63.5155 19.3755 63.316 19.8757 62.6177L22.2101 59.5917C22.2101 59.5917 42.2521 45.0601 39.8177 46.9555L45.32 39.3073L36.5829 34.4856Z"
        fill="white"
      />
      <path
        d="M14.873 61.8199L17.6076 63.15C18.3746 63.5158 19.2749 63.3163 19.8085 62.6179L22.1428 59.5919C24.5439 58.1288 27.145 55.9008 29.4793 54.2714L29.2459 61.1881"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0068 62.3852C16.7738 62.751 17.6742 62.5515 18.2078 61.8531L19.2082 60.5563"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1738 61.8196C15.7407 61.8196 16.2743 61.5869 16.6411 61.1213L17.6416 59.8245"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.5877 31.6263C47.421 34.2201 46.7873 37.1464 45.2533 39.2746L39.751 46.9228"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.40234 18.9231C5.23568 18.1251 5.06901 17.2937 4.90234 16.4957"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.76743 20.4199C1.16717 20.0209 0.600259 19.6218 0 19.2228"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2053 61.9532C10.505 62.2857 9.838 62.6515 9.1377 62.984"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.7462 121.41L30.1802 119.148L25.945 119.847L23.6774 116.289L17.408 116.921L13.1062 115.657L11.3721 118.517"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9429 122.174L12.2058 117.12L11.3721 118.55L20.3093 123.704C21.4097 124.336 22.6769 124.668 23.9442 124.668H26.7454L27.7124 123.139H24.6111C23.3106 123.139 22.0433 122.806 20.9429 122.174Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3721 118.516L20.3093 123.704C21.4097 124.335 22.6769 124.668 23.9442 124.668H26.7454"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.56738 124.668H76.666"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.6768 116.288L25.9444 119.846L30.1796 119.148L42.6516 98.0324L32.3138 95.1726L25.711 112.897"
        fill="white"
      />
      <path
        d="M23.6768 116.288L25.9444 119.846L30.1796 119.148L42.6516 98.0324L32.3138 95.1726L25.711 112.897"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.5846 21.6505C39.8176 20.9854 39.3174 20.0543 39.084 19.09C39.851 18.957 40.5513 18.5247 41.0848 17.9594C41.6184 17.3608 41.9185 16.596 41.9185 15.8312"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.1163 19.0562C37.5494 19.0894 36.9825 19.1227 36.449 18.9232C35.9154 18.7569 35.4152 18.2581 35.3818 17.726C35.5819 17.3603 35.8154 16.8615 36.0154 16.4957C35.9487 16.2297 36.0154 15.9304 35.9821 15.6644C36.4823 15.7641 37.0492 15.6976 37.4827 15.4316"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.8824 14.135C35.2821 14.2347 34.6485 13.6694 34.1816 13.3036L36.2492 10.6434L36.1825 9.81207C36.1492 9.21351 36.3159 8.64821 36.616 8.14941"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.5536 18.9899C46.2868 17.3938 46.6537 15.6646 47.5874 14.3345C48.3544 13.2372 49.2214 12.4391 49.655 11.1755C50.0885 9.91185 50.0551 8.41546 49.3215 7.28485"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.9524 11.4081C43.0858 10.9426 43.586 10.6766 44.0528 10.7431C44.5197 10.8096 44.8865 11.2086 45.0199 11.6409C45.1533 12.1065 45.0533 12.6053 44.7865 13.0043C44.5197 13.4033 44.1529 13.7026 43.7527 13.9354C43.3525 14.1682 42.8857 14.3344 42.4521 14.4674"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.3211 3.85977C48.8542 3.92627 48.2539 3.82651 47.8204 3.89302C47.7204 3.49398 47.5203 2.99519 47.4202 2.59615C46.92 2.76241 46.2531 2.46314 45.7528 2.6294C45.5528 2.29687 45.3527 1.83133 45.3193 1.43229C44.7524 1.53205 44.1522 1.66506 43.6519 1.93109C43.4185 1.66506 43.2518 1.23277 43.0517 1C42.6515 1.29928 42.0513 1.66506 41.6511 1.96434C41.3843 1.66506 40.9841 1.36578 40.684 1.06651C40.3505 1.36578 39.7503 1.66506 39.4168 1.96434C39.05 1.79807 38.6164 1.86458 38.2496 1.69832C38.2163 2.0641 38.0162 2.42988 37.9828 2.79567C37.616 2.76241 37.1158 2.79567 36.749 2.72916C36.5155 3.1282 36.3154 3.76001 36.5155 4.15904C36.2487 4.15904 35.8152 4.29206 35.5484 4.29206C35.4484 5.12338 35.6818 6.12098 36.3821 6.61977L36.8824 6.78604L43.085 7.01881L49.1543 6.45351L49.3211 5.55567C49.2877 5.05688 49.3211 4.35856 49.3211 3.85977Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.8185 7.21814L42.7852 10.5434"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.7861 8.18276L49.3218 7.31818"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5139 62.2854L33.681 68.803L32.2471 78.9119"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="79" height="125" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
