import { BorderlessList } from '@portal/components/account_resolution/borderless_list';
import { ScheduleAppointmentLink } from '@portal/components/app/schedule_appointment_link';
import { BillingTabs } from '@portal/components/billing/tabs';
import { Spinner } from '@portal/components/helpers';
import {
  Boxes,
  CleanCreditCard as CreditCard,
  MoverWithCartBoxes,
  PaperworkClipboard,
} from '@portal/components/shared/icons';
import { TrackedClick } from '@portal/components/wt/tracked_click';
import { billingURL, claimsURL, planUpdateURL } from '@portal/config/routes';
import { useAccountDetailQuery } from '@portal/schema';
import { TrackedLink } from '@portal/components/wt/tracked_link';
import { IEventParams } from '@portal/types/wt/event';
import { onboardingUpdatable } from '@portal/utils/order';
import { Box, COLORS, FontWeight, List, Text } from '@clutter/clean';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as React from 'react';
import { Link } from 'react-router-dom';

const linkStyle = css`
  &:hover {
    text-decoration: none;
    & > div > div {
      color: ${COLORS.tealBrand};
    }
  }
`;

const TextLink = styled(Text.Body)`
  color: ${COLORS.tealPrimary};
  align-self: center;
`;

const StyledAnchorTag = styled.a`
  ${linkStyle}
`;

const StyledLink = styled(Link)`
  ${linkStyle}
`;

const WT_PARAMS: IEventParams = {
  pageName: 'portal:help_start',
  container: 'quick_actions',
  action: 'click',
  objectType: 'link',
};

const FIXED_QUICK_ACTION_COUNT = 1;
const UPDATE_PLAN_NAME = 'update_storage_plan';

const TrackedRouterLink: React.FC<{
  href: string;
  objectName: string;
  children: React.ReactNode;
  label?: string;
  position: number;
}> = ({ href, objectName, children, label, position }) => (
  <TrackedClick
    params={{
      ...WT_PARAMS,
      objectName,
      label,
      position,
      value: href,
    }}
  >
    <StyledLink to={href}>{children}</StyledLink>
  </TrackedClick>
);

interface IQuickAction {
  copy: string;
  icon: React.ReactNode;
  href: string;
  objectName: string;
}

type IQuickActionProps = IQuickAction & {
  position: number;
};

const QUICK_ACTIONS: IQuickAction[] = [
  {
    copy: 'Add a payment method',
    icon: <CreditCard />,
    href: billingURL(BillingTabs.Payment),
    objectName: 'add_payment_method',
  },
  {
    copy: 'Update my storage plan',
    icon: <Boxes width={45} height={47} />,
    href: planUpdateURL(),
    objectName: UPDATE_PLAN_NAME,
  },
  {
    copy: 'File a claim',
    icon: <PaperworkClipboard width={36} height={42} title="Clipboard" />,
    href: claimsURL(),
    objectName: 'file_claim',
  },
];

const ActionItem: React.FC<{ text: React.ReactNode; icon: React.ReactNode }> = ({ text, icon }) => (
  <Box.Flex justifyContent="flex-start">
    <Box.Flex justifyContent="center" width="50px" margin="0 20px 0 0">
      {icon}
    </Box.Flex>
    <TextLink weight={FontWeight.Medium}>{text}</TextLink>
  </Box.Flex>
);

// The Appointment Action component is dynamic and needs to conform to the ScheduleAppointmentLink's API
const AppointmentAction: React.FC<{
  href: string;
  text: string;
  objectName: string;
  children: React.ReactNode;
  onClick: React.MouseEventHandler;
}> = ({ href, text, objectName, onClick, children }) => (
  <TrackedLink
    params={{
      ...WT_PARAMS,
      objectName,
      position: 1,
      label: text,
      value: href,
    }}
  >
    <StyledAnchorTag href={href} onClick={onClick}>
      <ActionItem text={children} icon={<MoverWithCartBoxes />} />
    </StyledAnchorTag>
  </TrackedLink>
);

const QuickAction: React.FC<IQuickActionProps> = ({ copy, href, icon, objectName, position }) => (
  <TrackedRouterLink href={href} objectName={objectName} label={copy} position={position}>
    <ActionItem text={copy} icon={icon} />
  </TrackedRouterLink>
);

export const QuickActions: React.FC = () => {
  const { data, loading } = useAccountDetailQuery();

  const planUpdatable = onboardingUpdatable(data?.upcomingStorageOnboarding);

  const actions: IQuickAction[] = loading
    ? []
    : QUICK_ACTIONS.filter((action) => action.objectName !== UPDATE_PLAN_NAME || planUpdatable);

  return (
    <>
      <Text.Title size="large">Quick actions</Text.Title>
      {loading && <Spinner />}
      {actions.length !== 0 && (
        <BorderlessList density="default" verticalPadding={24}>
          <List.Item>
            <ScheduleAppointmentLink component={AppointmentAction} />
          </List.Item>
          {actions.map((action, index) => (
            <List.Item key={action.copy}>
              <QuickAction {...action} position={index + 1 + FIXED_QUICK_ACTION_COUNT} />
            </List.Item>
          ))}
        </BorderlessList>
      )}
    </>
  );
};
