import React from 'react';

const ID = 'chevron-left';

export const ChevronLeft: React.FC<{ title?: string; color?: string }> = ({ title = 'Back', color = 'black' }) => (
  <svg aria-labelledby={ID} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title id={ID}>{title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.42099 2.3737C9.61764 2.1696 9.94446 2.1696 10.1411 2.3737L10.6657 2.9182C10.8523 3.11185 10.8523 3.41839 10.6657 3.61204L6.77217 7.65308C6.58558 7.84673 6.58558 8.15327 6.77217 8.34692L10.6657 12.388C10.8523 12.5816 10.8523 12.8882 10.6657 13.0818L10.1411 13.6263C9.94446 13.8304 9.61764 13.8304 9.42099 13.6263L4.33426 8.34692C4.14768 8.15327 4.14768 7.84673 4.33426 7.65308L9.42099 2.3737Z"
      fill={color}
    />
  </svg>
);
