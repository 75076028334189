import * as React from 'react';

import { Select } from '@clutter/clean';
import { FieldFormGroup } from './field_form_group';

type Option<T> = {
  label: string;
  value: T;
};

type SelectProps<T = any> = {
  options: Array<Option<T>>;
  onChange(value?: T): void;
  value: T;
  placeholder?: string;
  label: React.ReactNode;
  error?: string;
};

export const SelectFormGroup: React.FC<
  Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'onChange' | 'value'> & SelectProps
> = ({ label, options, error, value, id, ...props }) => (
  <FieldFormGroup label={label} error={error} id={id}>
    <Select
      {...props}
      options={[{ label: '- Select - ', value: undefined }, ...options]}
      state={error ? 'error' : undefined}
      value={value}
      id={id}
    />
  </FieldFormGroup>
);
