import { Estimation__AlgorithmName } from '@portal/schema';
import React from 'react';
import styled from '@emotion/styled';

import { Button, COLORS, mq, Text } from '@clutter/clean';

import home from '@portal/images/home.svg';
import boxes from '@portal/images/boxes.svg';
import furniture from '@portal/images/furniture.svg';
import { useBreakpointFlags } from '@portal/utils/browser';

import { BaseStepType } from './data';
import { StepContainer } from './step_container';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 720px;
  margin: 0 auto;
  justify-content: space-around;
  ${mq({
    marginTop: [0, '50px'],
  })}
`;

const CircleContainer = styled.div`
  text-align: center;
  h4 {
    margin-top: -16px;
  }
`;

const BoxesContainer = styled(CircleContainer)`
  ${mq({
    marginTop: ['32px', 0],
  })}
`;

const FurnitureContainer = styled(CircleContainer)<{ isV1: boolean }>`
  ${({ isV1 }) =>
    mq({
      marginRight: [isV1 ? '32px' : 0, 0],
    })}
`;

const Circle = styled.div`
  background-color: ${COLORS.tealBackground};
  border-radius: 100%;
  height: 141px;
  width: 141px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlexLineBreak = styled.div`
  width: 100%;
  ${mq({
    display: ['block', 'none'],
  })}
`;

const Description = styled.div`
  ${mq({
    color: [COLORS.hippo, COLORS.panther],
    marginTop: ['32px', '70px'],
  })}
`;

const GetStarted = styled(Button)`
  width: 350px;
  margin-top: 50px;
`;

export const Intro: React.FC<BaseStepType> = (props) => {
  const { isMobile } = useBreakpointFlags();
  const isV1 = props.algorithmName === Estimation__AlgorithmName.V1;

  return (
    <StepContainer
      {...props}
      headline="Here’s what we need more details on:"
      nextLabel="Get Started"
      hideNav={!isMobile}
      canPrev={false}
    >
      <Container>
        <CircleContainer>
          <Circle>
            <img src={home} />
          </Circle>
          <Text.Title size="extraSmall">Your home</Text.Title>
        </CircleContainer>
        {isV1 && (
          <BoxesContainer>
            <Circle>
              <img src={boxes} />
            </Circle>
            <Text.Title size="extraSmall">Boxes</Text.Title>
          </BoxesContainer>
        )}
        <FlexLineBreak />
        <FurnitureContainer isV1={isV1}>
          <Circle>
            <img src={furniture} />
          </Circle>
          <Text.Title size="extraSmall">{isV1 ? 'Furniture' : 'Your items'}</Text.Title>
        </FurnitureContainer>
        <Description>
          Don’t worry—it’s okay to estimate, and you can always come back to update the details.
        </Description>
        {!isMobile && <GetStarted onClick={props.next}>Get Started</GetStarted>}
      </Container>
    </StepContainer>
  );
};
