import { Mfa__VerificationKind, Status, useMfaCodeCheckMutation } from '@portal/schema';
import { Box, Button, COLORS, FontWeight, Modal, mq, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { Spacer } from '@shared/components/helpers';
import React, { useState } from 'react';
import { VerificationInput } from './verification_input';

const CODE_LENGTH = 6;

const ErrorMessage = styled(Text.Callout)`
  color: ${COLORS.toucan};
  margin-bottom: 8px;
`;

const TealFontSpan = styled.span`
  color: ${COLORS.tealPrimary};
`;

const MainModalBoxContainer = styled(Box)`
  overflow-y: scroll;
  ${mq({
    maxHeight: ['650px', '700px', '750px', '800px'],
    width: ['340px', '460px', '580px', '700px'],
  })}
`;

export const MFAModal: React.FC<{
  contactMethod: string;
  requestID: string;
  hideModal: () => void;
  onVerified: () => void;
}> = ({ contactMethod, requestID, hideModal, onVerified }) => {
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [enteredCode, setEnteredCode] = useState<string>('');

  const [mfaCodeCheck, { loading }] = useMfaCodeCheckMutation();

  const checkMFACode = async () => {
    const response = await mfaCodeCheck({ variables: { requestId: requestID, code: enteredCode } });

    if (response.data?.mfaCodeCheck?.status === Status.Ok) {
      onVerified();
      hideModal();
    } else {
      setError(response.data?.mfaCodeCheck?.error ?? 'You have entered an invalid code.');
    }
  };

  return (
    <Modal isOpen={true} handleModalClose={hideModal} includeCloseButton={true}>
      <MainModalBoxContainer>
        <Box background={COLORS.cloud} color={COLORS.panther} padding="24px 0px 0px 0px" textAlign={'center'}>
          <Text.SmallCaps>{Mfa__VerificationKind.EmailUpdate ? 'Verify Email' : 'Verify Phone Number'}</Text.SmallCaps>
        </Box>
        <Box padding="24px" background={COLORS.cloud} textAlign={'center'}>
          <Text.Title size="medium" color={COLORS.tealDark}>
            {Mfa__VerificationKind.EmailUpdate ? 'Verify your email' : 'Verify your phone number'}
          </Text.Title>
          <Spacer height="12px" />
          <Text.Body>
            Last step! Please verify your {Mfa__VerificationKind.EmailUpdate ? 'email address' : 'phone nnumber'}
            <br />
            to save your updated account information.
          </Text.Body>
          <Spacer height="24px" />
          <Box padding={['0px 24px 12px', '0 116px 12px']}>
            <Box margin="auto auto 12px">
              <Text.Body weight={FontWeight.Medium}>
                Enter the 6-digit code we sent to <TealFontSpan>{contactMethod}</TealFontSpan>
              </Text.Body>
            </Box>
            <VerificationInput
              length={CODE_LENGTH}
              disabled={loading}
              value={enteredCode}
              onChange={async (newEnteredCode: string): Promise<void> => {
                setEnteredCode(newEnteredCode);
                setError(undefined);
              }}
              state={error ? 'error' : undefined}
            />
          </Box>
          <Spacer height="24px" />
          <Button
            size="small"
            fullWidth={true}
            disabled={enteredCode.length < CODE_LENGTH || !!error}
            loading={false}
            onClick={checkMFACode}
          >
            Verify
          </Button>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Box>
      </MainModalBoxContainer>
    </Modal>
  );
};
