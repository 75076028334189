import * as React from 'react';

import { Text } from '@shared/components/bootstrap';
import { useLatestProtectionSubscriptionQuery } from '@portal/schema';

export const Subscriptions = () => {
  const { data } = useLatestProtectionSubscriptionQuery();
  return (
    <Text alignment="right" wrapping="truncate" tag="p">
      <>Protection Plan: </>
      {data && data.account ? (
        <strong>
          {data.account.latestProtectionSubscription ? data.account.latestProtectionSubscription.name : 'N/A'}
        </strong>
      ) : (
        <span>-</span>
      )}
    </Text>
  );
};
