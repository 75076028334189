import * as React from 'react';

import { COLORS } from '@clutter/clean';
import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

import { Checkmark } from '@portal/components/shared/icons/checkmark_white';

type StyleValue = string | number | ((props: any) => string | number) | SerializedStyles;

function toggleStyleValue<T>(propName: keyof T, trueValue: StyleValue, falseValue: StyleValue) {
  return (props: T) => (props[propName] ? trueValue : falseValue);
}

const transparentize = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return 'rgba(' + r + ',' + g + ',' + b + ',' + alpha + ')';
};

export const Label = styled.label`
  user-select: none;
  width: 100%;
  display: flex;
  cursor: pointer;
  padding: 12px 0;
`;

export const InvisibleInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
`;

const CheckContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Square = styled.div<{ checked: boolean }>`
  background: ${toggleStyleValue('checked', COLORS.tealPrimary, COLORS.cloud)};
  border: 2px solid ${COLORS.tealPrimary};
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 4px;
  transition: background 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 8px 16px ${transparentize(COLORS.panther, 0.1)};
`;

const Description = styled.div`
  margin-left: 16px;
  color: ${COLORS.panther};
  font-size: 16px;
  font-weight: 100;
  line-height: 24px;
`;

const Fieldset = styled.fieldset`
  border: none;
`;

interface ICheckboxOption {
  key: string;
  label: string | React.ReactElement<any>;
}

export interface ICheckboxProps {
  name: string;
  className?: string;
  options: ICheckboxOption[];
  value: { [key: string]: boolean };
  onChange(name: string, value: { [key: string]: boolean }): void;
}

export const Checkbox: React.FC<ICheckboxProps> = ({ name, options, className, value, onChange }) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const optionKey = e.currentTarget.dataset.originalId!;
    const newValues = {
      ...value,
      [optionKey]: e.currentTarget.checked,
    };
    onChange(name, newValues);
  };

  return (
    <Fieldset className={className}>
      {options.map((option, i) => {
        const id = `${name}-${i}`;
        const checked = !!value[option.key];
        return (
          <Label key={i}>
            <InvisibleInput
              id={id}
              name={name}
              type="checkbox"
              data-original-id={option.key}
              checked={checked}
              onChange={handleOnChange}
            />
            <Square checked={checked}>
              {checked && (
                <CheckContainer>
                  <Checkmark />
                </CheckContainer>
              )}
            </Square>
            <Description>{option.label}</Description>
          </Label>
        );
      })}
    </Fieldset>
  );
};
