import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { PaymentPlanBanner } from '@portal/components/app/notices/payment_plan_banner';
import { payURL } from '@portal/config/routes';

import { BannerKind, bannerStyle, Base as BaseBanner } from './base';

const Container = styled.div`
  max-width: 712px;
  margin: auto;
  padding-bottom: 32px;
  width: 100%;
`;

const StyledPaymentPlanBanner = styled(PaymentPlanBanner)`
  ${bannerStyle}
`;

export const Banner: React.FC<{ offerID?: string; delinquentBalance: number; creditBalance: number }> = ({
  offerID,
  delinquentBalance,
  creditBalance,
}) => {
  const [redirectToPay, setRedirectToPay] = useState<boolean>(false);

  if (redirectToPay) {
    return <Redirect push to={payURL()} />;
  }

  if (creditBalance > 0) {
    return (
      <Container>
        <BaseBanner
          title="Credit"
          description="Your credit will be applied to your next payment."
          kind={BannerKind.Primary}
          amount={creditBalance}
        />
      </Container>
    );
  }

  if (offerID) {
    return (
      <Container>
        <StyledPaymentPlanBanner offerID={offerID} />
      </Container>
    );
  }

  return (
    <>
      {delinquentBalance > 0 && (
        <Container>
          <BaseBanner
            title="Delinquent Balance"
            description="Pay now to avoid late fees or auctioned items."
            amount={delinquentBalance}
            kind={BannerKind.Danger}
            buttonText="Pay Now"
            onButtonClick={() => setRedirectToPay(true)}
          />
        </Container>
      )}
    </>
  );
};
