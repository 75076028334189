import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { Button, COLORS, Text } from '@clutter/clean';
import { orderURL } from '@portal/config/routes';
import { IOrder } from '@portal/types';

import { Spacer } from '@shared/components/helpers';

import { client } from '@portal/libraries/apollo';
import { Account__CancelIntent__Reason as AccountCancelIntentReason, useCancellationPolicyQuery } from '@portal/schema';

const Title = styled(Text.Title)`
  margin-bottom: 24px;
`;

const Body = styled(Text.Body)`
  color: ${COLORS.hippo};
  white-space: pre-wrap;
`;

const PayToVacateMessage = styled(Text.Callout)`
  color: ${COLORS.toucan};
`;

const isPayToVacate = (order: IOrder) => order.accountCancelIntent?.reason === AccountCancelIntentReason.PayToVacate;

export const Cancel = ({ order }: { order: IOrder }) => {
  const { cancelable } = order.permissions;

  const { data } = useCancellationPolicyQuery({ client, variables: { orderID: order.id }, skip: !cancelable });

  if (!cancelable || !data) {
    return null;
  }

  const payToVacate = isPayToVacate(order);
  return (
    <>
      <Title size="small">Need to cancel?</Title>
      <Link to={!payToVacate ? orderURL(order.id, 'cancel') : '#'}>
        <Button kind="destructive" disabled={payToVacate}>
          Cancel
        </Button>
      </Link>
      <Spacer height="1rem" />
      {payToVacate ? (
        <PayToVacateMessage>
          This appointment cannot be canceled online due to an outstanding Pay-to-Vacate agreement. Please contact
          customer support if you need to modify your appointment time.
        </PayToVacateMessage>
      ) : (
        <>
          <Body>{data.cancellationPolicy}</Body>
          {order.permissions.chargeLateFee && (
            <Body>$25 late fee for each extra day that your items were held in storage.</Body>
          )}
        </>
      )}
    </>
  );
};
