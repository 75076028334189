import * as React from 'react';

import {
  useAcknowledgeDocumentsMutation,
  useUnacknowledgedDocumentsQuery,
  DocumentAcknowledgmentKind,
} from '@portal/schema';
import { Prompt } from './prompt';

export const Acknowledgment: React.FC = () => {
  const { data, refetch } = useUnacknowledgedDocumentsQuery();
  const [mutate, { loading: saving }] = useAcknowledgeDocumentsMutation();

  if (!data || !data.account) {
    return null;
  }
  const { unacknowledgedDocuments: documents } = data.account;
  if (!documents.length) {
    return null;
  }

  const acknowledge = async (kind: DocumentAcknowledgmentKind) => {
    const input = documents.map(({ id: documentID }) => ({
      documentID,
      kind,
    }));
    await mutate({ variables: { input } });
  };

  return <Prompt saving={saving} acknowledge={acknowledge} documents={documents} onClose={refetch} />;
};
