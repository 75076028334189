import * as React from 'react';
import { referralURL } from '@portal/config/routes';

const DIALOG_HEIGHT = 640;
const DIALOG_WIDTH = 480;

export const Action: React.FC<{
  source: string;
}> = ({ source, children }) => {
  const onClick = () => {
    const URL = referralURL(source, 'facebook');
    open(URL, 'Share on Facebook', `height=${DIALOG_HEIGHT},width=${DIALOG_WIDTH}`);
  };

  return (
    <div onClick={onClick} title="Facebook">
      {children}
    </div>
  );
};
