import styled from '@emotion/styled';
import * as React from 'react';
import { useHistory } from 'react-router';

import SAVINGS from '@portal/images/savings.svg';

import { useUsageWithDownsizeQuery } from '@portal/schema';

import { downsizeURL } from '@portal/config/routes';

import { Text, COLORS, Button, mq, transparentize } from '@clutter/clean';
import { Currency } from '@shared/components/helpers';
import { Name } from './name';

const Icon = styled.img`
  ${mq({
    width: ['80px', null, '116px'],
    height: ['70px', null, '92px'],
  })}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${transparentize(COLORS.tealBackground, 0.6)};
  border-radius: 4px;
  margin: 32px 0;

  ${mq({
    flexDirection: ['column', null, 'row'],
    textAlign: ['center', null, 'initial'],
    padding: ['24px', null, '24px 48px'],
  })}
`;

const IconEntry = styled.div`
  flex-grow: 0;
  flex-shrink: 0;

  ${mq({
    margin: ['-36px 0 0', null, '0 24px 0 0'],
  })}
`;

const TextEntry = styled.div`
  flex-grow: 1;
  flex-shrink: 1;

  ${mq({
    width: ['100%', null, 'initial'],
    margin: ['12px 0 12px 0', null, '0 24px 0 24px'],
  })}
`;

const ButtonEntry = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  ${mq({
    width: ['100%', null, '25%'],
    margin: ['12px 0 0 0', null, '0 0 0 24px'],
  })}
`;

export const DownsizePanel: React.FC = () => {
  const { data } = useUsageWithDownsizeQuery();
  const history = useHistory();

  const summary = data?.account.summaryForSuggestedUpgradeDowngrade;
  if (!summary) return null;

  const { savings } = summary;

  return (
    <Container>
      <IconEntry>
        <Icon src={SAVINGS} />
      </IconEntry>
      <TextEntry>
        <Text.Title size="medium">
          Save <Currency precision={0} value={savings} /> a month!
        </Text.Title>
        <Text.Callout>
          Your items can fit into a <Name summary={summary} /> plan. Save <Currency precision={0} value={savings} /> a
          month by downsizing.
        </Text.Callout>
      </TextEntry>
      <ButtonEntry>
        <Button fullWidth kind="primary" size="small" onClick={() => history.push(downsizeURL())}>
          View Details
        </Button>
      </ButtonEntry>
    </Container>
  );
};
