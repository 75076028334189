import { DateTime } from 'luxon';
import React from 'react';
import { Text } from '@clutter/clean';

import { useTermCommitmentBalanceQuery } from '@portal/schema';
import { Currency } from '@shared/components/helpers';
import { Section } from '@portal/components/orders/steps/confirm/section';

export const TermCommitmentBalance: React.FC<{
  scheduled: DateTime;
}> = ({ scheduled }) => {
  const { data } = useTermCommitmentBalanceQuery({ variables: { scheduled: scheduled.toJSON() } });
  const amountDue = data?.termCommitmentBalance?.amountDue || 0;

  return (
    <Section title="Term Commitment Balance">
      <Text.Body>
        The <Currency value={amountDue} /> balance left on your term commitment will be charged when you book this
        appointment. The appointment will not be booked if that charge fails.
      </Text.Body>
    </Section>
  );
};
