import styled from '@emotion/styled';
import * as React from 'react';
import { Box, COLORS, LARGE_TITLE_STYLES, Text } from '@clutter/clean';
import AFFIRM from '@portal/images/affirm.svg';
import { CheckCircle } from '@portal/components/shared/icons/check_circle';
import { Container } from '@portal/components/shared/layout';

const Image = styled.img`
  margin: -7px 0 10px;
`;

const TopContainer = styled(Container)`
  justify-content: flex-start;
  padding-top: 70px;
`;

const Caps = styled(Text.SmallCaps)`
  padding-bottom: 8px;
`;

const Title = styled.h1`
  ${LARGE_TITLE_STYLES.MD}
  margin: 0 0 32px;
  line-height: 48px;
  text-align: center;
`;

const Affirm: React.FC = () => <Image src={AFFIRM} alt="Affirm" />;

export const Succeeded: React.FC = () => (
  <TopContainer>
    <Box padding="0 0 32px 0">
      <CheckCircle height="75" width="75" fillColor={COLORS.tealPrimary} />
    </Box>
    <Caps>Thank you!</Caps>
    <Title>
      You’ve paid for your move with <Affirm />
    </Title>
  </TopContainer>
);
