import React from 'react';

import { orderURL } from '@portal/config/routes';
import { Phone } from '@shared/components/helpers/phone';
import { AppointmentDetailsOrder } from '../full_panel';
import { LineItem, TrackedLinkAction } from '../../line_item';
import { wtLinkParams } from './constants';

function updateContactTrackingParams(orderID: string) {
  return {
    ...wtLinkParams,
    objectName: 'update_contact',
    label: 'Edit',
    value: orderURL(orderID, 'contact'),
    order_id: orderID,
  };
}

export const OrderContactLineItem: React.FC<{ order: AppointmentDetailsOrder }> = ({ order }) => {
  const contactName = order.contact?.name || order.account.customer.name;
  const contactPhone = order.contact?.phone || order.account.customer.phone;

  return (
    <LineItem
      label="Day of Contact"
      action={
        <TrackedLinkAction
          params={{
            text: 'Edit',
            url: orderURL(order.id, 'contact'),
            trackingParams: updateContactTrackingParams(order.id),
          }}
        />
      }
    >
      <div>
        <div>{contactName}</div>
        <div>{<Phone value={contactPhone} />}</div>
      </div>
    </LineItem>
  );
};
