import styled from '@emotion/styled';
import React from 'react';

import { Spinner } from '@portal/components/helpers';
import { CONTAINER_SIDE_PADDING } from '@portal/components/shared/layout';
import { PaymentPlan__Offer__State as OfferState, usePaymentPlanOfferQuery } from '@portal/schema';

import { Accepted } from './accepted';
import { Approved } from './approved';
import { Expired } from './expired';

const Container = styled.div`
  padding: 0 ${CONTAINER_SIDE_PADDING}px;
  margin-top: 32px;
`;

export const Detail: React.FC<{ offerID: string }> = ({ offerID }) => {
  const { data, loading } = usePaymentPlanOfferQuery({ variables: { id: offerID } });

  if (loading) {
    return <Spinner />;
  }

  const offer = data && data.offer;

  if (!offer) {
    return <div>Invalid Offer.</div>;
  }

  let View = Expired;

  if (offer.state === OfferState.Accepted) {
    View = Accepted;
  } else if (offer.state === OfferState.Approved && !offer.expired) {
    View = Approved;
  }

  return (
    <Container>
      <View offer={offer} />
    </Container>
  );
};
