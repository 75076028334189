/* @jsx jsx */
import React, { useState } from 'react';

import { jsx } from '@emotion/react';

import styled from '@emotion/styled';

import { Button, COLORS, FontWeight, mq, Text } from '@clutter/clean';

import MagicBox from '@portal/images/magic-box.svg';

const RewardsShare = styled.div`
  background: ${COLORS.cloud};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 50px auto 0;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MagicBoxContainer = styled.img`
  width: 134px;
  height: 96px;
  position: relative;
  margin-top: -60px;
`;

const ShareHeader = styled.div`
  font-family: 'Recoleta', Georgia, serif;
  font-style: normal;
  font-weight: ${FontWeight.Medium};
  ${mq({
    marginTop: ['10px', null, '16px'],
    fontSize: ['26px', null, '30px'],
    lineHeight: ['34px', null, '41px'],
  })}
`;

const StyledAmount = styled.span`
  color: ${COLORS.tealBrand};
`;

const ShareSubtext = styled(Text.Callout)`
  margin-top: 8px;
`;

const CouponCode = styled.div<{ copied: boolean }>`
  height: 48px;
  border-radius: 4px;
  background: ${COLORS.grayBackground};
  margin: 24px auto 0;
  display: flex;
  justify-content: space-between;
  border: ${(props) => (props.copied ? '1.5' : '0')}px solid #097e77;
  box-sizing: border-box;
  cursor: pointer;
  ${mq({
    width: ['270px', null, '240px'],
  })}
`;

const Coupon = styled(Text.Body)<{ copied: boolean }>`
  margin: auto ${(props) => (props.copied ? '11.5' : '13')}px;
`;

const Copy = styled(Text.SmallCaps)<{ copied: boolean }>`
  margin: auto ${(props) => (props.copied ? '11.5' : '13')}px;
  color: ${COLORS.tealPrimary};
`;

const ShareButton = styled(Button)`
  margin-top: 16px;
  ${mq({
    width: ['279px', null, '240px'],
  })}
`;

export const CouponCard: React.FC<{
  referralCode: string;
  showShareModal(): void;
  className?: string;
}> = ({ referralCode, showShareModal, className }) => {
  const [copied, setCopied] = useState<boolean>(false);

  return (
    <RewardsShare className={className}>
      <MagicBoxContainer src={MagicBox} />
      <ShareHeader>
        Get up to <StyledAmount>$100</StyledAmount> for every friend you refer
      </ShareHeader>
      <ShareSubtext weight={FontWeight.Regular}>Promo for storage & moving</ShareSubtext>
      <CouponCode
        onClick={() => {
          setCopied(true);
          navigator.clipboard?.writeText(referralCode);
          setTimeout(() => setCopied(false), 20000);
        }}
        copied={copied}
      >
        <Coupon weight={FontWeight.Medium} copied={copied}>
          {referralCode}
        </Coupon>
        <Copy copied={copied}>{copied ? 'COPIED!' : 'COPY'}</Copy>
      </CouponCode>
      <ShareButton size="medium" kind="primary" onClick={showShareModal}>
        Share your Code
      </ShareButton>
    </RewardsShare>
  );
};
