import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spacer } from '@shared/components/helpers';
import cn from 'classnames';
import { omit } from 'lodash';
import * as React from 'react';

export type ButtonKind =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'
  | 'link'
  | 'mint'
  | 'default';
export type ButtonSize = 'sm' | 'lg';

type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
type AnchorProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

interface IButtonStylingProps {
  active?: boolean;
  block?: boolean;
  outline?: boolean;
  kind?: ButtonKind;
  size?: ButtonSize;
  className?: string;
}

interface IButtonStateProps {
  disabled?: boolean;
  disabledLabel?: string;
  loading?: boolean;
  loadingLabel?: string;
}

const styleAndStateKeys = [
  'active',
  'block',
  'outline',
  'kind',
  'size',
  'className',
  'disabled',
  'disabledLabel',
  'loading',
  'loadingLabel',
];

function generateClassName({
  active,
  block,
  outline,
  kind,
  size,
  className,
  disabled,
  loading,
}: IButtonStylingProps & IButtonStateProps) {
  return cn(
    'btn',
    active && 'active',
    kind && (outline ? `btn-outline-${kind}` : `btn-${kind}`),
    size && `btn-${size}`,
    block && 'btn-block',
    (loading || disabled) && 'disabled',
    className,
  );
}

function generateLabel({
  children,
  disabled,
  disabledLabel,
  loading,
  loadingLabel,
  size,
}: IButtonStateProps & { children?: React.ReactNode; disabled?: boolean; size?: ButtonSize }) {
  if (disabled) {
    return disabledLabel || children;
  } else if (loading) {
    return (
      <>
        <FontAwesomeIcon icon="spinner" spin />
        <Spacer width={(size === 'sm' && '4px') || (size === 'lg' && '8px') || '6px'} inline />
        {loadingLabel || children}
      </>
    );
  }

  return children;
}

const ButtonGroup: React.FC = ({ children }) => (
  <div className="btn-group" role="group">
    {children}
  </div>
);

const Button: React.FC<ButtonProps & IButtonStylingProps & IButtonStateProps> = ({ type = 'button', ...props }) => (
  <button
    type={type}
    {...omit(props, styleAndStateKeys)}
    className={generateClassName(props)}
    children={generateLabel(props)}
    disabled={props.loading || props.disabled}
  />
);

const AnchorButton: React.FC<AnchorProps & IButtonStylingProps & IButtonStateProps> = (props) => (
  <a
    {...omit(props, styleAndStateKeys)}
    className={generateClassName(props)}
    children={generateLabel(props)}
    href={props.disabled || props.loading ? undefined : props.href}
    aria-disabled={props.loading || props.disabled}
  />
);

const CombinedButton = Object.assign(Button, { Group: ButtonGroup });
const CombinedAnchorButton = Object.assign(AnchorButton, { Group: ButtonGroup });
export { CombinedButton as Button };
export { CombinedAnchorButton as AnchorButton };
