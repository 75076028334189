import { COLORS, FontWeight, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { CostTable } from '@portal/components/shared/cost_table';

const Callout = styled(Text.Callout)`
  color: ${COLORS.panther};
`;

const Table = styled(CostTable)`
  td {
    text-align: left !important; // Override shared table styling.
  }
`;

export const Lines: React.FC<{ lines: string[] }> = ({ lines }) => (
  <Table removeBottomBorder removeTopBorder>
    <tbody>
      {lines.map((line, idx) => (
        <tr key={idx}>
          <td>
            <Callout weight={FontWeight.Medium}>{line}</Callout>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);
