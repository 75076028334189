import styled from '@emotion/styled';
import React from 'react';

import { AnchorButton } from '@portal/components/shared/button';
import { Heading } from '@portal/components/shared/text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Heading)`
  margin: 32px 0;
  text-align: center;
`;

const StyledAnchorButton = styled(AnchorButton)`
  width: 100%;
  max-width: 470px;
`;

export const Success: React.FC = () => (
  <Container>
    <Title level={1} size="medium">
      Thanks for your feedback
    </Title>
    <StyledAnchorButton kind="secondary" size="medium" href="/">
      Back to Account
    </StyledAnchorButton>
  </Container>
);
