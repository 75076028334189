import styled from '@emotion/styled';
import { COLORS, Text } from '@clutter/clean';
import React, { useEffect, useState } from 'react';

const FADE_TRANSITION = 250; // ms.

const Flash = styled.div<{ transparent: boolean }>`
  background: ${COLORS.tealDark};
  border-radius: 4px;
  color: ${COLORS.cloud};
  padding: 12px;
  text-align: center;
  position: fixed;
  left: 24px;
  right: 24px;
  bottom: 24px;
  z-index: 1;
  opacity: ${({ transparent }) => (transparent ? 0 : 1)};
  transition: opacity ${FADE_TRANSITION}ms linear;
`;

export const SuccessFlash: React.FC<{
  message: string;
  onFadeout(): void;
}> = ({ message, onFadeout }) => {
  const [transparent, setTransparent] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setTransparent(true);
      setTimeout(onFadeout, FADE_TRANSITION);
    }, 3000);
  });

  return (
    <Flash transparent={transparent}>
      <Text.Callout>{message}</Text.Callout>
    </Flash>
  );
};
