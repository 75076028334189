import { Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

import {
  PaymentPlanDetail__OfferFragment as PaymentPlanOffer,
  PaymentPlan__Offer__Type as OfferType,
} from '@portal/schema';

import { Checkbox } from '@portal/components/shared/checkbox';
import { Pluralize } from '@shared/components/helpers';
import { currency } from '@shared/utils/currency';
import { formatDate } from '@shared/utils/format_date';

const Container = styled.div`
  margin: 32px 0;
`;

const Title = styled(Text.Title)`
  margin-bottom: 24px;
`;

export const Terms: React.FC<{
  offer: PaymentPlanOffer;
  setAgreed(agreed: boolean): void;
}> = ({ offer, setAgreed }) => {
  const [checkboxValues, setCheckboxValues] = useState<{ [key: string]: boolean }>({});

  const { oneTimeFee, monthlyFee, monthlyFeeTerm, storageTermEndDate, type } = offer;
  const paymentTotal = (oneTimeFee || 0) + (monthlyFee || 0);

  const options: Array<{ key: string; label: React.ReactElement<any> }> = [];

  if (type === OfferType.AutopayOptIn) {
    options.push({
      key: 'authorizeCardForAutopay',
      label: (
        <Text.Callout>
          I authorize Clutter to automatically charge the card selected above for all future monthly invoices associated
          with my storage unit rentals.
        </Text.Callout>
      ),
    });
  } else {
    options.push({
      key: 'auctionEligible',
      label: (
        <Text.Callout>
          I understand that failure to pay future invoices will result in my stored items being eligible for auction.
        </Text.Callout>
      ),
    });
  }

  options.push({
    key: 'authorizeCharge',
    label: (
      <Text.Callout>
        I authorize Clutter to charge the card selected above for {currency(paymentTotal)} when I{' '}
        {type === OfferType.AutopayOptIn ? 'opt into autopay' : 'accept the payment plan'}
        {!!monthlyFee && !!monthlyFeeTerm && (
          <>
            {' '}
            and to charge {currency(monthlyFee)} on today’s date for the next{' '}
            <Pluralize count={monthlyFeeTerm - 1} singular="month" plural="months" />
          </>
        )}
        .
      </Text.Callout>
    ),
  });

  if (storageTermEndDate) {
    options.push({
      key: 'storageCommitment',
      label: (
        <Text.Callout>
          I understand that I am committing to store with Clutter until {formatDate(offer.storageTermEndDate!)} and am
          responsible for all storage payments during that term.
        </Text.Callout>
      ),
    });
  }

  useEffect(() => {
    const allSelected = options.every((option) => checkboxValues[option.key]);
    setAgreed(allSelected);
  }, [checkboxValues]);

  return (
    <Container>
      <Title size="extraSmall">Agree to terms</Title>
      <Checkbox
        name="agreement-terms"
        value={checkboxValues}
        options={options}
        onChange={(_, value) => setCheckboxValues(value)}
      />
    </Container>
  );
};
