import { itemsURL } from '@portal/config/routes';
import { CallbackWindow } from '@portal/schema';
import { Box, COLORS, Text } from '@clutter/clean';
import { DateTime, Duration } from 'luxon';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatCallbackWindow } from './util';

export const Confirmed: React.FC<{
  callbackWindow: CallbackWindow;
}> = ({ callbackWindow }) => (
  <Box margin="0 auto" maxWidth="728px">
    <Text.Title size="medium" color={COLORS.tealDark}>
      Call back confirmed!
    </Text.Title>
    <br />
    <Text.Body>
      Your appointment is <b>not yet canceled</b>.
    </Text.Body>
    <br />
    <Text.Body>
      {callbackWindow.duration === Duration.fromObject({ minutes: 10 }).toISO() ? (
        <>A Clutter agent will call you back in the next 5-10 minutes to complete your cancellation request.</>
      ) : (
        <>
          A Clutter agent will call you back {DateTime.fromISO(callbackWindow.time).toLocaleString(DateTime.DATE_MED)}{' '}
          between {formatCallbackWindow(callbackWindow)} to complete your cancellation request.
        </>
      )}
    </Text.Body>
    <br />
    <Text.Body>
      Take me back to <Link to={itemsURL()}>My Appointment</Link>.
    </Text.Body>
  </Box>
);
