export const formattedDeductible = (deductible: number) => {
  if (deductible === 0) {
    return 'No Deductible';
  } else {
    return `$${deductible} Deductible`;
  }
};

export const formattedCost = (price: number) => {
  if (price === 0) {
    return 'Free';
  } else {
    return `$${price.toFixed(2)} per $100`;
  }
};
