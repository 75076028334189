import { COLORS, ButtonComponent, Text } from '@clutter/clean';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Paperclip } from '@portal/components/shared/icons/paperclip';
import { Times } from '@portal/components/shared/icons/times';
import { bodyStyle } from '@portal/components/shared/text';
import { Manager, Selector, Uploader } from '@shared/components/files';
import { IEventParams } from '@portal/types/wt/event';
import { wt } from '@portal/initializers/wt';
import { toggleStyleValue } from '../orders/steps/delivery_option/css';

const horizontallyCenter = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const disabledStyle = css`
  pointer-events: none;
  box-shadow: none;
  color: ${COLORS.hippo};
  border: none;
  background: ${COLORS.grayBorder};
`;

const FileContainer = styled.div`
  ${bodyStyle}
  ${horizontallyCenter}
  background-color: ${COLORS.grayBackground};
  border-radius: 4px;
  height: 48px;
  padding: 0 16px;
  margin: 12px 0;
  justify-content: space-between;
`;

const FileName = styled.span`
  max-width: 90%;
  overflow-wrap: break-word;
  word-break: break-all;
`;

const RemoveButton = styled.button`
  float: right;
  :focus {
    outline: 2px solid ${COLORS.tealPrimary};
    box-shadow: none;
  }
`;

const AttachButton = styled(ButtonComponent)<{ disabled?: boolean }>`
  ${horizontallyCenter}
  justify-content: center;
  ${({ disabled }) => disabled && disabledStyle}
`.withComponent('label');

const AttachButtonText = styled(Text.Button)<{ contentLeft?: boolean }>`
  padding-left: ${toggleStyleValue('contentLeft', '8px', '0px')};
`;

interface IFileData {
  file: File;
  signedID: string;
}

const ATTACH_BUTTON_LABEL = 'Attach a file';

export const FileUploader: React.FC<{
  onUploading(uploading: boolean): void;
  onFileSelected(signedIDs: string[]): void;
  wtEventParams?: IEventParams;
  withPaperClip?: boolean;
  fullWidthButton?: boolean;
  disabled?: boolean;
}> = ({ onUploading, onFileSelected, wtEventParams, withPaperClip = true, fullWidthButton = true, disabled }) => {
  const [uploadedFiles, setUploadedFiles] = useState<IFileData[]>([]);

  const onFileUpload = (file: File, signedID: string) => {
    setUploadedFiles((current) => {
      const newUploads = [...current, { file, signedID }];
      onFileSelected(newUploads.map(({ signedID: entrySignedID }) => entrySignedID));
      return newUploads;
    });
    if (wtEventParams) {
      wt.track({
        ...wtEventParams,
        action: 'click',
        label: ATTACH_BUTTON_LABEL,
        objectName: 'attach_file',
        objectType: 'input:button',
        value: file.name,
      });
    }
  };

  const onFileRemove = (entry: IFileData, index: number) => {
    if (!uploadedFiles) {
      return;
    }
    const filteredUploads = uploadedFiles.filter(({ signedID }) => signedID !== entry.signedID);
    onFileSelected(filteredUploads.map(({ signedID }) => signedID));
    setUploadedFiles(filteredUploads);
    if (wtEventParams) {
      wt.track({
        ...wtEventParams,
        action: 'click',
        label: 'x',
        objectName: 'remove_attachment',
        objectType: 'image',
        position: index + 1, // overriden position with the index of the file we removed
        value: entry.file.name,
      });
    }
  };

  return (
    <Manager onUploading={onUploading} onSave={onFileUpload}>
      {({ uploads }) => (
        <>
          <AttachButton
            htmlFor="file-selector"
            kind="secondary"
            size="medium"
            style={{ display: fullWidthButton ? 'flex' : 'inline-flex' }}
            fullWidth={fullWidthButton}
            disabled={disabled}
          >
            {withPaperClip && <Paperclip />}
            <AttachButtonText contentLeft={withPaperClip}>{ATTACH_BUTTON_LABEL}</AttachButtonText>
            <Selector id="file-selector" disabled={disabled} />
          </AttachButton>
          {uploads.map((entry) => (
            <Uploader key={entry.uuid} {...entry} />
          ))}
          {uploadedFiles.map((entry, index) => (
            <FileContainer key={entry.signedID}>
              <FileName>{entry.file.name}</FileName>
              {!disabled && (
                <RemoveButton className="btn" type="button" onClick={() => onFileRemove(entry, index)}>
                  <Times />
                </RemoveButton>
              )}
            </FileContainer>
          ))}
        </>
      )}
    </Manager>
  );
};
