import { Button, COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import * as qs from 'qs';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Spinner } from '@portal/components/helpers';
import { RewardsBanner } from '@portal/components/rewards/rewards_banner';
import { TrackedLink } from '@portal/components/wt/tracked_link';
import { accountURL } from '@portal/config/routes';
import { Marketing__Share, Rating, useReferralQuery } from '@portal/schema';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Text.Title)`
  margin: 16px 0;
`;

const StyledLink = styled.a`
  color: ${COLORS.tealPrimary};
  text-decoration: none;
  :hover {
    color: ${COLORS.tealDark};
    text-decoration: none;
  }
`;

const ReferralCode = styled.span`
  font-weight: bold;
  color: ${COLORS.tealPrimary};
`;

const BannerContainer = styled.div`
  margin: 24px 0;
`;

const MarketingShare: React.FC<{ share: Marketing__Share }> = ({ share }) => (
  <>
    <Title size="extraSmall">Spread the love</Title>
    <Text.Body>
      We’re thrilled you’re a fan of Clutter! Help us spread the word.{' '}
      <strong>
        Drop us a line on{' '}
        <TrackedLink
          params={{
            pageName: 'portal:review_thanks',
            container: 'spread_the_love',
            action: 'click',
            objectType: 'link',
            objectName: 'review_link',
            label: share.text,
            value: share.url,
          }}
        >
          <StyledLink href={share.url}>{share.text}</StyledLink>
        </TrackedLink>
      </strong>{' '}
      – it helps us reach more awesome customers like you!
    </Text.Body>
  </>
);

const Referral: React.FC<{ reviewableID: string }> = ({ reviewableID }) => {
  const { data, loading } = useReferralQuery({ variables: { reviewableID } });

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <Title size="extraSmall">Score up to $100 Clutter credit</Title>
      <Text.Body>
        <strong>
          You earn $100 in account credit once your friend books storage with Clutter, and $50 in account credit once
          your friend books a move.
        </strong>{' '}
        As a bonus, your friend receives the same amount as account credit as well! There’s no limit on the number of
        referrals you can cash in on. Just give them your referral code:{' '}
        <ReferralCode>{data?.referralCode}</ReferralCode>
      </Text.Body>
      <BannerContainer>
        <RewardsBanner source="post_job_review_page" />
      </BannerContainer>
      {data?.marketingShare && <MarketingShare share={data.marketingShare} />}
    </div>
  );
};

const QS_PARSE_OPTIONS = { ignoreQueryPrefix: true };

export const Thanks: React.FC = () => {
  const location = useLocation();
  const params = qs.parse(location.search, QS_PARSE_OPTIONS);
  const reviewableID = params.reviewable_id;
  const rating = params.rating?.toUpperCase();

  return (
    <Container>
      <Title size="medium">Thanks for your feedback</Title>
      {rating === Rating.Good ? (
        <Referral reviewableID={reviewableID} />
      ) : (
        <a href={accountURL()}>
          <Button size="medium">Back to Account</Button>
        </a>
      )}
    </Container>
  );
};
