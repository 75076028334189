import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Input, Button, Text, FontWeight, COLORS } from '@clutter/clean';
import { Banner as ErrorBanner } from '@portal/components/shared/error';
import { InputLabel } from '@portal/components/shared/form';
import { CUSTOMER_POST_BOOKING_SIGN_IN } from '@portal/config/customer';

const FormContent = styled.div`
  padding: 16px 0;
  input {
    width: 100%;
  }
`;

const UpdateCTA = styled(Button)`
  margin-top: 16px;
  width: 100%;
  @media (max-width: 600px) {
    margin-top: 8px;
  }
`;

const Container = styled.div`
  background-color: ${COLORS.grayBackground};
  border-radius: 4px;
  @media (min-width: 701px) {
    margin: 20px 0;
    padding: 40px 60px 50px;
  }
  @media (max-width: 700px) {
    margin: 24px 0;
    padding: 32px 24px;
    width: calc(100% + 32px);
    margin-left: -16px;
  }
`;

const Caption = styled(Text.Caption)`
  color: ${COLORS.storm};
  margin-top: 8px;
  font-weight: ${FontWeight.Medium};
`;

const Error = styled(ErrorBanner)`
  padding: 0 0 16px 0;
  @media (max-width: 600px) {
    padding: 8px 0;
  }
`;

export const PasswordForm: React.FC<{
  error?: React.ReactElement;
  loading: boolean;
  buttonText?: string;
  savePassword(password: string): void;
}> = ({ error, loading, buttonText = 'Update Password', savePassword }) => {
  const [password, setPassword] = useState<string>('');

  const MINIMUM_REQUIRED_CHARACTERS = 8;

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    await savePassword(password);
  };

  if (CUSTOMER_POST_BOOKING_SIGN_IN) {
    return (
      <>
        {error && <Error>{error}</Error>}
        <Container>
          <form onSubmit={onSubmit}>
            <FormContent>
              <label htmlFor="customer-password">
                <Text.Body weight={FontWeight.Medium}>Set Your Password</Text.Body>
              </label>
              <Input
                id="customer-password"
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value || '')}
                autoFocus
              />
              <Caption>Must contain {MINIMUM_REQUIRED_CHARACTERS} or more characters.</Caption>
            </FormContent>
            <UpdateCTA
              kind="primary"
              size="medium"
              loading={loading}
              disabled={password.length < MINIMUM_REQUIRED_CHARACTERS}
            >
              {buttonText}
            </UpdateCTA>
          </form>
        </Container>
      </>
    );
  }

  return (
    <>
      {error && <Error>{error}</Error>}
      <form onSubmit={onSubmit}>
        <FormContent>
          <InputLabel htmlFor="customer-password">Password</InputLabel>
          <Input
            id="customer-password"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value || '')}
            autoFocus
          />
          <Caption>Must contain {MINIMUM_REQUIRED_CHARACTERS} or more characters.</Caption>
        </FormContent>
        <UpdateCTA
          kind="primary"
          size="medium"
          loading={loading}
          disabled={password.length < MINIMUM_REQUIRED_CHARACTERS}
        >
          {buttonText}
        </UpdateCTA>
      </form>
    </>
  );
};
